export default [
  "A Estrada, Spain",
  "Abadino, Spain",
  "Abanilla, Spain",
  "Abano Terme, Italy",
  "Abanto Zierbena, Spain",
  "Abaran, Spain",
  "Abbeville, France",
  "Abbeyleix Laois, Ireland",
  "Abbiategrasso, Italy",
  "Abbotsford, Canada",
  "Abda, Hungary",
  "Abejar, Spain",
  "Abenberg, Germany",
  "Aberbargoed, United Kingdom",
  "Abercarn, United Kingdom",
  "Aberdare, United Kingdom",
  "Aberdeen, United Kingdom",
  "Aberdeen, United States",
  "Aberdour Fife, United Kingdom",
  "Abergavenny, United Kingdom",
  "Abergele, United Kingdom",
  "Aberlour Moray, United Kingdom",
  "Abidjan, Ivory Coast",
  "Abingdon, United Kingdom",
  "Abingdon, United States",
  "Ablitas, Spain",
  "Aboyne, United Kingdom",
  "Abra, Portugal",
  "Abrantes, Portugal",
  "Abrera, Spain",
  "Abridge Essex, United Kingdom",
  "Abrigada, Portugal",
  "Absam, Austria",
  "Abtsgmuend, Germany",
  "Abtsteinach, Germany",
  "Abtswind, Germany",
  "Abu Dhabi, United Arab Emirates",
  "Acae, Gabon",
  "Acajutla, El Salvador",
  "Acambaro, Mexico",
  "ACATIC, Mexico",
  "Acatlan de Juarez, Mexico",
  "Accrington, United Kingdom",
  "Acerra, Italy",
  "Aceuchal, Spain",
  "Achberg, Germany",
  "Achenheim, France",
  "Achern, Germany",
  "Achim (bei Bremen), Germany",
  "Acigne, France",
  "Ackworth, United Kingdom",
  "Acolman, Mexico",
  "Acquanegra Cremonese, Italy",
  "Acquasparta, Italy",
  "Acquaviva, Italy",
  "Acquaviva delle Fonti, Italy",
  "Acquaviva Picena, Italy",
  "Acqui Terme, Italy",
  "Acquigny, France",
  "Acton, United States",
  "Acton London, United Kingdom",
  "Acton Turville, United Kingdom",
  "Acton Vale, Canada",
  "Acworth, United States",
  "Ad Dakhla, Morocco",
  "Adairsville, United States",
  "Adak, United States",
  "Adamov, Czech Republic",
  "Adana, Turkey",
  "Adapazari, Turkey",
  "Adaufe, Portugal",
  "Adazi, Latvia",
  "Adderbury, United Kingdom",
  "Addis Ababa, Ethiopia",
  "Addison, United States",
  "Addlestone Surrey, United Kingdom",
  "Addo, South Africa",
  "Adelaide, Australia",
  "Adelfia, Italy",
  "Adelsdorf (Mittelfranken), Germany",
  "Adelshofen, Germany",
  "Aden, Yemen",
  "Adendorf (Kr Lueneburg), Germany",
  "Adiyaman, Turkey",
  "Adlington Ches, United Kingdom",
  "Adlington Lancs, United Kingdom",
  "Adlkofen, Germany",
  "Admont, Austria",
  "Adorf, Germany",
  "Adrano, Italy",
  "Adrara San Martino, Italy",
  "Adria, Italy",
  "Adro, Italy",
  "Adrspach, Czech Republic",
  "Aduna, Spain",
  "Advance, United States",
  "AELMHULT, Sweden",
  "Aengelholm, Sweden",
  "Aerzen, Germany",
  "Affalterbach (Wuertt), Germany",
  "Affi, Italy",
  "Afidnes, Greece",
  "Afton, United States",
  "Afula, Israel",
  "Afumati, Romania",
  "Afyon, Turkey",
  "Agadez, Niger",
  "Agadir, Morocco",
  "Agia, Greece",
  "Agigea, Romania",
  "Agios Athanasios, Greece",
  "Agios Pavlos Khalkidiki, Greece",
  "Agios Stefanos, Greece",
  "Agliana, Italy",
  "Agnana Calabra, Italy",
  "Agnellengo, Italy",
  "Agnin, France",
  "Agognate, Italy",
  "Agoncillo, Spain",
  "Agost, Spain",
  "Agra, India",
  "Agramunt, Spain",
  "Agrate Brianza, Italy",
  "Agreda, Spain",
  "Agrigento, Italy",
  "Agrinio, Greece",
  "Agsu, Azerbaijan",
  "Aguadulce Almeria, Spain",
  "Aguas Belas, Portugal",
  "Aguas de Moura, Portugal",
  "Aguas Zarcas, Costa Rica",
  "Aguascalientes, Mexico",
  "Agueda, Portugal",
  "Aguilar de Campoo, Spain",
  "Aguilar Frontera, Spain",
  "Aguilas, Spain",
  "Aguimes, Spain",
  "Agullent, Spain",
  "Ahaus, Germany",
  "Ahlen (Westf), Germany",
  "Ahmedabad, India",
  "Ahrensboek, Germany",
  "Ahrensburg, Germany",
  "Ahuachapan, El Salvador",
  "Aichach, Germany",
  "Aichhalden (b Schramberg), Germany",
  "Aichtal, Germany",
  "Aicurzio, Italy",
  "Aiello del Friuli, Italy",
  "Aielo de Malferit, Spain",
  "Aiginio Pierias, Greece",
  "Aigre, France",
  "Aiguaviva GIRONA, Spain",
  "Aigues Mortes, France",
  "Aiken, United States",
  "Aillevillers-et-Lyaumont, France",
  "Aimargues, France",
  "Ain Harrouda, Morocco",
  "Ainring, Germany",
  "Aintree, United Kingdom",
  "Airasca, Italy",
  "Airdrie, United Kingdom",
  "Airola, Italy",
  "Airport Industria, South Africa",
  "Airuno, Italy",
  "Ait Amira, Morocco",
  "Ait melloul, Morocco",
  "Aitona, Spain",
  "Aitos, Bulgaria",
  "Aitrach, Germany",
  "Aix en Provence, France",
  "Aix les Bains, France",
  "Aixe sur Vienne, France",
  "Aizenay, France",
  "Aizkraukle, Latvia",
  "Ajalvir, Spain",
  "Ajax, Canada",
  "Ajdovscina, Slovenia",
  "Ajman, United Arab Emirates",
  "Akcansa - Ambarli, Turkey",
  "Akersloot, Netherlands",
  "Akhisar, Turkey",
  "Akita, Japan",
  "Akron, United States",
  "Aksaray, Turkey",
  "Aksehir, Turkey",
  "Akutan, United States",
  "Akyazi, Turkey",
  "Akyurt, Turkey",
  "Akaa, Finland",
  "Al Ain, United Arab Emirates",
  "Al Asad Airbase, Iraq",
  "Al Azraq, Jordan",
  "Al Dhafrah, United Arab Emirates",
  "Al Jaber, Kuwait",
  "Al Jubayl, Saudi Arabia",
  "Al Karak, Jordan",
  "Al Kharj, Saudi Arabia",
  "Al Khoms, Libya",
  "Al Mafraq, United Arab Emirates",
  "AL MENUFIA, Egypt",
  "Al Nubaria, Egypt",
  "Al Qassim, Saudi Arabia",
  "Al Qayyarah, Iraq",
  "Al Quasis, United Arab Emirates",
  "Al Rayyan, Qatar",
  "Al Salijah, Qatar",
  "Al Udeid, Qatar",
  "Ala, Italy",
  "Alagon, Spain",
  "Alajuela, Costa Rica",
  "Alameda de la Sagra, Spain",
  "Alamo, Mexico",
  "Alange, Spain",
  "Alanno, Italy",
  "Alano di Piave, Italy",
  "Alanya, Turkey",
  "Alaquas, Spain",
  "Alar del Rey, Spain",
  "Alasehir, Turkey",
  "Alava asturias, Spain",
  "Alba, Italy",
  "Alba de Tormes, Spain",
  "Alba Iulia, Romania",
  "Albacete, Spain",
  "Albaching, Germany",
  "Albaida, Spain",
  "Albairate, Italy",
  "Albal, Spain",
  "Albalat Ribera, Spain",
  "Albalate Del Arzobispo, Spain",
  "Albanchez, Spain",
  "Albano Laziale, Italy",
  "Albano Sant'Alessandro, Italy",
  "Albany, United States",
  "Albaredo d'Adige, Italy",
  "Albatarrec, Spain",
  "Albate, Italy",
  "Albatera, Spain",
  "Albavilla, Italy",
  "Albbruck, Germany",
  "Albelda de Iregua, Spain",
  "Albenga, Italy",
  "Alberche Del Caudillo, Spain",
  "Albergaria a Velha, Portugal",
  "Albergaria dos Doze, Portugal",
  "Alberic, Spain",
  "Alberite, Spain",
  "Alberton, South Africa",
  "Albese Con Cassano, Italy",
  "Albettone, Italy",
  "Albi, France",
  "Albiano, Italy",
  "Albiate, Italy",
  "Albignasego, Italy",
  "Albina, Italy",
  "Albinea, Italy",
  "Albino, Italy",
  "ALBION, United States",
  "Albizzate, Italy",
  "Alblasserdam, Netherlands",
  "Albolote, Spain",
  "Alborache, Spain",
  "Alboraya, Spain",
  "Albox, Spain",
  "Albrandswaard, Netherlands",
  "Albstadt, Germany",
  "Albuixech, Spain",
  "Alby sur Cheran, France",
  "Alcabon, Spain",
  "Alcala de Guadaira, Spain",
  "Alcala de Henares, Spain",
  "Alcala del Rio Sevilla, Spain",
  "Alcala la Real, Spain",
  "Alcampell, Spain",
  "Alcanede, Portugal",
  "Alcanena, Portugal",
  "Alcaniz, Spain",
  "Alcantarilla, Spain",
  "Alcantera de Xuquer, Spain",
  "Alcarras, Spain",
  "Alcasser, Spain",
  "Alcaudete, Spain",
  "Alcazar de San Juan, Spain",
  "Alcester, United Kingdom",
  "Alcobaca, Portugal",
  "Alcobendas, Spain",
  "Alcochete, Portugal",
  "Alcolea de Cordoba, Spain",
  "Alcolea del Rio, Spain",
  "Alcoletge, Spain",
  "Alconbury, United Kingdom",
  "Alcontar, Spain",
  "Alcora, Spain",
  "Alcorcon, Spain",
  "Alcoy, Spain",
  "Alcudia de Crespins, Spain",
  "Aldaia, Spain",
  "Aldbury, United Kingdom",
  "Aldea del Cano, Spain",
  "Aldeamayor de San Martin, Spain",
  "Aldeanueva de Ebro, Spain",
  "Aldeatejada, Spain",
  "Aldeburgh, United Kingdom",
  "Aldenhoven (bei Juelich), Germany",
  "Aldeno, Italy",
  "Aldermaston, United Kingdom",
  "Aldersbach, Germany",
  "Aldershot, United Kingdom",
  "Aldingen, Germany",
  "Aldridge, United Kingdom",
  "Alegia, Spain",
  "Alegria-Dulantzi de Alava, Spain",
  "ALEKSINCE, Slovakia",
  "Alenquer, Portugal",
  "Aleson, Spain",
  "Alessandria, Italy",
  "Alexandria, Egypt",
  "Alexandria, Greece",
  "Alexandria, Romania",
  "Alexandria, United States",
  "Alexandroupolis, Greece",
  "Alfafar, Spain",
  "Alfajarin, Spain",
  "Alfamen, Spain",
  "Alfaro, Spain",
  "Alfarp, Spain",
  "Alfdorf, Germany",
  "Alfeld (Leine), Germany",
  "Alfena, Portugal",
  "Alfenas, Brazil",
  "Alfero, Italy",
  "Alfhausen, Germany",
  "Alfianello, Italy",
  "Alfonsine, Italy",
  "Alford Gramp, United Kingdom",
  "Alford Lincs, United Kingdom",
  "Alfreton, United Kingdom",
  "Alfter, Germany",
  "Algar, Spain",
  "Algeciras, Spain",
  "Algemesi, Spain",
  "Algete, Spain",
  "Algiers port, Algeria",
  "Algiers Terminal, Algeria",
  "Alginet, Spain",
  "Algood, United States",
  "Alguazas, Spain",
  "Alguena, Spain",
  "Algyo, Hungary",
  "Alhama de Aragon, Spain",
  "Alhama de Murcia, Spain",
  "Alhaurin de la Torre, Spain",
  "Alheim Heinebach, Germany",
  "Ali Al Salem Ab, Kuwait",
  "Aliaga Izmir, Turkey",
  "Aliartos, Greece",
  "Alicante, Spain",
  "Alife, Italy",
  "Alimos, Greece",
  "Alimosho, Nigeria",
  "Alingsaas, Sweden",
  "Aliquippa, United States",
  "Alitak, United States",
  "Alizay, France",
  "Aljaraque, Spain",
  "Aljubarrota Sao Vicente, Portugal",
  "Alken, Belgium",
  "Alkmaar, Netherlands",
  "Allariz, Spain",
  "Allegan, United States",
  "Allen, United States",
  "Allen Park, United States",
  "Allendale, United States",
  "Allendorf (Eder), Germany",
  "Allensbach, Germany",
  "Allentown, United States",
  "Alleppey, India",
  "Allington Wiltshire, United Kingdom",
  "Alloa, United Kingdom",
  "Allonnes Sarthe, France",
  "Allonzier la Caille, France",
  "Almacelles, Spain",
  "Almada, Portugal",
  "Almansa, Spain",
  "Almasfuzito, Hungary",
  "Almassera, Spain",
  "Almazan, Spain",
  "Almazora, Spain",
  "Alme, Italy",
  "Almelo, Netherlands",
  "Almenara, Spain",
  "Almendralejo, Spain",
  "Almendricos, Spain",
  "Almenno San Bartolomeo, Italy",
  "Almenno San Salvatore, Italy",
  "Almere, Netherlands",
  "Almeria, Spain",
  "AlmeseTO, Italy",
  "Almiros, Greece",
  "Almisano, Italy",
  "Almoharin, Spain",
  "Almonte, Spain",
  "Almoradi, Spain",
  "Almudevar, Spain",
  "Almunecar, Spain",
  "Almunia Dona Godina, Spain",
  "Almuniente, Spain",
  "Almussafes, Spain",
  "Al-Mwagar, Jordan",
  "Alness, United Kingdom",
  "Alnwick Northumberland NE, United Kingdom",
  "Alojzov, Czech Republic",
  "Alonte, Italy",
  "Alora Malaga, Spain",
  "Alovera, Spain",
  "Alpedrete, Spain",
  "Alpen, Germany",
  "Alpena, United States",
  "Alpharetta, United States",
  "Alphen aan den Rijn, Netherlands",
  "Alpiarca, Portugal",
  "Alpignano, Italy",
  "Alpirsbach, Germany",
  "Alqueria de Asnar, Spain",
  "Alquerias, Spain",
  "Alquerias de Nino Perdido, Spain",
  "Alresford, United Kingdom",
  "Alrewas Staffordshire, United Kingdom",
  "Alrode, South Africa",
  "Alsager, United Kingdom",
  "Alsancak, Turkey",
  "Alsasua, Spain",
  "Alsbach-Haehnlein, Germany",
  "Alsdorf, Germany",
  "Alsfeld, Germany",
  "Alsip, United States",
  "Alsozsolca, Hungary",
  "Alstermo, Sweden",
  "Alston Cumbria CA, United Kingdom",
  "Alt Bork, Germany",
  "Altamira, Mexico",
  "Altamura, Italy",
  "Altare, Italy",
  "Altavilla Irpina, Italy",
  "Altavilla Vicentina, Italy",
  "Altavista, United States",
  "Altbach, Germany",
  "Altdorf, Switzerland",
  "Altdorf (b Landshut), Germany",
  "Altdorf (b Nuernberg), Germany",
  "Alte Ceccato, Italy",
  "Altea, Spain",
  "Altedo, Italy",
  "Altena, Germany",
  "Altenberge (Westf), Germany",
  "Altenkunstadt, Germany",
  "Altenmarkt, Austria",
  "Altenstadt (b Schongau), Germany",
  "Altenstadt (Hess), Germany",
  "Altensteig, Germany",
  "Altham, United Kingdom",
  "Altheim, Austria",
  "Altheim (bei Ehingen), Germany",
  "Althengstett, Germany",
  "Althofen, Austria",
  "Altillac, France",
  "Altindag, Turkey",
  "Altishofen, Switzerland",
  "Altivole, Italy",
  "Altleiningen, Germany",
  "Altlussheim, Germany",
  "Alton Hampshire, United Kingdom",
  "Alton Staffordshire, United Kingdom",
  "Altopascio, Italy",
  "Altrincham, United Kingdom",
  "Altshausen, Germany",
  "Altstaetten, Switzerland",
  "Altube, Spain",
  "Altus, United States",
  "Altusried, Germany",
  "Altzo, Spain",
  "Alum Bank, United States",
  "Alvaraes, Portugal",
  "Alverca do Ribatejo, Portugal",
  "Alvignano, Italy",
  "Alwernia, Poland",
  "Alytus, Lithuania",
  "Alzano Lombardo, Italy",
  "Alzate Brianza, Italy",
  "Alzenau-Hoerstein, Germany",
  "Alzey, Germany",
  "Alzira, Spain",
  "Amadora, Portugal",
  "Amaliada, Greece",
  "Amantea, Italy",
  "Amanzimtoti, South Africa",
  "Amarante, Portugal",
  "Amares, Portugal",
  "Amargosa Valley, United States",
  "Amarillo, United States",
  "Amaro, Italy",
  "Ambares et Lagrave, France",
  "Ambarli Port Istanbul, Turkey",
  "Ambarnath, India",
  "Amberg (bei Buchloe), Germany",
  "Amberg (Oberpf), Germany",
  "Ambergate, United Kingdom",
  "Ambert, France",
  "Ambes, France",
  "Amblainville, France",
  "Ambra, Italy",
  "Ambridge, United States",
  "Amecameca, Mexico",
  "Amerang, Germany",
  "Amerdingen, Germany",
  "Amersfoort, Netherlands",
  "Amersham, United Kingdom",
  "Ames, Spain",
  "Amesbury, United Kingdom",
  "Ametlla del Valles, Spain",
  "Amezketa, Spain",
  "Amezqueta, Spain",
  "Amherst, United States",
  "Amiens, France",
  "Amilly, France",
  "Amingaon, India",
  "Amlwch, United Kingdom",
  "Amman, Jordan",
  "Ammanford, United Kingdom",
  "Ammerbuch, Germany",
  "Ammerndorf, Germany",
  "Ammersbek, Germany",
  "Amorbach, Germany",
  "Amorebieta, Spain",
  "Amoroto Eleizalde, Spain",
  "Ampfing, Germany",
  "Ampflwang Im Hausruckwald, Austria",
  "Amphion les Bains, France",
  "Amplepuis, France",
  "Amposta, Spain",
  "Ampthill, United Kingdom",
  "Ampuis, France",
  "Amriswil, Switzerland",
  "Amritsar, India",
  "Amsdorf, Germany",
  "Amstelveen, Netherlands",
  "Amsterdam, Netherlands",
  "Amstetten, Germany",
  "Amt Wachsenburg, Germany",
  "Amta, India",
  "Amtzell, Germany",
  "Amurrio, Spain",
  "Amusco, Spain",
  "Amuwo-Odofin, Nigeria",
  "An Duong, Vietnam",
  "An Seong, Korea, South",
  "Anaconda, United States",
  "Anadia, Portugal",
  "Anagni, Italy",
  "Anaheim, United States",
  "Anapolis, Brazil",
  "Ancarano, Italy",
  "Ancaster Lincolnshire, United Kingdom",
  "Ancenis, France",
  "Anchianlos Thessalonikis, Greece",
  "Ancizes Comps, France",
  "Ancona, Italy",
  "Ancram, United States",
  "Ancud, Chile",
  "Andalo Valtellino, Italy",
  "Andancette, France",
  "Andelst, Netherlands",
  "Andenne, Belgium",
  "Anderlecht, Belgium",
  "Andernach, Germany",
  "Anderson, United States",
  "Anderstorp, Sweden",
  "Andezeno, Italy",
  "Andijk, Netherlands",
  "Andoain, Spain",
  "Andosilla, Spain",
  "Andover, United Kingdom",
  "Andover, United States",
  "Andrews, United States",
  "Andrezieux Boutheon, France",
  "Andria, Italy",
  "Andujar, Spain",
  "Anduze, France",
  "Aneby, Sweden",
  "Angera, Italy",
  "Angers, France",
  "Angerville Essonne, France",
  "Angerville la Campagne, France",
  "Anghiari, Italy",
  "Angiari, Italy",
  "Angier, United States",
  "Anglefort, France",
  "Anglesola, Spain",
  "Anglet, France",
  "Angmering West Sussex BN, United Kingdom",
  "Angola, United States",
  "Angondje, Gabon",
  "Angouleme, France",
  "Angres, France",
  "Angresse, France",
  "Angri, Italy",
  "Anhalt Sued, Germany",
  "Anhausen, Germany",
  "Anhee, Belgium",
  "Aniche, France",
  "Anif, Austria",
  "Aninoasa - Hunedoara, Romania",
  "Anita, Italy",
  "Anixi, Greece",
  "Anjou, Canada",
  "Ankara, Turkey",
  "Ankaran, Slovenia",
  "Anklam, Germany",
  "Ankleshwar, India",
  "Ankum, Germany",
  "Anleo, Spain",
  "Ann Arbor, United States",
  "Annaba, Algeria",
  "Annaberg Buchholz, Germany",
  "Annaburg, Germany",
  "Annacotty Co Limerick, Ireland",
  "Annan, United Kingdom",
  "Annecy, France",
  "Annemasse, France",
  "Annesley Nottinghamshire, United Kingdom",
  "Anneville sur Scie, France",
  "Anneyron, France",
  "Annezin, France",
  "Annicco, Italy",
  "Anniston, United States",
  "Annonay, France",
  "Annone di Brianza, Italy",
  "Annone Veneto, Italy",
  "Annopol, Poland",
  "Annweiler (am Trifels), Germany",
  "Anoeta, Spain",
  "Anorbe, Spain",
  "Anover de Tajo, Spain",
  "Anqing, China",
  "Anroechte, Germany",
  "Ans, Denmark",
  "Ansan, Korea, South",
  "Ansbach, Germany",
  "Anse, France",
  "Antakya, Turkey",
  "Antalya, Turkey",
  "Antananarivo, Madagascar",
  "Antegnate, Italy",
  "Antequera, Spain",
  "Anteuil, France",
  "Antigua Guatemala, Guatemala",
  "Antiguo Cuscatlan, El Salvador",
  "Antofagasta, Chile",
  "Antony, France",
  "Antrim Northern Ireland, United Kingdom",
  "Antula, Guinea-Bissau",
  "Antunez, Mexico",
  "Antwerp, Belgium",
  "Antzuola, Spain",
  "Anzano del Parco, Italy",
  "Anzegem, Belgium",
  "Anzin, France",
  "Anzio, Italy",
  "Anzola dell Emilia, Italy",
  "Aoiz, Spain",
  "Apace, Slovenia",
  "Apapa, Nigeria",
  "Apaseo el Grande, Mexico",
  "Apatzingan, Mexico",
  "Apecchio, Italy",
  "Apeldoorn, Netherlands",
  "Apensen, Germany",
  "Apia, Samoa",
  "Apiro, Italy",
  "Apodaca, Mexico",
  "APODI, Brazil",
  "Apolda, Germany",
  "Apopa, El Salvador",
  "Appenweier, Germany",
  "Appenzell, Switzerland",
  "Appiano Gentile, Italy",
  "Appiano Sulla Strada Del Vino, Italy",
  "Appignano, Italy",
  "Appilly, France",
  "Appleby Cumbria, United Kingdom",
  "Appledore Devon, United Kingdom",
  "Appleton, United States",
  "Appleton ThornWarrington WA, United Kingdom",
  "Aprilci, Bulgaria",
  "Aprilia, Italy",
  "Apt, France",
  "Apucarana, Brazil",
  "Aqaba, Jordan",
  "Arab, United States",
  "Arad, Israel",
  "Arad, Romania",
  "Arafo, Spain",
  "Araguari, Brazil",
  "Arahal, Spain",
  "Araia, Spain",
  "Arakkonam, India",
  "Aranda de Duero, Spain",
  "Arandas, Mexico",
  "Aranguiz, Spain",
  "Aranguren Vizcaya, Spain",
  "Aranjuez, Spain",
  "ARAPILES, Spain",
  "Araraquara, Brazil",
  "Araras, Brazil",
  "Araucaria, Brazil",
  "Araxa, Brazil",
  "Arazuri, Spain",
  "Arbeca, Spain",
  "Arbent, France",
  "Arbizu, Spain",
  "Arbizzano, Italy",
  "Arboleas, Spain",
  "Arbon, Switzerland",
  "Arborfield, United Kingdom",
  "Arbos, Spain",
  "Arbroath, United Kingdom",
  "Arbuckle, United States",
  "Arc les Gray, France",
  "Arc sur Tille, France",
  "Arcade, Italy",
  "Arcadia, United States",
  "Arcata, United States",
  "Arceniega, Spain",
  "Arcevia, Italy",
  "Archbald, United States",
  "Archbold, United States",
  "Archena, Spain",
  "Arches, France",
  "Archidona, Spain",
  "Arcicollar, Spain",
  "Arcisate, Italy",
  "Arco, Italy",
  "Arcola, Italy",
  "Arcole, Italy",
  "Arconate, Italy",
  "Arconnay, France",
  "Arcore, Italy",
  "Arcos de Jalon, Spain",
  "Arcos de la Frontera, Spain",
  "Arcugnano, Italy",
  "Ardea, Italy",
  "Ardenno, Italy",
  "Ardesio, Italy",
  "Ardon, Switzerland",
  "Ardoncino, Spain",
  "Ardooie, Belgium",
  "Ardwick Manchester, United Kingdom",
  "Arena Po, Italy",
  "Arendonk, Belgium",
  "Arendsee (Altmark), Germany",
  "Arenillas de Muno, Spain",
  "Arenzana de Abajo, Spain",
  "Arenzano, Italy",
  "Arese, Italy",
  "Aresing, Germany",
  "Areso, Spain",
  "Areta, Spain",
  "Aretxabaleta Guipuzcoa, Spain",
  "Arevalo, Spain",
  "Arezzo, Italy",
  "Argame, Spain",
  "Arganda, Spain",
  "Argelaguer, Spain",
  "Argelato, Italy",
  "Argenbuehl, Germany",
  "Argenta, Italy",
  "Argenteuil, France",
  "Argentona, Spain",
  "Argentre du Plessis, France",
  "Argoeuves, France",
  "Argonay, France",
  "Argos, Greece",
  "Argos, United States",
  "Arguedas, Spain",
  "Ariano Irpino, Italy",
  "Arica, Chile",
  "Ariccia, Italy",
  "Aricestii Rahtivani, Romania",
  "ARIESTOLAS, Spain",
  "Arifjan, Kuwait",
  "Arinaga, Spain",
  "Arino di Dolo, Italy",
  "Arinthod, France",
  "Arkas Korfez, Turkey",
  "Arlanda stad, Sweden",
  "Arles, France",
  "Arlesey, United Kingdom",
  "Arlesheim, Switzerland",
  "Arleta, United States",
  "Arlington, United States",
  "Arlon, Belgium",
  "Arluno, Italy",
  "Arma Di Taggia, Italy",
  "Armagh Northern Ireland, United Kingdom",
  "Armavir, Armenia",
  "Armenia, El Salvador",
  "Armutlu-Izmir, Turkey",
  "Arnac Pompadour, France",
  "Arnage, France",
  "Arnas, France",
  "Arnavutkoy, Turkey",
  "Arnay le Duc, France",
  "Arneburg, Germany",
  "Arnedo, Spain",
  "Arnhem, Netherlands",
  "Arnoldstein, Austria",
  "Arnsberg, Germany",
  "Arnstadt, Germany",
  "Arnstein (Unterfr), Germany",
  "Arnstorf, Germany",
  "Arolsen, Germany",
  "Arona, Italy",
  "Aroor, India",
  "Arosio, Italy",
  "Arpajon, France",
  "Arqua Polesine, Italy",
  "Arquata Scrivia, Italy",
  "Arques Pas de Calais, France",
  "Arrabaldo, Spain",
  "Arrabloy, France",
  "Arraijan-Panama, Panama",
  "Arrankudiaga, Spain",
  "Arras, France",
  "Arre, Italy",
  "Arre, Spain",
  "Arrentela, Portugal",
  "Arrifana, Portugal",
  "Arrigorriaga, Spain",
  "Arrona, Spain",
  "Arronville, France",
  "Arroyo de Cuellar Segovia, Spain",
  "Arroyo De La Encomienda, Spain",
  "Arroyo de San Servan, Spain",
  "Arroyomolinos, Spain",
  "Arrubal, Spain",
  "Ars Charente, France",
  "Arsago Seprio, Italy",
  "Arsie, Italy",
  "Arsiero, Italy",
  "Arta, Greece",
  "Artana, Spain",
  "Artea, Spain",
  "Arteaga, Mexico",
  "Artegna, Italy",
  "Arteixo, Spain",
  "Artena, Italy",
  "Artenay, France",
  "Artern, Germany",
  "Artix, France",
  "Artur Nogueira, Brazil",
  "Artziniega, Spain",
  "Arundel, United Kingdom",
  "Arveyres, France",
  "Arzago d adda, Italy",
  "Arzano, Italy",
  "Arzberg (Oberfr ), Germany",
  "Arzens, France",
  "Arzergrande, Italy",
  "Arzignano, Italy",
  "As, Czech Republic",
  "As Bouzas, Spain",
  "As Pontes, Spain",
  "Asan, Korea, South",
  "Asbach (Westerwald), Germany",
  "Asbach Baeumenheim, Germany",
  "Asbury, United States",
  "Aschach an der Donau, Austria",
  "Aschaffenburg, Germany",
  "Aschau a Inn, Germany",
  "Aschbach-Markt, Austria",
  "Ascheberg Westf, Germany",
  "Aschersleben (Sachs-Anh), Germany",
  "Aschheim, Germany",
  "Ascoli Piceno, Italy",
  "Ascot, United Kingdom",
  "Asenovgrad, Bulgaria",
  "Asfeld, France",
  "Ash Kent, United Kingdom",
  "Ash Vale Surrey, United Kingdom",
  "Ashalim, Israel",
  "Ashbocking, United Kingdom",
  "Ashbourne, United Kingdom",
  "Ashburn, United States",
  "Ashby-de-la-Zouch, United Kingdom",
  "Ashdod, Israel",
  "Asheboro, United States",
  "Asheville, United States",
  "Ashford Kent, United Kingdom",
  "Ashford Surrey, United Kingdom",
  "Ashington Northumberland, United Kingdom",
  "Ashland, United States",
  "Ashland City, United States",
  "Ashqelon, Israel",
  "Ashtabula, United States",
  "Ashtead Surrey, United Kingdom",
  "Ashton, United States",
  "Ashton in Makerfield, United Kingdom",
  "Ashton-under-Lyne, United Kingdom",
  "Ashulia, Bangladesh",
  "Askaoun, Morocco",
  "Asker, Norway",
  "Askern, United Kingdom",
  "Asnaes, Denmark",
  "Asola, Italy",
  "Asolo, Italy",
  "Aspach, Germany",
  "Aspatria, United Kingdom",
  "Aspe, Spain",
  "Asperg, Germany",
  "Aspers, United States",
  "Aspropirgos, Greece",
  "Assago, Italy",
  "Assen, Netherlands",
  "Assendelft, Netherlands",
  "Assens, Denmark",
  "Assiros, Greece",
  "Assisi, Italy",
  "Assiut, Egypt",
  "Asslar, Germany",
  "Astakos, Greece",
  "Asteasu, Spain",
  "Asti, Italy",
  "Astigarraga, Spain",
  "Astillero, Spain",
  "Astley Green, United Kingdom",
  "Astley Worcestershire, United Kingdom",
  "Aston, United States",
  "Aston Clinton Buckinghamshire, United Kingdom",
  "Astudillo, Spain",
  "Asua, Spain",
  "Asuncion, Paraguay",
  "Asuncion Mita, Guatemala",
  "ATALANTI, Greece",
  "Atarfe, Spain",
  "Atchison, United States",
  "Ateca, Spain",
  "Atessa, Italy",
  "Athboy Meath, Ireland",
  "Athens, Greece",
  "Athens, United States",
  "Atherstone, United Kingdom",
  "Atherton, United Kingdom",
  "Athies sous Laon, France",
  "Athis de l'Orne, France",
  "Athis Mons, France",
  "Athus, Belgium",
  "Athy Kildare, Ireland",
  "Atienza, Spain",
  "Atitalaquia, Mexico",
  "Atkarsk, Russia",
  "Atlanta, United States",
  "Atlanta Fairburn, United States",
  "Atmore, United States",
  "Atotonilco, Mexico",
  "Atotonilco de Tula, Mexico",
  "Atri, Italy",
  "Attendorn, Germany",
  "Attimis, Italy",
  "Attleborough Norfolk, United Kingdom",
  "Attleborough Warwickshire, United Kingdom",
  "Attnang Puchheim, Austria",
  "Atwater, United States",
  "Atxondo, Spain",
  "Atzeneta de Albaida, Spain",
  "Au (i d Hallertau), Germany",
  "Aubagne, France",
  "Aubange, Belgium",
  "Aubel, Belgium",
  "Aubenas, France",
  "Aubergenville, France",
  "Aubervilliers, France",
  "Aubevoye, France",
  "Aubigny Somme, France",
  "Auburn, United States",
  "Auburndale, United States",
  "Aubusson, France",
  "Aucamville, France",
  "Auchel, France",
  "Auchterarder, United Kingdom",
  "Auckland, New Zealand",
  "Auckland Metroport, New Zealand",
  "Audenshaw Manchester M, United Kingdom",
  "Aue (Sachsen), Germany",
  "Auenwald, Germany",
  "Auerbach (Erzgeb), Germany",
  "Auerbach (Oberfalz), Germany",
  "Aufhausen, Germany",
  "Augsburg, Germany",
  "Augusta, Italy",
  "Augusta, United States",
  "Augustdorf, Germany",
  "Augustow k Belchatow, Poland",
  "Aukrug, Germany",
  "Aulendorf, Germany",
  "Aulnay sous Bois, France",
  "Aulnay-la-Riviere, France",
  "AUNAC, France",
  "Auneau, France",
  "Aurangabad, India",
  "Aurec sur Loire, France",
  "Aurich (Ostfriesland), Germany",
  "Aurolzmuenster, Austria",
  "Aurora, United States",
  "Ausonia, Italy",
  "Austin, United States",
  "Auterive, France",
  "Autol, Spain",
  "Autun, France",
  "Auxerre, France",
  "Auxonne, France",
  "Auzat, France",
  "Ava, United States",
  "Avanca, Portugal",
  "Avcilar, Turkey",
  "Aveiras de Cima, Portugal",
  "Aveiro, Portugal",
  "Avelar, Portugal",
  "Aveleda, Portugal",
  "Aveley, United Kingdom",
  "Avelgem, Belgium",
  "Avelin, France",
  "Avellino, Italy",
  "Avenches, Switzerland",
  "Avenel, United States",
  "Avenza, Italy",
  "Avermes, France",
  "Aversa, Italy",
  "Avesnes le Comte, France",
  "Avesta, Sweden",
  "Avezzano, Italy",
  "Avgorou, Cyprus",
  "Aviano, Italy",
  "Aviemore, United Kingdom",
  "Avigliana, Italy",
  "Avignon, France",
  "Avila, Spain",
  "Aviles, Spain",
  "Avinyo, Spain",
  "Avinyonet del Penedes, Spain",
  "Avis, Portugal",
  "Avlonas, Greece",
  "Avoca, South Africa",
  "Avondale, United States",
  "Avonmouth, United Kingdom",
  "Avontuur, South Africa",
  "Avrainville, France",
  "Avrille Maine et Loire, France",
  "Axioupoli, Greece",
  "Axis, United States",
  "Axminster, United Kingdom",
  "Ayamonte, Spain",
  "Ayazaga, Turkey",
  "Aycliffe Durham, United Kingdom",
  "Aydin, Turkey",
  "Ayegui, Spain",
  "Ayer, United States",
  "Ayguesvives, France",
  "Aying, Germany",
  "Aylesbury, United Kingdom",
  "Aylesford Kent ME, United Kingdom",
  "Aylesham, United Kingdom",
  "Ayr, United Kingdom",
  "Ayutthaya, Thailand",
  "Azagra, Spain",
  "Azambuja, Portugal",
  "Aze, France",
  "Azeglio, Italy",
  "Azemmour, Morocco",
  "Azkoitia, Spain",
  "Aznalcazar, Spain",
  "Aznalcollar, Spain",
  "Azov, Russia",
  "Azpeitia, Spain",
  "Azuqueca Henares, Spain",
  "Azusa, United States",
  "Azzano Decimo, Italy",
  "Azzano Mella, Italy",
  "Azzano San Paolo, Italy",
  "Azzate, Italy",
  "Babahoyo - Los Rios, Ecuador",
  "Babenhausen, Germany",
  "Babensham, Germany",
  "Babice, Czech Republic",
  "Babylon, United States",
  "Bacau, Romania",
  "Bachupally, India",
  "Backa Palanka, Serbia",
  "Backnang, Germany",
  "Backowice, Poland",
  "Bacup, United Kingdom",
  "Bad Aibling, Germany",
  "Bad Bentheim, Germany",
  "Bad Bergzabern, Germany",
  "Bad Berleburg, Germany",
  "Bad Berneck, Germany",
  "Bad Birnbach, Germany",
  "Bad Boll, Germany",
  "Bad Camberg, Germany",
  "Bad Driburg, Germany",
  "Bad Duerkheim, Germany",
  "Bad Duerrheim, Germany",
  "Bad Ems, Germany",
  "Bad Essen, Germany",
  "BAD FALLINGBOSTEL, Germany",
  "Bad Feilnbach, Germany",
  "Bad Friedrichshall, Germany",
  "Bad Gandersheim, Germany",
  "Bad Gottleuba, Germany",
  "Bad Groenenbach, Germany",
  "Bad Hall, Austria",
  "Bad Hersfeld, Germany",
  "Bad Hoenningen, Germany",
  "Bad Homburg (v d Hoehe), Germany",
  "Bad Honnef, Germany",
  "Bad Kissingen, Germany",
  "Bad Koenigshofen, Germany",
  "Bad Koestritz, Germany",
  "Bad Kreuznach, Germany",
  "Bad Krozingen, Germany",
  "Bad Laer, Germany",
  "Bad Langensalza, Germany",
  "Bad Lauterberg (im Harz), Germany",
  "Bad Leonfelden, Austria",
  "Bad Liebenwerda, Germany",
  "Bad Liebenzell, Germany",
  "Bad Lobenstein, Germany",
  "Bad Laasphe, Germany",
  "Bad Marienberg (Westerwal, Germany",
  "Bad Mergentheim, Germany",
  "Bad Muender (am Deister), Germany",
  "Bad Muenstereifel, Germany",
  "Bad Nenndorf, Germany",
  "Bad Neustadt, Germany",
  "Bad Oeynhausen, Germany",
  "Bad Oldesloe, Germany",
  "Bad Rappenau, Germany",
  "Bad Reichenhall, Germany",
  "Bad Rodach, Germany",
  "Bad Saeckingen, Germany",
  "Bad Salzdetfurth, Germany",
  "Bad Salzschlirf, Germany",
  "Bad Salzuflen, Germany",
  "Bad Salzungen, Germany",
  "Bad Saulgau, Germany",
  "Bad Schandau, Germany",
  "BAD SCHLEMA, Germany",
  "Bad Schoenborn, Germany",
  "Bad Schussenried, Germany",
  "Bad Schwartau, Germany",
  "Bad Sobernheim, Germany",
  "Bad Soden-Salmuenster, Germany",
  "Bad Staffelstein (Oberfr), Germany",
  "Bad Saarow, Germany",
  "Bad Tennstedt, Germany",
  "Bad Ueberkingen, Germany",
  "Bad Waldsee, Germany",
  "Bad Wildungen, Germany",
  "Bad Wimsbach-Neydharting, Austria",
  "Bad Woerishofen, Germany",
  "Bad Wuennenberg, Germany",
  "Bad Wurzach, Germany",
  "Badajoz, Spain",
  "Badalona, Spain",
  "Baden-Baden, Germany",
  "Badenheim, Germany",
  "Badenweiler, Germany",
  "Badhoevedorp, Netherlands",
  "Badia a Settimo, Italy",
  "Badia al Pino, Italy",
  "Badia Calavena, Italy",
  "Badia Polesine, Italy",
  "Badinieres, France",
  "Badoere di Morgano, Italy",
  "BADR CITY, Egypt",
  "Baena, Spain",
  "Baeretswil, Switzerland",
  "Baesweiler, Germany",
  "Bagaces, Costa Rica",
  "Bagcilar, Turkey",
  "Bagenalstown, Ireland",
  "Baggiovara, Italy",
  "Baglan, United Kingdom",
  "Bagnacavallo, Italy",
  "Bagnara di Romagna, Italy",
  "Bagnasco, Italy",
  "Bagnatica, Italy",
  "Bagneux, France",
  "Bagni di Lucca, Italy",
  "Bagni di Tivoli, Italy",
  "Bagno, Italy",
  "Bagno a Ripoli, Italy",
  "Bagnolet, France",
  "Bagnoli di Sopra, Italy",
  "Bagnolo Cremasco, Italy",
  "Bagnolo in Piano, Italy",
  "Bagnolo Mella, Italy",
  "Bagnolo San Vito, Italy",
  "Bagolino, Italy",
  "Bagram, Afghanistan",
  "Bagram Via Chaman, Afghanistan",
  "Bagshot Surrey, United Kingdom",
  "Bagsvaerd, Denmark",
  "Baguim do Monte, Portugal",
  "Bagyurdu, Turkey",
  "Bahia Blanca, Argentina",
  "Bahlingen, Germany",
  "Bahrain, Bahrain",
  "Baienfurt, Germany",
  "Baiersbronn, Germany",
  "Bailen, Spain",
  "Bailieborough Cavan, Ireland",
  "Baillargues, France",
  "Baille, France",
  "Baillet en France, France",
  "Bain de Bretagne, France",
  "Bais Ille et Vilaine, France",
  "Baiso, Italy",
  "Bakar, Croatia",
  "Bakewell, United Kingdom",
  "Bakirkoy, Turkey",
  "Bakov nad Jizerou, Czech Republic",
  "Baku, Azerbaijan",
  "Bakum (Kr Vechta), Germany",
  "Balaguer, Spain",
  "Balan Ain, France",
  "Balangero, Italy",
  "Balashikha, Russia",
  "Balasore, India",
  "Balazote, Spain",
  "Balboa, Panama",
  "Balbriggan Dublin, Ireland",
  "Balchik, Bulgaria",
  "Baldegg, Switzerland",
  "Baldersheim, France",
  "Baldissero d'Alba, Italy",
  "Baldock, United Kingdom",
  "Baldwin Park, United States",
  "Baldwinsville, United States",
  "Balen, Belgium",
  "Balenya, Spain",
  "Balestrand, Norway",
  "Balikesir, Turkey",
  "Balikpapan, Indonesia",
  "Balingen, Germany",
  "Balk, Netherlands",
  "Ball Ground, United States",
  "Ballabio, Italy",
  "Ballainvilliers, France",
  "Ballancourt sur Essonne, France",
  "Ballee, France",
  "Ballenstedt, Germany",
  "Ballina Mayo, Ireland",
  "Balling, Denmark",
  "Ballobar, Spain",
  "Ballon Co Carlow, Ireland",
  "Ballyconnell Cavan, Ireland",
  "Ballygawley Northern Ireland, United Kingdom",
  "Ballygowan Northern Ireland, United Kingdom",
  "Ballyhaunis Mayo, Ireland",
  "Ballyjamesduff, Ireland",
  "Ballymena Northern Ireland, United Kingdom",
  "Ballymoney Northern Ireland, United Kingdom",
  "Ballymount Dublin, Ireland",
  "Ballynahinch, United Kingdom",
  "Balmedie, United Kingdom",
  "Balocco, Italy",
  "Balozi, Latvia",
  "Balsall Common, United Kingdom",
  "Balsapintada, Spain",
  "Baltar, Portugal",
  "Baltimore, United States",
  "Baltmannsweiler, Germany",
  "Balvano, Italy",
  "Balve, Germany",
  "balzola, Italy",
  "Bamako, Mali",
  "Bamber Bridge, United Kingdom",
  "Bamberg, Germany",
  "Bamberg, United States",
  "Bammental, Germany",
  "Banbridge Northern Ireland, United Kingdom",
  "Banbury, United Kingdom",
  "Banchory, United Kingdom",
  "Bancroft, Canada",
  "Bandar Abbas, Iran",
  "Bandar Puncak Alam, Malaysia",
  "Bandhagen, Sweden",
  "Bandim, Guinea-Bissau",
  "Bandirma, Turkey",
  "Bando, Italy",
  "Banff Aberdeenshir, United Kingdom",
  "Bangalore, India",
  "Bangkok, Thailand",
  "Bangor - Gwynedd, United Kingdom",
  "Bangor Northern Ireland, United Kingdom",
  "Bangpakong, Thailand",
  "Banha, Egypt",
  "Banja Luka, Bosnia and Herzegovina",
  "Banjul, Gambia",
  "Bannberscheid, Germany",
  "Banos de Rio Tobia, Spain",
  "Banos y Mendigo, Spain",
  "Banovce Nad Bebravou, Slovakia",
  "Banska Bystrica, Slovakia",
  "Banska Stiavnica, Slovakia",
  "Banstead, United Kingdom",
  "Bant, Netherlands",
  "Banyeres de Mariola, Spain",
  "Banyoles, Spain",
  "Bao An, China",
  "Bapaume, France",
  "Bapulapadu, India",
  "Bar, Montenegro",
  "Bar sur Seine, France",
  "Barajas, Spain",
  "Barakaldo, Spain",
  "Baramati, India",
  "Baranovichi, Belarus",
  "Baranow, Poland",
  "Baranzate, Italy",
  "Barasso, Italy",
  "Barauna, Brazil",
  "Barbara, Italy",
  "Barbarano Vicentino, Italy",
  "Barbariga, Italy",
  "Barbastro, Spain",
  "Barbata, Italy",
  "Barbatain, Spain",
  "Barbate, Spain",
  "Barbera del Valles, Spain",
  "BARBEREY-SAINT-SULPICE, France",
  "Barberino di Mugello, Italy",
  "Barberino Val d'Elsa, Italy",
  "Barberton, United States",
  "Barbiano di Ravenna, Italy",
  "Barbing, Germany",
  "Barbisano, Italy",
  "Barby, France",
  "Barby, Germany",
  "Barcarena, Portugal",
  "Barcellona Pozzo di Gotto, Italy",
  "Barcelona, Spain",
  "Barcelos, Portugal",
  "Barcena de Cicero, Spain",
  "Barchfeld (Werra), Germany",
  "BARCO VALDEORRAS, Spain",
  "Bardejov, Slovakia",
  "Bardenas, Spain",
  "Bardhaman, India",
  "Bardney, United Kingdom",
  "Bardolino, Italy",
  "Bardowick, Germany",
  "Bardstown, United States",
  "Bareggio, Italy",
  "Barembach, France",
  "Barendrecht, Netherlands",
  "Barge, Italy",
  "Barghe, Italy",
  "Bari, Italy",
  "Barkan, Israel",
  "Barking London, United Kingdom",
  "Barkaaker, Norway",
  "Barlad, Romania",
  "Barlassina, Italy",
  "Barlborough, United Kingdom",
  "Barleben, Germany",
  "Barletta, Italy",
  "Barmstedt, Germany",
  "Barnard Castle, United Kingdom",
  "Barnesville, United States",
  "Barnet, United Kingdom",
  "Barnetby, United Kingdom",
  "Barneveld, Netherlands",
  "Barnoldswick, United Kingdom",
  "Barnsley Yorkshire, United Kingdom",
  "Barnstaple, United Kingdom",
  "Barnstone Nottinghamshire, United Kingdom",
  "Barnstorf (Kr Diepholz), Germany",
  "Barntrup, Germany",
  "Baroda, India",
  "Barolo, Italy",
  "Baronissi, Italy",
  "Barr, France",
  "Barranquilla, Colombia",
  "Barrie, Canada",
  "Barrit, Denmark",
  "Barro, Portugal",
  "Barros, Spain",
  "Barrow In Furness, United Kingdom",
  "Barry Wales, United Kingdom",
  "Barsac, France",
  "Barsbuettel, Germany",
  "Barstow, United States",
  "Bartin, Turkey",
  "Bartlett, South Africa",
  "Barton Hampshire, United Kingdom",
  "Barton Under Needwood, United Kingdom",
  "Barton Upon Humber, United Kingdom",
  "Bartoszyce, Poland",
  "Bartow, United States",
  "Baruccana, Italy",
  "Baruth (Mark), Germany",
  "Barwell, United Kingdom",
  "Barxeta, Spain",
  "Barzago, Italy",
  "Barzana, Italy",
  "BarzanoLC, Italy",
  "BASAKSEHIR, Turkey",
  "Basaldella, Italy",
  "Basaluzzo, Italy",
  "Basauri Vizcaya, Spain",
  "Bascharage, Luxemburg",
  "Basciano, Italy",
  "Basel, Switzerland",
  "Basel Schweizerhalle, Switzerland",
  "Basiano, Italy",
  "Basildon Essex, United Kingdom",
  "Basiliano, Italy",
  "Basilicagoiano, Italy",
  "Basilicanova, Italy",
  "Basingstoke, United Kingdom",
  "Basni, India",
  "Bassano del Grappa, Italy",
  "Basse Ham, France",
  "Bassens, France",
  "Bassersdorf, Switzerland",
  "Bassone, Italy",
  "Bassum, Germany",
  "Bastia di Rovolon, Italy",
  "Bastia Umbra, Italy",
  "Bastiglia, Italy",
  "Bata, Equatorial Guinea",
  "Batalha, Portugal",
  "Batam Island, Indonesia",
  "Batangas, Philippines",
  "Batavia, United States",
  "Batesville, United States",
  "Bath, United Kingdom",
  "Bath, United States",
  "Bathford, United Kingdom",
  "Bathgate, United Kingdom",
  "Batilly Meurthe et Moselle, France",
  "Batley, United Kingdom",
  "Baton Rouge, United States",
  "Batonyterenye, Hungary",
  "Battice, Belgium",
  "Battipaglia, Italy",
  "Battle Creek, United States",
  "Battleboro, United States",
  "Batu Pahat, Malaysia",
  "Baumgartenberg, Austria",
  "Baumholder, Germany",
  "Baunach, Germany",
  "Bauru, Brazil",
  "Bautzen, Germany",
  "Bauxite, United States",
  "Bavegem, Belgium",
  "Baveno, Italy",
  "Bavikhove, Belgium",
  "A Estrada, Spain",
  "Bay Saint Louis, United States",
  "Bayeux, France",
  "Bayhead, South Africa",
  "Bayonne, France",
  "Bayonne, United States",
  "Bayou la Batre, United States",
  "Bayport, United States",
  "Bayrampasa, Turkey",
  "Bayreuth, Germany",
  "Bayside, Canada",
  "Baytown, United States",
  "Baza, Spain",
  "Bazanowice, Poland",
  "Bazeilles, France",
  "Bazougers, France",
  "Bazzano, Italy",
  "Beaconsfield, United Kingdom",
  "Bear, United States",
  "Beard Geovreissiat, France",
  "Beasain, Spain",
  "Beasley, United States",
  "Beaucaire, France",
  "Beauceville, Canada",
  "Beaucouze, France",
  "Beaufort en Vallee, France",
  "Beaumont, United States",
  "Beaune, France",
  "Beaupreau, France",
  "Beaurainville, France",
  "Beaurepaire, France",
  "Beauvais Essonne, France",
  "Beauvais Oise, France",
  "Beauvoir sur Mer, France",
  "Beaverton, United States",
  "Bebra, Germany",
  "Beccles, United Kingdom",
  "Becerril de Campos, Spain",
  "Bechyne, Czech Republic",
  "Beckenham, United Kingdom",
  "Beckingen, Germany",
  "Beckton, United Kingdom",
  "Beckum, Germany",
  "Bedale North Yorkshire, United Kingdom",
  "Bedburg (Erft), Germany",
  "Bedfont London, United Kingdom",
  "Bedford, United Kingdom",
  "Bedford, United States",
  "Bedford Heights, United States",
  "Bedford Park, United States",
  "Bedfordview, South Africa",
  "Bedihost, Czech Republic",
  "Bedizzole, Italy",
  "Bedlington, United Kingdom",
  "Bedlno, Poland",
  "Bedmar, Spain",
  "Bedong, Malaysia",
  "Bedwas, United Kingdom",
  "Bedzin, Poland",
  "Beech Island, United States",
  "Beecher, United States",
  "Beek, Netherlands",
  "Beek (b Doetinchem), Netherlands",
  "Beek en Donk, Netherlands",
  "Beelen, Germany",
  "Beelitz (Mark), Germany",
  "Beenham West Berkshire, United Kingdom",
  "Beernem, Belgium",
  "Beerse, Belgium",
  "Beersheba, Israel",
  "Beeskow, Germany",
  "Beeston Nottinghamshire, United Kingdom",
  "Beetham, United Kingdom",
  "Begles, France",
  "Begonte, Spain",
  "Begunje na Gorenjskem, Slovenia",
  "Behera, Egypt",
  "Behren les Forbach, France",
  "Beihai, China",
  "Beijiao, China",
  "Beilngries, Germany",
  "Beinasco, Italy",
  "Beinheim, France",
  "Beira, Mozambique",
  "Beire, Spain",
  "Beirut, Lebanon",
  "Beit Keshet, Israel",
  "Beit Shemesh, Israel",
  "Beith, United Kingdom",
  "Beja, Portugal",
  "Beja, Tunisia",
  "Bejaia, Algeria",
  "Bejar, Spain",
  "Bekasi, Indonesia",
  "Bel Air Zone A, Senegal",
  "Belapatfalva, Hungary",
  "Belauntza, Spain",
  "Belawan, Indonesia",
  "Belcamp, United States",
  "Belchatow, Poland",
  "Belecin, Poland",
  "Belem do Sao Francisco, Brazil",
  "Belen, United States",
  "Belfast Northern Ireland, United Kingdom",
  "Belfeld, Netherlands",
  "Belfiore d'Adige, Italy",
  "Belford Northumberland NE, United Kingdom",
  "Belfort, France",
  "Belforte Del Chienti, Italy",
  "Belgida, Spain",
  "Belgorod, Russia",
  "Belgorod Dnestrovskiy, Ukraine",
  "Belgrade, Serbia",
  "Belgrave, United Kingdom",
  "Beli Manastir, Croatia",
  "Bell Bay, Australia",
  "Bellagio, Italy",
  "Bellano, Italy",
  "Bellaria Igea Marina, Italy",
  "Bellcaire D'urgell, Spain",
  "Belle Vernon, United States",
  "Bellefonte, United States",
  "Bellegarde sur Valserine, France",
  "Bellenberg, Germany",
  "Bellerive sur Allier, France",
  "Belleville, Canada",
  "Belleville, France",
  "Belleville, United States",
  "Bellevue, United States",
  "Belley, France",
  "Bellheim, Germany",
  "Bellignat, France",
  "Bellinge, Denmark",
  "Bellinzago Novarese, Italy",
  "Bellizzi, Italy",
  "Bellocchi, Italy",
  "Belloy-en-France, France",
  "Bellpuig, Spain",
  "Bellreguard, Spain",
  "Bellshill Scotland, United Kingdom",
  "Belluno, Italy",
  "Bellusco, Italy",
  "Bellvei Tarragona, Spain",
  "Bellville, South Africa",
  "Bellvis Lleida, Spain",
  "Bellwood, United States",
  "Belm, Germany",
  "Belmont, Canada",
  "Belmont, United States",
  "Belmonte, Spain",
  "Beloeil, Belgium",
  "Belogradchik, Bulgaria",
  "Belorado, Spain",
  "Belpasso, Italy",
  "Belper, United Kingdom",
  "Belsk Duzy, Poland",
  "Beltinci, Slovenia",
  "Belton, United States",
  "Belusa, Slovakia",
  "Belvedere, United Kingdom",
  "Belvedere di Tezze, Italy",
  "Belvidere, United States",
  "Belview Port Co Kilkenny, Ireland",
  "Belvis de La Jara toledo, Spain",
  "Belzec, Poland",
  "Ben Arous, Tunisia",
  "Ben Schoeman Dock, South Africa",
  "Benaguasil, Spain",
  "Benahadux, Spain",
  "Benatky Nad Jizerou, Czech Republic",
  "Benavente, Portugal",
  "Benavente Zamora, Spain",
  "Benavites, Spain",
  "Benbecula, United Kingdom",
  "Bencatel, Portugal",
  "Bendern, Liechtenstein",
  "Bendorf (Kr Mayen-Koblenz, Germany",
  "Bene Vagienna, Italy",
  "Beneden-Leeuwen, Netherlands",
  "Benedita, Portugal",
  "Beneixida, Spain",
  "Benejuzar, Spain",
  "Benesov-U-Prahy, Czech Republic",
  "Benestroff, France",
  "Benevento, Italy",
  "Benfleet, United Kingdom",
  "Benghazi, Libya",
  "Beni khalled, Tunisia",
  "Beni Mellal, Morocco",
  "Beni Suef, Egypt",
  "Beniajan, Spain",
  "Benicarlo, Spain",
  "BENICOLET, Spain",
  "Beniel, Spain",
  "Benifaio, Spain",
  "Benifairo de la Valldigna, Spain",
  "Benimarfull, Spain",
  "Benimodo, Spain",
  "Beningbrough Yorkshire YO, United Kingdom",
  "Beniparrell, Spain",
  "Benisano, Spain",
  "Benissoda, Spain",
  "Benningen, Germany",
  "Bennwihr, France",
  "Benoa, Indonesia",
  "Benoni, South Africa",
  "Bensalem, United States",
  "Bensenville, United States",
  "Bensheim, Germany",
  "Benson, United States",
  "Benthuizen, Netherlands",
  "Bentivoglio, Italy",
  "Benton, United States",
  "Benton Harbor, United States",
  "BERA, Spain",
  "Berango, Spain",
  "Berantevilla, Spain",
  "Berastegui, Spain",
  "Berbenno, Italy",
  "Berbera, Somalia",
  "Berching, Germany",
  "BEREA, United States",
  "Berg (Pfalz), Germany",
  "Berg (Schussental), Germany",
  "Berga, Spain",
  "Bergama, Turkey",
  "Bergamo, Italy",
  "Bergantino, Italy",
  "Bergara, Spain",
  "Bergeijk, Netherlands",
  "Bergen, Netherlands",
  "Bergen, Norway",
  "Bergen op Zoom, Netherlands",
  "Bergheim, Austria",
  "Bergheim (Erftkreis), Germany",
  "Bergisch Gladbach, Germany",
  "Bergkamen, Germany",
  "Bergkirchen, Germany",
  "Bergneustadt, Germany",
  "Bergondo, Spain",
  "Beriain, Spain",
  "Beringe, Netherlands",
  "Beringen, Belgium",
  "Berinsfield, United Kingdom",
  "Berkane, Morocco",
  "Berkatal, Germany",
  "Berkeley Gloucerstershire, United Kingdom",
  "Berkeley Springs, United States",
  "Berkhamsted, United Kingdom",
  "Berkley, United Kingdom",
  "Berkovitza, Bulgaria",
  "Berlin, Germany",
  "Berlin, United States",
  "Berlingerode, Germany",
  "Berlingo, Italy",
  "BERMERICOURT, France",
  "Bern, Switzerland",
  "Bernalda, Italy",
  "Bernalillo, United States",
  "Bernardos, Spain",
  "Bernareggio, Italy",
  "Bernartice Vlasim, Czech Republic",
  "Bernate Ticino, Italy",
  "Bernau im Schwarzwald, Germany",
  "Bernay, France",
  "Bernburg, Germany",
  "Berndorf, Austria",
  "Berne, Germany",
  "Bernedo, Spain",
  "Bernezzo, Italy",
  "Bernhardswald, Germany",
  "Bernkastel-Kues, Germany",
  "Beromuenster, Switzerland",
  "Beroun, Czech Republic",
  "Berovo, Macedonia",
  "Berre l'Etang, France",
  "Berrechid, Morocco",
  "Berriatua, Spain",
  "Berric, France",
  "Berrioplano, Spain",
  "Berriozar, Spain",
  "Berriz, Spain",
  "Bersaillin, France",
  "Bersenbrueck, Germany",
  "Berssel, Germany",
  "Bersteland, Germany",
  "Berthelming, France",
  "Bertinoro, Italy",
  "Bertiolo, Italy",
  "Bertrange, Luxemburg",
  "Berwick upon Tweed, United Kingdom",
  "Berzo Inferiore, Italy",
  "Besalu, Spain",
  "Besana Brianza, Italy",
  "Besancon, France",
  "Beselich (Obertiefenbach), Germany",
  "Besiktas-Istanbul, Turkey",
  "Besnate, Italy",
  "Besozzo, Italy",
  "BESSAN, France",
  "Bessay sur Allier, France",
  "Bessemer, United States",
  "Bessemer City, United States",
  "Bessenbach, Germany",
  "Bessica di Loria, Italy",
  "Bessieres, France",
  "Best, Netherlands",
  "Betanzos, Spain",
  "Betchworth, United Kingdom",
  "Betera, Spain",
  "Bethania Ceredigion, United Kingdom",
  "Betheny, France",
  "Bethlehem, United States",
  "Bethune, France",
  "Bethune, United States",
  "Betliar, Slovakia",
  "Bettembourg, Luxemburg",
  "Bettolle, Italy",
  "Bettona, Italy",
  "Betxi, Spain",
  "Betzenweiler, Germany",
  "Betzigau, Germany",
  "Beuningen, Netherlands",
  "BEVEREN-WAREGEM, Belgium",
  "BEVEREN-WAAS, Belgium",
  "Beverley, United Kingdom",
  "Beverstedt, Germany",
  "Beverungen, Germany",
  "Beverwijk, Netherlands",
  "Bevilacqua, Italy",
  "Bewdley, United Kingdom",
  "Bexley, United Kingdom",
  "Beychac et Caillau, France",
  "Beykoz, Turkey",
  "Beylikduzu, Turkey",
  "Beysehir, Turkey",
  "Bezdekov, Czech Republic",
  "Bezeril, France",
  "Beziers, France",
  "Bezons, France",
  "Bhachau, India",
  "Bhalwal, Pakistan",
  "Bharuch, India",
  "BHILAI, India",
  "Bhimavaram, India",
  "Bhiwadi, India",
  "Bhiwandi, India",
  "Bhusawal, India",
  "Biadene, Italy",
  "Biala, Poland",
  "Biala Podlaska, Poland",
  "Biala Rawska, Poland",
  "Biale Blota, Poland",
  "Bialogard, Poland",
  "Bialystok, Poland",
  "Biancade, Italy",
  "Bianconese di Fontevivo, Italy",
  "Biandrate, Italy",
  "Biandronno, Italy",
  "Bianze, Italy",
  "Biar, Spain",
  "Biars sur Cere, France",
  "Bias, France",
  "Biassono, Italy",
  "Biatorbagy, Hungary",
  "bibano, Italy",
  "Bibbiano, Italy",
  "Bibbiena, Italy",
  "Bibbona LI, Italy",
  "Biberach (an der Riss), Germany",
  "Biberach (Baden), Germany",
  "Biberist, Switzerland",
  "Bibiana, Italy",
  "Bicerova, Turkey",
  "Bicester, United Kingdom",
  "Bicinicco, Italy",
  "Bidart, France",
  "Biddinghuizen, Netherlands",
  "Bideford, United Kingdom",
  "Bidford Warwickshire, United Kingdom",
  "Biebesheim (am Rhein), Germany",
  "Biedenkopf, Germany",
  "Bielany Wroclawskie, Poland",
  "Bielawa, Poland",
  "Bielefeld, Germany",
  "Biella, Italy",
  "Bielsko Biala, Poland",
  "Bieniewo Parcela, Poland",
  "Bientina, Italy",
  "Bierun Stary, Poland",
  "Bierutow, Poland",
  "Biervliet, Netherlands",
  "Biesheim, France",
  "Biessenhofen, Germany",
  "Bietigheim (Baden), Germany",
  "Bietigheim-Bissingen, Germany",
  "Big Spring, United States",
  "Biga, Turkey",
  "Bigastro, Spain",
  "Biggleswade, United Kingdom",
  "Bigues, Spain",
  "Bihac, Bosnia and Herzegovina",
  "Bikita, Zimbabwe",
  "Biksti, Latvia",
  "Bilbao, Spain",
  "Bilbeis, Egypt",
  "Bilecik, Turkey",
  "Bilgoraj, Poland",
  "Bilina, Czech Republic",
  "Billerbeck (Westf), Germany",
  "Billericay, United Kingdom",
  "Billingborough, United Kingdom",
  "Billingham, United Kingdom",
  "Billingshurst, United Kingdom",
  "Billy sur Aisne, France",
  "Bilovec, Czech Republic",
  "Bilovice, Czech Republic",
  "Bilston Wolverhampton, United Kingdom",
  "Bilzen, Belgium",
  "Binasco, Italy",
  "Binbrook, United Kingdom",
  "Bindlach, Germany",
  "Binefar, Spain",
  "Bingen (am Rhein), Germany",
  "Binghamton, United States",
  "Bingley, United Kingdom",
  "BINSFELD, Germany",
  "Bintulu, Malaysia",
  "Bione, Italy",
  "Biot, France",
  "Biougra, Morocco",
  "Birchington On Sea, United Kingdom",
  "Birchwood, United Kingdom",
  "Birganj, Nepal",
  "Birkeland, Norway",
  "Birkenfeld (Wuertt), Germany",
  "Birkenhead, United Kingdom",
  "Birkov, Czech Republic",
  "Birmingham, United Kingdom",
  "Birmingham, United States",
  "Birr Offaly, Ireland",
  "Birsfelden, Switzerland",
  "Birstall Kirklees, United Kingdom",
  "Birtley Tyne, United Kingdom",
  "Bischofsheim (b Ruesselsh), Germany",
  "Bischofshofen, Austria",
  "Bischofswiesen, Germany",
  "Bischwiller, France",
  "Bishop Auckland, United Kingdom",
  "Bishops Castle, United Kingdom",
  "Bishops Itchington, United Kingdom",
  "Bishop's Stortford, United Kingdom",
  "BISHOPTON PA, United Kingdom",
  "Biskupice Podgorne, Poland",
  "Biskupiec, Poland",
  "Bisley Surrey, United Kingdom",
  "Bissau, Guinea-Bissau",
  "Bissen, Luxemburg",
  "Bissendorf, Germany",
  "Bissingen, Germany",
  "Bissingen (an der Teck), Germany",
  "Bistrita, Romania",
  "Bisuschio, Italy",
  "Bitburg, Germany",
  "Bitem, Spain",
  "Bitola, Macedonia",
  "Bitonto, Italy",
  "Bitozeves, Czech Republic",
  "Bitterfeld, Germany",
  "Bitung, Indonesia",
  "Bizerta, Tunisia",
  "Bjala Slatina, Bulgaria",
  "Bjerringbro, Denmark",
  "Bjuv, Sweden",
  "Blackburn Lancs, United Kingdom",
  "Blackburn Scotland, United Kingdom",
  "Blackheath, South Africa",
  "Blackpool, United Kingdom",
  "Blacksburg, United States",
  "Blackwood Caerphilly, United Kingdom",
  "Bladel, Netherlands",
  "Blaenavon, United Kingdom",
  "Blagoevgrad, Bulgaria",
  "Blaina, United Kingdom",
  "Blainville, Canada",
  "Blair, United States",
  "Blairgowrie, United Kingdom",
  "Blairs, United States",
  "Blanca, Spain",
  "Blanchardstown Dublin, Ireland",
  "Blandain Hertain, Belgium",
  "Blandford Forum, United Kingdom",
  "Blankenfelde, Germany",
  "Blanquefort Gers, France",
  "Blanquefort Gironde, France",
  "Blansko, Czech Republic",
  "Blanzy, France",
  "Blaszki, Poland",
  "Blatna, Czech Republic",
  "Blaubeuren, Germany",
  "Blaufelden, Germany",
  "Blaustein, Germany",
  "Blavozy, France",
  "Blaydon, United Kingdom",
  "Blaye, France",
  "Blaye les Mines, France",
  "Bledow, Poland",
  "Blenheim, Canada",
  "Blenod Les Pont a Mousson, France",
  "Bletchley Milton Keynes, United Kingdom",
  "Blieskastel, Germany",
  "Blockley, United Kingdom",
  "Blois, France",
  "Blomberg (Kr Lippe), Germany",
  "Blonie, Poland",
  "Bloomfield, United States",
  "Bloomingdale, United States",
  "Bloomington, United States",
  "Bloomsburg, United States",
  "Blovice, Czech Republic",
  "Bloxwich, United Kingdom",
  "Bludenz, Austria",
  "Blue Island, United States",
  "Blue Mountain, United States",
  "Bluff, New Zealand",
  "Bluffton, United States",
  "Blumberg (Baden), Germany",
  "Blyth Nottinghamshire, United Kingdom",
  "Blythewood, United States",
  "BlythNorthumberland, United Kingdom",
  "Boadilla del Monte, Spain",
  "Boara Pisani, Italy",
  "Bobadela, Portugal",
  "Bobenheim-Roxheim, Germany",
  "Bobigny, France",
  "Bobingen, Germany",
  "Bobo-Dioulasso, Burkina Faso",
  "Bobruisk, Belarus",
  "Boca, Italy",
  "Boca Raton, United States",
  "Bocairent, Spain",
  "Bochnia, Poland",
  "Bocholt, Belgium",
  "Bocholt, Germany",
  "Bochum, Germany",
  "Bodegraven, Netherlands",
  "Bodelwyddan, United Kingdom",
  "Boden, Germany",
  "Bodenheim - Rheinhessen, Germany",
  "Bodenkirchen, Germany",
  "Bodio Lomnago, Italy",
  "Bodman Ludwigshafen, Germany",
  "Bodmin, United Kingdom",
  "Bodrum, Turkey",
  "Boe, France",
  "Boeblingen, Germany",
  "Boecillo, Spain",
  "Boehmenkirch, Germany",
  "Boen, France",
  "Boenen, Germany",
  "Boennigheim, Germany",
  "Boerkop, Denmark",
  "Boerne, United States",
  "Boerssum, Germany",
  "Boesel, Germany",
  "Boffalora Sopra Ticino, Italy",
  "Boffzen, Germany",
  "Bogata, United States",
  "Bogen, Germany",
  "Bognor Regis, United Kingdom",
  "Bogny sur Meuse, France",
  "Bogo, Denmark",
  "Boguchwala, Poland",
  "Bohdanec, Czech Republic",
  "Bohinjska Bistrica, Slovenia",
  "Bohmte, Germany",
  "Bohumin, Czech Republic",
  "Bohunovice, Czech Republic",
  "Bohuslavice, Czech Republic",
  "Bohuslavice u Zlina, Czech Republic",
  "Boigny sur Bionne, France",
  "Boiro, Spain",
  "Bois Grenier, France",
  "Boisar, India",
  "Boisset les Montrond, France",
  "Boissy l'aillerie, France",
  "Boissy sous Saint Yon, France",
  "Bojkovice, Czech Republic",
  "Bokros, Hungary",
  "Boksburg, South Africa",
  "Bolanos Calatrava, Spain",
  "Bolatice, Czech Republic",
  "Boldon, United Kingdom",
  "Bolechowo, Poland",
  "Bolecin, Poland",
  "Boleraz, Slovakia",
  "Boleslaw, Poland",
  "Boleslawiec, Poland",
  "Bolgare, Italy",
  "Bolingbrook, United States",
  "Bolivar, United States",
  "Bolladello, Italy",
  "Bollate Milano, Italy",
  "Bollene, France",
  "Bollingstedt, Germany",
  "Bollington, United Kingdom",
  "Bollullos de la Mitacion, Spain",
  "Bollullos par del Condado, Spain",
  "Bologna, Italy",
  "Bolsover, United Kingdom",
  "Bolsward, Netherlands",
  "Boltiere, Italy",
  "Bolton, Canada",
  "Bolton Lancs, United Kingdom",
  "Bolu, Turkey",
  "Bolzaneto, Italy",
  "Bolzano, Italy",
  "Bolzano Novarese, Italy",
  "Bolzano Vicentino, Italy",
  "Bomlitz, Germany",
  "Bommasandra, India",
  "Bomporto, Italy",
  "Bon Encontre, France",
  "Bonate Sotto, Italy",
  "Bonavicina, Italy",
  "Bondeno, Italy",
  "Bondeno di Gonzaga, Italy",
  "Bondorf, Germany",
  "Bondoufle, France",
  "Bondy, France",
  "Bonea, Italy",
  "Bo'ness, United Kingdom",
  "Bonferraro di Sorga, Italy",
  "Bonmati, Spain",
  "Bonn, Germany",
  "Bonnat, France",
  "Bonne Terre, United States",
  "Bonneuil les Eaux, France",
  "Bonneuil sur Marne, France",
  "Bonneval, France",
  "Bonneville, France",
  "Bonnieres sur Seine, France",
  "Bonnybridge, United Kingdom",
  "Bonnyrigg, United Kingdom",
  "Bonrepos, Spain",
  "Bonson, France",
  "Boom, Belgium",
  "Boone, United States",
  "Boortmeerbeek, Belgium",
  "Bootle Sefton, United Kingdom",
  "Bopfingen, Germany",
  "Boppard, Germany",
  "Boqueixon, Spain",
  "Bor u Tachova, Czech Republic",
  "Borba, Portugal",
  "Borculo, Netherlands",
  "Bordeaux, France",
  "Borden, United Kingdom",
  "Bordentown, United States",
  "Borderes et Lamensans, France",
  "Bordils, Spain",
  "Bordon Camp Hampshire, United Kingdom",
  "Bordon Hampshire, United Kingdom",
  "Borehamwood, United Kingdom",
  "Borek Strzelinski, Poland",
  "Boretto, Italy",
  "Borg El Arab, Egypt",
  "Borgaro Torinese, Italy",
  "Borgentreich, Germany",
  "Borghetto Di Borbera, Italy",
  "Borghetto Lodigian, Italy",
  "Borgholzhausen, Germany",
  "Borgloon, Belgium",
  "Borgo a Mozzano, Italy",
  "Borgo Chiese, Italy",
  "Borgo Grappa, Italy",
  "Borgo Lavezzaro, Italy",
  "Borgo San Dalmazzo, Italy",
  "Borgo san Giacomo, Italy",
  "Borgo San Giovanni, Italy",
  "Borgo San Lorenzo, Italy",
  "Borgo San Michele, Italy",
  "Borgo Santa Maria, Italy",
  "Borgo Ticino, Italy",
  "BORGO TOSSIGNANO, Italy",
  "Borgo Val di Taro, Italy",
  "Borgo Valsugana, Italy",
  "Borgo Virgilio, Italy",
  "Borgocarbonara, Italy",
  "Borgoforte, Italy",
  "Borgofranco Sul Po, Italy",
  "Borgomanero, Italy",
  "Borgomaro, Italy",
  "Borgone Susa, Italy",
  "Borgonovo Val Tidone, Italy",
  "Borgoricco, Italy",
  "Borgorose, Italy",
  "Borgosatollo, Italy",
  "Borgosesia, Italy",
  "Borisov, Belarus",
  "Borj El Amri, Tunisia",
  "Borja, Spain",
  "Borken, Germany",
  "Borken (Westphalia), Germany",
  "Borkhedi, India",
  "Bormujos, Spain",
  "Born, Netherlands",
  "Borna, Germany",
  "Borne Sulinowo, Poland",
  "Bornem, Belgium",
  "Bornheim (Rheinl), Germany",
  "Borohradek, Czech Republic",
  "Borotin, Czech Republic",
  "Boroughbridge, United Kingdom",
  "Borovany, Czech Republic",
  "Borovnica, Slovenia",
  "Borox, Spain",
  "Borrby, Sweden",
  "Borre, France",
  "Borris Carlow, Ireland",
  "Bors, Romania",
  "Borsky Mikulas, Slovakia",
  "Borso del Grappa, Italy",
  "Borzano di Albine, Italy",
  "Boraas, Sweden",
  "Bosany, Slovakia",
  "Bosco Chiesanuova, Italy",
  "Bosco di Scandiano, Italy",
  "Bosco Marengo, Italy",
  "Bosisio Parini, Italy",
  "Boskovice, Czech Republic",
  "Bosnasco, Italy",
  "Bostanj, Slovenia",
  "Boston, United Kingdom",
  "Boston, United States",
  "Botevgrad, Bulgaria",
  "Bothwell South Lanarkshire, United Kingdom",
  "Botlek, Netherlands",
  "Botley Hampshire, United Kingdom",
  "Botosani, Romania",
  "Bottanuco, Italy",
  "Botticino, Italy",
  "Botticino Mattina, Italy",
  "Bottrop, Germany",
  "Botucatu, Brazil",
  "Bouc Bel Air, France",
  "Boucherville, Canada",
  "Bouconvillers, France",
  "Boue, France",
  "Boughton Monchelsea Kent, United Kingdom",
  "Boughton Northamptonshire, United Kingdom",
  "Bougival, France",
  "Boulogne sur Mer, France",
  "Bourbon Lancy, France",
  "Bourg de Thizy, France",
  "Bourg en Bresse, France",
  "Bourg les Valence, France",
  "Bourg Saint Maurice, France",
  "Bourgbarre, France",
  "Bourges, France",
  "Bourg-et-Comin, France",
  "Bourgoin Jallieu, France",
  "Bourne End - Bedfordshire, United Kingdom",
  "Bourne Lincolnshire, United Kingdom",
  "Bournemouth, United Kingdom",
  "Bourron-Marlotte, France",
  "Bourton on the Water, United Kingdom",
  "Bousbecque, France",
  "Bouskoura, Morocco",
  "Boussac, France",
  "Boussay, France",
  "Boussens, France",
  "Bout du Pont de Larn, France",
  "Bouxwiller, France",
  "Bouzy, France",
  "Boves, France",
  "Boves, Italy",
  "Bovey Tracey, United Kingdom",
  "Bovezzo, Italy",
  "Bovingdon, United Kingdom",
  "Bovisio Masciago, Italy",
  "Bovolenta, Italy",
  "Bovolone, Italy",
  "Bow London, United Kingdom",
  "Bowburn Durham, United Kingdom",
  "Bowling, United Kingdom",
  "Bowling Green, United States",
  "Boxberg, Germany",
  "Boxberg (OL), Germany",
  "Boxborough, United States",
  "Boxholm, Sweden",
  "Boxmeer, Netherlands",
  "Boxtel, Netherlands",
  "Boyarka, Ukraine",
  "Boyertown, United States",
  "Boykins, United States",
  "Boynton Beach, United States",
  "Bozdogan, Turkey",
  "Bozicany, Czech Republic",
  "Bozuyuk-Bilecik, Turkey",
  "Bra, Italy",
  "Brabant le Roi, France",
  "Brabrand, Denmark",
  "Brackenheim, Germany",
  "Brackley Northamptonshire, United Kingdom",
  "Bracknell, United Kingdom",
  "Bradenton, United States",
  "Bradford On Avon, United Kingdom",
  "Bradford Yorkshire, United Kingdom",
  "Bradwell Derbyshire, United Kingdom",
  "Braecke, Sweden",
  "Braedstrup, Denmark",
  "Braeunlingen, Germany",
  "Braga, Portugal",
  "Bragadiru, Romania",
  "Braganca, Portugal",
  "Braila, Romania",
  "Braine, France",
  "Braintree, United Kingdom",
  "Brake (Unterweser), Germany",
  "Brakel, Germany",
  "Brakpan, South Africa",
  "Bralin, Poland",
  "Bramley View, South Africa",
  "Brampton, Canada",
  "Bramsche (Hase), Germany",
  "Branchburg, United States",
  "Brande, Denmark",
  "Brandenburg, Germany",
  "Brandenburg, United States",
  "Brand-Erbisdorf, Germany",
  "Branderup, Denmark",
  "brandico, Italy",
  "Brandis (bei Wurzen), Germany",
  "Brandizzo, Italy",
  "Brandon Suffolk, United Kingdom",
  "Brandys nad Labem, Czech Republic",
  "Brandysek, Czech Republic",
  "Braniewo, Poland",
  "Brannenburg, Germany",
  "Bransgore, United Kingdom",
  "Brantford, Canada",
  "Braselton, United States",
  "Brasov, Romania",
  "Brassac, France",
  "Brassington, United Kingdom",
  "Bratislava, Slovakia",
  "Brattleboro, United States",
  "Braubach, Germany",
  "Braunschweig, Germany",
  ",Braunston Northamptonshire, United Kingdom",
  "Brazzaville, Congo",
  "Brcko, Bosnia and Herzegovina",
  "Brea, United States",
  "Brea De Aragon, Spain",
  "Brebbia, Italy",
  "Brech, France",
  "Brechin, United Kingdom",
  "Brecht, Belgium",
  "Breckenridge, United States",
  "Breclav, Czech Republic",
  "Brecon, United Kingdom",
  "Breda, Netherlands",
  "Breda, Spain",
  "Breda di Piave, Italy",
  "Bredaryd, Sweden",
  "Bredbury, United Kingdom",
  "Bree, Belgium",
  "Breganze, Italy",
  "Bregenz, Austria",
  "Bregnano, Italy",
  "Bregy, France",
  "Brehan, France",
  "Brehna, Germany",
  "Breidenbach, Germany",
  "Breinigsville, United States",
  "Breisach (am Rhein), Germany",
  "Breitenau, Austria",
  "Breitenbach, Switzerland",
  "Breitenguessbach, Germany",
  "Breitscheid, Germany",
  "Breitscheid (Hessen), Germany",
  "Brekstad, Norway",
  "Brembate, Italy",
  "Brembate di Sopra, Italy",
  "Brembilla, Italy",
  "Bremen, Germany",
  "Bremen, United States",
  "Bremerhaven, Germany",
  "Bremerton, United States",
  "Bremervoerde, Germany",
  "Bremgarten, Switzerland",
  "BRENCHLEY, United Kingdom",
  "Brendola, Italy",
  "Brenes, Spain",
  "Brenna, Italy",
  "Breno, Italy",
  "Brentford, United Kingdom",
  "Brentino Belluno, Italy",
  "Brentwood, United Kingdom",
  "Brescello, Italy",
  "Brescia, Italy",
  "Bressana Bottarone, Italy",
  "Bressanone, Italy",
  "Bressanvido, Italy",
  "Bresso, Italy",
  "Bressuire, France",
  "Brest, Belarus",
  "Brest, France",
  "Breteuil, France",
  "Bretigny sur Orge, France",
  "Bretnig, Germany",
  "Bretten, Germany",
  "Bretzfeld, Germany",
  "Breuberg, Germany",
  "Breuil le Sec, France",
  "Breukelen, Netherlands",
  "Breuna, Germany",
  "Brevans, France",
  "Brevard, United States",
  "Brevonnes, France",
  "Brezice, Slovenia",
  "Breznice, Czech Republic",
  "Brezno, Slovakia",
  "Brezno - Ml Boleslav, Czech Republic",
  "Brezova pod Bradlom, Slovakia",
  "Briare, France",
  "Bridge of Allan, United Kingdom",
  "Bridge of Weir, United Kingdom",
  "Bridgend Glam, United Kingdom",
  "Bridgeview, United States",
  "Bridgewater, United States",
  "Bridgnorth, United Kingdom",
  "Bridgwater Somerset, United Kingdom",
  "Bridlicna, Czech Republic",
  "Bridlington, United Kingdom",
  "Bridport, United Kingdom",
  "Brie sous Archiac, France",
  "Brienon sur Armancon, France",
  "Brierley Hill, United Kingdom",
  "Brieselang, Germany",
  "Briey, France",
  "Briga Novarese, Italy",
  "Brigachtal, Germany",
  "Brigg, United Kingdom",
  "Brighouse Calderdale, United Kingdom",
  "Brightlingsea, United Kingdom",
  "BRIGHTON, United States",
  "Brighton Brighton And Hove, United Kingdom",
  "Brignais, France",
  "Brignano Gera d'Adda, Italy",
  "Brignoles, France",
  "Brignoud, France",
  "Brilon, Germany",
  "Brimsdown London, United Kingdom",
  "Brindisi, Italy",
  "Brion Ain, France",
  "Briones, Spain",
  "Briord, France",
  "Briosco, Italy",
  "Brioude, France",
  "Brisbane, Australia",
  "Brisbane, United States",
  "Brissac Quince, France",
  "Bristol, United Kingdom",
  "Bristol, United States",
  "Briviesca, Spain",
  "Brivio, Italy",
  "Brixworth Northamptonshire, United Kingdom",
  "BRNIK, Slovenia",
  "Brniste, Czech Republic",
  "Brno, Czech Republic",
  "Bro, Sweden",
  "Broadstairs, United Kingdom",
  "Broadway Hereford and Worcester, United Kingdom",
  "Broager, Denmark",
  "Broccostella, Italy",
  "Broceni, Latvia",
  "Brochon, France",
  "Brochow, Poland",
  "Brockenhurst, United Kingdom",
  "Brockville, Canada",
  "Brockworth Gloucestershire, United Kingdom",
  "Brodnica, Poland",
  "Broek op Langedijk, Netherlands",
  "Broekhuizen, Netherlands",
  "Broekhuizenvorst, Netherlands",
  "Broendby, Denmark",
  "Brogliano, Italy",
  "Broken Arrow, United States",
  "Brolo, Italy",
  "Bromborough, United Kingdom",
  "Bromley, United Kingdom",
  "Bromsgrove, United Kingdom",
  "Bromskirchen, Germany",
  "Bromyard, United Kingdom",
  "Broni, Italy",
  "Bronisze, Poland",
  "Bronte, Italy",
  "Bronx, United States",
  "Bronzolo, Italy",
  "Brookhaven, United States",
  "Brooklyn, United States",
  "BROOKPARK, United States",
  "BROOKSTON, United States",
  "Brookville, United States",
  "Broseley Shropshire, United Kingdom",
  "Brotterode, Germany",
  "Brough Humberside, United Kingdom",
  "Broughton Astley, United Kingdom",
  "Broumov, Czech Republic",
  "Brousseval, France",
  "Brownhills Staffordshire, United Kingdom",
  "BROWNSTOWN, United States",
  "Brownsville, United States",
  "Broxbourne, United Kingdom",
  "Broxburn, United Kingdom",
  "Brtnice, Czech Republic",
  "Bruceville, United States",
  "Bruchsal, Germany",
  "Bruck an der Leitha, Austria",
  "Bruckberg (Niederbayern), Germany",
  "Bruckmuehl, Germany",
  "Brueggen (Niederrhein), Germany",
  "Bruehl (Erftkreis), Germany",
  "Bruges, France",
  "Brugg, Switzerland",
  "Brugge, Belgium",
  "Brugherio, Italy",
  "Brugine, Italy",
  "Brugnera, Italy",
  "Brumov, Czech Republic",
  "Brumovice, Czech Republic",
  "Brunegg, Switzerland",
  "Brunello, Italy",
  "Brunico, Italy",
  "Brunn - Kr Regensburg, Germany",
  "Brunn am Gebirge, Austria",
  "Brunsbuettel, Germany",
  "Brunssum, Netherlands",
  "Brunswick, United States",
  "Bruntal, Czech Republic",
  "Brusaporto, Italy",
  "Brusnengo, Italy",
  "Brussels, Belgium",
  "Brusy, Poland",
  "Bruton, United Kingdom",
  "Bruyeres sur Oise, France",
  "Bryan, United States",
  "Bryne, Norway",
  "Brzeg, Poland",
  "Brzeg Dolny, Poland",
  "Brzesko, Poland",
  "Brzeszcze, Poland",
  "Brzoza Bydgoska, Poland",
  "Braak, Germany",
  "Bubendorf, Switzerland",
  "Bubenheim (Pfalz), Germany",
  "Buc, France",
  "Buca, Turkey",
  "Buccinasco, Italy",
  "Buccino, Italy",
  "Bucharest, Romania",
  "Bucharest Area Four, Romania",
  "Bucharest Area One, Romania",
  "Bucharest Area Three, Romania",
  "Bucharest Area Two, Romania",
  "Buchbach (Oberbayern), Germany",
  "Buchholz (i d Nordheide), Germany",
  "Buchholz (Westerwald), Germany",
  "Buchloe, Germany",
  "Buchs, Switzerland",
  "Buchs AG, Switzerland",
  "Buchs ZH, Switzerland",
  "Bucine, Italy",
  "Buciumeni, Romania",
  "Buckfastleigh, United Kingdom",
  "Buckhannon, United States",
  "Buckhurst Hill, United Kingdom",
  "Buckie, United Kingdom",
  "Buckingham, United Kingdom",
  "Buckley, United Kingdom",
  "Bucksburn, United Kingdom",
  "Bucovice, Czech Republic",
  "Bucu, Romania",
  "Buda, United States",
  "Budakeszi, Hungary",
  "BUDAORS, Hungary",
  "Budapest, Hungary",
  "Budd Lake, United States",
  "Bude, United Kingdom",
  "Budenheim, Germany",
  "Budisov nad Budisovkou, Czech Republic",
  "Budrio, Italy",
  "Budzyn (Wielkopolskie), Poland",
  "Buechenbach, Germany",
  "Buechlberg, Germany",
  "Bueckeburg, Germany",
  "Buedingen (Hess), Germany",
  "Buehl (Baden), Germany",
  "Buehlertann, Germany",
  "Buena Fe, Ecuador",
  "Buenaventura, Colombia",
  "Buende, Germany",
  "Buenos Aires, Argentina",
  "Bueren (Westf), Germany",
  "Buergstadt, Germany",
  "Buers, Austria",
  "Buerstadt, Germany",
  "Buetzow, Germany",
  "Bufalo, Honduras",
  "Buffalo, United States",
  "Buford, United States",
  "Buftea - Bucuresti, Romania",
  "Bugarra, Spain",
  "Buggenhout, Belgium",
  "Bugo, Philippines",
  "Buguggiate, Italy",
  "Buia, Italy",
  "Bujaraloz, Spain",
  "Buk, Hungary",
  "Buk, Poland",
  "Bukowno, Poland",
  "Bulciago, Italy",
  "Bulgarograsso, Italy",
  "Bulgneville, France",
  "Bulwell, United Kingdom",
  "Bungay, United Kingdom",
  "Bunol, Spain",
  "Bunschoten-Spakenburg, Netherlands",
  "Buntingford, United Kingdom",
  "Bunuel, Spain",
  "Burago di Molgora, Italy",
  "Buraydah, Saudi Arabia",
  "Burbach (Siegerland), Germany",
  "Burdur, Turkey",
  "Burg, Germany",
  "Burg (Dithmarschen), Germany",
  "Burgas, Bulgaria",
  "Burgau (Schwab), Germany",
  "Burgberg, Germany",
  "Burgbernheim, Germany",
  "Burgbrohl, Germany",
  "Burgdorf, Switzerland",
  "Burgebrach, Germany",
  "Burgess Hill, United Kingdom",
  "Burghaslach, Germany",
  "Burghausen Salzach, Germany",
  "Burgheim, Germany",
  "Burgkirchen, Austria",
  "Burgkirchen (a d Alz), Germany",
  "Burgkunstadt, Germany",
  "Burglengenfeld, Germany",
  "Burgo de Osma, Spain",
  "Burgos, Spain",
  "Burgstaedt, Germany",
  "Burgstetten, Germany",
  "Burguillos Sevilla, Spain",
  "Burgwald (Eder), Germany",
  "Burkau, Germany",
  "Burkville, United States",
  "Burlington, Canada",
  "Burlington, United States",
  "Burnaby, Canada",
  "Burnaston, United Kingdom",
  "Burnham on Crouch, United Kingdom",
  "Burnhaupt le Haut, France",
  "Burnley, United Kingdom",
  "Burnopsfield, United Kingdom",
  "Burntisland, United Kingdom",
  "Burntwood, United Kingdom",
  "Burolo, Italy",
  "Burriana, Spain",
  "Burry Port, United Kingdom",
  "Bursa, Turkey",
  "Burscheid (Rhein-Hilgen), Germany",
  "Burscough, United Kingdom",
  "Burslem, United Kingdom",
  "BURT, United States",
  "Burtenbach, Germany",
  "Burton, United States",
  "Burton Latimer, United Kingdom",
  "Burton on Trent, United Kingdom",
  "Burton Wiltshire, United Kingdom",
  "Burton Wirral, United Kingdom",
  "Burtonwood, United Kingdom",
  "Burwell Cambridgeshire, United Kingdom",
  "Bury Lancs, United Kingdom",
  "Bury St Edmunds, United Kingdom",
  "Busalla, Italy",
  "Busan, Korea, South",
  "Busano Canavese, Italy",
  "Busca, Italy",
  "Buscate, Italy",
  "Buseck, Germany",
  "Busenberg, Germany",
  "Bushehr, Iran",
  "Bushmills Northern Ireland, United Kingdom",
  "Bushrod Island, Liberia",
  "Busnago, Italy",
  "Bussero, Italy",
  "Busseto, Italy",
  "Bussolengo, Italy",
  "Bussy Saint Georges, France",
  "Busto Arsizio, Italy",
  "Busto Garolfo, Italy",
  "Bustos, Portugal",
  "Buttapietra, Italy",
  "Buttenwiesen, Germany",
  "Buttigliera Alta, Italy",
  "Buttigliera d Asti, Italy",
  "Buttrio, Italy",
  "Butzbach, Germany",
  "Buxheim (B Memmingen), Germany",
  "Buxtehude, Germany",
  "Buxton Derbyshire, United Kingdom",
  "Buxton Norfolk, United Kingdom",
  "Buyukcekmece, Turkey",
  "Buzancais, France",
  "Buzau, Romania",
  "Buzica, Slovakia",
  "Buzzards Bay, United States",
  "Byala, Bulgaria",
  "Bydgoszcz, Poland",
  "Byfleet, United Kingdom",
  "Byhalia, United States",
  "Byley, United Kingdom",
  "Byron, United States",
  "Bystre, Slovakia",
  "Bystrice Nad Pernstejnem, Czech Republic",
  "Bystrice Pod Hostynem, Czech Republic",
  "Bystrzyca Klodzka, Poland",
  "Bytca, Slovakia",
  "Bytom, Poland",
  "Bytow, Poland",
  "Bzenec, Czech Republic",
  "Ca' Di David, Italy",
  "Cabanas de la Sagra, Spain",
  "Cabanillas del Campo, Spain",
  "Cabannes, France",
  "Cabezas de San Juan, Spain",
  "Cabezo de Torres, Spain",
  "Cabezon de la Sal, Spain",
  "Cabezon de Pisuerga, Spain",
  "Cabezuela del Valle, Spain",
  "Cabiate, Italy",
  "Cabinda, Angola",
  "Cabra, Spain",
  "CABREJAS DEL PINAR, Spain",
  "Cabrera de Mar, Spain",
  "Cabries, France",
  "Cacapava, Brazil",
  "Cacem, Portugal",
  "Caceres, Spain",
  "Cachtice, Slovakia",
  "Cacicedo de Camargo Cantabria, Spain",
  "Cadalso de los Vidrios, Spain",
  "Cadaval, Portugal",
  "Cadca, Slovakia",
  "Cade, Italy",
  "Cadelbosco di Sopra, Italy",
  "Cadeo, Italy",
  "Cadillac, United States",
  "Cadiz, Spain",
  "Cadolzburg, Germany",
  "Cadoneghe, Italy",
  "Cadorago, Italy",
  "Cadreita, Spain",
  "Cadrete, Spain",
  "Cadriano, Italy",
  "Caenby Lincs, United Kingdom",
  "Caerano San Marco, Italy",
  "Caerphilly, United Kingdom",
  "Caesarea, Israel",
  "Cafasse, Italy",
  "Cagayan de Oro, Philippines",
  "Cagli, Italy",
  "Cagnano, Italy",
  "Cahir Tipperary, Ireland",
  "Caimito, Panama",
  "Caino, Italy",
  "Cairanne, France",
  "Cairate, Italy",
  "Cairo Montenotte, Italy",
  "Caistor, United Kingdom",
  "Caivano, Italy",
  "Cajiz, Spain",
  "Cakovec, Croatia",
  "Cal, Turkey",
  "Calaf, Spain",
  "Calahorra, Spain",
  "Calais, France",
  "Calamandrana, Italy",
  "Calanda, Spain",
  "Calasparra, Spain",
  "Calatafimi, Italy",
  "Calatayud, Spain",
  "Calau, Germany",
  "Calbe (Saale), Germany",
  "Calbuco, Chile",
  "Calcara, Italy",
  "Calceranica al Lago, Italy",
  "Calcinate, Italy",
  "Calcinato, Italy",
  "Calcinelli, Italy",
  "Calcio, Italy",
  "Calco, Italy",
  "Caldaro, Italy",
  "Caldas da Rainha, Portugal",
  "Caldas de Reis, Spain",
  "Caldera, Chile",
  "Calderano, Italy",
  "Calderara di Reno, Italy",
  "Caldes de Malavella, Spain",
  "Caldes de Montbui, Spain",
  "Caldicot, United Kingdom",
  "Caldiero, Italy",
  "Caldogno, Italy",
  "Caldonazzo, Italy",
  "Calendasco, Italy",
  "Calenzano, Italy",
  "Caleppio di Settala, Italy",
  "Calera, Mexico",
  "Calerno, Italy",
  "Calestano, Italy",
  "Caleta de Velez, Spain",
  "Calexico, United States",
  "Calgary, Canada",
  "Calhoun, United States",
  "California City, United States",
  "Calignac, France",
  "Caligny, France",
  "Callander, United Kingdom",
  "Callao, Peru",
  "Calliano, Italy",
  "Callington, United Kingdom",
  "Callosa de En Sarria, Spain",
  "Callosa de Segura, Spain",
  "Calmasino, Italy",
  "Calne, United Kingdom",
  "Calolziocorte, Italy",
  "Caltagirone, Italy",
  "Caltanissetta, Italy",
  "Caltignaga, Italy",
  "Calto, Italy",
  "Caltrano, Italy",
  "Calub, Ethiopia",
  "Caluire et Cuire, France",
  "Calumet City, United States",
  "Calusco d'Adda, Italy",
  "Caluso, Italy",
  "Calvagese della Riviera, Italy",
  "Calvari, Italy",
  "Calvert City, United States",
  "Calverton Milton Keynes, United Kingdom",
  "Calverton Nottinghamshire, United Kingdom",
  "Calvignasco, Italy",
  "Calvisano, Italy",
  "Calw, Germany",
  "calzolaro, Italy",
  "Camacari, Brazil",
  "Camaiore, Italy",
  "Camarate, Portugal",
  "Camarillo, United States",
  "Camarles, Spain",
  "Camarma de Esteruelas, Spain",
  "Camas, Spain",
  "Cambados, Spain",
  "Cambe, Brazil",
  "Cambeo, Spain",
  "Camberley, United Kingdom",
  "Cambiago, Italy",
  "Cambiano, Italy",
  "Camborne, United Kingdom",
  "Cambre, Spain",
  "Cambria, United States",
  "Cambridge, Canada",
  "Cambridge, United States",
  "Cambridge Cambridgeshire, United Kingdom",
  "Cambrils, Spain",
  "Camburg, Germany",
  "Camelford, United Kingdom",
  "Camerano, Italy",
  "Cameri Novara, Italy",
  "Camerino, Italy",
  "Camin, Italy",
  "Camisano, Italy",
  "Camisano Vicentino, Italy",
  "Camlough Northern Ireland, United Kingdom",
  "Camnago, Italy",
  "Camotan, Guatemala",
  "Camp Lemonier, Djibouti",
  "Camp Pendleton, United States",
  "Camp Taji, Iraq",
  "Campagna Lupia, Italy",
  "Campagnola di Zevio, Italy",
  "Campagnola Emilia, Italy",
  "Campanillas, Spain",
  "Campano Pontevedra, Spain",
  "Campbeltown Argyll - Bute, United Kingdom",
  "Campegine, Italy",
  "Campello Sul Clitunno, Italy",
  "Camphin-en-Carembault, France",
  "Campi Bisenzio, Italy",
  "Campiglia dei Berici, Italy",
  "Campiglia Marittima, Italy",
  "Campigo, Italy",
  "Campillos, Spain",
  "Campina, Romania",
  "Campo Calabro, Italy",
  "Campo Di Pietra, Italy",
  "Campo Real, Spain",
  "Campo San Martino, Italy",
  "Campo Tures, Italy",
  "Campobasso, Italy",
  "Campobello di Mazara, Italy",
  "Campochiesa, Italy",
  "Campodarsego, Italy",
  "Campodoro, Italy",
  "Campofelice DI Rocella, Italy",
  "Campofilone, Italy",
  "Campoformido, Italy",
  "Campogalliano, Italy",
  "Campolongo al Torre, Italy",
  "Campolongo Sul Brenta, Italy",
  "Camponaraya, Spain",
  "Camponogara, Italy",
  "Camporotondo Etneo, Italy",
  "CAMPORRAPADO, Spain",
  "Camposampiero, Italy",
  "Camposanto, Italy",
  "Campoverde, Italy",
  "Campulung Muscel, Romania",
  "Canada, Spain",
  "Canada Juncosa, Spain",
  "Canakkale, Turkey",
  "Canale d'Alba, Italy",
  "canals, France",
  "canals, Spain",
  "Canals Valencia, Spain",
  "Canamero, Spain",
  "Canaro, Italy",
  "Canas, Costa Rica",
  "Canavaccio, Italy",
  "Canaveral, Spain",
  "Can-Canakkale, Turkey",
  "Cancello ed Arnone, Italy",
  "Cande, France",
  "Candelu, Italy",
  "Candiana, Italy",
  "Candido Rodrigues, Brazil",
  "Candiolo, Italy",
  "Canegrate, Italy",
  "Canelas, Portugal",
  "Canelli, Italy",
  "Caneva, Italy",
  "Cangas del Morrazo, Spain",
  "Canicossa, Italy",
  "Canino, Italy",
  "Cankiri, Turkey",
  "Canneto sull'Oglio, Italy",
  "Canning, Canada",
  "Canning Town London, United Kingdom",
  "Cannington, United Kingdom",
  "Cannock, United Kingdom",
  "Canonica d'Adda, Italy",
  "Canonsburg, United States",
  "Canossa, Italy",
  "Canove Di Gavone, Italy",
  "Canovelles, Spain",
  "Cantagallo, Italy",
  "Cantello, Italy",
  "Canterbury, United Kingdom",
  "Cantillana Sevilla, Spain",
  "Cantley Doncaster, United Kingdom",
  "Canton, United States",
  "Cantoria, Spain",
  "Cantu, Italy",
  "Canvey Island, United Kingdom",
  "Canzo, Italy",
  "Caorera Di Vas, Italy",
  "Caorso, Italy",
  "Capaccio, Italy",
  "Capalle, Italy",
  "Capannori, Italy",
  "Caparroso, Spain",
  "Capdesaso, Spain",
  "Cape Town, South Africa",
  "Capellades, Spain",
  "Capena, Italy",
  "Capens, France",
  "Capitan Sarmiento Buenos Aires, Argentina",
  "Capo d'Orlando, Italy",
  "Capodichino, Italy",
  "Capodrise, Italy",
  "Caponago, Italy",
  "Cappeln (Oldenburg), Germany",
  "Capraia E Limite, Italy",
  "Caprarola, Italy",
  "CAPRAZZINO, Italy",
  "Capri Leone, Italy",
  "Capriano del Colle, Italy",
  "Capriata d'Orba, Italy",
  "Capriate San Gervasio, Italy",
  "Caprino Bergamasco, Italy",
  "Caprino Veronese, Italy",
  "Capriolo, Italy",
  "Capua, Italy",
  "Capurso, Italy",
  "Caraglio, Italy",
  "Carasco, Italy",
  "Carate Brianza, Italy",
  "Caravaca de la Cruz, Spain",
  "Caravaggio, Italy",
  "Caravonica, Italy",
  "Carballeda, Spain",
  "Carballino, Spain",
  "Carballo A Coruna, Spain",
  "Carbon Blanc, France",
  "Carbonara Po, Italy",
  "Carbonara Scrivia, Italy",
  "Carbonate, Italy",
  "Carbonera, Italy",
  "Carbonero el Mayor, Spain",
  "Carcaixent, Spain",
  "Carcar, Spain",
  "Carcassonne, France",
  "CARCHELEJOS, Spain",
  "Carcina, Italy",
  "Cardano Al Campo, Italy",
  "Cardedeu, Spain",
  "Cardiff, United Kingdom",
  "Cardigan, United Kingdom",
  "Cardinal, Canada",
  "Carentan, France",
  "Cariari, Costa Rica",
  "Carimate, Italy",
  "Carinaro, Italy",
  "Carinena, Spain",
  "Carini, Italy",
  "Carisolo, Italy",
  "Carlet, Spain",
  "Carlisle, United Kingdom",
  "Carlisle, United States",
  "Carlow Co Carlow, Ireland",
  "Carlstadt, United States",
  "Carlton in Lindrick, United Kingdom",
  "Carluke, United Kingdom",
  "Carmagnola, Italy",
  "Carmarthen, United Kingdom",
  "Carmel, United States",
  "CARMENA, Spain",
  "Carmi, United States",
  "Carmichaels, United States",
  "Carmiel, Israel",
  "Carmignano, Italy",
  "Carmignano di Brenta, Italy",
  "Carmona, Spain",
  "Carnate, Italy",
  "Carnaxide, Portugal",
  "Carndonagh Donegal, Ireland",
  "Carnforth, United Kingdom",
  "Carnoet, France",
  "Carnoustie, United Kingdom",
  "Carobbio degli Angeli, Italy",
  "Carol Stream, United States",
  "Carolles, France",
  "Caronno Pertusella, Italy",
  "Carpaneto Piacentino, Italy",
  "Carpenedolo, Italy",
  "Carpentras, France",
  "Carpesica, Italy",
  "Carpi, Italy",
  "Carpiano, Italy",
  "Carpignano Salentino, Italy",
  "Carpineti, Italy",
  "Carquefou, France",
  "Carraia, Italy",
  "Carral, Spain",
  "Carrara, Italy",
  "Carrascal del Rio, Spain",
  "Carre, Italy",
  "Carregado, Portugal",
  "Carregosa, Portugal",
  "Carreira VNF, Portugal",
  "Carreno, Spain",
  "Carrick on Shannon Leitrim, Ireland",
  "Carrickfergus, United Kingdom",
  "Carrickmacross Monaghan, Ireland",
  "Carrigtwohill, Ireland",
  "Carrington Man, United Kingdom",
  "Carrion De Los Cespedes, Spain",
  "Carrion de los Condes, Spain",
  "Carrollton, United States",
  "Carros, France",
  "Carru, Italy",
  "Carshalton, United Kingdom",
  "Carsoli, Italy",
  "Carson, United States",
  "Carswell AFB, United States",
  "Cartagena, Colombia",
  "Cartagena, Spain",
  "Cartago, Costa Rica",
  "Cartama, Spain",
  "Cartaya, Spain",
  "Carteret, United States",
  "Cartersville, United States",
  "Carterton, United Kingdom",
  "Cartes, Spain",
  "Cartigliano, Italy",
  "Cartoceto, Italy",
  "Cartuja Baja, Spain",
  "Carugate, Italy",
  "Carugo, Italy",
  "Carvico, Italy",
  "Carvin, France",
  "Casa Grande, United States",
  "Casablanca, Morocco",
  "Casalarreina, Spain",
  "Casalbuttano, Italy",
  "Casale Corte Cerro, Italy",
  "Casale di Felino, Italy",
  "Casale di Scodosia, Italy",
  "Casale Litta, Italy",
  "Casale Monferrato, Italy",
  "Casale sul Sile, Italy",
  "Casalecchio, Italy",
  "CASALECCHIO DI RENO, Italy",
  "Casaleone, Italy",
  "Casaletto Lodigiano, Italy",
  "Casaletto Vaprio, Italy",
  "Casalfiumanese, Italy",
  "Casalgrande, Italy",
  "Casalguidi, Italy",
  "Casalmaggiore, Italy",
  "Casalmorano, Italy",
  "Casalmoro, Italy",
  "Casalnuovo di Napoli, Italy",
  "Casaloldo, Italy",
  "Casalpusterlengo, Italy",
  "Casalromano, Italy",
  "Casalserugo, Italy",
  "Casalvieri, Italy",
  "Casamassima, Italy",
  "Casandrino, Italy",
  "Casar de Caceres, Spain",
  "Casarrubios del Monte, Spain",
  "Casarrubuelos, Spain",
  "Casarsa Della Delizia, Italy",
  "Casarza Ligure, Italy",
  "Casas del Monte Caceres, Spain",
  "Casas Ibanez, Spain",
  "Casatejada, Spain",
  "Casatenovo, Italy",
  "Casatisma, Italy",
  "Casavatore, Italy",
  "Casazza, Italy",
  "Cascante, Spain",
  "Cascavel, Brazil",
  "Casciago, Italy",
  "CASCIANA TERME, Italy",
  "Cascina, Italy",
  "Cascioarele, Romania",
  "Casella, Italy",
  "Casella d'Asolo, Italy",
  "Caselle di Altivole, Italy",
  "Caselle di Pressana, Italy",
  "Caselle di Selvazzano, Italy",
  "Caselle Lurani, Italy",
  "Caselle Torinese, Italy",
  "Caserta, Italy",
  "Casetas, Spain",
  "Casette d'Ete, Italy",
  "Casier, Italy",
  "Casinalbo, Italy",
  "CASIRATE D'ADDA, Italy",
  "Caslav, Czech Republic",
  "Casnate, Italy",
  "Casnigo, Italy",
  "Casola Valsenio, Italy",
  "Casole d'Elsa, Italy",
  "Casoli, Italy",
  "Casoni, Italy",
  "Casorate Primo, Italy",
  "Casorate Sempione, Italy",
  "Casorezzo, Italy",
  "Caspe, Spain",
  "Cassa de la Selva, Spain",
  "Cassago Brianza, Italy",
  "Cassano d'Adda, Italy",
  "Cassano delle Murge, Italy",
  "Cassano Magnago, Italy",
  "Cassina de' Pecchi, Italy",
  "Cassina Nuova, Italy",
  "Cassina Rizzardi, Italy",
  "Cassinetta di Biandronno, Italy",
  "Cassinetta di Lugnano, Italy",
  "Cassino, Italy",
  "Cassis, France",
  "Cassola, Italy",
  "Cassolnovo, Italy",
  "Castagnaro, Italy",
  "Castagnito, Italy",
  "Castagnole, Italy",
  "Castagnole delle Lanze, Italy",
  "Castalla, Spain",
  "CASTANARES, Spain",
  "Castanheira do Ribatejo, Portugal",
  "Castano Primo, Italy",
  "Casteggio, Italy",
  "Castegnato, Italy",
  "Castegnero, Italy",
  "Castejon, Spain",
  "Castejon de Monegros, Spain",
  "Castejon del Puente, Spain",
  "Castel Boglion, Italy",
  "Castel Bolognese, Italy",
  "Castel d Aiano, Italy",
  "Castel d'Azzano, Italy",
  "Castel di Casio, Italy",
  "Castel Goffredo, Italy",
  "Castel Guelfo di Bologna, Italy",
  "Castel Madama, Italy",
  "Castel Maggiore, Italy",
  "Castel Mella, Italy",
  "Castel Rozzone, Italy",
  "Castel San Giorgio, Italy",
  "Castel San Giovanni, Italy",
  "Castel San Niccolo, Italy",
  "Castel San Pietro Terme, Italy",
  "Castel Sant'Elia, Italy",
  "Castel Viscardo, Italy",
  "Castel Volturno, Italy",
  "Castelbaldo, Italy",
  "Castelbelforte, Italy",
  "Castelbellino, Italy",
  "Castelbello, Italy",
  "Castelbuono, Italy",
  "Castelcovati, Italy",
  "Castelcucco, Italy",
  "Casteldaccia, Italy",
  "Castelferretti, Italy",
  "Castelfidardo, Italy",
  "Castelfiorentino, Italy",
  "Castelfranco di Sopra, Italy",
  "Castelfranco di Sotto, Italy",
  "Castelfranco Emilia, Italy",
  "Castelfranco Veneto, Italy",
  "Castelgomberto, Italy",
  "Castelguelfo, Italy",
  "Castellammare di Stabia, Italy",
  "Castellamonte, Italy",
  "Castellanos de Moriscos, Spain",
  "Castellanza, Italy",
  "Castellar del Valles, Spain",
  "Castellarano, Italy",
  "Castellar-Oliveral, Spain",
  "Castellazzo Bormida, Italy",
  "Castellbisbal, Spain",
  "Castelleone, Italy",
  "Castelletto di Branduzzo, Italy",
  "castelletto stura, Italy",
  "Castelletto Ticino, Italy",
  "Castellgali, Spain",
  "Castelli Calepio, Italy",
  "Castellina in Chianti, Italy",
  "Castellinaldo, Italy",
  "Castello d'Agogna, Italy",
  "Castello d'Argile, Italy",
  "Castello de Rugat, Spain",
  "Castello di Godego, Italy",
  "Castello di Roganzuolo, Italy",
  "Castello di Serravalle, Italy",
  "Castellon, Spain",
  "Castellucchio, Italy",
  "Castellvell Camp, Spain",
  "Castellvi Rosanes, Spain",
  "Castelmarte, Italy",
  "Castelmartini, Italy",
  "Castelmassa, Italy",
  "Castelminio, Italy",
  "Castelnau d'Estretefonds, France",
  "Castelnau le Lez, France",
  "Castelnaudary, France",
  "Castelnovetto, Italy",
  "Castelnovo Boriano, Italy",
  "Castelnovo di Sotto, Italy",
  "Castelnovo Ne Monti, Italy",
  "Castelnuovo Berardenga, Italy",
  "Castelnuovo Bocca d'Adda, Italy",
  "Castelnuovo del Garda, Italy",
  "Castelnuovo di Garfagnana, Italy",
  "Castelnuovo di Subbiano, Italy",
  "Castelnuovo Magra, Italy",
  "Castelnuovo Rangone, Italy",
  "Castelnuovo Scrivia, Italy",
  "Castelnuovo Valsu, Italy",
  "Castelnuovo Vomano, Italy",
  "Castelo Branco, Portugal",
  "Castelplanio, Italy",
  "Castelseprio, Italy",
  "Castelverde, Italy",
  "Castelvetrano, Italy",
  "Castelvetro di Modena, Italy",
  "Castelvetro Piacentino, Italy",
  "Castenaso, Italy",
  "Castenedolo, Italy",
  "Castiglion Fibocchi, Italy",
  "Castiglion Fiorentino, Italy",
  "Castiglione d'Adda, Italy",
  "Castiglione delle Stiviere, Italy",
  "Castiglione Olona, Italy",
  "Castignano, Italy",
  "Castione Andevenno, Italy",
  "Castions di Strada, Italy",
  "Castkovce, Slovakia",
  "Castle Cary, United Kingdom",
  "Castle Donington, United Kingdom",
  "Castleblaney Monaghan, Ireland",
  "Castleford, United Kingdom",
  "Castleisland Kerry, Ireland",
  "CASTLETON MAN, United Kingdom",
  "Castletownbere, Ireland",
  "Casto, Italy",
  "Castolovice, Czech Republic",
  "Castres, France",
  "Castrette, Italy",
  "Castrezzato, Italy",
  "Castro, Chile",
  "Castro de Rei, Spain",
  "Castro del Rio, Spain",
  "Castro Urdiales, Spain",
  "Castrobarto, Spain",
  "Castrocaro Terme, Italy",
  "Castronno, Italy",
  "Castrop-Rauxel, Germany",
  "CASTROVILLE, United States",
  "Catacamas, Honduras",
  "Catalca-Istanbul, Turkey",
  "Catania, Italy",
  "Catarroja, Spain",
  "Cateasca, Romania",
  "Catenoy, France",
  "Catfield Norfolk, United Kingdom",
  "Catillon Fumechon, France",
  "Catllar, France",
  "Cato Ridge, South Africa",
  "CatonLancashire, United Kingdom",
  "Catral, Spain",
  "Catrine, United Kingdom",
  "Catterick, United Kingdom",
  "Cattolica, Italy",
  "Caucedo, Dominican Republic",
  "Caudebec en Caux, France",
  "Caudete, Spain",
  "Cauneille, France",
  "Caussade, France",
  "Cava de' Tirreni, Italy",
  "Cavaglia, Italy",
  "Cavaion Veronese, Italy",
  "Cavalicco, Italy",
  "Cavallirio, Italy",
  "Cavaloes, Portugal",
  "Cavan Co Cavan, Ireland",
  "Cavanella Po, Italy",
  "Cavaria, Italy",
  "Cavarzere, Italy",
  "Cavaso del Tomba, Italy",
  "Cavazzale, Italy",
  "Cavenago de Brianza, Italy",
  "Cavetown, United States",
  "Cavezzo, Italy",
  "Cavignac, France",
  "Cavola, Italy",
  "Cavour, Italy",
  "Cavriago, Italy",
  "cavriana, Italy",
  "Cavriglia, Italy",
  "Cawston Norfolk Nr, United Kingdom",
  "Cayirova-Istanbul, Turkey",
  "Caylus, France",
  "Caythorpe, United Kingdom",
  "Cazzago di Pianiga, Italy",
  "Cazzago S Martino, Italy",
  "Cazzano di Tramigna, Italy",
  "Cazzano S Andrea, Italy",
  "CBM Depot, Turkey",
  "Cebazan, France",
  "Cebazat, France",
  "Cebolla, Spain",
  "Cebu City, Philippines",
  "Cechtin, Czech Republic",
  "Cedar Park, United States",
  "Cedar Rapids, United States",
  "Cedeira, Spain",
  "Ceggia, Italy",
  "Cehegin, Spain",
  "Cejc, Czech Republic",
  "CEKMEKOY, Turkey",
  "Celakovice, Czech Republic",
  "Celarevo, Serbia",
  "Celaya, Mexico",
  "Celestynow, Poland",
  "Celje, Slovenia",
  "Cella, Italy",
  "Cellatica, Italy",
  "Celle, Germany",
  "Celles, Belgium",
  "Celles sur Durolle, France",
  "Cellino Attanasio, Italy",
  "Cellino San Marco, Italy",
  "Cellole, Italy",
  "Celra, Spain",
  "Cembra, Italy",
  "Cenac, France",
  "Cenate Sotto, Italy",
  "Cene, Italy",
  "Ceneselli, Italy",
  "Cenicero, Spain",
  "Cenkovce, Slovakia",
  "Centallo, Italy",
  "Centelles, Spain",
  "Cento, Italy",
  "Centralia, United States",
  "Centurion, South Africa",
  "Ceolini, Italy",
  "Ceparana, Italy",
  "Cepovan, Slovenia",
  "Ceprano, Italy",
  "Ceranesi, Italy",
  "Cerano, Italy",
  "Cerasolo, Italy",
  "Cerbara, Italy",
  "Cerceda Coruna, Spain",
  "Cerdanyola, Spain",
  "Cerea, Italy",
  "Ceregnano, Italy",
  "Cerekvice nad Loucnou, Czech Republic",
  "Ceres, South Africa",
  "Ceres, United States",
  "Cerese di Virgilio, Italy",
  "Ceresole Alba, Italy",
  "Cerezo de Abajo, Spain",
  "Cerezo de Rio Tiron, Spain",
  "Cergy-Pontoise, France",
  "Cerhovice, Czech Republic",
  "Ceriano Laghetto, Italy",
  "Cerignola, Italy",
  "Cerilly, France",
  "Cerizay, France",
  "Cerkezkoy-Istanbul, Turkey",
  "Cerklje Na Gorenjskem, Slovenia",
  "Cerknica, Slovenia",
  "Cermenate, Italy",
  "Cerna Hora, Czech Republic",
  "Cernay, France",
  "Cernuc, Czech Republic",
  "Cernusco Lombardone, Italy",
  "Cernusco sul Navi, Italy",
  "Cerreto Castello, Italy",
  "Cerreto d'Esi, Italy",
  "Cerreto Guidi, Italy",
  "Cerrione - Serra Lago, Italy",
  "Cerro Al Lambro, Italy",
  "Cerro de Ortega, Mexico",
  "Cerro Maggiore, Italy",
  "Cerro Tanaro, Italy",
  "Certaldo, Italy",
  "Cervarese Santa Croce, Italy",
  "Cervasca, Italy",
  "Cervello, Spain",
  "Cervena Voda, Czech Republic",
  "Cerveny Kostelec, Czech Republic",
  "Cervera, Spain",
  "CERVIA DE TER, Spain",
  "Cervignano del Friuli, Italy",
  "Cervo, Spain",
  "Cesa Della Chiana, Italy",
  "Cesana Brianza, Italy",
  "Cesano Boscone, Italy",
  "Cesano Maderno, Italy",
  "Cesar, Portugal",
  "Cesate, Italy",
  "Cesena, Italy",
  "Cesenatico, Italy",
  "Ceska Lipa, Czech Republic",
  "Ceska Skalice, Czech Republic",
  "Ceska Trebova, Czech Republic",
  "Ceska Ves, Czech Republic",
  "Ceske Budejovice, Czech Republic",
  "Ceske Velenice, Czech Republic",
  "Cesky Brod, Czech Republic",
  "Cesky Krumlov, Czech Republic",
  "Cesky Tesin, Czech Republic",
  "Cesole, Italy",
  "Cessalto, Italy",
  "Cessieu, France",
  "Cessy, France",
  "Cestas, France",
  "Cestlice, Czech Republic",
  "Ceto, Italy",
  "Ceuta, Spain",
  "Ceuti, Spain",
  "Ceva, Italy",
  "Ceyhan, Turkey",
  "Ceylanpinar, Turkey",
  "Chabarovice, Czech Republic",
  "Chabeuil, France",
  "Chachoengsao, Thailand",
  "Chadderton, United Kingdom",
  "Chagny, France",
  "Chaineux, Belgium",
  "Chaingy, France",
  "Chakan, India",
  "Chalchuapa, El Salvador",
  "Chalco, Mexico",
  "Chalette sur Loing, France",
  "Chalezeule, France",
  "Chalfont St Giles, United Kingdom",
  "Chalfont St Peter, United Kingdom",
  "Chalgrove, United Kingdom",
  "Chalkida, Greece",
  "Chalon sur Saone, France",
  "Chalons en Champagne, France",
  "Cham, Germany",
  "Cham, Switzerland",
  "Chambersburg, United States",
  "Chambery, France",
  "Chambray les Tours, France",
  "Chamdor, South Africa",
  "Champ sur Drac, France",
  "Champagne au Mont d'Or, France",
  "Champagne Sarthe, France",
  "Champagnole, France",
  "Champdeniers Saint Denis, France",
  "Champdeuil, France",
  "Champerico, Guatemala",
  "Champforgeuil, France",
  "Champhol, France",
  "Champigneulles, France",
  "Champlain, United States",
  "Champtoce Sur Loire, France",
  "CHANCEAUX SUR CHOISILLE, France",
  "Chandler, United States",
  "Chandlers Ford, United Kingdom",
  "Chanduy - Guayas, Ecuador",
  "CHANE, Spain",
  "Change Mayenne, France",
  "Changsha, China",
  "Changshu, China",
  "Changuinola, Panama",
  "Changwon, Korea, South",
  "Changzhou, China",
  "Chania Crete Island, Greece",
  "Chaniers, France",
  "Channahon, United States",
  "Channelview, United States",
  "Chantada, Spain",
  "Chanteloup les Vignes, France",
  "Chanthaburi, Thailand",
  "Chantilly, United States",
  "Chantonnay, France",
  "Chapela, Spain",
  "Chaponnay, France",
  "Chaponost, France",
  "Charavines, France",
  "Charcenne, France",
  "Charchigne, France",
  "Chard, United Kingdom",
  "Chardon, United States",
  "Charenton le Pont, France",
  "Charleroi, Belgium",
  "Charleroi, United States",
  "Charleston, United States",
  "Charleston AFB, United States",
  "Charleston North, United States",
  "Charlieu, France",
  "Charlotte, United States",
  "Charlton London SE, United Kingdom",
  "Charmes Vosges, France",
  "Charmhaven, Australia",
  "Chartham Kent, United Kingdom",
  "Chartres, France",
  "Chartres DE Bretagne, France",
  "Charvieu Chavagneux, France",
  "Chase City, United States",
  "Chaspuzac, France",
  "Chasse sur Rhone, France",
  "Chasselay, France",
  "Chasseneuil du Poitou, France",
  "Chassieu, France",
  "Chassors, France",
  "Chateau Gontier, France",
  "Chateau Renault, France",
  "Chateaubernard, France",
  "Chateaubourg, France",
  "Chateaubriant, France",
  "Chateaudun, France",
  "Chateauneuf du Faou, France",
  "Chateauneuf du Pape, France",
  "Chateauneuf les Martigues, France",
  "Chateauneuf sur Sarthe, France",
  "Chateauroux Indre, France",
  "Chateauthebaud, France",
  "Chatelet, Belgium",
  "Chatellerault, France",
  "Chatenoy le Royal, France",
  "Chatham, United Kingdom",
  "Chatillon en Michaille, France",
  "Chatillon sur Seine, France",
  "Chatou, France",
  "Chatres, France",
  "Chatsworth, United States",
  "Chattanooga, United States",
  "Chatte, France",
  "Chatteris, United Kingdom",
  "Chauchina, Spain",
  "Chaudeney sur Moselle, France",
  "Chauffailles, France",
  "Chauny, France",
  "Chavannes sur Reyssouze, France",
  "Chavannes sur Suran, France",
  "Chavanod, France",
  "Chaves, Portugal",
  "Chawapail, India",
  "Chazay d'Azergues, France",
  "Chazelles sur Lyon, France",
  "Cheadle Staffordshire, United Kingdom",
  "Cheam, United Kingdom",
  "Cheb, Czech Republic",
  "Checy, France",
  "Cheddar, United Kingdom",
  "Cheddleton, United Kingdom",
  "Cheetam Hill, United Kingdom",
  "Cheffois, France",
  "Chehalis, United States",
  "Chelford, United Kingdom",
  "Chelles, France",
  "Chelm, Poland",
  "Chelm Slaski, Poland",
  "Chelmek, Poland",
  "Chelmno, Poland",
  "Chelmsford, United Kingdom",
  "Chelmza, Poland",
  "Chelsea London, United Kingdom",
  "Cheltenham, United Kingdom",
  "Chemere, France",
  "Chemille-en-Anjou, France",
  "Cheminot, France",
  "Chemnitz (Sachsen), Germany",
  "Chengalpattu, India",
  "Chengdu, China",
  "Chennai, India",
  "Chenove, France",
  "Chepstow, United Kingdom",
  "Cherac, France",
  "Cherasco, Italy",
  "Cherbourg, France",
  "Cherkassy, Ukraine",
  "Chernivtsi, Ukraine",
  "Cherry Point, United States",
  "Chertsey, United Kingdom",
  "Cherven Bryag, Bulgaria",
  "Chervonograd, Ukraine",
  "Chesapeake, United States",
  "Chesham, United Kingdom",
  "Cheshire, United States",
  "Chessington, United Kingdom",
  "Cheste, Spain",
  "Chester, United Kingdom",
  "Chester, United States",
  "Chester le Street, United Kingdom",
  "Chesterfield, United States",
  "Chesterfield Derbyshire, United Kingdom",
  "Chesterton Staffordshire, United Kingdom",
  "Chevanceaux, France",
  "Chevigny Saint Sauveur, France",
  "Chevilly Larue, France",
  "Chevrieres, France",
  "Chevry, France",
  "Chiampo, Italy",
  "Chiaramonte Gulfi, Italy",
  "Chiarano, Italy",
  "Chiari, Italy",
  "Chicago, United States",
  "Chicago Heights, United States",
  "Chicago Joliet, United States",
  "Chicago Ridge, United States",
  "Chichester, United Kingdom",
  "Chichigalpa, Nicaragua",
  "Chickasha, United States",
  "Chiclana de la Frontera, Spain",
  "Chicopee, United States",
  "Chienes, Italy",
  "Chieri, Italy",
  "Chiesa in Valmale, Italy",
  "ChiesanuovaRSM, Italy",
  "Chiesina Uzzanese, Italy",
  "Chieti, Italy",
  "Chieti Scalo, Italy",
  "Chieve, Italy",
  "Chignolo d'Isola, Italy",
  "Chignolo Po', Italy",
  "Chigwell, United Kingdom",
  "Chihuahua, Mexico",
  "Chilches, Spain",
  "Childerditch, United Kingdom",
  "Chillan, Chile",
  "Chilly Mazarin, France",
  "Chiloeches, Spain",
  "Chilton, United States",
  "Chilton Durham, United Kingdom",
  "Chimaltenango, Guatemala",
  "Chimay, Belgium",
  "Chimilin, France",
  "Chinandega, Nicaragua",
  "Chinchilla De Monte, Spain",
  "Chinchon, Spain",
  "Chingford, United Kingdom",
  "Chinley, United Kingdom",
  "Chino, United States",
  "Chioggia, Italy",
  "Chions, Italy",
  "Chiopris Viscone, Italy",
  "Chipinge, Zimbabwe",
  "Chipiona, Spain",
  "Chipley, United States",
  "Chippenham Wiltshire, United Kingdom",
  "Chipping Campden Gloucestershire, United Kingdom",
  "Chipping Norton, United Kingdom",
  "Chipping Warden, United Kingdom",
  "Chirignago, Italy",
  "Chirk, United Kingdom",
  "CHIRNOGI, Romania",
  "Chirnside, United Kingdom",
  "Chiswick, United Kingdom",
  "Chitila, Romania",
  "Chittagong, Bangladesh",
  "Chitungwiza, Zimbabwe",
  "Chiuduno, Italy",
  "Chiuppano, Italy",
  "Chiuro, Italy",
  "Chiusa Di San Michele, Italy",
  "Chiusanico, Italy",
  "Chiusavecchia, Italy",
  "Chiusi, Italy",
  "Chiva, Spain",
  "Chlum u Trebone, Czech Republic",
  "Chlumcany, Czech Republic",
  "Chlumec, Czech Republic",
  "Chlumec nad Cidlinou, Czech Republic",
  "Chmielow, Poland",
  "Chocen, Czech Republic",
  "Chocques, France",
  "Chodov, Czech Republic",
  "Chodova Plana, Czech Republic",
  "Chodziez, Poland",
  "Chojnow, Poland",
  "Cholet, France",
  "Choloma Cortes, Honduras",
  "Choluteca, Honduras",
  "Chomerac, France",
  "Chomutov, Czech Republic",
  "Chonburi, Thailand",
  "Chonchi, Chile",
  "Chongqing, China",
  "Chorley Lancs, United Kingdom",
  "Chornomorsk, Ukraine",
  "Chorrera, Panama",
  "Chorvatsky Grob, Slovakia",
  "Chorzow, Poland",
  "Chotebor, Czech Republic",
  "Chrast - Chrudim, Czech Republic",
  "Chrast - Plzen, Czech Republic",
  "Chrastany u Prahy, Czech Republic",
  "Chrastava u Liberce, Czech Republic",
  "Chrisoupolis Kavalas, Greece",
  "Christchurch Dorset, United Kingdom",
  "Christchurch Metroport, New Zealand",
  "Christiana, South Africa",
  "Christiansfeld, Denmark",
  "Chropyne, Czech Republic",
  "Chroustovice, Czech Republic",
  "Chrudim, Czech Republic",
  "Chrzanow, Poland",
  "Chuguyev, Ukraine",
  "Chula Vista, United States",
  "Chumphon, Thailand",
  "Church Stretton, United Kingdom",
  "Churriana, Spain",
  "Chvaletice, Czech Republic",
  "Chvalkovice, Czech Republic",
  "Chynov, Czech Republic",
  "Ciano d'Enza, Italy",
  "Cicagna, Italy",
  "Cicognara, Italy",
  "Ciechanow, Poland",
  "Ciele, Poland",
  "Ciempozuelos, Spain",
  "Cienega, Mexico",
  "Cierbena, Spain",
  "Cierna nad Tisou, Slovakia",
  "Cieszyn, Poland",
  "Cieza, Spain",
  "Cigacice, Poland",
  "Cigales, Spain",
  "Cigli, Turkey",
  "Cigognola, Italy",
  "cigole, Italy",
  "Cilavegna, Italy",
  "Cimadolmo, Italy",
  "Cimelice, Czech Republic",
  "Cimitile, Italy",
  "Cimolais, Italy",
  "Cincinnati, United States",
  "Cinco Casas, Spain",
  "Cinderford, United Kingdom",
  "CINE, Turkey",
  "Ciney, Belgium",
  "Cingia de Botti, Italy",
  "Cingoli, Italy",
  "Cinisello Balsamo, Italy",
  "Cinto Caomaggiore, Italy",
  "Cintruenigo, Spain",
  "Circleville, United States",
  "Cirencester, United Kingdom",
  "Cirie, Italy",
  "Cirimido, Italy",
  "Ciron, France",
  "Cisano Bergamasco, Italy",
  "Ciserano, Italy",
  "Cislago, Italy",
  "Cisliano, Italy",
  "Cison di Valmarino, Italy",
  "Cisse, France",
  "Cisterna di Latina, Italy",
  "CITTA DELLA PIEVE, Italy",
  "Citta' di Castello, Italy",
  "Citta Sant'Angelo, Italy",
  "Cittadella, Italy",
  "City Centre, South Africa",
  "City Centre Port Elizabeth, South Africa",
  "City Deep, South Africa",
  "City of Industry, United States",
  "Ciudad Acuna, Mexico",
  "Ciudad Arce, El Salvador",
  "Ciudad Guzman, Mexico",
  "Ciudad Juarez, Mexico",
  "Ciudad Obregon, Mexico",
  "Ciudad Real, Spain",
  "Ciudad Rodrigo, Spain",
  "Ciudad Sahagun, Mexico",
  "Ciudad Victoria, Mexico",
  "Ciudalcampo, Spain",
  "Civate, Italy",
  "Cividale del Friuli, Italy",
  "Cividate al Piano, Italy",
  "Cividate Camuno, Italy",
  "Cividino, Italy",
  "Civita Castellana, Italy",
  "Civitanova Marche, Italy",
  "Civitavecchia, Italy",
  "Civitella di Romagna, Italy",
  "Civitella in Val di Chiana, Italy",
  "CIVRAC EN MEDOC, France",
  "Civray, France",
  "Civrieux d'Azergues, France",
  "Civril-Denizli, Turkey",
  "Cizzolo, Italy",
  "Ckyne, Czech Republic",
  "Clackamas, United States",
  "Clackmannan Clackmannanshire, United Kingdom",
  "Clacton on Sea, United Kingdom",
  "Claix, France",
  "Clamecy, France",
  "Clara Town, Liberia",
  "Clare, United States",
  "Claremore, United States",
  "Clarence, United States",
  "Clavier, Belgium",
  "Clay Cross, United Kingdom",
  "Claycomo, United States",
  "Claydon Suffolk, United Kingdom",
  "Claye Souilly, France",
  "Clayton, United States",
  "Clayton le Moors, United Kingdom",
  "Clayton Manchester, United Kingdom",
  "Clearwater, United States",
  "Cleator Moor, United Kingdom",
  "Cleburne, United States",
  "Cleckheaton, United Kingdom",
  "Cleethorpes, United Kingdom",
  "Cleguer, France",
  "Clemmons, United States",
  "Clenay, France",
  "Clerac, France",
  "Clermont, France",
  "Clermont Ferrand, France",
  "Clermont l'Herault, France",
  "Cles, Italy",
  "Clevedon, United Kingdom",
  "Cleveland, United States",
  "Clewiston, United States",
  "Clichy, France",
  "Clifton, United States",
  "Clifton Bristol, United Kingdom",
  "Clinceni, Romania",
  "Clingen, Germany",
  "Clinton, United States",
  "Clinton Township, United States",
  "Clintonville, United States",
  "Clisson, France",
  "Clitheroe, United Kingdom",
  "Clondalkin Dublin, Ireland",
  "Clones Monaghan, Ireland",
  "Clonmel Tipperary, Ireland",
  "Cloppenburg, Germany",
  "Cloquet, United States",
  "Clover, United States",
  "Clowne, United Kingdom",
  "Cloyes sur le Loir, France",
  "Cluj-Napoca, Romania",
  "Clydach Swansea, United Kingdom",
  "Clyde, United States",
  "Clydebank, United Kingdom",
  "Cmielow, Poland",
  "Coal City, United States",
  "Coalville, United Kingdom",
  "Coatbridge, United Kingdom",
  "Coban, Guatemala",
  "Cobatillas, Spain",
  "Cobeja, Spain",
  "Cobena, Spain",
  "Cobham Surrey, United Kingdom",
  "Cobourg, Canada",
  "Coburg, Germany",
  "Coca, Spain",
  "Coccaglio, Italy",
  "Cocentaina, Spain",
  "Cochin, India",
  "Cocquio Trevisago, Italy",
  "Codevigo, Italy",
  "Codevilla, Italy",
  "Codford St Mary, United Kingdom",
  "Codicote, United Kingdom",
  "Codigoro, Italy",
  "Codogne, Italy",
  "Codogno, Italy",
  "Codroipo, Italy",
  "Coega, South Africa",
  "Coelbe, Germany",
  "Coenzo di Sorbolo, Italy",
  "Coesfeld, Germany",
  "Coevorden, Netherlands",
  "Coggeshall, United Kingdom",
  "Cogliate, Italy",
  "Cognac, France",
  "Cogollo del Cengio, Italy",
  "Cogozzo, Italy",
  "Coimbra, Portugal",
  "Coin, Spain",
  "Coiros, Spain",
  "Cojobar, Spain",
  "Colbordolo, Italy",
  "Colchester, United Kingdom",
  "Coldstream, United Kingdom",
  "Coleford Gloucestershire, United Kingdom",
  "Coleraine Northern Ireland, United Kingdom",
  "Coleshill Oxfords, United Kingdom",
  "Coleshill Warks, United Kingdom",
  "Colico, Italy",
  "Colima, Mexico",
  "Collado Villalba, Spain",
  "Collbato, Spain",
  "Colle Salvetti, Italy",
  "Colle Umberto, Italy",
  "Colle Val d'Elsa, Italy",
  "Collebeato, Italy",
  "Collecchio, Italy",
  "Colledara, Italy",
  "Colleferro, Italy",
  "College Park, United States",
  "Collegien, France",
  "Collegno, Italy",
  "Colli al Metauro, Italy",
  "Collierville, United States",
  "Collodi, Italy",
  "Collonges au Mont d'Or, France",
  "Colmar, France",
  "Colmenar Viejo, Spain",
  "Colnago, Italy",
  "Colnbrook, United Kingdom",
  "Colne Lancs, United Kingdom",
  "Colney Heath, United Kingdom",
  "Cologna di Berra, Italy",
  "Cologna Veneta, Italy",
  "Cologne, Germany",
  "Cologne, Italy",
  "Cologno al Serio, Italy",
  "Cologno Monzese, Italy",
  "Colognola ai Colli, Italy",
  "Coloma, United States",
  "Colombaro di Corte Franca, Italy",
  "Colombes, France",
  "Colombier-Saugnieu, France",
  "Colombo, Brazil",
  "Colombo, Sri Lanka",
  "Colomiers, France",
  "Colon Free Zone, Panama",
  "COLONIA DEL SACRAMENTO, Uruguay",
  "Colonial Heights, United States",
  "Colonnella, Italy",
  "Colorno, Italy",
  "Colsterworth Lincolnshire, United Kingdom",
  "Colturano, Italy",
  "Columbia, United States",
  "Columbia City, United States",
  "Columbus, United States",
  "Colwick Nottinghamshire, United Kingdom",
  "Colwyn Bay, United Kingdom",
  "Colzate, Italy",
  "Comabbio, Italy",
  "Comblanchien, France",
  "Combourtille, France",
  "Combs la Ville, France",
  "COMINES-KOMEN, Belgium",
  "Comiso, Italy",
  "Commack, United States",
  "Commerce, United States",
  "Commerce Township, United States",
  "Commessaggio, Italy",
  "Como, Italy",
  "Compiobbi, Italy",
  "Compton, United States",
  "Comun Nuovo, Italy",
  "Comunanza, Italy",
  "Comunion, Spain",
  "Cona, Italy",
  "Conakry, Guinea",
  "Concamarise, Italy",
  "Concarneau, France",
  "Concesio, Italy",
  "Concord, Canada",
  "Concordia Entre Rios, Argentina",
  "Concordia Sagittaria, Italy",
  "Concordia sulla Secchia, Italy",
  "Concorezzo, Italy",
  "Conde sur Huisne, France",
  "Conde sur Vire, France",
  "Condega, Nicaragua",
  "Condino, Italy",
  "Condom, France",
  "Condren, France",
  "Conegliano Veneto, Italy",
  "Confienza, Italy",
  "Conflans Ste Honorine, France",
  "Confolens Charente, France",
  "Confort, France",
  "Congleton, United Kingdom",
  "Conil de la Frontera, Spain",
  "Coniolo, Italy",
  "Conley, United States",
  "CONNELLYS SPRINGS, United States",
  "Connerre, France",
  "Conon Bridge, United Kingdom",
  "Conover, United States",
  "Conrad, United States",
  "Conroe, United States",
  "Conselice, Italy",
  "Conselve, Italy",
  "Consett, United Kingdom",
  "Conshohocken, United States",
  "Constanta, Romania",
  "Constanti, Spain",
  "Consuegra, Spain",
  "Contagem, Brazil",
  "Contai, India",
  "Contern, Luxemburg",
  "Contrecoeur, Canada",
  "Contres, France",
  "Contrevoz, France",
  "Controguerra, Italy",
  "Conway, United States",
  "Conwy, United Kingdom",
  "Conyers, United States",
  "Conza della Campania, Italy",
  "Cookeville, United States",
  "Cookstown Northern Ireland, United Kingdom",
  "Coolham West Sussex RH, United Kingdom",
  "Coolidge, United States",
  "Coon Rapids, United States",
  "Copenhagen, Denmark",
  "Copiano, Italy",
  "Copparo, Italy",
  "Coppell, United States",
  "Coppenbruegge, Germany",
  "Copthorne Surrey, United Kingdom",
  "Coquimbo, Chile",
  "Corabia, Romania",
  "Corana, Italy",
  "Coraopolis, United States",
  "Corato, Italy",
  "Corbanese, Italy",
  "Corbas, France",
  "Corbeil Essonnes, France",
  "Corbera de Llobregat, Spain",
  "Corbera Valencia, Spain",
  "Corbetta, Italy",
  "Corby Northamptonshire, United Kingdom",
  "Corca Girona, Spain",
  "Corcagnano, Italy",
  "Corciano, Italy",
  "Cordenons, Italy",
  "Cordignano, Italy",
  "Cordoba, Argentina",
  "Cordoba, Mexico",
  "Cordoba, Spain",
  "CORDOVA, United States",
  "Cordovado, Italy",
  "Cordovilla, Spain",
  "Corella, Spain",
  "Coreno Ausonio, Italy",
  "Coreses, Spain",
  "Corgo O, Spain",
  "Corgoloin, France",
  "Coria Caceres, Spain",
  "Coriano, Italy",
  "Corigliano Calabro, Italy",
  "Corinaldo, Italy",
  "Corinth, United States",
  "Corinto, Nicaragua",
  "Corio, Italy",
  "Cork Co Cork, Ireland",
  "Corlu, Turkey",
  "Cormano, Italy",
  "Cormeilles en Parisis, France",
  "Cormelles le Royal, France",
  "Cormons, Italy",
  "Cornaredo, Italy",
  "Cornate d'Adda, Italy",
  "Cornedo Vicentino, Italy",
  "Cornegliano Laudense, Italy",
  "Cornella de Llobregat, Spain",
  "Cornella del Terri, Spain",
  "Corno di Rosazzo, Italy",
  "Cornuda, Italy",
  "Cornwood Devon, United Kingdom",
  "Corona, United States",
  "Coronel, Chile",
  "Corpe, France",
  "Corpus Christi, United States",
  "Corral De Almaguer, Spain",
  "Corrales de Buelna, Spain",
  "Correggio, Italy",
  "Corridonia, Italy",
  "Corsham Wilts, United Kingdom",
  "Corsicana, United States",
  "Corsico, Italy",
  "Cortabbio, Italy",
  "Cortaccia sulla strada del vino, Italy",
  "Corte de' Frati, Italy",
  "Corte de Peleas, Spain",
  "Corte Franca, Italy",
  "Cortegaca, Portugal",
  "Cortemaggiore, Italy",
  "Cortenova, Italy",
  "Cortenuova, Italy",
  "Corteolona, Italy",
  "Cortes, Portugal",
  "Cortes Navarra, Spain",
  "Corum, Turkey",
  "Corunna, Canada",
  "Coruno, Spain",
  "Corveissiat, France",
  "Corvera Murcia, Spain",
  "Corwen, United Kingdom",
  "Corzano, Italy",
  "Coseano, Italy",
  "Coseley, United Kingdom",
  "Cosenza, Italy",
  "Coslada, Spain",
  "Cosne Cours sur Loire, France",
  "Cosne d'Allier, France",
  "Cosoleacaque, Mexico",
  "Cossano Belbo, Italy",
  "Cossato, Italy",
  "Costa di Mezzate, Italy",
  "Costa di Serina, Italy",
  "Costa Masnaga, Italy",
  "Costa Volpino, Italy",
  "Costabissara, Italy",
  "Costigliole d Asti, Italy",
  "Costigliole Saluzzo, Italy",
  "COSWIG - ANH, Germany",
  "Coswig (bei Dresden), Germany",
  "Cotgrave, United Kingdom",
  "Cotignola, Italy",
  "Coton Cambridgeshire, United Kingdom",
  "Cotonou, Benin",
  "Cottbus, Germany",
  "Cottingham E Ridings of Yorkshire, United Kingdom",
  "Couchey, France",
  "Coudekerque Branche, France",
  "Coueron, France",
  "Coufouleux, France",
  "Coullons, France",
  "Coulombs, France",
  "Coulommiers, France",
  "Coulsdon, United Kingdom",
  "Council Bluffs, United States",
  "Courbevoie, France",
  "Courcelles, Belgium",
  "Courcelles les lens, France",
  "Courchelettes, France",
  "Courcouronnes, France",
  "Courlaoux, France",
  "Cournon d'Auvergne, France",
  "Cournonsec, France",
  "Courpignac, France",
  "Cours la Ville, France",
  "Courthezon, France",
  "Courtland, United States",
  "Courtry, France",
  "Coutances, France",
  "Couzon au Mont d'Or, France",
  "Covas, Spain",
  "Coventry, United Kingdom",
  "Covilha, Portugal",
  "Covina, United States",
  "Covington, United States",
  "Covo, Italy",
  "Cowbridge, United Kingdom",
  "Cowdenbaeath, United Kingdom",
  "Cowley Oxon, United Kingdom",
  "Cowpens, United States",
  "Cowra, Australia",
  "Cox, Spain",
  "Cradley, United Kingdom",
  "Cradley Heath, United Kingdom",
  "Craigavon Northern Ireland, United Kingdom",
  "Craigellachie, United Kingdom",
  "Crailsheim Ubf, Germany",
  "Craiova, Romania",
  "Cramlington, United Kingdom",
  "Cran Gevrier, France",
  "Cranberry Township, United States",
  "Cranbrook, United Kingdom",
  "Cranbury, United States",
  "Crancey, France",
  "Crandall, United States",
  "Crane, United States",
  "Cranleigh, United Kingdom",
  "Craponne, France",
  "Craven Arms, United Kingdom",
  "Crawfordsville, United States",
  "Crawley W Sussex, United Kingdom",
  "Crayford, United Kingdom",
  "Craywick, France",
  "Creazzo, Italy",
  "Creches sur Saone, France",
  "Credaro, Italy",
  "Credera, Italy",
  "Crediton, United Kingdom",
  "Creglingen, Germany",
  "Creil, France",
  "Creil, Netherlands",
  "Crema, Italy",
  "Cremella, Italy",
  "Cremieu, France",
  "Cremnago di Inverigo, Italy",
  "Cremona, Italy",
  "Cremosano, Italy",
  "Crepy en Valois, France",
  "Crescentino, Italy",
  "Crespano Del Grappa, Italy",
  "Crespellano, Italy",
  "Crespiatica, Italy",
  "Crespino, Italy",
  "Crespos, Spain",
  "Cressa, Italy",
  "Cressona, United States",
  "Crestview, United States",
  "Crete, United States",
  "Creussen, Germany",
  "Creutzwald, France",
  "Creuzburg, Germany",
  "Creuzier le Vieux, France",
  "Crevalcore, Italy",
  "Crevant-Laveine, France",
  "Crevillent, Spain",
  "Crevin, France",
  "Crevoladossola, Italy",
  "Crewe Cheshire, United Kingdom",
  "Creysse, France",
  "Crezancy, France",
  "Crick Monmouthshire, United Kingdom",
  "Crick Northamptonshire, United Kingdom",
  "Cricklade, United Kingdom",
  "Crieff, United Kingdom",
  "Crimmitschau, Germany",
  "Criquebeuf sur Seine, France",
  "Crispiano, Italy",
  "Crissey Saone et Loire, France",
  "Crnice, Slovenia",
  "Crnomelj, Slovenia",
  "Crocetta Del Montello, Italy",
  "Croissy Beaubourg, France",
  "Croix, France",
  "Crook Cumbria, United Kingdom",
  "Crook Durham, United Kingdom",
  "Cropthorne Worcestershire, United Kingdom",
  "Crosby Sefton, United Kingdom",
  "Crossville, United States",
  "Crotone, Italy",
  "Crottendorf, Germany",
  "Crottet, France",
  "Crouy, France",
  "Crowborough, United Kingdom",
  "Crowley, United States",
  "Crown Point, United States",
  "Croxby Lincolnshire LN, United Kingdom",
  "Croydon London, United Kingdom",
  "Crumlin Antrim, United Kingdom",
  "Crusinallo, Italy",
  "Csenger, Hungary",
  "Csevharaszt, Hungary",
  "Csomor, Hungary",
  "Csongrad, Hungary",
  "Csorna, Hungary",
  "Cuarte de Huerva, Spain",
  "Cuautitlan Izcalli, Mexico",
  "Cubas de la Sagra, Spain",
  "Cubatao, Brazil",
  "Cubillas de Santa Marta, Spain",
  "Cubillos del Sil, Spain",
  "Cublize, France",
  "Cucamonga, United States",
  "Cucciago, Italy",
  "Cuceglio, Italy",
  "Cuckfield, United Kingdom",
  "Cudahy, United States",
  "Cuddalore, India",
  "Cuellar, Spain",
  "Cuenca, Spain",
  "Cuernavaca, Mexico",
  "Cuevas de Almanzora, Spain",
  "Cuffies, France",
  "Cuggiono, Italy",
  "Cuijk, Netherlands",
  "Cuiseaux, France",
  "Cuisery, France",
  "Cuitlahuac, Mexico",
  "Culemborg, Netherlands",
  "Culiacan, Mexico",
  "Cullar Granada, Spain",
  "Culleredo, Spain",
  "Cullinan, South Africa",
  "Cullingworth, United Kingdom",
  "Culloden, United States",
  "Cullompton Devon, United Kingdom",
  "Cullybackey Northern Ireland, United Kingdom",
  "Culoz, France",
  "Cumberland, United States",
  "Cumbernauld, United Kingdom",
  "Cumiana, Italy",
  "Cumming, United States",
  "Cumnock, United Kingdom",
  "Cuneo, Italy",
  "Cunevo, Italy",
  "Cupar, United Kingdom",
  "Cupramontana, Italy",
  "Cura Carpignano, Italy",
  "Curico, Chile",
  "Curitiba, Brazil",
  "Curno, Italy",
  "Curtarolo, Italy",
  "Cusago, Italy",
  "Cusano Milanino, Italy",
  "Custonaci, Italy",
  "Cut And Shoot, United States",
  "Cutnall Green, United Kingdom",
  "Cuvio, Italy",
  "Cuxhaven, Germany",
  "Cuyahoga Falls, United States",
  "Cvikov, Czech Republic",
  "Cwmbran, United Kingdom",
  "Czaplinek, Poland",
  "Czarna Bialostocka, Poland",
  "Czarnkow, Poland",
  "Czchow, Poland",
  "Czechowice-Dziedzice, Poland",
  "Czeladz, Poland",
  "Czerwiensk, Poland",
  "Czestochowa, Poland",
  "Czudec, Poland",
  "Da Nang, Vietnam",
  "Dabas, Hungary",
  "Dabrowa Gornicza, Poland",
  "Dachau, Germany",
  "Dachstein, France",
  "Dacice, Czech Republic",
  "Dadri, India",
  "Daegu Taegu, Korea, South",
  "Dafeng, China",
  "Daganzo de Arriba, Spain",
  "Dagenham, United Kingdom",
  "Dagmersellen, Switzerland",
  "Dagneux, France",
  "Dahegam, India",
  "Dahej, India",
  "Dahej SEZ, India",
  "Dahlenburg, Germany",
  "Daimiel, Spain",
  "Daimus, Spain",
  "Dairago, Italy",
  "Dakar, Senegal",
  "Dakota, United States",
  "Dalbeattie, United Kingdom",
  "Daleville, United States",
  "Dalian, China",
  "Dalkeith, United Kingdom",
  "Dallas, United States",
  "Dalmine, Italy",
  "Dalston Cumbria, United Kingdom",
  "Dalton, United States",
  "Dalton In Furness, United Kingdom",
  "Dalton N Yorkshire, United Kingdom",
  "DALZAVOD, Russia",
  "Daman, India",
  "Dambach la Ville, France",
  "Damietta, Egypt",
  "Dammam, Saudi Arabia",
  "Dammarie les Lys, France",
  "Damparis, France",
  "Damyang, Korea, South",
  "Dandridge, United States",
  "Dange Saint Romain, France",
  "Dania, United States",
  "Danli, Honduras",
  "Danville, United States",
  "Daours, France",
  "Dar Bouazza, Morocco",
  "Dar es Salaam, Tanzania",
  "Dardilly, France",
  "Darfo, Italy",
  "Dargun, Germany",
  "Darlaston, United Kingdom",
  "Darlington, United Kingdom",
  "Darmstadt, Germany",
  "Darnall, United Kingdom",
  "Daroca, Spain",
  "Dartford, United Kingdom",
  "Dartmouth, Canada",
  "Darton Sth Yorkshire, United Kingdom",
  "Darwen, United Kingdom",
  "Dassow, Germany",
  "Datteln, Germany",
  "Daumeray, France",
  "Dautphetal, Germany",
  "Davao City, Philippines",
  "Davenport, United States",
  "Daventry, United Kingdom",
  "Daverio, Italy",
  "Davezieux, France",
  "David, Panama",
  "Davie, United States",
  "Davis Monthan AFB, United States",
  "Davydovo, Russia",
  "Dax, France",
  "Dayton, United States",
  "Dayville, United States",
  "De Leon Springs, United States",
  "De Meern, Netherlands",
  "De Rijp, Netherlands",
  "Deal Kent, United Kingdom",
  "Deal Party, South Africa",
  "Dearborn, United States",
  "Dearborn Heights, United States",
  "Deba, Spain",
  "Debelt, Bulgaria",
  "Debenham, United Kingdom",
  "Debica, Poland",
  "Debnica Kaszubska, Poland",
  "Debno Lubuskie, Poland",
  "Debowiec, Poland",
  "Debrecen, Hungary",
  "Decatur, United States",
  "Decherd, United States",
  "Decin, Czech Republic",
  "Decines Charpieu, France",
  "Decollatura, Italy",
  "Deer Park, United States",
  "Deerlijk, Belgium",
  "Deeside Industrial Park, United Kingdom",
  "Defiance, United States",
  "Deggendorf, Germany",
  "Deggenhausertal, Germany",
  "Deggingen, Germany",
  "Dego, Italy",
  "Dehradun, India",
  "Deil, Netherlands",
  "Deinze, Belgium",
  "Deisslingen, Germany",
  "Deizisau, Germany",
  "Dekalb, United States",
  "Dekani, Slovenia",
  "Dekheila - Alexandria, Egypt",
  "Del Rio, United States",
  "Delanco, United States",
  "Delavan, United States",
  "Delaware City, United States",
  "Delaware Water Gap, United States",
  "Delbrueck, Germany",
  "Delden, Netherlands",
  "Delebio, Italy",
  "Delfgauw, Netherlands",
  "Delft, Netherlands",
  "Delfzijl, Netherlands",
  "Delhi, United States",
  "Delitzsch, Germany",
  "Dello, Italy",
  "Delmas, South Africa",
  "Delmenhorst, Germany",
  "Delta, Canada",
  "Dematagoda, Sri Lanka",
  "Deming, United States",
  "Demjen, Hungary",
  "Demopolis, United States",
  "Den Bosch - S-Hertogenbosch, Netherlands",
  "Den Helder, Netherlands",
  "Denaby, United Kingdom",
  "Denain, France",
  "Denbigh, United Kingdom",
  "Denderleeuw, Belgium",
  "Dendermonde, Belgium",
  "Denizli, Turkey",
  "Denkendorf ( Kr Esslingen), Germany",
  "Denkendorf (Oberbayern), Germany",
  "Denklingen (Oberbayern), Germany",
  "Denmark, United States",
  "DENNO, Italy",
  "Denny, United Kingdom",
  "Densborn, Germany",
  "Denton, United States",
  "Denton Tameside, United Kingdom",
  "Denver, United States",
  "Depalpur, Pakistan",
  "Depew, United States",
  "Deposit, United States",
  "Derby, United Kingdom",
  "Dereham East, United Kingdom",
  "Deridder, United States",
  "Derince, Turkey",
  "Derio, Spain",
  "Deruta, Italy",
  "Des Moines, United States",
  "Des Plaines, United States",
  "Desborough, United Kingdom",
  "Dese, Italy",
  "Desenzano del Garda, Italy",
  "Desford, United Kingdom",
  "Desio, Italy",
  "Deskle, Slovenia",
  "Desna, Czech Republic",
  "DESSAU-ROSSLAU, Germany",
  "Desselgem, Belgium",
  "Desteldonk, Belgium",
  "Desvres, France",
  "Deszczno, Poland",
  "Detmarovice, Czech Republic",
  "Detmold, Germany",
  "Detroit, United States",
  "Dettelbach, Germany",
  "Dettingen (an der Erms), Germany",
  "Dettingen (unter Teck), Germany",
  "Dettwiller, France",
  "Detva, Slovakia",
  "Deurne, Belgium",
  "Deurne, Netherlands",
  "Deventer, Netherlands",
  "Deville les Rouen, France",
  "Devizes, United Kingdom",
  "Devnya, Bulgaria",
  "Devonport, Australia",
  "Devonport, United Kingdom",
  "Dewsbury, United Kingdom",
  "Dhaka, Bangladesh",
  "Dhamrai, Bangladesh",
  "Dhannad, India",
  "Dianalund, Denmark",
  "Dicastillo, Spain",
  "Dickson, United States",
  "Didcot, United Kingdom",
  "Didi Lilo, Georgia",
  "Die, France",
  "Dieblich, Germany",
  "Dieburg, Germany",
  "Diedorf (Schwab), Germany",
  "Dielheim, Germany",
  "Diemelstadt, Germany",
  "Dienville, France",
  "Diepenau, Germany",
  "Diepenbeek, Belgium",
  "Diepholz, Germany",
  "Dieppe, France",
  "Dierdorf, Germany",
  "Diest, Belgium",
  "Dietach, Austria",
  "Dietenheim, Germany",
  "Dietenhofen, Germany",
  "Dietersburg, Germany",
  "Dietmannsried, Germany",
  "Dietzenbach, Germany",
  "Diez, Germany",
  "DIFFERDANGE-NIEDERKORN, Luxemburg",
  "Dighton, United States",
  "Dignano, Italy",
  "Digoin, France",
  "Dijon, France",
  "Dilbeek, Belgium",
  "Dili, Timor Leste",
  "Diliskelesi, Turkey",
  "Dillenburg, Germany",
  "Dilling, Norway",
  "Dillingen (Saar), Germany",
  "Dillon, United States",
  "Dilsen, Belgium",
  "Dinar-Afyon, Turkey",
  "Dinard, France",
  "Dinazzano, Italy",
  "Dindigul, India",
  "Dingelstaedt, Germany",
  "Dingolfing, Germany",
  "Dingwall, United Kingdom",
  "Dinkelsbuehl, Germany",
  "Dinkelscherben, Germany",
  "Dinklage, Germany",
  "Dinnington Rotherham S, United Kingdom",
  "Dinsheim, France",
  "Dinslaken, Germany",
  "Dintikon, Switzerland",
  "Diors, France",
  "Dippoldiswalde, Germany",
  "Dire Dawa, Ethiopia",
  "Dirinon, France",
  "Dischingen, Germany",
  "Disenaa, Norway",
  "Diss Norfolk, United Kingdom",
  "Dissay Vienne France, France",
  "Dissen (am Teutoburger Wald), Germany",
  "District Heights, United States",
  "Ditchling, United Kingdom",
  "Dittersdorf, Germany",
  "Ditzingen, Germany",
  "Divaca, Slovenia",
  "Divisov, Czech Republic",
  "Divonne les Bains, France",
  "Dixon, United States",
  "Diyarbakir, Turkey",
  "Djibouti, Djibouti",
  "Dlugoleka, Poland",
  "Dlugopole Zdroj, Poland",
  "Dmitrov, Russia",
  "Dnipro, Ukraine",
  "Dobanovci, Serbia",
  "Dobczyce, Poland",
  "Doberlug-Kirchhain, Germany",
  "Dobl, Austria",
  "Dobra, Poland",
  "Dobrany, Czech Republic",
  "Dobre Miasto, Poland",
  "Dobrejovice, Czech Republic",
  "Dobrenice, Czech Republic",
  "Dobrich, Bulgaria",
  "Dobris, Czech Republic",
  "Dobromierz K Kielce, Poland",
  "Dobron, Poland",
  "Dobroszyce, Poland",
  "Dobrovice, Czech Republic",
  "Dobroviz, Czech Republic",
  "Dobruska, Czech Republic",
  "Dobrzyca, Poland",
  "Dobsina, Slovakia",
  "Dodewaard, Netherlands",
  "Dodge City, United States",
  "Dodici Morelli, Italy",
  "Doerentrup, Germany",
  "Doerth, Germany",
  "Doerzbach, Germany",
  "Doetinchem, Netherlands",
  "Dogern, Germany",
  "Doha, Qatar",
  "Dohna, Germany",
  "Doicesti, Romania",
  "Dojc, Slovakia",
  "Dokkum, Netherlands",
  "Dokuchayevske, Ukraine",
  "Dolany, Czech Republic",
  "Dolce, Italy",
  "Dolenjske Toplice, Slovenia",
  "Dollern, Germany",
  "Dolna Banya, Bulgaria",
  "Dolna Mitropolija, Bulgaria",
  "Dolna Streda, Slovakia",
  "Dolne Vestenice, Slovakia",
  "Dolni Becva, Czech Republic",
  "Dolni Benesov, Czech Republic",
  "Dolni Bousov, Czech Republic",
  "Dolni Dobrouc, Czech Republic",
  "Dolni Jircany, Czech Republic",
  "Dolni Kalna, Czech Republic",
  "Dolni Lhota U Luhacovic, Czech Republic",
  "Dolni Podluzi, Czech Republic",
  "Dolni Rychnov, Czech Republic",
  "Dolny Kubin, Slovakia",
  "Dolo, Italy",
  "Dolores, Spain",
  "Dolsk, Poland",
  "Dom Aquino, Brazil",
  "Domagne, France",
  "Domazan, France",
  "Domazelice, Czech Republic",
  "Domazlice, Czech Republic",
  "Dombasle sur Meurthe, France",
  "Domblans, France",
  "Dombuehl, Germany",
  "Domdidier, Switzerland",
  "Domegge di Cadore, Italy",
  "Domegliara, Italy",
  "Domene, France",
  "Domerat, France",
  "Domevre sur Durbion, France",
  "Domlur, India",
  "Domodedovo, Russia",
  "Domodossola, Italy",
  "Domokos, Greece",
  "Dompierre-sur-yon, France",
  "Domzale, Slovenia",
  "Don Benito, Spain",
  "Donaueschingen, Germany",
  "DONCASTER, United Kingdom",
  "Donegal Co Donegal, Ireland",
  "Dongen, Netherlands",
  "Dongguan, China",
  "Donnersdorf, Germany",
  "Donnington Shropshire, United Kingdom",
  "Donoratico, Italy",
  "Donzdorf, Germany",
  "Doral, United States",
  "Doraville, United States",
  "Dorchester Dorset, United Kingdom",
  "Dordon, United Kingdom",
  "Dordrecht, Netherlands",
  "Dorfen (Stadt), Germany",
  "Dorfprozelten, Germany",
  "Dorking, United Kingdom",
  "Dormagen, Germany",
  "Dormansland, United Kingdom",
  "Dormettingen, Germany",
  "Dornach, Switzerland",
  "Dornbirn, Austria",
  "Dornburg (Westerw), Germany",
  "Dornburg-Camburg, Germany",
  "Dornhan, Germany",
  "Dorno, Italy",
  "Dornstadt, Germany",
  "Dornstetten, Germany",
  "Dorsten, Germany",
  "Dortmund, Germany",
  "Dortyol, Turkey",
  "Dorval, Canada",
  "Dos Hermanas, Spain",
  "Dosbarrios, Spain",
  "Dosolo, Italy",
  "Dospat, Bulgaria",
  "Dosrius, Spain",
  "Dossenheim, Germany",
  "Dosso, Italy",
  "Dossobuono, Italy",
  "Dosson di Casier, Italy",
  "Dothan, United States",
  "Dotternhausen, Germany",
  "Douai, France",
  "Douala, Cameroon",
  "Douarnenez, France",
  "Douglas, United States",
  "Douglassville, United States",
  "Doulevant le Chateau, France",
  "Doullens, France",
  "Doumen, China",
  "Dourges, France",
  "Douvres la Delivrande, France",
  "Douz, Tunisia",
  "Dover, United Kingdom",
  "Dover, United States",
  "DOVER AFB, United States",
  "Dovera, Italy",
  "Downey, United States",
  "Downham Market, United Kingdom",
  "Downpatrick Northern Ireland, United Kingdom",
  "Doyle, United States",
  "Drachten, Netherlands",
  "Dragalina, Romania",
  "Drahovce, Slovakia",
  "Drama, Greece",
  "Drammen, Norway",
  "Drancy, France",
  "Draperstown Northern Ireland, United Kingdom",
  "Drapetsona, Greece",
  "Drasov, Czech Republic",
  "Draveil, France",
  "Dravograd, Slovenia",
  "Dreieich, Germany",
  "Dreilini, Latvia",
  "Dreisbach, Germany",
  "Dresano, Italy",
  "Dresden, Germany",
  "Dreux, France",
  "Drezdenko, Poland",
  "Drianovo, Bulgaria",
  "Driedorf, Germany",
  "Driffield East Riding, United Kingdom",
  "Drighlington Leeds, United Kingdom",
  "Drimos, Greece",
  "Drinagh Co Cork, Ireland",
  "Drogheda Louth, Ireland",
  "Droitwich, United Kingdom",
  "Drolshagen, Germany",
  "Dromore Banbridge, United Kingdom",
  "Dronero, Italy",
  "Dronfield Derbyshire, United Kingdom",
  "Drongen, Belgium",
  "Dronninglund, Denmark",
  "Dronten, Netherlands",
  "Droue sur Drouette, France",
  "Droylsden, United Kingdom",
  "Druento, Italy",
  "Drulingen, France",
  "Drummondville, Canada",
  "Drumnadrochit, United Kingdom",
  "Drusenheim, France",
  "Drzkov, Czech Republic",
  "Duartina, Brazil",
  "Dubai, United Arab Emirates",
  "Dubai Industrial City, United Arab Emirates",
  "Dubai Investment Park, United Arab Emirates",
  "Dubi u Teplic, Czech Republic",
  "Dubiecko, Poland",
  "Dubino, Italy",
  "Dublin, Ireland",
  "Dublin, United States",
  "Dubnica nad Vahom, Slovakia",
  "Duchcov, Czech Republic",
  "Duchnice, Poland",
  "Dudelange, Luxemburg",
  "Dudeldorf, Germany",
  "Duderstadt, Germany",
  "Dudley W Midlands, United Kingdom",
  "Dudullu, Turkey",
  "Due Carrare, Italy",
  "Duebendorf, Switzerland",
  "Duedenbuettel, Germany",
  "Duelmen, Germany",
  "Dueren, Germany",
  "Duermentingen, Germany",
  "Duerrholz, Germany",
  "Duerrwangen, Germany",
  "Duesseldorf, Germany",
  "Dueville, Italy",
  "Duffel, Belgium",
  "Dugo Selo, Croatia",
  "Duingen, Germany",
  "Duino, Italy",
  "Duino-Aurisina, Italy",
  "Duisans, France",
  "Duisburg, Germany",
  "Dukinfield, United Kingdom",
  "Duleek Co Meath, Ireland",
  "Dulovo, Bulgaria",
  "Duluth, United States",
  "Dulverton Somerset, United Kingdom",
  "Dulwich London, United Kingdom",
  "Dumbarton, United Kingdom",
  "Dumbria, Spain",
  "Dumfries, United Kingdom",
  "Dunaharaszti, Hungary",
  "Dunajska Luzna, Slovakia",
  "Dunajska Streda, Slovakia",
  "Dunakeszi, Hungary",
  "Dunavarsany, Hungary",
  "Dunbar, United Kingdom",
  "Dunblane, United Kingdom",
  "Duncan, United States",
  "Duncansville, United States",
  "Dundalk Louth, Ireland",
  "DUNDEE, United Kingdom",
  "Dunedin, United States",
  "Dunfermline, United Kingdom",
  "Dungannon Northern Ireland, United Kingdom",
  "Dunkerque, France",
  "Dunnigan, United States",
  "Duns, United Kingdom",
  "Dunstable, United Kingdom",
  "Dupnitza, Bulgaria",
  "Duppigheim, France",
  "Duquesne, United States",
  "Duran - Guayas, Ecuador",
  "Durango, Mexico",
  "Durango, Spain",
  "DURAZNO, Uruguay",
  "Durban, South Africa",
  "Durbanville, South Africa",
  "Durgapur, India",
  "Durham, United Kingdom",
  "Durham, United States",
  "Durres, Albania",
  "Dursley, United Kingdom",
  "Dusino San Michele, Italy",
  "Dusslingen, Germany",
  "Dutch Harbor, United States",
  "Duttlenheim, France",
  "Duxford, United Kingdom",
  "Duzce, Turkey",
  "Dvor, Slovenia",
  "DVUR KRALOVE NAD LABEM, Czech Republic",
  "Dwikozy, Poland",
  "Dyce, United Kingdom",
  "Dyess, United States",
  "Dyje, Czech Republic",
  "Dymokury, Czech Republic",
  "Dysina u Plzne, Czech Republic",
  "Dywity, Poland",
  "Dzerzhinsk, Russia",
  "Dzialdowo, Poland",
  "Dzialoszyn, Poland",
  "Dzierzoniow, Poland",
  "Eagle Pass, United States",
  "Eaglescliffe Stockton, United Kingdom",
  "Ealing London W, United Kingdom",
  "Earlimart, United States",
  "Earls Barton, United Kingdom",
  "Earls Colne, United Kingdom",
  "Easingwold, United Kingdom",
  "East Berlin, United States",
  "East Brunswick, United States",
  "East Granby, United States",
  "East Greenville, United States",
  "East Grinstead, United Kingdom",
  "East Harling NR, United Kingdom",
  "East Kilbride, United Kingdom",
  "East Liberty, United States",
  "East London, South Africa",
  "East Molesey, United Kingdom",
  "East Peckham, United Kingdom",
  "East Peoria, United States",
  "East Point, United States",
  "East Providence, United States",
  "East Setauket, United States",
  "East Troy, United States",
  "East Windsor, United States",
  "Eastaboga, United States",
  "Eastbourne E Sussex, United Kingdom",
  "Eastchurch Kent, United Kingdom",
  "Eastlake, United States",
  "Eastleigh Hampshire, United Kingdom",
  "Easton, United States",
  "Eastry Kent, United Kingdom",
  "Eaton, United States",
  "Eaton Socon Cambridgeshire PE, United Kingdom",
  "Eau Claire, United States",
  "Eauze, France",
  "Ebbw Vale, United Kingdom",
  "Ebensee, Austria",
  "Eberbach (Baden), Germany",
  "Eberdingen, Germany",
  "Eberhardzell, Germany",
  "Ebermannsdorf, Germany",
  "Ebermannstadt, Germany",
  "Ebern, Germany",
  "Ebernhahn, Germany",
  "Ebersbach (an der Fils), Germany",
  "Ebersbach-Neugersdorf, Germany",
  "Ebersberg (Oberbayern), Germany",
  "Eberschwang, Austria",
  "Ebersdorf (Oberfr), Germany",
  "Eboli, Italy",
  "Ebsdorfergrund, Germany",
  "Eccles Salford, United Kingdom",
  "Eccleshall Staffs, United Kingdom",
  "Echassieres, France",
  "Echauffour, France",
  "Eching, Germany",
  "Eching (Kr Freising), Germany",
  "Eching (Niederbay), Germany",
  "Echirolles, France",
  "Echt, Netherlands",
  "Echternach, Luxemburg",
  "Ecija, Spain",
  "Eckbolsheim, France",
  "Eckental, Germany",
  "Eckernfoerde, Germany",
  "Eclectic, United States",
  "Ecru, United States",
  "Edam, Netherlands",
  "Eddystone, United States",
  "Ede, Netherlands",
  "Edegem, Belgium",
  "Edenbridge, United Kingdom",
  "Edenkoben, Germany",
  "Edermuende Besse, Germany",
  "Edessa, Greece",
  "Edewecht, Germany",
  "Edgerton, United States",
  "Edgeware London, United Kingdom",
  "Edina, United States",
  "Edinburgh, United Kingdom",
  "Edingen Neckarhausen, Germany",
  "Edirne-Edirne, Turkey",
  "Edison, United States",
  "Edling, Germany",
  "Edmonton, Canada",
  "Edmonton, United Kingdom",
  "Edolo, Italy",
  "Edremit, Turkey",
  "Edzell, United Kingdom",
  "Eeklo, Belgium",
  "Eemshaven, Netherlands",
  "Eferding, Austria",
  "Effingham, United Kingdom",
  "Efringen Kirchen, Germany",
  "EGALEO, Greece",
  "Egelsbach, Germany",
  "Egerkingen, Switzerland",
  "Egersund, Norway",
  "Eggenfelden, Germany",
  "Eggenstein-Leopoldshafen, Germany",
  "Eggenthal, Germany",
  "Egglham, Germany",
  "Egglkofen, Germany",
  "Eggolsheim, Germany",
  "Eggstaett, Germany",
  "Egham, United Kingdom",
  "Eging am See, Germany",
  "Egio, Greece",
  "Egletons Correze, France",
  "Eglfing (Oberbay), Germany",
  "Egling, Germany",
  "Egmating, Germany",
  "Egna, Italy",
  "Egnach, Switzerland",
  "Egremont, United Kingdom",
  "Egridir, Turkey",
  "Egues, Spain",
  "Eguilles, France",
  "Ehingen (Donau), Germany",
  "Ehningen, Germany",
  "Ehrenfriedersdorf, Germany",
  "Ehrenkirchen, Germany",
  "Ehringshausen (Dill), Germany",
  "Eibar, Spain",
  "Eibau, Germany",
  "Eibelstadt, Germany",
  "Eibergen, Netherlands",
  "Eichelhardt, Germany",
  "Eichenzell, Germany",
  "Eichstaett, Germany",
  "Eijgelshoven, Netherlands",
  "Eijsden, Netherlands",
  "Eikenhof, South Africa",
  "Eilabun, Israel",
  "Eilenburg, Germany",
  "Eilsleben, Germany",
  "Einbeck, Germany",
  "Eindhout, Belgium",
  "Eindhoven, Netherlands",
  "Eisenach (Thuer), Germany",
  "Eisenberg (Pfalz), Germany",
  "Eisenberg (Thuer), Germany",
  "Eisenhuettenstadt, Germany",
  "Eisfeld, Germany",
  "Eislingen (Fils), Germany",
  "Eitensheim, Germany",
  "Eiterfeld, Germany",
  "Eitorf, Germany",
  "Ejea de Los Caballeros, Spain",
  "Ejpovice, Czech Republic",
  "Ejstrupholm, Denmark",
  "Eksjoe, Sweden",
  "El Agba, Tunisia",
  "El Alamo, Spain",
  "El Albujon, Spain",
  "El Boalo, Spain",
  "El Burgo de Ebro, Spain",
  "El Campo, United States",
  "El Carmen - Manabi, Ecuador",
  "El Ciego, Spain",
  "El Cuervo Sevilla, Spain",
  "El Ejido, Spain",
  "El Empalme - Guayas, Ecuador",
  "El Fayoum, Egypt",
  "El Grao, Spain",
  "El Guabo, Ecuador",
  "El Hajeb, Morocco",
  "El Herrumblar, Spain",
  "El Jadida, Morocco",
  "El Kalyoubeyah, Egypt",
  "El Menia, Egypt",
  "El Mirador, Spain",
  "El Mojon De Beniel, Spain",
  "El Monte, United States",
  "El Morell, Spain",
  "El Palmar Murcia, Spain",
  "El Papiol, Spain",
  "El Paso, United States",
  "El Pedernoso, Spain",
  "El Pedregal, El Salvador",
  "El Pla de Sta Maria, Spain",
  "El Progreso, Guatemala",
  "El Progreso, Honduras",
  "El Provencio, Spain",
  "El Rancho, Guatemala",
  "El Repilado, Spain",
  "El Rosario, El Salvador",
  "El Rubio, Spain",
  "El Raal, Spain",
  "El Salto, Mexico",
  "El Triunfo - Guayas, Ecuador",
  "El Viejo, Nicaragua",
  "El Zaqaziq, Egypt",
  "El Aaiun, Morocco",
  "Elandsfontein, South Africa",
  "Elazig, Turkey",
  "Elblag, Poland",
  "Elbridge, United States",
  "Elburg, Netherlands",
  "Elche, Spain",
  "Elchingen, Germany",
  "Elciego, Spain",
  "Elda, Spain",
  "Elduain, Spain",
  "Elefsina, Greece",
  "Elgeta, Spain",
  "Elgin, United Kingdom",
  "Elgin, United States",
  "Elgoibar, Spain",
  "Elimaki, Finland",
  "Elin Pelin, Bulgaria",
  "Elizabeth, United States",
  "Elizabethtown, United States",
  "ELIZONDO, Spain",
  "Eljas, Spain",
  "Elk, Poland",
  "Elk Grove Village, United States",
  "Elk Rapids, United States",
  "Elkhart, United States",
  "Elkin, United States",
  "Elkridge, United States",
  "Elkton, United States",
  "Elland, United Kingdom",
  "Ellefeld, Germany",
  "Ellera Umbra, Italy",
  "Ellesmere Port, United Kingdom",
  "Ellesmere Shropshire, United Kingdom",
  "Ellicott City, United States",
  "Ellington Cambrigdeshire, United Kingdom",
  "Ellon, United Kingdom",
  "Ellrich, Germany",
  "Ellwangen (Jagst), Germany",
  "Ellzee, Germany",
  "Elm City, United States",
  "Elma, United States",
  "Elmendorf, United States",
  "Elmer, United States",
  "Elmhurst, United States",
  "Elmira, Canada",
  "Elmira Heights, United States",
  "Elmshorn, Germany",
  "Elmswell, United Kingdom",
  "Elmwood Park, United States",
  "Elne, France",
  "Elnesvaagen, Norway",
  "Elora, Canada",
  "Elorrio, Spain",
  "Els Hostalets de Pierola, Spain",
  "Els Monjos, Spain",
  "Elsdorf (Erftkreis), Germany",
  "Elsendorf, Germany",
  "Elsenfeld, Germany",
  "Elsenham Essex, United Kingdom",
  "Elsham, United Kingdom",
  "Elst, Netherlands",
  "Elsteraue, Germany",
  "Elsterheide, Germany",
  "Elsterwerda, Germany",
  "Elton Cheshire, United Kingdom",
  "Eltville (Am Rhein), Germany",
  "Elven, France",
  "Elvington, United Kingdom",
  "Elwood, United States",
  "Ely Cambs, United Kingdom",
  "Elzach, Germany",
  "Elztal, Germany",
  "Embrach, Switzerland",
  "Emden, Germany",
  "Emerainville, France",
  "Emiliano Zapata, Mexico",
  "Eminonu, Turkey",
  "Emlichheim, Germany",
  "Emmaste, Estonia",
  "Emmeloord, Netherlands",
  "Emmen, Netherlands",
  "Emmen, Switzerland",
  "Emmendingen, Germany",
  "Emmerich, Germany",
  "Empfertshausen, Germany",
  "Empfingen, Germany",
  "Empoli, Italy",
  "Emporia, United States",
  "Emsbueren, Germany",
  "Emsdetten, Germany",
  "Emskirchen, Germany",
  "Emstek, Germany",
  "Emsworth, United Kingdom",
  "Emyvale, Ireland",
  "Encinas Reales, Spain",
  "Enderby, United Kingdom",
  "Endine Gaiano, Italy",
  "Endingen (Kaiserstuhl), Germany",
  "Eneriz, Spain",
  "Enez, Turkey",
  "Enfield, United Kingdom",
  "Enfield, United States",
  "Engelhartszell, Austria",
  "Engelsberg (Oberbayern), Germany",
  "Engen, Germany",
  "Enger, Germany",
  "Englefield Green, United Kingdom",
  "Enguera, Spain",
  "Eningen Unter Achalm, Germany",
  "Enkenbach Alsenborn, Germany",
  "Enkhuizen, Netherlands",
  "Enkoeping, Sweden",
  "Ennepetal, Germany",
  "Ennery, France",
  "Enney, Switzerland",
  "Ennezat, France",
  "Ennfida, Tunisia",
  "Ennigerloh, Germany",
  "Ennis, United States",
  "Ennis Clare, Ireland",
  "Enniskillen Northern Ireland, United Kingdom",
  "Ennore Chennai, India",
  "Enns, Austria",
  "Ennsdorf, Austria",
  "Enoree, United States",
  "Enschede, Netherlands",
  "Ense, Germany",
  "Ensenada, Mexico",
  "Ensisheim, France",
  "Enstone, United Kingdom",
  "Entebbe, Uganda",
  "Enter, Netherlands",
  "Entrambasaguas, Spain",
  "Entrena, Spain",
  "Entroncamento, Portugal",
  "Entzheim, France",
  "Envie, Italy",
  "Epercieux, France",
  "Epernay, France",
  "Ephrata, United States",
  "Epinay sur Seine, France",
  "Eppelborn, Germany",
  "Eppelheim, Germany",
  "EPPING, South Africa",
  "EPPING, United Kingdom",
  "Eppingen, Germany",
  "Eppstein (Taunus), Germany",
  "Epsom, United Kingdom",
  "Erandio, Spain",
  "Erba, Italy",
  "Erbach (Donau), Germany",
  "Erbach (Odenw), Germany",
  "Erbe', Italy",
  "Erbendorf, Germany",
  "Erbes-Buedesheim, Germany",
  "Erbezzo, Italy",
  "Erbil, Iraq",
  "Erbusco, Italy",
  "Ercolano, Italy",
  "Erdemli, Turkey",
  "Erding, Germany",
  "Erdington, United Kingdom",
  "Erdmannhausen, Germany",
  "Eregli-Konya, Turkey",
  "Eregli-Zonguldak, Turkey",
  "Erembodegem, Belgium",
  "Erftstadt, Germany",
  "Erfurt, Germany",
  "Erie, United States",
  "Ering, Germany",
  "Eriskirch, Germany",
  "Erith, United Kingdom",
  "Erkelenz, Germany",
  "Erkrath, Germany",
  "Erla, Spain",
  "Erlangen, Germany",
  "Erlanger, United States",
  "Erlau, Germany",
  "Erlenbach, Germany",
  "Erlenbach (am Main), Germany",
  "Erlensee, Germany",
  "Erligheim, Germany",
  "Erlinsbach, Switzerland",
  "Ermelo, Netherlands",
  "Ermont, France",
  "Ermsleben, Germany",
  "Ermua, Spain",
  "Erndtebrueck, Germany",
  "Ernsgaden, Germany",
  "Ernstthal, Germany",
  "Erpe Mere, Belgium",
  "Erquy, France",
  "Errol Perthshire PH, United Kingdom",
  "Erskine, United Kingdom",
  "Erstein, France",
  "Ertingen, Germany",
  "Ertvelde, Belgium",
  "Erwarton Suffolk, United Kingdom",
  "Erwitte, Germany",
  "Erzincan, Turkey",
  "Esanatoglia, Italy",
  "Esbjerg, Denmark",
  "Escalon, United States",
  "Escalona, Spain",
  "Escalonilla, Spain",
  "Escalquens, France",
  "Esch sur Alzette, Luxemburg",
  "Eschau, Germany",
  "Eschbach, Germany",
  "Eschborn, Germany",
  "Eschenbach (Oberpfalz), Germany",
  "Eschenburg, Germany",
  "Eschershausen, Germany",
  "Eschwege, Germany",
  "Eschweiler (Rheinland), Germany",
  "Escoriaza, Spain",
  "Escrennes, France",
  "Escucha, Spain",
  "Escuintla, Guatemala",
  "Escuzar Granada, Spain",
  "Esenler, Turkey",
  "Esenyurt, Turkey",
  "Esher, United Kingdom",
  "Eskil, Turkey",
  "Eskilstuna, Sweden",
  "Eskisehir, Turkey",
  "Esloev, Sweden",
  "Esmans, France",
  "ESME, Turkey",
  "Esmeraldas - Esmeraldas, Ecuador",
  "Esmoriz, Portugal",
  "Esparraguera, Spain",
  "Esparreguera, Spain",
  "Espartinas, Spain",
  "Esparza de Galar, Spain",
  "Espel, Netherlands",
  "Espelkamp, Germany",
  "Espenhain, Germany",
  "Espiel, Spain",
  "Espinardo, Spain",
  "Espinho, Portugal",
  "Esplugues de Llobregat, Spain",
  "Esplus Huesca, Spain",
  "Espoo, Finland",
  "Esposende, Portugal",
  "Esquiroz, Spain",
  "Esquivias, Spain",
  "Essen, Belgium",
  "Essen, Germany",
  "Essen (Oldenburg), Germany",
  "Essenbach, Germany",
  "Essingen (Wuertt), Germany",
  "Esslingen (am Neckar), Germany",
  "Estacion Blanca, Spain",
  "Estadilla, Spain",
  "Estarreja, Portugal",
  "Este, Italy",
  "Esteiro, Spain",
  "Esteli, Nicaragua",
  "Estella Lizarra, Spain",
  "Estepa, Spain",
  "Estepona, Spain",
  "Estillac, France",
  "Estissac, France",
  "Estrees Mons, France",
  "Estremoz, Portugal",
  "Estribela, Spain",
  "Esztergom, Hungary",
  "Etainhus, France",
  "Etampes sur Marne, France",
  "Etival Clairefontaine, France",
  "Etobicoke, Canada",
  "Etoile sur Rhone, France",
  "Etowah, United States",
  "Etropole, Bulgaria",
  "Ettenheim, Germany",
  "ETTEN-LEUR, Netherlands",
  "Ettlingen, Germany",
  "Ettringen (Wertach), Germany",
  "Etxauri, Spain",
  "Etxebarri, Spain",
  "Etxebarria, Spain",
  "Euclid, United States",
  "Eufaula, United States",
  "Eugene, United States",
  "Euless, United States",
  "Eupen, Belgium",
  "Eura, Finland",
  "Eurajoki, Finland",
  "Europoort, Netherlands",
  "Euskirchen, Germany",
  "Evans, United States",
  "Evans City, United States",
  "Evansville, United States",
  "Evanton, United Kingdom",
  "Evercreech, United Kingdom",
  "Everett, United States",
  "Evergem, Belgium",
  "Eversley Hampshire, United Kingdom",
  "Evesham, United Kingdom",
  "Evora, Portugal",
  "Evreux, France",
  "Evron, France",
  "Ewell, United Kingdom",
  "Exeter, United Kingdom",
  "Exeter, United States",
  "Exmouth, United Kingdom",
  "Export, United States",
  "Exton, United States",
  "Eye Cambridgeshire, United Kingdom",
  "Eye Suffolk, United Kingdom",
  "Eygalieres, France",
  "Eymoutiers, France",
  "Eynatten, Belgium",
  "Eynsham, United Kingdom",
  "Eyragues, France",
  "Eysines, France",
  "Eystrup, Germany",
  "Eyup, Turkey",
  "Ezcaray, Spain",
  "Fabbrico, Italy",
  "Fabregues, France",
  "Fabriano, Italy",
  "Fabrica di Roma, Italy",
  "Fabro Scalo, Italy",
  "Faedis, Italy",
  "Faenza, Italy",
  "Faetano, Italy",
  "Fafe, Portugal",
  "Fagagna, Italy",
  "Faggiano, Italy",
  "Fagnano Olona, Italy",
  "Fahrenzhausen, Germany",
  "Failsworth, United Kingdom",
  "Fairfield, United States",
  "Fairport, United States",
  "Faisalabad, Pakistan",
  "Fakenham, United Kingdom",
  "Fala, Slovenia",
  "Falaise, France",
  "Falces, Spain",
  "Falciano, Italy",
  "Falerone, Italy",
  "Falkenberg, Germany",
  "Falkenberg, Sweden",
  "Falkenberg (bei Torgau), Germany",
  "Falkenhagen (bei Pritzwalk), Germany",
  "Falkensee, Germany",
  "Falkirk, United Kingdom",
  "Falkoeping, Sweden",
  "Fall River, United States",
  "Falleron, France",
  "Falmouth, United States",
  "Falset, Spain",
  "Falze de Piave, Italy",
  "Famalicao, Portugal",
  "Fameck, France",
  "Famoes, Portugal",
  "Fanano, Italy",
  "Fangcheng, China",
  "Fano, Italy",
  "Fanzeres, Portugal",
  "Fanzolo, Italy",
  "Fara Gera d'Adda, Italy",
  "Fara San Martino, Italy",
  "Fara Vicentino, Italy",
  "Farafenni, Gambia",
  "Fareham, United Kingdom",
  "Faridabad, India",
  "Farmers Branch, United States",
  "Farmersville, United States",
  "Farmingdale, United States",
  "Farmington, United States",
  "Farmsum, Netherlands",
  "Farnborough Hampshire, United Kingdom",
  "Farnham Common, United Kingdom",
  "Farnham Suffolk, United Kingdom",
  "Farnham Surrey, United Kingdom",
  "Farnworth Halton, United Kingdom",
  "Faro, Portugal",
  "Farra Di Soligo, Italy",
  "Farsala, Greece",
  "Farsoe, Denmark",
  "Farum, Denmark",
  "Fasano, Italy",
  "FATEHGARH SAHIB, India",
  "Fatima, Portugal",
  "Fauillet, France",
  "Favara, Italy",
  "Favaro Veneto, Italy",
  "Favars, France",
  "Faversham, United Kingdom",
  "Fawley Hants, United Kingdom",
  "FAYE-L'ABBESSE, France",
  "Fayetteville, United States",
  "Featherstone Yorkshire, United Kingdom",
  "Fecamp, France",
  "Fehraltorf, Switzerland",
  "Fehrbellin, Germany",
  "Feignies, France",
  "Feilitzsch, Germany",
  "Feillens, France",
  "Feldkirch, Austria",
  "Feldkirchen Bei Mattighofen, Austria",
  "Feldkirchen-Westerham, Germany",
  "Felegara, Italy",
  "Feletto Umberto, Italy",
  "Felgueiras, Portugal",
  "Felina, Italy",
  "Felino, Italy",
  "Felixstowe, United Kingdom",
  "Fellbach, Germany",
  "Felling, United Kingdom",
  "Felsopakony, Hungary",
  "Feltham, United Kingdom",
  "Feltre, Italy",
  "Feluy, Belgium",
  "Fene, Spain",
  "Fenegro, Italy",
  "Fengxian, China",
  "Ferentino, Italy",
  "Feriana, Tunisia",
  "Fermignano, Italy",
  "Fermo, Italy",
  "Ferndale, United Kingdom",
  "Ferndale, United States",
  "Fernley, United States",
  "Ferno, Italy",
  "Ferrandina, Italy",
  "Ferrara, Italy",
  "Ferriere la Grande, France",
  "Ferrieres EN Bray, France",
  "Ferrieres Seine et Marne, France",
  "Ferrol, Spain",
  "Ferrybank Co Waterford, Ireland",
  "Ferrybridge, United Kingdom",
  "Ferryhill Durham, United Kingdom",
  "Fertoszentmiklos, Hungary",
  "Fes, Morocco",
  "Fetcham, United Kingdom",
  "Feternes, France",
  "Fethiye, Turkey",
  "Feucht, Germany",
  "Feuchtwangen, Germany",
  "Feuchy, France",
  "Feuquieres, France",
  "Feuquieres en Vimeu, France",
  "Feurs, France",
  "Feytiat, France",
  "Feyzin, France",
  "Fiaes, Portugal",
  "Fibbiana, Italy",
  "Fichtenau, Germany",
  "Fichtenberg (Wuertt), Germany",
  "Fidenza, Italy",
  "Fiesco, Italy",
  "Fiesso d'Artico, Italy",
  "Fiesso Umbertiano, Italy",
  "Figino Serenza, Italy",
  "Figliaro, Italy",
  "Figline Valdarno, Italy",
  "Figline Vegliaturo, Italy",
  "Figueira da Foz, Portugal",
  "Figueres, Spain",
  "Figueroles, Spain",
  "Figueruelas, Spain",
  "Fijnaart, Netherlands",
  "Filago, Italy",
  "Filakovo, Slovakia",
  "Filderstadt, Germany",
  "Filey, United Kingdom",
  "Filiano, Italy",
  "Filottrano, Italy",
  "Filton, United Kingdom",
  "Finale Emilia, Italy",
  "Finchampstead, United Kingdom",
  "Findlay, United States",
  "Finedon, United Kingdom",
  "Fines, Spain",
  "Finglas Dublin, Ireland",
  "Finnentrop, Germany",
  "Fino Mornasco, Italy",
  "Fiorano Al Serio, Italy",
  "Fiorano Modenese, Italy",
  "Fiorenzuola D'arda, Italy",
  "Firenze, Italy",
  "Firenzuola, Italy",
  "Firgrove, South Africa",
  "Firminy, France",
  "Fisantekraal, South Africa",
  "Fischamend, Austria",
  "Fischbach (bei Dahn), Germany",
  "Fisciano, Italy",
  "Fish Port Terminal, Russia",
  "Fiskarstranda, Norway",
  "Fiskeville, United States",
  "Fitchburg, United States",
  "Fitilieu, France",
  "Fitzgerald, United States",
  "Fiuggi, Italy",
  "Fiumana di Predappio, Italy",
  "Fiume Veneto, Italy",
  "Fiumicino, Italy",
  "Fizzonasco, Italy",
  "Fjerritslev, Denmark",
  "Fkih Ben Salah, Morocco",
  "Flagstaff, United States",
  "Flanders, United States",
  "FLASSANS-SUR-ISSOLE, France",
  "Flechtingen, Germany",
  "Fleckney Leicestershire, United Kingdom",
  "Fleet Hampshire, United Kingdom",
  "Fleetwood, United Kingdom",
  "Flein, Germany",
  "Flemalle, Belgium",
  "Flen, Sweden",
  "Flero, Italy",
  "Flers, France",
  "Fletcher, United States",
  "Fleurance, France",
  "Fleurus, Belgium",
  "Fleury, France",
  "Fleury les Aubrais, France",
  "Fleury Oise, France",
  "Fleville devant Nancy, France",
  "Flevy, France",
  "Flieden, Germany",
  "Flint, United Kingdom",
  "Flitwick, United Kingdom",
  "Flixborough, United Kingdom",
  "Floeha, Germany",
  "Floersheim (am Main), Germany",
  "Floersheim-Dalsheim, Germany",
  "Florange, France",
  "Floreffe, Belgium",
  "Florence, United States",
  "FLORENCEVILLE-BRISTOL, Canada",
  "Florensac, France",
  "Floroe, Norway",
  "Florstadt, Germany",
  "Flower Mound, United States",
  "Flumeri, Italy",
  "Flushing, United States",
  "Foca, Turkey",
  "Fochabers, United Kingdom",
  "Focsani, Romania",
  "Foehren, Germany",
  "Foeritz, Germany",
  "Foersloev, Sweden",
  "Foevling, Denmark",
  "Fogars de la Selva, Spain",
  "Foggia, Italy",
  "Fogliano Redipuglia, Italy",
  "Foiano della Chiana, Italy",
  "Foios, Spain",
  "FOJNICA, Bosnia and Herzegovina",
  "Folcroft, United States",
  "Foligno, Italy",
  "Folkestone, United Kingdom",
  "Folkston, United States",
  "Follina, Italy",
  "Folschviller, France",
  "Fombio, Italy",
  "Fompedraza, Spain",
  "FONCINE LE HAUT, France",
  "Fond du Lac, United States",
  "Fondettes, France",
  "Fondi, Italy",
  "Fondo in val di Non, Italy",
  "Fondotoce, Italy",
  "Fonolleres, Spain",
  "Fontaine Isere, France",
  "Fontaines-sur-Saone, France",
  "Fontana, United States",
  "Fontanafredda, Italy",
  "Fontanar, Spain",
  "Fontanellato, Italy",
  "Fontanelle di Oderzo, Italy",
  "Fontaneto d'Agogna, Italy",
  "Fontanini, Italy",
  "Fontaniva, Italy",
  "Fonte, Italy",
  "Fontenay le Comte, France",
  "Fontenay sous Bois, France",
  "Fontenay sur Eure, France",
  "Fontenay Tresigny, France",
  "Fontevivo, Italy",
  "Forbach, France",
  "Forchach, Austria",
  "Forchheim (Oberfr), Germany",
  "Forchtenberg, Germany",
  "Ford West Sussex, United Kingdom",
  "Fordingbridge, United Kingdom",
  "Fordoun Aberdeenshire, United Kingdom",
  "Forest Park, United States",
  "Forest Row, United Kingdom",
  "Forest-sur-Marque, France",
  "Forestville, United States",
  "Forfar, United Kingdom",
  "Forges les Bains, France",
  "Forges les Eaux, France",
  "Forino, Italy",
  "Forli, Italy",
  "Forlimpopoli, Italy",
  "Formby, United Kingdom",
  "Formia, Italy",
  "Formigine, Italy",
  "Formigliana, Italy",
  "Formignana, Italy",
  "Fornace di Trento, Italy",
  "Fornace Zarattini, Italy",
  "Fornacette, Italy",
  "Fornaci di Barga, Italy",
  "Fornaci di Briosco, Italy",
  "Forney, United States",
  "Fornos de Algodres, Portugal",
  "Fornovo di Taro, Italy",
  "Fornovo San Giovanni, Italy",
  "Forres, United Kingdom",
  "Fors, Sweden",
  "Forssa, Finland",
  "Forst, Germany",
  "Forst (Baden), Germany",
  "Forstinning, Germany",
  "Fort Benning, United States",
  "Fort Bliss, United States",
  "Fort Bragg, United States",
  "Fort Carson, United States",
  "Fort Collins, United States",
  "Fort de France, Martinique",
  "Fort Erie, Canada",
  "Fort Lauderdale, United States",
  "Fort Lewis, United States",
  "Fort Loramie, United States",
  "Fort Madison, United States",
  "Fort Meade, United States",
  "Fort Mill, United States",
  "Fort Myers, United States",
  "Fort Riley, United States",
  "Fort Rucker, United States",
  "Fort Sill, United States",
  "Fort Smith, United States",
  "Fort Wayne, United States",
  "Fort Worth, United States",
  "Fortuna, Spain",
  "Fos sur Mer, France",
  "Foshan, China",
  "Fossacesia, Italy",
  "Fossadello di Caorso, Italy",
  "Fossalta di Piave, Italy",
  "Fossalta di Portogruaro, Italy",
  "Fossano, Italy",
  "Fossato di Vico, Italy",
  "Fosses, France",
  "Fosso', Italy",
  "Fossombrone, Italy",
  "Foston Derbys, United Kingdom",
  "Fot, Hungary",
  "Fougeres, France",
  "Fountain Inn, United States",
  "Fountain Valley, United States",
  "Fouras, France",
  "Fournes, France",
  "Fourques sur Garonne, France",
  "Foussemagne, France",
  "Foussignac, France",
  "Fowlerville, United States",
  "Foxton Cambridgeshire, United Kingdom",
  "Fradley Staffordshire, United Kingdom",
  "Fraga, Spain",
  "FRAGNES, France",
  "Fraijanes, Costa Rica",
  "Frameries, Belgium",
  "Framlingham, United Kingdom",
  "Franca, Brazil",
  "Francaltroff, France",
  "Francavilla di Sicilia, Italy",
  "Francenigo, Italy",
  "Francesti, Romania",
  "Francistown City Centre, Botswana",
  "Francolise, Italy",
  "Franeker, Netherlands",
  "Frankenburg am Hausruck, Austria",
  "Frankeneck, Germany",
  "Frankenhardt, Germany",
  "Frankenmarkt, Austria",
  "Frankenthal (Pfalz), Germany",
  "Frankfort, United States",
  "Frankfurt, Germany",
  "Frankfurt / Oder, Germany",
  "Franklin, United States",
  "Franklin Park, United States",
  "Frant, United Kingdom",
  "Fraore, Italy",
  "Frasdorf, Germany",
  "Fraser, United States",
  "Fraserburgh, United Kingdom",
  "Frasnes lez Gosselies, Belgium",
  "Fratta Polesine, Italy",
  "Frattamaggiore, Italy",
  "Frattaminore, Italy",
  "Frauenau, Germany",
  "Frechen, Germany",
  "Fredericia, Denmark",
  "Frederick, United States",
  "Fredericksburg, United States",
  "Fredericktown, United States",
  "Frederikshavn, Denmark",
  "FREDERSDORF-VOGELSDORF, Germany",
  "Fredrikstad, Norway",
  "Freedom, United States",
  "Freehold, United States",
  "Freeport, Bahamas",
  "Freeport, United States",
  "Freetown, Sierra Leone",
  "Fregenal De La Sierra, Spain",
  "Freiberg (am Neckar), Germany",
  "Freiberg (Sachsen), Germany",
  "Freiburg (im Breisgau), Germany",
  "Freihung, Germany",
  "Freilassing, Germany",
  "Freimersheim, Germany",
  "Freisen, Germany",
  "Freising, Germany",
  "Freital, Germany",
  "Fremantle, Australia",
  "Fremont, United States",
  "Frenaros, Cyprus",
  "French Camp, United States",
  "Frenstat Pod Radhostem, Czech Republic",
  "Fresagrandinaria, Italy",
  "Fresnes, France",
  "Fresnillo, Mexico",
  "Fresno, United States",
  "Fretin, France",
  "Freudenberg (Baden), Germany",
  "Freudenberg (Westf), Germany",
  "Freudenstadt, Germany",
  "Freyung, Germany",
  "Frickenhausen (Wuertt), Germany",
  "Frickingen, Germany",
  "Fridingen (an der Donau), Germany",
  "Fridolfing, Germany",
  "Friedberg (Hesse), Germany",
  "Friedberg (KrAichach), Germany",
  "Friedewald, Germany",
  "Friedrichsdorf (Taunus), Germany",
  "Friedrichshafen, Germany",
  "Frielas, Portugal",
  "Friesach, Austria",
  "Friesenheim (Baden), Germany",
  "Friesoythe, Germany",
  "Frigento, Italy",
  "Frimley Surry GU, United Kingdom",
  "Frinton on Sea, United Kingdom",
  "Friola, Italy",
  "Friolzheim, Germany",
  "Frisco, United States",
  "Fristad, Sweden",
  "Fritsla, Sweden",
  "Frodsham Chesire, United Kingdom",
  "Froendenberg, Germany",
  "Froges, France",
  "Frogner, Norway",
  "Frome, United Kingdom",
  "Front, Italy",
  "Front Royal, United States",
  "Frontera coahuila, Mexico",
  "Frontignan, France",
  "FRONTONAS, France",
  "Frosinone, Italy",
  "Frostproof, United States",
  "Frouard, France",
  "Froyennes, Belgium",
  "Frycovice, Czech Republic",
  "Frydek-Mistek, Czech Republic",
  "Frydlant Nad Ostravici, Czech Republic",
  "Frystak, Czech Republic",
  "Fubine, Italy",
  "Fucecchio, Italy",
  "Fuendejalon, Spain",
  "Fuengirola, Spain",
  "Fuenlabrada, Spain",
  "Fuenmayor, Spain",
  "Fuensalida, Spain",
  "Fuensanta De Martos, Spain",
  "Fuente Alamo de Murcia, Spain",
  "Fuente Carreteros, Spain",
  "Fuente de Cantos, Spain",
  "Fuente de Pedro Naharro, Spain",
  "Fuente de Piedra, Spain",
  "Fuente del Maestre, Spain",
  "Fuente el Saz de Jarama, Spain",
  "Fuente Obejuna, Spain",
  "Fuente Vaqueros, Spain",
  "Fuentealbilla, Spain",
  "Fuentepelayo Segovia, Spain",
  "Fuentes de Andalucia, Spain",
  "Fuentes De Ebro, Spain",
  "Fuentes de Nava, Spain",
  "Fuentes de Valdepero, Spain",
  "Fuentiduena de Tajo, Spain",
  "Fuerstenfeld, Austria",
  "Fuerstenwalde/Spree, Germany",
  "Fuerstenzell, Germany",
  "Fuerth (Bayern), Germany",
  "Fujairah, United Arab Emirates",
  "Fukuyama, Japan",
  "Fulbourn, United Kingdom",
  "Fulda, Germany",
  "Fulham London, United Kingdom",
  "Full Sutton, United Kingdom",
  "FULLERTON, United States",
  "Fulnek, Czech Republic",
  "Fulton, United States",
  "Fumane, Italy",
  "Fumay, France",
  "Fumel, France",
  "Funes, Spain",
  "Funo di Argelato, Italy",
  "Fuorni, Italy",
  "Fuquay Varina, United States",
  "Fur, Denmark",
  "Furdenheim, France",
  "Furth (I Wald), Germany",
  "Fusa, Norway",
  "Fusignano, Italy",
  "Fusina, Italy",
  "Fussach, Austria",
  "Fussgoenheim, Germany",
  "Fustinana, Spain",
  "Fuzesabony, Hungary",
  "Fuzhou, China",
  "Faaborg, Denmark",
  "Faarup, Denmark",
  "Faarvang, Denmark",
  "Gabes, Tunisia",
  "Gaborone City Centre, Botswana",
  "Gabrovo, Bulgaria",
  "Gachiani, Georgia",
  "Gadki, Poland",
  "Gaeiras, Portugal",
  "Gael, France",
  "Gaertringen, Germany",
  "Gaerwen, United Kingdom",
  "Gaesti, Romania",
  "Gaevle, Sweden",
  "Gafanha da Encarnacao, Portugal",
  "Gafanha da Nazare, Portugal",
  "Gaggenau, Germany",
  "Gaggiano, Italy",
  "Gaggio Montano, Italy",
  "Gagillapur, India",
  "Gagnef, Sweden",
  "Gaiarine di Treviso, Italy",
  "Gaida, Italy",
  "Gaildorf, Germany",
  "Gaillon, France",
  "Gainesville, United States",
  "Gainsborough, United Kingdom",
  "Gais, Italy",
  "Gajano, Spain",
  "Gajary, Slovakia",
  "Galanta, Slovakia",
  "Galashiels, United Kingdom",
  "Galati, Romania",
  "Galazzano, Italy",
  "Galbiate, Italy",
  "Galdakao, Spain",
  "Galeata, Italy",
  "Galgate Lancashire, United Kingdom",
  "Galgon, France",
  "Galindo, Spain",
  "Gallarate, Italy",
  "Gallarta, Spain",
  "Gallatin, United States",
  "Gallaway, United States",
  "Gallese, Italy",
  "Galliate, Italy",
  "Gallicano, Italy",
  "Galliera, Italy",
  "Galliera Veneta, Italy",
  "Gallo d'Alba, Italy",
  "Gallo di Petriano, Italy",
  "Gallur, Spain",
  "Galston, United Kingdom",
  "Galten, Denmark",
  "Galveston, United States",
  "Galway Co Galway, Ireland",
  "Gambarare di Mira, Italy",
  "Gambellara, Italy",
  "Gambettola, Italy",
  "Gambhirpura, India",
  "Gambolo, Italy",
  "Gambugliano, Italy",
  "Gameren, Netherlands",
  "Gaming, Austria",
  "Gamle Fredrikstad, Norway",
  "Gamlingay, United Kingdom",
  "Gan, Slovakia",
  "Ganderkesee, Germany",
  "Gandesa, Spain",
  "Gandhidham, India",
  "Gandia, Spain",
  "Gandino, Italy",
  "Gaolan, China",
  "Gaoming, China",
  "Gaoyao, China",
  "Garbagnate Milanese, Italy",
  "Garbagnate Monastero, Italy",
  "Garbsen, Germany",
  "Garching (a d Alz), Germany",
  "Garching (bei Muenchen), Germany",
  "Garcia, Mexico",
  "Gardanne, France",
  "Garden City, United States",
  "Garden Grove, United States",
  "Gardena, United States",
  "Gardene, Latvia",
  "Gardermoen, Norway",
  "Gardigiano, Italy",
  "Gardolo, Italy",
  "Gardone Val Trompia, Italy",
  "Garessio, Italy",
  "Garfield Heights, United States",
  "Garforth, United Kingdom",
  "Garges les Gonesse, France",
  "Gargrave, United Kingdom",
  "Gargzdai, Lithuania",
  "Garland, United States",
  "Garlasco, Italy",
  "Garlate, Italy",
  "Garner, United States",
  "Garons, France",
  "Garoua, Cameroon",
  "Garphyttan, Sweden",
  "Garrel, Germany",
  "Garrett, United States",
  "Garsfontein, South Africa",
  "Garstang, United Kingdom",
  "Garston, United Kingdom",
  "Garvagh Northern Ireland, United Kingdom",
  "Garwolin, Poland",
  "Garwood, United States",
  "Gasny, France",
  "Gaspoltshofen, Austria",
  "Gastern, Austria",
  "Gastonia, United States",
  "Gasville, France",
  "Gaszowice, Poland",
  "Gata De Gorgos, Spain",
  "Gately EBS, South Africa",
  "Gateshead, United Kingdom",
  "Gatineau, Canada",
  "Gattatico, Italy",
  "Gatteo, Italy",
  "Gattico, Italy",
  "Gattinara, Italy",
  "Gatwick, United Kingdom",
  "Gau Odernheim, Germany",
  "Gauchy, France",
  "Gauciel, France",
  "Gautam Budhh Nagar, India",
  "Gauting, Germany",
  "Gava, Spain",
  "Gavardo, Italy",
  "Gavasseto, Italy",
  "Gavirate, Italy",
  "Gawr As-Safi, Jordan",
  "Gaziantep, Turkey",
  "Gaziemir, Turkey",
  "Gaziosmanpasa, Turkey",
  "Gazoldo degli Ippoliti, Italy",
  "Gazzada, Italy",
  "Gazzo Padovano, Italy",
  "Gazzo Veronese, Italy",
  "Gazzola, Italy",
  "Gazzolo, Italy",
  "Gazzuolo, Italy",
  "Gbelany, Slovakia",
  "Gbely, Slovakia",
  "Gdansk, Poland",
  "Gdow, Poland",
  "Gdynia, Poland",
  "Gebze, Turkey",
  "Gedern, Germany",
  "Gedved, Denmark",
  "Geel, Belgium",
  "Geertruidenberg, Netherlands",
  "Geeste, Germany",
  "Geesthacht, Germany",
  "Gehren (Thuer), Germany",
  "Geilenkirchen, Germany",
  "Geiselhoering, Germany",
  "Geisenfeld, Germany",
  "Geisenhausen, Germany",
  "Geisenheim, Germany",
  "Geisingen (Baden), Germany",
  "Geislingen (an der Steige), Germany",
  "Geispolsheim, France",
  "Geithain, Germany",
  "Gela, Italy",
  "Geldermalsen, Netherlands",
  "Geldern, Germany",
  "Geldrop, Netherlands",
  "Gellainville, France",
  "Gelsa, Spain",
  "Gelsenkirchen, Germany",
  "Gelsted, Denmark",
  "Gelves, Spain",
  "Gemenos, France",
  "Gemerska Horka, Slovakia",
  "Gemert, Netherlands",
  "Gemlik, Turkey",
  "Gemona del Friuli, Italy",
  "Gemonio, Italy",
  "Gemuenden (Felda), Germany",
  "Genas, France",
  "Genay, France",
  "Genderkingen, Germany",
  "Gendorf (Burgkirchen), Germany",
  "Gendt, Netherlands",
  "Genelard, France",
  "Genemuiden, Netherlands",
  "General Alvear Mendoza, Argentina",
  "General Santos City Dadiangas, Philippines",
  "General Toshevo, Bulgaria",
  "Geneston, France",
  "Geneva, Switzerland",
  "Geneva, United States",
  "Genga, Italy",
  "Gengenbach, Germany",
  "Genk, Belgium",
  "Gennevilliers, France",
  "Genoa, Italy",
  "Genoa Vado Ligure, Italy",
  "Genola, Italy",
  "Genouillac Creuse, France",
  "Gensac la Pallue, France",
  "Gensingen, Germany",
  "Gent, Belgium",
  "Genthin, Germany",
  "Georgensgmuend, Germany",
  "Georgetown, Canada",
  "Georgetown, Guyana",
  "Georgetown, United States",
  "Georgsmarienhuette, Germany",
  "Geovreisset, France",
  "Gera, Germany",
  "Gera Lario, Italy",
  "Gerakini, Greece",
  "Gerenzano, Italy",
  "Geretsberg, Austria",
  "Geretsried, Germany",
  "Gerhardshofen, Germany",
  "Gerichshain, Germany",
  "Gerindote, Spain",
  "Geringswalde, Germany",
  "Gerlingen (Wuertt), Germany",
  "Germantown, United States",
  "Germering, Germany",
  "Germersheim, Germany",
  "Germiston, South Africa",
  "Gernika-Lumo, Spain",
  "Gernrode, Germany",
  "Gernsbach, Germany",
  "Gernsheim Rhein, Germany",
  "Gerolstein, Germany",
  "Gerrards Cross, United Kingdom",
  "Gersheim, Germany",
  "Gerstetten, Germany",
  "Gersthofen, Germany",
  "Gerstungen, Germany",
  "Gerzat, France",
  "Geraardsbergen, Belgium",
  "Gescher, Germany",
  "Geseke, Germany",
  "Gessate, Italy",
  "Getafe, Spain",
  "Getaria, Spain",
  "Gettysburg, United States",
  "Getxo, Spain",
  "Gevelsberg, Germany",
  "Gevgelija, Macedonia",
  "Gevrey Chambertin, France",
  "Gezina, South Africa",
  "Ghaghar Phathak, Pakistan",
  "Ghedi, Italy",
  "Ghemme, Italy",
  "Ghimbav, Romania",
  "Ghirano, Italy",
  "Ghisalba, Italy",
  "Ghislarengo, Italy",
  "Ghislenghien, Belgium",
  "Ghlin, Belgium",
  "Ghraba, Tunisia",
  "Giacciano di Baruchella, Italy",
  "Giammoro, Italy",
  "Gianico, Italy",
  "Giannitsa, Greece",
  "Giarre, Italy",
  "Giavera del Montello, Italy",
  "Gibbstown, United States",
  "Gibraleon, Spain",
  "Gibraltar, Gibraltar",
  "Gibson City, United States",
  "Giebelstadt, Germany",
  "Gien, France",
  "Giengen (an der Brenz), Germany",
  "Giessen, Germany",
  "Giessen, Netherlands",
  "Giffoni Valle Piana, Italy",
  "Gifhorn, Germany",
  "Gijon, Spain",
  "Gilberdyke, United Kingdom",
  "Gilbert, United States",
  "Gilching, Germany",
  "Gillenfeld, Germany",
  "Gillingham Kent, United Kingdom",
  "Gilly sur Isere, France",
  "Gilman, United States",
  "Gilmer, United States",
  "Gimileo, Spain",
  "Ginestreto, Italy",
  "Gingen (an der Fils), Germany",
  "Ginsheim-Gustavsburg, Germany",
  "Gioia del Colle, Italy",
  "Gioia Tauro, Italy",
  "Gioiosa Ionica, Italy",
  "Girona, Spain",
  "Gironde sur Dropt, France",
  "Gironico, Italy",
  "Girton Cambridgeshire, United Kingdom",
  "Girvan, United Kingdom",
  "Gislaved, Sweden",
  "Gissi, Italy",
  "Giubiasco, Switzerland",
  "Giugliano In Campania, Italy",
  "Giussago, Italy",
  "Giussano, Italy",
  "Givet, France",
  "Givors, France",
  "Gjerdsvika, Norway",
  "Gjoevik, Norway",
  "Gladbeck, Germany",
  "Gladenbach, Germany",
  "Glaire, France",
  "Glandorf, Germany",
  "Glasgow, United Kingdom",
  "Glasgow, United States",
  "Glastonbury, United Kingdom",
  "Glaubitz, Germany",
  "Glauchau, Germany",
  "Gleinstaetten, Austria",
  "Gleize, France",
  "Glemsford, United Kingdom",
  "Glen Rock, United States",
  "Glendale Heights, United States",
  "Glenfield, United Kingdom",
  "Glenrothes, United Kingdom",
  "Glenshaw, United States",
  "Glenwood, United States",
  "Glinde (Kr Stormarn), Germany",
  "Glinianka, Poland",
  "Gliwice, Poland",
  "Gloggnitz, Austria",
  "Glogovac, Serbia",
  "Glogow, Poland",
  "Glogow Malopolski, Poland",
  "Glomel, France",
  "Glonn, Germany",
  "Glos, France",
  "GLOS SUR RISLE, France",
  "Glossop, United Kingdom",
  "Glostrup, Denmark",
  "Gloucester, Canada",
  "Gloucester, United Kingdom",
  "Gloversville, United States",
  "Glucholazy, Poland",
  "Gluszyca, Poland",
  "Gmuend, Niederoesterreich, Austria",
  "Gmund (Am Tegernsee), Germany",
  "Gmunden, Austria",
  "Gnarrenburg, Germany",
  "Gniezno, Poland",
  "Gnosjoe, Sweden",
  "Goce Delchev, Bulgaria",
  "Goch, Germany",
  "Gochsheim, Germany",
  "God, Hungary",
  "Godalming, United Kingdom",
  "Godech, Bulgaria",
  "Godega di Sant Urbano, Italy",
  "Godella, Spain",
  "Godstone, United Kingdom",
  "Godziszow, Poland",
  "Goe, Belgium",
  "Goebitz, Germany",
  "Goellheim, Germany",
  "Goeppingen, Germany",
  "Goerlev, Denmark",
  "Goerwihl, Germany",
  "Goes, Netherlands",
  "Goetene, Sweden",
  "Goettingen, Germany",
  "Goito, Italy",
  "Golbasi, Turkey",
  "Golbey, France",
  "Golborne, United Kingdom",
  "Golcuk Kocaeli, Turkey",
  "Golcuv Jenikov, Czech Republic",
  "Goldenstedt, Germany",
  "Goldthorpe South Yorks, United Kingdom",
  "Goleniow, Poland",
  "Golina, Poland",
  "Golmes, Spain",
  "Golssen, Germany",
  "Golub-Dobrzyn, Poland",
  "Gomaringen, Germany",
  "Gomersal Kirklees, United Kingdom",
  "Gomez Palacio, Mexico",
  "Gonars, Italy",
  "Gondomar, Spain",
  "Gondreville, France",
  "Gonen-Balikesir, Turkey",
  "Gonesse, France",
  "Gonfreville l'Orcher, France",
  "Gongyi, China",
  "Gonzaga, Italy",
  "Gonzales, United States",
  "Gonzalez, Mexico",
  "Goodlettsville, United States",
  "Goodwater, United States",
  "Goodyear, United States",
  "Goole, United Kingdom",
  "Goose Creek, United States",
  "Gora, Poland",
  "Gora Kalwaria, Poland",
  "Gordon, United States",
  "Gordona, Italy",
  "Gorenja Vas, Slovenia",
  "Goresbridge Kilkenny, Ireland",
  "Gorey Wexford, Ireland",
  "Gorgo al Monticano, Italy",
  "Gorgonzola, Italy",
  "Gorinchem, Netherlands",
  "Gorizia, Italy",
  "Gorla, Italy",
  "Gorla Maggiore, Italy",
  "Gorla Minore, Italy",
  "Gorlago, Italy",
  "Gorle, Italy",
  "Gorlice, Poland",
  "Gorna Oriahovica, Bulgaria",
  "Gornal, Spain",
  "Gornate Olona, Italy",
  "Gornji Grad, Slovenia",
  "Gornji Milanovac, Serbia",
  "Gornji Podgradci, Bosnia and Herzegovina",
  "Gorodok, Ukraine",
  "Gorredijk, Netherlands",
  "Gorrevod, France",
  "Gorron, France",
  "Gorzow Wielkopolski, Poland",
  "Goscicino, Poland",
  "Goscino, Poland",
  "Gosforth Newcastle upon Tyne, United Kingdom",
  "Goslar, Germany",
  "Gosport, United Kingdom",
  "Gossau, Switzerland",
  "Gostyn, Poland",
  "Gotha (Thuer), Germany",
  "Gothenburg, Sweden",
  "Gottmadingen, Germany",
  "Gottolengo, Italy",
  "Gouda, South Africa",
  "Gouesnou, France",
  "Gournay en Bray, France",
  "Gourock, United Kingdom",
  "Goussainville, France",
  "GOVERNADOR DIX SEPT ROSADO, Brazil",
  "Governolo, Italy",
  "Govone, Italy",
  "Goz Beida, Chad",
  "Gozon, Spain",
  "Gozzano, Italy",
  "Grabfeld, Germany",
  "Grabouw, South Africa",
  "Grabow (Meckl), Germany",
  "Grabsleben, Germany",
  "Grace-Hollogne, Belgium",
  "Gradignan, France",
  "Gradisca d'Isonzo, Italy",
  "Graefenhainichen, Germany",
  "Graested, Denmark",
  "Grafenau (Niederbay), Germany",
  "Grafenberg, Germany",
  "Grafenwoehr, Germany",
  "Graffignana, Italy",
  "Graffigny Chemin, France",
  "GRAFSCHAFT BOELINGEN, Germany",
  "Grafschaft Gelsdorf, Germany",
  "Graglia, Italy",
  "Gragnano, Italy",
  "Gragnano Trebbiense, Italy",
  "Graham, United States",
  "Grahamstown, South Africa",
  "Grajewo, Poland",
  "Grammene, Belgium",
  "Granada, Spain",
  "Granadilla De Abona, Spain",
  "Granarolo, Italy",
  "Granarolo Dell'Emilia, Italy",
  "Grand Couronne, France",
  "Grand Prairie, United States",
  "Grand Rapids, United States",
  "Granda de Siero, Spain",
  "Grandate, Italy",
  "Granen, Spain",
  "Grange Sligo, Ireland",
  "Grangemouth, United Kingdom",
  "Graniteville, United States",
  "Grannagh Waterford, Ireland",
  "Granollers, Spain",
  "Grans, France",
  "Gransee, Germany",
  "Grantham, United Kingdom",
  "Granton, United Kingdom",
  "Grantorto, Italy",
  "Granudden, Sweden",
  "Granville, France",
  "Grao de Gandia, Spain",
  "Grapevine, United States",
  "Grasbrunn, Germany",
  "Grasellenbach, Germany",
  "Grasleben, Germany",
  "Grasse, France",
  "Grassie, Canada",
  "Grassobbio, Italy",
  "Gratacasolo, Italy",
  "Graulhet, France",
  "Gravellona Lomellina, Italy",
  "Gravellona Toce, Italy",
  "Gravesend, United Kingdom",
  "Gravigny, France",
  "Gravina In Puglia, Italy",
  "Grays, United Kingdom",
  "Graz, Austria",
  "Grazzano Visconti, Italy",
  "Greasby, United Kingdom",
  "Great Blakenham, United Kingdom",
  "Great Bookham, United Kingdom",
  "Great Braxted Essex, United Kingdom",
  "Great Brickhill, United Kingdom",
  "Great Dunmow, United Kingdom",
  "Great Gransden, United Kingdom",
  "Great Harwood, United Kingdom",
  "Great Missenden, United Kingdom",
  "Great Ryburgh, United Kingdom",
  "Great Yarmouth, United Kingdom",
  "Greater Noida, India",
  "Greatham TS, United Kingdom",
  "Grebenau, Germany",
  "Grebocin, Poland",
  "Grecia, Costa Rica",
  "Green Bay, United States",
  "Greenbushes, South Africa",
  "Greencastle, United States",
  "Greendale, United States",
  "Greeneville, United States",
  "Greenfield, United States",
  "Greenford, United Kingdom",
  "Greenham Common Berkshire RG, United Kingdom",
  "Greenhithe Kent, United Kingdom",
  "Greenock, United Kingdom",
  "Greensboro, United States",
  "Greensburg, United States",
  "Greenville, United States",
  "GREENWICH, United Kingdom",
  "Greenwood, United States",
  "Greer, United States",
  "Greetham Rutland, United Kingdom",
  "Greetland Calderdale, United Kingdom",
  "Grefrath, Germany",
  "Greifenberg, Germany",
  "Greiz, Germany",
  "Grenay Isere, France",
  "Grenderich, Germany",
  "Greneville-en-Beauce, France",
  "Grenloch, United States",
  "Grenoble, France",
  "Grenaa, Denmark",
  "Greppin, Germany",
  "Gresten, Austria",
  "Gretz Armainvilliers, France",
  "Greve, Denmark",
  "Greve, Italy",
  "Greven (Westf), Germany",
  "Grevena, Greece",
  "Grevenbroich, Germany",
  "Grezillac, France",
  "Grezzago, Italy",
  "Grezzana, Italy",
  "Greaaker, Norway",
  "Gricignano d'Aversa, Italy",
  "Griesheim (Hess), Germany",
  "Grieskirchen, Austria",
  "Griffin, United States",
  "Grignasco, Italy",
  "Grigno, Italy",
  "Grimaud, France",
  "Grimbergen, Belgium",
  "Grimsby, United Kingdom",
  "Grindsted, Denmark",
  "Grinon, Spain",
  "Grinzane Cavour, Italy",
  "Grisignano di Zocco, Italy",
  "Grobbendonk, Belgium",
  "Grodzisk Mazowiecki, Poland",
  "Grodzisk Wielkopolski, Poland",
  "Groebming, Austria",
  "Groedig, Austria",
  "Groenlo, Netherlands",
  "Groissiat, France",
  "Grojec, Poland",
  "Grolsheim, Germany",
  "Gromadka, Poland",
  "Grombalia, Tunisia",
  "Gron Yonne, France",
  "Gronau (Leine), Germany",
  "Gronau (Westf), Germany",
  "Groningen, Netherlands",
  "Groot Bijgaarden, Belgium",
  "Grootebroek, Netherlands",
  "Gropello Cairoli, Italy",
  "Grosotto, Italy",
  "Gross Gerau, Germany",
  "Gross Kreutz (Havel), Germany",
  "Gross Oesingen, Germany",
  "Gross Rohrheim, Germany",
  "Gross Zimmern, Germany",
  "Grossaitingen, Germany",
  "Grossalmerode, Germany",
  "Grossbeeren, Germany",
  "Grossbreitenbach, Germany",
  "Grossenbrode, Germany",
  "Grossengottern, Germany",
  "Grossenhain (Sachsen), Germany",
  "Grossenkneten, Germany",
  "Grossenlueder, Germany",
  "Grossenzersdorf, Austria",
  "Grosseto, Italy",
  "Grosshabersdorf, Germany",
  "Grossheirath, Germany",
  "Grosshoehenrain, Germany",
  "Grosskarlbach, Germany",
  "Grosskarolinenfeld, Germany",
  "Grosskrotzenburg, Germany",
  "Grosslehna, Germany",
  "Grossmehring, Germany",
  "Grossostheim, Germany",
  "Grosspoesna, Germany",
  "Grosspostwitz, Germany",
  "Grossraeschen, Germany",
  "Grossroehrsdorf, Germany",
  "Grossrueckerswalde, Germany",
  "Grossschirma, Germany",
  "Gross-Umstadt, Germany",
  "Grosuplje, Slovenia",
  "Grottaminarda, Italy",
  "Groveport, United States",
  "Gruaro, Italy",
  "Grubbenvorst, Netherlands",
  "Grudziadz, Poland",
  "Gruendau, Germany",
  "Gruenhainichen, Germany",
  "Gruenheide, Germany",
  "Gruensfeld, Germany",
  "Gruenstadt, Germany",
  "Grugliasco, Italy",
  "Grumello Cremonese, Italy",
  "Grumello del Monte, Italy",
  "Grumo Appula, Italy",
  "Grumolo delle Abbadesse, Italy",
  "Gryfice, Poland",
  "Gryfino, Poland",
  "Guacimo, Costa Rica",
  "Guadalajara, Mexico",
  "Guadalajara, Spain",
  "Guadalcacin del Caudillo, Spain",
  "Guadalcanal, Spain",
  "Guadalcazar, Spain",
  "Guadarrama, Spain",
  "Guadasequies, Spain",
  "Guadassuar, Spain",
  "Guadiana del Caudillo, Spain",
  "Gualba, Spain",
  "Gualdicciolo, Italy",
  "Gualdo Tadino, Italy",
  "Gualtieri, Italy",
  "Guamo, Italy",
  "Guanajuato, Mexico",
  "Guangzhou, China",
  "Guanta, Venezuela",
  "Guanzate, Italy",
  "Guapiles, Costa Rica",
  "GUARA, Brazil",
  "Guaranao, Venezuela",
  "Guararapes, Brazil",
  "Guarcino, Italy",
  "Guarda, Portugal",
  "Guardamar del Segura, Spain",
  "Guardamiglio, Italy",
  "Guardiagrele, Italy",
  "Guardiaregia, Italy",
  "Guardizela, Portugal",
  "Guardo Palencia, Spain",
  "Guarene, Italy",
  "Guarnizo, Spain",
  "Guarroman, Spain",
  "Guarulhos, Brazil",
  "Guastalla, Italy",
  "Guasticce, Italy",
  "GuatemalA, Guatemala",
  "Guatire-Miranda, Venezuela",
  "GUAXUPE, Brazil",
  "Guayaquil, Ecuador",
  "Guaynabo, Puerto Rico",
  "Guben, Germany",
  "Gubin, Poland",
  "Gudensberg, Germany",
  "Gudur, India",
  "Gueglingen, Germany",
  "Guelph, Canada",
  "Guenes Vizcaya, Spain",
  "Guenrouet, France",
  "Guenzburg, Germany",
  "Guerande, France",
  "Gueret, France",
  "Guesnain, France",
  "Guessing, Austria",
  "Guetenbach, Germany",
  "Guetersloh, Germany",
  "Guglionesi CB, Italy",
  "Guidizzolo, Italy",
  "Guidonia, Italy",
  "Guildford, United Kingdom",
  "Guilherand, France",
  "Guilvinec, France",
  "Guimar, Spain",
  "Guimaraes, Portugal",
  "Guipavas, France",
  "Guisborough, United Kingdom",
  "Guiseley, United Kingdom",
  "Guitiriz, Spain",
  "Guixers, Spain",
  "Gulbene, Latvia",
  "Gulfport, United States",
  "Gullegem, Belgium",
  "Gulu, Uganda",
  "Gumi, Korea, South",
  "Gummersbach, Germany",
  "Gumpoldskirchen, Austria",
  "Gundelfingen (a d Donau), Germany",
  "Gundelsheim (Kr Bamberg), Germany",
  "Gundelsheim (Wuertt), Germany",
  "Gunesli-Istanbul, Turkey",
  "Gunjur, Gambia",
  "Gunness, United Kingdom",
  "Gunskirchen, Austria",
  "Guntersblum, Germany",
  "Guntramsdorf, Austria",
  "Guntur, India",
  "Gunzenhausen, Germany",
  "Gurb, Spain",
  "Gurgaon, India",
  "Guriezo, Spain",
  "Gurkovo, Bulgaria",
  "Gurnee, United States",
  "Guruve, Zimbabwe",
  "Gussago, Italy",
  "Gutach, Germany",
  "Gutenstetten, Germany",
  "Guyhirn, United Kingdom",
  "Gwangyang, Korea, South",
  "Gweru, Zimbabwe",
  "Gyal, Hungary",
  "Gyongyos, Hungary",
  "Gyor, Hungary",
  "Gypsum, United States",
  "Gyumri, Armenia",
  "Gzenaya, Morocco",
  "Habartov, Czech Republic",
  "Habo, Sweden",
  "Hachinohe, Japan",
  "Hackbridge, United Kingdom",
  "Hackney, United Kingdom",
  "Had Soualem, Morocco",
  "Haddenham Buckinghamshire HP, United Kingdom",
  "HaddenhamCambridgeshire, United Kingdom",
  "Haddington East Lothian, United Kingdom",
  "Hadera, Israel",
  "Haderslev, Denmark",
  "Hadimkoy, Turkey",
  "Hadleigh Suffolk, United Kingdom",
  "Hadsten, Denmark",
  "Hadsund, Denmark",
  "Haegendorf, Switzerland",
  "Haelen, Netherlands",
  "Haerkingen, Switzerland",
  "Haerland, Norway",
  "Haessleholm, Sweden",
  "Hagen (Westf), Germany",
  "Hagenbach (Pfalz), Germany",
  "Hagerstown, United States",
  "Hagudi, Estonia",
  "Haguenau, France",
  "Hahn Flughafen, Germany",
  "Hahnstaetten, Germany",
  "Haibach (Unterfr), Germany",
  "Haifa, Israel",
  "Haiger, Germany",
  "Haigerloch, Germany",
  "Haikou, China",
  "Hailsham, United Kingdom",
  "Hainault, United Kingdom",
  "Hainburg, Germany",
  "Haiphong, Vietnam",
  "Haiphong - Lach Huyen, Vietnam",
  "Haiterbach, Germany",
  "Hajduszoboszlo, Hungary",
  "Hakata, Japan",
  "Halastra, Greece",
  "Halberstadt, Germany",
  "Halden, Norway",
  "Haldensleben, Germany",
  "Haldenwang (Allgaeu), Germany",
  "Haldia Port, India",
  "Hale Bank Cheshire, United Kingdom",
  "Halenkov, Czech Republic",
  "Halesowen, United Kingdom",
  "Halesworth, United Kingdom",
  "Halethorpe, United States",
  "Halewood, United Kingdom",
  "Halifax, Canada",
  "Halifax, United Kingdom",
  "Halinow, Poland",
  "Haljala, Estonia",
  "Halkali, Turkey",
  "Halkidona, Greece",
  "Hall, Austria",
  "Halle (Westf), Germany",
  "Halle Saale, Germany",
  "Hallein, Austria",
  "Hallenberg, Germany",
  "Hallerndorf, Germany",
  "Hallsberg, Sweden",
  "Hallstadt, Germany",
  "Hallstavik, Sweden",
  "Halluin, France",
  "Hallum, Netherlands",
  "Halmstad, Sweden",
  "Halol, India",
  "Halsbruecke, Germany",
  "Halstead Essex, United Kingdom",
  "Halstead Kent, United Kingdom",
  "Halsteren, Netherlands",
  "Haltern (Westf), Germany",
  "Haltwhistle, United Kingdom",
  "Halver, Germany",
  "Halze, Czech Republic",
  "Ham, Belgium",
  "Ham les moines, France",
  "Hamble Hampshire, United Kingdom",
  "Hambleton Lancs, United Kingdom",
  "Hambruecken, Germany",
  "Hambuehren, Germany",
  "Hamburg, Germany",
  "Hameenlinna, Finland",
  "Hameln, Germany",
  "Hamer, United States",
  "Hamilton, Canada",
  "Hamilton, United Kingdom",
  "Hamilton, United States",
  "Hamina, Finland",
  "Hamm, Germany",
  "Hamm Westf, Germany",
  "Hammah, Germany",
  "Hammamet, Tunisia",
  "Hamme, Belgium",
  "Hammel, Denmark",
  "Hammelburg, Germany",
  "Hammerau (Ainring), Germany",
  "Hammersmith London, United Kingdom",
  "Hamminkeln, Germany",
  "Hammond, United States",
  "Hammonton, United States",
  "Hamoir sur Ourthe, Belgium",
  "Hamont, Belgium",
  "Hampton, United States",
  "Hampton London, United Kingdom",
  "Hamriya Freezone, United Arab Emirates",
  "Hamtramck, United States",
  "Hanahan, United States",
  "Hanaskog, Sweden",
  "Hanau, Germany",
  "Handewitt, Germany",
  "Handsworth, United Kingdom",
  "Hangelsberg, Germany",
  "Haniska pri Kosiciach, Slovakia",
  "Hankensbuettel, Germany",
  "Hankey, South Africa",
  "Hanley, United Kingdom",
  "Hann Muenden, Germany",
  "Hannover, Germany",
  "Hanover, United States",
  "Hanover Park, United States",
  "Hanstholm, Denmark",
  "Hansweert, Netherlands",
  "Hanumangarh, India",
  "Hanworth London, United Kingdom",
  "Hapert, Netherlands",
  "Hapton Lancs, United Kingdom",
  "Haramidere, Turkey",
  "Harare City Center, Zimbabwe",
  "Harbarnsen, Germany",
  "Harburg, Germany",
  "Hard, Austria",
  "Hardenberg, Netherlands",
  "Harderwijk, Netherlands",
  "Hardheim, Germany",
  "Hardthausen (am Kocher), Germany",
  "Harefield, United Kingdom",
  "Harelbeke, Belgium",
  "Haren (Ems), Germany",
  "Harfleur, France",
  "Harjavalta, Finland",
  "Harka, Hungary",
  "Harlan, United States",
  "Harlesden London, United Kingdom",
  "Harleston Norfolk, United Kingdom",
  "Harleston Suffolk, United Kingdom",
  "Harlev, Denmark",
  "Harlingen, Netherlands",
  "Harlingen, United States",
  "Harlow, United Kingdom",
  "Harmanec, Slovakia",
  "Harmanli, Bulgaria",
  "Harmannsdorf, Austria",
  "Harmondsworth, United Kingdom",
  "Harmony, United States",
  "Harnes, France",
  "Haro, Spain",
  "Harold Wood, United Kingdom",
  "Harpenden, United Kingdom",
  "Harper, Liberia",
  "Harpstedt, Germany",
  "Harrachov, Czech Republic",
  "Harreveld, Netherlands",
  "Harrisburg, United States",
  "Harrislee, Germany",
  "Harrison, United States",
  "Harrogate, United Kingdom",
  "Harrow London, United Kingdom",
  "Harsewinkel, Germany",
  "Hartberg, Austria",
  "Hartenstein, Germany",
  "Hartfield, United Kingdom",
  "Hartland, United States",
  "Hartlebury, United Kingdom",
  "Hartlepool, United Kingdom",
  "Hartley Wintney, United Kingdom",
  "Hartselle, United States",
  "Hartwell, United States",
  "Harvey, United States",
  "Harwich, United Kingdom",
  "Haskovo, Bulgaria",
  "Haslach an der Muehl, Austria",
  "Haslemere, United Kingdom",
  "Haslet, United States",
  "Haslingden, United Kingdom",
  "Hasselager, Denmark",
  "Hasselroth, Germany",
  "Hasselt, Netherlands",
  "Hassfurt, Germany",
  "Hassloch, Germany",
  "Hassmersheim, Germany",
  "Hassocks West Sussex, United Kingdom",
  "HASTINGS, United Kingdom",
  "HASTINGS, United States",
  "Hatay, Turkey",
  "Hatfield, United States",
  "Hatfield Hertfordshire, United Kingdom",
  "Hattersheim (am Main), Germany",
  "Hattiesburg, United States",
  "Hattingen (Ruhr), Germany",
  "Hatzfeld (Eder), Germany",
  "Haubourdin, France",
  "Hauenstein (Pfalz), Germany",
  "Haugesund, Norway",
  "Hauho, Finland",
  "Haulchin, France",
  "Haulerwijk, Netherlands",
  "Hauneck, Germany",
  "Hauppauge, United States",
  "Hausach, Germany",
  "HAUT-MAUCO, France",
  "Hauzenberg, Germany",
  "Havant, United Kingdom",
  "Haverfordwest, United Kingdom",
  "Haverhill, United Kingdom",
  "Havirov, Czech Republic",
  "Havixbeck, Germany",
  "Havlickuv Brod, Czech Republic",
  "Havran, Czech Republic",
  "Hawangen, Germany",
  "Hawarden, United Kingdom",
  "Hawes North Yorkshire, United Kingdom",
  "Hawesville, United States",
  "Hawick, United Kingdom",
  "Hawkhurst, United Kingdom",
  "Haydock, United Kingdom",
  "Hayes London, United Kingdom",
  "Hayle, United Kingdom",
  "Hayling Island, United Kingdom",
  "Haynes, United Kingdom",
  "Hayward, United States",
  "Haywards Heath, United Kingdom",
  "Hazas de Cesto, Spain",
  "Hazebrouck, France",
  "Hazeldonk - Breda, Netherlands",
  "Hazira, India",
  "Hazlehurst, United States",
  "Hazleton, United States",
  "Heanor, United Kingdom",
  "Heathrow, United Kingdom",
  "Hebburn, United Kingdom",
  "Hebden Bridge, United Kingdom",
  "Hebertshausen, Germany",
  "Hebron, United States",
  "Hechingen, Germany",
  "Hechtel, Belgium",
  "Hechthausen, Germany",
  "Heckington Lincolnshire NG, United Kingdom",
  "Hecklingen, Germany",
  "Heckmondwike, United Kingdom",
  "Heddesheim (Baden), Germany",
  "Hedensted, Denmark",
  "Hedge End, United Kingdom",
  "Hednesford, United Kingdom",
  "Heek, Germany",
  "Heelsum, Netherlands",
  "Heemskerk, Netherlands",
  "Heerenveen, Netherlands",
  "Heerhugowaard, Netherlands",
  "Hefei, China",
  "HEFER, Israel",
  "Hegyeshalom, Hungary",
  "Hehlen, Germany",
  "Heichelheim, Germany",
  "Heideck, Germany",
  "Heidelberg, South Africa",
  "Heidelberg (Neckar), Germany",
  "Heiden, Germany",
  "Heidenau (Sachsen), Germany",
  "Heidenheim (an der Brenz), Germany",
  "Heidenheim (Mittelfr), Germany",
  "Heidenreichstein, Austria",
  "Heidesheim, Germany",
  "Heijen, Netherlands",
  "Heilbronn (Neckar), Germany",
  "Heiligengrabe, Germany",
  "Heiligenhaus, Germany",
  "Heiligenroth, Germany",
  "Heiloo, Netherlands",
  "Heinenoord, Netherlands",
  "Heinkenszand, Netherlands",
  "Heino, Netherlands",
  "Heinsberg (Rheinland), Germany",
  "Heinsdorfergrund, Germany",
  "Heist op den Berg, Belgium",
  "Helbra, Germany",
  "Helden Beringe, Netherlands",
  "Hele, United Kingdom",
  "Helena, United States",
  "Helensburgh, United Kingdom",
  "Hell, Norway",
  "Hellerup, Denmark",
  "Hellin, Spain",
  "Helmond, Netherlands",
  "Helmstadt-Bargen, Germany",
  "Helmstedt, Germany",
  "Helsby, United Kingdom",
  "Helsingborg, Sweden",
  "Helsingoer, Denmark",
  "Helsinki, Finland",
  "Helston, United Kingdom",
  "Hemau, Germany",
  "Hemel Hempstead, United Kingdom",
  "Hemer, Germany",
  "Hemhofen, Germany",
  "Hemmingen (Kr Hannover), Germany",
  "Hemmingen (Wuertt), Germany",
  "Hemmoor, Germany",
  "Hemne, Norway",
  "Hempstead Gloucester, United Kingdom",
  "Hemswell Lincolnshire, United Kingdom",
  "Hencovce, Slovakia",
  "Hendaye, France",
  "Hendek-Istanbul, Turkey",
  "HENDERSON, United States",
  "Hendersonville, United States",
  "Henfield, United Kingdom",
  "Hengelo, Netherlands",
  "Hengersberg, Germany",
  "Hengevelde, Netherlands",
  "Hengoed Caerphilly, United Kingdom",
  "Henin Beaumont, France",
  "Henley on Thames, United Kingdom",
  "Henlow, United Kingdom",
  "Henndorf, Austria",
  "Hennecourt, France",
  "Hennef Sieg, Germany",
  "Hennuyeres, Belgium",
  "Henrichemont, France",
  "Henrico, United States",
  "Henstedt-Ulzburg, Germany",
  "Henstridge Somerset, United Kingdom",
  "Heppenheim (Bergstrasse), Germany",
  "Heraklion, Greece",
  "Heralec, Czech Republic",
  "Heras Cantabria, Spain",
  "Herbertingen, Germany",
  "Herblay, France",
  "Herbolzheim (Breisgau), Germany",
  "Herborn (Hess), Germany",
  "Herbrechtingen, Germany",
  "Herbstein, Germany",
  "Herdecke, Germany",
  "Herdwangen Schoenach, Germany",
  "Heredia, Costa Rica",
  "Hereford, United Kingdom",
  "Herentals, Belgium",
  "Herenthout, Belgium",
  "Herford, Germany",
  "Hergatz-Wohmbrechts, Germany",
  "Hergensweiler, Germany",
  "Hericourt, France",
  "Herisau, Switzerland",
  "Herlong, United States",
  "HERMALLE-SOUS-ARGENTEAU, Belgium",
  "Hermanice Nad Labem, Czech Republic",
  "Hermanky, Czech Republic",
  "Hermanova Hut, Czech Republic",
  "Hermanuv Mestec, Czech Republic",
  "Hermeskeil, Germany",
  "Hermsdorf (Thuer), Germany",
  "Hernan Cortes, Spain",
  "Hernando, United States",
  "Hernani, Spain",
  "Herndon, United States",
  "Herne, Belgium",
  "Herne, Germany",
  "Herning, Denmark",
  "Heroeya, Norway",
  "Heroldstatt, Germany",
  "Herouville Saint Clair, France",
  "Herrenberg, Germany",
  "Herrera de Camargo Cantabria, Spain",
  "Herrera del Duque, Spain",
  "Herrieden, Germany",
  "Herrlisheim pres Colmar, France",
  "Herrljunga, Sweden",
  "Herrngiersdorf, Germany",
  "Herrsching, Germany",
  "HERSDEN, United Kingdom",
  "Herstal, Belgium",
  "Herten, Netherlands",
  "Herten (Westf), Germany",
  "Hertford, United Kingdom",
  "Herxheim (bei Landau, Pfalz), Germany",
  "Herzberg, Germany",
  "Herzebrock-Clarholz, Germany",
  "Herzogenaurach, Germany",
  "Herzogenrath, Germany",
  "Hessay York YO, United Kingdom",
  "Hessisch Lichtenau, Germany",
  "Hessisch Oldendorf, Germany",
  "Hessle, United Kingdom",
  "Heston, United Kingdom",
  "Hettenshausen, Germany",
  "Hettingen (Kr Sigmaringen), Germany",
  "Hettstedt (Sachs-Anh), Germany",
  "Hetzmannsdorf, Austria",
  "Heubach, Germany",
  "Heudebouville, France",
  "Heufeld (Bruckmuehl), Germany",
  "Heule, Belgium",
  "Heusden-Zolder, Belgium",
  "Heusenstamm, Germany",
  "Heusweiler, Germany",
  "Hexham, United Kingdom",
  "Heyrieux, France",
  "Heysham, United Kingdom",
  "Heywood Lancs, United Kingdom",
  "Heze, China",
  "Hibikishinko, Japan",
  "Hickman, United States",
  "Hickory, United States",
  "Hidalgo, Mexico",
  "Hidalgo, United States",
  "Hiddenhausen, Germany",
  "Hiddenite, United States",
  "High Bentham, United Kingdom",
  "High Point, United States",
  "High Roding, United Kingdom",
  "High Wycombe, United Kingdom",
  "Highbridge Somerset TA, United Kingdom",
  "Highgate London, United Kingdom",
  "Highland Heights, United States",
  "Hijuelas, Chile",
  "Hilbersdorf (bei Freiberg, Sachsen), Germany",
  "Hildburghausen, Germany",
  "Hilden, Germany",
  "Hildesheim, Germany",
  "Hilgert, Germany",
  "Hill Air Force Base, United States",
  "Hillegom, Netherlands",
  "Hillerstorp, Sweden",
  "Hillingdon London, United Kingdom",
  "Hillington Norfolk, United Kingdom",
  "Hillington Renfrew, United Kingdom",
  "Hillsboro, United States",
  "Hillsborough, United States",
  "Hillside, United States",
  "Hilpoltstein, Germany",
  "Hilsenheim, France",
  "Hilter (am Teutoburger Wald), Germany",
  "Hilvarenbeek, Netherlands",
  "Hilversum, Netherlands",
  "Hilzingen, Germany",
  "Himmelkron, Germany",
  "Hinckley, United Kingdom",
  "Hindelbank, Switzerland",
  "Hindley Green, United Kingdom",
  "Hingham, United States",
  "HINJE, Slovenia",
  "Hinnerup, Denmark",
  "Hinojos, Spain",
  "Hinojosa del Duque, Spain",
  "Hinterschmiding, Germany",
  "Hinwil, Switzerland",
  "Hiram, United States",
  "Hiroshima, Japan",
  "Hirschaid, Germany",
  "Hirschau (Oberpfalz), Germany",
  "Hirtshals, Denmark",
  "Hisingsbacka, Sweden",
  "Hitachinaka, Japan",
  "Hitchin, United Kingdom",
  "Hitzacker, Germany",
  "Hixon, United Kingdom",
  "Hjoerring, Denmark",
  "Hladke Zivotice, Czech Republic",
  "Hlinik nad Hronom, Slovakia",
  "Hlinsko, Czech Republic",
  "Hlohovec, Slovakia",
  "Hluk, Czech Republic",
  "Hnatnice, Czech Republic",
  "Hnojnik, Czech Republic",
  "Hnusta, Slovakia",
  "Ho Chi Minh City, Vietnam",
  "Hobart, Australia",
  "Hoboken, Belgium",
  "Hoboken, United States",
  "Hobro, Denmark",
  "Hoce, Slovenia",
  "Hochdorf, Switzerland",
  "Hochdorf b Plochingen, Germany",
  "Hochheim (am Main), Germany",
  "Hochstadt, Germany",
  "Hochstadt (am Main), Germany",
  "Hockenheim, Germany",
  "Hockley Heath, United Kingdom",
  "Hockwold Cum Wilton, United Kingdom",
  "HOD HA-SHARON, Israel",
  "Hoddesdon, United Kingdom",
  "Hodmezovasarhely, Hungary",
  "Hodonice, Czech Republic",
  "Hodonin, Czech Republic",
  "Hoechstaedt (a d Donau), Germany",
  "Hoedspruit, South Africa",
  "Hoeganaes, Sweden",
  "Hoehr-Grenzhausen, Germany",
  "Hoejbjerg, Denmark",
  "Hoek, Netherlands",
  "Hoek van Holland, Netherlands",
  "Hoeng, Denmark",
  "Hoerbranz, Austria",
  "Hoerdt, France",
  "Hoersching, Austria",
  "Hoerstel, Germany",
  "Hoesbach, Germany",
  "Hoetensleben, Germany",
  "Hoettingen, Germany",
  "Hoevelhof, Germany",
  "Hoexter, Germany",
  "Hoeyanger, Norway",
  "Hof, Norway",
  "Hof (Westerwald), Germany",
  "Hof Saale, Germany",
  "Hofstetten, Germany",
  "Hofuf, Saudi Arabia",
  "Hohberg, Germany",
  "Hohenau, Germany",
  "Hohenberg, Germany",
  "Hohenbrunn, Germany",
  "Hohenems, Austria",
  "Hohenfels, Germany",
  "Hohenkammer, Germany",
  "Hohenpeissenberg, Germany",
  "Hohenstein-Ernstthal, Germany",
  "Hohenzell, Austria",
  "Hokksund, Norway",
  "Holbaek, Denmark",
  "Holbeach Lincoln, United Kingdom",
  "Holcovice, Czech Republic",
  "Holdorf (Niedersachsen), Germany",
  "Holeby, Denmark",
  "Holesov, Czech Republic",
  "Holgate, United States",
  "Holic, Slovakia",
  "Holice, Czech Republic",
  "Holiday City, United States",
  "Holland, United States",
  "Hollenbach, Germany",
  "Hollern-Twielenfleth, Germany",
  "Hollidaysburg, United States",
  "Holliston, United States",
  "Hollola, Finland",
  "Holloman AFB, United States",
  "Holly Hill, United States",
  "Hollywood, United States",
  "Holme Upon Spalding Moor, United Kingdom",
  "Holmes Chapel, United Kingdom",
  "Holmestrand, Norway",
  "Holmewood, United Kingdom",
  "Holmfirth, United Kingdom",
  "Holmsjoe, Sweden",
  "Holon, Israel",
  "Holoubkov, Czech Republic",
  "Holstebro, Denmark",
  "Holt Norfolk, United Kingdom",
  "Holten, Netherlands",
  "Holtzheim, France",
  "Holtzwihr, France",
  "Holubice, Czech Republic",
  "Holyhead, United Kingdom",
  "Holytown, United Kingdom",
  "Holywell Flint, United Kingdom",
  "Holzgerlingen, Germany",
  "Holzminden, Germany",
  "Holzwickede, Germany",
  "Homberg  (Efze), Germany",
  "Homberg (Ohm), Germany",
  "Hombourg, Belgium",
  "Hombourg, France",
  "Homburg (Saar), Germany",
  "Hone, Italy",
  "Honeybourne, United Kingdom",
  "Honfleur, France",
  "Hong Kong, Hong Kong CHINA",
  "Honiton, United Kingdom",
  "Honolulu, United States",
  "Hontoria Segovia, Spain",
  "Hoofddorp, Netherlands",
  "Hoogeveen, Netherlands",
  "Hoogezand, Netherlands",
  "Hoogstraten, Belgium",
  "Hook Hampshire, United Kingdom",
  "Hoorn, Netherlands",
  "Hope Derbyshire, United Kingdom",
  "Hopewell, United States",
  "Hopkins, United States",
  "Hopkinsville, United States",
  "HOPPEGARTEN, Germany",
  "Hoppstaedten-Weiersbach, Germany",
  "Hopsten, Germany",
  "Horana, Sri Lanka",
  "Horana Free Zone, Sri Lanka",
  "Horatev, Czech Republic",
  "Horazdovice, Czech Republic",
  "Horb (am Neckar), Germany",
  "HORESEDLY, Czech Republic",
  "Horgen, Switzerland",
  "Horice, Czech Republic",
  "Horjul, Slovenia",
  "Horka u Stare Paky, Czech Republic",
  "Horley Surrey, United Kingdom",
  "Horna Streda, Slovakia",
  "Hornachuelos, Spain",
  "Hornbach (Pfalz), Germany",
  "Horn-Bad Meinberg, Germany",
  "Horncastle, United Kingdom",
  "Hornchurch, United Kingdom",
  "Horne Saliby, Slovakia",
  "Horne Zelenice, Slovakia",
  "Horni Adrspach, Czech Republic",
  "Horni Briza, Czech Republic",
  "Horni Pocaply, Czech Republic",
  "Horni Stepanov, Czech Republic",
  "Horni Sucha, Czech Republic",
  "Horno de Alcedo, Spain",
  "Hornsyld, Denmark",
  "Horny Hricov, Slovakia",
  "Horovice, Czech Republic",
  "Horquetas de Sarapiqui, Costa Rica",
  "Horsens, Denmark",
  "Horsforth Leeds, United Kingdom",
  "Horsham West Sussex, United Kingdom",
  "Horsmonden, United Kingdom",
  "Horsovsky Tyn, Czech Republic",
  "Horst, Netherlands",
  "Horstmar, Germany",
  "Horton Berks, United Kingdom",
  "Horwich, United Kingdom",
  "hoshiarpur, India",
  "Hosingen, Luxemburg",
  "Hoskote - Bangalore, India",
  "Hospitalet, Spain",
  "Hostalric, Spain",
  "Hostinne, Czech Republic",
  "Hostivice, Czech Republic",
  "Hosur, India",
  "Houdan, France",
  "Houdeng-Aimeries, Belgium",
  "Houdeng-Goegnies, Belgium",
  "Houghton le Spring, United Kingdom",
  "Houghton Regis, United Kingdom",
  "Hounslow, United Kingdom",
  "Houplines, France",
  "Houston, United States",
  "Hout Bay, South Africa",
  "Houten, Netherlands",
  "Houthalen, Belgium",
  "HOVE, United Kingdom",
  "Howden Yorkshire DN, United Kingdom",
  "Hoya del Campo, Spain",
  "Hoyerswerda, Germany",
  "Hoylake, United Kingdom",
  "Hoznayo, Spain",
  "Hradec Kralove, Czech Republic",
  "Hradek Nad Nisou, Czech Republic",
  "Hradistko, Czech Republic",
  "HRANICE (u Ase), Czech Republic",
  "Hranice na Morave, Czech Republic",
  "Hrastnik, Slovenia",
  "Hrinova, Slovakia",
  "Hrusovany u Brna, Czech Republic",
  "Hrvatski Leskovac, Croatia",
  "Huadu, China",
  "Huangpu, China",
  "Huarte, Spain",
  "Hub, Pakistan",
  "Hucknall, United Kingdom",
  "Huddersfield, United Kingdom",
  "Huddinge, Sweden",
  "Hude (Oldenburg), Germany",
  "HUDSON, United States",
  "Hueckelhoven, Germany",
  "Hueckeswagen, Germany",
  "Huegelsheim, Germany",
  "Huehuetoca Edo Mex, Mexico",
  "Huejotzingo, Mexico",
  "Huellhorst, Germany",
  "Huelva, Spain",
  "Hueneja, Spain",
  "Huenfeld, Germany",
  "Huenxe, Germany",
  "Huerta, Spain",
  "Huerta de Valdecarabanos, Spain",
  "Huerth, Germany",
  "Huesca, Spain",
  "Huetor Tajar, Spain",
  "Huettlingen, Germany",
  "Huevar, Spain",
  "Hughesville, United States",
  "Huichihuayan, Mexico",
  "Huimanguillo, Mexico",
  "Huizen, Netherlands",
  "Huizhou, China",
  "Hulin, Czech Republic",
  "Hull, United Kingdom",
  "Hulste, Belgium",
  "Humanes, Spain",
  "Humble, United States",
  "Humenne, Slovakia",
  "Humilladero, Spain",
  "Humpolec, Czech Republic",
  "Hunderdorf, Germany",
  "Hunedoara, Romania",
  "Hungen, Germany",
  "Hungerford West Berkshire, United Kingdom",
  "Huningue, France",
  "Hunslet, United Kingdom",
  "Huntingdon Cambs, United Kingdom",
  "Huntington, United States",
  "Huntley, United States",
  "Huntly, United Kingdom",
  "Huntsville, United States",
  "Hurbanovo, Slovakia",
  "Huron Park, Canada",
  "Hurst, United States",
  "Hurst Green East Sussex, United Kingdom",
  "Hurstpierpoint West Sussex, United Kingdom",
  "Huskvarna, Sweden",
  "Hustopece, Czech Republic",
  "Huszlew, Poland",
  "Huthwaite, United Kingdom",
  "Huttenheim, France",
  "Huttenheim (Philippsburg), Germany",
  "Hutthurm, Germany",
  "Huyton, United Kingdom",
  "Hvide Sande, Denmark",
  "Hvidovre, Denmark",
  "Hwasung, Korea, South",
  "Hyde Hampshire, United Kingdom",
  "Hyde Tameside, United Kingdom",
  "Hyderabad, India",
  "Hyderabad, Pakistan",
  "Hyeres, France",
  "Hyllinge, Sweden",
  "Hypoluxo, United States",
  "Hythe Kent, United Kingdom",
  "Haag a.d. Amper, Germany",
  "Haan, Germany",
  "Haapsalu, Estonia",
  "Haar (Kr Muenchen), Germany",
  "Haarlem, Netherlands",
  "Haarlev, Denmark",
  "Ialoven, Moldova",
  "Ianca, Romania",
  "Iano, Italy",
  "Iasi, Romania",
  "Ibarra, Spain",
  "Ibbenbueren, Germany",
  "Ibi, Spain",
  "Ibiricu Egues, Spain",
  "Ica, Peru",
  "Ichenhausen, Germany",
  "Ichon, Korea, South",
  "Ichtershausen, Germany",
  "Idabel, United States",
  "Idaho Falls, United States",
  "Idiazabal, Spain",
  "Idro, Italy",
  "Idstein, Germany",
  "Ieper, Belgium",
  "Ifs, France",
  "Ig, Slovenia",
  "Igersheim, Germany",
  "Iggingen, Germany",
  "Igorre, Spain",
  "Igualada, Spain",
  "Ijmuiden, Netherlands",
  "Ijsselmuiden, Netherlands",
  "Ijsselstein, Netherlands",
  "Ikast, Denmark",
  "Ikeja, Nigeria",
  "Ikitelli, Turkey",
  "Ikornnes, Norway",
  "Iksan, Korea, South",
  "Ilava, Slovakia",
  "Ilbesheim, Germany",
  "Ilford, United Kingdom",
  "Ilhavo, Portugal",
  "Ilkeston, United Kingdom",
  "Ilkley, United Kingdom",
  "Illertissen, Germany",
  "Illescas, Spain",
  "Illesheim, Germany",
  "Illfurth, France",
  "Illingen (Saar), Germany",
  "Illingen (Wuertt), Germany",
  "Illkirch Graffenstaden, France",
  "Illmitz, Austria",
  "Illzach, France",
  "Ilmenau, Germany",
  "Ilminster, United Kingdom",
  "Ilopango, El Salvador",
  "Ilsenburg, Germany",
  "Ilsfeld, Germany",
  "Ilshofen, Germany",
  "Ilza, Poland",
  "Imarcoain, Spain",
  "Imbituba, Brazil",
  "Imbsheim, France",
  "Immenreuth, Germany",
  "Immenstadt, Germany",
  "Immenstaad, Germany",
  "Immingham, United Kingdom",
  "Imola, Italy",
  "Imperia, Italy",
  "IMPERIAL BEACH, United States",
  "Imphy, France",
  "Imst, Austria",
  "Inchinnan, United Kingdom",
  "Inchon, Korea, South",
  "Inciems, Latvia",
  "Incirlik Air Base, Turkey",
  "Incoronata, Italy",
  "Indaiatuba, Brazil",
  "Independence, United States",
  "Indianapolis, United States",
  "Inegol, Turkey",
  "Ingatestone, United Kingdom",
  "Ingelmunster, Belgium",
  "Ingenio, Spain",
  "Ingersoll, Canada",
  "Ingham Lincolnshire, United Kingdom",
  "Inglewood, United States",
  "Ingolstadt (Donau), Germany",
  "Ingre, France",
  "Inkberrow, United Kingdom",
  "Inkoo, Finland",
  "Inkster, United States",
  "Inman, United States",
  "Innsbruck, Austria",
  "Inowroclaw, Poland",
  "Insch, United Kingdom",
  "Insingen, Germany",
  "Introbio, Italy",
  "Inverbervie, United Kingdom",
  "Invercargill, New Zealand",
  "Invergordon, United Kingdom",
  "Inverigo, Italy",
  "Inverkeithing, United Kingdom",
  "Inverness, United Kingdom",
  "Inveruno, Italy",
  "Inverurie, United Kingdom",
  "Invorio, Italy",
  "Inwood, United States",
  "Inzago, Italy",
  "Ioannina, Greece",
  "Ionia, United States",
  "Iowa City, United States",
  "Iphofen, Germany",
  "Ipswich, United Kingdom",
  "Iquique, Chile",
  "Irakleia, Greece",
  "Irapuato, Mexico",
  "Irbid, Jordan",
  "Irig, Serbia",
  "Irigny, France",
  "Irlam, United Kingdom",
  "Ironton, United States",
  "Irschenberg, Germany",
  "Irthlingborough, United Kingdom",
  "Irun, Spain",
  "Irungattukottai, India",
  "Irura, Spain",
  "IRURTZUN, Spain",
  "Irvillac, France",
  "Irvine, United Kingdom",
  "Irvine, United States",
  "Irving, United States",
  "Irwin, United States",
  "Is sur Tille, France",
  "Isando, South Africa",
  "Iscar, Spain",
  "Iserlohn, Germany",
  "Isernhagen, Germany",
  "Isernia, Italy",
  "ISIKKENT, Turkey",
  "Iskenderun, Turkey",
  "Isla Mayor, Spain",
  "Isle, France",
  "Isle of Grain Medway, United Kingdom",
  "Isleworth, United Kingdom",
  "Islington, United Kingdom",
  "Islip Norhtants, United Kingdom",
  "Ismaileya, Egypt",
  "Ismaning, Germany",
  "Isny (im Allgaeu), Germany",
  "Isola Del Gran Sasso, Italy",
  "Isola del Liri, Italy",
  "Isola del Piano, Italy",
  "Isola della Scala, Italy",
  "Isola Dovarese, Italy",
  "Isola Rizza, Italy",
  "Isola Vicentina, Italy",
  "Isorella, Italy",
  "Isparta, Turkey",
  "Isperih, Bulgaria",
  "Ispringen, Germany",
  "Isselburg, Germany",
  "Isso, Italy",
  "Issoire, France",
  "Issoudun, France",
  "Issum, Germany",
  "Istebne, Slovakia",
  "Istrana, Italy",
  "Itaguai Sepetiba, Brazil",
  "Itajai, Brazil",
  "Itajobi, Brazil",
  "Itancourt, France",
  "Itapevi, Brazil",
  "Itapoa, Brazil",
  "Itapolis, Brazil",
  "Itasca, United States",
  "Itea, Greece",
  "Itri, Italy",
  "Itsasondo, Spain",
  "Itterbeck, Germany",
  "Ittervoort, Netherlands",
  "Ittre, Belgium",
  "Itziar Guipuzcoa, Spain",
  "Iurreta, Spain",
  "Ivancice, Czech Republic",
  "Ivanka Pri Dunaji, Slovakia",
  "Ivanka Pri Nitre, Slovakia",
  "Ivano-Frankivsk, Ukraine",
  "Ivanovo, Russia",
  "Ivars Durgell, Spain",
  "Iver, United Kingdom",
  "Ivrea, Italy",
  "Ivybridge, United Kingdom",
  "Ivyland, United States",
  "Ixonia, United States",
  "Ixtapaluca, Mexico",
  "Iyomishima, Japan",
  "Iza, Spain",
  "Izegem, Belgium",
  "Izernore, France",
  "Izeron, France",
  "Izmir, Turkey",
  "IZMIT, Turkey",
  "Izmit Korfezi, Turkey",
  "Iznalloz, Spain",
  "Izola, Slovenia",
  "Izurza, Spain",
  "Jablonec nad Nisou, Czech Republic",
  "Jablonica, Slovakia",
  "Jablonna, Poland",
  "Jablonna (Lublin), Poland",
  "Jablonna Lacka, Poland",
  "Jablonne nad Orlici, Czech Republic",
  "Jablonne v Podjestedi, Czech Republic",
  "Jacarilla, Spain",
  "Jacinto City, United States",
  "Jacksboro, United States",
  "Jackson, United States",
  "Jackson Center, United States",
  "Jacksonville, United States",
  "Jaco, Costa Rica",
  "Jacobabad, Pakistan",
  "Jacobsdorf, Germany",
  "Jacona, Mexico",
  "Jadcherla, India",
  "Jaen, Spain",
  "Jaerfaella, Sweden",
  "Jaerva Jaani, Estonia",
  "Jagodina, Serbia",
  "Jaipur, India",
  "Jakarta, Indonesia",
  "Jakubcovice nad Odrou, Czech Republic",
  "Jalapa, Mexico",
  "Jalgaon, India",
  "Jallais, France",
  "Jalon, Spain",
  "Jamaica, United States",
  "Jamastran, Honduras",
  "Jambi, Indonesia",
  "Jamesburg, United States",
  "Jamestown, United States",
  "Jamnagar, India",
  "Jamne, Czech Republic",
  "Jamsa, Finland",
  "Jamshedpur, India",
  "Janesville, United States",
  "Janossomorja, Hungary",
  "Janova Lehota, Slovakia",
  "Janowiec Wielkopolski, Poland",
  "JANUSZKOWICE, Poland",
  "Janze, France",
  "Jaraczewo, Poland",
  "Jarnac, France",
  "Jarnac Champagne, France",
  "Jaromer, Czech Republic",
  "Jaroslaw, Poland",
  "Jaroszow, Poland",
  "Jaroszowiec, Poland",
  "Jarrie, France",
  "Jarrow, United Kingdom",
  "Jasienica, Poland",
  "Jasieniec (Grojecki), Poland",
  "Jasin, Malaysia",
  "Jasionka, Poland",
  "Jaslo, Poland",
  "Jasper, United States",
  "Jassans Riottier, France",
  "Jaszapati, Hungary",
  "Jaszbereny, Hungary",
  "Jaszfenyszaru, Hungary",
  "Javea, Spain",
  "Jawaharlal Nehru, India",
  "Jaworzno, Poland",
  "Jaworzyna Slaska, Poland",
  "Jazlovice, Czech Republic",
  "Jeanerette, United States",
  "Jebel Ali, United Arab Emirates",
  "Jebel Ali Industrial Area, United Arab Emirates",
  "Jedburgh, United Kingdom",
  "Jeddah, Saudi Arabia",
  "Jedlina Zdroj, Poland",
  "Jedlinsk, Poland",
  "Jedrzejow, Poland",
  "JEFFERSON, United States",
  "Jefferson Hills, United States",
  "Jeffersonville, United States",
  "Jejuri, India",
  "Jelcz Laskowice, Poland",
  "Jelenia Gora, Poland",
  "Jelgava, Latvia",
  "Jelsava, Slovakia",
  "Jena, Germany",
  "Jenec u Prahy, Czech Republic",
  "Jenisovice, Czech Republic",
  "Jerago con Orago, Italy",
  "Jerez Caballeros, Spain",
  "Jerez de la Front, Spain",
  "Jersey City, United States",
  "Jerzmanowice, Poland",
  "Jesenice, Czech Republic",
  "Jesenice, Slovenia",
  "Jesenik, Czech Republic",
  "Jesewitz, Germany",
  "Jesi, Italy",
  "Jesolo, Italy",
  "Jessen (Elster), Germany",
  "Jessup, United States",
  "Jet Park, South Africa",
  "Jettingen, Germany",
  "Jettingen-Scheppach, Germany",
  "Jetzendorf, Germany",
  "Jevicko, Czech Republic",
  "Jevnaker, Norway",
  "Jharsuguda, India",
  "Jiangmen, China",
  "Jiangyin - Jiangsu, China",
  "Jiaxing, China",
  "Jicin, Czech Republic",
  "Jihlava, Czech Republic",
  "Jijona, Spain",
  "Jilemnice, Czech Republic",
  "Jilove u Prahy, Czech Republic",
  "Jiloviste, Czech Republic",
  "Jinamar, Spain",
  "Jindrichov, Czech Republic",
  "Jindrichuv Hradec, Czech Republic",
  "Jinhae, Korea, South",
  "Jinja, Uganda",
  "Jinotega, Nicaragua",
  "Jinzhou, China",
  "Jirny, Czech Republic",
  "JIUJIANG, China",
  "Jiujiang Guangdong, China",
  "Jiutepec, Mexico",
  "Joal, Senegal",
  "Jodhpur, India",
  "Jodoigne, Belgium",
  "Joenkoeping, Sweden",
  "Joensuu, Finland",
  "Jogeva, Estonia",
  "Johannesburg, South Africa",
  "Johanneshov, Sweden",
  "Johanniskirchen, Germany",
  "Johnson City, United States",
  "Johnstone, United Kingdom",
  "Johnstown, United States",
  "Johor Bahru, Malaysia",
  "Joliette, Canada",
  "Jolon, United States",
  "Jona, Switzerland",
  "Jonava, Lithuania",
  "Jonesboro, United States",
  "Jonestown, United States",
  "Jonesville, United States",
  "Joplin, United States",
  "Joppa, United States",
  "Jordbro, Sweden",
  "Jorf El Melha, Morocco",
  "Jorf Lasfar, Morocco",
  "Jork, Germany",
  "Joroinen, Finland",
  "Joubertina, South Africa",
  "Joure, Netherlands",
  "Jouy-le-Chatel, France",
  "JUCHITEPEC, Mexico",
  "Judenbach, Germany",
  "Juechen, Germany",
  "Juelich, Germany",
  "Juelsminde, Denmark",
  "Juenkerath, Germany",
  "Juia, Spain",
  "Juigalpa, Nicaragua",
  "Juillac le Coq, France",
  "Jumilla, Spain",
  "Juncal, Portugal",
  "Juneda, Spain",
  "Juri, Estonia",
  "Jurong, Singapore",
  "Jussey, France",
  "JUVIGNY, France",
  "Jyllinge, Denmark",
  "Kabelsketal, Germany",
  "Kabwe, Zambia",
  "Kacov, Czech Republic",
  "Kadamalaiputhur, India",
  "Kadan, Czech Republic",
  "Kaebschuetztal, Germany",
  "Kaevlinge, Sweden",
  "Kafr El Dawwar, Egypt",
  "Kafr El Sheikh, Egypt",
  "Kafr El Zayat, Egypt",
  "Kagithane, Turkey",
  "Kahl am Main, Germany",
  "Kahla (Thueringen), Germany",
  "Kahraman Maras, Turkey",
  "kaipiainen, Finland",
  "Kairouan, Tunisia",
  "Kaisersesch, Germany",
  "Kaiserslautern, Germany",
  "Kaisiadorys, Lithuania",
  "Kajaszo, Hungary",
  "Kakinada, India",
  "Kal, Hungary",
  "Kalamata, Greece",
  "Kalambaka, Greece",
  "Kalefeld, Germany",
  "Kalety, Poland",
  "Kalida, United States",
  "Kaliningrad, Russia",
  "Kalinkovichi, Belarus",
  "Kalisz, Poland",
  "Kalives, Greece",
  "Kall, Germany",
  "Kalletal, Germany",
  "Kallo, Belgium",
  "Kalmthout, Belgium",
  "Kalochori, Greece",
  "Kaltenkirchen, Germany",
  "Kalundborg, Denmark",
  "Kalwaria Zebrzydowska, Poland",
  "Kamen, Germany",
  "Kamena Vourla, Greece",
  "Kamenice Nad Lipou, Czech Republic",
  "Kamenicky Senov, Czech Republic",
  "Kamenz, Germany",
  "Kamienica Krolewska, Poland",
  "Kamienica Polska, Poland",
  "Kamienna Gora, Poland",
  "Kamionna, Poland",
  "Kammerstein, Germany",
  "Kammlach, Germany",
  "Kamnik, Slovenia",
  "Kampala, Uganda",
  "Kampen, Netherlands",
  "Kampenhout, Belgium",
  "Kampinos, Poland",
  "Kamp-Lintfort, Germany",
  "Kamyk nad Vltavou, Czech Republic",
  "Kanazawa, Japan",
  "Kanczuga, Poland",
  "Kandahar, Afghanistan",
  "Kandel, Germany",
  "Kandla SEZ, India",
  "Kanfanar, Croatia",
  "Kangasala, Finland",
  "Kankaanpaa, Finland",
  "Kannapolis, United States",
  "Kano, Nigeria",
  "Kanpur, India",
  "Kansas City, United States",
  "Kanturk Cork, Ireland",
  "Kantvik, Finland",
  "Kaohsiung, Taiwan China",
  "Kapelle, Netherlands",
  "Kapellen, Belgium",
  "Kapfenberg, Austria",
  "Kaplice, Czech Republic",
  "Kaposvar, Hungary",
  "Kappel-Grafenhausen, Germany",
  "Kappelrodeck, Germany",
  "Karacabey, Turkey",
  "Karachi, Pakistan",
  "Karaman, Turkey",
  "Karamursel, Turkey",
  "Karben, Germany",
  "Karczew, Poland",
  "Karditsa, Greece",
  "Kardjali, Bulgaria",
  "Kareedouw, South Africa",
  "Kargowa, Poland",
  "Karibib, Namibia",
  "Karlovac, Croatia",
  "Karlovice, Czech Republic",
  "Karlovo, Bulgaria",
  "Karlovy Vary, Czech Republic",
  "Karlsbad, Germany",
  "Karlsdorf-Neuthard, Germany",
  "Karlsfeld (bei Muenchen), Germany",
  "Karlshamn, Sweden",
  "Karlskoga, Sweden",
  "Karlslunde, Denmark",
  "Karlsruhe Baden, Germany",
  "Karlstad, Sweden",
  "Karlstadt (Main), Germany",
  "Karlstein, Germany",
  "Karnal, India",
  "Karnobat, Bulgaria",
  "Karns City, United States",
  "Karolinka U Vsetina, Czech Republic",
  "Karstaedt, Germany",
  "Karstula, Finland",
  "Kartal, Turkey",
  "Kartepe, Turkey",
  "Karvina, Czech Republic",
  "Kasba Tadla, Morocco",
  "Kasendorf, Germany",
  "Kashima Ibakari, Japan",
  "Kashipur, India",
  "Kasinhani, Turkey",
  "Kaspichan, Bulgaria",
  "Kassel, Germany",
  "Kastamonu, Turkey",
  "Kasterlee, Belgium",
  "Kastl (Kr Altoetting), Germany",
  "Kastoria, Greece",
  "Kastrup, Denmark",
  "Katarinska Huta, Slovakia",
  "Katerini, Greece",
  "Kathuwas, India",
  "Katowice, Poland",
  "Katrineholm, Sweden",
  "Kattedan, India",
  "Kattupalli, India",
  "Katunitza, Bulgaria",
  "Katwijk, Netherlands",
  "Katwijk aan Zee, Netherlands",
  "Katy, United States",
  "Katy Wroclawskie, Poland",
  "Kaufbeuren, Germany",
  "Kaufering, Germany",
  "Kaufungen (Hessen), Germany",
  "Kauhajoki, Finland",
  "Kaukauna, United States",
  "Kaunas, Lithuania",
  "Kavadarci, Macedonia",
  "Kavala, Greece",
  "Kayseri, Turkey",
  "Kaysersberg, France",
  "Kazan, Turkey",
  "Kazanlak, Bulgaria",
  "Kazincbarcika, Hungary",
  "Kaznejov, Czech Republic",
  "Kazrin, Israel",
  "Kearny, United States",
  "Keasbey, United States",
  "Kebeli, Tunisia",
  "Kechnec, Slovakia",
  "Kecskemet, Hungary",
  "Kedainiai, Lithuania",
  "Kedange sur Canner, France",
  "Kedrzyno, Poland",
  "Kedzierzyn-Kozle, Poland",
  "Keelung, Taiwan China",
  "Kefenrod, Germany",
  "Kefermarkt, Austria",
  "Kegums, Latvia",
  "Kehl, Germany",
  "Keighley, United Kingdom",
  "Keila, Estonia",
  "Keith, United Kingdom",
  "Kelambakkam, India",
  "Kelanimulla, Sri Lanka",
  "Kelberg, Germany",
  "Kelheim, Germany",
  "Kelibia, Tunisia",
  "Kelkheim (Taunus), Germany",
  "Kellington, United Kingdom",
  "Kells Meath, Ireland",
  "Kelme, Lithuania",
  "Kelpen, Netherlands",
  "Kelso, United Kingdom",
  "Kelsterbach, Germany",
  "Keltern, Germany",
  "Kelvedon, United Kingdom",
  "Kemalpasa, Turkey",
  "Kematen an der Ybbs, Austria",
  "Kemerburgaz, Turkey",
  "Kemi, Finland",
  "Kemmlitz, Germany",
  "Kemnath (Stadt), Germany",
  "Kempen, Germany",
  "Kempston, United Kingdom",
  "Kempten (Allgaeu), Germany",
  "Kempton Park, South Africa",
  "Kenbridge, United States",
  "Kendal, United Kingdom",
  "Kendallville, United States",
  "Kenilworth, United Kingdom",
  "Kenitra, Morocco",
  "Kenmore, United States",
  "Kennedale, United States",
  "Kennedy Space Center, United States",
  "Kennesaw, United States",
  "Kennewick, United States",
  "Kenosha, United States",
  "Kensington B, South Africa",
  "Kensworth, United Kingdom",
  "Kent, United States",
  "Kentford, United Kingdom",
  "Kentmere Cumbria, United Kingdom",
  "Kenton, United States",
  "Kenton Suffolk, United Kingdom",
  "Kentville, Canada",
  "Kenzingen, Germany",
  "Keokuk, United States",
  "Kepno, Poland",
  "Keratea, Greece",
  "Kerkdriel, Netherlands",
  "Kerken, Germany",
  "Kerkrade, Netherlands",
  "Kernen im Remstal, Germany",
  "Kernersville, United States",
  "Kerpen (Eifel), Germany",
  "Kerpen (Rheinland), Germany",
  "Kerteminde, Denmark",
  "Kesalahti, Finland",
  "Kessel Lo, Belgium",
  "Kesselsdorf, Germany",
  "Kestel, Turkey",
  "Kesteren, Netherlands",
  "Keston London, United Kingdom",
  "Keswick Cumbria, United Kingdom",
  "Ketrzyn, Poland",
  "Kettenkamp, Germany",
  "Kettering, United Kingdom",
  "Ketteringham, United Kingdom",
  "Kettlethorpe, United Kingdom",
  "Kety, Poland",
  "Ketzin, Germany",
  "Keur Moussa Zone Two, Senegal",
  "Kevelaer, Germany",
  "Keynsham, United Kingdom",
  "Kezmarok, Slovakia",
  "Khandwa, India",
  "KHARKIV, Ukraine",
  "Khartoum, Sudan",
  "Kherson, Ukraine",
  "Khledia, Tunisia",
  "Khmelnik, Ukraine",
  "Khmelnitskiy, Ukraine",
  "Khoni, Georgia",
  "Khopoli, India",
  "Khotkovo, Russia",
  "Khouribga, Morocco",
  "KIBBUTZ EINAT, Israel",
  "Kibbutz Hazor, Israel",
  "Kibwezi, Kenya",
  "Kidderminster, United Kingdom",
  "Kidlington, United Kingdom",
  "Kidricevo, Slovenia",
  "Kiedrich, Germany",
  "Kiel, Germany",
  "Kiel Kanal, Germany",
  "Kielce, Poland",
  "Kielczow, Poland",
  "Kielczyglow, Poland",
  "Kierspe, Germany",
  "Kiev, Ukraine",
  "Kifisia, Greece",
  "Kigali, Rwanda",
  "Kilbarchan Renfrewshire - Scotland, United Kingdom",
  "Kilcock Kildare, Ireland",
  "Kildare Co Kildare, Ireland",
  "Kilgore, United States",
  "Kilis, Turkey",
  "Kilkeel Northern Ireland, United Kingdom",
  "KilkennyCo Kilkenny, Ireland",
  "Kilkis, Greece",
  "Killala Mayo, Ireland",
  "Killarney Gardens, South Africa",
  "Killarney Kerry, Ireland",
  "Killingholme, United Kingdom",
  "Killingworth, United Kingdom",
  "Killybegs Donegal, Ireland",
  "Kilmacolm, United Kingdom",
  "Kilmacthomas Co Waterford, Ireland",
  "Kilmallock Limerick, Ireland",
  "Kilmarnock, United Kingdom",
  "Kilsyth, United Kingdom",
  "Kimbe, Papua New Guinea",
  "Kimhae, Korea, South",
  "Kincardine Fife, United Kingdom",
  "Kinderbeuern, Germany",
  "Kinding, Germany",
  "King Abdullah Port, Saudi Arabia",
  "King Of Prussia, United States",
  "King William, United States",
  "Kingman, United States",
  "Kings Bay, United States",
  "Kings Langley, United Kingdom",
  "King's Lynn, United Kingdom",
  "Kings Mountain, United States",
  "Kings Norton, United Kingdom",
  "Kingscourt Cavan, Ireland",
  "Kingsland, United States",
  "Kingsteignton, United Kingdom",
  "Kingston, Jamaica",
  "Kingston upon Thames London, United Kingdom",
  "Kingswinford, United Kingdom",
  "Kingussie, United Kingdom",
  "KINIK, Turkey",
  "Kinnarp, Sweden",
  "Kinnerley, United Kingdom",
  "Kinross, United Kingdom",
  "Kinshasa, Congo, Dem. Rep. of",
  "Kintore, United Kingdom",
  "Kipfenberg, Germany",
  "Kirac, Turkey",
  "Kirby Muxloe, United Kingdom",
  "Kirchanschoering, Germany",
  "Kirchardt, Germany",
  "Kirchberg (Hunsrueck), Germany",
  "Kirchberg an der Jagst, Germany",
  "Kirchbichl, Austria",
  "Kirchdorf (an der Iller), Germany",
  "Kirchdorf (b Haag, Oberbay), Germany",
  "Kirchdorf (bei Sulingen), Germany",
  "Kirchdorf am Inn, Germany",
  "Kirchdorf in Tirol, Austria",
  "Kirchen (Sieg), Germany",
  "Kirchgandern, Germany",
  "Kirchgellersen, Germany",
  "Kirchhain, Germany",
  "Kirchheim, Germany",
  "Kirchheim (bei Muenchen), Germany",
  "Kirchheim (Schwaben), Germany",
  "Kirchheim (unter Teck), Germany",
  "Kirchheim am Neckar, Germany",
  "Kirchheimbolanden, Germany",
  "Kirchhundem, Germany",
  "Kirchlengern, Germany",
  "Kirchseeon, Germany",
  "KIRIKHAN, Turkey",
  "Kirikkale, Turkey",
  "Kirillovka, Russia",
  "Kirjat Malachi, Israel",
  "Kirkby in Ashfield, United Kingdom",
  "Kirkby In Furness, United Kingdom",
  "Kirkby Merseyside, United Kingdom",
  "Kirkby Stephen Cumbria, United Kingdom",
  "Kirkcaldy Fife, United Kingdom",
  "Kirke Saaby, Denmark",
  "Kirkel, Germany",
  "Kirkham Lancashire, United Kingdom",
  "Kirkintilloch, United Kingdom",
  "Kirklareli, Turkey",
  "Kirkliston, United Kingdom",
  "Kirkmichael South Ayrshire, United Kingdom",
  "Kirksandall, United Kingdom",
  "Kirkwood, South Africa",
  "Kirkwood, United States",
  "Kirmington, United Kingdom",
  "Kirn, Germany",
  "Kirov Kirov, Russia",
  "Kirriemuir, United Kingdom",
  "Kirschau, Germany",
  "Kirsehir, Turkey",
  "Kirton-In-Lindsey, United Kingdom",
  "Kisikkoy, Turkey",
  "Kiskoros, Hungary",
  "Kiskunfelegyhaza, Hungary",
  "Kissimmee, United States",
  "Kissing, Germany",
  "Kisslegg, Germany",
  "Kist, Germany",
  "Kistarcsa, Hungary",
  "Kisvarda, Hungary",
  "Kitchener, Canada",
  "Kittanning, United States",
  "Kittsee, Austria",
  "Kitwe, Zambia",
  "Kitzingen, Germany",
  "Kjellerup, Denmark",
  "Kladno, Czech Republic",
  "Kladruby, Czech Republic",
  "Kladruby Nad Labem, Czech Republic",
  "Klagenfurt, Austria",
  "Klaipeda, Lithuania",
  "Klang, Malaysia",
  "Klasterec nad Ohri, Czech Republic",
  "Klatovy, Czech Republic",
  "Klaukkala, Finland",
  "Klecany, Czech Republic",
  "Kleinbettingen, Luxemburg",
  "Kleinblittersdorf, Germany",
  "Kleinheubach, Germany",
  "Kleinkarlbach, Germany",
  "Kleinostheim, Germany",
  "Kleinsendelbach, Germany",
  "Kleinwallstadt, Germany",
  "Klembow, Poland",
  "Klenci pod Cerchovem, Czech Republic",
  "Klenovice Na Hane, Czech Republic",
  "Klepp Stasjon, Norway",
  "Kleppestoe, Norway",
  "Kleve (Niederrhein), Germany",
  "Klin (Moscow), Russia",
  "Klingenberg, Germany",
  "Klingenberg (am Main), Germany",
  "Klingnau, Switzerland",
  "Klipphausen, Germany",
  "Kliprivier, South Africa",
  "Kloczew, Poland",
  "Klodawa, Poland",
  "Klodzko, Poland",
  "Kloosterzande, Netherlands",
  "Klucze, Poland",
  "Kluisbergen, Belgium",
  "Klundert, Netherlands",
  "Knapsack (Huerth), Germany",
  "Knapton Yorks, United Kingdom",
  "Knaresborough Yorkshire, United Kingdom",
  "Knebworth, United Kingdom",
  "KNEJA, Bulgaria",
  "Knesebeck, Germany",
  "Knetzgau, Germany",
  "Knezeves, Czech Republic",
  "Knighton Powys, United Kingdom",
  "Knin, Croatia",
  "Knottingley, United Kingdom",
  "Knowsley, United Kingdom",
  "Knoxville, United States",
  "Knurow, Poland",
  "Knutange, France",
  "Knutsford, United Kingdom",
  "Kobe, Japan",
  "Kobierzyce, Poland",
  "Koblenz (am Rhein), Germany",
  "Kobuleti, Georgia",
  "Kobylka, Poland",
  "Kocevje, Slovenia",
  "Kocevska Reka, Slovenia",
  "kockelscheuer, Luxemburg",
  "Kocovce, Slovakia",
  "Kodersdorf, Germany",
  "Kodiak, United States",
  "Koeditz (Oberfranken), Germany",
  "Koeflach, Austria",
  "Koege, Denmark",
  "Koekelare, Belgium",
  "Koelleda, Germany",
  "Koenigsberg (i Bay), Germany",
  "Koenigsbrunn, Germany",
  "Koenigsee-Rottenbach, Germany",
  "Koenigstein (Im Taunus), Germany",
  "Koenigstein (Saechs Schweiz), Germany",
  "Koenigswinter, Germany",
  "Koennern, Germany",
  "Koesching, Germany",
  "Koetz, Germany",
  "Kohila, Estonia",
  "Kohtla-Jarve, Estonia",
  "Kokotow, Poland",
  "KOLAR, India",
  "Kolbermoor, Germany",
  "Kolbingen, Germany",
  "Kolbu, Norway",
  "Kolding, Denmark",
  "Kolga Jaani, Estonia",
  "Kolham, Netherlands",
  "Kolicevo, Slovenia",
  "Kolin, Czech Republic",
  "Kolitzheim, Germany",
  "Kolkata, India",
  "Kolkwitz, Germany",
  "Kolo, Poland",
  "Kolonia Poczesna, Poland",
  "kolsva, Sweden",
  "Koluszki, Poland",
  "KOMARIV, Ukraine",
  "Komarno, Slovakia",
  "Komarom, Hungary",
  "Komarov, Czech Republic",
  "Komjatice, Slovakia",
  "Komorniki, Poland",
  "Komotini, Greece",
  "Koniecpol, Poland",
  "Konin, Poland",
  "Konnersreuth, Germany",
  "Konolfingen, Switzerland",
  "Konradsreuth, Germany",
  "Konskie, Poland",
  "Konskowola, Poland",
  "Konstanz, Germany",
  "Kontich, Belgium",
  "Konya, Turkey",
  "Konz, Germany",
  "Koog aan de Zaan, Netherlands",
  "Kootstertille, Netherlands",
  "Koper, Slovenia",
  "Kopparberg, Sweden",
  "Koprivnica, Croatia",
  "Koprivnice, Czech Republic",
  "Korb (Remstal), Germany",
  "Korba, India",
  "Korba, Tunisia",
  "Korbach, Germany",
  "Korinos, Greece",
  "Korinthos, Greece",
  "Kormend, Hungary",
  "Korneuburg, Austria",
  "Korntal-Muenchingen, Germany",
  "Kornwestheim, Germany",
  "Kornye, Hungary",
  "Koropi, Greece",
  "Korosten, Ukraine",
  "Korostishev, Ukraine",
  "Korsakov, Russia",
  "Korschenbroich, Germany",
  "Korsoer, Denmark",
  "Kortrijk, Belgium",
  "Korycany, Czech Republic",
  "Korytnica, Poland",
  "Koscian, Poland",
  "Kosice, Slovakia",
  "Koski TI, Finland",
  "Kosofe, Nigeria",
  "Kosow Lacki, Poland",
  "Kostalov, Czech Republic",
  "Kostanjevica na Krki, Slovenia",
  "Kostelec, Czech Republic",
  "Kostelec na Hane, Czech Republic",
  "Kostelec nad Cernymi Lesy, Czech Republic",
  "Kostelec nad Labem, Czech Republic",
  "Kostelec nad Orlici, Czech Republic",
  "Kostenec, Bulgaria",
  "Kostinbrod, Bulgaria",
  "Kostolne, Slovakia",
  "Kostowiec, Poland",
  "Kostrzyn (Lubuskie), Poland",
  "Kostrzyn Wielkopolski, Poland",
  "Koszalin, Poland",
  "Kot Kapura, India",
  "Kota, India",
  "Kota Kinabalu, Malaysia",
  "Kotka, Finland",
  "Kotlarnia, Poland",
  "Kottayam, India",
  "Kottingbrunn, Austria",
  "Kottmar, Germany",
  "Kotvrdovice, Czech Republic",
  "Koufalia, Greece",
  "Kounice, Czech Republic",
  "Kourim, Czech Republic",
  "Kouvola, Finland",
  "Kovel, Ukraine",
  "Kowale, Poland",
  "Kowary, Poland",
  "Kowiesy, Poland",
  "Kozan, Turkey",
  "Kozani, Greece",
  "Kozmin Wlkp, Poland",
  "Kozomin, Czech Republic",
  "Kraftisried, Germany",
  "Kragujevac, Serbia",
  "Kraichtal, Germany",
  "Krailling, Germany",
  "Krakow, Poland",
  "Kralice na Hane, Czech Republic",
  "Kraliky, Czech Republic",
  "Kralova nad Vahom, Slovakia",
  "Kralovsky Chlmec, Slovakia",
  "Kralupy nad Vltavou, Czech Republic",
  "Kraluv Dvur, Czech Republic",
  "Kramfors, Sweden",
  "Kranenburg (Niederrhein), Germany",
  "Kranj, Slovenia",
  "Kranzberg, Germany",
  "Krapina, Croatia",
  "Krapkowice, Poland",
  "Krasna Lipa, Czech Republic",
  "Krasnik, Poland",
  "Krasno Nad Kysucou, Slovakia",
  "Krasnodar, Russia",
  "Krasocin, Poland",
  "Krauchenwies, Germany",
  "Krautheim/Jagst, Germany",
  "Krefeld, Germany",
  "Krefeld-Uerdingen, Germany",
  "Kreimbach-Kaulbach, Germany",
  "Kremenchuk, Ukraine",
  "Kremnica, Slovakia",
  "Krems an der Donau, Austria",
  "Kremsmuenster, Austria",
  "Kressberg, Germany",
  "Kressbronn, Germany",
  "Kretinga, Lithuania",
  "Kretzschau, Germany",
  "Kreuzlingen, Switzerland",
  "Kreuztal, Germany",
  "Kreuzwertheim, Germany",
  "Krichim, Bulgaria",
  "Kriebstein, Germany",
  "Krieglach, Austria",
  "Kriens, Switzerland",
  "Kriftel, Germany",
  "Krimpen a d Ijssel, Netherlands",
  "Krinec, Czech Republic",
  "Krioneri Attica, Greece",
  "Krishnapatnam, India",
  "Kristiansand, Norway",
  "Kristianstad, Sweden",
  "Kristinehamn, Sweden",
  "Kriva Palanka, Macedonia",
  "Krizanov, Czech Republic",
  "KRNCA, Slovakia",
  "Krnov, Czech Republic",
  "Krobia, Poland",
  "Kromeriz, Czech Republic",
  "Krompachy, Slovakia",
  "Kronach, Germany",
  "Kronshtadt, Russia",
  "Kroppenstedt, Germany",
  "Krosno, Poland",
  "Krostitz, Germany",
  "Krouna, Czech Republic",
  "Krska Vas, Slovenia",
  "Krsko, Slovenia",
  "Kruft, Germany",
  "Krugersdorp, South Africa",
  "Kruibeke, Belgium",
  "Kruiningen, Netherlands",
  "Kruishoutem, Belgium",
  "Krumbach (Schwaben), Germany",
  "Krummnussbaum, Austria",
  "Krumovgrad, Bulgaria",
  "Krupina, Slovakia",
  "Krupka, Czech Republic",
  "Krupski Mlyn, Poland",
  "Krusevac, Serbia",
  "Krusovce, Slovakia",
  "Krusovice, Czech Republic",
  "Krusaa, Denmark",
  "Krymsk, Russia",
  "Krzanowice, Poland",
  "Krzepice, Poland",
  "Krzywaczka, Poland",
  "Kraakeroey, Norway",
  "Kuantan, Malaysia",
  "Kubrat, Bulgaria",
  "Kuching, Malaysia",
  "Kucukcekmece, Turkey",
  "Kuelsheim (Baden), Germany",
  "Kuemmersbruck, Germany",
  "Kuenzell, Germany",
  "Kuenzelsau, Germany",
  "Kueps, Germany",
  "Kufstein, Austria",
  "Kuilsriver, South Africa",
  "Kulai, Malaysia",
  "Kuldiga, Latvia",
  "Kulmbach, Germany",
  "Kumanovo, Macedonia",
  "Kumasi, Ghana",
  "Kumla, Sweden",
  "Kuncicky u Basky, Czech Republic",
  "Kungsoer, Sweden",
  "Kunice u Rican, Czech Republic",
  "Kunin, Czech Republic",
  "Kunovice, Czech Republic",
  "Kunshan, China",
  "Kunstat na Morave, Czech Republic",
  "Kunszentmarton, Hungary",
  "Kunszentmiklos, Hungary",
  "Kupferzell, Germany",
  "Kupiskis, Lithuania",
  "Kuppenheim, Germany",
  "Kurim, Czech Republic",
  "Kurow, Poland",
  "Kurtkoy-Istanbul, Turkey",
  "Kustavi, Finland",
  "Kusterdingen, Germany",
  "Kutahya, Turkey",
  "Kutaisi, Georgia",
  "Kutna Hora, Czech Republic",
  "Kutno, Poland",
  "Kuty, Slovakia",
  "Kutztown, United States",
  "Kuwait, Kuwait",
  "Kuznica Kiedrzynska, Poland",
  "Kvareli, Georgia",
  "Kwekwe, Zimbabwe",
  "Kwidzyn, Poland",
  "Kyjov, Czech Republic",
  "Kyritz (Prignitz), Germany",
  "Kyselka, Czech Republic",
  "Kysucke Nove Mesto, Slovakia",
  "Kyustendil, Bulgaria",
  "Kaallekaer, Sweden",
  "Kaarst, Germany",
  "L Alcudia, Spain",
  "L Aquila, Italy",
  "La Alberca de Zan, Spain",
  "La Albuera, Spain",
  "La Algaba, Spain",
  "La Aljorra, Spain",
  "La Aparecida, Spain",
  "La Arenosa, Panama",
  "La Baneza, Spain",
  "La Bathie, France",
  "La Baume d'Hostun, France",
  "La Calera, Chile",
  "LA CAMPANA, Spain",
  "La Canonja, Spain",
  "La Carlota, Spain",
  "La Carolina, Spain",
  "La Cartuja, Spain",
  "La Cassa, Italy",
  "La Ceiba, Honduras",
  "La Cellera Del Ter, Spain",
  "La Chaize le Vicomte, France",
  "La Chambre, France",
  "La Chapelle d'Andaine, France",
  "La Chapelle d'Armentieres, France",
  "La Chapelle de Guinchay, France",
  "La Chapelle Gaceline, France",
  "La Chapelle Montlinard, France",
  "La Chapelle Saint Laurent, France",
  "La Chapelle Saint Luc, France",
  "La Chapelle Saint Mesmin, France",
  "La Chapelle St Martial, France",
  "La Chapelle sur Erdre, France",
  "La Chappelle Saint Mesnin, France",
  "La Chataigneraie, France",
  "La Chevroliere, France",
  "La Ciotat, France",
  "La Cisterniga, Spain",
  "La Copechagniere, France",
  "La Coruna, Spain",
  "La Cote, France",
  "La Cote Saint Andre, France",
  "La Courneuve, France",
  "La Couronne, France",
  "La Courtine, France",
  "La Creche Deux Sevres, France",
  "La Croix aux Mines, France",
  "La Crosse, United States",
  "La Farlede, France",
  "La Fayette, United States",
  "La Felguera, Spain",
  "La Ferte Bernard, France",
  "La Ferte sous Jouarre, France",
  "La Flamengrie, France",
  "La Fleche, France",
  "La Fortuna, Costa Rica",
  "La Fouillouse, France",
  "La Gacilly, France",
  "La Galera, Spain",
  "La Garnache, France",
  "La Garriga, Spain",
  "La Garrovilla Badajoz, Spain",
  "La Genete, France",
  "La Gineta, Spain",
  "La Gorgue, France",
  "La Granada, Spain",
  "La Grand Croix, France",
  "La Grange, United States",
  "La Granja, Spain",
  "La Granja de la Costera, Spain",
  "La Granja Ildefonso, Spain",
  "La Guaira, Venezuela",
  "La Guardia Pontevedra, Spain",
  "La Hoya, Spain",
  "La Joyosa, Spain",
  "La Libertad, Ecuador",
  "La Libertad, El Salvador",
  "La Ligua, Chile",
  "La Lima, Honduras",
  "La Linea de la Concepcion, Spain",
  "La Llosa, Spain",
  "La Loggia, Italy",
  "La Loyere, France",
  "La Mana, Ecuador",
  "La Marina Del Pinet, Spain",
  "La Marsa, Tunisia",
  "LA MATA, Spain",
  "La Maxe, France",
  "La Mirada, United States",
  "La Mojonera, Spain",
  "La Monnerie le Montel, France",
  "La Morra, Italy",
  "La Mothe Achard, France",
  "La Motte Servolex, France",
  "La Muela Zaragoza, Spain",
  "La Nucia, Spain",
  "La Orotava, Spain",
  "La Palma Murcia, Spain",
  "La Paz, El Salvador",
  "La Penilla de Cayon, Spain",
  "La Piedad, Mexico",
  "La Plata, Argentina",
  "La Pobla de Claramunt, Spain",
  "La Pobla Del Duc, Spain",
  "La Porte, United States",
  "La Portella, Spain",
  "La Puebla de Alfinden, Spain",
  "La Puebla del Rio, Spain",
  "La Pueblanueva, Spain",
  "La Rambla, Spain",
  "La Reole, France",
  "La Ricamarie, France",
  "La Riche, France",
  "La Rinconada, Spain",
  "La Riviere de Corps, France",
  "La Roca del Valles, Spain",
  "La Roche de Glun, France",
  "La Roche de Rame, France",
  "La Roche sur Yon, France",
  "La Rochelle, France",
  "La Rochette, France",
  "La Roda Albacete, Spain",
  "La Roda de Andalucia, Spain",
  "La Romana, Spain",
  "La Rouge, France",
  "La Rue Saint Pierre, France",
  "La Sauve, France",
  "La Selva del Campo, Spain",
  "La Senia, Spain",
  "La Sentiu de Sio, Spain",
  "La Seyne sur Mer, France",
  "La Solana, Spain",
  "La Souterraine, France",
  "La Spezia, Italy",
  "La Suze sur Sarthe, France",
  "La Talaudiere, France",
  "La Teste, France",
  "La Tigra de San Carlos, Costa Rica",
  "La Torre de Esteban Hambran, Spain",
  "La Tour de Salvagny, France",
  "La Tronche, France",
  "La Union, El Salvador",
  "La Vega, Dominican Republic",
  "La Vergne, United States",
  "La Verpilliere, France",
  "La Verrie, France",
  "La Vista, United States",
  "La Voulte sur Rhone, France",
  "La Wantzenau, France",
  "La Zaida, Spain",
  "LABAJOS, Spain",
  "Labarthe Inard, France",
  "Labastide Saint Pierre, France",
  "Labastide-Beauvoir, France",
  "Labatut, France",
  "Labenne, France",
  "Labico, Italy",
  "Labourse, France",
  "Labrede, France",
  "Labruguiere, France",
  "Lacanche, France",
  "Lacchiarella, Italy",
  "Laces, Italy",
  "Lachen, Germany",
  "Lachine, Canada",
  "Lacq, France",
  "Lacrost, France",
  "Ladaux, France",
  "Ladbergen, Germany",
  "Ladce, Slovakia",
  "Ladenburg, Germany",
  "Ladna, Czech Republic",
  "Ladna, Poland",
  "Ladson, United States",
  "Ladzice, Poland",
  "Lae, Papua New Guinea",
  "Laem Chabang, Thailand",
  "Laer (Kr Steinfurt), Germany",
  "Lafayette, United States",
  "Lafox, United States",
  "Lafrancaise, France",
  "Lagadas, Greece",
  "Lage (Kr Lippe), Germany",
  "Lagedi, Estonia",
  "Lagnasco, Italy",
  "Lagnieu, France",
  "Lagny le Sec, France",
  "Lagny sur Marne, France",
  "Lagos de Moreno, Mexico",
  "LaGrange, United States",
  "Laguardia, Spain",
  "Laguardia Alava, Spain",
  "Laguepie, France",
  "Laguna de Duero, Spain",
  "Laguna Hills, United States",
  "Lagundo, Italy",
  "Lahnau, Germany",
  "Lahnstein, Germany",
  "Lahntal, Germany",
  "Laholm, Sweden",
  "Lahontan, France",
  "Lahore, Pakistan",
  "Lahr (Schwarzwald), Germany",
  "Lahti, Finland",
  "Laichingen, Germany",
  "L'Aigle, France",
  "Laille, France",
  "Lainate, Italy",
  "Laindon, United Kingdom",
  "LAISSAC, France",
  "Laitila, Finland",
  "Laives, Italy",
  "Lake Charles, United States",
  "Lake City, United States",
  "Lake Forest, United States",
  "Lake Wales, United States",
  "Lake Zurich, United States",
  "Lakehurst, United States",
  "Lakeland, United States",
  "Lakenheath, United Kingdom",
  "Lakeville, United States",
  "Lakewood, United States",
  "Lakkoma, Greece",
  "Lakuntza, Spain",
  "L'Alcudia de Carlet, Spain",
  "Lalin, Spain",
  "Lalinde, France",
  "Lallio, Italy",
  "LA-LONDE-LES-MAURES, France",
  "Lalueza, Spain",
  "Lamastre, France",
  "Lambach, Austria",
  "Lamballe-Armor, France",
  "Lamberhurst Kent, United Kingdom",
  "Lambeth, United Kingdom",
  "Lambourn, United Kingdom",
  "Lambourn Woodlands, United Kingdom",
  "Lambourne End, United Kingdom",
  "Lambrecht (Pfalz), Germany",
  "Lambsborn, Germany",
  "L'ametlla del Valles, Spain",
  "Lamia, Greece",
  "Lammari, Italy",
  "Lamonzie Saint Martin, France",
  "Lampertheim, Germany",
  "Lampertheim Bas Rhin, France",
  "Lampertswalde (bei Grossenhain), Germany",
  "Lampeter, United Kingdom",
  "Lamporecchio, Italy",
  "Lamspringe, Germany",
  "Lana d'Adige, Italy",
  "Lanaja, Spain",
  "Lanaken, Belgium",
  "Lancaster, United Kingdom",
  "Lancaster, United States",
  "Lanciano, Italy",
  "Lancing, United Kingdom",
  "Lancut, Poland",
  "Landau (an der Isar), Germany",
  "Landau (in der Pfalz), Germany",
  "Landeleau, France",
  "Landerrouat, France",
  "Landersheim, France",
  "Landhi, Pakistan",
  "Landiras, France",
  "Landivisiau, France",
  "Landivy, France",
  "Landquart, Switzerland",
  "Landriano, Italy",
  "Landsberg (a Lech), Germany",
  "Landsberg bei Halle, Germany",
  "Landscheid (Eifel), Germany",
  "Landshut, Germany",
  "Landskrona, Sweden",
  "Laneuveville devant Nancy, France",
  "Langar, United Kingdom",
  "Langelsheim, Germany",
  "Langemark, Belgium",
  "Langen in Hessen, Germany",
  "Langenau (Wuertt), Germany",
  "Langenbach (B Kirburg), Germany",
  "Langenbach (Freising), Germany",
  "Langenberg (Kr Guetersloh), Germany",
  "Langenbrettach, Germany",
  "Langenenslingen, Germany",
  "Langenfeld (Mittelfr), Germany",
  "Langenfeld (Rheinland), Germany",
  "Langenhagen (Hann), Germany",
  "Langenlonsheim, Germany",
  "Langenmosen, Germany",
  "Langenneufnach, Germany",
  "Langenselbold, Germany",
  "Langenthal, Switzerland",
  "Langenwetzendorf, Germany",
  "Langenzenn, Germany",
  "Langeron, France",
  "Langerwehe, Germany",
  "Langgoens, Germany",
  "Langhirano, Italy",
  "Langholm, United Kingdom",
  "Langkampfen, Austria",
  "Langkloof, South Africa",
  "Langkloof E Cape, South Africa",
  "Langley, Canada",
  "Langley Cheshire, United Kingdom",
  "Langley Moor, United Kingdom",
  "Langport, United Kingdom",
  "Langreo Asturias, Spain",
  "Langres, France",
  "Languidic, France",
  "Langweid (a Lech), Germany",
  "Lanhelin, France",
  "Lanjaron, Spain",
  "Lanko, South Africa",
  "Lansargues, France",
  "Lanshi, China",
  "Lansing, United States",
  "Lanskroun, Czech Republic",
  "Lantaron, Spain",
  "Lantejuela, Spain",
  "Lantenne Vertiere, France",
  "Lanuvio, Italy",
  "Lanvallay, France",
  "Lanvenegen, France",
  "Lanzenkirchen, Austria",
  "Lanzhot, Czech Republic",
  "Lanzo d'Intelvi, Italy",
  "Lanzo Torinese, Italy",
  "Laon, France",
  "Lapalisse, France",
  "Lapanga, India",
  "Lapczyca, Poland",
  "Lapford, Devon, United Kingdom",
  "Lapoutroie, France",
  "Lappeenranta, Finland",
  "Lappersdorf, Germany",
  "Lapseki, Turkey",
  "Laracha, Spain",
  "Larache, Morocco",
  "l'arbresle, France",
  "Larciano, Italy",
  "Lardero, Spain",
  "L'Ardoise, France",
  "Lardy, France",
  "Laredo, Spain",
  "Laredo, United States",
  "Largo, United States",
  "Largs, United Kingdom",
  "Larino, Italy",
  "Larisa, Greece",
  "Larkfield Kent, United Kingdom",
  "Larkhall, United Kingdom",
  "Larling Norfolk, United Kingdom",
  "Larnaca, Cyprus",
  "Larne Northern Ireland, United Kingdom",
  "Laronxe, France",
  "Larraga Navarra, Spain",
  "Larvik, Norway",
  "Las Bayas, Spain",
  "Las Canalejas, Spain",
  "Las Cruces, United States",
  "Las Labores, Spain",
  "Las Norias de Daza, Spain",
  "Las Palmas Gran Canaria, Spain",
  "Las Pedroneras, Spain",
  "Las Rozas, Spain",
  "Las Somozas Ferrol, Spain",
  "Las Tablas, Panama",
  "Las Torres de Cotillas, Spain",
  "Las Vegas, United States",
  "Lasa, Italy",
  "Lasalgaon, India",
  "Lasalle, Canada",
  "Lasarte, Spain",
  "Laskarzew, Poland",
  "Lassing, Austria",
  "Latakia, Syria",
  "Latchingdon, United Kingdom",
  "Lathrop, United States",
  "Latina, Italy",
  "Latina Scalo, Italy",
  "Latrany, Hungary",
  "Latrobe, United States",
  "Laubach (Hessen), Germany",
  "Lauben, Germany",
  "Lauben (Oberallgaeu), Germany",
  "Laucha (Unstrut), Germany",
  "Lauchheim, Germany",
  "Lauda-Koenigshofen, Germany",
  "Laudenbach (Rhein-Neckar), Germany",
  "Laudun, France",
  "Lauenfoerde, Germany",
  "Lauf (a d Pegnitz), Germany",
  "Laufeld, Germany",
  "Laufen, Switzerland",
  "Lauingen (Donau), Germany",
  "Launceston, United Kingdom",
  "Laupheim, Germany",
  "Laurel, United States",
  "Laurencekirk, United Kingdom",
  "Lauriano, Italy",
  "Lauscha, Germany",
  "Laussig, Germany",
  "Lauta, Germany",
  "Lautenbach, Germany",
  "Lauterach, Austria",
  "Lauterbach (Hessen), Germany",
  "Lauterbourg, France",
  "Lauterecken, Germany",
  "Lautert, Germany",
  "Lautoka, Fiji Islands",
  "Lauwe, Belgium",
  "Lauwin Planque, France",
  "Lauzacco, Italy",
  "Laval, Canada",
  "Laval, France",
  "Laval sur Vologne, France",
  "Lavardac, France",
  "Lavau, France",
  "Lavelanet, France",
  "Lavelanet de Comminges, France",
  "Laveno, Italy",
  "Laveno Mombello, Italy",
  "Lavenone, Italy",
  "Lavera, France",
  "Laveyron, France",
  "Lavezzola, Italy",
  "Lavis, Italy",
  "Lavrio, Greece",
  "Lawrence, United States",
  "Lawrenceburg, United States",
  "Lawrenceville, United States",
  "Layrac, France",
  "Lazaro Cardenas, Mexico",
  "lazise, Italy",
  "Laziska Gorne, Poland",
  "Lazkao, Spain",
  "Lazne Belohrad, Czech Republic",
  "Lazne Bohdanec, Czech Republic",
  "Lazzate, Italy",
  "Le Bignon, France",
  "Le Blanc Mesnil, France",
  "Le Bourget, France",
  "Le Bouscat, France",
  "Le Breuil, France",
  "LE CAILAR, France",
  "Le Cannet Des Maures, France",
  "Le Cateau Cambresis, France",
  "Le Chambon Feugerolles, France",
  "Le Coteau, France",
  "Le Creusot, France",
  "Le Crotoy, France",
  "Le Faouet Morbihan, France",
  "Le Gault-Saint-Denis, France",
  "Le Grand Quevilly, France",
  "Le Haillan, France",
  "Le Havre, France",
  "Le Houga, France",
  "Le Landeron, Switzerland",
  "Le Mans, France",
  "Le Mars, United States",
  "Le May sur Evre, France",
  "Le Mesnil Amelot, France",
  "Le Meux, France",
  "Le Petit Quevilly, France",
  "Le Pin, France",
  "Le Plessis Belleville, France",
  "Le Poire sur Vie, France",
  "Le Pont de Claix, France",
  "Le Pouget, France",
  "Le Pouy du Touges, France",
  "Le Pouzin, France",
  "Le Relecq Kerhuon, France",
  "Le Rheu, France",
  "Le Sourn, France",
  "Le Temple Sur Lot, France",
  "Le Theil sur Huisne, France",
  "Le Thillay, France",
  "Le Thillot, France",
  "Le Trait, France",
  "Le Tremblay sur Mauldre, France",
  "Le Treport, France",
  "Le Val D'Hazey, France",
  "LE VANNEAU, France",
  "Le Vigeant, France",
  "Leadenham, United Kingdom",
  "Leamington, United Kingdom",
  "Leatherhead, United Kingdom",
  "Lebanon, United States",
  "Lebbeke, Belgium",
  "Lebork, Poland",
  "Lebrija, Spain",
  "Lecce, Italy",
  "Lecco, Italy",
  "Lechovice, Czech Republic",
  "Lecompte, United States",
  "Lecumberri, Spain",
  "Leczyca, Poland",
  "Ledbury, United Kingdom",
  "Ledec nad Sazavou, Czech Republic",
  "Lednice, Czech Republic",
  "Lednicke Rovne, Slovakia",
  "Ledziny, Poland",
  "Lee Moor Devon, United Kingdom",
  "Leeds Kent, United Kingdom",
  "Leeds Yorkshire, United Kingdom",
  "Leek, United Kingdom",
  "Leerdam, Netherlands",
  "Leers, France",
  "Leesburg, United States",
  "Leeuwarden, Netherlands",
  "Leganes, Spain",
  "Legazpia, Spain",
  "Lege, France",
  "Leghorn, Italy",
  "Legnago, Italy",
  "Legnano, Italy",
  "Legnaro, Italy",
  "Legnica, Poland",
  "Legnickie Pole, Poland",
  "Legorreta, Spain",
  "Legutiano, Spain",
  "LEHOTA, Slovakia",
  "Lehrte, Germany",
  "Leiblfing, Germany",
  "Leicester, United Kingdom",
  "Leichlingen (Rheinland), Germany",
  "Leiden, Netherlands",
  "Leigh Lancashire WN, United Kingdom",
  "Leigh-on-Sea, United Kingdom",
  "Leighton Buzzard, United Kingdom",
  "Leimbach, Germany",
  "Leimen (Rhein-Neckar), Germany",
  "Leinefelde, Germany",
  "Leinfelden-Echterdingen, Germany",
  "Leingarten, Germany",
  "Leini, Italy",
  "Leioa, Spain",
  "Leipheim, Germany",
  "Leipzig, Germany",
  "Leiria, Portugal",
  "Leiston, United Kingdom",
  "Leivi, Italy",
  "Leixlip Kildare, Ireland",
  "Leixoes, Portugal",
  "Leiza, Spain",
  "Leliu, China",
  "Lelystad, Netherlands",
  "Lem, Denmark",
  "Leme, Brazil",
  "Lemelerveld, Netherlands",
  "Lemfoerde, Germany",
  "Lemgo, Germany",
  "Lemignano, Italy",
  "Lemmer, Netherlands",
  "Lemmetsa, Estonia",
  "Lemoa, Spain",
  "Lempdes Puy de Dome, France",
  "Lempaala, Finland",
  "Lemvig, Denmark",
  "Lemwerder, Germany",
  "Lenart V Slovenskih Goricah, Slovenia",
  "Lengau, Austria",
  "Lengede, Germany",
  "Lengerich (Westf), Germany",
  "Lenggries, Germany",
  "Lenham, United Kingdom",
  "Lennestadt, Germany",
  "Lennik, Belgium",
  "Lenningen, Germany",
  "Leno, Italy",
  "Lenoir, United States",
  "Lens, France",
  "Lentate Sul Seveso, Italy",
  "Lentiai, Italy",
  "Lentilly, France",
  "Lenting, Germany",
  "Lentzweiler, Luxemburg",
  "Lenwade Norfolk NR, United Kingdom",
  "Lenzing, Austria",
  "Leobendorf, Austria",
  "Leominster, United Kingdom",
  "Leon, Mexico",
  "Leon, Nicaragua",
  "Leon, Spain",
  "Leonberg (Oberfr), Germany",
  "Leonding, Austria",
  "Leopoldsdorf, Austria",
  "Leopoldshoehe, Germany",
  "Lepe, Spain",
  "Lequio Berria, Italy",
  "Leran, France",
  "Lerici, Italy",
  "LERMA, Mexico",
  "LERMA, Spain",
  "Les Abrets, France",
  "Les Andelys, France",
  "Les Artigues de Lussac, France",
  "Les Barthes, France",
  "Les Bordes Loiret, France",
  "Les Borges Blanques, Spain",
  "Les Borges del Camp, Spain",
  "Les Echets, France",
  "Les Essarts le Roi, France",
  "Les Forges, France",
  "Les Franqueses del Valles, Spain",
  "Les Herbiers, France",
  "Les Landes Genusson, France",
  "Les Mazures, France",
  "Les Pavillons Sous Bois, France",
  "Les Ponts de, France",
  "Les Preses, Spain",
  "Les Rues des Vignes, France",
  "Les Sables d'Olonne, France",
  "Les Sorinieres, France",
  "Lescar, France",
  "Leskovac, Serbia",
  "Lesmahagow, United Kingdom",
  "Lesmo, Italy",
  "Lesperon, France",
  "Lespinasse, France",
  "Lessines, Belgium",
  "Lessona, Italy",
  "Lestiac sur garonne, France",
  "Lestrem, France",
  "Leszno, Poland",
  "Letchworth, United Kingdom",
  "Letnitsa, Bulgaria",
  "Letohrad, Czech Republic",
  "Letovice, Czech Republic",
  "Letterkenny Donegal, Ireland",
  "Leucate, France",
  "Leuna, Germany",
  "Leusden, Netherlands",
  "Leutenberg, Germany",
  "Leutershausen, Germany",
  "Leutkirch (im Allgaeu), Germany",
  "Leuven, Belgium",
  "Leuze en Hainaut, Belgium",
  "Levada, Italy",
  "Levane, Italy",
  "Levata Di Grontardo, Italy",
  "Levate, Italy",
  "Levent-Istanbul, Turkey",
  "Leverkusen, Germany",
  "Leves et Thoumeyragues, France",
  "Levice, Slovakia",
  "Levski, Bulgaria",
  "Lewes, United Kingdom",
  "Lewin Brzeski, Poland",
  "Lewis Center, United States",
  "Lewisberry, United States",
  "Lewisburg, United States",
  "Lewiston, United States",
  "Lewistown, United States",
  "Lewisville, United States",
  "Lexington, United States",
  "Lexmond, Netherlands",
  "Leyland, United Kingdom",
  "Leyton, United Kingdom",
  "Lezajsk, Poland",
  "Lezama, Spain",
  "Lezennes, France",
  "Lezo, Spain",
  "L'Hay Les Roses, France",
  "L'HERBERGEMENT, France",
  "L'Horme, France",
  "L'Hospitalet de Infant, Spain",
  "L'Hotellerie de Flee, France",
  "Lhotka Nad Becvou, Czech Republic",
  "Liancourt, France",
  "Lianyungang, China",
  "Liaskovec, Bulgaria",
  "Libcany, Czech Republic",
  "Libercourt, France",
  "Liberec, Czech Republic",
  "Liberia, Costa Rica",
  "Libertyville, United States",
  "Libiaz, Poland",
  "Libin, Czech Republic",
  "Libochovice, Czech Republic",
  "Libourne, France",
  "Libramont-Chevigny, Belgium",
  "Libreville, Gabon",
  "Librilla, Spain",
  "Libun, Czech Republic",
  "Lich (Hess), Germany",
  "Lichfield, United Kingdom",
  "Lichnov, Czech Republic",
  "Lichtenau, Germany",
  "Lichtenau (Mittelfr), Germany",
  "Lichtenau (Sachsen), Germany",
  "Lichtenberg / Erzgeb, Germany",
  "Lichtenfels, Germany",
  "Lichtenfels (Bay), Germany",
  "Lichtenstein (Wuerttemberg), Germany",
  "Lichtenvoorde, Netherlands",
  "Lichtervelde, Belgium",
  "Lidkoeping, Sweden",
  "Lidzbark Warminski, Poland",
  "Liebefeld, Switzerland",
  "Liebenscheid, Germany",
  "Liege, Belgium",
  "Liege Angleur, Belgium",
  "Lienz, Austria",
  "Liepaja, Latvia",
  "Lier, Belgium",
  "Lier, Norway",
  "Lieshout, Netherlands",
  "Lieuron, France",
  "Liezen, Austria",
  "Lignano Sabbiadoro, Italy",
  "Ligne, France",
  "Ligny-le-Chatel, France",
  "L'Ile d'Elle, France",
  "Lilesville, United States",
  "Lilienthal, Germany",
  "Lilla Edet, Sweden",
  "Lille, France",
  "Lille Skensved, Denmark",
  "Lillesand, Norway",
  "Lillestroem, Norway",
  "Lilongwe, Malawi",
  "Lima, United States",
  "Limana, Italy",
  "Limanowa, Poland",
  "Limassol, Cyprus",
  "Limavady Northern Ireland, United Kingdom",
  "Limay, France",
  "Limbach-Oberfrohna, Germany",
  "Limbazhi, Latvia",
  "Limbiate, Italy",
  "Limbourg, Belgium",
  "Limburg (a d Lahn), Germany",
  "Limburgerhof, Germany",
  "Limbus, Slovenia",
  "Limena, Italy",
  "Limerick Co Limerick, Ireland",
  "Limeshain, Germany",
  "Limhamn, Sweden",
  "Limidi, Italy",
  "Limito, Italy",
  "Limoges, France",
  "Limoux, France",
  "Limser Aliaga, Turkey",
  "Linares, Spain",
  "Linbro Park, South Africa",
  "Lincoln, United Kingdom",
  "Lincoln Park, United States",
  "Lincolnshire, United States",
  "Lincolnton, United States",
  "Lindau (Bodensee), Germany",
  "Linden, United States",
  "Linden (Hessen), Germany",
  "Lindenberg, Germany",
  "Lindesberg, Sweden",
  "Lindlar, Germany",
  "Lindon, United States",
  "Lingen (Ems), Germany",
  "Lingenfeld, Germany",
  "Lingfield, United Kingdom",
  "Linkoeping, Sweden",
  "Linlithgow, United Kingdom",
  "Linne, Netherlands",
  "Linnich, Germany",
  "Linqing, China",
  "Linselles, France",
  "Linsengericht, Germany",
  "Linthe, Germany",
  "Linton Cambs, United Kingdom",
  "Linwood, Canada",
  "Linwood, United States",
  "Linwood Renfrew, United Kingdom",
  "Linyola, Spain",
  "Linz, Austria",
  "Liomer, France",
  "Lioni, Italy",
  "Liopetri, Cyprus",
  "Lipetsk, Russia",
  "Liphook, United Kingdom",
  "Lipinki Luzyckie, Poland",
  "Lipnik nad Becvou, Czech Republic",
  "Lipomo, Italy",
  "Lippo, Italy",
  "Lippstadt, Germany",
  "Liptal, Czech Republic",
  "Liptovsky Hradok, Slovakia",
  "Liptovsky Mikulas, Slovakia",
  "Lire, France",
  "Lisbon, Portugal",
  "Lisbon, United States",
  "Lisburn Northern Ireland, United Kingdom",
  "Liscate, Italy",
  "Lisia Gora, Poland",
  "Liskeard, United Kingdom",
  "L'Isle d'Espagnac, France",
  "Lisov, Czech Republic",
  "Liss, United Kingdom",
  "Lisse, Netherlands",
  "Lisses, France",
  "Lissieu, France",
  "Lissone, Italy",
  "Listowel Kerry, Ireland",
  "Listrac Medoc, France",
  "Litchfield Hampshire, United Kingdom",
  "Lithia Springs, United States",
  "Lithonia, United States",
  "Litija, Slovenia",
  "Lititz, United States",
  "Litomerice, Czech Republic",
  "Litomysl, Czech Republic",
  "Litovel, Czech Republic",
  "Little Chute, United States",
  "Little Falls, United States",
  "Little Ferry, United States",
  "Little HultonSalford, United Kingdom",
  "Little Island Cork, Ireland",
  "Little Rock, United States",
  "Littleborough Rochdale, United Kingdom",
  "Littlehampton, United Kingdom",
  "Littleport Cambridgeshire, United Kingdom",
  "Littleton, United States",
  "Litvinov, Czech Republic",
  "Liu, Estonia",
  "Lively, Canada",
  "Livermore, United States",
  "LIVERPOOL, United Kingdom",
  "LIVERPOOL, United States",
  "Liversedge, United Kingdom",
  "Livingston, United Kingdom",
  "Livingston, United States",
  "Livonia, United States",
  "Livraga, Italy",
  "Livron sur Drome, France",
  "Lizartza, Spain",
  "Lizums, Latvia",
  "Ljubljana, Slovenia",
  "Ljubno ob Savinji, Slovenia",
  "Ljung, Sweden",
  "Ljungby Kronoberg, Sweden",
  "Ljusdal, Sweden",
  "Llandre, United Kingdom",
  "Llandrindod, United Kingdom",
  "Llandudno, United Kingdom",
  "Llandybie, United Kingdom",
  "Llandyrnog, United Kingdom",
  "Llanelli, United Kingdom",
  "Llanera, Spain",
  "Llanera De Ranes, Spain",
  "Llangadog, United Kingdom",
  "Llangefni, United Kingdom",
  "Llangollen, United Kingdom",
  "Llanidloes Powys, United Kingdom",
  "Llanon Ceredigion, United Kingdom",
  "Llanrwst Wales, United Kingdom",
  "Llantrisant Rhondda Cynon Taff, United Kingdom",
  "Llanybydder, United Kingdom",
  "Llauri, Spain",
  "Lleida, Spain",
  "Llica de Vall, Spain",
  "Llinars del Valles, Spain",
  "Lliria, Spain",
  "Llodio, Spain",
  "Llombai, Spain",
  "Llosa de Ranes, Spain",
  "Llutxent, Spain",
  "Loanhead Midlothian, United Kingdom",
  "Lobito, Angola",
  "Lobnya, Russia",
  "Lobon, Spain",
  "Lobosillo, Spain",
  "Locate Triulzi, Italy",
  "Locate Varesino, Italy",
  "Loce Pri Poljcanah, Slovenia",
  "Lochgelly, United Kingdom",
  "Lockbourne, United States",
  "Lockerbie, United Kingdom",
  "Lockport, United States",
  "LOCKS HEATH, United Kingdom",
  "Locmelar, France",
  "Lod, Israel",
  "Lodenice, Czech Republic",
  "Lodeynoye Pole, Russia",
  "Lodi, Italy",
  "Lodi, United States",
  "Lodi Vecchio, Italy",
  "Lodosa Navarra, Spain",
  "Lodrino, Italy",
  "Lodz, Poland",
  "Loebau, Germany",
  "Loebichau, Germany",
  "Loeches, Spain",
  "Loeddekoepinge, Sweden",
  "Loegstoer, Denmark",
  "Loehnberg, Germany",
  "Loehne, Germany",
  "Loenen, Gelderland, Netherlands",
  "Loeningen, Germany",
  "Loerrach, Germany",
  "Loesning, Denmark",
  "Loewenstedt, Germany",
  "Logan, United States",
  "Logan Township, United States",
  "Logatec, Slovenia",
  "Lograto, Italy",
  "Logrono, Spain",
  "Loguivy Plougras, France",
  "Lohja, Finland",
  "Lohmar, Germany",
  "Lohne (Oldenburg), Germany",
  "Lohr (a Main), Germany",
  "Loiano, Italy",
  "Loiching, Germany",
  "Loire, France",
  "Loire sur Rhone, France",
  "Loiu, Spain",
  "Lokeren, Belgium",
  "Lom, Bulgaria",
  "Lomagna, Italy",
  "Lomazzo, Italy",
  "Lombardore, Italy",
  "Lome, Togo",
  "Lominchar, Spain",
  "Lommel, Belgium",
  "Lomnice nad Popelkou, Czech Republic",
  "Lomnice u Tisnova, Czech Republic",
  "Lomza, Poland",
  "Lonate Ceppino, Italy",
  "Lonate Pozzolo, Italy",
  "Lonato, Italy",
  "Londerzeel, Belgium",
  "London, Canada",
  "London, United Kingdom",
  "London Colney Hertfordshire, United Kingdom",
  "London Gateway, United Kingdom",
  "Londonderry, United States",
  "Londonderry Northern Ireland, United Kingdom",
  "Long Beach, United States",
  "Long Bennington, United Kingdom",
  "Long Buckby, United Kingdom",
  "Long Crendon, United Kingdom",
  "Long Eaton, United Kingdom",
  "Long Island City, United States",
  "Long Marston Hertfordshire, United Kingdom",
  "Long Sutton Lincolnshire, United Kingdom",
  "Longare, Italy",
  "Longares, Spain",
  "Longarone, Italy",
  "Longford Co Longford, Ireland",
  "Longgang, China",
  "Longiano, Italy",
  "Longjumeau, France",
  "Longkou, China",
  "Longniddry, United Kingdom",
  "Longny au Perche, France",
  "Longroy, France",
  "Longtown Cumbria, United Kingdom",
  "Longue Jumelles, France",
  "Longueuil, Canada",
  "Longvic, France",
  "Longview, United States",
  "Loni, India",
  "Lonigo, Italy",
  "Lonnerstadt, Germany",
  "Lonsee, Germany",
  "Loon Plage, France",
  "Loos, France",
  "Lopburi, Thailand",
  "Lopen Ta, United Kingdom",
  "Lopik, Netherlands",
  "Lora del Rio, Spain",
  "Lorca, Spain",
  "Lordelo, Portugal",
  "Loreggia, Italy",
  "Loreo, Italy",
  "Loreto, Italy",
  "Loreto Aprutino, Italy",
  "Lorette, France",
  "Lorgies, France",
  "Loria, Italy",
  "Loriguilla, Spain",
  "Loriol sur Drome, France",
  "Lormont, France",
  "Lorqui, Spain",
  "Lorsch, Germany",
  "Los Angeles, United States",
  "Los Barrios, Spain",
  "Los Beatos, Spain",
  "LOS CAMACHOS, Spain",
  "Los Chiles, Costa Rica",
  "Los Dolores de Pacheco Murcia, Spain",
  "Los Palacios y Villafranca, Spain",
  "Los Ramos, Spain",
  "Los Santos de la Humosa, Spain",
  "Los Yebenes, Spain",
  "Losheim (Saar), Germany",
  "Lossburg, Germany",
  "Losser, Netherlands",
  "Lossiemouth, United Kingdom",
  "Lostice, Czech Republic",
  "Lostock, United Kingdom",
  "Lotte, Germany",
  "Loucna nad Desnou, Czech Republic",
  "Loudeac, France",
  "Loughborough, United Kingdom",
  "Louhans, France",
  "Louisville, United States",
  "Lounovice pod Blanikem, Czech Republic",
  "Louny, Czech Republic",
  "LOURCHES, France",
  "Lourdes, El Salvador",
  "Lourdes, France",
  "Lourensford, South Africa",
  "Loures, Portugal",
  "Lourical, Portugal",
  "Lourosa, Portugal",
  "Lousa, Portugal",
  "Lousada, Portugal",
  "Lousado, Portugal",
  "Louterwater, South Africa",
  "Louth, United Kingdom",
  "Louth Co Louth, Ireland",
  "Louverne, France",
  "Louvres, France",
  "Louvroil, France",
  "Lovech, Bulgaria",
  "Lovere, Italy",
  "Lovinobana, Slovakia",
  "Lovo, Hungary",
  "Lovosice, Czech Republic",
  "Lowell, United States",
  "Lowestoft, United Kingdom",
  "Lowicz, Poland",
  "Lowville, United States",
  "Loxley, United States",
  "Loxstedt, Germany",
  "Loz, Slovenia",
  "Lozorno, Slovakia",
  "Lozza, Italy",
  "Lozzolo, Italy",
  "LPC Midland Port, New Zealand",
  "Luanda, Angola",
  "Luban, Poland",
  "Lubartow, Poland",
  "Lubenik, Slovakia",
  "Lubersac, France",
  "Lubicz, Poland",
  "Lubin, Poland",
  "Lublin, Poland",
  "Lubliniec, Poland",
  "Lubmin, Germany",
  "Lubna, Czech Republic",
  "Lubny, Ukraine",
  "Lubumbashi, Congo, Dem. Rep. of",
  "Luby (U Chebu), Czech Republic",
  "Lucca, Italy",
  "Lucena, Spain",
  "Lucena del Cid, Spain",
  "Lucenec, Slovakia",
  "Luceni, Spain",
  "Lucens, Switzerland",
  "Luckau (NL), Germany",
  "Luckenwalde, Germany",
  "Lucrezia, Italy",
  "Luderitz, Namibia",
  "Ludesch, Austria",
  "Ludgershall Wiltshire, United Kingdom",
  "Ludhiana, India",
  "Ludkovice, Czech Republic",
  "Ludlow, United Kingdom",
  "Ludon Medoc, France",
  "Ludres, France",
  "Ludwigsburg Wuertt, Germany",
  "Ludwigsfelde, Germany",
  "Ludwigshafen, Germany",
  "Ludwigsstadt, Germany",
  "Luebbecke, Germany",
  "Luebeck, Germany",
  "Luebesse, Germany",
  "Luebz, Germany",
  "Luechow (Wendland), Germany",
  "Luedenscheid, Germany",
  "Luedinghausen, Germany",
  "Lueneburg, Germany",
  "Luenen, Germany",
  "Lugagnano, Italy",
  "Lugnano, Italy",
  "Lugo, Spain",
  "Lugo de llanera, Spain",
  "Lugo di Grezzana, Italy",
  "Lugo di Ravenna, Italy",
  "Lugo di Vicenza, Italy",
  "Lugoj, Romania",
  "Lugon ET L'ILE DU Carnay, France",
  "Lugones, Spain",
  "Luhacovice, Czech Republic",
  "Luhden, Germany",
  "Luhe Wildenau, Germany",
  "Luino, Italy",
  "Luisago, Italy",
  "Luka Nad Jihlavou, Czech Republic",
  "Lukavac, Bosnia and Herzegovina",
  "Lukavice, Czech Republic",
  "Lukow, Poland",
  "Lukowa, Poland",
  "Lukowica, Poland",
  "Lula, United States",
  "Luleburgaz, Turkey",
  "Lumezzane, Italy",
  "Lummen, Belgium",
  "Lunano, Italy",
  "Lunata, Italy",
  "Lund, Sweden",
  "Lund East Riding of Yorkshire, United Kingdom",
  "Lunderskov, Denmark",
  "Lunel Viel, France",
  "Lunery, France",
  "Luneville, France",
  "Lungavilla, Italy",
  "Lunzenau, Germany",
  "Lurago d'Erba, Italy",
  "Lurago Marinone, Italy",
  "lurano, Italy",
  "Lurate Caccivio, Italy",
  "Lurcy Levis, France",
  "Lure, France",
  "Lurgan Co Armagh Northern Ireland, United Kingdom",
  "Lurnea, Australia",
  "Lusaka, Zambia",
  "Luserna San Giovanni, Italy",
  "Lustenau, Austria",
  "Lustenice, Czech Republic",
  "Lutherstadt Eisleben, Germany",
  "Lutherstadt Wittenberg, Germany",
  "Lutin, Czech Republic",
  "Luton Bedfordshire, United Kingdom",
  "Lutsk, Ukraine",
  "Lutterworth, United Kingdom",
  "Lutxana, Spain",
  "Luxembourg, Luxemburg",
  "Luynes, France",
  "Luzec Nad Vltavou, Czech Republic",
  "Luzenac, France",
  "Luzianky, Slovakia",
  "Luzzara, Italy",
  "Lviv-Lvov, Ukraine",
  "Lyantonde, Uganda",
  "Lydd, United Kingdom",
  "Lydney, United Kingdom",
  "Lymington, United Kingdom",
  "Lymm, United Kingdom",
  "Lympne, United Kingdom",
  "Lynchburg, United States",
  "Lyndhurst, United States",
  "Lynge, Denmark",
  "Lynn, United States",
  "Lynnfield, United States",
  "Lyon, France",
  "Lyons, United States",
  "Lysice, Czech Republic",
  "Lysomice, Poland",
  "Lyss, Switzerland",
  "Lystrup, Denmark",
  "Lytham St Annes, United Kingdom",
  "Lyttelton, New Zealand",
  "Lyttleton, South Africa",
  "Laa an der Thaya, Austria",
  "Laakdal, Belgium",
  "Laarne, Belgium",
  "Laasby, Denmark",
  "Laatzen, Germany",
  "Mablethorpe, United Kingdom",
  "Mably, France",
  "Macael, Spain",
  "Macanet de Selva, Spain",
  "Macau, France",
  "Maccastorna, Italy",
  "Macclesfield, United Kingdom",
  "Maceira, Portugal",
  "Macerata, Italy",
  "Machado, Brazil",
  "Machala - El Oro, Ecuador",
  "Mache, France",
  "Machecoul, France",
  "Machelen, Belgium",
  "Macherio, Italy",
  "Machesney Park, United States",
  "Machynlleth, United Kingdom",
  "Maclodio, Italy",
  "Macmerry East Lothian, United Kingdom",
  "Macon, France",
  "Macon, United States",
  "Macotera, Spain",
  "Madang, Papua New Guinea",
  "Maddaloni, Italy",
  "Madeley Staffordshire, United Kingdom",
  "Madignano, Italy",
  "Madill, United States",
  "Madison, United States",
  "Madison Heights, United States",
  "Madisonville, United States",
  "Madone, Italy",
  "Madrid, Spain",
  "Madridejos, Spain",
  "Madrigalejo, Spain",
  "Madrigueras, Spain",
  "Madurai, India",
  "Maerne, Italy",
  "Maerne di Martellago, Italy",
  "Maersta, Sweden",
  "Maerstetten, Switzerland",
  "Maesteg, United Kingdom",
  "Mafraq, Jordan",
  "Magallon, Spain",
  "Magdeburg, Germany",
  "Magenta, France",
  "Magenta, Italy",
  "Maghera Northern Ireland BT, United Kingdom",
  "Magheralin Northern Ireland, United Kingdom",
  "Magherfelt Northern Ireland, United Kingdom",
  "Magliano di Tenna, Italy",
  "Maglod, Hungary",
  "Magnago, Italy",
  "Magnano in Riviera, Italy",
  "Magnice k Wroclawia, Poland",
  "Magor, United Kingdom",
  "Magoula, Greece",
  "Magre, Italy",
  "Magreta di Formigine, Italy",
  "MAHESANA, India",
  "Mahmutbey-Istanbul, Turkey",
  "Maia, Portugal",
  "Maidenhead, United Kingdom",
  "MAIDSTONE, United Kingdom",
  "Mainaschaff, Germany",
  "Mainburg, Germany",
  "Mainhausen, Germany",
  "Mainleus, Germany",
  "Maintal, Germany",
  "Mainz, Germany",
  "Mainz-Kastel, Germany",
  "Mainz-Kostheim, Germany",
  "Mairena de Aljarafe, Spain",
  "Mairena del Alcor, Spain",
  "Maisach (Oberbayern), Germany",
  "Maishofen, Austria",
  "Maisons Alfort, France",
  "Maitland, South Africa",
  "Majadahonda, Spain",
  "Majano, Italy",
  "Makassar, Indonesia",
  "Makov, Slovakia",
  "Malabar, United States",
  "Malabo, Equatorial Guinea",
  "Malacky, Slovakia",
  "Malaga, Spain",
  "Malagon, Spain",
  "Malanpur, India",
  "Malataverne, France",
  "Malatya, Turkey",
  "Malaya Vishera, Russia",
  "Malbork, Poland",
  "Malchow, Germany",
  "Malczyce, Poland",
  "Maldegem, Belgium",
  "Maldon, United Kingdom",
  "Male, Maldives",
  "Malelane, South Africa",
  "Malesherbes, France",
  "Malgesso, Italy",
  "Maliano, Spain",
  "Malin, Ukraine",
  "Malkara, Turkey",
  "Malkerns, Swaziland",
  "Malkinia, Poland",
  "Mallabia, Spain",
  "Malle, Belgium",
  "Mallen, Spain",
  "Mallersdorf-Pfaffenberg, Germany",
  "Mallow Cork, Ireland",
  "Mallusk Northern Ireland, United Kingdom",
  "Malmesbury, South Africa",
  "Malmesbury, United Kingdom",
  "Malmoe, Sweden",
  "Malnate, Italy",
  "Malo, Italy",
  "Malonne, Belgium",
  "Malonno, Italy",
  "Malpaga di calvisano, Italy",
  "Malpartida de Plasencia, Spain",
  "Malpas Cheshire, United Kingdom",
  "Malpica del Tajo, Spain",
  "Malpils, Latvia",
  "Malsch (Kr Karlsruhe), Germany",
  "Malschwitz, Germany",
  "Malsfeld, Germany",
  "Maltepe, Turkey",
  "Malterdingen, Germany",
  "Malton, United Kingdom",
  "MALVERN, United Kingdom",
  "Malvern Wells Worcestershire, United Kingdom",
  "Malville, France",
  "Maly Krtis, Slovakia",
  "Mammendorf, Germany",
  "Mamming, Germany",
  "Managua, Nicaragua",
  "Manassas, United States",
  "Manaus, Brazil",
  "Mancasale, Italy",
  "Mancelona, United States",
  "Mancha Real, Spain",
  "Manchester, United Kingdom",
  "Manchester, United States",
  "Manciet, France",
  "Mandello del Lario, Italy",
  "Mandeure, France",
  "Mandideep, India",
  "Mandra Attikis, Greece",
  "Mandrio, Italy",
  "Manduel, France",
  "Manduria, Italy",
  "Manea, United Kingdom",
  "Manerba, Italy",
  "Manerbio, Italy",
  "Manfredonia, Italy",
  "Mangalore, India",
  "MANGONE, Italy",
  "Mangualde, Portugal",
  "Manhay, Belgium",
  "Maniago, Italy",
  "Manila, Philippines",
  "MANILA SOUTH, Philippines",
  "Manisa, Turkey",
  "Manises, Spain",
  "Manistee, United States",
  "Mankato, United States",
  "Mankovice, Czech Republic",
  "Manlleu, Spain",
  "Mannheim, Germany",
  "Manning, United States",
  "Manningtree, United Kingdom",
  "Manocalzati, Italy",
  "Manoppello, Italy",
  "Manosque, France",
  "Manouba, Tunisia",
  "Manresa, Spain",
  "Mansfeld, Germany",
  "Mansfield, United States",
  "Mansfield Notts, United Kingdom",
  "Mansoura, Egypt",
  "Manston Kent, United Kingdom",
  "Mansue, Italy",
  "Manta, Italy",
  "Manta - Manabi, Ecuador",
  "Mantel, Germany",
  "Manteno, United States",
  "MANTOUDI, Greece",
  "Mantova, Italy",
  "Mantua, United States",
  "Mantyluoto, Finland",
  "Manuel, Spain",
  "Manvel, United States",
  "Manzanares, Spain",
  "Manzanillo, Mexico",
  "Manzanillo, Panama",
  "Manzano, Italy",
  "Manziat, France",
  "Mapello, Italy",
  "Maple Heights, United States",
  "Mapleton, United States",
  "Mappano, Italy",
  "Maputo, Mozambique",
  "Mar del Plata, Argentina",
  "Maracaibo, Venezuela",
  "Maracineni, Romania",
  "Maraimalai Nagar, India",
  "Maranello, Italy",
  "Marange-silvange, France",
  "Marano di Valpolicella, Italy",
  "Marano sul Panaro, Italy",
  "Marano Ticino, Italy",
  "Marano Veneziano, Italy",
  "Marano Vicentino, Italy",
  "Marans, France",
  "Marapoama, Brazil",
  "Marbella, Spain",
  "Marble Falls, United States",
  "Marble Hall, South Africa",
  "Marburg, Germany",
  "Marcallo con Casone, Italy",
  "Marcaria, Italy",
  "March, United Kingdom",
  "March Air Reserve Base, United States",
  "Marchamalo, Spain",
  "Marchena, Spain",
  "Marcheno, Italy",
  "Marchington, United Kingdom",
  "Marchtrenk, Austria",
  "Marciac, France",
  "Marcianise, Italy",
  "Marciano della Chiana, Italy",
  "Marcigny, France",
  "Marcilla, Spain",
  "Marckolsheim, France",
  "Marco de Canaveses, Portugal",
  "Marcon, Italy",
  "Marconne, France",
  "Marcoussis, France",
  "MARCOUX, France",
  "Marcq en Baroeul, France",
  "Marden Kent, United Kingdom",
  "Mardin, Turkey",
  "Marengo, United States",
  "Marennes, France",
  "Mareno di Piave, Italy",
  "Marfleet, United Kingdom",
  "margarita, Italy",
  "Margate, United Kingdom",
  "Marghera, Italy",
  "Maria de Huerva, Spain",
  "Maria Enzersdorf, Austria",
  "Mariager, Denmark",
  "Marialba De La Ribera, Spain",
  "Marialva, Brazil",
  "Mariana Mantovana, Italy",
  "Marianhill, South Africa",
  "Mariano Comense, Italy",
  "Mariano del Friuli, Italy",
  "Marianske Lazne, Czech Republic",
  "Maribo, Denmark",
  "Maribor, Slovenia",
  "Maricopa, United States",
  "Mariel, Cuba",
  "Marienfeld (Harsewinkel), Germany",
  "Marienheide, Germany",
  "Marienmuenster, Germany",
  "Mariestad, Sweden",
  "Marietta, United States",
  "Marigliano, Italy",
  "Marigny, France",
  "Marijampole, Lithuania",
  "Marin, Spain",
  "Marin, Switzerland",
  "Marina, United States",
  "Marina di Carrara, Italy",
  "MARINA DI RAVENNA, Italy",
  "Marinette, United States",
  "Marinha Grande, Portugal",
  "Marion, United States",
  "Markaryd, Sweden",
  "Markdorf (Baden), Germany",
  "Marke, Belgium",
  "Markersbach, Germany",
  "Markesan, United States",
  "Market Deeping, United Kingdom",
  "Market Drayton, United Kingdom",
  "Market Harborough, United Kingdom",
  "Market Rasen, United Kingdom",
  "Market Weighton, United Kingdom",
  "Markgroeningen, Germany",
  "Marki, Poland",
  "Markina Xemein, Spain",
  "Markinch, United Kingdom",
  "Marklkofen, Germany",
  "Markopoulo, Greece",
  "Markranstaedt, Germany",
  "Markt Erlbach, Germany",
  "Marktbreit, Germany",
  "Marktheidenfeld, Germany",
  "Marktoberdorf, Germany",
  "Marktredwitz, Germany",
  "Marktrodach, Germany",
  "Marktsteft, Germany",
  "Markvartice, Czech Republic",
  "Marl (Kr Recklinghausen), Germany",
  "Marlboro, South Africa",
  "Marlborough, United Kingdom",
  "Marlborough, United States",
  "Marle, France",
  "Marlengo, Italy",
  "Marlenheim, France",
  "Marlia, Italy",
  "Marlow, United Kingdom",
  "Marly la Ville, France",
  "Marly Nord, France",
  "Marmagao, India",
  "Marmande, France",
  "Marmirolo, Italy",
  "Marnate, Italy",
  "Marneuli, Georgia",
  "Maron di Brugnera, Italy",
  "Marone, Italy",
  "Marostica, Italy",
  "Marquain lez Tournai, Belgium",
  "Marrakech, Morocco",
  "Marrero, United States",
  "Marsainvilliers, France",
  "Marsala, Italy",
  "Marsango, Italy",
  "Marsaxlokk, Malta",
  "Marsberg, Germany",
  "Marsciano, Italy",
  "MARSEILLE, France",
  "Marshfield, United States",
  "Marston Magna, United Kingdom",
  "Marston Moretaine MK, United Kingdom",
  "Marston Wiltshire, United Kingdom",
  "Martano, Italy",
  "Martellago, Italy",
  "Martignacco, Italy",
  "Martignat, France",
  "Martigne Ferchaud, France",
  "Martin, Slovakia",
  "Martin, United States",
  "Martinengo, Italy",
  "Martinez de la Torre, Mexico",
  "Martinsburg, United States",
  "Martinsicuro, Italy",
  "Martinsville, United States",
  "Martlesham, United Kingdom",
  "Martock, United Kingdom",
  "Martorano, Italy",
  "Martorell, Spain",
  "Martorelles, Spain",
  "Martos, Spain",
  "Marupe, Latvia",
  "Marxzell, Germany",
  "Maryport Cumbria, United Kingdom",
  "Marysville, United States",
  "Marzana, Italy",
  "Marzano, Italy",
  "Masalaves, Spain",
  "Masatepe, Nicaragua",
  "Masaya, Nicaragua",
  "Maser, Italy",
  "Masera di Padova, Italy",
  "Maserada sul Piave, Italy",
  "Maseru, Lesotho",
  "Masham, United Kingdom",
  "Masi San Giacomo, Italy",
  "Masi Torello, Italy",
  "Masku, Finland",
  "Maslak, Turkey",
  "Maslianico, Italy",
  "Masllorenc, Spain",
  "Mason, United States",
  "Mason Vicentino, Italy",
  "Masquefa, Spain",
  "Massa Carrara, Italy",
  "Massa e Cozzile, Italy",
  "Massa Lombarda, Italy",
  "Massafra, Italy",
  "Massalengo, Italy",
  "Massalfassar, Spain",
  "Massamagrell, Spain",
  "Massanassa, Spain",
  "Massanzago, Italy",
  "Massarosa, Italy",
  "Massen-Niederlausitz, Germany",
  "Massillon, United States",
  "Massilly, France",
  "Massing, Germany",
  "Matadi, Congo, Dem. Rep. of",
  "Matagalpa, Nicaragua",
  "Matam, Guinea",
  "Matamoros, Mexico",
  "Mataro, Spain",
  "Matera, Italy",
  "Matha, France",
  "Mathi, Italy",
  "Matina, Costa Rica",
  "Matinella, Italy",
  "Matlock, United Kingdom",
  "Matosinhos, Portugal",
  "Matoto, Guinea",
  "Matsapha Ami Swaziland, Swaziland",
  "Mattighofen, Austria",
  "Mattoon, United States",
  "Maubeuge, France",
  "Mauchline East Ayrshire, United Kingdom",
  "Mauerkirchen, Austria",
  "Mauges Sur Loire, France",
  "Maulbronn, Germany",
  "Maulburg, Germany",
  "Maulden, United Kingdom",
  "Maumee, United States",
  "Maurecourt, France",
  "Maureilhan, France",
  "Mauvezin Gers, France",
  "Maval, India",
  "Mavrovouni, Greece",
  "Mawei, China",
  "Maxhuette-Haidhof, Germany",
  "Maybole, United Kingdom",
  "Maydon Wharf, South Africa",
  "Mayen, Germany",
  "Mayenne, France",
  "Mayfair, United Kingdom",
  "MaylandEssex, United Kingdom",
  "Maywood, United States",
  "Mazamet, France",
  "Mazamitla, Mexico",
  "Mazara del Vallo, Italy",
  "Mazarron, Spain",
  "Mazatlan, Mexico",
  "Mazeikiai, Lithuania",
  "Mazeres, France",
  "Mazieres en Mauges, France",
  "Mazingarbe, France",
  "Mazzano, Italy",
  "Mazzantica, Italy",
  "Mazzo di Rho, Italy",
  "Mazzo Valtellina, Italy",
  "Mbalmayo, Cameroon",
  "Mbao Zone One, Senegal",
  "Mbeya, Tanzania",
  "Mc Adenville, United States",
  "Mc Bee, United States",
  "Mc Intyre, United States",
  "Mcallen, United States",
  "Mcdonough, United States",
  "McGuire AFB, United States",
  "McKinney, United States",
  "Mealhada, Portugal",
  "Meano Pontevedra, Spain",
  "Measham, United Kingdom",
  "Meaux, France",
  "Mechanicsburg, United States",
  "Mechanicsville, United States",
  "Mechelen, Belgium",
  "Mechernich, Germany",
  "Mechnov, Czech Republic",
  "Meckenbeuren, Germany",
  "Meckenheim (Rheinl), Germany",
  "Meckesheim, Germany",
  "Meco, Spain",
  "Meda MI, Italy",
  "Medchal, India",
  "Medea, Italy",
  "Medebach, Germany",
  "Medemblik, Netherlands",
  "Medesano, Italy",
  "Medicina, Italy",
  "Mediglia, Italy",
  "Medina, Saudi Arabia",
  "Medina, United States",
  "Medina de las Torres, Spain",
  "Medina de Rioseco, Spain",
  "Medina del Campo, Spain",
  "Mediouna, Morocco",
  "Medley, United States",
  "Medlov, Czech Republic",
  "Medolago, Italy",
  "Medolla, Italy",
  "Meduna di Livenza, Italy",
  "Meduno, Italy",
  "Medvode, Slovenia",
  "Meeder, Germany",
  "Meer, Belgium",
  "Meerane, Germany",
  "Meerbusch, Germany",
  "Meerhout, Belgium",
  "Meeuwen, Belgium",
  "Meeuwen, Netherlands",
  "Megara, Greece",
  "Megrine, Tunisia",
  "Mehren, Germany",
  "Mehring, Germany",
  "Mehrstetten, Germany",
  "Meinerzhagen, Germany",
  "Meineweh, Germany",
  "Meiningen, Germany",
  "Meira LUGO, Spain",
  "Meirinhas, Portugal",
  "Meis, Spain",
  "Meissen (Sachsen), Germany",
  "Meitingen, Germany",
  "Mejillones, Chile",
  "Mejorada del Campo, Spain",
  "Meknes, Morocco",
  "Mel, Italy",
  "Melano, Italy",
  "Melara, Italy",
  "Melbourn, United Kingdom",
  "Melbourne, Australia",
  "Melbourne, United States",
  "Melbourne Derbyshire, United Kingdom",
  "MelbourneNorth Yorkshire, United Kingdom",
  "Meldola, Italy",
  "Meledo, Italy",
  "Melegnano, Italy",
  "Melhus, Norway",
  "Melicucco, Italy",
  "Melida, Spain",
  "Melilla, Spain",
  "Melito di Napoli, Italy",
  "Melksham, United Kingdom",
  "Mellac, France",
  "Mellaredo, Italy",
  "Melle, Belgium",
  "Melle, France",
  "Melle, Germany",
  "Melloussa, Morocco",
  "Mellrichstadt, Germany",
  "Melmerby North Yorkshire, United Kingdom",
  "Melnik, Czech Republic",
  "Melrand, France",
  "Melrose Park, United States",
  "Melsele, Belgium",
  "Melsungen, Germany",
  "Meltham, United Kingdom",
  "Melton Constable Norfolk, United Kingdom",
  "Melton Mowbray, United Kingdom",
  "Melton Suffolk, United Kingdom",
  "Melville, United States",
  "Melzo, Italy",
  "Mem Martins, Portugal",
  "Memmingen, Germany",
  "Memphis, United States",
  "Menai Bridge Anglesey, United Kingdom",
  "Menaldum, Netherlands",
  "Menasha, United States",
  "Mendaro, Spain",
  "Mendavia, Spain",
  "Menden (Sauerland), Germany",
  "Menderes, Turkey",
  "Mendig, Germany",
  "Mendlesham, United Kingdom",
  "Mendoza, Argentina",
  "Menemen, Turkey",
  "Menen, Belgium",
  "Menfi, Italy",
  "Mengen (Wuertt), Germany",
  "Mengerskirchen, Germany",
  "Menges, Slovenia",
  "Mengibar, Spain",
  "Menidi, Greece",
  "Menlo Park, South Africa",
  "Menomonee Falls, United States",
  "Menstrie, United Kingdom",
  "Mentor, United States",
  "Mentrida, Spain",
  "Menzel Temime, Tunisia",
  "Menznau, Switzerland",
  "Meolo, Italy",
  "Meppel, Netherlands",
  "Mequon, United States",
  "Mer, France",
  "Merak, Indonesia",
  "Merano, Italy",
  "Merate, Italy",
  "Mercato San Severino, Italy",
  "Merchantville, United States",
  "Mercues, France",
  "Mere Wiltshire, United Kingdom",
  "Mere Yvelines, France",
  "Merelbeke, Belgium",
  "Merey Sous Montrond, France",
  "Meri, Italy",
  "Merida, Mexico",
  "Merida, Spain",
  "Meridianville, United States",
  "Merignac, France",
  "Merin, Czech Republic",
  "Merindad de Valdivielso, Spain",
  "Mering, Germany",
  "Merkers, Germany",
  "Merksem, Belgium",
  "Merksplas, Belgium",
  "Merkwiller Pechelbronn, France",
  "Merlara, Italy",
  "Merlimau, Malaysia",
  "Merlino, Italy",
  "Merone, Italy",
  "Merpins, France",
  "Mers les Bains, France",
  "Merseburg, Germany",
  "Mersin, Turkey",
  "Mersin Freezone, Turkey",
  "Merstham, United Kingdom",
  "Merter, Turkey",
  "Merthyr Tydfil, United Kingdom",
  "Mertingen, Germany",
  "Mertzwiller, France",
  "Meru, France",
  "Merville, France",
  "Merxheim, France",
  "Merzalben, Germany",
  "Merzig, Germany",
  "Mesa, United States",
  "Mesagne, Italy",
  "Meschede, Germany",
  "Mese, Italy",
  "Mesero, Italy",
  "Meshkovo-Pogorelovo, Ukraine",
  "Mesia, Spain",
  "Mesquite, United States",
  "Messancy, Belgium",
  "Messel, Germany",
  "Messia sur Sorne, France",
  "Messimy, France",
  "Messina, Italy",
  "Messologi, Greece",
  "Messstetten, Germany",
  "Mestinje, Slovenia",
  "Mesto Albrechtice, Czech Republic",
  "Mestre, Italy",
  "Mestrino, Italy",
  "Metamora, United States",
  "Metamorfosi, Greece",
  "Metlika, Slovenia",
  "Mettlach, Germany",
  "Mettmann, Germany",
  "Mettmenstetten, Switzerland",
  "Mettray, France",
  "Metuchen, United States",
  "Metz, France",
  "Metzingen (Wuertt), Germany",
  "Meudt, Germany",
  "Meulebeke, Belgium",
  "Meung sur Loire, France",
  "Meursault, France",
  "Meuzac, France",
  "Mexborough, United Kingdom",
  "Mexicali, Mexico",
  "Mexico, United States",
  "Mexico City, Mexico",
  "Meyenburg (Prignitz), Germany",
  "Meyriat, France",
  "Meythet, France",
  "Meyzieu, France",
  "Mezdra, Bulgaria",
  "Meze, France",
  "Mezica, Slovenia",
  "Mezimesti u Broumova, Czech Republic",
  "Mezofalva, Hungary",
  "Mezzago, Italy",
  "Mezzanego, Italy",
  "Mezzani, Italy",
  "Mezzanino, Italy",
  "Mezzano, Italy",
  "Mezzano Inferiore, Italy",
  "Mezzocorona, Italy",
  "Mezzolara, Italy",
  "Mezzolombardo, Italy",
  "Miajadas, Spain",
  "MIAMI, United States",
  "Miami Gardens, United States",
  "Miami Lakes, United States",
  "Miami Platja, Spain",
  "Miamisburg, United States",
  "Miane, Italy",
  "Miasteczko Slaskie, Poland",
  "Michalovce, Slovakia",
  "Michelau (Oberfr), Germany",
  "Michelbach an der Bilz, Germany",
  "Micheldever, United Kingdom",
  "Michelhausen, Austria",
  "Michelstadt, Germany",
  "Michigan Center, United States",
  "Michigan City, United States",
  "MICKLEOVER, Derby, United Kingdom",
  "Middelburg, Netherlands",
  "Middelfart, Denmark",
  "Middelharnis, Netherlands",
  "Middenmeer, Netherlands",
  "Middlesbrough, United Kingdom",
  "Middleton, Canada",
  "Middleton, United States",
  "Middleton Man, United Kingdom",
  "Middletown, United States",
  "Middlewich, United Kingdom",
  "Midelt, Morocco",
  "Midhurst, United Kingdom",
  "Midland, United States",
  "Midleton Cork, Ireland",
  "Midrand, South Africa",
  "Midsomer Norton, United Kingdom",
  "Midway, United States",
  "Miedes de Aragon, Spain",
  "Miedzyrzecz, Poland",
  "Miehlen, Germany",
  "Miejska Gorka, Poland",
  "Miekinia, Poland",
  "Mielec, Poland",
  "Mieres Asturias, Spain",
  "Mieroszow, Poland",
  "Miesbach, Germany",
  "Mieste, Germany",
  "Mietingen, Germany",
  "MIGDAL HA-EMEQ, Israel",
  "Migennes, France",
  "Miglianico, Italy",
  "Migliarina, Italy",
  "Mignagola, Italy",
  "Mignanego, Italy",
  "Mihla, Germany",
  "Mijares, Spain",
  "Mijas, Spain",
  "Mijas costa, Spain",
  "Mijdrecht, Netherlands",
  "Mikkeli, Finland",
  "Mikolow, Poland",
  "Mikulov, Czech Republic",
  "Milagro, Spain",
  "Milagro - Guayas, Ecuador",
  "Milan, Italy",
  "Milan, United States",
  "Mildenau, Germany",
  "Mildenhall Suffolk, United Kingdom",
  "Milevsko, Czech Republic",
  "Milford, United States",
  "Milford Haven, United Kingdom",
  "Milharado, Portugal",
  "Milicz, Poland",
  "MILKOWICE, Poland",
  "Millau, France",
  "Millerovo, Russia",
  "Millom, United Kingdom",
  "Millsboro, United States",
  "Millville, United States",
  "Milmersdorf, Germany",
  "Milmort, Belgium",
  "Milnerton, South Africa",
  "Milnrow, United Kingdom",
  "Milnthorpe, United Kingdom",
  "Milsbeek, Netherlands",
  "Milton, Canada",
  "Milton, United States",
  "Milton Keynes, United Kingdom",
  "Milwaukee, United States",
  "Mimizan, France",
  "Mimon, Czech Republic",
  "Mina Abdullah, Kuwait",
  "Mina Saqr, United Arab Emirates",
  "Minano Mayor, Spain",
  "Minas de Riotinto, Spain",
  "Minaya, Spain",
  "Mindelheim, Germany",
  "Mindelo, Cape Verde Island",
  "Minden Westf, Germany",
  "Minehead, United Kingdom",
  "Mineola, United States",
  "Minerbe, Italy",
  "Minerbio, Italy",
  "Miniac-Morvan, France",
  "MINNEAPOLIS, United States",
  "Minooka, United States",
  "Minot, United States",
  "Minsk, Belarus",
  "Minsk Mazowiecki, Poland",
  "Mintraching, Germany",
  "Minworth, United Kingdom",
  "Mionica, Serbia",
  "Mions, France",
  "MIRA, Italy",
  "Mira Loma, United States",
  "Mirabello Monferrato, Italy",
  "Miradolo Terme, Italy",
  "Miralcamp, Spain",
  "Miramar, Spain",
  "Miramar, United States",
  "Mirambeau, France",
  "Miranda, Spain",
  "Miranda de Ebro, Spain",
  "Mirandola, Italy",
  "Mirano, Italy",
  "Mirebeau, France",
  "Mirebeau-sur-Beze, France",
  "Mirfield, United Kingdom",
  "Miribel, France",
  "Mirkow, Poland",
  "Mirna, Slovenia",
  "Mirna Pec, Slovenia",
  "Miroslav, Czech Republic",
  "Mirto, Italy",
  "Misano Adriatico, Italy",
  "Misano di Gera d'Adda, Italy",
  "Misawa, Japan",
  "Mishawaka, United States",
  "Misinto, Italy",
  "Miskolc, Hungary",
  "Mislinja, Slovenia",
  "Missaglia, Italy",
  "Mission, United States",
  "Mississauga, Canada",
  "Misterbianco, Italy",
  "Misuratah, Libya",
  "Mitcham, United Kingdom",
  "Mitcheldean, United Kingdom",
  "Mitchell, Canada",
  "Mitry Mory, France",
  "Mitterteich, Germany",
  "Mittweida, Germany",
  "Mitwitz, Germany",
  "Mixco, Guatemala",
  "Mizushima, Japan",
  "Mjoelby, Sweden",
  "Mjoendalen, Norway",
  "Mkushi, Zambia",
  "Mlada Boleslav, Czech Republic",
  "Mlada Vozice, Czech Republic",
  "Mlade Buky, Czech Republic",
  "Mladenovac, Serbia",
  "Mlawa, Poland",
  "Mlochow, Poland",
  "Mnichovo Hradiste, Czech Republic",
  "Mnisek Pod Brdy, Czech Republic",
  "Mobberley, United Kingdom",
  "Mobeni, South Africa",
  "Mobile, United States",
  "Mochov, Czech Republic",
  "Mockrehna, Germany",
  "Mocksville, United States",
  "Moconesi, Italy",
  "Modasa, India",
  "Modena, Italy",
  "Modesto, United States",
  "Modica, Italy",
  "Modigliana, Italy",
  "Modinagar, India",
  "Modjadjiskloof, South Africa",
  "Modjo, Ethiopia",
  "Modlany, Czech Republic",
  "Modletice, Czech Republic",
  "Modra, Slovakia",
  "Modrice, Czech Republic",
  "Modugno, Italy",
  "Moeckern (b Magdeburg), Germany",
  "Moeckmuehl, Germany",
  "Moedling, Austria",
  "Moegglingen, Germany",
  "Moehlin, Switzerland",
  "Moehnesee / Stockum, Germany",
  "Moelan sur Mer, France",
  "Moeldrup, Denmark",
  "Moelln (Kr Hzgt Lauenb), Germany",
  "Moemlingen, Germany",
  "Moenchengladbach, Germany",
  "Moerarp, Sweden",
  "Moerdijk, Netherlands",
  "Moerfelden-Walldorf, Germany",
  "Moerkapelle, Netherlands",
  "Moerlenbach, Germany",
  "Moers, Germany",
  "Moersfeld, Germany",
  "Moessingen, Germany",
  "Moettingen, Germany",
  "Moetzingen, Germany",
  "Moga, India",
  "Mogadishu, Somalia",
  "Mogadore, United States",
  "Mogeely Co Cork, Ireland",
  "Mogendorf, Germany",
  "Mogente, Spain",
  "Moggio Udinese, Italy",
  "Mogielnica, Poland",
  "Mogilany, Poland",
  "Mogilev, Belarus",
  "Moglia, Italy",
  "Mogliano Veneto, Italy",
  "Mohammedia, Morocco",
  "Mohelnice, Czech Republic",
  "Moimacco, Italy",
  "Moimenta da Beira, Portugal",
  "Moirans, France",
  "Moirans en Montagne, France",
  "Moissy Cramayel, France",
  "Moji, Japan",
  "Mokrzec, Poland",
  "MOLAI, Greece",
  "Mold, United Kingdom",
  "Moldava nad Bodvou, Slovakia",
  "Molenaarsgraaf, Netherlands",
  "Molfetta, Italy",
  "Molina, Chile",
  "Molina de Segura, Spain",
  "Molina di Fiemme, Italy",
  "Molinella, Italy",
  "Molinetto di Mazzano, Italy",
  "Molinfaing, Belgium",
  "Molino dei Torti, Italy",
  "Molins de Rey, Spain",
  "Mollaro, Italy",
  "Mollem, Belgium",
  "Mollerussa, Spain",
  "Mollet del Valles, Spain",
  "Molodechno, Belarus",
  "Molsheim, France",
  "Molteno, Italy",
  "Molvena, Italy",
  "MOLVIZAR, Spain",
  "Mombaroccio, Italy",
  "Mombasa, Kenya",
  "Mombercelli, Italy",
  "Momchilgrad, Bulgaria",
  "Momence, United States",
  "Momignies, Belgium",
  "Mommenheim, France",
  "Momo, Italy",
  "Monaghan Co Monaghan, Ireland",
  "Monasterolo Di SaviglianoCN, Italy",
  "Monastier, Italy",
  "Monastir, Tunisia",
  "Moncada, Spain",
  "Moncalieri, Italy",
  "Moncalvo, Italy",
  "Moncao, Portugal",
  "Monchiero, Italy",
  "Monchy le Preux, France",
  "Moncofa, Spain",
  "Moncton, Canada",
  "Monda, Spain",
  "Mondaino, Italy",
  "Mondariz, Spain",
  "Mondavio, Italy",
  "Mondeville, France",
  "Mondolfo, Italy",
  "Mondoubleau, France",
  "Mondovi, Italy",
  "Mondragon Arrasate, Spain",
  "Moneteau, France",
  "Monfalcone, Italy",
  "Monforte del Cid, Spain",
  "Mongla, Bangladesh",
  "Mongrassano, Italy",
  "Monheim (Kr Mettmann), Germany",
  "Monheim (Schwab), Germany",
  "Monistrol Montserrat, Spain",
  "Monistrol sur Loire, France",
  "Monmouth, United Kingdom",
  "Monmouth Junction, United States",
  "Monongahela, United States",
  "Monopoli, Italy",
  "Monovar, Spain",
  "Monreal del Campo, Spain",
  "Monroe, United States",
  "Monroe Township, United States",
  "Monrovia, Liberia",
  "Monrupino, Italy",
  "Mons, Belgium",
  "Mons en Baroeul, France",
  "Monsagrati, Italy",
  "Monsano, Italy",
  "Monselice, Italy",
  "Monserrat, Spain",
  "Monsheim (Rheinhessen), Germany",
  "Monsummano, Italy",
  "Monswiller, France",
  "Mont, France",
  "Monta, Italy",
  "Montabaur, Germany",
  "Montagnana, Italy",
  "Montagny les Beaune, France",
  "Montague Gardens, South Africa",
  "Montaigu Vendee, France",
  "MONTAIONE, Italy",
  "Montalban de Cordoba, Spain",
  "Montalbano Elicona, Italy",
  "Montalbo, Spain",
  "Montalcino, Italy",
  "Montale, Italy",
  "Montale Rangone, Italy",
  "Montalto Uffugo, Italy",
  "Montana, Bulgaria",
  "Montana, South Africa",
  "Montanana, Spain",
  "MONTANASO LOMBARDO, Italy",
  "Montano Lucino, Italy",
  "Montauban de Bretagne, France",
  "Montauban Tarn et Garonne, France",
  "Montaverner, Spain",
  "Montbard, France",
  "Montbartier, France",
  "Montblanc, Spain",
  "Montbrison Loire, France",
  "Montcada I Reixac, Spain",
  "Montceau les Mines, France",
  "Montchanin, France",
  "Montdoumerc, France",
  "Monte Cerignone, Italy",
  "Monte Cremasco, Italy",
  "Monte di Malo, Italy",
  "Monte Marenzo, Italy",
  "Monte Porzio Catone, Italy",
  "Monte Roberto, Italy",
  "Monte San Pietro, Italy",
  "Monte San Savino, Italy",
  "Monte Sant Angelo, Italy",
  "Monte Santa Maria Tiberina, Italy",
  "Monte Urano, Italy",
  "Monteagudo Murcia, Spain",
  "Montebello della Battaglia, Italy",
  "Montebello Vicentino, Italy",
  "Montebelluna, Italy",
  "Montebourg, France",
  "Montecalvo In Foglia, Italy",
  "Montecarlo, Italy",
  "Montecarotto, Italy",
  "Montecassiano, Italy",
  "Montecavolo, Italy",
  "Montecchia Di Crosara, Italy",
  "Montecchio, Italy",
  "Montecchio Emilia, Italy",
  "Montecchio Maggiore, Italy",
  "Montecchio Precalcino, Italy",
  "Montechiarugolo, Italy",
  "Montecompatri, Italy",
  "Montecosaro, Italy",
  "Montefano, Italy",
  "Montefiore Conca, Italy",
  "Montefiore dell'Aso, Italy",
  "Montefiorino, Italy",
  "Monteforte d'Alpone, Italy",
  "Montefredane, Italy",
  "Montegalda, Italy",
  "Montegaldella, Italy",
  "Montegranaro, Italy",
  "Montegrosso d Asti, Italy",
  "Montegrotto Terme, Italy",
  "Montehermoso, Spain",
  "Montelabbate, Italy",
  "Monteleger, France",
  "Montelimar, France",
  "Montello, Italy",
  "Montelupo Fiorent, Italy",
  "Montelupone, Italy",
  "Montemaggiore al Metauro, Italy",
  "Montemayor, Spain",
  "Montemiletto, Italy",
  "Montemorelos, Mexico",
  "Montemurlo, Italy",
  "Monteprandone, Italy",
  "Montepulciano, Italy",
  "Monterado, Italy",
  "Monterey, United States",
  "Monteriggioni, Italy",
  "MONTERONI D'ARBIA, Italy",
  "Monterotondo, Italy",
  "Monterrey, Mexico",
  "Monterubbiano, Italy",
  "Montesa, Spain",
  "Montesarchio, Italy",
  "Montese, Italy",
  "Montesolaro, Italy",
  "Montespertoli, Italy",
  "Montesquiu, Spain",
  "Montesson Yvelines, France",
  "Monteux, France",
  "Montevarchi, Italy",
  "Monteveglio, Italy",
  "Monteviale, Italy",
  "Montevideo, Uruguay",
  "Montezuma, United States",
  "Montfaucon en Velay, France",
  "Montfoort, Netherlands",
  "Montfort sur Risle, France",
  "Montgomery, United States",
  "Montherme, France",
  "Monticelli Brusati, Italy",
  "Monticelli de Ongina, Italy",
  "Monticello, United States",
  "Monticello Brianza, Italy",
  "Monticello Conte Otto, Italy",
  "Monticello d'Alba, Italy",
  "Montichiari, Italy",
  "Montierchaume, France",
  "Montignoso, Italy",
  "Montigny en Gohelle, France",
  "Montigny le Bretonneux, France",
  "Montigny le Roi, France",
  "Montijo, Portugal",
  "Montijo, Spain",
  "Montilla, Spain",
  "Montilliers, France",
  "Montirone, Italy",
  "MONTITXELVO, Spain",
  "Montlhery, France",
  "Montluel, France",
  "Montmelian, France",
  "Montmelo, Spain",
  "Montodine, Italy",
  "Montoir de Bretagne, France",
  "Montoire sur le Loir, France",
  "Montoliu de Lleida, Spain",
  "Montone, Italy",
  "Montopoli Val d'Arno, Italy",
  "Montorio, Spain",
  "MONTORIO AL VOMANO, Italy",
  "Montornes del Valles, Spain",
  "Montoro Inferiore, Italy",
  "Montorso Vicentino, Italy",
  "Montoursville, United States",
  "Montpellier, France",
  "MONTREAL, Canada",
  "Montreal-Nord, Canada",
  "Montreal-Ouest, Canada",
  "Montredon Des Corbieres, France",
  "Montreuil, France",
  "Montreuil Bellay, France",
  "Montrevault-sur-Evre, France",
  "Montrevel-en-Bresse, France",
  "Montrose, United Kingdom",
  "Montrose, United States",
  "Montrottier, France",
  "Montu Beccaria, Italy",
  "Monturque, Spain",
  "Monvalle, Italy",
  "Monza, Italy",
  "Monzon, Spain",
  "Moody, United States",
  "Moon Township, United States",
  "Moore, United States",
  "Moorestown, United States",
  "Mooresville, United States",
  "Moos, Germany",
  "Moosburg, Germany",
  "Moosburg (a d Isar), Germany",
  "Moosic, United States",
  "Mor, Hungary",
  "Mora, Portugal",
  "Mora d'Ebre, Spain",
  "Mora la Nova, Spain",
  "Mora Toledo, Spain",
  "Moradabad, India",
  "Morag, Poland",
  "Moraine, United States",
  "Moraleda de Zafayona, Spain",
  "Moraleja, Spain",
  "Moraleja de Enmedio, Spain",
  "Moralzarzal, Spain",
  "Morana, Spain",
  "Morangis, France",
  "Moraro, Italy",
  "Morata de Tajuna, Spain",
  "Moratalla, Spain",
  "Moravany, Czech Republic",
  "Moravany u Brno, Czech Republic",
  "Moravska Trebova, Czech Republic",
  "Moravske Branice, Czech Republic",
  "Moravske Budejovice, Czech Republic",
  "Moravske Toplice, Slovenia",
  "Moravsky Beroun, Czech Republic",
  "Moravsky Krumlov, Czech Republic",
  "Moravsky Pisek, Czech Republic",
  "Moravsky Svaty Jan, Slovakia",
  "Morawica, Poland",
  "Morazzone, Italy",
  "Morbach (Hunsrueck), Germany",
  "Morbegno, Italy",
  "Morbi, India",
  "Morcin, Spain",
  "Mordano, Italy",
  "Morden London, United Kingdom",
  "Moreac, France",
  "Morecambe, United Kingdom",
  "Moreira Da Maia, Portugal",
  "Moreira de Conegos, Portugal",
  "Morelia, Mexico",
  "Moreton in Marsh, United Kingdom",
  "Moreton Valance Gloucestershire, United Kingdom",
  "Moreton Wirral, United Kingdom",
  "Moretta, Italy",
  "Moreuil, France",
  "Morez, France",
  "Morgan Hill, United States",
  "Morganton, United States",
  "Morgantown, United States",
  "Mori, Italy",
  "Moriago della Battaglia, Italy",
  "Morlaix, France",
  "Morley Yorks, United Kingdom",
  "Morlhon le Haut, France",
  "Mornac Charente, France",
  "Mornago, Italy",
  "Mornaguia, Tunisia",
  "Mornas, France",
  "Mornico al Serio, Italy",
  "Moron de la Frontera, Spain",
  "Moroni, Comoro Islands",
  "Morpeth, United Kingdom",
  "Morra de Sanctis, Italy",
  "Morris, United States",
  "Morristown, United States",
  "Morrisville, United States",
  "Morro d Alba, Italy",
  "Morro d'Oro, Italy",
  "Morrow, United States",
  "Morsano al Tagliamento, Italy",
  "Morsbach (Sieg), Germany",
  "Morschheim, Germany",
  "Mortagne au Perche, France",
  "Mortagne sur Sevre, France",
  "Mortagua, Portugal",
  "Mortara, Italy",
  "Mos, Spain",
  "Moschheim, Germany",
  "Mosciano Sant'Angelo, Italy",
  "Moscow, Russia",
  "Moscufo, Italy",
  "Moselle, United States",
  "Mosen, Switzerland",
  "MOSHI, Tanzania",
  "Mosnigo, Italy",
  "Mosnov, Czech Republic",
  "Mosonmagyarovar, Hungary",
  "Mosonszolnok, Hungary",
  "Mosonudvar, Hungary",
  "Moss, Norway",
  "Mossley, United Kingdom",
  "Mossoro, Brazil",
  "Most, Czech Republic",
  "Most Na Soci, Slovenia",
  "Mostar, Bosnia and Herzegovina",
  "Mostoles, Spain",
  "Mostyn, United Kingdom",
  "Moszczenica, Poland",
  "Mota del Cuervo, Spain",
  "Motala, Sweden",
  "Motherwell, United Kingdom",
  "Motilla del Palancar, Spain",
  "Motrico Mutriku, Spain",
  "Motril, Spain",
  "Motta Baluffi, Italy",
  "Motta di Livenza, Italy",
  "Motta S Anastasia, Italy",
  "Motteggiana, Italy",
  "Mouans Sartoux, France",
  "Mouguerre, France",
  "Moulay Bousselham, Morocco",
  "Moulins, France",
  "Mount Airy, United States",
  "Mount Holly Springs, United States",
  "Mount Juliet, United States",
  "MOUNT PLEASANT, United States",
  "Mount Pocono, United States",
  "Mount Sterling, United States",
  "Mount Vernon, United States",
  "Mountain Ash, United Kingdom",
  "Mountain City, United States",
  "Mountain Top, United States",
  "Mourenx, France",
  "Mourmelon le Petit, France",
  "MOUSCRON - MOESKROEN, Belgium",
  "Moussy le Neuf, France",
  "Moustier Hainaut, Belgium",
  "Mouthiers sur Boeme, France",
  "Moutiers Savoie, France",
  "Mouzillon, France",
  "Moyenmoutier, France",
  "Moze sur Louet, France",
  "Mozelos, Portugal",
  "Mozoncillo, Spain",
  "Mozzanica, Italy",
  "Mozzate, Italy",
  "Mozzecane, Italy",
  "Mozzo, Italy",
  "Mratin, Czech Republic",
  "Mroczen, Poland",
  "Mseno, Czech Republic",
  "Mszczonow, Poland",
  "Mtwara, Tanzania",
  "Muara, Brunei",
  "Mucenieki, Latvia",
  "Much Wenlock, United Kingdom",
  "Mudanya-Bursa, Turkey",
  "Mudurnu, Turkey",
  "Muegeln b Oschatz, Germany",
  "Mueglitztal, Germany",
  "Muehlacker, Germany",
  "Muehldorf (am Inn), Germany",
  "Muehlenbeck, Germany",
  "Muehlhausen, Germany",
  "Muehlhausen (im Taele), Germany",
  "Muehlhausen (Mittelfr), Germany",
  "Muehlhausen (Thuer), Germany",
  "Muehlhausen Ehingen, Germany",
  "Muehlheim (am Main), Germany",
  "Muehltroff, Germany",
  "Muel, Spain",
  "Muelheim (Mosel), Germany",
  "Muelheim an der Ruhr, Germany",
  "Muelheim-Kaerlich, Germany",
  "Muelle San Carlos, Costa Rica",
  "Muellheim, Germany",
  "Muenchberg, Germany",
  "Muenchenstein, Switzerland",
  "Muenchsmuenster, Germany",
  "Muenchsteinach, Germany",
  "Muendersbach, Germany",
  "Muennerstadt, Germany",
  "Muensingen, Germany",
  "Muensingen, Switzerland",
  "Muenster (b Dieburg), Germany",
  "Muenster (Westf), Germany",
  "Muerzzuschlag, Austria",
  "Muggensturm, Germany",
  "Muggia, Italy",
  "Muggio, Italy",
  "Mugla, Turkey",
  "Muille Villette, France",
  "Muir of Ord, United Kingdom",
  "MUIZENBERG, South Africa",
  "Mula, Spain",
  "Mulazzano, Italy",
  "Mulberry, United States",
  "Muldraugh, United States",
  "Mulfingen (Jagst), Germany",
  "Mulhouse, France",
  "Mulhuddart, Ireland",
  "Mullingar Westmeath, Ireland",
  "Mullins, United States",
  "Mullsjoe, Sweden",
  "Multan, Pakistan",
  "Mulund, India",
  "Muncy, United States",
  "Mundelein, United States",
  "Munderfing, Austria",
  "Munderkingen, Germany",
  "Mundra, India",
  "Mundra Port SEZ, India",
  "Mungia, Spain",
  "Munich, Germany",
  "Munorrodero, Spain",
  "Munshiganj, Bangladesh",
  "Munster, Germany",
  "Muras, Spain",
  "Murat, France",
  "Murchante, Spain",
  "Murcia, Spain",
  "Muret, France",
  "Murfreesboro, United States",
  "Muriedas, Spain",
  "Murieta, Spain",
  "Muro de Alcoy, Spain",
  "Muro Leccese, Italy",
  "Murow, Poland",
  "Murowana Goslina, Poland",
  "Murray, United States",
  "Murrhardt, Germany",
  "Murrieta, United States",
  "Murska Sobota, Slovenia",
  "Musaffa, United Arab Emirates",
  "Muscat, Oman",
  "Muscatine, United States",
  "Muscoline, Italy",
  "Museros, Spain",
  "Musile di Piave, Italy",
  "Muskegon, United States",
  "Mussleburgh, United Kingdom",
  "Mussolente, Italy",
  "Mustafa Kemalpasa, Turkey",
  "Muszyna, Poland",
  "Muta, Slovenia",
  "Mutare, Zimbabwe",
  "Mutilva Alta, Spain",
  "Mutlangen, Germany",
  "Mutsamudu, Comoro Islands",
  "Muttenz, Switzerland",
  "Mutxamel, Spain",
  "Mutzig, France",
  "Muuga, Estonia",
  "Mvurwi, Zimbabwe",
  "Myjava, Slovakia",
  "Mykolayiv, Ukraine",
  "Mylau, Germany",
  "Myrne, Ukraine",
  "Myslenice, Poland",
  "Myslocovice, Czech Republic",
  "Myslowice, Poland",
  "Myto, Czech Republic",
  "Maadi, Egypt",
  "Maaloev, Denmark",
  "Maaloey, Norway",
  "Maanshan, China",
  "Maardu, Estonia",
  "Maasbree, Netherlands",
  "Maasdijk, Netherlands",
  "Maashees, Netherlands",
  "Maasmechelen, Belgium",
  "Maastricht, Netherlands",
  "Maasvlakte, Netherlands",
  "Nabada, Georgia",
  "Nabeglavi, Georgia",
  "Nacala, Mozambique",
  "Nachod, Czech Republic",
  "Nacimiento, Spain",
  "Naco, Honduras",
  "Nacogdoches, United States",
  "Nadejov, Czech Republic",
  "Nador, Morocco",
  "Naerboe, Norway",
  "Naessjoe, Sweden",
  "Naestved, Denmark",
  "Nafplion, Greece",
  "Nagold, Germany",
  "Nagoya, Japan",
  "Nagpur, India",
  "Nagyatad, Hungary",
  "Nagykanizsa, Hungary",
  "Nagyrede, Hungary",
  "Nagytarcsa, Hungary",
  "Nahal Sorek, Israel",
  "Naharros, Spain",
  "Naidupeta, India",
  "Naila, Germany",
  "Nainital, India",
  "Nairobi, Kenya",
  "Najera, Spain",
  "Naklo, Slovenia",
  "Naknek, United States",
  "Nakskov, Denmark",
  "Nalles, Italy",
  "Nambroca, Spain",
  "Namest nad Oslavou, Czech Republic",
  "Namestovo, Slovakia",
  "Namibe, Angola",
  "Nampa, United States",
  "Namur, Belgium",
  "Nanchang, China",
  "Nanclares de Oca, Spain",
  "Nancy, France",
  "Nandaime, Nicaragua",
  "Nandakumar, India",
  "Nandax, France",
  "Nandayure, Costa Rica",
  "Nandlstadt, Germany",
  "Nangang, China",
  "Nangis, France",
  "NanHu, China",
  "Nanjing, China",
  "Nanno, Italy",
  "Nansha New Port, China",
  "Nanterre, France",
  "Nantes, France",
  "Nanto, Italy",
  "Nantong, China",
  "Nantwich, United Kingdom",
  "Naousa Kentriki Makedonia, Greece",
  "Napa, United States",
  "Napajedla, Czech Republic",
  "Napier, New Zealand",
  "Naples, Italy",
  "Naples, United States",
  "Naquera, Spain",
  "Naranjal - Guayas, Ecuador",
  "Naranjito, Ecuador",
  "Naranjo, Costa Rica",
  "Narberth SA, United Kingdom",
  "Narbonne, France",
  "Narborough Leics, United Kingdom",
  "Narew, Poland",
  "Narni, Italy",
  "Naron A Coruna, Spain",
  "Narva, Estonia",
  "Nashua, United States",
  "Nashville, United States",
  "Nasik, India",
  "Nasr, Egypt",
  "Nassandres, France",
  "Nassenfels, Germany",
  "Nassereith, Austria",
  "Natick, United States",
  "National City, United States",
  "Nattheim, Germany",
  "Naturno, Italy",
  "Nauen, Germany",
  "Naugatuck, United States",
  "Nauheim (Kr Gross-Gerau), Germany",
  "Naumburg (Saale), Germany",
  "Nava De La Asuncion, Spain",
  "Nava Del Rey, Spain",
  "Navalcarnero, Spain",
  "Navalmanzano, Spain",
  "Navalmoral Mata, Spain",
  "Navarrete, Spain",
  "Nave, Italy",
  "Navegantes, Brazil",
  "Navi Mumbai, India",
  "Navia Oviedo, Spain",
  "Navodari, Romania",
  "Navojoa, Mexico",
  "Navole', Italy",
  "Nazareth, Belgium",
  "Nazareth, Israel",
  "Nazareth, United States",
  "Nazeing, United Kingdom",
  "Nazilli, Turkey",
  "Ndola, Zambia",
  "Nea Artaki, Greece",
  "Nea Ionia Thessaloniki, Greece",
  "Nea Moudania, Greece",
  "Nea Redestos, Greece",
  "Nea Sanda Kilkis, Greece",
  "Neasden, United Kingdom",
  "Neath, United Kingdom",
  "Neave Township, South Africa",
  "Nebbiuno, Italy",
  "Nebuzely, Czech Republic",
  "Nechanice, Czech Republic",
  "Neckarsteinach, Germany",
  "Neckarsulm, Germany",
  "Neckartailfingen, Germany",
  "Nederweert, Netherlands",
  "Nedvedice, Czech Republic",
  "Needham Market, United Kingdom",
  "Neenah, United States",
  "Negarine, Italy",
  "Negley, United States",
  "Negotin, Serbia",
  "Negrar di Valpolicella, Italy",
  "Negrepelisse, France",
  "Nehren (Wuertt), Germany",
  "NEHVIZDY, Czech Republic",
  "Neidenfels (Pfalz), Germany",
  "Neidenstein, Germany",
  "Neiva, Portugal",
  "Nejdek, Czech Republic",
  "Nelas, Portugal",
  "Nelson, New Zealand",
  "Nelson Lancs, United Kingdom",
  "Nelspruit, South Africa",
  "Nembro, Italy",
  "Nemcicky (u Ivancic), Czech Republic",
  "Nemea, Greece",
  "Nemesvamos, Hungary",
  "Nemirov, Ukraine",
  "Nemours, France",
  "Nemsova, Slovakia",
  "Neo Faliro, Greece",
  "Neo Ikonio, Greece",
  "Neochorouda, Greece",
  "Nepi, Italy",
  "Nerac, France",
  "Neratovice, Czech Republic",
  "Nersac, France",
  "Nervesa Della Battaglia, Italy",
  "Nerviano, Italy",
  "Nesbit, United States",
  "Nesbyen, Norway",
  "Neston Cheshire, United Kingdom",
  "Nesttun, Norway",
  "Nesvady, Slovakia",
  "Netanya, Israel",
  "Netherton Merseyside, United Kingdom",
  "Netphen, Germany",
  "Netstal, Switzerland",
  "Nettetal, Germany",
  "Neu Ulm, Germany",
  "Neu Wulmstorf, Germany",
  "Neuberg, Germany",
  "Neubeuern, Germany",
  "Neubrandenburg, Germany",
  "Neubrunn (bei Meiningen), Germany",
  "Neubulach, Germany",
  "Neuburg (an der Donau), Germany",
  "Neuburg an der Kammel, Germany",
  "Neuchatel, Switzerland",
  "Neuching, Germany",
  "Neudau, Austria",
  "Neudietendorf, Germany",
  "Neuenbuerg (Wuertt), Germany",
  "Neuenburg (am Rhein), Germany",
  "Neuendorf, Germany",
  "Neuenegg, Switzerland",
  "Neuengoenna, Germany",
  "Neuenhagen, Germany",
  "Neuenhaus, Germany",
  "Neuenkirchen (Kreis Steinfurt), Germany",
  "Neuenkirchen (Land Hadeln), Germany",
  "Neuenmarkt, Germany",
  "Neuenrade, Germany",
  "Neuenstadt, Germany",
  "Neuenstein, Germany",
  "Neufahrn (bei Freising), Germany",
  "Neufahrn in Niederbayern, Germany",
  "Neuhaus (a Rennweg), Germany",
  "Neuhaus an der Pegnitz, Germany",
  "Neuhaus/Inn, Germany",
  "Neuhausen (Enzkr), Germany",
  "Neuhausen ad Fildern, Germany",
  "Neuhausen am Rheinfall, Switzerland",
  "Neuhof (Kr Fulda), Germany",
  "Neuhofen, Germany",
  "Neuille, France",
  "Neuilly sur Marne, France",
  "Neu-Isenburg, Germany",
  "Neukieritzsch, Germany",
  "Neukirch (Tettnang), Germany",
  "Neukirchen (Pleisse), Germany",
  "Neukirchen (vorm Wald), Germany",
  "Neukirchen Erzgeb, Germany",
  "Neukirchen-Vluyn, Germany",
  "Neu-Kupfer, Germany",
  "Neulehe, Germany",
  "Neuler, Germany",
  "Neulingen (b Pforzheim), Germany",
  "Neumarkt (Oberpfalz), Germany",
  "Neumarkt am Wallersee, Austria",
  "Neumarkt Sankt Veit, Germany",
  "Neumuenster, Germany",
  "Neunburg (Vorm Wald), Germany",
  "Neunkirchen (Siegerl), Germany",
  "Neunkirchen (Saar), Germany",
  "Neunkirchen a Brand, Germany",
  "Neunkirchen-Seelscheid, Germany",
  "Neuoetting, Germany",
  "Neuquen Neuquen, Argentina",
  "Neuried, Germany",
  "Neuruppin, Germany",
  "Neusaess, Germany",
  "Neuss, Germany",
  "Neustadt (a d Donau), Germany",
  "Neustadt (Orla), Germany",
  "Neustadt (Sachsen), Germany",
  "Neustadt (Vogtl), Germany",
  "Neustadt (Wied), Germany",
  "Neustadt an der Weinstrasse, Germany",
  "Neustadt/Coburg, Germany",
  "Neustadt-Glewe, Germany",
  "Neustrelitz, Germany",
  "Neutraubling, Germany",
  "Neuville en Ferrain, France",
  "Neuville les Dieppe, France",
  "Neuweiler, Germany",
  "Neuwied, Germany",
  "Nevasa, India",
  "Nevers, France",
  "Nevoy, France",
  "Nevsehir, Turkey",
  "New Albany, United States",
  "New Alresford, United Kingdom",
  "New Baltimore, United States",
  "New Berlin, United States",
  "New Bern, United States",
  "New Boston, United States",
  "New Braunfels, United States",
  "New Bremen, United States",
  "New Brighton, United States",
  "New Britain, United States",
  "New Brunswick, United States",
  "New Castle, United States",
  "New Castle Northern Ireland, United Kingdom",
  "New Century, United States",
  "New Cumberland, United States",
  "New Delhi, India",
  "New Germany, South Africa",
  "New Hamburg, Canada",
  "New Holland, United States",
  "New Hudson, United States",
  "New Kensington, United States",
  "New London, United States",
  "New Malden, United Kingdom",
  "New Mills Derbyshire, United Kingdom",
  "New Orleans, United States",
  "New Paris, United States",
  "New Philadelphia, United States",
  "New Sabzi Mandi, Pakistan",
  "New Tazewell, United States",
  "New Ulm, United States",
  "New Westminster, Canada",
  "New York - Staten Island, United States",
  "Newark, United States",
  "Newark Nottinghamshire, United Kingdom",
  "Newberry, United States",
  "Newbridge Kildare, Ireland",
  "Newbridge Loth, United Kingdom",
  "Newburn, United Kingdom",
  "Newbury, United Kingdom",
  "Newcastle Emlyn, United Kingdom",
  "Newcastle under Lyme, United Kingdom",
  "Newcastle upon Tyne, United Kingdom",
  "Newcomerstown, United States",
  "Newent, United Kingdom",
  "Newhaven, United Kingdom",
  "Newington, United States",
  "Newman, United States",
  "Newmarket Suffolk, United Kingdom",
  "Newnan, United States",
  "NEWPORT, United States",
  "Newport Gwent, United Kingdom",
  "Newport News, United States",
  "Newport Pagnell, United Kingdom",
  "Newport Shropshire, United Kingdom",
  "Newquay, United Kingdom",
  "Newry Northern Ireland, United Kingdom",
  "Newton, United States",
  "Newton Abbott, United Kingdom",
  "Newton Aycliffe, United Kingdom",
  "Newton Heath, United Kingdom",
  "Newton le Willows St Helens, United Kingdom",
  "Newton Stewart, United Kingdom",
  "Newtonhill, United Kingdom",
  "Newtown Powys, United Kingdom",
  "Newtownabbey Northern Ireland, United Kingdom",
  "Newtownards Northern Ireland, United Kingdom",
  "Niagara Falls, Canada",
  "Niagara Falls, United States",
  "Niamey, Niger",
  "Nibbia, Italy",
  "Nibbiano, Italy",
  "Nibe, Denmark",
  "Nibionno, Italy",
  "Nice, France",
  "Nichelino, Italy",
  "Nicosia, Cyprus",
  "Nicoya, Costa Rica",
  "Nidda, Germany",
  "Niederaichbach, Germany",
  "Niederaula, Germany",
  "Niederdreisbach, Germany",
  "Niederglatt ZH, Switzerland",
  "Niedergoersdorf, Germany",
  "Niedergosgen, Switzerland",
  "Niederkassel, Germany",
  "Niederkruechten, Germany",
  "Niederlauterbach, France",
  "Niedernberg, Germany",
  "Niederneisen, Germany",
  "Niedernhall, Germany",
  "Niederoenz, Switzerland",
  "Nieder-Olm, Germany",
  "Niederwangen, Switzerland",
  "Niederwinkling, Germany",
  "Niederzier, Germany",
  "Niederzissen, Germany",
  "Niefern-Oeschelbronn, Germany",
  "Nieledew, Poland",
  "Nienburg (Saale), Germany",
  "Nienburg (Weser), Germany",
  "Nienhagen, Germany",
  "Niepolomice, Poland",
  "Nieporet, Poland",
  "Niesky, Germany",
  "Niestetal, Germany",
  "Nieuwdorp, Netherlands",
  "Nieuwegein, Netherlands",
  "Nieuwerkerk, Netherlands",
  "Nieuwerkerk aan den Ijssel, Netherlands",
  "Nieuwerkerken, Belgium",
  "Nieuwkerken Waas, Belgium",
  "Nieuwkuijk, Netherlands",
  "Nieuw-Vennep, Netherlands",
  "Nigde, Turkey",
  "Nigde-Aksaray, Turkey",
  "Nigel, South Africa",
  "Nigoline, Italy",
  "Nigran, Spain",
  "Niigata, Japan",
  "Nijar, Spain",
  "Nijkerk, Netherlands",
  "Nijlen, Belgium",
  "Nijmegen, Netherlands",
  "Nijverdal, Netherlands",
  "Nikopol, Ukraine",
  "Nilai, Malaysia",
  "Nilufer, Turkey",
  "Nimes, France",
  "Nindiri, Nicaragua",
  "Ningbo, China",
  "Niort, France",
  "Nishiki-cho Naka-ku Kanagawa, Japan",
  "Nisko, Poland",
  "Nitra, Slovakia",
  "Nitrianske Pravno, Slovakia",
  "Nivelles, Belgium",
  "Nivnice, Czech Republic",
  "Nizampatnam, India",
  "Nizbor, Czech Republic",
  "NIZIP, Turkey",
  "Nizna(nad Oravou), Slovakia",
  "Njombe, Tanzania",
  "Nkok, Gabon",
  "Noain, Spain",
  "Noale, Italy",
  "Noblejas, Spain",
  "Nocelleto di Carinola, Italy",
  "Nocera Inferiore, Italy",
  "Nocera Superiore, Italy",
  "Nocera Umbra, Italy",
  "Noceto, Italy",
  "Noci, Italy",
  "Noe Haute Garonne, France",
  "Noerager, Denmark",
  "Noerdlingen, Germany",
  "Noerre Alslev, Denmark",
  "Noerresundby, Denmark",
  "Noerten-Hardenberg, Germany",
  "Nogales, Mexico",
  "Nogara, Italy",
  "Nogarole Rocca, Italy",
  "Nogent le Roi, France",
  "Nogent le Rotrou, France",
  "Nogent sur Eure, France",
  "Nogent sur Oise, France",
  "Nogent sur Seine, France",
  "Noginsk, Russia",
  "Nogueira do Cravo, Portugal",
  "Nogueres, France",
  "Nohfelden, Germany",
  "Nohra, Germany",
  "Noisy le Grand, France",
  "Noisy le Sec, France",
  "Nokia, Finland",
  "Nol, Sweden",
  "Nola, Italy",
  "Nolay, France",
  "Nonantola, Italy",
  "None, Italy",
  "Nonington Kent, United Kingdom",
  "Nonnweiler, Germany",
  "Noord-Scharwoude, Netherlands",
  "Noordwijkerhout, Netherlands",
  "Norcross, United States",
  "Nordenham, Germany",
  "Nordenstadt, Germany",
  "Norderstedt, Germany",
  "Nordhausen, Germany",
  "Nordhavn, Denmark",
  "Nordhorn, Germany",
  "Nordkirchen, Germany",
  "Nordwalde, Germany",
  "Norfolk, United States",
  "Norheimsund, Norway",
  "Normal, United States",
  "Norman, United States",
  "Normanton Wakefield, United Kingdom",
  "Norrkoeping, Sweden",
  "Norsjoe, Sweden",
  "Nort sur Erdre, France",
  "North Augusta, United States",
  "North Aurora, United States",
  "North Bergen, United States",
  "North Berwick, United Kingdom",
  "North Berwick, United States",
  "North Billerica, United States",
  "North Branch, United States",
  "North Brunswick, United States",
  "North Chelmsford, United States",
  "North Collins, United States",
  "North Dighton, United States",
  "North End East PLZ, South Africa",
  "North End West PLZ, South Africa",
  "North Ferriby, United Kingdom",
  "NORTH FORT MYERS, United States",
  "North Kansas City, United States",
  "North Kingstown, United States",
  "North Las Vegas, United States",
  "North Little Rock, United States",
  "North Pier Yokohama, Japan",
  "North Ridgeville, United States",
  "North Riding, South Africa",
  "North Scarle, United Kingdom",
  "North Shields, United Kingdom",
  "North Tawton, United Kingdom",
  "North Thoresby, United Kingdom",
  "North Walsham Norfolk, United Kingdom",
  "North Weald Bassett Essex, United Kingdom",
  "North Wilkesboro, United States",
  "North York, Canada",
  "Northallerton, United Kingdom",
  "Northampton, United Kingdom",
  "Northeim, Germany",
  "Northfleet, United Kingdom",
  "Northlake, United States",
  "Northolt, United Kingdom",
  "Northumberland, United States",
  "Northwich, United Kingdom",
  "Northwood HA, United Kingdom",
  "Norton Kent, United Kingdom",
  "Norwich, Canada",
  "Norwich, United Kingdom",
  "NORWOOD, United States",
  "Nosovice, Czech Republic",
  "Nossen, Germany",
  "Noto, Italy",
  "Notre Dame de l'Isle, France",
  "Nottingham, United Kingdom",
  "Nottingham, United States",
  "Nottuln, Germany",
  "Nouacer, Morocco",
  "Nouadhibou, Mauritania",
  "Nouakchott, Mauritania",
  "Nouasseur, Morocco",
  "Nouatre, France",
  "Noumea, New Caledonia",
  "Nova Bana, Slovakia",
  "Nova Bystrice, Czech Republic",
  "Nova Cerekev, Czech Republic",
  "Nova Dubnica, Slovakia",
  "Nova Gorica, Slovenia",
  "Nova Lima, Brazil",
  "Nova Milanese, Italy",
  "Nova Paka, Czech Republic",
  "Nova Role, Czech Republic",
  "Nova Vcelnice, Czech Republic",
  "Nova Zagora, Bulgaria",
  "Novaky, Slovakia",
  "Novaledo, Italy",
  "Novales, Spain",
  "Novara, Italy",
  "Novate Milanese, Italy",
  "Nove, Italy",
  "Nove Mesto na Morave, Czech Republic",
  "Nove Mesto nad Metuji, Czech Republic",
  "Nove Mesto nad Vahom, Slovakia",
  "NOVE SADY, Slovakia",
  "Nove Veseli, Czech Republic",
  "Nove Zamky, Slovakia",
  "Novedrate, Italy",
  "Novegro di Segrate, Italy",
  "Novelda Alicante, Spain",
  "Novellara, Italy",
  "Noventa di Piave, Italy",
  "Noventa Padovana, Italy",
  "Noventa Vicentina, Italy",
  "Noves, Spain",
  "Novgorod, Russia",
  "Novi di Modena, Italy",
  "Novi Ligure, Italy",
  "Novi Marof, Croatia",
  "Novi Pazar, Bulgaria",
  "Novi Sad, Serbia",
  "Noviglio, Italy",
  "Novo Mesto, Slovenia",
  "Novomirgorod, Ukraine",
  "Novorossiysk, Russia",
  "Novosedly, Czech Republic",
  "Novosibirsk, Russia",
  "NOVOT, Slovakia",
  "Novy Bor, Czech Republic",
  "Novy Bydzov, Czech Republic",
  "Novy Jicin, Czech Republic",
  "Nowa Ruda, Poland",
  "Nowa Sarzyna, Poland",
  "Nowa Sol, Poland",
  "Nowa Wies Wroclawska, Poland",
  "Nowe Miasto nad Pilica, Poland",
  "Nowe Skalmierzyce, Poland",
  "Nowogrodziec, Poland",
  "Nowy Dwor Mazowiecki, Poland",
  "Nowy Konik, Poland",
  "Nowy Sacz, Poland",
  "Nowy Targ, Poland",
  "Nowy Tomysl, Poland",
  "Nowy Wisnicz, Poland",
  "Noyal Chatillon sur Seiche, France",
  "Noyal Pontivy, France",
  "Noyal sur Vilaine, France",
  "Noyelles les Seclin, France",
  "NOYELLES LES VERMELLES, France",
  "Noyelles sous Lens, France",
  "Noyers sur Cher, France",
  "Noyon, France",
  "Nuembrecht, Germany",
  "Nuernberg, Germany",
  "Nuertingen, Germany",
  "Nueva Gorgona, Panama",
  "Nueva Italia, Mexico",
  "Nuevo Urecho, Mexico",
  "Nueziders, Austria",
  "Nufringen, Germany",
  "Nuits Saint Georges, France",
  "Nules, Spain",
  "Numancia De La Sagra, Spain",
  "Nuneaton, United Kingdom",
  "Nuova Olonia di Dubino, Italy",
  "Nupaky, Czech Republic",
  "Nurieux Volognat, France",
  "Nurmijarvi, Finland",
  "Nurmo, Finland",
  "Nusco, Italy",
  "Nussbach, Austria",
  "Nuthetal, Germany",
  "Nuvolento, Italy",
  "Nuvolera, Italy",
  "Nyborg, Denmark",
  "Nyergesujfalu, Hungary",
  "Nygaard, Sweden",
  "Nyiradony, Hungary",
  "Nyiregyhaza, Hungary",
  "Nyirgelse, Hungary",
  "Nyirtura, Hungary",
  "Nykoebing Mors, Denmark",
  "Nymburk, Czech Republic",
  "Nynaeshamn, Sweden",
  "Nyrany, Czech Republic",
  "Nysa, Poland",
  "Naarden, Netherlands",
  "Naas Kildare, Ireland",
  "Naassen, Tunisia",
  "O Grove, Spain",
  "O Rosal Pontevedra, Spain",
  "Oak Harbor, United States",
  "Oak Ridge, United States",
  "Oakfield, United States",
  "Oakham, United Kingdom",
  "Oakland, United States",
  "Oakville, Canada",
  "Oaxaca, Mexico",
  "Oban, United Kingdom",
  "Oberahr, Germany",
  "Oberarnbach, Germany",
  "Oberasbach (bei Nuernberg), Germany",
  "Oberau, Germany",
  "Oberaurach, Germany",
  "Oberbueren, Switzerland",
  "Oberburg, Switzerland",
  "Oberderdingen, Germany",
  "Oberding, Germany",
  "Oberentfelden, Switzerland",
  "Oberguenzburg, Germany",
  "Oberhaid, Germany",
  "Oberharmersbach, Germany",
  "Oberhausen (Kr Neuburg/Donau), Germany",
  "Oberhausen (Rheinland), Germany",
  "Oberkirch, Germany",
  "Oberkochen, Germany",
  "Oberkotzau, Germany",
  "Oberlahr, Germany",
  "Obermeitingen, Germany",
  "Obermoschel, Germany",
  "Obernai, France",
  "Obernburg (am Main), Germany",
  "Oberndorf (am Neckar), Germany",
  "Oberndorf am Lech, Germany",
  "Obernheim, Germany",
  "Obernzell, Germany",
  "Oberottmarshausen, Germany",
  "Oberpframmern, Germany",
  "Oberriet, Switzerland",
  "Oberrot, Germany",
  "Oberscheinfeld, Germany",
  "Oberschleissheim, Germany",
  "Oberschoenegg, Germany",
  "Obersontheim, Germany",
  "Oberstaufen, Germany",
  "Obersulm, Germany",
  "Oberteuringen, Germany",
  "Obertraubling, Germany",
  "Obertshausen, Germany",
  "Oberweis, Austria",
  "Obetz, United States",
  "Obidos, Portugal",
  "Obing, Germany",
  "Obour, Egypt",
  "Obrenovac, Serbia",
  "Obrigheim (Pfalz), Germany",
  "Ocala, United States",
  "Ocana, Spain",
  "Occhiobello, Italy",
  "Occimiano, Italy",
  "Oceanside, United States",
  "Ochakiv, Ukraine",
  "Ochlocknee, United States",
  "Ochsenfurt, Germany",
  "Ochsenhausen, Germany",
  "Ochtendung, Germany",
  "Ochtrup, Germany",
  "Ociaz, Poland",
  "Ocotal, Nicaragua",
  "Ocsa, Hungary",
  "Odda, Norway",
  "Odelzhausen, Germany",
  "Odemira, Portugal",
  "Odemis, Turkey",
  "Odena, Spain",
  "Odense, Denmark",
  "Oderen, France",
  "Oderwitz, Germany",
  "Oderzo, Italy",
  "Odessa, Ukraine",
  "Odessa, United States",
  "Odiham, United Kingdom",
  "Odolo, Italy",
  "Odry, Czech Republic",
  "Oehringen, Germany",
  "Oelde, Germany",
  "Oelegem, Belgium",
  "Oelgod, Denmark",
  "Oelsnitz (bei Grossenhain, Sachsen), Germany",
  "Oelsnitz(Vogtland), Germany",
  "Oelsted, Denmark",
  "Oelstykke, Denmark",
  "Oensingen, Switzerland",
  "Oerebro, Sweden",
  "Oerkelljunga, Sweden",
  "Oerlinghausen, Germany",
  "Oestansjoe, Sweden",
  "Oesterwurth, Germany",
  "Oestrich-Winkel, Germany",
  "Oestringen, Germany",
  "Oetigheim, Germany",
  "Oettingen, Germany",
  "Oevel, Belgium",
  "Oeverum, Sweden",
  "Oeynhausen, Austria",
  "Offanengo, Italy",
  "Offenbach (am Main), Germany",
  "Offenburg, Germany",
  "Offenhausen (Mittelfr), Germany",
  "Offida, Italy",
  "Offingen (Donau), Germany",
  "Offlaga, Italy",
  "Offranville, France",
  "Ofterdingen, Germany",
  "Oftringen, Switzerland",
  "Ogan Komering Ilir, Indonesia",
  "Ogden, United States",
  "Oggiona Con Santo Stefano, Italy",
  "Oggiono, Italy",
  "Oglianico, Italy",
  "Ohrdruf, Germany",
  "Ohrid, Macedonia",
  "Oia - Oliveira do Bairro, Portugal",
  "Oiartzun, Spain",
  "Oil City, United States",
  "Oimbra, Spain",
  "OINOFYTA, Greece",
  "Oion, Spain",
  "Oiry, France",
  "Oita, Japan",
  "Okehampton, United Kingdom",
  "Oklahoma City, United States",
  "Okmeydani, Turkey",
  "Okolona, United States",
  "Okondo, Spain",
  "Okonek, Poland",
  "Okrisky, Czech Republic",
  "Okrouhlice, Czech Republic",
  "Olaberria Guipuzcoa, Spain",
  "Olaine, Latvia",
  "Olathe, United States",
  "Olawa, Poland",
  "Olching, Germany",
  "Old, United Kingdom",
  "Oldbury West Midlands, United Kingdom",
  "Oldcastle, Canada",
  "Oldenburg (Oldenburg), Germany",
  "Oldenburg in Holstein, Germany",
  "Oldendorf (Kr Stade), Germany",
  "Oldenzaal, Netherlands",
  "Oldham, United Kingdom",
  "Oldmeldrum, United Kingdom",
  "Oldrzychowice Klodzkie, Poland",
  "Oleggio, Italy",
  "Oleggio Castello, Italy",
  "Oleiros, Spain",
  "Olen, Belgium",
  "Olerdola, Spain",
  "Olesa de Montserrat, Spain",
  "Olesnica, Poland",
  "Olesnice na Morave, Czech Republic",
  "Olevsk, Ukraine",
  "Olgiate Comasco, Italy",
  "Olgiate Olona, Italy",
  "Olginate, Italy",
  "Olhao, Portugal",
  "Olias del Rey, Spain",
  "Olite, Spain",
  "Olius, Spain",
  "Oliva, Spain",
  "Olive Branch, United States",
  "Oliveira de Azemeis, Portugal",
  "Oliveira de Bairro, Portugal",
  "Oliveira de Frades, Portugal",
  "Olivenza, Spain",
  "Olivet, France",
  "Oliveto Citra, Italy",
  "Olkusz, Poland",
  "Ollauri, Spain",
  "Olleria, Spain",
  "Ollerton Notts, United Kingdom",
  "Olloki, Spain",
  "Olloniego Asturias, Spain",
  "Olmedo, Spain",
  "Olmi, Italy",
  "Olmsted Falls, United States",
  "Olney Milton Keynes, United Kingdom",
  "Olocau, Spain",
  "Olocuilta, El Salvador",
  "Olomouc, Czech Republic",
  "Olot, Spain",
  "Olpe (Biggesee), Germany",
  "Olsany, Czech Republic",
  "Olsberg, Germany",
  "Olszowa, Poland",
  "Olsztyn, Poland",
  "Olsztynek, Poland",
  "Oltenita, Romania",
  "Oltrona al Lago, Italy",
  "Olvega, Spain",
  "Olympiada, Greece",
  "Omagh Northern Ireland, United Kingdom",
  "Omaha, United States",
  "Ome, Italy",
  "Omegna, Italy",
  "Onahama, Japan",
  "Onara Tombolo, Italy",
  "Onate, Spain",
  "Onda, Spain",
  "One' di Fonte, Italy",
  "Onesti, Romania",
  "Ongar Essex, United Kingdom",
  "Ongole, India",
  "Onil, Spain",
  "Onnaing, France",
  "Onne, Nigeria",
  "Onore, Italy",
  "Ontario, United States",
  "Ontigola, Spain",
  "Ontinar del Salz, Spain",
  "Ontinyent, Spain",
  "Onton, Spain",
  "Onzonilla, Spain",
  "Ooigem, Belgium",
  "Ooltewah, United States",
  "Oostakker, Belgium",
  "Oostburg, Netherlands",
  "Oosterhout, Netherlands",
  "Oosthuizen, Netherlands",
  "Oostkamp, Belgium",
  "Oostrozebeke, Belgium",
  "Oostrum, Netherlands",
  "Opatov, Czech Republic",
  "Opatowek, Poland",
  "Opava, Czech Republic",
  "Opera, Italy",
  "Opocno, Czech Republic",
  "Opoczno, Poland",
  "Opole, Poland",
  "Oppach, Germany",
  "Oppeano, Italy",
  "Oppenau, Germany",
  "Ora, Italy",
  "Oradea, Romania",
  "Oragadam, India",
  "Oran, Algeria",
  "Oran Terminal, Algeria",
  "ORANGE, United States",
  "Orange Park, United States",
  "Orangeville, Canada",
  "Oranienburg, Germany",
  "Oranjestad, Aruba",
  "Orastie, Romania",
  "Oravska Lesna, Slovakia",
  "Oravsky Podzamok, Slovakia",
  "Orbassano, Italy",
  "Orbe, Switzerland",
  "Orchies, France",
  "Orchomenos, Greece",
  "Orcoyen, Spain",
  "Orcq, Belgium",
  "Ordes, Spain",
  "Ordu, Turkey",
  "Orefield, United States",
  "OREJO (Marina de Cudeyo), Spain",
  "Orekhovo Zuyevo, Russia",
  "Oreokastro, Greece",
  "Orera, Spain",
  "Orgaz, Spain",
  "Orgiano, Italy",
  "Orhangazi, Turkey",
  "Oricain, Spain",
  "Origgio, Italy",
  "Origny Sainte Benoite, France",
  "Orihuela, Spain",
  "Orimattila, Finland",
  "Orio al Serio, Italy",
  "Orizaba, Mexico",
  "Orizovo, Bulgaria",
  "Orkanger, Norway",
  "Orla, Poland",
  "Orlando, United States",
  "Orleans, France",
  "Orleans, United States",
  "Orly, France",
  "Ormelle, Italy",
  "Ormes, France",
  "Ormskirk, United Kingdom",
  "Ornago, Italy",
  "Ornans, France",
  "Orono, Canada",
  "Oropesa del Mar, Spain",
  "Oropesa Toledo, Spain",
  "Oroshaza, Hungary",
  "Oroso S Martino, Spain",
  "Orotina Via Caldera, Costa Rica",
  "Orozco, Spain",
  "Orpington, United Kingdom",
  "Orrville, United States",
  "Orsago, Italy",
  "Orsenigo, Italy",
  "Orskog, Norway",
  "Orta di Atella, Italy",
  "Orta Nova, Italy",
  "Ortenberg (Baden), Germany",
  "Ortenberg (Hess), Germany",
  "Ortenburg, Germany",
  "Ortona, Italy",
  "Ortonovo, Italy",
  "Ortucchio, Italy",
  "Ortuella, Spain",
  "Orvault, France",
  "Orvieto, Italy",
  "Orzesze, Poland",
  "Orzinuovi, Italy",
  "Osaka, Japan",
  "Osan, Korea, South",
  "Osasco, Brazil",
  "Osceola, United States",
  "Oschatz, Germany",
  "Oshawa, Canada",
  "Oshkosh, United States",
  "Osimo, Italy",
  "Osio di Sopra, Italy",
  "Osio Sotto, Italy",
  "Oskaloosa, United States",
  "Oskarshamn, Sweden",
  "Oslo, Norway",
  "OSMANGAZI, Turkey",
  "Osmaniye-Adana, Turkey",
  "Osmannoro, Italy",
  "Osnabrueck, Germany",
  "Osnago, Italy",
  "Osoppo, Italy",
  "Osorno, Chile",
  "Osorno, Spain",
  "Osov, Czech Republic",
  "Ospedaletto, Italy",
  "Ospedaletto d Alpinolo, Italy",
  "Ospedaletto Euganeo, Italy",
  "Ospedaletto Lodigiano, Italy",
  "Ospedalicchio, Italy",
  "Ospiate, Italy",
  "Ospitaletto, Italy",
  "Oss, Netherlands",
  "Ossago Lodigiano, Italy",
  "Ossett, United Kingdom",
  "Ossona, Italy",
  "Ostellato, Italy",
  "Ostend, Belgium",
  "Osterburken, Germany",
  "Osterhofen (Niederbay), Germany",
  "Osterholz Scharmbeck, Germany",
  "Osteria Grande, Italy",
  "Osterode (am Harz), Germany",
  "Osterweddingen, Germany",
  "Osterwieck, Germany",
  "Ostfildern, Germany",
  "Ostia, Italy",
  "Ostiano, Italy",
  "Ostiglia, Italy",
  "Ostra, Italy",
  "Ostrach, Germany",
  "Ostrata, Czech Republic",
  "Ostrau (Sachsen), Germany",
  "Ostrava, Czech Republic",
  "Ostravice, Czech Republic",
  "Ostroda, Poland",
  "Ostromer, Czech Republic",
  "Ostrov u Stribra, Czech Republic",
  "Ostrow Lubelski, Poland",
  "Ostrow Mazowiecka, Poland",
  "Ostrow Mazowiecki, Poland",
  "Ostrow Wielkopolski, Poland",
  "Ostrowiec Swietokrzyski, Poland",
  "Ostrzeszow, Poland",
  "Oststeinbek, Germany",
  "Osuna, Spain",
  "Oswestry Shrops, United Kingdom",
  "Oswiecim, Poland",
  "Otero de Herreros, Spain",
  "Otley Suffolk, United Kingdom",
  "Otley Yorkshire, United Kingdom",
  "Otopeni, Romania",
  "Otricoli, Italy",
  "Otrokovice, Czech Republic",
  "Ottaviano, Italy",
  "Ottawa, Canada",
  "Ottawa, United States",
  "Ottendorf Okrilla, Germany",
  "Otterberg, Germany",
  "Otterndorf, Germany",
  "Ottershaw, United Kingdom",
  "Ottery Saint Mary, United Kingdom",
  "Ottmarsheim, France",
  "Ottobeuren, Germany",
  "Ottobrunn, Germany",
  "Ottweiler, Germany",
  "Otxandio, Spain",
  "Otzberg, Germany",
  "Ouagadougou, Burkina Faso",
  "Oudalle, France",
  "Oud-Beijerland, Netherlands",
  "Oudenbosch, Netherlands",
  "Oudenaarde, Belgium",
  "Oud-Gastel, Netherlands",
  "Oudkarspel, Netherlands",
  "Oujda, Morocco",
  "Ouled Taima, Morocco",
  "Oullins, France",
  "Oulu, Finland",
  "Oundle, United Kingdom",
  "Ourem, Portugal",
  "Ourense, Spain",
  "Outarville, France",
  "Outeiro da Cabeca, Portugal",
  "Outeiro Pequeno, Portugal",
  "Outriaz, France",
  "Ouville la Riviere, France",
  "Ovar, Portugal",
  "Ovaro, Italy",
  "Overpelt, Belgium",
  "Overton on Dee, United Kingdom",
  "Ovidiu, Romania",
  "Oviedo, Spain",
  "Oviedo, United States",
  "Owatonna, United States",
  "Owensboro, United States",
  "Owingen (Bodensee), Germany",
  "Owosso, United States",
  "Oxford, United Kingdom",
  "Oxford, United States",
  "Oxie, Sweden",
  "Oxnard, United States",
  "Oxted, United Kingdom",
  "Oyon, Spain",
  "Oyonnax, France",
  "Oyten, Germany",
  "Ozark, United States",
  "Ozbalt ob Dravi, Slovenia",
  "Ozimek, Poland",
  "Ozoir la Ferriere, France",
  "Ozolnieki, Latvia",
  "Ozorkow, Poland",
  "Ozurgeti, Georgia",
  "Ozzano dell'Emilia, Italy",
  "Ozzano Taro, Italy",
  "Pabianice, Poland",
  "Pace del Mela, Italy",
  "Pachino, Italy",
  "Pachuca, Mexico",
  "Pacoima, United States",
  "Pacora, Panama",
  "Pacos de Ferreira, Portugal",
  "Pacov, Czech Republic",
  "Pacs del Penedes, Spain",
  "Pacy Sur Eure, France",
  "Padang, Indonesia",
  "Padborg, Denmark",
  "Paddock Wood, United Kingdom",
  "Paderborn, Germany",
  "Padergnone, Italy",
  "Padernello, Italy",
  "Paderno, Italy",
  "Paderno d'Adda, Italy",
  "Paderno Dugnano, Italy",
  "Paderno Franciacorta, Italy",
  "Padiham, United Kingdom",
  "Padova, Italy",
  "Padre Garcia, Philippines",
  "Padron, Spain",
  "Paducah, United States",
  "Padul, Spain",
  "Paesana, Italy",
  "Paese, Italy",
  "Pagani, Italy",
  "Pagazzano, Italy",
  "Pagegiai, Lithuania",
  "Pageland, United States",
  "Pagham, United Kingdom",
  "Pagnacco, Italy",
  "Pagny le Chateau, France",
  "Pago Pago, American Samoa",
  "Pago Veiano, Italy",
  "Paignton, United Kingdom",
  "Paina, Italy",
  "Paine, Chile",
  "Paiporta, Spain",
  "Paisley, United Kingdom",
  "Paita, Peru",
  "Paitone, Italy",
  "Pajeczno, Poland",
  "Paju, Korea, South",
  "Palacaguina, Nicaragua",
  "Palacoulo, Portugal",
  "Palafolls, Spain",
  "Palagonia, Italy",
  "Palarikovo, Slovakia",
  "Palas de Rei, Spain",
  "Palau d'Anglesola, Spain",
  "Palau Plegamans, Spain",
  "Palazuelos de Eresma, Spain",
  "Palazzago, Italy",
  "Palazzo Pignano, Italy",
  "Palazzo S Gervasio, Italy",
  "Palazzolo Acreide, Italy",
  "Palazzolo Milanese, Italy",
  "Palazzolo sull'Oglio, Italy",
  "Palazzolo Vercellese, Italy",
  "Palembang, Indonesia",
  "Palencia, Spain",
  "Palermo, Italy",
  "Palhoca, Brazil",
  "Paliano, Italy",
  "Palidano, Italy",
  "Palin, Guatemala",
  "Palinges, France",
  "Palleja, Spain",
  "Pallini, Greece",
  "Palma Campania, Italy",
  "Palma de Gandia, Spain",
  "Palma del Condado, Spain",
  "Palma del Rio, Spain",
  "Palmanova, Italy",
  "Palmares, Costa Rica",
  "Palmdale, United States",
  "Palmela, Portugal",
  "Palmetto, United States",
  "Palmones, Spain",
  "Palmyra, United States",
  "Palo del Colle, Italy",
  "Palol de Revardit, Spain",
  "Palomares Del Rio, Spain",
  "Palombaio, Italy",
  "Palomonte, Italy",
  "Palos de la Frontera, Spain",
  "Palosco, Italy",
  "Palu', Italy",
  "Palwal, India",
  "Pamiers, France",
  "Pamplona, Spain",
  "Pamukova, Turkey",
  "Panabo City, Philippines",
  "Panagyurishte, Bulgaria",
  "Panama City, Panama",
  "Panama City, United States",
  "Panama City Beach, United States",
  "Panama Pacifico, Panama",
  "Pancalieri, Italy",
  "Pancevo, Serbia",
  "Pandacan, Philippines",
  "Pandino, Italy",
  "Panevezys, Lithuania",
  "Pangaon, Bangladesh",
  "Panipat, India",
  "Paniza, Spain",
  "Panjang Port, Indonesia",
  "Pannes, France",
  "Panningen, Netherlands",
  "Pantelimon, Romania",
  "Pantigliate, Italy",
  "Pantin, France",
  "Pantnagar, India",
  "Pantoja, Spain",
  "Panton, Spain",
  "Panvel, India",
  "Panzano, Italy",
  "Papa, Hungary",
  "Papantla, Mexico",
  "Papenburg, Germany",
  "Paphos, Cyprus",
  "Par Cornwall, United Kingdom",
  "Parabiago, Italy",
  "Paracuellos del Jarama, Spain",
  "Paradas, Spain",
  "Paradela, Spain",
  "Paragould, United States",
  "Paramaribo, Suriname",
  "Paranagua, Brazil",
  "Paranavai, Brazil",
  "Paratico, Italy",
  "Paray le Monial, France",
  "Parcay Meslay, France",
  "Parchim, Germany",
  "Parczew, Poland",
  "Pardubice, Czech Republic",
  "Paredes, Portugal",
  "Paredes de Nava, Spain",
  "Parempuyre, France",
  "Parets del Valles, Spain",
  "Pargny sur Saulx, France",
  "Parigne l'Eveque, France",
  "Paris, France",
  "Paris, United States",
  "Park Forest, United States",
  "Park Royal London, United Kingdom",
  "Parkersburg, United States",
  "Parksepa, Estonia",
  "Parla, Spain",
  "Parma, Italy",
  "Parndorf, Austria",
  "Parnu, Estonia",
  "Parona, Italy",
  "Parona di Valpolicella, Italy",
  "Parow, South Africa",
  "Parre, Italy",
  "Parrita, Costa Rica",
  "Parsdorf, Germany",
  "Parsippany, United States",
  "Parsons, United States",
  "Partanna, Italy",
  "Parthenay, France",
  "Partille, Sweden",
  "Partizanske, Slovakia",
  "Paruzzaro, Italy",
  "Parvomai, Bulgaria",
  "Pasadena, United States",
  "Pasaje - El Oro, Ecuador",
  "Pascagoula, United States",
  "Pasco, United States",
  "Pasian di Prato, Italy",
  "Pasiano, Italy",
  "Pasir Gudang, Malaysia",
  "Paskov, Czech Republic",
  "Passaic, United States",
  "Passau, Germany",
  "Passavant la Rochere, France",
  "Passel, France",
  "Passendale, Belgium",
  "Passirana, Italy",
  "Passirano, Italy",
  "Passons, Italy",
  "Passy, France",
  "Pastorano, Italy",
  "Pastoriza, Spain",
  "Pastrengo, Italy",
  "Paszto, Hungary",
  "Pataias, Portugal",
  "Patancheru, India",
  "Pataskala, United States",
  "Patensie, South Africa",
  "Paterna, Spain",
  "Paterno, Italy",
  "Paterson, United States",
  "Pathumthani, Thailand",
  "Patli, India",
  "Patornay, France",
  "Patos De Minas, Brazil",
  "Patparganj, India",
  "Patras, Greece",
  "Patrica, Italy",
  "Patterson, United States",
  "Patti, Italy",
  "Patuxent River, United States",
  "Paty, Hungary",
  "Pau, France",
  "Paulhaguet, France",
  "Paulhan, France",
  "Pausa, Germany",
  "Pavel Banya, Bulgaria",
  "Pavia, Italy",
  "Pavia di Udine, Italy",
  "Pavlikeni, Bulgaria",
  "Pavlograd, Ukraine",
  "Pavlov - Unhost, Czech Republic",
  "Pavone nel Mella, Italy",
  "Pavullo, Italy",
  "Pawtucket, United States",
  "Paynesville, Liberia",
  "PAYSANDU, Uruguay",
  "Pazardzhik, Bulgaria",
  "Pazos De Borben, Spain",
  "Peabody, United States",
  "Peachtree City, United States",
  "Peania, Greece",
  "Pearl River, United States",
  "Pearland, United States",
  "Pease Pottage, United Kingdom",
  "Peaugres, France",
  "Peccioli, Italy",
  "Pecem, Brazil",
  "Pechina, Spain",
  "Pecinci, Serbia",
  "Pecky, Czech Republic",
  "Pedavena, Italy",
  "Pedemonte di Valpollicella, Italy",
  "Pederobba, Italy",
  "Pedrajas de San Esteban, Spain",
  "Pedralba, Spain",
  "Pedreguer Alicante, Spain",
  "Pedregulho, Brazil",
  "Pedreiras, Portugal",
  "Pedrengo, Italy",
  "Pedrera, Spain",
  "Pedrezuela, Spain",
  "Pedrignano, Italy",
  "Pedro Munoz, Spain",
  "Pedrola, Spain",
  "Pedroso, Portugal",
  "Peebles, United Kingdom",
  "Peenya, India",
  "Pegli, Italy",
  "Pego, Spain",
  "Pegognaga, Italy",
  "Peine, Germany",
  "Peissenberg, Germany",
  "Peiting, Germany",
  "Pekan Nenas, Malaysia",
  "Pekanbaru, Indonesia",
  "PELABRAVO, Spain",
  "Pelhrimov, Czech Republic",
  "Pellegrue, France",
  "Pellezzano, Italy",
  "Pelussin, France",
  "Pembridge, United Kingdom",
  "Penafiel, Portugal",
  "Penafiel, Spain",
  "Penaflor, Spain",
  "Penagos, Spain",
  "Penang, Malaysia",
  "Penarth The Vale of Glamorgan, United Kingdom",
  "Pencader, United Kingdom",
  "Pencin, Czech Republic",
  "Pencoed, United Kingdom",
  "Pencran, France",
  "Pendalofos, Greece",
  "Pendergrass, United States",
  "Pendik, Turkey",
  "Penela da Beira, Portugal",
  "Peniche, Portugal",
  "Penicuik, United Kingdom",
  "Penistone, United Kingdom",
  "Penjamo, Mexico",
  "Penkridge, United Kingdom",
  "Penkule, Latvia",
  "Pennsauken, United States",
  "Penonome, Panama",
  "Penrhyndeudreath, United Kingdom",
  "Penrith, United Kingdom",
  "Penzance, United Kingdom",
  "Penzberg, Germany",
  "Peoria, United States",
  "Peosta, United States",
  "Pepino, Spain",
  "Perafita, Portugal",
  "Peraga, Italy",
  "Perai, Malaysia",
  "Peralta, Spain",
  "Peraseinajoki, Finland",
  "Perawang, Indonesia",
  "Percoto, Italy",
  "Perego, Italy",
  "Pereiro de Aguiar Orense, Spain",
  "Peremarton, Hungary",
  "Perena de la Ribera, Spain",
  "Pergine Valsugana, Italy",
  "Pergola, Italy",
  "Periban, Mexico",
  "Perignano, Italy",
  "Perigny Charente Maritime, France",
  "Peristeri, Greece",
  "Perl, Germany",
  "Perm, Russia",
  "Pernik, Bulgaria",
  "Pernis, Netherlands",
  "Pernumia, Italy",
  "Pero, Italy",
  "Pero di Breda di Piave, Italy",
  "Pero Pinheiro, Portugal",
  "Peronne Somme, France",
  "Perpignan, France",
  "Perrignier, France",
  "Perris, United States",
  "Perry, United States",
  "Perrysburg, United States",
  "Perrysville, United States",
  "Perryville, United States",
  "Persan, France",
  "Pershore, United Kingdom",
  "Persico Dosimo, Italy",
  "Perstorp, Sweden",
  "Perth, United Kingdom",
  "Perth Amboy, United States",
  "Pertuis, France",
  "Peru, United States",
  "Perugia, Italy",
  "Peruwelz, Belgium",
  "Pesaro, Italy",
  "Pescaglia, Italy",
  "Pescantina, Italy",
  "Pescara, Italy",
  "Pescate, Italy",
  "Peschadoires, France",
  "Peschiera Borromeo, Italy",
  "Peschiera del Garda, Italy",
  "Pescia, Italy",
  "Peseggia, Italy",
  "Peshtera, Bulgaria",
  "Peso da Regua, Portugal",
  "Pesquera de Duero, Spain",
  "Pesqueria, Mexico",
  "Pessac, France",
  "Pessano con Bornago, Italy",
  "Pessione, Italy",
  "Pestovo, Russia",
  "Petah Tiqva, Israel",
  "Petelos, Spain",
  "Peterborough, Canada",
  "Peterborough, United Kingdom",
  "Peterculter, United Kingdom",
  "Peterhead, United Kingdom",
  "Peterlee, United Kingdom",
  "Petersbach, France",
  "Petersfield, United Kingdom",
  "Petershagen (Weser), Germany",
  "Petfurdo-Gyartelep, Hungary",
  "Petit Fayt, France",
  "Petite Foret, France",
  "Petosino, Italy",
  "Petralia Sottana, Italy",
  "Petrer, Spain",
  "Petrich, Bulgaria",
  "Petrignano, Italy",
  "PETROPAVLOVSK-KAMCHATSKIY, Russia",
  "Petrov nad Desnou, Czech Republic",
  "Petrovice u Karvine, Czech Republic",
  "PETTENASCO, Italy",
  "Pettoranello, Italy",
  "Pettstadt, Germany",
  "Pevensey, United Kingdom",
  "Peveragno, Italy",
  "Peynier, France",
  "Peyruis, France",
  "Peza Crete, Greece",
  "Pezinok, Slovakia",
  "Pfaffenhausen (Schwab), Germany",
  "Pfaffenhofen (a d Ilm), Germany",
  "Pfaffing (a d Attel), Germany",
  "Pfaffschwende, Germany",
  "Pfarrkirchen, Germany",
  "Pfastatt, France",
  "Pfedelbach, Germany",
  "Pfinztal, Germany",
  "Pforzheim, Germany",
  "Pfullendorf (Baden), Germany",
  "Pfullingen, Germany",
  "Pfungen, Switzerland",
  "Pfungstadt, Germany",
  "Phalsbourg, France",
  "Pharr, United States",
  "Philadelphia, United States",
  "Philippsburg, Germany",
  "Phillipsburg, United States",
  "Phnom Penh, Cambodia",
  "PHOENIX, Mauritius",
  "PHOENIX, United States",
  "Phoenixville, United States",
  "Phra Khanong, Thailand",
  "Piacenza, Italy",
  "Piadena, Italy",
  "Piagge, Italy",
  "Pian Camuno, Italy",
  "Piancaldoli, Italy",
  "Piancogno, Italy",
  "Piandimeleto, Italy",
  "Pianella, Italy",
  "Pianengo, Italy",
  "Pianezza, Italy",
  "Pianezze, Italy",
  "Piangipane, Italy",
  "Pianico, Italy",
  "Piano di Coreglia, Italy",
  "Piano Tavola, Italy",
  "Pianodardine, Italy",
  "Pianopoli, Italy",
  "Pianoro, Italy",
  "Piantedo, Italy",
  "Pianzano, Italy",
  "Piasco, Italy",
  "Piaseczno, Poland",
  "Piastow, Poland",
  "Piateda, Italy",
  "Piavon di Oderzo, Italy",
  "Piazzola sul Brenta, Italy",
  "Pibrac, France",
  "Picassent, Spain",
  "Picayune, United States",
  "Pickering, United Kingdom",
  "Pico Rivera, United States",
  "Piechowice, Poland",
  "Piedimonte San Germano, Italy",
  "Piedimulera, Italy",
  "Piedmont, United States",
  "Piedras Negras, Mexico",
  "Piekary Slaskie, Poland",
  "Piekoszow, Poland",
  "Piera, Spain",
  "Pierantonio, Italy",
  "Pierre Benite, France",
  "Pierrefitte Nestalas, France",
  "Pierrefitte sur Seine, France",
  "Pierrelatte, France",
  "Pierrelaye, France",
  "Piesendorf, Austria",
  "Piesok, Slovakia",
  "Piesport, Germany",
  "Piestany, Slovakia",
  "Pietarsaari, Finland",
  "Pietermaritzburg, South Africa",
  "Pietramelara, Italy",
  "Pietramurata, Italy",
  "Pietrasanta, Italy",
  "Pietrowice Wielkie, Poland",
  "Pietrzykowice k Zywca, Poland",
  "Pieve a Nievole, Italy",
  "Pieve Belvicino, Italy",
  "Pieve d'Alpago, Italy",
  "Pieve di Cento, Italy",
  "Pieve di Curtarolo, Italy",
  "Pieve di Soligo, Italy",
  "Pieve di Teco, Italy",
  "Pieve Emanuele, Italy",
  "Pieve Fissiraga, Italy",
  "Pieve Porto Morone, Italy",
  "Pieve San Giacomo, Italy",
  "Pieve Santo Stefano, Italy",
  "Pievesestina, Italy",
  "Pignataro Maggiore, Italy",
  "Pigneto, Italy",
  "Pikermi, Greece",
  "Piketberg, South Africa",
  "Pila, Poland",
  "Pilar de la Horadada, Spain",
  "Pilas, Spain",
  "Pilas de Cangel, Costa Rica",
  "Pilawa, Poland",
  "Piles, Spain",
  "Pillichsdorf, Austria",
  "Pilzno, Poland",
  "Pilzone de Iseo, Italy",
  "Pimienta, Honduras",
  "Pimpri-Chinchwad, India",
  "Pina De Ebro, Spain",
  "Pinarbasi, Turkey",
  "Pin-Balma, France",
  "Pinchbeck, United Kingdom",
  "Pindorama, Brazil",
  "Pinellas Park, United States",
  "Pinerolo, Italy",
  "Pinetown, South Africa",
  "Pineuilh, France",
  "Pineville, United States",
  "Piney Flats, United States",
  "Pingree Grove, United States",
  "Pinneberg, Germany",
  "Pinner, United Kingdom",
  "Pinos Puente, Spain",
  "Pinoso, Spain",
  "Pinseque, Spain",
  "Pinsoro, Spain",
  "Pinto, Spain",
  "Pinxton, United Kingdom",
  "Piobesi Torinese, Italy",
  "Pioltello, Italy",
  "Piombino, Italy",
  "Piombino di Dese, Italy",
  "Pionki, Poland",
  "Piossasco, Italy",
  "Piotrkow Trybunalski, Poland",
  "Piova' Massaia, Italy",
  "Piove di Sacco, Italy",
  "Piovene Rocchette, Italy",
  "Pipavav, India",
  "Piqua, United States",
  "Piracicaba, Brazil",
  "Piraeus, Greece",
  "Pirey, France",
  "Pirk, Germany",
  "Pirmasens, Germany",
  "Pisa, Italy",
  "Piscataway, United States",
  "Pischelsdorf am Engelbach, Austria",
  "Pisco, Peru",
  "Pisek, Czech Republic",
  "Pisogne, Italy",
  "Pissonas, Greece",
  "Pisticci, Italy",
  "Pistoia, Italy",
  "Pital, Costa Rica",
  "Pitesti, Romania",
  "Pithampur, India",
  "Pithiviers, France",
  "PITILLAS, Spain",
  "Pitomaca, Croatia",
  "Pittsburgh, United States",
  "Pittsfield, United States",
  "Pivdennyi, Ukraine",
  "Piyala, India",
  "Pizarra, Spain",
  "Pizzighettone, Italy",
  "Plaidt, Germany",
  "Plainfield, United States",
  "Plainsboro, United States",
  "Plaintel, France",
  "Plainview, United States",
  "Plaisir, France",
  "Plana nad Luznici, Czech Republic",
  "Plana u Marianskych Lazni, Czech Republic",
  "Planina pri Sevnici, Slovenia",
  "Plankstadt, Germany",
  "Plano, United States",
  "Plant City, United States",
  "Plantersville, United States",
  "Plaquemine, United States",
  "Plasencia, Spain",
  "Plati Imathia, Greece",
  "Plattling, Germany",
  "Plattsville, Canada",
  "Plauen, Germany",
  "Pleasant Prairie, United States",
  "Plechatel, France",
  "Pleidelsheim, Germany",
  "Plemet, France",
  "Plerin, France",
  "Pleteny Ujezd, Czech Republic",
  "Plettenberg, Germany",
  "Pleubian, France",
  "Pleucadeuc, France",
  "Pleudaniel, France",
  "Pleven, Bulgaria",
  "Plewiska, Poland",
  "Pleystein, Germany",
  "Plobsheim, France",
  "Ploce, Croatia",
  "Plochingen, Germany",
  "Plochocin, Poland",
  "Plock, Poland",
  "Ploemeur, France",
  "Ploermel, France",
  "Ploiesti, Romania",
  "Ploisy, France",
  "Plombieres, Belgium",
  "Plonsk, Poland",
  "Ploudalmezeau, France",
  "Ploudaniel, France",
  "Plouedern, France",
  "Plouhinec Finistere, France",
  "Ploumagoar, France",
  "Plovdiv, Bulgaria",
  "Pluederhausen, Germany",
  "Pluguffan, France",
  "Plumlov, Czech Republic",
  "Pluvigner, France",
  "Plymouth, United Kingdom",
  "Plymouth, United States",
  "Plympton, United Kingdom",
  "Plzen, Czech Republic",
  "Pniewy, Poland",
  "Pobiedziska, Poland",
  "Pobla De Claramunt, Spain",
  "Pobla de Vallbona Valencia, Spain",
  "Pobla Llarga, Spain",
  "Pobla Tornesa, Spain",
  "Pocahontas, United States",
  "Poce Sur Cisse, France",
  "Poceirao, Portugal",
  "Pocenia, Italy",
  "Pocking, Germany",
  "Pocklington, United Kingdom",
  "Pocomoke City, United States",
  "Podborany, Czech Republic",
  "Podbrezova, Slovakia",
  "Podebrady, Czech Republic",
  "Podenzano, Italy",
  "Podgrad, Slovenia",
  "Podivin, Czech Republic",
  "Podleze, Poland",
  "Podolsk, Russia",
  "Podravska Slatina, Croatia",
  "Poeldijk, Netherlands",
  "Poelzig, Germany",
  "Poesing, Germany",
  "Poessneck, Germany",
  "Poettelsdorf, Austria",
  "Poettmes, Germany",
  "Pofi, Italy",
  "Poggibonsi, Italy",
  "Poggio Berni, Italy",
  "Poggio Imperiale, Italy",
  "Poggio Rusco, Italy",
  "Poggiorsini, Italy",
  "Pogliano Milanese, Italy",
  "Pognano, Italy",
  "Pogno, Italy",
  "Pohang, Korea, South",
  "Pohlheim, Germany",
  "Pohorelice u Brna, Czech Republic",
  "Poiano Valpantena, Italy",
  "Poigny, France",
  "Poilly-Lez-Gien, France",
  "Poing, Germany",
  "Point, South Africa",
  "Point Edward, Canada",
  "Point Lisas, Trinidad and Tobago",
  "Pointe a Pitre, Guadeloupe",
  "Pointe Noire, Congo",
  "Pointe-Claire, Canada",
  "Poirino, Italy",
  "Poisy, France",
  "Poitiers, France",
  "Pojana Maggiore, Italy",
  "Polanco, Spain",
  "Polaniec, Poland",
  "Polaveno, Italy",
  "Polcenigo, Italy",
  "Polch, Germany",
  "Polczyn Zdroj, Poland",
  "Polegate, United Kingdom",
  "Polesine Parmense, Italy",
  "Policka, Czech Republic",
  "Poligny, France",
  "Polinya, Spain",
  "Polistena, Italy",
  "Poliyiros, Greece",
  "Polkowice, Poland",
  "Polla, Italy",
  "Pollenza, Italy",
  "Pollenzo, Italy",
  "Polling (Kr Muehldorf am Inn), Germany",
  "Pollington, United Kingdom",
  "Polna u Jihlavy, Czech Republic",
  "Polomka, Slovakia",
  "Polonghera, Italy",
  "Poltar, Slovakia",
  "Poltava, Ukraine",
  "Poltsamaa, Estonia",
  "Polverigi, Italy",
  "Polykastro, Greece",
  "Pomaz, Hungary",
  "Pombal, Portugal",
  "Pombia, Italy",
  "Pomeys, France",
  "Pomezia, Italy",
  "Pomigliano d'Arco, Italy",
  "Pommelsbrunn, Germany",
  "Pommeret, France",
  "Pommeuse, France",
  "Pomona, South Africa",
  "Pomona, United States",
  "Pomorie, Bulgaria",
  "Pompano Beach, United States",
  "Pompei, Italy",
  "Pompey, France",
  "Pompiano, Italy",
  "Pomponesco, Italy",
  "Pomposa, Italy",
  "Poncarale, Italy",
  "Ponchon, France",
  "Ponders End, United Kingdom",
  "Ponferrada, Spain",
  "Ponneri, India",
  "Ponso, Italy",
  "Pont a Mousson, France",
  "Pont de Cheruy, France",
  "Pont de L'arche, France",
  "Pont de l'Isere, France",
  "Pont de Veyle, France",
  "Pont Du Casse, France",
  "Pont Eveque, France",
  "Pont Saint Martin, France",
  "Pont Saint Pierre, France",
  "Pont Ste Marie, France",
  "Pont Ste Maxence, France",
  "Ponta Grossa, Brazil",
  "Pontarlier, France",
  "Pontassieve, Italy",
  "Pontcharra, France",
  "Pontcharra Sur Turdine, France",
  "Pontchateau, France",
  "Ponte, Portugal",
  "Ponte a Cappiano, Italy",
  "Ponte a Egola, Italy",
  "Ponte Buggianese, Italy",
  "Ponte Caldelas, Spain",
  "Ponte di Barbarano, Italy",
  "Ponte di Mossano, Italy",
  "Ponte di Nanto, Italy",
  "Ponte di Piave, Italy",
  "Ponte Lambro, Italy",
  "Ponte Nelle Alpi, Italy",
  "Ponte Nossa, Italy",
  "Ponte San Giovanni, Italy",
  "Ponte San Marco, Italy",
  "Ponte San Nicolo, Italy",
  "Ponte San Pietro, Italy",
  "Ponte Valleceppi, Italy",
  "Ponte Zanano, Italy",
  "Ponteareas, Spain",
  "Pontecchio, Italy",
  "Pontecesures, Spain",
  "Pontecurone, Italy",
  "Pontedassio, Italy",
  "Pontedera, Italy",
  "Pontedeume, Spain",
  "Pontefract, United Kingdom",
  "Pontelangorino, Italy",
  "Pontelongo, Italy",
  "Pontenure, Italy",
  "Ponteranica, Italy",
  "PONTES GARCIA RODRIGUEZ, Spain",
  "Pontevedra, Spain",
  "Pontevico, Italy",
  "Ponti sul Mincio, Italy",
  "Pontiac, United States",
  "Pontianak, Indonesia",
  "Ponticelli, Italy",
  "Pontida, Italy",
  "Pontinia, Italy",
  "Pontirolo Nuovo, Italy",
  "Pontivy, France",
  "Pontotoc, United States",
  "Pontrieux, France",
  "Pontyclun, United Kingdom",
  "Pontypool, United Kingdom",
  "Pontypridd, United Kingdom",
  "Ponzano Veneto, Italy",
  "Ponzone, Italy",
  "Poole Dorset, United Kingdom",
  "Pooler, United States",
  "Pope Air Force Base, United States",
  "Poperinge, Belgium",
  "Popesti-Leordeni, Romania",
  "Poplar Bluff, United States",
  "Popovo, Bulgaria",
  "Poppendorf (bei Rostock), Germany",
  "Poppenhausen (Wasserkuppe), Germany",
  "Poprad, Slovakia",
  "Popuvky, Czech Republic",
  "Porbandar, India",
  "Porcari, Italy",
  "Porceyo, Spain",
  "Porcia, Italy",
  "Pordenone, Italy",
  "Poreba - Pszczyna, Poland",
  "Pori, Finland",
  "Porici nad Sazavou, Czech Republic",
  "Porqueres, Spain",
  "Porretta Terme, Italy",
  "Porrino, Spain",
  "Porsgrunn, Norway",
  "Port, France",
  "Port Allen, United States",
  "Port Chalmers, New Zealand",
  "Port Clinton, United States",
  "Port Elizabeth, South Africa",
  "Port Everglades, United States",
  "Port Glasgow, United Kingdom",
  "Port Hueneme, United States",
  "Port Huron, United States",
  "Port Klang, Malaysia",
  "Port Lincoln, Australia",
  "Port Louis, Mauritius",
  "Port Moresby, Papua New Guinea",
  "Port Noro, Solomon Islands",
  "Port of Big Creek, Belize",
  "Port Qasim, Pakistan",
  "Port Rashid, United Arab Emirates",
  "Port Reunion, Reunion",
  "Port Said East, Egypt",
  "Port Said West, Egypt",
  "Port Saint Louis du Rhone, France",
  "Port Shepstone, South Africa",
  "Port Sudan, Sudan",
  "Port Sunlight, United Kingdom",
  "Port Talbot, United Kingdom",
  "Port Tangier Mediterranee, Morocco",
  "Port Washington, United States",
  "Porta Westfalica, Germany",
  "Portadown Northern Ireland, United Kingdom",
  "Portage, United States",
  "Portalbera, Italy",
  "Portaria Khalkidhiki, Greece",
  "Port-au-Prince, Haiti",
  "Portbury, United Kingdom",
  "Porte, Italy",
  "Porter, United States",
  "Portes les Valence, France",
  "Portet sur Garonne, France",
  "Porthcawl, United Kingdom",
  "Portillo de Toledo, Spain",
  "Portillo Valladolid, Spain",
  "Portland, United States",
  "Portland Dorset, United Kingdom",
  "Portlaoise Laois IE, Ireland",
  "Portlethen, United Kingdom",
  "Porto, Portugal",
  "Porto de Mos, Portugal",
  "Porto Mantovano, Italy",
  "Porto Marghera, Italy",
  "Porto Potenza Picena, Italy",
  "Porto Recanati, Italy",
  "Porto Viro, Italy",
  "Portobuffole, Italy",
  "Portogruaro, Italy",
  "Portomaggiore, Italy",
  "Portsmouth, United Kingdom",
  "Portsmouth, United States",
  "Portsoy, United Kingdom",
  "Porvoo, Finland",
  "Possagno, Italy",
  "Postalesio, Italy",
  "Postbauer-Heng, Germany",
  "Postioma, Italy",
  "Postrelmov, Czech Republic",
  "Potenza, Italy",
  "Potenza Picena, Italy",
  "Poti, Georgia",
  "Potosi, Nicaragua",
  "Potrerillos, Honduras",
  "Potsdam, Germany",
  "Potters Bar, United Kingdom",
  "Potuga, Panama",
  "Pouilly en Auxois, France",
  "Poulton-le-Fylde, United Kingdom",
  "Pouxeux, France",
  "Pouzauges, France",
  "Povazany, Slovakia",
  "Povazska Bystrica, Slovakia",
  "Pove del Grappa, Italy",
  "Povegliano Veronese, Italy",
  "Poviglio, Italy",
  "Povina, Slovakia",
  "Povoa de Lanhoso, Portugal",
  "Povoa de Santa Iria, Portugal",
  "Povolaro, Italy",
  "Povoletto, Italy",
  "Povrly, Czech Republic",
  "Poway, United States",
  "Poznan, Poland",
  "Pozo Estrecho, Spain",
  "Pozoblanco, Spain",
  "Pozohondo, Spain",
  "Pozuelo Alarcon, Spain",
  "Pozuelo De Calatrava, Spain",
  "Pozzaglio, Italy",
  "Pozzilli, Italy",
  "Pozzo d'Adda, Italy",
  "Pozzolengo, Italy",
  "Pozzoleone, Italy",
  "Pozzolo Formigaro, Italy",
  "Pozzonovo, Italy",
  "Pozzuolo del Friuli, Italy",
  "Pozzuolo Martesana, Italy",
  "Prachatice, Czech Republic",
  "Prachinburi, Thailand",
  "Pradamano, Italy",
  "Pradejon, Spain",
  "Pradilla de Ebro, Spain",
  "Pradoluengo Burgos, Spain",
  "Praha, Czech Republic",
  "Prahecq, France",
  "Prai, Malaysia",
  "Praia, Cape Verde Island",
  "Prakovce, Slovakia",
  "Pramaggiore, Italy",
  "Prambachkirchen, Austria",
  "Prat de Llobregat, Spain",
  "Prata Camportaccio, Italy",
  "Prata di Pordenone, Italy",
  "Prata di Principato Ultra, Italy",
  "Pratissolo, Italy",
  "Prato, Italy",
  "Prato Allo Stelvio, Italy",
  "Prato Sesia, Italy",
  "Pratovecchio, Italy",
  "Prats de Llucanes, Spain",
  "Pratz, France",
  "Pravenec, Slovakia",
  "Pravisdomini, Italy",
  "Pre en Pail, France",
  "Precy sur Oise, France",
  "Predaia, Italy",
  "Predazzo, Italy",
  "Predmier, Slovakia",
  "Prees Shropshire SY, United Kingdom",
  "Preganziol, Italy",
  "Pregnana Milanese, Italy",
  "Preili, Latvia",
  "Prejmer, Romania",
  "Prelog, Croatia",
  "Prelouc, Czech Republic",
  "Premariacco, Italy",
  "Premia de Dalt, Spain",
  "Premia de Mar, Spain",
  "Premnitz, Germany",
  "Premosello, Italy",
  "Prenzlau, Germany",
  "Prerov, Czech Republic",
  "Prescot, United Kingdom",
  "Presezzo, Italy",
  "Preslav, Bulgaria",
  "Presov, Slovakia",
  "Pressana, Italy",
  "Pressath, Germany",
  "Pressig, Germany",
  "Prestanov, Czech Republic",
  "Prestice, Czech Republic",
  "Preston Lancs, United Kingdom",
  "Prestranek, Slovenia",
  "Prestwich, United Kingdom",
  "Pretoria, South Africa",
  "Pretoria City Centre, South Africa",
  "Pretoria Gardens, South Africa",
  "Pretoria West, South Africa",
  "Preussisch Oldendorf, Germany",
  "Prevalle, Italy",
  "Prevessin Moens, France",
  "Pribenik, Slovakia",
  "Pribor, Czech Republic",
  "Pribram, Czech Republic",
  "Pribyslav, Czech Republic",
  "Prichsenstadt, Germany",
  "Priego, Spain",
  "Priego de Cordoba, Spain",
  "Prienai, Lithuania",
  "Prievidza, Slovakia",
  "Prignano sulla Secchia, Italy",
  "Prilep, Macedonia",
  "Primaluna, Italy",
  "Prince George, Canada",
  "Prince Rupert, Canada",
  "Princeton, United States",
  "Pringy, France",
  "Priocca, Italy",
  "Priolo Gargallo, Italy",
  "Priozersk, Russia",
  "Pritzwalk, Germany",
  "Privas, France",
  "Priverno, Italy",
  "Prnjavor, Bosnia and Herzegovina",
  "Progreso, Mexico",
  "Promissao, Brazil",
  "Pronsfeld, Germany",
  "Prosotsani, Greece",
  "Prospecton, South Africa",
  "Prosperity, United States",
  "Prossedi, Italy",
  "Prostejov, Czech Republic",
  "Protivin, Czech Republic",
  "Prouville, France",
  "Prouvy, France",
  "Provadia, Bulgaria",
  "Provaglio d'Iseo, Italy",
  "Provins, France",
  "Prudhoe, United Kingdom",
  "Pruem, Germany",
  "Pruhonice, Czech Republic",
  "Prundu, Romania",
  "Prusice, Poland",
  "Pruszkow, Poland",
  "Prutting, Germany",
  "Przasnysz, Poland",
  "Przeclaw, Poland",
  "Przemysl, Poland",
  "Przeworsk, Poland",
  "Przezmierowo, Poland",
  "Przysieka Stara, Poland",
  "Przysieki, Poland",
  "Przytoczna, Poland",
  "Pszczolki, Poland",
  "Pszczyna, Poland",
  "Pteni, Czech Republic",
  "PTUJSKA GORA, Slovenia",
  "Publier, France",
  "Puceul, France",
  "Puchheim, Germany",
  "Puchov, Slovakia",
  "Pucklechurch, United Kingdom",
  "Pucol, Spain",
  "Pudong Downtown, China",
  "Pudsey, United Kingdom",
  "Puebla de Alborton, Spain",
  "Puebla de Almoradiel, Spain",
  "Puebla de Cazalla, Spain",
  "Puebla de la Calzada, Spain",
  "Puebla de la Parrilla, Spain",
  "Puebla Mexico, Mexico",
  "Puebla Montalban, Spain",
  "Pueblonuevo Del Guadiana, Spain",
  "Puegnago sul Garda, Italy",
  "Puente del Obispo, Spain",
  "Puente Genil, Spain",
  "Puente la Reina, Spain",
  "Puente Mayorga, Spain",
  "Puente San Miguel, Spain",
  "Puente Tocinos, Spain",
  "Puerto Barrios, Guatemala",
  "Puerto Bolivar - El Oro, Ecuador",
  "Puerto Cabello, Venezuela",
  "Puerto Caldera, Costa Rica",
  "Puerto Chacabuco, Chile",
  "Puerto Cortes, Honduras",
  "Puerto de Sagunto, Spain",
  "Puerto de Santa Maria, Spain",
  "Puerto Deseado, Argentina",
  "Puerto Limon, Costa Rica",
  "Puerto Lumbreras, Spain",
  "Puerto Madero, Mexico",
  "Puerto Madryn, Argentina",
  "Puerto Moin, Costa Rica",
  "Puerto Montt, Chile",
  "Puerto Octay, Chile",
  "Puerto Plata, Dominican Republic",
  "Puerto Quetzal, Guatemala",
  "Puerto Real, Spain",
  "Puerto Varas, Chile",
  "Puertollano, Spain",
  "Puig, Spain",
  "Puigdalber, Spain",
  "Puigpelat, Spain",
  "Puja, Italy",
  "Pula, Croatia",
  "Pulaski, United States",
  "Pulawy, Poland",
  "Pulborough, United Kingdom",
  "Pulheim, Germany",
  "Pulianas, Spain",
  "Pullach, Germany",
  "Pullenreuth, Germany",
  "Pulpi, Spain",
  "Pulsnitz, Germany",
  "Pune, India",
  "Punsk, Poland",
  "Punta Arenas, Chile",
  "Punta Gorda, United States",
  "Puntarenas, Costa Rica",
  "Purchena, Spain",
  "Pure, Latvia",
  "Purfleet, United Kingdom",
  "PURISIMA DEL RINCON, Mexico",
  "Purley, United Kingdom",
  "Purmerend, Netherlands",
  "Purvis, United States",
  "Puschwitz, Germany",
  "Pusignan, France",
  "Pussi, Estonia",
  "Pustkow, Poland",
  "Pusztamonostor, Hungary",
  "Puteaux, France",
  "Putian, China",
  "Putignano, Italy",
  "Puurs, Belgium",
  "Puxeiros PONTEVEDRA, Spain",
  "Puy Guillaume, France",
  "Puyallup, United States",
  "Puyoo Bellocq Ramous, France",
  "Pwllheli, United Kingdom",
  "Pyhasalmi, Finland",
  "Pyhtaa, Finland",
  "Pyle Bridgend, United Kingdom",
  "Pyong Taek, Korea, South",
  "Pyrzyce, Poland",
  "Pyskowice, Poland",
  "Paarden Eiland, South Africa",
  "Paarl, South Africa",
  "Paarl Wpk, South Africa",
  "Qapco, Qatar",
  "Qaradag, Azerbaijan",
  "Qatalum, Qatar",
  "Qchem, Qatar",
  "Qingdao, China",
  "Qingyuan, China",
  "Qinhuangdao, China",
  "Qinzhou, China",
  "Qiryat Shemona, Israel",
  "Quakenbruck, Germany",
  "Quakertown, United States",
  "Quarrata, Italy",
  "Quart de Poblet, Spain",
  "Quartell, Spain",
  "Quarto, Italy",
  "Quarto d'Altino, Italy",
  "Quatre-Champs, France",
  "Quatretonda, Spain",
  "Quatro Barras, Brazil",
  "Quattordio, Italy",
  "Quattro Castella, Italy",
  "Quebec, Canada",
  "Quedgeley, United Kingdom",
  "Queenborough Kent, United Kingdom",
  "Queensferry, United Kingdom",
  "Quel La Rioja, Spain",
  "Quemchi, Chile",
  "Quer, Spain",
  "Querceta, Italy",
  "Queretaro, Mexico",
  "Quero, Italy",
  "Quesnoy sur Deule, France",
  "Quetigny, France",
  "Quetzaltenango, Guatemala",
  "Quevedo - Los Rios, Ecuador",
  "Queven, France",
  "Qui Nhon, Vietnam",
  "Quickborn (Pinneberg), Germany",
  "Quillon, Chile",
  "Quimper, France",
  "Quimperle, France",
  "Quinche - Pichincha, Ecuador",
  "Quincie En Beaujolais, France",
  "Quincy, United States",
  "Quincy Voisins, France",
  "Quintana de la Serena, Spain",
  "Quintanar de la Orden, Spain",
  "Quintanar De La Sierra, Spain",
  "Quintanar del Rey, Spain",
  "Quintanilla Sobresierra, Spain",
  "Quintano, Italy",
  "Quinto, Spain",
  "Quinto di Treviso, Italy",
  "Quinto di Valpantena, Italy",
  "Quinto Vicentino, Italy",
  "Quinzano d'Oglio, Italy",
  "Quistello, Italy",
  "Quito - Pichincha, Ecuador",
  "Rabastens, France",
  "Rabastens de Bigorre, France",
  "Rabat, Morocco",
  "Rabaul, Papua New Guinea",
  "Rabigh, Saudi Arabia",
  "Rabka Zdroj, Poland",
  "Racalmas, Hungary",
  "Raciaz, Poland",
  "Raciborowice Gorne, Poland",
  "Raciborz, Poland",
  "Racice, Czech Republic",
  "Racine, United States",
  "Rackwitz, Germany",
  "Radcliffe Bury, United Kingdom",
  "Radeberg, Germany",
  "Radebeul, Germany",
  "Radece, Slovenia",
  "Radevormwald, Germany",
  "Radibor, Germany",
  "Radicofani, Italy",
  "Radinovo, Bulgaria",
  "Radlett, United Kingdom",
  "Radlje Ob Dravi, Slovenia",
  "Radnevo, Bulgaria",
  "Radnice u Plzne, Czech Republic",
  "Radolfzell (Am Bodensee), Germany",
  "Radom, Poland",
  "Radomir, Bulgaria",
  "Radomsko, Poland",
  "Radonice, Czech Republic",
  "Radovis, Macedonia",
  "Radstock, United Kingdom",
  "Radvilishkis, Lithuania",
  "RADYMNO, Poland",
  "Radziejowice, Poland",
  "Radzionkow, Poland",
  "Radzymin, Poland",
  "Radzyn Podlaski, Poland",
  "Raeren, Belgium",
  "Raesfeld, Germany",
  "Rafelbunol, Spain",
  "Ragusa, Italy",
  "Raguva, Lithuania",
  "Rahden, Germany",
  "Raillencourt Sainte Olle, France",
  "Rainford, United Kingdom",
  "Rainham Essex, United Kingdom",
  "Rainham Kent, United Kingdom",
  "Raipur, India",
  "Raismes, France",
  "Rajamaki, Finland",
  "Rajec, Slovakia",
  "Rajec Jestrebi, Czech Republic",
  "Rajecke Teplice, Slovakia",
  "Rajhrad u Brna, Czech Republic",
  "Rajnochovice, Czech Republic",
  "Rakamaz, Hungary",
  "Rakkestad, Norway",
  "Rakova, Slovakia",
  "Rakovnik, Czech Republic",
  "Rakovski, Bulgaria",
  "Rakszawa, Poland",
  "Rakvere, Estonia",
  "Raleigh, United States",
  "Rama, Nicaragua",
  "Ramacca, Italy",
  "Ramales de la Victoria Cantabria, Spain",
  "Ramat Hovav, Israel",
  "RAMLA, Israel",
  "Rammingen - Wuertt, Germany",
  "Rammingen (Schwab), Germany",
  "Ramon, Italy",
  "Ramos Arizpe, Mexico",
  "Rampton Nottinghamshire, United Kingdom",
  "Ramsbottom, United Kingdom",
  "Ramsey, United States",
  "Ramsey Cambridgeshire, United Kingdom",
  "Ramsey Essex, United Kingdom",
  "Ramsgate Kent, United Kingdom",
  "Ramsi, Estonia",
  "Ramstein-Miesenbach, Germany",
  "Rancagua, Chile",
  "Rancheria, Nicaragua",
  "Rancho Cordova, United States",
  "Rancho Cucamonga, United States",
  "Rancho Dominguez, United States",
  "Randaberg, Norway",
  "Randalstown Northern Ireland, United Kingdom",
  "Randboel, Denmark",
  "Randburg, South Africa",
  "Randers, Denmark",
  "Randers SV, Denmark",
  "Randfontein, South Africa",
  "Randleman, United States",
  "Randolph, United States",
  "Ranes, France",
  "Rangsdorf, Germany",
  "Ranica, Italy",
  "Ranjangaon, India",
  "Rankweil, Austria",
  "Ranlo, United States",
  "Ransbach-Baumbach, Germany",
  "Ranst, Belgium",
  "Raon l'Etape, France",
  "Rapid City, United States",
  "Rapina, Estonia",
  "Rapla, Estonia",
  "Rapolano Terme, Italy",
  "Rapperswil-Jona, Switzerland",
  "Ra's Al Khaimah, United Arab Emirates",
  "Ras Al Khair, Saudi Arabia",
  "Rasdorf, Germany",
  "Raseiniai, Lithuania",
  "Raskovice, Czech Republic",
  "Raspenava, Czech Republic",
  "Rastatt, Germany",
  "Rastede, Germany",
  "Rastenfeld, Austria",
  "Rastignano, Italy",
  "Ratchaburi, Thailand",
  "Ratekau, Germany",
  "Rathcoole Dublin, Ireland",
  "Rathenow, Germany",
  "Rathnew Wicklow, Ireland",
  "Ratingen, Germany",
  "Ratlam, India",
  "Ratoma, Guinea",
  "Raubling, Germany",
  "Raudeberg, Norway",
  "Rauhenebrach, Germany",
  "Rauma, Finland",
  "Raunds Northamptonshire, United Kingdom",
  "Raunheim, Germany",
  "Rauscedo, Italy",
  "Rauschenberg, Germany",
  "Ravadese, Italy",
  "Ravarino, Italy",
  "Ravenna, Italy",
  "Ravenna, United States",
  "Ravensburg, Germany",
  "Ravensthorpe Yorksh, United Kingdom",
  "Ravieres, France",
  "Ravina, Italy",
  "Ravne na Koroskem, Slovenia",
  "Rawa Mazowiecka, Poland",
  "Rawtenstall, United Kingdom",
  "Rayleigh, United Kingdom",
  "Rayong, Thailand",
  "Razgrad, Bulgaria",
  "Reading, United Kingdom",
  "Reading, United States",
  "Real de Gandia, Spain",
  "Real de Montroi, Spain",
  "Reana del Roiale, Italy",
  "Rebais, France",
  "Rebordoes, Portugal",
  "Rebordosa, Portugal",
  "Recanati, Italy",
  "Recany nad Labem, Czech Republic",
  "Rechnitz, Austria",
  "Recklinghausen, Germany",
  "Recogne, Belgium",
  "Recy, France",
  "Red Lion, United States",
  "Red Lodge Suffolk IP, United Kingdom",
  "Redavalle, Italy",
  "Redcar, United Kingdom",
  "Reddish, United Kingdom",
  "Redditch, United Kingdom",
  "Redecesio, Italy",
  "Redene, France",
  "Redford, United States",
  "Redhill Surrey, United Kingdom",
  "Reding, France",
  "Redlands, United States",
  "Redondela, Spain",
  "Redondesco, Italy",
  "Redovan, Spain",
  "Redruth, United Kingdom",
  "Redstone Arsenal, United States",
  "Redzikowo, Poland",
  "Reedsville, United States",
  "Refrontolo, Italy",
  "Regalbuto, Italy",
  "Regau, Austria",
  "Regen, Germany",
  "Regensburg, Germany",
  "Regensdorf, Switzerland",
  "Regenstauf, Germany",
  "Reggello, Italy",
  "Reggio di Calabria, Italy",
  "Reggio Emilia, Italy",
  "Reggiolo, Italy",
  "Reghin, Romania",
  "Regina, Canada",
  "Reguengos de Monsaraz, Portugal",
  "Rehau, Germany",
  "Rehburg-Loccum, Germany",
  "Reichartshausen, Germany",
  "Reichenau (Baden), Germany",
  "Reichenbach (an der Fils), Germany",
  "Reichenbach (Thuringia), Germany",
  "Reichenbach / Vogtland, Germany",
  "Reichenbach am Heuberg, Germany",
  "Reichenberg (Unterfr), Germany",
  "Reichenschwand, Germany",
  "Reichertsheim, Germany",
  "Reichertshofen, Germany",
  "Reichshof, Germany",
  "Reichshoffen, France",
  "Reichstett, France",
  "Reiden, Switzerland",
  "Reidsville, United States",
  "Reigate, United Kingdom",
  "Reignac, France",
  "Reignac sur Indre, France",
  "Reims, France",
  "Reinach, Switzerland",
  "Reinbek, Germany",
  "Reinfeld (Holstein), Germany",
  "Reinheim, Germany",
  "Reinosa, Spain",
  "Reiskirchen (Wieseck), Germany",
  "Reit im Winkl, Germany",
  "Reken, Germany",
  "Rekingen, Switzerland",
  "Rekkem, Belgium",
  "Remagen, Germany",
  "Remalle, India",
  "Remanzacco, Italy",
  "Remedello, Italy",
  "Remington, United States",
  "Remiremont, France",
  "Remolinos, Spain",
  "Remoulins, France",
  "Remscheid, Germany",
  "Remshalden, Germany",
  "Remus, United States",
  "Renaison, France",
  "Renate, Italy",
  "Renazzo, Italy",
  "Renchen, Germany",
  "Rendalen, Norway",
  "Rende, Italy",
  "Rendlesham Suffolk, United Kingdom",
  "Rendsburg, Germany",
  "Renedo de Pielagos, Spain",
  "Renfrew, United Kingdom",
  "Rengsdorf, Germany",
  "Renkum, Netherlands",
  "Rennertshofen, Germany",
  "Rennes, France",
  "Renningen, Germany",
  "Reno, United States",
  "Renswoude, Netherlands",
  "Renteria Errenteria, Spain",
  "Rentis, Greece",
  "Renton, United States",
  "Reocin, Spain",
  "Reola, Estonia",
  "Replonges, France",
  "Requena, Spain",
  "Requinoa, Chile",
  "Resaca, United States",
  "Resana, Italy",
  "Rescaldina, Italy",
  "Reschigliano, Italy",
  "Resen, Macedonia",
  "Reserve, United States",
  "Resita, Romania",
  "Ressons sur Matz, France",
  "Retalhuleu, Guatemala",
  "Retford, United Kingdom",
  "Rethimnon, Greece",
  "Retiers, France",
  "Retournac, France",
  "Retreat, South Africa",
  "Retsag, Hungary",
  "Rettendon, United Kingdom",
  "Reus, Spain",
  "Reuthe, Austria",
  "Reutlingen, Germany",
  "Revel, France",
  "Revello, Italy",
  "Reventin Vaugris, France",
  "Revere, Italy",
  "Revere, United States",
  "Revilla de Camargo, Spain",
  "Revo, Italy",
  "Revuca, Slovakia",
  "Rewari, India",
  "Rexdale, Canada",
  "Reynosa, Mexico",
  "Reyrieux, France",
  "Reze, France",
  "Rezekne, Latvia",
  "Rezzato, Italy",
  "Rheda-Wiedenbrueck, Germany",
  "Rhede (Westf), Germany",
  "Rheinau (Baden), Germany",
  "Rheinbach, Germany",
  "Rheinberg, Germany",
  "Rheinboellen, Germany",
  "Rheine, Germany",
  "Rheinfelden, Germany",
  "Rheinfelden, Switzerland",
  "Rheinmuenster, Germany",
  "Rheinsberg, Germany",
  "Rheinstetten, Germany",
  "Rho, Italy",
  "Rhome, United States",
  "Rhondda, United Kingdom",
  "Rhosesmor, United Kingdom",
  "Rhyl, United Kingdom",
  "Rhymney, United Kingdom",
  "RIACHOS, Portugal",
  "Rialto, United States",
  "Riardo, Italy",
  "Ribadavia, Spain",
  "Ribadelouro, Spain",
  "Ribadumia, Spain",
  "Ribaforada, Spain",
  "Riba-roja del Turia, Spain",
  "Ribaseca, Spain",
  "Ribe, Denmark",
  "Ribecourt Dreslincourt, France",
  "Ribeira, Spain",
  "Ribeirao, Portugal",
  "Ribnica, Slovenia",
  "Ricany u Brna, Czech Republic",
  "Ricany u Prahy, Czech Republic",
  "Riceboro, United States",
  "Ricengo, Italy",
  "Richardson, United States",
  "Richibucto, Canada",
  "Richland, United States",
  "Richmond, Canada",
  "Richmond, United States",
  "Richmond North Yorkshire, United Kingdom",
  "Richmond on Thames London, United Kingdom",
  "Rickenbach, Switzerland",
  "Rickmansworth, United Kingdom",
  "Ridderkerk, Netherlands",
  "Riddings, United Kingdom",
  "Ridgecrest, United States",
  "Ridgefield Park, United States",
  "Ridgeland, United States",
  "Ridgeway, United States",
  "Ridgewood, United States",
  "Ried im Innkreis, Austria",
  "Riedenburg, Germany",
  "Riedlhuette, Germany",
  "Riedlingen (Wuertt), Germany",
  "Riedstadt, Germany",
  "Riegel, Germany",
  "Riego de la Vega, Spain",
  "Rielasingen-Worblingen, Germany",
  "Rielves, Spain",
  "Riesa, Germany",
  "Riese Pio Decimo, Italy",
  "Rieste, Germany",
  "Rietavas, Lithuania",
  "Rietberg, Germany",
  "Rieti, Italy",
  "Rieux, France",
  "Riga, Latvia",
  "Rignano Sull'Arno, Italy",
  "Riihimaki, Finland",
  "Rijeka, Croatia",
  "Rijen, Netherlands",
  "Rijkevorsel, Belgium",
  "Rijsenhout, Netherlands",
  "Rijssen, Netherlands",
  "Rijswijk, Netherlands",
  "Rillieux la Pape, France",
  "Rimavska Sobota, Slovakia",
  "Rimini, Italy",
  "Rimnicu Vilcea, Romania",
  "Rincon de Soto, Spain",
  "Ringaskiddy Cork, Ireland",
  "Ringe, Denmark",
  "Ringe, Germany",
  "Ringelai, Germany",
  "Ringgold, United States",
  "Ringsheim, Germany",
  "Ringsted, Denmark",
  "Ringwood, United Kingdom",
  "Rinteln, Germany",
  "Rio Cuarto de Grecia, Costa Rica",
  "Rio de Janeiro, Brazil",
  "Rio De Mouro, Portugal",
  "Rio Gallegos Santa Cruz, Argentina",
  "Rio Grande, Brazil",
  "Rio Haina, Dominican Republic",
  "Rio Jimenez, Costa Rica",
  "Rio Lindo, Honduras",
  "Rio Maior, Portugal",
  "Rio Meao, Portugal",
  "Rio Nance, Honduras",
  "Rio Negrinho, Brazil",
  "Rio Saliceto, Italy",
  "Rio Tinto, Portugal",
  "Riom, France",
  "Ripalimosani, Italy",
  "Ripalta Cremasca, Italy",
  "Ripatransone, Italy",
  "Ripe, Italy",
  "Ripley, United States",
  "RipleyDerbyshire, United Kingdom",
  "Ripollet, Spain",
  "Ripon, United Kingdom",
  "Ripon, United States",
  "Riposto, Italy",
  "Risca, United Kingdom",
  "Riscle, France",
  "Rishon le Zion, Israel",
  "Rishton Lancashire BB, United Kingdom",
  "Risley Cheshire, United Kingdom",
  "Ritthem, Netherlands",
  "Rittman, United States",
  "Riudarenes, Spain",
  "Riudellots de la Selva, Spain",
  "Riva del Garda, Italy",
  "Rivabellosa, Spain",
  "Rivalta di Torino, Italy",
  "Rivalta Scrivia Interporto, Italy",
  "Rivanazzano, Italy",
  "Rivarolo Canavese, Italy",
  "Rivarolo del Re, Italy",
  "Rivarolo Mantovano, Italy",
  "Rivarotta di Teor, Italy",
  "Rivas Vaciamadrid, Spain",
  "River Rouge, United States",
  "Rivergaro, Italy",
  "Riverside, United States",
  "Rives, France",
  "Rives sur Fure, France",
  "Rivesaltes, France",
  "Riviera Beach, United States",
  "Rivignano, Italy",
  "Rivnopil', Ukraine",
  "Rivoli, Italy",
  "Rivoli Veronese, Italy",
  "Rivolta d'Adda, Italy",
  "Rixheim, France",
  "Riyadh, Saudi Arabia",
  "Roanne, France",
  "Roanoke, United States",
  "Roasio, Italy",
  "Robakowo, Poland",
  "Robards, United States",
  "Robassomero, Italy",
  "Robbiano di Mediglia, Italy",
  "Robbiate, Italy",
  "Robbio, Italy",
  "Robecchetto Con I, Italy",
  "Robecco d Oglio CR, Italy",
  "Robecco Pavese, Italy",
  "Robecco sul Naviglio, Italy",
  "Robertsbridge, United Kingdom",
  "Robledo, Spain",
  "Rocca Canavese, Italy",
  "Rocca di Capri Leone, Italy",
  "Rocca Imperiale, Italy",
  "Roccabianca, Italy",
  "Roccabruna, Italy",
  "Roccaforte Mondovi, Italy",
  "Roccafranca, Italy",
  "Roccastrada, Italy",
  "Roccella Jonica, Italy",
  "Rochdale, United Kingdom",
  "Roche Chalais, France",
  "Roche la Moliere, France",
  "Roche les Beaupre, France",
  "Rochefort, France",
  "Rochefort sur Mer, France",
  "Rochefoucauld, France",
  "Rochelle, United States",
  "Rochester, United States",
  "Rochester Kent, United Kingdom",
  "Rochetoirin, France",
  "Rochford Essex, United Kingdom",
  "Rochlitz, Germany",
  "Rock City Falls, United States",
  "Rock Ferry, United Kingdom",
  "Rock Hill, United States",
  "Rock Island, United States",
  "Rockenhausen, Germany",
  "Rockford, United States",
  "Rockland, United States",
  "Rocklin, United States",
  "Rockport, United States",
  "Rockville, United States",
  "Rockwall, United States",
  "Rocky Mount, United States",
  "Roda de Ter, Spain",
  "Rodano, Italy",
  "Roddi, Italy",
  "Rodengo Saiano, Italy",
  "Rodez, France",
  "Rodgau, Germany",
  "Rodigo, Italy",
  "Roding, Germany",
  "Rodleben, Germany",
  "Rodonya, Spain",
  "Roe' Volciano, Italy",
  "Roebuck, United States",
  "Roecliffe Harrogate, United Kingdom",
  "Roedby, Denmark",
  "Roedding, Denmark",
  "Roedekro, Denmark",
  "Roedental, Germany",
  "ROEDERLAND, Germany",
  "Roedermark, Germany",
  "Roedinghausen, Germany",
  "Roedkaersbro, Denmark",
  "Roelofarendsveen, Netherlands",
  "Roenne, Denmark",
  "Roermond, Netherlands",
  "Roeros, Norway",
  "Roeselare, Belgium",
  "Roetha, Germany",
  "Roethenbach (a d Pegnitz, Germany",
  "Roethenbach (Allgaeu), Germany",
  "Roethlein, Germany",
  "Roettingen, Germany",
  "Roetz (Oberpfalz), Germany",
  "Rogeno, Italy",
  "Rogers, United States",
  "Rogersville, United States",
  "Rogerville, France",
  "Rognac, France",
  "Rogno, Italy",
  "Rogolo, Italy",
  "Rogow Opolski, Poland",
  "Rogozino, Poland",
  "Rohatec, Czech Republic",
  "Rohoznik, Slovakia",
  "Rohr, Germany",
  "Rohrbach, Germany",
  "Rohrbach (Ilm), Germany",
  "Rohrdorf (Kr Rosenheim), Germany",
  "Roigheim, Germany",
  "Roisel, France",
  "ROISSY CDG CEDEX, France",
  "Roissy en France, France",
  "Roissy Seine et Marne, France",
  "Roja, Latvia",
  "Rokishkis, Lithuania",
  "Rokycany, Czech Republic",
  "Rokytnice v Orlickych Horach, Czech Republic",
  "Rolandia, Brazil",
  "Roldan, Spain",
  "Roletto, Italy",
  "Rolo, Italy",
  "Roma, Italy",
  "Romagnano, Italy",
  "Romagnano Sesia, Italy",
  "Romaneche Thorins, France",
  "Romanengo, Italy",
  "Romano d'Ezzelino, Italy",
  "Romano di Lombardia, Italy",
  "Romanore, Italy",
  "Romans d'Isonzo, Italy",
  "Romans sur Isere, France",
  "Romanshorn, Switzerland",
  "Rome, United States",
  "Romeno, Italy",
  "Romentino, Italy",
  "Romeoville, United States",
  "Romford London, United Kingdom",
  "Romilly sur Andelle, France",
  "Romilly sur Seine, France",
  "Romorantin Lanthenay, France",
  "Romsey, United Kingdom",
  "Romulus, United States",
  "Ronago, Italy",
  "Ronca, Italy",
  "Roncade, Italy",
  "Roncadelle, Italy",
  "Roncadelle Veneto, Italy",
  "Roncadello di Casalmaggiore, Italy",
  "Roncanova, Italy",
  "Roncello, Italy",
  "Ronchamp, France",
  "Ronchi dei Legionari, Italy",
  "Ronchi di Campanile, Italy",
  "Ronchis, Italy",
  "Ronco all'Adige, Italy",
  "Ronco Briantino, Italy",
  "Ronco Cia, Spain",
  "Ronco Scrivia, Italy",
  "Roncoferraro, Italy",
  "Roncole Verdi PR, Italy",
  "Roncone, Italy",
  "Roncopascolo, Italy",
  "Roncq, France",
  "Rondonopolis, Brazil",
  "Rongqi, China",
  "Ronneburg, Germany",
  "Ronneburg (Thuer), Germany",
  "Ronneby, Sweden",
  "Ronnenberg, Germany",
  "Ronse, Belgium",
  "Roodepoort, South Africa",
  "Roosendaal, Netherlands",
  "Roost, Luxemburg",
  "Root, Switzerland",
  "Ropazi, Latvia",
  "Ropczyce, Poland",
  "Roquebrune, France",
  "Roquebrune sur Argens, France",
  "Roquefort la Bedoule, France",
  "Rosa, Italy",
  "Rosario, Argentina",
  "Rosate, Italy",
  "Rosbach (v d Hoehe), Germany",
  "Rosciano, Italy",
  "Roscommon Roscommon, Ireland",
  "Roscrea Tipperary, Ireland",
  "Roseburg, United States",
  "Rosedale, United States",
  "Roseland, United States",
  "Roselle, United States",
  "Rosello Lerida, Spain",
  "Rosenberg, United States",
  "Rosenberg (Baden), Germany",
  "Rosenberg (Wuertt), Germany",
  "Rosendahl, Germany",
  "Rosenfeld (Wuertt), Germany",
  "Rosenheim (Oberbayern), Germany",
  "Rosersberg, Sweden",
  "Roses, Spain",
  "Roseto degli Abruzzi, Italy",
  "ROSEVILLE, United States",
  "ROSH HA-AYIN, Israel",
  "Rosignano Solvay, Italy",
  "Rositz, Germany",
  "Roslev, Denmark",
  "Roslyn, United States",
  "Rosny sur Seine, France",
  "Rosolini, Italy",
  "Rosporden, France",
  "Ross on Wye, United Kingdom",
  "Rossana, Italy",
  "Rossano Veneto, Italy",
  "Rossbach (Niederbayern), Germany",
  "Rossburgh, South Africa",
  "Rosscarbery, Ireland",
  "Rossdorf, Germany",
  "Rossell, Spain",
  "Rossendale, United Kingdom",
  "Rossleben, Germany",
  "Rosslyn, South Africa",
  "Rosta TO, Italy",
  "Rostock, Germany",
  "Rostov Veliky, Russia",
  "Rosyth, United Kingdom",
  "Rot am See, Germany",
  "Rot an der Rot, Germany",
  "Rota, Spain",
  "Roteglia, Italy",
  "Rotgla y Corbera, Spain",
  "Roth, Germany",
  "Roth (Mittelfr), Germany",
  "Rothenburg, Switzerland",
  "Rothenburg (bei Koennern), Germany",
  "Rothenburg (Ob der Tauber, Germany",
  "Rotherham, United Kingdom",
  "Rothienorman, United Kingdom",
  "Rothrist, Switzerland",
  "Rott am Inn, Germany",
  "Rottenacker, Germany",
  "Rottenburg (am Neckar), Germany",
  "Rottenburg an der Laaber, Germany",
  "Rottendorf, Germany",
  "Rotterdam, Netherlands",
  "Rotthalmuenster, Germany",
  "Rottleberode, Germany",
  "Rottofreno, Italy",
  "Rottweil, Germany",
  "Roubaix, France",
  "Roudnice nad Labem, Czech Republic",
  "Rouen, France",
  "Rouhling, France",
  "Roullet Saint Estephe, France",
  "Round Rock, United States",
  "Rousies, France",
  "Rousinov, Czech Republic",
  "Roussas, France",
  "Rousset, France",
  "Rouveen, Netherlands",
  "Rouvignies, France",
  "Rouvroy, France",
  "Rouvroy sur Audry, France",
  "Rouyn-Noranda, Canada",
  "Rovato, Italy",
  "Rovellasca, Italy",
  "Rovello Porro, Italy",
  "Rovensko pod  Troskami, Czech Republic",
  "Roverbasso di Codogne, Italy",
  "Roverbella, Italy",
  "Roverchiara, Italy",
  "Rovere Veronese, Italy",
  "Roveredo di Gua, Italy",
  "Roveredo in Piano, Italy",
  "Rovereto, Italy",
  "Roveri, Italy",
  "ROVIES, Greece",
  "Rovigo, Italy",
  "Rovinka, Slovakia",
  "Rovolon, Italy",
  "Rowland Heights, United States",
  "Roxana de Guapiles, Costa Rica",
  "Royal Oak, United States",
  "Roye, France",
  "Royston Hertfordshire, United Kingdom",
  "Royston Yorkshire, United Kingdom",
  "Royton, United Kingdom",
  "Rozalin, Poland",
  "Rozhanovce, Slovakia",
  "Rozmital Pod Tremsinem, Czech Republic",
  "Roznava, Slovakia",
  "Roznov, Romania",
  "Roznov Pod Radhostem, Czech Republic",
  "Rozzano, Italy",
  "Rtyne Nad Bilinou, Czech Republic",
  "Ruabon, United Kingdom",
  "Rubano, Italy",
  "Rubi, Spain",
  "Rubiera, Italy",
  "Ruchocice, Poland",
  "Ruda Slaska, Poland",
  "Ruddervoorde, Belgium",
  "Rudiano, Italy",
  "Rudna, Czech Republic",
  "Rudnik, Poland",
  "Rudno, Poland",
  "Rudolstadt, Germany",
  "Rudozem, Bulgaria",
  "Rudshoegda, Norway",
  "Rueda Valladolid, Spain",
  "Ruedersdorf, Germany",
  "Ruedesheim (am Rhein), Germany",
  "Rueil Malmaison, France",
  "Ruethen, Germany",
  "Rueti bei Bueren, Switzerland",
  "Ruffec, France",
  "Rufford Lancashire, United Kingdom",
  "Rugao, China",
  "Rugby, United Kingdom",
  "Rugeley, United Kingdom",
  "Rugvica, Croatia",
  "Ruhstorf (a d Rott), Germany",
  "Ruisbroek, Belgium",
  "Ruislip, United Kingdom",
  "Ruitz, France",
  "Ruma, Serbia",
  "Rumbeke, Belgium",
  "Rumburk, Czech Republic",
  "Rumford, United States",
  "Rumilly, France",
  "Rumst, Belgium",
  "Runcorn, United Kingdom",
  "Rungis, France",
  "Runkel (Lahn), Germany",
  "Ruoms, France",
  "Rupganj, Bangladesh",
  "Ruppach-Goldhausen, Germany",
  "Ruppichteroth, Germany",
  "Rural Hall, United States",
  "Rusayl, Oman",
  "Ruse, Bulgaria",
  "Ruse, Slovenia",
  "Rushden Hertfordshire, United Kingdom",
  "Rushden Northampton, United Kingdom",
  "Rushock, United Kingdom",
  "Russellville, United States",
  "Russi, Italy",
  "Rustington, United Kingdom",
  "Rute, Spain",
  "Rutesheim, Germany",
  "Ruth, United States",
  "Rutherford, United States",
  "Rutherglen, United Kingdom",
  "Ruthin, United Kingdom",
  "Rutigliano, Italy",
  "Ruzomberok, Slovakia",
  "Ry, Denmark",
  "Ryazan, Russia",
  "Rybarzowice, Poland",
  "Rybnik, Poland",
  "Rychnov Nad Kneznou, Czech Republic",
  "Rye, United Kingdom",
  "Rygge, Norway",
  "Rymarov, Czech Republic",
  "Rynkeby, Denmark",
  "Ryomgaard, Denmark",
  "Ryton On Dunsmore Warwickshire, United Kingdom",
  "Rzeszow, Poland",
  "Rzgow, Poland",
  "Raalte, Netherlands",
  "Raamsdonksveer, Netherlands",
  "Raamsdonkveer, Netherlands",
  "S Adria de Besos, Spain",
  "S Adrian Navarra, Spain",
  "S Agustin de Guadalix, Spain",
  "S Anatolia di Narco PG, Italy",
  "S Andreu de la Barca, Spain",
  "S Antonio de Benageber, Spain",
  "S Boi de Llobregat, Spain",
  "S Carles de la Rapita, Spain",
  "S Celoni, Spain",
  "S Ciprian, Spain",
  "S Ciprian Lugo, Spain",
  "S Cugat del Valles, Spain",
  "S Cugat Sesgarrigues, Spain",
  "S Egidio Alla Vibrata, Italy",
  "S Esteve d'en Bas, Spain",
  "S Esteve Sesrovires, Spain",
  "S Feliu de Buixalleu Girona, Spain",
  "S Feliu de Llobregat, Spain",
  "S Fernando, Spain",
  "S Fernando de Henares, Spain",
  "S Fruitos de Bages, Spain",
  "S Fulgencio, Spain",
  "S Gines Murcia, Spain",
  "S Gregori Girona, Spain",
  "S Isidro de Duenas Palencia, Spain",
  "S Jaume Llierca, Spain",
  "S Javier, Spain",
  "S Joan Abadesses, Spain",
  "S Joan de Vilatorrada, Spain",
  "S Joan Despi, Spain",
  "S Joan les Fonts, Spain",
  "S Jose de la Rinconada, Spain",
  "S Jose de la Vega, Spain",
  "S Juan Aznalfarache, Spain",
  "S Juan de Alicante, Spain",
  "S Juan DE Moro, Spain",
  "S Juan de Mozarrifar, Spain",
  "S Juan del Puerto, Spain",
  "S Julia de Ramis, Spain",
  "S Julia Vilatorta, Spain",
  "S Just Desvern, Spain",
  "S Llorenc d'Hortons, Spain",
  "S Martin de la Vega Madrid, Spain",
  "S Martin de Valdeiglesias, Spain",
  "S Mateo de Gallego, Spain",
  "S Miquel de Balenya, Spain",
  "S Nicolo, Italy",
  "S Pedro de Alcantara, Spain",
  "S Pedro del Pinatar, Spain",
  "S Pere de Ribes, Spain",
  "S Pere de Riudebitlles, Spain",
  "S Pere Pescador, Spain",
  "S Pol de Mar, Spain",
  "S Quirze de Besora, Spain",
  "S Quirze Valles, Spain",
  "S Roque, Spain",
  "S Sadurni d'Anoia, Spain",
  "S Sebastian, Spain",
  "S Sebastian de los Reyes, Spain",
  "S Vicenc de Castellet, Spain",
  "S Vicenc dels Horts, Spain",
  "S Vicent de Torello, Spain",
  "S Vicente Alcantara, Spain",
  "S Vicente de la Sonsierra, Spain",
  "S Vicente del Raspeig, Spain",
  "S Vito Chietino, Italy",
  "Sabadell, Spain",
  "Sabaudia, Italy",
  "Sabbio Bergamasco, Italy",
  "Sabbio Chiese, Italy",
  "Sabbioneta, Italy",
  "Sabinanigo, Spain",
  "Sabinov, Slovakia",
  "Sable sur Sarthe, France",
  "Saccolongo, Italy",
  "Sachana, India",
  "Sachin, India",
  "Sachsen, Germany",
  "Sacile, Italy",
  "Sacramento, United States",
  "Sada, Spain",
  "Sadaba, Spain",
  "Sadat City, Egypt",
  "SADOVE, Ukraine",
  "Sadska, Czech Republic",
  "Sady, Poland",
  "Saedinenie Plov, Bulgaria",
  "Saerbeck, Germany",
  "Saffron Walden, United Kingdom",
  "Safi, Morocco",
  "Safim, Guinea-Bissau",
  "Sagamihara, Japan",
  "Sagana, Kenya",
  "Sagarejo, Georgia",
  "Sagrado, Italy",
  "Sagunto, Spain",
  "Sahab, Jordan",
  "Sahathai, Thailand",
  "Sahibabad, India",
  "SAHNEWAL, India",
  "Sailly-lez-Cambrai, France",
  "Sainghin en Melantois, France",
  "Sains du Nord, France",
  "Saint Agathe la Bouteresse, France",
  "Saint Agathon, France",
  "Saint Alban les Eaux, France",
  "Saint Alban sur Limagnole, France",
  "Saint Albans, South Africa",
  "Saint Amans Valtoret, France",
  "Saint Amant de Boixe, France",
  "Saint Amour, France",
  "Saint Andiol, France",
  "Saint Andre de Cubzac, France",
  "Saint Andre de l'Eure, France",
  "Saint Andre le Gaz, France",
  "Saint Andre les Vergers, France",
  "Saint Andre lez Lille, France",
  "Saint Apollinaire, France",
  "Saint Armel, France",
  "Saint Astier Dordogne, France",
  "Saint Auban, France",
  "Saint Aubin, France",
  "Saint Aubin De Blaye, France",
  "Saint Aubin des Landes, France",
  "Saint Aubin les Elbeuf, France",
  "Saint Avold, France",
  "Saint Barthelemy d'Anjou, France",
  "Saint Benoit, France",
  "Saint Berthevin, France",
  "Saint Bonnet de Mure, France",
  "Saint Brevin les Pins, France",
  "Saint Brice Courcelles, France",
  "Saint Brice en Cogles, France",
  "Saint Brieuc, France",
  "Saint Caprais de Blaye, France",
  "Saint Catherine, Canada",
  "Saint Chamas, France",
  "Saint Chamond, France",
  "Saint Charles, United States",
  "Saint Clair, United States",
  "Saint Clair de la Tour, France",
  "Saint Clair du Rhone, France",
  "Saint Claude, France",
  "Saint Clement des Levees, France",
  "Saint Crepin Ibouvillers, France",
  "Saint Cyr en Bourg, France",
  "Saint Cyr en Val, France",
  "Saint Cyr sur Loire, France",
  "Saint Denis, France",
  "Saint Denis de Cabanne, France",
  "Saint Denis de l'Hotel, France",
  "Saint Didier de la Tour, France",
  "Saint Didier sur Chalaronne, France",
  "Saint Divy, France",
  "Saint Dizier Haute Marne, France",
  "Saint Doulchard, France",
  "Saint Egreve, France",
  "Saint Eloy les Mines, France",
  "Saint Emilion, France",
  "Saint Etienne de Saint Geoirs, France",
  "Saint Etienne du Rouvray, France",
  "Saint Etienne Loire, France",
  "Saint Eusebe Saone et Loire, France",
  "Saint Felix, France",
  "Saint Florent le Vieil, France",
  "Saint Florentin, France",
  "Saint Fons, France",
  "Saint Gabriel, United States",
  "Saint Galmier, France",
  "Saint Gaudens, France",
  "Saint Genis Laval, France",
  "Saint Genis les Ollieres, France",
  "Saint Genix sur Guiers, France",
  "Saint Georges de Mons, France",
  "Saint Georges de Montaigu, France",
  "Saint Georges de Reneins, France",
  "Saint Georges d'Esperanche, France",
  "Saint Georges sur Loire, France",
  "Saint Geours de Maremne, France",
  "Saint Gerand, France",
  "Saint Germain de Lusignan, France",
  "Saint Germain du Puch, France",
  "Saint Germain du Puy, France",
  "Saint Germain la Ville, France",
  "Saint Germain Laval, France",
  "Saint Germain Les Arlay, France",
  "Saint Germain sur Moine, France",
  "Saint Gervais, France",
  "Saint Ghislain, Belgium",
  "Saint Gilles, France",
  "Saint Goazec, France",
  "Saint Guen, France",
  "Saint Herblain, France",
  "Saint Hilaire de Loulay, France",
  "Saint Iscle de Vallalta, Spain",
  "Saint Jean d'Angely, France",
  "Saint Jean d'Ardieres, France",
  "Saint Jean de Braye, France",
  "Saint Jean de la Ruelle, France",
  "Saint Jean de Vedas, France",
  "Saint Jean d'Illac, France",
  "Saint Joseph, United States",
  "Saint Julien de Concelles, France",
  "Saint Julien du Sault, France",
  "Saint Junien, France",
  "Saint Just d Avray, France",
  "Saint Just en Chaussee, France",
  "Saint Just Saint Rambert, France",
  "Saint Lambert des Levees, France",
  "Saint Laurent de Mure, France",
  "Saint Laurent des Vignes, France",
  "Saint Laurent du Pont, France",
  "Saint Laurent du Var, France",
  "Saint Laurent Medoc, France",
  "Saint Laurent sur Sevre, France",
  "Saint Leger sur Dheune, France",
  "Saint Leonard Seine Maritime, France",
  "Saint Lin, France",
  "Saint Lothain, France",
  "Saint Loubes, France",
  "Saint Louis, United States",
  "Saint Loup sur Semouse, France",
  "Saint Lys, France",
  "Saint Magne de Castillon, France",
  "Saint Maixent l'Ecole, France",
  "Saint Malo, France",
  "Saint Marcel, France",
  "Saint Marcel Morbihan, France",
  "Saint Marcel Saone et Loire, France",
  "Saint Marcellin en Forez, France",
  "Saint Mars la Briere, France",
  "Saint Martin au Laert, France",
  "Saint Martin Belle Roche, France",
  "Saint Martin De Seignanx, France",
  "Saint Martin du Fouilloux, France",
  "Saint Martin du Puy, France",
  "Saint Martin Longueau, France",
  "Saint Martin sur le Pre, France",
  "Saint Marys, United States",
  "Saint Maurice de Beynost, France",
  "Saint Maurice l'Exil, France",
  "Saint Meen le Grand, France",
  "Saint Michel de Fronsac, France",
  "Saint Michel Des Loups, France",
  "Saint Michel sur Orge, France",
  "Saint Mihiel, France",
  "Saint Nabord Vosges, France",
  "Saint Nazaire, France",
  "Saint Nizier sous Charlieu, France",
  "Saint Omer, France",
  "Saint Ouen l'Aumone, France",
  "Saint Ouen Somme, France",
  "Saint Ours Puy de Dome, France",
  "Saint Pal de Mons, France",
  "Saint Paul, France",
  "Saint Paul, United States",
  "Saint Paul Trois Chateaux, France",
  "Saint Pere en Retz, France",
  "Saint Petersburg, United States",
  "Saint Philbert sur Risle, France",
  "Saint Pierre de Chandieu, France",
  "Saint Pierre des Corps, France",
  "Saint Pierre D'Exideuil, France",
  "Saint Pierre d'Irube, France",
  "Saint Pierre les Elbeuf, France",
  "Saint Pourcain sur Sioule, France",
  "Saint Priest de Gimel, France",
  "Saint Priest Rhone, France",
  "Saint Quentin Aisne, France",
  "Saint Quentin Fallavier, France",
  "Saint Rambert d'Albon, France",
  "Saint Romain Lachalm, France",
  "Saint Romain le Puy, France",
  "Saint Rose, United States",
  "Saint Sauveur des Landes, France",
  "Saint Sebastien de Morsent, France",
  "Saint Sebastien sur Loire, France",
  "Saint Severin, France",
  "Saint Simon de Bordes, France",
  "Saint Soupplets, France",
  "Saint Sulpice sur Leze, France",
  "Saint Sylvain d'Anjou, France",
  "Saint Sylvestre sur Lot, France",
  "Saint Symphorien d'Ozon, France",
  "Saint Thibault des Vignes, France",
  "Saint Trivier sur Moignans, France",
  "Saint Vallier Drome, France",
  "Saint Viance, France",
  "Saint Viaud, France",
  "Saint Victor des oules, France",
  "Saint Victor sur Loire, France",
  "Saint Vigor d'Ymonville, France",
  "Saint Vincent de Pertignas, France",
  "Saint Vincent en Bresse, France",
  "Saint Vulbas, France",
  "Saint Yorre, France",
  "Saint Aaron, France",
  "Sainte Anne de Bellevue, Canada",
  "Sainte Cecile les Vignes, France",
  "Sainte Croix de Mareuil, France",
  "Sainte Eanne, France",
  "Sainte Florence, France",
  "Sainte Florine, France",
  "Sainte Hermine, France",
  "Sainte Menehould, France",
  "Sainte Sigolene, France",
  "Sainte Soulle, France",
  "Sainte-Eulalie, Canada",
  "Saintes, France",
  "Saint-Eustache, Canada",
  "Saint-Georges, Canada",
  "Saint-Hyacinthe, Canada",
  "Saint-Jean-de-Losne, France",
  "Saint-Jean-sur-Richelieu, Canada",
  "Saint-Jouan-de-l'Isle, France",
  "Saint-Laurent, Canada",
  "Saint-Leger-des-Bois, France",
  "Saint-Leger-en-Bray, France",
  "Saint-Leonard, Canada",
  "Saint-Pardoux-la-Riviere, France",
  "Saint-Philippe-d'Aiguille, France",
  "Saint-Sulpice-la-Pointe, France",
  "Sajobabony, Hungary",
  "Sakaiminato, Japan",
  "Sakarya, Turkey",
  "Sakhnin, Israel",
  "Saku, Estonia",
  "Sakvice, Czech Republic",
  "Sakyla, Finland",
  "Sala, Slovakia",
  "Sala Baganza, Italy",
  "Sala Bolognese, Italy",
  "Salacgriva, Latvia",
  "Salaise sur Sanne, France",
  "Salalah, Oman",
  "Salamanca, Mexico",
  "Salamanca, Spain",
  "Salaspils, Latvia",
  "Salaverry, Peru",
  "Salcedo, Spain",
  "SALE, Italy",
  "SALE, Morocco",
  "SALE, United Kingdom",
  "Salem, Spain",
  "Salem Baden, Germany",
  "Salerano sul Lambro, Italy",
  "Salerno, Italy",
  "Saletto, Italy",
  "Salford Manchester, United Kingdom",
  "Salford Oxfordshire, United Kingdom",
  "Salfords, United Kingdom",
  "Salgareda, Italy",
  "Salgotarjan, Hungary",
  "Salies du Salat, France",
  "Salignac sur Charente, France",
  "Salihli-Manisa, Turkey",
  "Salina, United States",
  "Salinas de Pamplona, Spain",
  "Salindres, France",
  "Saline di Volterra, Italy",
  "Salisbury, United Kingdom",
  "Salisbury, United States",
  "Salizzole, Italy",
  "Sallent, Spain",
  "Salles d'Angles, France",
  "Salmtal, Germany",
  "Salo, Finland",
  "Salo, Italy",
  "Salon de Provence, France",
  "Salonta, Romania",
  "Salsomaggiore PR, Italy",
  "Salt, Spain",
  "Salt Lake City, United States",
  "Saltara, Italy",
  "Saltash Cornwall, United Kingdom",
  "Saltburn-by-the-Sea, United Kingdom",
  "Saltcoats, United Kingdom",
  "Salteras, Spain",
  "Saltillo, Mexico",
  "Saltillo, United States",
  "Saltney, United Kingdom",
  "Salto, Uruguay",
  "Saluzzo, Italy",
  "Salvador, Brazil",
  "Salvaterra, Italy",
  "Salvatierra Alava, Spain",
  "Salzano, Italy",
  "Salzbergen, Germany",
  "Salzburg, Austria",
  "Salzgitter, Germany",
  "Salzkotten, Germany",
  "Salzwedel, Germany",
  "Samandira, Turkey",
  "Samarate, Italy",
  "Sambuca Val di Pesa, Italy",
  "Sambuceto, Italy",
  "Samlesbury, United Kingdom",
  "Samobor, Croatia",
  "Samokov, Bulgaria",
  "Samora Correia, Portugal",
  "Samsun, Turkey",
  "Samutprakan, Thailand",
  "Samutsakhon, Thailand",
  "San Angelo, United States",
  "San Angelo Scalo, Italy",
  "San Antonio, Chile",
  "San Antonio, United States",
  "San Antonio Este, Argentina",
  "San Bartolo, El Salvador",
  "San Bartolomeo, Italy",
  "San Bartolomeo in Bosco, Italy",
  "San Bassano, Italy",
  "San Benedetto del Tronto, Italy",
  "San Benedetto Po, Italy",
  "San Benito Peten, Guatemala",
  "San Bernardino, United States",
  "San Bernardo Di Ivrea, Italy",
  "San Biagio di Callalta, Italy",
  "San Bonifacio, Italy",
  "San Canzian d Isonzo, Italy",
  "San Carlos, United States",
  "San Cayetano, Spain",
  "San Cesareo, Italy",
  "San Cesario sul Panaro, Italy",
  "San Cipriano Po, Italy",
  "San Clemente, Italy",
  "San Climent Sescebes, Spain",
  "San Colombano al Lambro, Italy",
  "San Costantino Calabro, Italy",
  "San Costanzo, Italy",
  "San Cristobal, Dominican Republic",
  "San Cristobal de Entrevinas, Spain",
  "San Damaso, Italy",
  "San Damiano d'Asti, Italy",
  "San Daniele del Friuli, Italy",
  "San Daniele Po, Italy",
  "San Defendente D Cervasca, Italy",
  "San Diego, United States",
  "San Dona' di Piave, Italy",
  "San Donato Milanese, Italy",
  "San Donnino, Italy",
  "San Donnino di Liguria, Italy",
  "San Dorligo della Valle, Italy",
  "San Felice del Benaco, Italy",
  "San Felice sul Panaro, Italy",
  "San Felices de Buelna, Spain",
  "San Felipe, Chile",
  "San Ferdinando di Puglia, Italy",
  "San Fernando, Chile",
  "San Fior, Italy",
  "San Fior di Sopra, Italy",
  "San Francisco Cordoba, Argentina",
  "San Francisco del Rincon, Mexico",
  "San Gennaro Vesuviano, Italy",
  "San Germano dei Berici, Italy",
  "San Gervasio Bresciano, Italy",
  "San Giacomo di Veglia, Italy",
  "San Gillio, Italy",
  "San Gimignano, Italy",
  "San Giorgio a Colonica, Italy",
  "San Giorgio Canavese, Italy",
  "San Giorgio della Richinvelda, Italy",
  "San Giorgio delle Pertiche, Italy",
  "SAN GIORGIO DI MANTOVA, Italy",
  "San Giorgio di Nogaro, Italy",
  "San Giorgio di Piano, Italy",
  "San Giorgio in Bosco, Italy",
  "San Giorgio Piacentino, Italy",
  "San Giorgio su Legnano, Italy",
  "San Giovanni al Natisone, Italy",
  "San Giovanni Bianco, Italy",
  "San Giovanni di Ostellato, Italy",
  "San Giovanni di Polcenigo, Italy",
  "San Giovanni Ilarione, Italy",
  "San Giovanni in Croce, Italy",
  "San Giovanni in Marignano, Italy",
  "San Giovanni in Persiceto, Italy",
  "San Giovanni Lupatoto, Italy",
  "San Giovanni Teatino, Italy",
  "San Giovanni Valdarno, Italy",
  "San Girolamo di Guastalla, Italy",
  "San Giuliano Milanese, Italy",
  "San Giuseppe di Cairo, Italy",
  "San Giuseppe di Cassola, Italy",
  "San Giuseppe Vesuviano, Italy",
  "San Giustino, Italy",
  "San Grato, Italy",
  "San Isidro, Spain",
  "San Isidro del General, Costa Rica",
  "San Jose, Costa Rica",
  "San Jose Iturbide, Mexico",
  "San Jose Pinula, Guatemala",
  "San Juan, Argentina",
  "San Juan, Puerto Rico",
  "San Juan del Rio, Mexico",
  "San Juan Opico, El Salvador",
  "San Lazzaro di Savena, Italy",
  "San Leandro, United States",
  "San Leo di Pellaro, Italy",
  "San Leon, United States",
  "San Lorenzo, Honduras",
  "San Lorenzo in Campo, Italy",
  "San Luis Potosi, Mexico",
  "San Macario, Italy",
  "SAN MAMES DE MERUELO, Spain",
  "San Manuel, Honduras",
  "San Marco Evangelista, Italy",
  "San Marcos, Nicaragua",
  "San Marino, Italy",
  "San Martin del Rey Aurelio, Spain",
  "San Martin Texmelucan de Labastida, Mexico",
  "San Martino al Tagliamento, Italy",
  "San Martino Buon Albergo, Italy",
  "San Martino della Battaglia, Italy",
  "San Martino di Lupari, Italy",
  "San Martino di Trecate, Italy",
  "San Martino di Venezze, Italy",
  "San Martino in Rio, Italy",
  "San Martino in Strada Lombardy, Italy",
  "San Marzano di San Giuseppe, Italy",
  "San Marzano sul Sarno, Italy",
  "San Mateo, United States",
  "San Matteo della Decima, Italy",
  "San Maurizio d'Opaglio, Italy",
  "San Mauro Pascoli, Italy",
  "San Mauro Torinese, Italy",
  "San Michele, Italy",
  "San Michele di Serino, Italy",
  "San Miguel de Allende, Mexico",
  "San Miguel De Valero, Spain",
  "San Miguel del Arroyo, Spain",
  "San Miguel Duenas, Guatemala",
  "San Miguel Petapa, Guatemala",
  "San Miniato, Italy",
  "San Nazario, Italy",
  "San Nicola la Strada, Italy",
  "San Nicolo a Tordino, Italy",
  "San Nicolo a Trebbia, Italy",
  "San Nicolo di Po, Italy",
  "SAN PABLO DE LOS MONTES - TOLEDO, Spain",
  "San Paolo, Italy",
  "San Paolo d'Argon, Italy",
  "San Pedro Poas, Costa Rica",
  "San Pedro Sacatepequez, Guatemala",
  "San Pedro Sula, Honduras",
  "San Pellegrino Terme, Italy",
  "San Piero in Bagno, Italy",
  "San Pietro a Vico, Italy",
  "San Pietro di Feletto, Italy",
  "San Pietro di Legnago, Italy",
  "San Pietro di Morubio, Italy",
  "San Pietro In Cariano, Italy",
  "San Pietro in Casale, Italy",
  "San Pietro in Cerro, Italy",
  "San Pietro In Gu, Italy",
  "San Pietro In Vincoli, Italy",
  "San Pietro Mosezzo, Italy",
  "San Pietro Mussolino, Italy",
  "San Pietro Vernotico, Italy",
  "San Polo, Italy",
  "San Polo d'Enza, Italy",
  "San Polo di Piave, Italy",
  "San Polo PR, Italy",
  "San Possidonio, Italy",
  "San Procopio, Italy",
  "San Prospero, Italy",
  "San Quirico Trecasali PR, Italy",
  "San Quirino, Italy",
  "San Rafael, Spain",
  "San Ramon, Costa Rica",
  "San Rocco al Porto, Italy",
  "San Roman De Bembibre, Spain",
  "San Salvador, El Salvador",
  "San Salvador de Jujuy, Argentina",
  "San Salvatore Telesino, Italy",
  "San Salvo, Italy",
  "San Secondo Parmense, Italy",
  "San Severino Marche, Italy",
  "San Severo, Italy",
  "San Shan, China",
  "San Sisto, Italy",
  "San Stino di Livenza, Italy",
  "San Valentino Torio, Italy",
  "San Vendemiano, Italy",
  "San Vicente, Chile",
  "San Vitaliano, Italy",
  "San Vito al Tagliamento, Italy",
  "San Vito al Torre, Italy",
  "San Vito di Leguzzano, Italy",
  "San Vittore di Cesena, Italy",
  "San Vittore Olona, Italy",
  "San Ysidro, United States",
  "San Zeno, Italy",
  "San Zeno Naviglio, Italy",
  "San Zenone al Lambro, Italy",
  "San Zenone degli Ezzelini, Italy",
  "San Zenone di Ezzelin, Italy",
  "Sanand, India",
  "Sanaswadi, India",
  "Sanborn, United States",
  "Sancaktepe, Turkey",
  "Sance, France",
  "Sanchidrian, Spain",
  "Sanchonuno, Spain",
  "Sancoins, France",
  "Sand (a Main), Germany",
  "Sand Point, United States",
  "Sand Springs, United States",
  "Sandakan, Malaysia",
  "Sandbach, United Kingdom",
  "Sande Vila Nova, Portugal",
  "Sandefjord, Norway",
  "Sanderstead, United Kingdom",
  "Sandersville, United States",
  "Sandford Somerset, United Kingdom",
  "Sandhurst Gloucestershire GL, United Kingdom",
  "Sandigliano, Italy",
  "Sandnes, Norway",
  "Sandomierz, Poland",
  "Sandouville, France",
  "Sandrigo, Italy",
  "Sandston, United States",
  "Sandton, South Africa",
  "Sandusky, United States",
  "Sandvika, Norway",
  "Sandviken, Sweden",
  "Sandwich, United Kingdom",
  "Sandy Bedfordshire, United Kingdom",
  "Sandycroft, United Kingdom",
  "Sanford, United States",
  "Sangalhos, Portugal",
  "Sangli, India",
  "Sangonera la Seca, Spain",
  "Sangonera la Verde, Spain",
  "Sanguesa, Spain",
  "Sanguinaro di Fontanellato, Italy",
  "Sanguinetto, Italy",
  "Sanjan, India",
  "Sankt Andrae-Woerdern, Austria",
  "Sankt Blasien, Germany",
  "Sankt Georgen (im Schwarzwald), Germany",
  "Sankt Georgen An Der Stiefing, Austria",
  "Sankt Ingbert, Germany",
  "Sankt Johann, Germany",
  "Sankt Johann (Wuertt), Germany",
  "Sankt Johann in Tirol, Austria",
  "Sankt Leon-Rot, Germany",
  "Sankt Martin im Muehlkreis, Austria",
  "Sankt Michael in Obersteiermark, Austria",
  "Sankt Valentin, Austria",
  "Sankt Wendel, Germany",
  "Sankt Wolfgang, Germany",
  "Sanliurfa, Turkey",
  "Sanlucar de Barrameda, Spain",
  "Sanok, Poland",
  "San-Pedro, Ivory Coast",
  "Sanquhar Dumfries and Galloway, United Kingdom",
  "Sansepolcro, Italy",
  "Sanshui, China",
  "SANSKI MOST, Bosnia and Herzegovina",
  "Sant Agata, Italy",
  "Sant Agostino, Italy",
  "Sant Andrea delle Fratte PG, Italy",
  "Sant Angelo dei Lombardi, Italy",
  "Sant Angelo in Vado, Italy",
  "Sant Antonino, Switzerland",
  "Sant Boi Llobregat, Spain",
  "Sant' egidio Del Montalbino, Italy",
  "Sant Elena, Italy",
  "Sant Fost De Capcentelles, Spain",
  "Sant ippolito, Italy",
  "Sant Pere de Torello, Spain",
  "Santa Ana, El Salvador",
  "Santa Ana, Spain",
  "Santa Ana Albacete, Spain",
  "Santa Barbara, Honduras",
  "Santa Clara del Cobre, Mexico",
  "Santa Clarita, United States",
  "Santa Croce sull'Arno, Italy",
  "Santa Cruz de Yojoa, Honduras",
  "SANTA EULALIA DE RONCANA, Spain",
  "Santa Fe Springs, United States",
  "Santa Giustina, Italy",
  "Santa Giustina in Colle, Italy",
  "Santa Llogaia D'Alguema, Spain",
  "Santa Lucia di Piave, Italy",
  "Santa Margherita d Adige, Italy",
  "Santa Maria a Monte, Italy",
  "Santa Maria Codifiume, Italy",
  "Santa Maria da Feira, Portugal",
  "Santa Maria De Dota, Costa Rica",
  "Santa Maria della Versa, Italy",
  "Santa Maria di Sala, Italy",
  "Santa Maria di Zevio, Italy",
  "Santa Maria Hoe, Italy",
  "Santa maria in fabriago, Italy",
  "Santa Maria la Carita, Italy",
  "Santa Maria La Longa, Italy",
  "Santa Maria Maddalena, Italy",
  "Santa Marta-Magdalena, Colombia",
  "Santa Palomba, Italy",
  "Santa Perpetua de Mogoda, Spain",
  "Santa Rosa, United States",
  "Santa Rosa - El Oro, Ecuador",
  "Santa Rosa de Pocosol, Costa Rica",
  "Santa Rosa Montelibano, Honduras",
  "Santa Tecla, El Salvador",
  "Santa Vittoria, Italy",
  "Santa Vittoria d'Alba, Italy",
  "Santaella, Spain",
  "Sant'Agata Bologn, Italy",
  "Sant'Agata Feltria, Italy",
  "Sant'Agata sul Santerno, Italy",
  "Sant'Albano Stura, Italy",
  "Sant'Ambrogio di Torino, Italy",
  "Sant'Ambrogio Valpolicella, Italy",
  "Sant'Anastasia, Italy",
  "Santander, Spain",
  "Sant'Angelo di Piove di Sacco, Italy",
  "Sant'Angelo in Colle, Italy",
  "Sant'Angelo in Lizzola, Italy",
  "Sant'Anna di Chioggia, Italy",
  "Sant'Antonino casalgrande, Italy",
  "Sant'Antonio Abate, Italy",
  "Sant'Apollinare Chietino, Italy",
  "Santarcangelo di Romagna, Italy",
  "Santarem, Portugal",
  "Sant'Elpidio a Mare, Italy",
  "Santena, Italy",
  "Santeny, France",
  "Santerno, Italy",
  "Santervas de Campos, Spain",
  "Santes, France",
  "Santiago, Chile",
  "Santiago, Cuba",
  "Santiago, Dominican Republic",
  "SANTIAGO DE BESTEIROS, Portugal",
  "Santiago de Cartes, Spain",
  "Santiago de Compostela, Spain",
  "Santiago del Arroyo Valladolid, Spain",
  "Santiago del Campo, Spain",
  "Sant'Ilario d'Enza, Italy",
  "Santiponce, Spain",
  "SANTO DOMINGO, Dominican Republic",
  "Santo Domingo - Pichincha, Ecuador",
  "Santo Stefano Belbo, Italy",
  "Santo Stefano di Zimella, Italy",
  "Santo Stefano Magra, Italy",
  "Santo Stefano Ticino, Italy",
  "Santo Stino Di Livenza, Italy",
  "Santo Tirso, Portugal",
  "Santo Tomas de Castilla, Guatemala",
  "Santomera, Spain",
  "Sant'Omero, Italy",
  "Santona, Spain",
  "Santorso, Italy",
  "Santos, Brazil",
  "Santpedor, Spain",
  "SANT'URBANO, Italy",
  "Santurce, Spain",
  "Sanzeno, Italy",
  "Sao Bernardo do Campo, Brazil",
  "Sao Joao da Madeira, Portugal",
  "Sao Joao da Talha, Portugal",
  "Sao Juliao do Tojal, Portugal",
  "Sao Martinho do Campo, Portugal",
  "Sao Paio de Oleiros, Portugal",
  "Sao Paulo, Brazil",
  "Sao Pedro do Sul, Portugal",
  "Sao Sebastiao do Paraiso, Brazil",
  "Saonara, Italy",
  "Sapanca, Turkey",
  "Saraburi, Thailand",
  "Sarajevo, Bosnia and Herzegovina",
  "Saraland, United States",
  "Saran, France",
  "Sarapiqui, Costa Rica",
  "SARAYKOY, Turkey",
  "Sarcedo, Italy",
  "Sarcelles, France",
  "Sarcey, France",
  "Sarche di Calavino, Italy",
  "Sardinal, Costa Rica",
  "Sarego, Italy",
  "Sarepta, United States",
  "Sarezzo, Italy",
  "Sargentes De La Lora, Spain",
  "Sariano, Italy",
  "Sarinena, Spain",
  "Sarisske Michalany, Slovakia",
  "Sariyer, Turkey",
  "Sarlat la Caneda, France",
  "Sarmato, Italy",
  "Sarmede, Italy",
  "Sarno, Italy",
  "Sarone Di Caneva, Italy",
  "Saronno, Italy",
  "Sarovce, Slovakia",
  "Sarpsborg, Norway",
  "Sarral, Spain",
  "Sarralbe, France",
  "Sarrancolin, France",
  "Sarre Union, France",
  "Sarrebourg, France",
  "Sarreguemines, France",
  "Sarria, Spain",
  "Sarro, Gambia",
  "Sarstedt, Germany",
  "Sartaguda, Spain",
  "Sartrouville, France",
  "Saruhanli, Turkey",
  "Sarvar, Hungary",
  "Sarzana, Italy",
  "Sas Van Gent, Netherlands",
  "Sasamon, Spain",
  "Sasbach, Germany",
  "Saskatoon, Canada",
  "Sassano, Italy",
  "Sassello, Italy",
  "Sassenage, France",
  "Sassenberg, Germany",
  "Sassenheim, Netherlands",
  "Sasso Marconi, Italy",
  "Sassocorvaro, Italy",
  "Sassoferrato, Italy",
  "Sassuolo, Italy",
  "Sastago, Spain",
  "Sastin Straze, Slovakia",
  "Saterland, Germany",
  "Satolas et Bonce, France",
  "Satoraljaujhely, Hungary",
  "Satteldorf, Germany",
  "Sattledt, Austria",
  "Satu Mare, Romania",
  "Saucedilla Caceres, Spain",
  "Sauerlach, Germany",
  "Saultain, France",
  "SAUMOS, France",
  "Saumur, France",
  "Sausheim, France",
  "Saussenac, France",
  "Sautron, France",
  "Sauveterre de Guyenne, France",
  "Savanna, United States",
  "Savannah, United States",
  "Saverne, France",
  "Saviano, Italy",
  "Savigliano, Italy",
  "Savignano sul Panaro, Italy",
  "Savignano sul Rubicone, Italy",
  "Savigno, Italy",
  "Savignone, Italy",
  "Savigny le Temple, France",
  "Savigny sur Orge, France",
  "Savinesti, Romania",
  "Savogna d'Isonzo, Italy",
  "Savona, Italy",
  "Savski Marof, Croatia",
  "Sawbridgeworth, United Kingdom",
  "Sawston, United Kingdom",
  "Sax, Spain",
  "Saxham Suffolk, United Kingdom",
  "Saxmundham, United Kingdom",
  "Sayreville, United States",
  "Sayula, Mexico",
  "Sazava, Czech Republic",
  "Scaer, France",
  "Scafati, Italy",
  "Scalea, Italy",
  "Scandiano, Italy",
  "Scandicci, Italy",
  "Scandolara Ravara CR, Italy",
  "Scanzano Jonico, Italy",
  "Scanzorosciate, Italy",
  "Scarborough, Canada",
  "Scarborough, United Kingdom",
  "Scarlino, Italy",
  "Scarperia, Italy",
  "Scerne di Pineto, Italy",
  "Schaffhausen, Switzerland",
  "Schaijk, Netherlands",
  "Schalkau, Germany",
  "Schalkenbach, Germany",
  "Schalksmuehle, Germany",
  "Schaumburg, United States",
  "Schechingen, Germany",
  "Scheessel, Germany",
  "Scheibbs, Austria",
  "Scheinfeld, Germany",
  "Schelle, Belgium",
  "Schellerten, Germany",
  "Schelluinen, Netherlands",
  "Schenkenzell, Germany",
  "Schermbeck, Germany",
  "Scherpenzeel, Netherlands",
  "Schertz, United States",
  "Schesslitz, Germany",
  "Scheveningen, Netherlands",
  "Schiavon, Italy",
  "Schiedam, Netherlands",
  "Schieder-Schwalenburg, Germany",
  "Schiffdorf, Germany",
  "Schifferstadt, Germany",
  "Schijndel, Netherlands",
  "Schiller Park, United States",
  "Schiltigheim, France",
  "Schimatari, Greece",
  "Schio, Italy",
  "Schiphol, Netherlands",
  "Schiphol Zuidoost, Netherlands",
  "Schiphol-Rijk, Netherlands",
  "Schiranna, Italy",
  "Schkeuditz, Germany",
  "Schkoelen, Germany",
  "Schkopau, Germany",
  "Schlaitdorf, Germany",
  "Schleiden (Eiffel), Germany",
  "Schleinitz (b Zeitz), Germany",
  "Schleiz, Germany",
  "Schleusingen, Germany",
  "Schlierbach (Wuertt), Germany",
  "Schlieren, Switzerland",
  "Schlins, Austria",
  "Schloss Holte-Stukenbrock, Germany",
  "Schlotheim, Germany",
  "Schmalkalden, Germany",
  "Schmallenberg, Germany",
  "Schmelz, Germany",
  "Schmerkendorf, Germany",
  "Schmiedefeld, Germany",
  "Schmitten, Germany",
  "Schmoelln, Germany",
  "Schnaittach, Germany",
  "Schnaittenbach, Germany",
  "Schneegattern, Austria",
  "Schneizlreuth, Germany",
  "Schnelldorf, Germany",
  "Schneverdingen, Germany",
  "Schoemberg (b Balingen), Germany",
  "Schoenaich, Germany",
  "Schoenberg, Germany",
  "Schoenborn, Germany",
  "Schoenbrunn, Germany",
  "Schoenebeck (Elbe), Germany",
  "Schoeneck, France",
  "Schoeneck (Hess), Germany",
  "Schoenefeld (Berlin), Germany",
  "Schoenenberg-Kuebelberg, Germany",
  "Schoental (Jagst), Germany",
  "Schoenwalde, Germany",
  "Schoeppingen, Germany",
  "Schoepstal, Germany",
  "Schoerfling am Attersee, Austria",
  "Schonach (im Schwarzwald), Germany",
  "Schongau, Germany",
  "Schopfheim, Germany",
  "Schopfloch (Freudenstadt), Germany",
  "Schorfheide, Germany",
  "Schorndorf (Wuertt), Germany",
  "Schoten, Belgium",
  "Schramberg, Germany",
  "Schrems, Austria",
  "Schrobenhausen, Germany",
  "Schrozberg, Germany",
  "Schuettorf, Germany",
  "Schutterwald, Germany",
  "Schwabach, Germany",
  "Schwabmuenchen, Germany",
  "Schwaebisch Gmuend, Germany",
  "Schwaebisch Hall, Germany",
  "Schwaig (Oberding), Germany",
  "Schwaig b Nuernberg, Germany",
  "Schwaigern, Germany",
  "Schwalbach (am Taunus), Germany",
  "Schwalmstadt, Germany",
  "Schwanau, Germany",
  "Schwandorf, Germany",
  "Schwanewede, Germany",
  "Schwarzach, Austria",
  "Schwarzach (Odenwald), Germany",
  "Schwarzenbach (a d Saale, Germany",
  "Schwarzenbach (b Weiden), Germany",
  "Schwarzenbek, Germany",
  "Schwarzenberg, Germany",
  "Schwarzenberg (Neukirchen), Germany",
  "Schwarzenfeld, Germany",
  "Schwarzheide, Germany",
  "Schwebheim, Germany",
  "Schwechat, Austria",
  "Schwedt, Germany",
  "Schweighouse sur Moder, France",
  "Schweinfurt, Germany",
  "Schweitenkirchen, Germany",
  "Schwelm, Germany",
  "Schwendi, Germany",
  "Schwenningen (Kr Dillingen), Germany",
  "Schwepnitz, Germany",
  "Schwerin (Meckl), Germany",
  "Schwerstedt (bei Weimar), Germany",
  "Schwertberg, Austria",
  "Schwerte, Germany",
  "Schwieberdingen, Germany",
  "Schaarbeek, Belgium",
  "Sciacca, Italy",
  "Sciapianka, Belarus",
  "Scinawka Srednia, Poland",
  "Scomigo, Italy",
  "Scordia, Italy",
  "Scorze, Italy",
  "Scott, United States",
  "Scottsdale, United States",
  "Scranton, United States",
  "Scunthorpe, United Kingdom",
  "Scurelle, Italy",
  "Sderot, Israel",
  "SEAFORD, United States",
  "Seaham, United Kingdom",
  "Seano, Italy",
  "Seatac, United States",
  "Seattle, United States",
  "SEBARES Asturias, Spain",
  "Sebazac Concoures, France",
  "Sebetov, Czech Republic",
  "Sebewaing, United States",
  "Sebt el Guerdane, Morocco",
  "Secaucus, United States",
  "Seclin, France",
  "Secovce, Slovakia",
  "Secugnago, Italy",
  "Secunda, South Africa",
  "Sedan, France",
  "Seddiner See (b Potsdam), Germany",
  "Sedegliano, Italy",
  "Sedgefield, United Kingdom",
  "Sedico, Italy",
  "Sedlcany, Czech Republic",
  "Sedlnice, Czech Republic",
  "Sedriano, Italy",
  "Sedrina, Italy",
  "Seefeld (Oberbayern), Germany",
  "Seelze, Germany",
  "Seerhausen, Germany",
  "Seesen, Germany",
  "Seething, United Kingdom",
  "Seevetal, Germany",
  "Seewalchen am Attersee, Austria",
  "Sefakoy, Turkey",
  "Seferihisar, Turkey",
  "Sefrou, Morocco",
  "Sega di Cavajon, Italy",
  "Segesd, Hungary",
  "Seggiano di pioltello, Italy",
  "Seghe di Velo, Italy",
  "Segno, Italy",
  "Segonzac, France",
  "Segorbe, Spain",
  "Segovia, Spain",
  "Segrate, Italy",
  "Segre, France",
  "Segre-en-Anjou Bleu, France",
  "Seguin, United States",
  "Segusino, Italy",
  "Sehnde, Germany",
  "Seia, Portugal",
  "Seichamps, France",
  "Seiches sur le Loir, France",
  "Seifhennersdorf, Germany",
  "Seighford Staffordshire, United Kingdom",
  "Seilles, Belgium",
  "Seinajoki, Finland",
  "Seixal, Portugal",
  "Sekerpinar, Turkey",
  "Selb, Germany",
  "Selbitz (Oberfr), Germany",
  "SELBY, South Africa",
  "SELBY, United Kingdom",
  "Selestat, France",
  "Seligenstadt, Germany",
  "Selkirk, United Kingdom",
  "Selkirk, United States",
  "Sellieres, France",
  "Selm, Germany",
  "Selma, United States",
  "Selmarine, Turkey",
  "Selmer, United States",
  "Selnica ob Dravi, Slovenia",
  "Selters (Taunus), Germany",
  "Selters (Westerwald), Germany",
  "Seltz, France",
  "Selvazzano Dentro, Italy",
  "Semarang, Indonesia",
  "Sembach, Germany",
  "Semeac, France",
  "Semic, Slovenia",
  "Semily, Czech Republic",
  "Semond, France",
  "Semoy, France",
  "Sempeter pri Gorici, Slovenia",
  "Sempeter v Savinjski Dolini, Slovenia",
  "Semur-en-Vallon, France",
  "Senago, Italy",
  "Senas, France",
  "Sendai, Japan",
  "Senden, Germany",
  "Senden (Westf), Germany",
  "Sendenhorst, Germany",
  "Senec, Slovakia",
  "Seneffe, Belgium",
  "Senegue, Spain",
  "Sengenthal, Germany",
  "Senica, Slovakia",
  "Seniga, Italy",
  "Senigallia, Italy",
  "Senlis, France",
  "Senna Comasco, Italy",
  "Sennece Les Macon, France",
  "Senonches, France",
  "Senov u Noveho Jicina, Czech Republic",
  "Senov u Ostravy, Czech Republic",
  "Sens, France",
  "Sentjur pri Celju, Slovenia",
  "Sentmenat, Spain",
  "Sentvid pri Sticni, Slovenia",
  "Sepolno Krajenskie, Poland",
  "Seppois le Bas, France",
  "Septemes les Vallons, France",
  "Sequals, Italy",
  "Seravezza, Italy",
  "Sered, Slovakia",
  "Seregelyes, Hungary",
  "Seregno, Italy",
  "Sergiyev Posad, Russia",
  "Seriate, Italy",
  "Serifontaine, France",
  "Serino, Italy",
  "Sermide, Italy",
  "Sermoneta, Italy",
  "Sernaglia della Battaglia, Italy",
  "Seroa, Portugal",
  "Seros, Spain",
  "SERPA, Portugal",
  "Serqueux, France",
  "Serra de Conti, Italy",
  "Serra de Daro, Spain",
  "Serra Ricco, Italy",
  "Serradifalco, Italy",
  "Serramazzoni, Italy",
  "Serranillos del Valle, Spain",
  "Serravalle, Italy",
  "Serravalle Pistoiese, Italy",
  "Serravalle Scrivia, Italy",
  "Serravalle Sesia, Italy",
  "Serre di Rapolano, Italy",
  "Serrekunda, Gambia",
  "Serres, Greece",
  "Serres Castet, France",
  "Serrieres, France",
  "Serris, France",
  "Sersheim, Germany",
  "Servigny-les-Sainte-Barbe, France",
  "Serville, France",
  "Servon sur Vilaine, France",
  "Serzedelo, Portugal",
  "Serzedo, Portugal",
  "Sesena, Spain",
  "Sesena Nuevo, Spain",
  "Sesimbra, Portugal",
  "Sesma, Spain",
  "Sessa Aurunca, Italy",
  "Sessenbach, Germany",
  "Sesslach, Germany",
  "Sestao, Spain",
  "Sestino, Italy",
  "Sesto al Reghena, Italy",
  "Sesto Calende, Italy",
  "Sesto Di Rastignano, Italy",
  "Sesto Fiorentino, Italy",
  "Sesto San Giovanni, Italy",
  "Sesto Ulteriano, Italy",
  "Sestri Levante, Italy",
  "Sesvete, Croatia",
  "Sete Port de Sete, France",
  "Settala, Italy",
  "Settat, Morocco",
  "Settima di Gossolengo, Italy",
  "Settimo di Pescantina, Italy",
  "Settimo Milanese, Italy",
  "Settimo Torinese, Italy",
  "Settle, United Kingdom",
  "Setubal, Portugal",
  "Seva, Spain",
  "Sevelen, Switzerland",
  "Sevenoaks, United Kingdom",
  "Sevenum, Netherlands",
  "Severn Beach Gloucestershire BS, United Kingdom",
  "Seveso, Italy",
  "Sevierville, United States",
  "Sevilla, Spain",
  "Sevilla la Nueva, Spain",
  "Seville, United States",
  "Sevington TN, United Kingdom",
  "Sevlievo, Bulgaria",
  "Sevnica, Slovenia",
  "Sevrey, France",
  "Seymour, United States",
  "Seyssinet-Pariset, France",
  "Sezana, Slovenia",
  "Sezanne, France",
  "Sezimovo Usti, Czech Republic",
  "SezzeLT, Italy",
  "Sfax, Tunisia",
  "S-Gravenzande, Netherlands",
  "Shaftesbury, United Kingdom",
  "Shah Alam, Malaysia",
  "SHAIKH ISA Air Base, Bahrain",
  "Shallow Lake, Canada",
  "Shanghai, China",
  "Shangrao, China",
  "Shannon, United States",
  "Shannon Clare, Ireland",
  "Shantou, China",
  "Shanty Bay, Canada",
  "Shapingba, China",
  "Sharjah, United Arab Emirates",
  "Sharjah Industrial Area, United Arab Emirates",
  "Sharlston, United Kingdom",
  "Sharon, United States",
  "Sharonville, United States",
  "Sharpness, United Kingdom",
  "Sharq El Owainat, Egypt",
  "Shatian, China",
  "Shatura, Russia",
  "Shavington, United Kingdom",
  "Shaw Air Force Base, United States",
  "Shaw Oldham OL, United Kingdom",
  "Shawano, United States",
  "Shawinigan, Canada",
  "Shebbear Devon, United Kingdom",
  "Sheboygan Falls, United States",
  "Shedfield, United Kingdom",
  "Sheen Derbyshire, United Kingdom",
  "S-Heerenberg, Netherlands",
  "Sheerness, United Kingdom",
  "Sheffield, United Kingdom",
  "Shekou, China",
  "Shelbyville, United States",
  "Sheldon Birmingham, United Kingdom",
  "Shelf, United Kingdom",
  "Shelton, United States",
  "Shenstone Staffs, United Kingdom",
  "Shenyang, China",
  "Shepherdsville, United States",
  "Shepperton, United Kingdom",
  "Shepshed, United Kingdom",
  "Shepton Mallet, United Kingdom",
  "Sherborne Dorset, United Kingdom",
  "Sherbrooke, Canada",
  "Sherburn In Elmet, United Kingdom",
  "Sherburn North Yorkshire, United Kingdom",
  "Sheridan, United States",
  "Sheringham, United Kingdom",
  "Shermans Dale, United States",
  "S-Hertogenbosch, Netherlands",
  "Shidao, China",
  "Shifnal, United Kingdom",
  "Shihung, Korea, South",
  "Shildon, United Kingdom",
  "Shilute, Lithuania",
  "Shimizu, Japan",
  "Shiner, United States",
  "Shipdham Norfolk, United Kingdom",
  "Shipley Yorks, United Kingdom",
  "Shirebrook Derbyshire NG, United Kingdom",
  "Shiremoor, United Kingdom",
  "Shirley, United States",
  "Shirley Solihull, United Kingdom",
  "Shishmantsi, Bulgaria",
  "Shoeburyness, United Kingdom",
  "Shoham, Israel",
  "Shorajpur, India",
  "Shoreham, United Kingdom",
  "Shoreham By Sea, United Kingdom",
  "Shotton Flintshire, United Kingdom",
  "Shotts, United Kingdom",
  "Shoumen, Bulgaria",
  "Shreveport, United States",
  "Shrewsbury, United Kingdom",
  "ShrophamNorfolk, United Kingdom",
  "Shuaiba, Kuwait",
  "Shuidong, China",
  "Shuwaikh, Kuwait",
  "Siatista, Greece",
  "Siauliai, Lithuania",
  "Sibiu, Romania",
  "Sibu, Malaysia",
  "SID, Serbia",
  "Sidcup, United Kingdom",
  "Siderno, Italy",
  "Sidi Bou Ali, Tunisia",
  "Sidi Bouzid, Tunisia",
  "Sidi Slimane, Morocco",
  "Siebeldingen, Germany",
  "Siebenlehn, Germany",
  "Sieci, Italy",
  "Siedlce, Poland",
  "Siegburg, Germany",
  "Siegelsbach, Germany",
  "Siegen, Germany",
  "Siegenburg, Germany",
  "Siegsdorf, Germany",
  "Sielenbach, Germany",
  "Sielnica, Slovakia",
  "Siemianowice Slaskie, Poland",
  "Siemiatycze, Poland",
  "Siena, Italy",
  "Sieniawa, Poland",
  "Siepraw, Poland",
  "Sieradz, Poland",
  "Sierakow, Poland",
  "Siero, Spain",
  "SIEROSZEWICE, Poland",
  "Sierpc, Poland",
  "Siershahn, Germany",
  "Sievi, Finland",
  "Siewierz, Poland",
  "Sigmaringen, Germany",
  "Sigmaringendorf, Germany",
  "Signa, Italy",
  "Signes, France",
  "Signoressa di Trevignano, Italy",
  "Sigonella, Italy",
  "Siguatepeque, Honduras",
  "Sigulda, Latvia",
  "Sihanoukville, Cambodia",
  "Siilinjarvi, Finland",
  "Siklos, Hungary",
  "Sikourio, Greece",
  "Silandro, Italy",
  "Silao, Mexico",
  "Silchester, United Kingdom",
  "Silea, Italy",
  "Silifke, Turkey",
  "Silistra, Bulgaria",
  "Silivri, Turkey",
  "Silkeborg, Denmark",
  "Silla, Spain",
  "Sillamae, Estonia",
  "Sille le Guillaume, France",
  "Silleda, Spain",
  "Sillingy, France",
  "Silloth, United Kingdom",
  "Sils, Spain",
  "Silsden, United Kingdom",
  "Silvano d'Orba, Italy",
  "Silverstone, United Kingdom",
  "Silverton, South Africa",
  "Silvertown, United Kingdom",
  "Silvi Marina, Italy",
  "Silvota, Spain",
  "Simandre-sur-Suran, France",
  "Simat de Valldigna, Spain",
  "Simbach (am Inn), Germany",
  "Simbach (Niederbayern), Germany",
  "Simcoe, Canada",
  "Simmern (Hunsrueck), Germany",
  "Simonstone, United Kingdom",
  "Simpsonville, United States",
  "Sinalunga, Italy",
  "Sincan, Turkey",
  "Sindelfingen, Germany",
  "Sindos, Greece",
  "Sines, Portugal",
  "Singapore, Singapore",
  "Singen (Hohentwiel), Germany",
  "Singur, India",
  "Sinn, Germany",
  "Sinnar, India",
  "Sinntal / Altengronau, Germany",
  "Sins, Switzerland",
  "Sinsheim, Germany",
  "Sint Annaland, Netherlands",
  "Sint Baafs-Vijve, Belgium",
  "Sint Jacobiparochie, Netherlands",
  "Sint Katelijne Waver, Belgium",
  "Sint Lenaarts, Belgium",
  "Sint Niklaas East Flanders, Belgium",
  "Sint Oedenrode, Netherlands",
  "Sint Pieters Leeuw, Belgium",
  "Sint Truiden, Belgium",
  "Sintra, Portugal",
  "Sinzig, Germany",
  "Sioux City, United States",
  "Sioux Falls, United States",
  "Siquirres, Costa Rica",
  "Sireuil, France",
  "Sirmione, Italy",
  "Sirnach, Switzerland",
  "Sirok, Hungary",
  "Sirone, Italy",
  "Sisli, Turkey",
  "Sitiawan, Malaysia",
  "Sittard, Netherlands",
  "Sittensen, Germany",
  "Sittingbourne, United Kingdom",
  "Sivas, Turkey",
  "Sivice, Czech Republic",
  "Sixth October, Egypt",
  "Siziano, Italy",
  "Sjoebo, Sweden",
  "Skaelskoer, Denmark",
  "Skagen, Denmark",
  "Skalice Nad Svitavou, Czech Republic",
  "Skalna, Czech Republic",
  "Skals, Denmark",
  "Skanderborg, Denmark",
  "Skara, Sweden",
  "Skarbimierz, Poland",
  "Skarbimierz Osiedle, Poland",
  "Skarszewy, Poland",
  "Skaryszew, Poland",
  "Skarzysko Kamienna, Poland",
  "Skawina, Poland",
  "Skedsmokorset, Norway",
  "Skegness, United Kingdom",
  "Skellefteaa, Sweden",
  "Skelmersdale, United Kingdom",
  "Skelton Redcar and Cleveland, United Kingdom",
  "Skhirat, Morocco",
  "Skien, Norway",
  "Skierniewice, Poland",
  "Skikda, Algeria",
  "Skillingaryd, Sweden",
  "Skinnskatteberg, Sweden",
  "Skipton, United Kingdom",
  "Skive, Denmark",
  "Skjern, Denmark",
  "Skoczow, Poland",
  "Skodborg, Denmark",
  "Skodje, Norway",
  "Skoeldinge, Sweden",
  "Skoevde, Sweden",
  "Skofja Loka, Slovenia",
  "Skofljica, Slovenia",
  "Skogn, Norway",
  "Skolyszyn, Poland",
  "Skopje, Macedonia",
  "Skrivany, Czech Republic",
  "Skrzyszow, Poland",
  "Skydra, Greece",
  "Sladki Vrh, Slovenia",
  "Sladkovicovo, Slovakia",
  "Slagelse, Denmark",
  "Slany, Czech Republic",
  "Slapanice, Czech Republic",
  "Slapanov, Czech Republic",
  "Slate Hill, United States",
  "Slatina, Romania",
  "Slatinany, Czech Republic",
  "Slaton, United States",
  "Slavicin, Czech Republic",
  "Slavkov u Brna, Czech Republic",
  "Slavkov-near Uherske Hradiste, Czech Republic",
  "Slavonice, Czech Republic",
  "Slavonski Brod, Croatia",
  "Slavosovce, Slovakia",
  "Slawkow, Poland",
  "Slawno, Poland",
  "Sleaford Lincs, United Kingdom",
  "Slesin, Poland",
  "Slidell, United States",
  "Sligo Co Sligo, Ireland",
  "Sliven, Bulgaria",
  "Sliwice, Poland",
  "Slomniki, Poland",
  "Slough, United Kingdom",
  "Slovenj Gradec, Slovenia",
  "Slovenska Bistrica, Slovenia",
  "Slovenska Lupca, Slovakia",
  "Slovenske Konjice, Slovenia",
  "Slubice, Poland",
  "Sluiskil, Netherlands",
  "Sluknov, Czech Republic",
  "Slupca, Poland",
  "Slupsk, Poland",
  "Slusovice, Czech Republic",
  "Small Dole, United Kingdom",
  "Smarden, United Kingdom",
  "Smethwick, United Kingdom",
  "Smirice, Czech Republic",
  "Smithfield, United States",
  "Smithville, United States",
  "Smolenice, Slovakia",
  "Smolian, Bulgaria",
  "Smrzice, Czech Republic",
  "Smyrna, United States",
  "Smaalandsstenar, Sweden",
  "Sneedville, United States",
  "Sneek, Netherlands",
  "Snetterton, United Kingdom",
  "Snodland, United Kingdom",
  "Snohomish, United States",
  "Soave, Italy",
  "Sobeslav, Czech Republic",
  "Sobienie Jeziory, Poland",
  "Sobolew, Poland",
  "Sobradelo de Valdeorras, Spain",
  "Sobradiel, Spain",
  "Sobral de Monte, Portugal",
  "Sobrance, Slovakia",
  "Sochaczew, Poland",
  "Social Circle, United States",
  "Socuellamos, Spain",
  "Soedertaelje, Sweden",
  "Soemmerda, Germany",
  "Soender Felding, Denmark",
  "Soender Onsild, Denmark",
  "Soendersoe, Denmark",
  "Soerup, Germany",
  "Soest, Germany",
  "Soest, Netherlands",
  "Soesterberg, Netherlands",
  "Sofia, Bulgaria",
  "Sofiemyr, Norway",
  "Soham, United Kingdom",
  "Sohar, Oman",
  "Sohland, Germany",
  "Sohren, Germany",
  "Soiano del Lago, Italy",
  "Soignies, Belgium",
  "Soissons, France",
  "Soisy Sous Montmorency, France",
  "Soke, Turkey",
  "Sokhna, Egypt",
  "Sokolov, Czech Republic",
  "Sokolow Podlaski, Poland",
  "Solagna, Italy",
  "Solara, Italy",
  "Solaro, Italy",
  "Solaro PR, Italy",
  "Solarolo, Italy",
  "Solarolo Rainerio, Italy",
  "Solbiate, Italy",
  "Solbiate Arno, Italy",
  "Solbiate Olona, Italy",
  "Solec Kujawski, Poland",
  "Solero, Italy",
  "Solesino, Italy",
  "Solesmes, France",
  "Soleuvre, Luxemburg",
  "Soliera, Italy",
  "Solignano, Italy",
  "Solignano Nuovo, Italy",
  "Soligo, Italy",
  "Solihull, United Kingdom",
  "Soliman, Tunisia",
  "Solingen, Germany",
  "Sollana, Spain",
  "Sollebrunn, Sweden",
  "Sollenau, Austria",
  "Solms (Lahn), Germany",
  "Solnhofen, Germany",
  "Solofra, Italy",
  "Solon, United States",
  "Solothurn, Switzerland",
  "Somaglia, Italy",
  "Sombreffe, Belgium",
  "Somercotes, United Kingdom",
  "Somerdale, United States",
  "Someren, Netherlands",
  "Somerset, United States",
  "Somerton Somerset, United Kingdom",
  "Somma Lombardo, Italy",
  "Somma Vesuviana, Italy",
  "Sommacampagna, Italy",
  "Sommariva Bosco, Italy",
  "Sompolno, Poland",
  "Son, Netherlands",
  "Son en Breugel, Netherlands",
  "Sona, Italy",
  "Soncino, Italy",
  "Sondershausen, Germany",
  "Sondika, Spain",
  "Sondrio, Italy",
  "Soneja, Spain",
  "Sonepat, India",
  "Songkhla, Thailand",
  "Sonneberg (Thuer), Germany",
  "Sonnefeld, Germany",
  "Sonnenbuehl, Germany",
  "Sons et Roncheres, France",
  "Sonseca, Spain",
  "Sonsk, Poland",
  "Sonsonate, El Salvador",
  "Sontheim (an der Brenz), Germany",
  "Sonthofen, Germany",
  "Sopelana, Spain",
  "Sopron, Hungary",
  "Sora, Italy",
  "Soragna, Italy",
  "Sorano, Italy",
  "Sorbara, Italy",
  "Sorbolo, Italy",
  "Sordio, Italy",
  "Soresina, Italy",
  "Sorga, Italy",
  "Sorgues, France",
  "Soria, Spain",
  "Soriano Calabro, Italy",
  "Soriano del Cimino, Italy",
  "Sorinnes, Belgium",
  "Sornzig-Ablass, Germany",
  "Soroe, Denmark",
  "Sorrento, Italy",
  "Soses, Spain",
  "Sosnowiec, Poland",
  "Sospiro, Italy",
  "Sospirolo, Italy",
  "Sot de Ferrer, Spain",
  "Sotillo de la Adrada, Spain",
  "Soto de la Marina, Spain",
  "Soto de la Vega, Spain",
  "Soto del Real, Spain",
  "Sotopalacios, Spain",
  "Sotrondio, Spain",
  "Sotteville les Rouen, France",
  "Sotto il Monte, Italy",
  "Souda, Greece",
  "Souderton, United States",
  "Soufflenheim, France",
  "Souge Le Gamelon, France",
  "Souk el Arbaa, Morocco",
  "Souk Sebt, Morocco",
  "Soultz Haut Rhin, France",
  "Soultzmatt, France",
  "Soumagne Micheroux, Belgium",
  "Souprosse, France",
  "Soure, Portugal",
  "Sousse, Tunisia",
  "South Bend, United States",
  "South Charleston, United States",
  "South Deerfield, United States",
  "South Hackensack, United States",
  "South Haven, United States",
  "South Kirkby, United Kingdom",
  "South Molton, United Kingdom",
  "South Normanton, United Kingdom",
  "South Nutfield, United Kingdom",
  "South Ockendon, United Kingdom",
  "South Pittsburg, United States",
  "South Plainfield, United States",
  "South Queensferry, United Kingdom",
  "South River, United States",
  "South Shields, United Kingdom",
  "South Solon, United States",
  "South Windsor, United States",
  "Southall London UB, United Kingdom",
  "Southam Gloucs, United Kingdom",
  "Southam Warwickshire, United Kingdom",
  "Southampton, United Kingdom",
  "Southaven, United States",
  "Southend-on-Sea, United Kingdom",
  "Southfield, United States",
  "Southmoor, United Kingdom",
  "Southport, United Kingdom",
  "Southsea Portsmouth, United Kingdom",
  "Southwell Nottinghamshire, United Kingdom",
  "Southwold, United Kingdom",
  "Sovere, Italy",
  "Sovicille, Italy",
  "Sovico, Italy",
  "Sovigliana, Italy",
  "Sovizzo, Italy",
  "Sowerby Bridge, United Kingdom",
  "Soyapango, El Salvador",
  "Spaichingen, Germany",
  "Spalding, United Kingdom",
  "Spalt, Germany",
  "Spangdahlem, Germany",
  "Sparanise, Italy",
  "Sparkford, United Kingdom",
  "Sparkman, United States",
  "Sparks, United States",
  "Sparta, United States",
  "Spartanburg, United States",
  "Sparti, Greece",
  "Spay, France",
  "Specchia, Italy",
  "Speichersdorf, Germany",
  "Speke, United Kingdom",
  "Spellbrook, United Kingdom",
  "Spelle, Germany",
  "Spello, Italy",
  "Spennymoor, United Kingdom",
  "Speyer, Germany",
  "Spezzano, Italy",
  "Spijk, Netherlands",
  "Spijkenisse, Netherlands",
  "Spilamberto, Italy",
  "Spilimbergo, Italy",
  "Spilsby, United Kingdom",
  "Spinadesco, Italy",
  "Spinea, Italy",
  "Spinetta Maren, Italy",
  "Spino d'Adda, Italy",
  "Spirano, Italy",
  "Spisska Bela, Slovakia",
  "Spisska Nova Ves, Slovakia",
  "Split, Croatia",
  "Spodnja Hajdina, Slovenia",
  "Spoettrup, Denmark",
  "Spokane, United States",
  "Spoleto, Italy",
  "Spreitenbach, Switzerland",
  "Spremberg, Germany",
  "Spresiano, Italy",
  "Sprimont Liege, Belgium",
  "Spring Valley, United States",
  "Springboro, United States",
  "Springdale, United States",
  "Springe, Germany",
  "Springfield, United States",
  "Sprockhoevel, Germany",
  "Squinzano, Italy",
  "Sremska Mitrovica, Serbia",
  "Sri City, India",
  "Sriperumbudur, India",
  "Sroda Slaska, Poland",
  "Sroda Wielkopolska, Poland",
  "St Albans, United Kingdom",
  "St Andrews, United Kingdom",
  "St Asaph, United Kingdom",
  "St Austell, United Kingdom",
  "St Dennis, United Kingdom",
  "St Gallen, Switzerland",
  "St Gangloff, Germany",
  "St Helens, United Kingdom",
  "St Ives Cambridgeshire, United Kingdom",
  "St John's, Canada",
  "St Katharinen (bei Linz am Rhein), Germany",
  "St Leonards E Sussex, United Kingdom",
  "St Margrethen, Switzerland",
  "St Neots, United Kingdom",
  "St Petersburg, Russia",
  "St Petersburg FCT, Russia",
  "St Petersburg FP, Russia",
  "St Veit, Austria",
  "Sta Amalia, Spain",
  "Sta Barbara, Spain",
  "Sta Coloma de Farnes, Spain",
  "Sta Coloma de Queralt, Spain",
  "Sta Coloma Gramanet, Spain",
  "Sta Cruz, Spain",
  "Sta Cruz de Mudela, Spain",
  "Sta Margarida I Monjos, Spain",
  "Sta Maria de Corco, Spain",
  "Sta Maria de Palautordera, Spain",
  "Sta Maria del Aguila, Spain",
  "Sta Maria d'Olo, Spain",
  "Sta Oliva, Spain",
  "Sta Perpetua de Moguda, Spain",
  "Sta Pola, Spain",
  "Stabio, Switzerland",
  "Stabroek, Belgium",
  "Stade, Germany",
  "Stadlandet, Norway",
  "Stadtallendorf, Germany",
  "Stadthagen, Germany",
  "Stadtlengsfeld, Germany",
  "Stadtlohn, Germany",
  "Stadtoldendorf, Germany",
  "Stadtprozelten, Germany",
  "Staffanstorp, Sweden",
  "Stafford, United Kingdom",
  "Stafford, United States",
  "Stagno, Italy",
  "Stahlhofen am Wiesensee, Germany",
  "Staines, United Kingdom",
  "Stains, France",
  "Stalbridge, United Kingdom",
  "Stalhille, Belgium",
  "Stallavena, Italy",
  "Stallingborough, United Kingdom",
  "Stalowa Wola, Poland",
  "Stalybridge, United Kingdom",
  "Stambolijski, Bulgaria",
  "Stamford Lincolnshire, United Kingdom",
  "Stampersgat, Netherlands",
  "Standish, United States",
  "Standon Hertfordshire, United Kingdom",
  "Stanford-le-Hope, United Kingdom",
  "Stange, Norway",
  "Stanghella, Italy",
  "Stanhope Durham, United Kingdom",
  "Stankov, Czech Republic",
  "Stanley Durham, United Kingdom",
  "Stanmore London, United Kingdom",
  "Stanningley, United Kingdom",
  "Stans, Austria",
  "Stanstead, Canada",
  "Stansted, United Kingdom",
  "Stanton by Dale, United Kingdom",
  "Stanton Suffolk, United Kingdom",
  "Stanwell, United Kingdom",
  "Staporkow, Poland",
  "Star, United States",
  "Stara Boleslav, Czech Republic",
  "Stara Bystrica, Slovakia",
  "Stara Iwiczna, Poland",
  "Stara Lubovna, Slovakia",
  "Stara Pazova, Serbia",
  "Stara Tura, Slovakia",
  "Stara Voda, Czech Republic",
  "Stara Zagora, Bulgaria",
  "Starachowice, Poland",
  "Staranzano, Italy",
  "Stare Hradiste, Czech Republic",
  "Stare Mesto, Czech Republic",
  "Stare Mesto pod Sneznikem, Czech Republic",
  "Stare Mesto u Uherskeho Hradiste, Czech Republic",
  "Stare Miasto, Poland",
  "Stargard Szczecinski, Poland",
  "Stari Trg pri Lozu, Slovenia",
  "Staric, Czech Republic",
  "Starnberg, Germany",
  "Starogard Gdanski, Poland",
  "Stary Materov, Czech Republic",
  "Stassfurt, Germany",
  "Statesboro, United States",
  "Statesville, United States",
  "Staufenberg (Hess), Germany",
  "Stavanger, Norway",
  "Stavrochori, Greece",
  "Stazzano, Italy",
  "Stearns, United States",
  "Stechford Birmingham, United Kingdom",
  "STEELE, United States",
  "Steenbergen, Netherlands",
  "Steenvoorde, France",
  "Steeton, United Kingdom",
  "Stegelitz, Germany",
  "Stei, Romania",
  "Stein (Mittelfranken), Germany",
  "Steinach (Baden), Germany",
  "Steinach (Thuer), Germany",
  "Steinau (an der Strasse), Germany",
  "Steinbach (Taunus), Germany",
  "Steinbach am Wald, Germany",
  "Steinbach-Hallenberg, Germany",
  "Steinbourg, France",
  "Steinfeld (Oldenburg), Germany",
  "Steinfurt, Germany",
  "Steinhagen (Westf), Germany",
  "Steinhausen, Switzerland",
  "Steinhausen an der Rottum, Germany",
  "Steinheim (Westf), Germany",
  "Steinheim an der Murr, Germany",
  "Steinwiesen, Germany",
  "Steisslingen, Germany",
  "Stekene, Belgium",
  "Stelle (Kr Harburg), Germany",
  "Stembert, Belgium",
  "Stemwede, Germany",
  "Stenay, France",
  "Stendal, Germany",
  "Steni Evoias, Greece",
  "Stenkullen, Sweden",
  "Stenovice, Czech Republic",
  "Stenungsund, Sweden",
  "Stepanivka, Ukraine",
  "Stepanov, Czech Republic",
  "Stephanskirchen (Simssee), Germany",
  "Stephansposching, Germany",
  "Sterling, United States",
  "Sterling Heights, United States",
  "Sternberk, Czech Republic",
  "Steszew, Poland",
  "Steti, Czech Republic",
  "Stetten, Austria",
  "Stetten - Schwab, Germany",
  "Stetten (am Kalten Markt), Germany",
  "Steubenville, United States",
  "Stevenage, United Kingdom",
  "Stevenston, United Kingdom",
  "Stevensville, United States",
  "Steventon Oxfordshire, United Kingdom",
  "Stewart, United States",
  "Stewarton East Ayrshire, United Kingdom",
  "Steyerberg, Germany",
  "Steyr, Austria",
  "Steyregg, Austria",
  "Steyrermuehl, Austria",
  "Stezzano, Italy",
  "Stickney Lincolnshire, United Kingdom",
  "Stikland, South Africa",
  "Stilling, Denmark",
  "Stilo, Italy",
  "Stilwell, United States",
  "Stip, Macedonia",
  "Stirling Scotland, United Kingdom",
  "Stjoerdal, Norway",
  "Sto Domingo Calzada, Spain",
  "Stockach, Germany",
  "Stockelsdorf, Germany",
  "Stockerau, Austria",
  "Stockertown, United States",
  "Stockheim, Germany",
  "Stockholm, Sweden",
  "Stockport, United Kingdom",
  "Stockstadt am Rhein, Germany",
  "Stockton, United States",
  "Stockton-on-Tees, United Kingdom",
  "Stoevring, Denmark",
  "Stokeham, United Kingdom",
  "Stoke-on-Trent, United Kingdom",
  "Stolberg (Rhld), Germany",
  "Stolin, Belarus",
  "Stolzenau, Germany",
  "Stone Kent, United Kingdom",
  "Stone Mountain, United States",
  "Stone Staffs, United Kingdom",
  "Stoneham, United States",
  "Stonehaven, United Kingdom",
  "Stonehouse Gloucestershire, United Kingdom",
  "Stoney Creek, Canada",
  "Stopini, Latvia",
  "Store, Slovenia",
  "Stornarella, Italy",
  "Storo, Italy",
  "Storvorde, Denmark",
  "Stourbridge, United Kingdom",
  "Stourport-O-Sever, United Kingdom",
  "Stourton, United Kingdom",
  "Stowbridge Norfolk, United Kingdom",
  "Stowmarket, United Kingdom",
  "Stoystown, United States",
  "Stradbroke, United Kingdom",
  "Stradella, Italy",
  "Stradonice, Czech Republic",
  "Straelen, Germany",
  "Strakonice, Czech Republic",
  "Straldja, Bulgaria",
  "Strancice, Czech Republic",
  "Strand, South Africa",
  "Stranraer, United Kingdom",
  "Strasbourg, France",
  "Strasburg, United States",
  "Strassberg, Germany",
  "Strasskirchen, Germany",
  "Straszyn, Poland",
  "Stratford, United States",
  "Stratford London, United Kingdom",
  "Stratford-upon-Avon, United Kingdom",
  "Stratham, United States",
  "Stratoni, Greece",
  "Straubenhardt, Germany",
  "Straubing, Germany",
  "Straumen, Norway",
  "Straz Nad Nisou, Czech Republic",
  "Straz Pod Ralskem, Czech Republic",
  "Straza, Slovenia",
  "Straznice, Czech Republic",
  "Strazske, Slovakia",
  "Streator, United States",
  "Strecno, Slovakia",
  "Stredokluky, Czech Republic",
  "Street Somerset, United Kingdom",
  "Streetsboro, United States",
  "Stretford Manchester M, United Kingdom",
  "Stretton Cheshire, United Kingdom",
  "Striano, Italy",
  "Stribro, Czech Republic",
  "Strijbeek, Netherlands",
  "Stritez (U Jihlavy), Czech Republic",
  "Strmilov, Czech Republic",
  "Stroemstad, Sweden",
  "Strofilia, Greece",
  "Stroncone, Italy",
  "Stronie Slaskie, Poland",
  "Strood, United Kingdom",
  "Stroud Gloucestershire, United Kingdom",
  "Struandale, South Africa",
  "Struga, Macedonia",
  "Strullendorf, Germany",
  "Strumica, Macedonia",
  "Stryama, Bulgaria",
  "Strykow, Poland",
  "Strzebielino, Poland",
  "Strzegom, Poland",
  "Strzelce Opolskie, Poland",
  "Strzelin, Poland",
  "Strzelno, Poland",
  "Studenec, Slovenia",
  "Studenec U Horek, Czech Republic",
  "Studenka, Czech Republic",
  "Studley - Warwickshire, United Kingdom",
  "Stuehlingen, Germany",
  "Stuetzengruen, Germany",
  "Stuhr, Germany",
  "Stulln, Germany",
  "Stupino, Russia",
  "Sturgis, United States",
  "Sturminster Newton Dorset, United Kingdom",
  "Sturovo, Slovakia",
  "Sturtevant, United States",
  "Stuttgart, Germany",
  "Stuttgart, United States",
  "Stylida, Greece",
  "Suape, Brazil",
  "Subbiano, Italy",
  "Subic, Philippines",
  "Subirats, Spain",
  "Subotica, Serbia",
  "Sucany, Slovakia",
  "Sucha Beskidzka, Poland",
  "Suchy Dwor, Poland",
  "Sucina, Spain",
  "Sucy en Brie, France",
  "Sudanell, Spain",
  "Sudbury Suffolk, United Kingdom",
  "Sudomerice, Czech Republic",
  "Sueca, Spain",
  "Suederluegum, Germany",
  "Suedlohn, Germany",
  "Suello, Italy",
  "Suelzetal, Germany",
  "Suenching, Germany",
  "Suessen, Germany",
  "Suffolk, United States",
  "Sugar Land, United States",
  "Suhindol, Bulgaria",
  "Suhl, Germany",
  "Suhr, Switzerland",
  "Suisio, Italy",
  "Sulbiate, Italy",
  "Sulechow, Poland",
  "Sulejowek, Poland",
  "Sulignat, France",
  "Sulingen, Germany",
  "Sully, United Kingdom",
  "Sully sur Loire, France",
  "Sulmona, Italy",
  "Sulphur Springs, United States",
  "Sultanbeyli, Turkey",
  "Sultanhani, Turkey",
  "Sulz (am Neckar), Germany",
  "Sulzbach, Germany",
  "Sulzbach-Rosenberg, Germany",
  "Sulzberg, Germany",
  "Sulzdorf (a d Lederhecke), Germany",
  "Sulzemoos, Germany",
  "Sulzheim (Unterfr), Germany",
  "Sumare, Brazil",
  "Sumirago, Italy",
  "Summaga, Italy",
  "Summerville, United States",
  "Summonte, Italy",
  "Sumner, United States",
  "Sumperk, Czech Republic",
  "Sumter, United States",
  "Sumy, Ukraine",
  "Sun Prairie, United States",
  "Sun Valley, United States",
  "SUNBURY, United States",
  "Sunbury-On-Thames, United Kingdom",
  "Sundar Industrial Estate, Pakistan",
  "Sunderland Tyne And Wear, United Kingdom",
  "Sundern (Sauerland), Germany",
  "Sundsbruk, Sweden",
  "Sundsvall, Sweden",
  "Sunndalsoera, Norway",
  "Sunningdale, United Kingdom",
  "Sunnyhill, United Kingdom",
  "Sunnyridge EBS, South Africa",
  "Sunnyvale, United States",
  "Suno, Italy",
  "Suolahti, Finland",
  "Surabaya, Indonesia",
  "Surany, Slovakia",
  "Surat, India",
  "Surbiton, United Kingdom",
  "Surbourg, France",
  "SURCIN, Serbia",
  "Surgeres, France",
  "Surrey, Canada",
  "Sursee, Switzerland",
  "Sury le Comtal, France",
  "Susegana, Italy",
  "Susice, Czech Republic",
  "Susquehanna, United States",
  "Sustinente, Italy",
  "Susurluk, Turkey",
  "Sutton Bridge, United Kingdom",
  "Sutton Cambridgeshire CB, United Kingdom",
  "Sutton Coldfield, United Kingdom",
  "Sutton Surrey, United Kingdom",
  "Sutton-in-Ashfield, United Kingdom",
  "Suurbekom, South Africa",
  "Suva, Fiji Islands",
  "Suvereto, Italy",
  "Suwalki, Poland",
  "Suwanee, United States",
  "Suzhou, China",
  "Suzzara, Italy",
  "Svanskog, Sweden",
  "Svelgen, Norway",
  "Svendborg, Denmark",
  "Svenstrup, Denmark",
  "Sveta Nedelja, Croatia",
  "Svetla Nad Sazavou, Czech Republic",
  "Svetlyy, Russia",
  "Sviadnov, Czech Republic",
  "Svidnik, Slovakia",
  "Svilengrad, Bulgaria",
  "Svinndal, Norway",
  "Svishtov, Bulgaria",
  "Svit, Slovakia",
  "Svitavy, Czech Republic",
  "Svoge, Bulgaria",
  "Swadlincote, United Kingdom",
  "Swakopmund, Namibia",
  "Swallowfield, United Kingdom",
  "Swalmen, Netherlands",
  "Swalwell, United Kingdom",
  "Swaminatham, India",
  "Swanley, United Kingdom",
  "Swannanoa, United States",
  "Swanscombe Kent, United Kingdom",
  "Swansea, United Kingdom",
  "Swanton, United States",
  "Swartland, South Africa",
  "Swarzedz, Poland",
  "Swedesboro, United States",
  "Swidnica, Poland",
  "Swidnik, Poland",
  "Swiebodzice, Poland",
  "Swiebodzin, Poland",
  "Swiecie, Poland",
  "Swieciechowa, Poland",
  "Swierklaniec, Poland",
  "Swietochlowice, Poland",
  "Swifterbant, Netherlands",
  "Swindon Swindon, United Kingdom",
  "SwinesheadLincs, United Kingdom",
  "Swinoujscie, Poland",
  "Swinton Manchester, United Kingdom",
  "Swinton South Yorks, United Kingdom",
  "Swords Dublin, Ireland",
  "Sycow, Poland",
  "Sydney, Australia",
  "Syke, Germany",
  "Sykkylven, Norway",
  "Sylacauga, United States",
  "Syracuse, United States",
  "Syrgenstein, Germany",
  "Syrovice, Czech Republic",
  "Syston Leics, United Kingdom",
  "Sywell, United Kingdom",
  "Szalkszentmarton, Hungary",
  "Szamotuly, Poland",
  "Szarvas, Hungary",
  "Szazhalombatta, Hungary",
  "Szczecin, Poland",
  "Szczecinek, Poland",
  "Szczercow, Poland",
  "Szczytno, Poland",
  "Szecseny, Hungary",
  "Szeged, Hungary",
  "Szekesfehervar, Hungary",
  "Szekszard, Hungary",
  "Szentendre, Hungary",
  "Szentes, Hungary",
  "Szentgotthard, Hungary",
  "Szentlorinc, Hungary",
  "Szerencs, Hungary",
  "Szigetszentmiklos, Hungary",
  "Szolnok, Hungary",
  "Szombathely, Hungary",
  "Szprotawa, Poland",
  "Sztum, Poland",
  "Saal (A D  Saale), Germany",
  "Saal (an der Donau), Germany",
  "Saalburg Ebersdorf, Germany",
  "Saalfeld/Saale, Germany",
  "Saalfelden, Austria",
  "Saarbruecken, Germany",
  "Saarburg, Germany",
  "Saarlouis, Germany",
  "Saarwellingen, Germany",
  "Tabasalu, Estonia",
  "Taberg, Sweden",
  "Tabernas Almeria, Spain",
  "Taboadela, Spain",
  "Tabor, Czech Republic",
  "Tabua, Portugal",
  "Tacambaro, Mexico",
  "Tacherting, Germany",
  "Tachov, Czech Republic",
  "Tacna, Peru",
  "Tacoma, United States",
  "Tacoronte, Spain",
  "Tadcaster, United Kingdom",
  "Tadley, United Kingdom",
  "Tadworth, United Kingdom",
  "Taeby, Sweden",
  "Tafalla, Spain",
  "Tagolsheim, France",
  "Taicang, China",
  "Taichung, Taiwan China",
  "Tain Highland, United Kingdom",
  "Taino, Italy",
  "Taipei, Taiwan China",
  "Taivassalo, Finland",
  "Taiyuan, China",
  "Taizhou, China",
  "Tajonar, Spain",
  "Takeley, United Kingdom",
  "Takoradi, Ghana",
  "Talacchio, Italy",
  "Talamona, Italy",
  "TALARRUBIAS, Spain",
  "Talavera de la Reina, Spain",
  "Talayuela, Spain",
  "Talca, Chile",
  "Talcahuano, Chile",
  "Talegaon, India",
  "Tallaght Dublin, Ireland",
  "Tallinn, Estonia",
  "Tallmadge, United States",
  "Talmassons, Italy",
  "TALOD, India",
  "Taloja, India",
  "Tamai, Italy",
  "Tamara, Honduras",
  "Tamarite de Litera, Spain",
  "Tamasi, Hungary",
  "Tambach-Dietharz, Germany",
  "Tamm, Germany",
  "Tampa, United States",
  "Tampere, Finland",
  "Tampoi, Malaysia",
  "Tamworth, United Kingdom",
  "Tan Uyen, Vietnam",
  "Tananger, Norway",
  "Tancitaro, Mexico",
  "Tando Allahyar, Pakistan",
  "Tanfield, United Kingdom",
  "Tanga, Tanzania",
  "Tangail, Bangladesh",
  "Tangent, United States",
  "Tangermuende, Germany",
  "Tangier City, Morocco",
  "Tangmere, United Kingdom",
  "Tanji, Gambia",
  "Tanjung Pelepas, Malaysia",
  "Tanna, Germany",
  "Tanner, United States",
  "Tannhausen, Germany",
  "Tannheim, Germany",
  "Tanta, Egypt",
  "Tantan, Morocco",
  "Tanvald, Czech Republic",
  "Tao Yuan, Taiwan China",
  "Tapachula, Mexico",
  "Tapfheim, Germany",
  "Tapia De Casariego, Spain",
  "Taplow, United Kingdom",
  "Tapolca, Hungary",
  "Taquaritinga, Brazil",
  "Tarancon, Spain",
  "Tarantasca, Italy",
  "Taranto, Italy",
  "Tarare, France",
  "Tarascon sur Rhone, France",
  "Tarazona, Spain",
  "Tarazona de la Mancha, Spain",
  "Tarcento, Italy",
  "Tarczyn, Poland",
  "Tardienta, Spain",
  "Targovishte, Bulgaria",
  "Targoviste, Romania",
  "Targu Mures, Romania",
  "Tarifa, Spain",
  "Tarm, Denmark",
  "Tarnobrzeg, Poland",
  "Tarnow, Poland",
  "Tarnowo Podgorne, Poland",
  "Tarnowskie Gory, Poland",
  "Taroudant, Morocco",
  "Tarp, Germany",
  "Tarporley, United Kingdom",
  "Tarragona, Spain",
  "Tarrega, Spain",
  "Tarsus, Turkey",
  "Tartu, Estonia",
  "Tarvisio, Italy",
  "Tassignano, Italy",
  "Tassila, Morocco",
  "Tassin LA Demi Lune, France",
  "Tata, Hungary",
  "Tatabanya, Hungary",
  "Tataouine, Tunisia",
  "Tauberbischofsheim, Germany",
  "Taufkirchen, Germany",
  "Taufkirchen (Vils), Germany",
  "Taufkirchen an der Trattnach, Austria",
  "Taulignan, France",
  "Taulov, Denmark",
  "Taunton, United Kingdom",
  "Taunton, United States",
  "Taunusstein, Germany",
  "Taurage, Lithuania",
  "Tauranga, New Zealand",
  "Tauriano, Italy",
  "Taurianova, Italy",
  "Tauste, Spain",
  "Tauves, France",
  "Tauxigny, France",
  "Tavagnacco, Italy",
  "Tavarnelle Val di Pesa, Italy",
  "Tavaux, France",
  "Tavaux-et-Pontsericourt, France",
  "Tavazzano, Italy",
  "Taverne, Switzerland",
  "Tavernerio, Italy",
  "Tavernes de Valldigna, Spain",
  "Tavernole sul Mella, Italy",
  "Tavros, Greece",
  "Tavullia, Italy",
  "Tawau, Malaysia",
  "Taweelah, United Arab Emirates",
  "Taylor, United States",
  "Taylors, United States",
  "Tayport Fife, United Kingdom",
  "Tbilisi, Georgia",
  "Tczew, Poland",
  "Teapa, Mexico",
  "Tebing Tinggi Jambi, Indonesia",
  "Tecate, Mexico",
  "Tecoman, Mexico",
  "Tecovice, Czech Republic",
  "Tecpan Guatemala, Guatemala",
  "Tecumseh, United States",
  "Teddington London, United Kingdom",
  "Teesport, United Kingdom",
  "Teglio Veneto, Italy",
  "Tegucigalpa, Honduras",
  "Teichwolframsdorf, Germany",
  "Teignmouth Devon TQ, United Kingdom",
  "Teising, Germany",
  "Teisnach, Germany",
  "TEIXEIRO, Spain",
  "Tekirdag, Turkey",
  "Tela, Honduras",
  "Telavi, Georgia",
  "Telc, Czech Republic",
  "Teleshivka, Ukraine",
  "Telford, United Kingdom",
  "Telford, United States",
  "Telgate, Italy",
  "Telnice, Czech Republic",
  "Telsai, Lithuania",
  "Tema, Ghana",
  "Tembleque, Spain",
  "Temelin, Czech Republic",
  "Temelli, Turkey",
  "TEMPE, United States",
  "Temple, United States",
  "Templecombe, United Kingdom",
  "Templemore Tipperary, Ireland",
  "Templin, Germany",
  "Temse, Belgium",
  "Temuco, Chile",
  "Tenay, France",
  "Tenbury Worcester, United Kingdom",
  "Tenby, United Kingdom",
  "Tenczynek, Poland",
  "Tenerife, Spain",
  "Teningen, Germany",
  "Tenterden, United Kingdom",
  "Tentugal, Portugal",
  "Teo, Spain",
  "Teolo, Italy",
  "Teoloyucan, Mexico",
  "Teotitlan del Valle, Mexico",
  "Tepatitlan, Mexico",
  "Tepeji del Rio, Mexico",
  "Tepic, Mexico",
  "Teplice, Czech Republic",
  "Teplicka nad Vahom, Slovakia",
  "Tequila, Mexico",
  "Ter Apelkanaal, Netherlands",
  "Teramo, Italy",
  "Teresin, Poland",
  "Terlano, Italy",
  "Termeno, Italy",
  "Termens, Spain",
  "Termini Imerese, Italy",
  "Termoli, Italy",
  "Ternate, Italy",
  "Terneuzen, Netherlands",
  "Terni, Italy",
  "Terno d'Isola, Italy",
  "Ternopil, Ukraine",
  "Terranova Dei Passerini, Italy",
  "Terrassa, Spain",
  "Terrazzo, Italy",
  "Terre del Reno, Italy",
  "Terre Haute, United States",
  "Terrebonne, Canada",
  "Terrell, United States",
  "Terrer, Spain",
  "Terrugem, Portugal",
  "Terruggia, Italy",
  "Tertre, Belgium",
  "Teruel, Spain",
  "Tervajoki, Finland",
  "Terzigno, Italy",
  "Tesero, Italy",
  "Tesjoki, Finland",
  "Tessenderlo, Belgium",
  "Tetbury, United Kingdom",
  "Teterboro, United States",
  "Tetovo, Macedonia",
  "Tettau (Oberfr ), Germany",
  "Tettnang, Germany",
  "Teuschnitz, Germany",
  "Teutopolis, United States",
  "Teutschenthal, Germany",
  "Teverola, Italy",
  "Tewkesbury, United Kingdom",
  "Texarkana, United States",
  "Texcoco de Mora, Mexico",
  "Tezoyuca, Mexico",
  "Tezze sul Brenta, Italy",
  "Thaleischweiler-Froeschen, Germany",
  "Thalfang, Germany",
  "Thalheim, Germany",
  "Thalheim (Erzgeb), Germany",
  "Thame Oxon, United Kingdom",
  "Thames Ditton, United Kingdom",
  "Thamesmead, United Kingdom",
  "Thann, France",
  "Thansau, Germany",
  "THAR, India",
  "Thasos, Greece",
  "Thatcham, United Kingdom",
  "Thaxted, United Kingdom",
  "Thedinghausen, Germany",
  "Theix, France",
  "Them, Denmark",
  "Thenioux, France",
  "Theodore, United States",
  "Thermalbad Wiesenbad (Erzgeb), Germany",
  "Thermi, Greece",
  "Therouanne, France",
  "Thesee, France",
  "Thessaloniki, Greece",
  "Thetford Norfolk, United Kingdom",
  "Thiais, France",
  "Thibodaux, United States",
  "THIEL-SUR-ACOLIN, France",
  "Thiene, Italy",
  "Thierhaupten, Germany",
  "Thiers, France",
  "Thiersheim, Germany",
  "Thignonville, France",
  "Thika, Kenya",
  "THILAWA, Myanmar (Burma)",
  "Thilay, France",
  "Thimister, Belgium",
  "Thimmapur, India",
  "Thionville, France",
  "Thirsk, United Kingdom",
  "Thisted, Denmark",
  "Thiva, Greece",
  "Thizy Rhone, France",
  "Thoiry, France",
  "Tholen, Netherlands",
  "Tholey, Germany",
  "Thomasville, United States",
  "Thomson, United States",
  "Thones, France",
  "Thonon les Bains, France",
  "Thorganby North Yorkshire, United Kingdom",
  "Thornaby, United Kingdom",
  "Thorne, United Kingdom",
  "Thornley Durham DH, United Kingdom",
  "Thornton Heath, United Kingdom",
  "Thornton-Cleveleys, United Kingdom",
  "Thorold, Canada",
  "Thorpe Surrey, United Kingdom",
  "Thorsoe, Denmark",
  "Thouare sur Loire, France",
  "Thouars, France",
  "Thrapsanon, Greece",
  "Thrapston, United Kingdom",
  "Three Rivers, United States",
  "Thumrait, Oman",
  "Thundersley, United Kingdom",
  "Thurleigh, United Kingdom",
  "Thurmaston, United Kingdom",
  "Thyboroen, Denmark",
  "Thyrnau, Germany",
  "Tiberias, Israel",
  "Tibro, Sweden",
  "Tickhill, United Kingdom",
  "Tiebas NAVARRA, Spain",
  "Tiefenbach (Kr Passau), Germany",
  "Tiefenbach Landshut, Germany",
  "Tiefenbronn, Germany",
  "Tiefenort, Germany",
  "Tiel, Netherlands",
  "Tielt, Belgium",
  "Tienen, Belgium",
  "Tierga, Spain",
  "Tigery, France",
  "Tigliole d'Asti, Italy",
  "Tijuana, Mexico",
  "Tilburg, Netherlands",
  "Tilbury, United Kingdom",
  "Tilehurst, United Kingdom",
  "Tillicoultry, United Kingdom",
  "Tillieres sur Avre, France",
  "Tilloy les Mofflaines, France",
  "Tilly-sur-Meuse, France",
  "Tilst, Denmark",
  "Tilton, United States",
  "Timaru, New Zealand",
  "Timisoara, Romania",
  "Timsfors, Sweden",
  "Tin Can Island Port, Nigeria",
  "Tinglev, Denmark",
  "Tinguindin, Mexico",
  "Tinley Park, United States",
  "Tinqueux, France",
  "Tinteniac, France",
  "Tione di Trento, Italy",
  "Tipitapa, Nicaragua",
  "Tipperary Tipperary, Ireland",
  "Tipton, United Kingdom",
  "Tire, Turkey",
  "Tirrenia, Italy",
  "Tirschenreuth, Germany",
  "Tirupur, India",
  "Tisnov, Czech Republic",
  "Tisselt, Belgium",
  "Tiszakecske, Hungary",
  "Tiszaujvaros, Hungary",
  "Tiszavasvari, Hungary",
  "Tiszaalpar, Hungary",
  "Tit mellil, Morocco",
  "Tithorea Lokridos, Greece",
  "Titisee-Neustadt, Germany",
  "Tito Scalo, Italy",
  "Titting (Oberbayern), Germany",
  "Tittmoning, Germany",
  "Titulcia, Spain",
  "Tiverton Devon, United Kingdom",
  "Tivoli, Italy",
  "Tizayuca Edo de Hidalgo, Mexico",
  "Tizzano Val Parma, Italy",
  "Tlaxcala, Mexico",
  "Tlmace, Slovakia",
  "Tlumacov, Czech Republic",
  "Tnine Chtouka, Morocco",
  "Toamasina, Madagascar",
  "Toano, Italy",
  "Tobarra, Spain",
  "Tobel, Switzerland",
  "Tobolsk, Russia",
  "Tobyhanna, United States",
  "Toccoa, United States",
  "TOCINA, Spain",
  "Tockwith, United Kingdom",
  "Toddington Bedfordshire, United Kingdom",
  "Toddington Gloucestershire, United Kingdom",
  "Todmorden, United Kingdom",
  "Todtnau, Germany",
  "Todva, Estonia",
  "Toender, Denmark",
  "Toenisvorst, Germany",
  "Toensberg, Norway",
  "Toijala, Finland",
  "Tokol, Hungary",
  "Tokuyama, Japan",
  "Tokyo, Japan",
  "Tolbert, Netherlands",
  "Toldyk, Netherlands",
  "Toledo, Spain",
  "Toledo, United States",
  "Tolentino, Italy",
  "Tolkmicko, Poland",
  "Tollegno, Italy",
  "Tollesbury Essex, United Kingdom",
  "Tolleson, United States",
  "Tolmezzo, Italy",
  "Tolmin, Slovenia",
  "Tolosa Guipuzcoa, Spain",
  "Toltestava, Hungary",
  "Toluca, Mexico",
  "Tomakomai, Japan",
  "Tomaszow Boleslawiecki, Poland",
  "Tomaszow Lubelski, Poland",
  "Tomaszow Mazowiecki, Poland",
  "Tombolo, Italy",
  "TOME, Chile",
  "Tomelloso, Spain",
  "Tomislavgrad, Bosnia and Herzegovina",
  "Tomsk, Russia",
  "Tonala, Mexico",
  "Tonawanda, United States",
  "Tonbridge, United Kingdom",
  "Tondela, Portugal",
  "Tongeren, Belgium",
  "Tongling, China",
  "Tonna, Germany",
  "Tonneins, France",
  "Tonnerre, France",
  "Tonypandy, United Kingdom",
  "Tooele, United States",
  "Toomebridge Co Antrim BT, United Kingdom",
  "Topkapi, Turkey",
  "Topola, Serbia",
  "Topolcany, Slovakia",
  "Topolcianky, Slovakia",
  "Topton, United States",
  "Toral de Los Vados, Spain",
  "Torbali, Turkey",
  "Torbole, Italy",
  "Torce, France",
  "Torcy le Petit Seine Maritime, France",
  "Torcy-le-Petit, France",
  "Tordera Barcelona, Spain",
  "Torello, Spain",
  "Torgau, Germany",
  "Torgiano, Italy",
  "Torija, Spain",
  "Torino, Italy",
  "Tornabous, Spain",
  "Tornala, Slovakia",
  "Tornesch, Germany",
  "Tornio, Finland",
  "Toro, Spain",
  "Torokbalint, Hungary",
  "Torokszentmiklos, Hungary",
  "Toronto, Canada",
  "Torpoint Cornwall, United Kingdom",
  "Torquay, United Kingdom",
  "Torralba de Calatrava, Spain",
  "Torrance, United States",
  "Torre Annunziata, Italy",
  "Torre Boldone, Italy",
  "Torre de Passeri, Italy",
  "Torre de Picenardi, Italy",
  "Torre dei Roveri, Italy",
  "Torre del Campo, Spain",
  "Torre di Mosto, Italy",
  "Torre d'Isola, Italy",
  "Torre Fossa, Italy",
  "Torre Maina, Italy",
  "Torre Pacheco, Spain",
  "Torre Pallavicina, Italy",
  "Torre San Giorgio, Italy",
  "Torreaguera, Spain",
  "Torreano, Italy",
  "Torrebelvicino, Italy",
  "Torredembarra, Spain",
  "Torredonjimeno, Spain",
  "Torrefarrera, Spain",
  "Torreglia, Italy",
  "Torregrossa, Spain",
  "Torrejon de Ardoz, Spain",
  "Torrejon de la Calzada, Spain",
  "Torrejon De Velasco, Spain",
  "Torrelaguna, Spain",
  "Torrelavega Cantabria, Spain",
  "Torrelles de Foix, Spain",
  "TORREMAYOR - Badajoz, Spain",
  "Torremejia, Spain",
  "Torremolinos, Spain",
  "Torrent, Spain",
  "Torreon, Mexico",
  "Torres de la Alameda, Spain",
  "Torres del Segre, Spain",
  "Torres Novas, Portugal",
  "Torres Vedras, Portugal",
  "Torreserona, Spain",
  "Torrevieja, Spain",
  "Torri de Busi, Italy",
  "Torri di Quartesolo, Italy",
  "Torriana, Italy",
  "Torrijos, Spain",
  "Torrile PR, Italy",
  "Torrington, United Kingdom",
  "Torrita di Siena, Italy",
  "Torshavn, Denmark",
  "Torslanda, Sweden",
  "Tortona, Italy",
  "Tortoreto, Italy",
  "Tortosa, Spain",
  "Torun, Poland",
  "Toscanella di Doz, Italy",
  "toscolano maderno, Italy",
  "Tosno, Russia",
  "Totana, Spain",
  "Totanes, Spain",
  "Totnes, United Kingdom",
  "TOTOGALPA, Nicaragua",
  "Totowa, United States",
  "Tottenham, United Kingdom",
  "Tottington Lancashire BL, United Kingdom",
  "Totton, United Kingdom",
  "Touguinho, Portugal",
  "Toul, France",
  "Toulon, France",
  "Toulouse, France",
  "Tourcoing, France",
  "Tournai, Belgium",
  "Tournan en Brie, France",
  "Tournes, France",
  "Tournon Saint Martin, France",
  "Tournus, France",
  "Tours, France",
  "Touvre, France",
  "Touzim, Czech Republic",
  "Tovo Di Sant agata, Italy",
  "Towanda, United States",
  "Towcester, United Kingdom",
  "Townsville, Australia",
  "Toyama, Japan",
  "Tozeur, Tunisia",
  "Traben-Trarbach, Germany",
  "Tracy, United States",
  "Tracy le Mont, France",
  "Tradate, Italy",
  "Trafford Park, United Kingdom",
  "Tragano, Greece",
  "Train, Germany",
  "Traisen, Austria",
  "Traiskirchen, Austria",
  "Trajano, Spain",
  "Tralee Kerry, Ireland",
  "Tramagal, Portugal",
  "TRANA, Italy",
  "Tranent, United Kingdom",
  "Trani, Italy",
  "Tranovice, Czech Republic",
  "Trapagaran, Spain",
  "Trapani, Italy",
  "Trappes, France",
  "Trasmulas, Spain",
  "Traun, Austria",
  "Traunreut, Germany",
  "Traunstein, Germany",
  "Travagliato, Italy",
  "Travedona Monate, Italy",
  "Travelers Rest, United States",
  "Traversetolo, Italy",
  "Travesio, Italy",
  "Travis Air force Base, United States",
  "Trawniki, Poland",
  "Trebaseleghe, Italy",
  "Trebatice, Slovakia",
  "Trebbin, Germany",
  "Trebechovice Pod Orebem, Czech Republic",
  "Trebes, France",
  "Trebestovice, Czech Republic",
  "Trebic, Czech Republic",
  "Trebisov, Slovakia",
  "Trebnje, Slovenia",
  "Trebon, Czech Republic",
  "Trebur, Germany",
  "Trecasali, Italy",
  "Trecate, Italy",
  "Trecenta, Italy",
  "Tredegar, United Kingdom",
  "Treffort-Cuisiat, France",
  "Treflevenez, France",
  "Tregnago, Italy",
  "Treia, Italy",
  "Treiso, Italy",
  "Treize Vents, France",
  "Trelleborg, Sweden",
  "Tremblay en France, France",
  "Tremblay les Villages, France",
  "Tremorel, France",
  "Tremosnice, Czech Republic",
  "Trencianska Turna, Slovakia",
  "Trencianske Bohuslavice, Slovakia",
  "Trencianske Stankovce, Slovakia",
  "Trencianske Teplice, Slovakia",
  "Trencin, Slovakia",
  "Trento, Italy",
  "Trenton, Canada",
  "Trenton, United States",
  "Trenzano, Italy",
  "Treorchy, United Kingdom",
  "Tres Cantos, Spain",
  "Tres Coracoes, Brazil",
  "Trescore Balneario, Italy",
  "Trescore Cremasco, Italy",
  "Tresigallo, Italy",
  "Tressano, Italy",
  "Trest, Czech Republic",
  "Trestina, Italy",
  "Treto, Spain",
  "Treuchtlingen, Germany",
  "Treuen (Vogtl), Germany",
  "Trevenzuolo, Italy",
  "Trevi, Italy",
  "Treviglio, Italy",
  "Trevignano, Italy",
  "Treviolo, Italy",
  "Treviscoe, United Kingdom",
  "Treviso, Italy",
  "Trevoux, France",
  "Trezzano Rosa, Italy",
  "Trezzano sul Naviglio, Italy",
  "Trezzo d'Adda, Italy",
  "Trhanov, Czech Republic",
  "Trhovy Stepanov, Czech Republic",
  "Tribano, Italy",
  "Triberg, Germany",
  "Tribiano, Italy",
  "Tribogna, Italy",
  "Tricarico, Italy",
  "Tricerro, Italy",
  "Trichiana, Italy",
  "Trier, Germany",
  "Trieste, Italy",
  "Trieste City Limits, Italy",
  "Trige, Denmark",
  "Trignac, France",
  "Trigolo, Italy",
  "Trikala, Greece",
  "TRILLICK, OMAGH, United Kingdom",
  "Trilport, France",
  "Trinec, Czech Republic",
  "Tring, United Kingdom",
  "Trinitapoli, Italy",
  "Trinity, United States",
  "Trino, Italy",
  "Tripolis, Greece",
  "Triptis, Germany",
  "Trissino, Italy",
  "Trittau, Germany",
  "Triuggio, Italy",
  "Trmice, Czech Republic",
  "Trnava, Slovakia",
  "Trobajo del Camino, Spain",
  "Trochtelfingen (Hohenz), Germany",
  "Trofa, Portugal",
  "Troisdorf, Germany",
  "Trois-Rivieres, Canada",
  "Trollhaettan, Sweden",
  "Tromello, Italy",
  "Trondheim, Norway",
  "Trosa, Sweden",
  "Trossingen, Germany",
  "Trostberg, Germany",
  "Trostyanets, Ukraine",
  "Trostyanets Sumy, Ukraine",
  "Trowbridge, United Kingdom",
  "Troyan, Bulgaria",
  "Troyes, France",
  "Truccazzano, Italy",
  "Truro, Canada",
  "Truro, United Kingdom",
  "Truskolasy, Poland",
  "Trussville, United States",
  "Trutnov, Czech Republic",
  "Trzebinia, Poland",
  "Trzemeszno, Poland",
  "Trzesn, Poland",
  "Trzeszczany, Poland",
  "Trzin, Slovenia",
  "Trzyciaz, Poland",
  "Tualang Siak, Indonesia",
  "Tualatin, United States",
  "Tubbergen, Netherlands",
  "Tuchomerice, Czech Republic",
  "Tucker, United States",
  "Tucson, United States",
  "Tuczno, Poland",
  "Tudela Navarra, Spain",
  "Tudelilla, Spain",
  "Tuebingen, Germany",
  "Tuenno, Italy",
  "Tuerkheim (Wertach), Germany",
  "Tuggen, Switzerland",
  "Tui, Spain",
  "Tuitjenhorn, Netherlands",
  "Tula, Russia",
  "Tulare, United States",
  "Tullamore - Offaly, Ireland",
  "Tulsa, United States",
  "Tumbaco, Ecuador",
  "Tumbalovo, Bulgaria",
  "Tunbridge Wells, United Kingdom",
  "Tunis, Tunisia",
  "Tunstall Stoke on Trent, United Kingdom",
  "Tunstead, United Kingdom",
  "Tupa, Brazil",
  "Tupelo, United States",
  "Turate, Italy",
  "Turbigo, Italy",
  "Turbo, Colombia",
  "Turcianske Teplice, Slovakia",
  "Turek, Poland",
  "Turfontein, South Africa",
  "Turgutlu, Turkey",
  "Turis, Spain",
  "Turku, Finland",
  "Turlock, United States",
  "Turnhout, Belgium",
  "Turnov, Czech Republic",
  "Turriff, United Kingdom",
  "Tuscaloosa, United States",
  "Tuscumbia, United States",
  "Tussenhausen, Germany",
  "Tutbury Staffs, United Kingdom",
  "Tuticorin, India",
  "Tutrakan, Bulgaria",
  "Tuttlingen, Germany",
  "Tuxford, United Kingdom",
  "Tuxpan, Mexico",
  "Tuxtepec, Mexico",
  "Tuzla, Turkey",
  "Tuzser, Hungary",
  "Tvardica, Bulgaria",
  "Twardogora, Poland",
  "Twickenham, United Kingdom",
  "Twinsburg, United States",
  "Twist, Germany",
  "Twistringen, Germany",
  "Tychy, Poland",
  "Tyler, United States",
  "Tyn nad Vltavou, Czech Republic",
  "Tynemouth, United Kingdom",
  "Tyniec Maly, Poland",
  "Tyniste nad Orlici, Czech Republic",
  "Tyrnava, Finland",
  "Tyrone, United States",
  "Tyseley, United Kingdom",
  "Taars, Denmark",
  "Taastrup, Denmark",
  "Ubeda, Spain",
  "Ubersetto, Italy",
  "Ubly, United States",
  "Uboldo, Italy",
  "Ubstadt-Weiher, Germany",
  "Uchaux, France",
  "Uchte, Germany",
  "Uckfield, United Kingdom",
  "Uddevalla, Sweden",
  "Uddingston, United Kingdom",
  "Uddington, United Kingdom",
  "Uden, Netherlands",
  "Udenhout, Netherlands",
  "Udine, Italy",
  "UDUPI, India",
  "Uebach-Palenberg, Germany",
  "Ueberherrn, Germany",
  "Ueberlingen (Bodensee), Germany",
  "Uebigau, Germany",
  "Uedem, Germany",
  "Uelzen, Germany",
  "Uetersen, Germany",
  "Uetze, Germany",
  "Uffculme, United Kingdom",
  "Uffenheim, Germany",
  "Uggiate Trevano, Italy",
  "Ugine, France",
  "Uharte Arakil, Spain",
  "Uherske Hradiste, Czech Republic",
  "Uhersky Brod, Czech Republic",
  "Uhersky Ostroh, Czech Republic",
  "Uhingen, Germany",
  "Uhlirske Janovice, Czech Republic",
  "Uhlstaedt Kirchhasel, Germany",
  "Uitenhage, South Africa",
  "Uitgeest, Netherlands",
  "Ujanowice, Poland",
  "Ujazd - (Tomaszow Mazowiecki), Poland",
  "Ujezd pod Troskami, Czech Republic",
  "Ukmerge, Lithuania",
  "Ulbroka, Latvia",
  "Uldum, Denmark",
  "Ulestraten, Netherlands",
  "Ulila, Estonia",
  "Ulla, Spain",
  "Ulldecona, Spain",
  "Ullerslev, Denmark",
  "Ulm Donau, Germany",
  "Ulmen, Germany",
  "Ulmi, Romania",
  "Ulricehamn, Sweden",
  "Ulsan, Korea, South",
  "Ulsnis, Germany",
  "Ulu Tiram, Malaysia",
  "Ulverston, United Kingdom",
  "Ulvik, Norway",
  "Ulvila, Finland",
  "Ulaanbaatar, Mongolia",
  "Umag, Croatia",
  "Uman, Mexico",
  "Umatilla, United States",
  "Umbertide, Italy",
  "Umm Al Quwain, United Arab Emirates",
  "Umm Qasr, Iraq",
  "Umm Saieed, Qatar",
  "Ummendorf (Kr Biberach), Germany",
  "Umraniye, Turkey",
  "Una, India",
  "Ungerhausen, Germany",
  "Unhost, Czech Republic",
  "Unicoi, United States",
  "Unicov, Czech Republic",
  "Union City, United States",
  "Universal City, United States",
  "University Park, United States",
  "Unkel, Germany",
  "Unknown, Unknown",
  "Unna, Germany",
  "Unterbernbach, Germany",
  "Untereisesheim, Germany",
  "Unterensingen, Germany",
  "Unterfoehring, Germany",
  "Unterkirnach, Germany",
  "Untermeitingen, Germany",
  "Untermerzbach, Germany",
  "Unterneukirchen, Germany",
  "Unterradlberg, Austria",
  "Unterreichenbach, Germany",
  "Unterschleissheim, Germany",
  "Untersiggenthal, Switzerland",
  "Unterwellenborn, Germany",
  "Upahl, Germany",
  "Upala, Costa Rica",
  "Upice, Czech Republic",
  "Uplengen, Germany",
  "Upminster, United Kingdom",
  "Upper Langford, United Kingdom",
  "Upper Marlboro, United States",
  "Upplands-Vaesby, Sweden",
  "Uppsala, Sweden",
  "Upton Lancs, United Kingdom",
  "Urbach (Rems), Germany",
  "Urbana, United States",
  "Urbisaglia, Italy",
  "Urdiain, Spain",
  "Urgnano, Italy",
  "Urk, Netherlands",
  "Urla-Izmir, Turkey",
  "Urmitz, Germany",
  "Urmston, United Kingdom",
  "Urnieta, Spain",
  "Urqueira, Portugal",
  "Ursensollen, Germany",
  "Uruapan, Mexico",
  "Uruli Kanchan, India",
  "Urunuela La Rioja, Spain",
  "Ury, France",
  "Usak, Turkey",
  "Ushuaia, Argentina",
  "Usingen, Germany",
  "Usk, United Kingdom",
  "Uskudar, Turkey",
  "Uslar, Germany",
  "Usmate Velate, Italy",
  "Usobrno, Czech Republic",
  "Ussel, France",
  "Usson du Poitou, France",
  "Ust Luga, Russia",
  "Uster, Switzerland",
  "Usti nad Labem, Czech Republic",
  "Usti nad Orlici, Czech Republic",
  "Ustka, Poland",
  "Ustron, Poland",
  "Usulutan, El Salvador",
  "Usumatlan, Guatemala",
  "Usurbil, Spain",
  "Utebo, Spain",
  "Utena, Lithuania",
  "Utica, United States",
  "Utrecht, Netherlands",
  "Utrera, Spain",
  "Utrillas, Spain",
  "Uttenweiler, Germany",
  "Uttoxeter, United Kingdom",
  "Utzenaich, Austria",
  "Uusikaupunki, Finland",
  "Uvaly, Czech Republic",
  "Uxbridge, United Kingdom",
  "Uxes, Spain",
  "Uzice, Czech Republic",
  "Uzzano, Italy",
  "Vac, Hungary",
  "Vacarisses, Spain",
  "Vacha, Germany",
  "Vaciglio, Italy",
  "Vacil, Italy",
  "Vadeni, Romania",
  "Vado, Italy",
  "Vado, United States",
  "Vadodara, India",
  "Vadovce, Slovakia",
  "Vaenersborg, Sweden",
  "Vaermdoe, Sweden",
  "Vaernamo, Sweden",
  "Vaesteraas, Sweden",
  "Vaexjoe, Sweden",
  "Vago, Italy",
  "Vagos, Portugal",
  "Vaiano, Italy",
  "Vaiano Cremasco, Italy",
  "Vaihingen (an der Enz), Germany",
  "Vailate, Italy",
  "Val de Reuil, France",
  "Valado Dos Frades, Portugal",
  "Valady, France",
  "Valaliky, Slovakia",
  "Valarpuram, India",
  "Valaska Bela, Slovakia",
  "Valasska Polanka, Czech Republic",
  "Valasske Klobouky, Czech Republic",
  "Valasske Mezirici, Czech Republic",
  "Valbrembo, Italy",
  "Valbuena de Duero, Spain",
  "Valby, Denmark",
  "Valdagno, Italy",
  "Valdefuentes del Paramo, Spain",
  "Valdelacalzada, Spain",
  "Valdemorillo, Spain",
  "Valdemoro, Spain",
  "Valdepenas, Spain",
  "Valderrubio, Spain",
  "Valdese, United States",
  "Valdestillas, Spain",
  "Valdetorres de Jarama, Spain",
  "Valdivia, Spain",
  "Valdobbiadene, Italy",
  "Valdosta, United States",
  "Valdovino, Spain",
  "Valdredo CUDILLERO, Spain",
  "Valduggia, Italy",
  "Vale de Cambra, Portugal",
  "Valea Calugareasca, Romania",
  "Valeggio sul Mincio, Italy",
  "Valence, France",
  "Valencia, Spain",
  "Valencia, United States",
  "Valencia de Don Juan, Spain",
  "Valenciana de la Concepcion, Spain",
  "Valenciennes, France",
  "Valencina de la Concepcion, Spain",
  "Valensole, France",
  "Valenton, France",
  "Valenza, Italy",
  "Valera de Abajo, Spain",
  "Valfenera, Italy",
  "Valga, Estonia",
  "Valga Pontevedra, Spaink",
  "Valgatara, Italy",
  "Valgreghentino, Italy",
  "Valinhos, Brazil",
  "Valjevo, Serbia",
  "Valkeakoski, Finland",
  "Vall d'Alba, Spain",
  "Vall d'Uixo, Spain",
  "Valla', Italy",
  "Valladares, Spain",
  "Valladolid, Spain",
  "Vallbona d'Anoia, Spain",
  "Valle de Trapaga, Spain",
  "Valle Escombreras, Spain",
  "Valle Lomellina, Italy",
  "Valle Salimbene, Italy",
  "Vallefoglia, Italy",
  "Vallenoncello, Italy",
  "Vallese di Oppeano, Italy",
  "Valletta, Malta",
  "Valley Stream, United States",
  "Vallfogona de Balaguer, Spain",
  "Valli del Pasubio, Italy",
  "Vallirana, Spain",
  "Vallmoll, Spain",
  "Vallorbe, Switzerland",
  "Valls, Spain",
  "Valmadrera, Italy",
  "Valmojado, Spain",
  "Valmont Moselle, France",
  "Valongo, Portugal",
  "Valparaiso, Chile",
  "Valparaiso, United States",
  "Valperga, Italy",
  "Valras Plage, France",
  "Valreas, France",
  "Valsad, India",
  "VALSAMOGGIA, Italy",
  "Valstagna, Italy",
  "Valtice, Czech Republic",
  "Valvada, India",
  "Valvasone, Italy",
  "Valverde del Majano, Spain",
  "Vamberk, Czech Republic",
  "Vamdrup, Denmark",
  "Van Buren, United States",
  "Van Nuys, United States",
  "Vanceburg, United States",
  "Vancouver, Canada",
  "Vancouver, United States",
  "Vannes, France",
  "Vantaa, Finland",
  "Vanzaghello, Italy",
  "Vanzago, Italy",
  "Vapenna, Czech Republic",
  "Vapi, India",
  "Vara, Sweden",
  "Varallo Pombia, Italy",
  "Varano Borghi, Italy",
  "Varano de Melegari, Italy",
  "Varapodio, Italy",
  "Varazdin, Croatia",
  "Varberg, Sweden",
  "Varbola, Estonia",
  "Varces Allieres et Risset, France",
  "Varde, Denmark",
  "Varedo, Italy",
  "Varennes sur Allier, France",
  "Varennes Vauzelles, France",
  "Varese, Italy",
  "Varginha, Brazil",
  "Vari, Greece",
  "Varin, Slovakia",
  "Varkaus, Finland",
  "Varmo, Italy",
  "Varna, Bulgaria",
  "Varna, Italy",
  "Varnsdorf, Czech Republic",
  "Varone, Italy",
  "Varpalota, Hungary",
  "Varshets, Bulgaria",
  "Varsseveld, Netherlands",
  "Varzea Grande, Brazil",
  "Vasai, India",
  "Vasilika Thessalonikis, Greece",
  "Vasiliko, Greece",
  "Vasto, Italy",
  "Vatra Dornei, Romania",
  "Vatry, France",
  "Vattigudipadu, India",
  "Vatva, India",
  "Vaudreuil-Dorion, Canada",
  "Vaujours, France",
  "Vaulx en Velin, France",
  "Vaulx Milieu, France",
  "Vaulx Vraucourt, France",
  "Vauvert, France",
  "Vaux en Bugey, France",
  "Vaux le Penil, France",
  "Vayres, France",
  "Vazzola, Italy",
  "Veauche, France",
  "vecchiano, Italy",
  "Vechta, Germany",
  "Vecses, Hungary",
  "Vecumnieki, Latvia",
  "Vedano al Lambro, Italy",
  "Vedano Olona, Italy",
  "Vedbaek, Denmark",
  "Veddige, Sweden",
  "Vedelago, Italy",
  "Vedra, Spain",
  "Veduggio, Italy",
  "Veendam, Netherlands",
  "Veenendaal, Netherlands",
  "Veenoord, Netherlands",
  "Veggia, Italy",
  "Veggiano, Italy",
  "Veghel, Netherlands",
  "Veikkola, Finland",
  "Veitshoechheim, Germany",
  "Vejen, Denmark",
  "Vejle, Denmark",
  "Vejprty, Czech Republic",
  "Velaux, France",
  "Velbert, Germany",
  "Velburg, Germany",
  "Velddriel, Netherlands",
  "Veleliby, Czech Republic",
  "Velenje, Slovenia",
  "Veles, Macedonia",
  "Velestino, Greece",
  "Velez Malaga, Spain",
  "Veliko Tarnovo, Bulgaria",
  "VELILLA DE CINCA, Spain",
  "Velilla de San Antonio, Spain",
  "Velim, Czech Republic",
  "Velingrad, Bulgaria",
  "Velizy Villacoublay, France",
  "Velka Bites, Czech Republic",
  "Velka Bystrice, Czech Republic",
  "Velka Dobra, Czech Republic",
  "Velka Ida, Slovakia",
  "Velka Kras, Czech Republic",
  "Velka nad Velickou, Czech Republic",
  "Velka Polom, Czech Republic",
  "Velke Bilovice, Czech Republic",
  "Velke Karlovice, Czech Republic",
  "Velke Mezirici, Czech Republic",
  "Velke Opatovice, Czech Republic",
  "Velke Popovice, Czech Republic",
  "Velke Porici, Czech Republic",
  "Velke Prilepy, Czech Republic",
  "Velke Uherce, Slovakia",
  "Velke Ulany, Slovakia",
  "Velke Zaluzie, Slovakia",
  "Velky Krtis, Slovakia",
  "Velky Meder, Slovakia",
  "Velky Osek, Czech Republic",
  "Velky Senov, Czech Republic",
  "Vellberg, Germany",
  "Vellezzo Bellini, Italy",
  "Velo de Astico, Italy",
  "Velsen Zuid, Netherlands",
  "Velsen-Noord, Netherlands",
  "Velten, Germany",
  "Venaria Reale, Italy",
  "Vendargues, France",
  "Vendeville, France",
  "Vendin le Vieil, France",
  "Vendlincourt, Switzerland",
  "Vendome, France",
  "Venegono Inferiore, Italy",
  "Venegono Superiore, Italy",
  "Venelles, France",
  "Venette, France",
  "Venezia, Italy",
  "Veniano, Italy",
  "Venissieux, France",
  "Venlo, Netherlands",
  "Vennesla, Norway",
  "Venray, Netherlands",
  "Venta de Banos, Spain",
  "Venta del Peral, Spain",
  "Ventas de Huelma, Spain",
  "Ventspils, Latvia",
  "Venturina, Italy",
  "Venus, United States",
  "Venzone, Italy",
  "Vera de Bidasoa, Spain",
  "Veracruz, Mexico",
  "Veracruz, Panama",
  "Verano Brianza, Italy",
  "Veraval, India",
  "Verbania, Italy",
  "Vercelli, Italy",
  "Vercurago, Italy",
  "Verdal, Norway",
  "Verdellino, Italy",
  "Verdello, Italy",
  "Verden, Germany",
  "Verderio Inferiore, Italy",
  "Verderio Superiore, Italy",
  "Verdun Meuse, France",
  "Verduno, Italy",
  "Veresegyhaz, Hungary",
  "Vergiate, Italy",
  "Vergnasco, Italy",
  "Vergnasco di Cerr, Italy",
  "VERGONGHEON, France",
  "Veria, Greece",
  "Verin, Spain",
  "Verl, Germany",
  "Vermenton, France",
  "Vermerovice, Czech Republic",
  "Vermezzo, Italy",
  "Vern d'Anjou, France",
  "Vernate, Italy",
  "Verneuil en Halatte, France",
  "Verneuil sur Indre, France",
  "Vernon, United States",
  "Vernon Hills, United States",
  "Vernouillet Eure et Loir, France",
  "Vero Beach, United States",
  "Verolanuova, Italy",
  "Verolavecchia, Italy",
  "Verona, Italy",
  "Verona, United States",
  "Veronella, Italy",
  "Verovice, Czech Republic",
  "Verrebroek, Belgium",
  "Verrone, Italy",
  "Versailles, United States",
  "Versmold, Germany",
  "Vert Saint Denis, France",
  "Vertemate Con Minoprio, Italy",
  "Verteuil d'agenais, France",
  "Verton, France",
  "Vertova, Italy",
  "Vertus, France",
  "Verucchio, Italy",
  "Verviers, Belgium",
  "Verwood, United Kingdom",
  "Verzuolo, Italy",
  "Vescovana, Italy",
  "Vescovato, Italy",
  "Veseli Nad Luznici, Czech Republic",
  "Veseli nad Moravou, Czech Republic",
  "Vesoul, France",
  "Vestec, Czech Republic",
  "Vestenanova, Italy",
  "Vestenbergsgreuth, Germany",
  "Vester Skerninge, Denmark",
  "Vestone, Italy",
  "Veszprem, Hungary",
  "Vetlanda, Sweden",
  "Vetralla, Italy",
  "Vetraz Monthoux, France",
  "Vetrni, Czech Republic",
  "Vetschau (Brandenburg), Germany",
  "Vettelschoss, Germany",
  "Veurey Voroize, France",
  "Veurne, Belgium",
  "Vevce, Slovenia",
  "Veverska Bityska, Czech Republic",
  "Veverske Kninice, Czech Republic",
  "Vevey, Switzerland",
  "Veyre Monton, France",
  "Veyziat, France",
  "Vezirhan, Turkey",
  "Vezza d'Alba, Italy",
  "Vezzano ligure, Italy",
  "Vezzano sul Crostolo, Italy",
  "Viadana, Italy",
  "Viadana Bresciana, Italy",
  "Viadanica, Italy",
  "Viana, Angola",
  "Viana, Spain",
  "Viana do Castelo, Portugal",
  "Vianen, Netherlands",
  "Viano, Italy",
  "Viareggio, Italy",
  "Viarmes, France",
  "Viarolo, Italy",
  "Vias, France",
  "Viator, Spain",
  "Vibo Valentia, Italy",
  "Viborg, Denmark",
  "Viby Jylland, Denmark",
  "Viby Sjaelland, Denmark",
  "Vic, Spain",
  "Vicalvaro, Spain",
  "Vicar, Spain",
  "Vicchio, Italy",
  "Vicenza, Italy",
  "Vichte, Belgium",
  "Viciomaggio, Italy",
  "Vicksburg, United States",
  "Vicolozano, Spain",
  "Vicopisano, Italy",
  "Victoria, Seychelles",
  "Vidalengo di Caravaggio, Italy",
  "Videbaek, Denmark",
  "Vidin, Bulgaria",
  "Vidor, Italy",
  "Vidreres, Spain",
  "Viechtach, Germany",
  "Vieira do Minho, Portugal",
  "Viella Asturias, Spain",
  "Vielle Saint Girons, France",
  "Vielsalm, Belgium",
  "Vienna, Austria",
  "Vienne, France",
  "Viernheim, Germany",
  "Viersen, Germany",
  "Vierzon, France",
  "Vieu d Izenave, France",
  "Vievis, Lithuania",
  "Vif, France",
  "Vigano, Italy",
  "Vigarano Mainarda, Italy",
  "Vigasio, Italy",
  "Vigevano, Italy",
  "Viggiano, Italy",
  "VIGHIZZOLO D'ESTE, Italy",
  "Vighizzolo di Cantu CO, Italy",
  "Vigliano Biellese, Italy",
  "Vignate, Italy",
  "Vigneux de Bretagne, France",
  "Vigneux sur Seine, France",
  "Vignola, Italy",
  "Vignoles, France",
  "Vigo, Spain",
  "Vigo Rendena, Italy",
  "Vigodarzere, Italy",
  "Vigolo Vattaro, Italy",
  "Vigolzone, Italy",
  "Vigonovo, Italy",
  "Vigonovo di Fontanafredda, Italy",
  "Vigonza, Italy",
  "Vigorovea, Italy",
  "VIGUERA, Spain",
  "Viguzzolo, Italy",
  "Vijfhuizen, Netherlands",
  "Vila Das Aves, Portugal",
  "Vila de Cruces, Spain",
  "Vila de Mouros, Spain",
  "Vila do Conde, Portugal",
  "Vila Franca Do Rosario, Portugal",
  "Vila Nova da Telha, Portugal",
  "Vila Nova de Gaia, Portugal",
  "Vila Nova Famalicao, Portugal",
  "Vila Velha de Rodao, Portugal",
  "Vila Vicosa, Portugal",
  "Viladecans, Spain",
  "Viladrau, Spain",
  "Vilafames, Spain",
  "Vilafant, Spain",
  "Vilafranca del Penedes, Spain",
  "Vilagarcia de Arousa, Spain",
  "Vilalba, Spain",
  "Vilallonga del Camp Tarragona, Spain",
  "Vilamalla, Spain",
  "Vilamarxant, Spain",
  "Vilanova de Arousa, Spain",
  "Vilanova de la Barca, Spain",
  "Vilanova Del Cami Barcelona, Spain",
  "VILANOVA DEL VALLES, Spain",
  "Vilanova i Geltru, Spain",
  "VILA-REAL, Spain",
  "Vilarinho, Portugal",
  "Vila-Sana, Spain",
  "Vilaseca, Spain",
  "Vilassar de Dalt, Spain",
  "Vilassar de Mar, Spain",
  "Vilches, Spain",
  "Vildbjerg, Denmark",
  "Vilemov, Czech Republic",
  "Vilemov u Sluknova, Czech Republic",
  "Viljandi, Estonia",
  "Vilkaviskis, Lithuania",
  "Vilkyskiai, Lithuania",
  "Villa Carcina, Italy",
  "Villa Cortese, Italy",
  "Villa d'Adda, Italy",
  "Villa de Reyes, Mexico",
  "Villa del Conte, Italy",
  "Villa di Serio, Italy",
  "Villa d'Ogna, Italy",
  "Villa Fastiggi, Italy",
  "Villa Guardia, Italy",
  "Villa Nueva, Guatemala",
  "Villa Poma, Italy",
  "Villa Regina Rio Negro, Argentina",
  "Villa San Giovanni, Italy",
  "Villa Santo Stefano, Italy",
  "Villa Verucchio, Italy",
  "Villabe, France",
  "Villacanas, Spain",
  "Villacastin, Spain",
  "Villach, Austria",
  "Villadangos del Paramo, Spain",
  "Villadose, Italy",
  "Villadossola, Italy",
  "Villafalletto, Italy",
  "Villafames, Spain",
  "VILLAFLOR, Spain",
  "Villafontana, Italy",
  "Villafranca, Italy",
  "Villafranca de Cordoba, Spain",
  "Villafranca de los Barros, Spain",
  "Villafranca de los Caballeros, Spain",
  "Villafranca di Verona, Italy",
  "Villafranca Navarra, Spain",
  "Villafranca Padovana, Italy",
  "Villafranca Piemontese, Italy",
  "Villafranca Tirrena, Italy",
  "Villafranco Del Guadalhorce, Spain",
  "Villafranco Guadiana Badajoz, Spain",
  "Villafria de Burgos, Spain",
  "Villaganzerla, Italy",
  "Villagonzalo Pedernales, Spain",
  "Villagran, Mexico",
  "Villahde, Finland",
  "Villajoyosa, Spain",
  "Villalar de los Comuneros, Spain",
  "Villalbilla, Spain",
  "Villalbilla de Burgos, Spain",
  "Villalgordo Del Jucar, Spain",
  "Villalonga, Spain",
  "Villalonquejar, Spain",
  "Villaluenga de la sagra, Spain",
  "Villalunga, Italy",
  "Villamagna, Italy",
  "Villamanrique de la Condesa, Spain",
  "Villamarina, Italy",
  "Villamarzana, Italy",
  "Villamayor de Monjardin, Spain",
  "Villamediana La Rioja, Spain",
  "Villamuriel de Cerrato, Spain",
  "Villanova Canavese, Italy",
  "Villanova d'Albenga, Italy",
  "Villanova d'Ardenghi, Italy",
  "Villanova d'Asti, Italy",
  "Villanova di Camposampiero, Italy",
  "Villanova di Castenaso, Italy",
  "Villanova di Fossalta, Italy",
  "Villanova di Prata, Italy",
  "Villanova di Reggiolo, Italy",
  "Villanova Monferrato, Italy",
  "Villanova sull'Arda, Italy",
  "Villanterio, Italy",
  "Villanubla, Spain",
  "VILLANUEVA, Honduras",
  "Villanueva Canada, Spain",
  "Villanueva de Alcardete, Spain",
  "Villanueva de Castellon, Spain",
  "Villanueva de Gallego, Spain",
  "Villanueva De La Jara, Spain",
  "Villanueva de la Reina, Spain",
  "Villanueva de los castillejos, Spain",
  "Villanueva del Pardillo, Spain",
  "Villanueva del Trabuco, Spain",
  "Villanueva Serena, Spain",
  "Villanuova sul Clisi, Italy",
  "Villar de Olalla, Spain",
  "Villar de Rena, Spain",
  "Villar del Arzobispo, Spain",
  "Villarcayo, Spain",
  "Villard-bonnot, France",
  "Villarejo de Orbigo, Spain",
  "Villares de la Reina Salamanca, Spain",
  "Villaricca, Italy",
  "Villarrasa, Spain",
  "Villarrobledo, Spain",
  "Villarrubia de los Ojos, Spain",
  "Villarrubia De Santiago, Spain",
  "Villarrubio, Spain",
  "Villars les Dombes, France",
  "Villars-sur-Glane, Switzerland",
  "Villarta de San Juan, Spain",
  "Villarubia, Spain",
  "Villasanta MI, Italy",
  "Villaseca De La Sagra, Spain",
  "Villastanza, Italy",
  "Villastellone, Italy",
  "Villatobas, Spain",
  "Villatuerta, Spain",
  "Villaturiel, Spain",
  "Villava, Spain",
  "Villavaquerin, Spain",
  "Villaverde Madrid, Spain",
  "Villaverde Rio, Spain",
  "Villaverla, Italy",
  "Villavesco, Italy",
  "Villaviciosa Asturias, Spain",
  "Villaviciosa Odon, Spain",
  "Villavieja Castellon, Spain",
  "Ville la Grand, France",
  "Villebarou, France",
  "Villebon sur Yvette, France",
  "Villedieu la Blouere, France",
  "Villefranche, France",
  "Villefranche de Rouergue, France",
  "Villefranche sur Saone, France",
  "Villejuif, France",
  "Villejust, France",
  "Villena, Spain",
  "Villeneuve d'Ascq, France",
  "Villeneuve le Roi, France",
  "Villeneuve Saint Georges, France",
  "Villeneuve Saint Germain, France",
  "Villeneuve sur Lot, France",
  "Villeneuve sur Yonne, France",
  "Villepinte, France",
  "Villerest, France",
  "Villereversure, France",
  "Villers Bretonneux, France",
  "Villers Cotterets, France",
  "Villers le Bouillet, Belgium",
  "Villers les Pots, France",
  "Villers Saint Paul, France",
  "Villersexel, France",
  "Villesse, Italy",
  "Villeta, Paraguay",
  "Villetoureix, France",
  "Villette d'Anthon, France",
  "Villeurbanne, France",
  "Villeveyrac, France",
  "Villie Morgon, France",
  "Villiersdorp, South Africa",
  "Villieu Loyes Mollon, France",
  "Villimpenta, Italy",
  "Villingen-Schwenningen, Germany",
  "Villmergen, Switzerland",
  "Villoldo, Spain",
  "Villongo, Italy",
  "Villons Les Buissons, France",
  "Villorba, Italy",
  "Villotta di Chions, Italy",
  "Vilnius, Lithuania",
  "Vilobi del Penedes, Spain",
  "Vilpiano, Italy",
  "Vilsbiburg, Germany",
  "Vilseck, Germany",
  "Vilshofen (Niederbay), Germany",
  "Vilvoorde, Belgium",
  "Vimercate, Italy",
  "Vimianzo, Spain",
  "Vimmerby, Sweden",
  "Vimodrone, Italy",
  "Vimperk, Czech Republic",
  "Vinadio, Italy",
  "Vinaros, Spain",
  "Vinay, France",
  "Vinces, Ecuador",
  "Vinci, Italy",
  "Vincios, Spain",
  "Vineland, United States",
  "Vineuil Loir et Cher, France",
  "Vinica, Macedonia",
  "Vinnitsa, Ukraine",
  "Vinovo, Italy",
  "Vinterbro, Norway",
  "Viodos Abense de Bas, France",
  "Vipava, Slovenia",
  "Vipiteno, Italy",
  "Vippachedelhausen, Germany",
  "Viramgam, India",
  "VIRAZEIL, France",
  "Vire, France",
  "Virey, France",
  "Virginia Beach, United States",
  "Virginia Water, United Kingdom",
  "Viriat, France",
  "Viriville, France",
  "Virle Piemonte, Italy",
  "VIRONVAY, France",
  "Virton, Belgium",
  "Viry Chatillon, France",
  "Visakhapatnam, India",
  "Visalia, United States",
  "Visano, Italy",
  "Visbek (Kr Vechta), Germany",
  "Vise, Belgium",
  "Viserba, Italy",
  "Viseu, Portugal",
  "Visna, Italy",
  "Viso Del Alcor, Spain",
  "Visoko, Bosnia and Herzegovina",
  "Visso, Italy",
  "Vista, United States",
  "Vista Hermosa, Mexico",
  "Vitebsk, Belarus",
  "Viterbo, Italy",
  "Vitis, Austria",
  "Vitkov, Czech Republic",
  "Vitorchiano, Italy",
  "Vitoria, Spain",
  "VITRE, France",
  "Vitrolles Bouches de Rhone, France",
  "Vitry le Francois, France",
  "Vitry sur Seine, France",
  "Vittel, France",
  "Vittoria, Italy",
  "Vittorio Veneto, Italy",
  "Vittuone, Italy",
  "Vitulazio, Italy",
  "Viviers du Lac, France",
  "Vizille, France",
  "Vizovice, Czech Republic",
  "Vizzola Ticino, Italy",
  "VLACHY, Slovakia",
  "Vladimir, Russia",
  "Vladimirovac, Serbia",
  "Vladivostok, Russia",
  "Vladivostok - Sollers, Russia",
  "Vlasim, Czech Republic",
  "Vlezenbeek, Belgium",
  "Vlissingen, Netherlands",
  "Vlotho, Germany",
  "Vlaardingen, Netherlands",
  "VO, Italy",
  "Vobarno, Italy",
  "Voderady, Slovakia",
  "Vodnany, Czech Republic",
  "Vodskov, Denmark",
  "Voehringen, Germany",
  "Voehringen (Iller), Germany",
  "Voelklingen, Germany",
  "Voelpke, Germany",
  "Voerde Niederrhein, Germany",
  "Voesendorf, Austria",
  "Voeuil et Giget, France",
  "Voghera, Italy",
  "Voiron, France",
  "Vojens, Denmark",
  "Volano, Italy",
  "Volargne, Italy",
  "Volargne di Dolce, Italy",
  "Volary, Czech Republic",
  "Volcja Draga, Slovenia",
  "Volduchy, Czech Republic",
  "Volendam, Netherlands",
  "Volgograd, Russia",
  "Volkach, Germany",
  "Volkel, Netherlands",
  "Voll, Norway",
  "Volla, Italy",
  "Volongo, Italy",
  "Volos, Greece",
  "Volpago del Montello, Italy",
  "Volpiano, Italy",
  "Volpino, Italy",
  "Volta Mantovana, Italy",
  "Volvera, Italy",
  "Volvic, France",
  "Volyne, Czech Republic",
  "Vomecourt, France",
  "Vomp, Austria",
  "Vondelingenplaat, Netherlands",
  "Vonges, France",
  "Vonitsa, Greece",
  "Vonnas, France",
  "Vonore, United States",
  "Voorthuizen, Netherlands",
  "Vorchdorf, Austria",
  "Voreppe, France",
  "Voronezh, Russia",
  "Voru, Estonia",
  "Vostochniy Port, Russia",
  "VOTO, Spain",
  "Vrable, Slovakia",
  "Vranany, Czech Republic",
  "Vrasene, Belgium",
  "Vratimov, Czech Republic",
  "Vratza, Bulgaria",
  "Vrbno pod Pradedem, Czech Republic",
  "Vrbove, Slovakia",
  "Vrbovec, Croatia",
  "Vrbovsko, Croatia",
  "Vrchlabi, Czech Republic",
  "Vreden, Germany",
  "Vrees, Germany",
  "Vriezenveen, Netherlands",
  "Vroomshoop, Netherlands",
  "Vrutky, Slovakia",
  "Vraa, Denmark",
  "Vsechromy, Czech Republic",
  "Vsetaty, Czech Republic",
  "Vsetin, Czech Republic",
  "Vsevolozhsky, Russia",
  "Vung Tau, Vietnam",
  "Vuren, Netherlands",
  "Vyskov, Czech Republic",
  "Vysoke Myto, Czech Republic",
  "Vyssi Brod, Czech Republic",
  "Vyzyrka, Ukraine",
  "Vaajakoski, Finland",
  "Vaaksy, Finland",
  "Vaargaarda, Sweden",
  "Vaas, France",
  "Vaasa, Finland",
  "Vaassen, Netherlands",
  "Wabern, Germany",
  "Wabrzezno, Poland",
  "Wachau (b Radeberg), Germany",
  "Wachenroth, Germany",
  "Wachtberg, Germany",
  "Wackersdorf, Germany",
  "Waco, United States",
  "Waddinxveen, Netherlands",
  "Wadern, Germany",
  "Wadeville, South Africa",
  "Wadhurst, United Kingdom",
  "Wadley, United States",
  "Wadowice, Poland",
  "Waechtersbach, Germany",
  "Waegwan, Korea, South",
  "Wagenfeld, Germany",
  "Waghaeusel, Germany",
  "Waghodia, India",
  "Waging am See, Germany",
  "Wagrowiec, Poland",
  "Waiblingen, Germany",
  "Waidhaus, Germany",
  "Waidhofen (Oberbay), Germany",
  "Waidhofen an der Thaya, Austria",
  "Wain, Germany",
  "Wainfleet Lincolnshire, United Kingdom",
  "Waischenfeld, Germany",
  "Wakefield, United Kingdom",
  "Walbrzych, Poland",
  "Walburg, United States",
  "Walce, Poland",
  "Walcz, Poland",
  "WALDALGESHEIM, Germany",
  "Waldbroel, Germany",
  "Waldbrunn (Odenwald), Germany",
  "Waldenburg (Wuertt), Germany",
  "Waldershof, Germany",
  "Waldfischbach, Germany",
  "Waldkirch (Breisgau), Germany",
  "Waldkraiburg, Germany",
  "Wald-Michelbach, Germany",
  "Waldmuenchen, Germany",
  "Waldsee, Germany",
  "Waldshut-Tiengen, Germany",
  "Waldsolms, Germany",
  "Waldstetten (Kr Guenzburg, Germany",
  "Waldstetten-Wuertt, Germany",
  "Walesby Notts, United Kingdom",
  "Walford, United States",
  "Walhorn, Belgium",
  "Walkden, United Kingdom",
  "Walkenried, Germany",
  "Walkertshofen, Germany",
  "Wallasey, United Kingdom",
  "Walldorf (Baden), Germany",
  "Wallenhorst, Germany",
  "Waller, United States",
  "Wallerfing, Germany",
  "Wallersdorf, Germany",
  "Wallertheim, Germany",
  "Wallhamn, Sweden",
  "Wallingford, United Kingdom",
  "Wallington London, United Kingdom",
  "Wallsend, United Kingdom",
  "Walluf, Germany",
  "Walnut, United States",
  "Walnut Ridge, United States",
  "Walsall, United Kingdom",
  "Walsoorden, Netherlands",
  "Waltenhofen, Germany",
  "Waltershausen, Germany",
  "Waltham Abbey, United Kingdom",
  "Waltham Cross, United Kingdom",
  "Walthamstow London, United Kingdom",
  "Walton, United States",
  "Walton Hills, United States",
  "Walton on Thames, United Kingdom",
  "Waltrop, Germany",
  "Waluj, India",
  "Walvis Bay, Namibia",
  "Walworth, United States",
  "Wancourt, France",
  "Wandsworth London, United Kingdom",
  "Wang, Germany",
  "Wangen, Germany",
  "Wangen (im Allgaeu), Germany",
  "Wanlaweyn, Somalia",
  "Wannweil, Germany",
  "Wanssum, Netherlands",
  "Wantage, United Kingdom",
  "Warboys, United Kingdom",
  "Warburg, Germany",
  "Warcoing, Belgium",
  "Wardenburg, Germany",
  "Wardha, India",
  "Wardle Cheshire, United Kingdom",
  "Wardrecques, France",
  "Ware Herdfordshire, United Kingdom",
  "Waregem, Belgium",
  "Wareham, United Kingdom",
  "Waremme, Belgium",
  "Waren, Germany",
  "Warendorf, Germany",
  "Warka, Poland",
  "Warley, United Kingdom",
  "Warmensteinach, Germany",
  "Warmeriville, France",
  "Warminster, United Kingdom",
  "Warndon, United Kingdom",
  "Warner Robins, United States",
  "WARNETON-WAASTEN, Belgium",
  "Warren, United States",
  "Warrington Cheshire, United Kingdom",
  "Warstein, Germany",
  "Warszawa, Poland",
  "Warszowice, Poland",
  "Wartenberg, Germany",
  "Warthausen, Germany",
  "Warwick Warwickshire, United Kingdom",
  "Wasbek, Germany",
  "Washdyke, New Zealand",
  "Washington, United States",
  "Washington Tyne and Wear, United Kingdom",
  "Washington West Sussex, United Kingdom",
  "Wasosz, Poland",
  "Waspik, Netherlands",
  "Wasselonne, France",
  "Wassenberg, Germany",
  "Wasserburg (am Inn), Germany",
  "Wasungen, Germany",
  "Watchet, United Kingdom",
  "Water Orton, United Kingdom",
  "Waterbeach, United Kingdom",
  "Waterbury, United States",
  "Waterford, United States",
  "Waterford Co Waterford, Ireland",
  "Waterfront, South Africa",
  "WatergrasshillCo Cork, Ireland",
  "Wateringen, Netherlands",
  "WATERLOO, Canada",
  "Waterlooville, United Kingdom",
  "Watertown, United States",
  "Waterville, United States",
  "Watford Hertfordshire, United Kingdom",
  "Watford Northamptonshire NN, United Kingdom",
  "Watkins Glen, United States",
  "Watou, Belgium",
  "Watsontown, United States",
  "Wattens, Austria",
  "Watton Norfolk, United Kingdom",
  "Waukee, United States",
  "Waukegan, United States",
  "Waukesha, United States",
  "Waupaca, United States",
  "Wavre, Belgium",
  "Wavrin, France",
  "Waxahachie, United States",
  "Wayland, United States",
  "Wayne, United States",
  "Waynesboro, United States",
  "Weatherford, United States",
  "Weaverville, United States",
  "Wedel (Holstein), Germany",
  "Wedemark, Germany",
  "Wednesbury, United Kingdom",
  "Wednesfield, United Kingdom",
  "Weener, Germany",
  "Weert, Netherlands",
  "Weesp, Netherlands",
  "Wegberg, Germany",
  "Wegliniec, Poland",
  "Wegrow, Poland",
  "Wehingen, Germany",
  "Wehl, Netherlands",
  "Wehr, Germany",
  "Weida, Germany",
  "Weiden, Germany",
  "Weiden (i d Opf), Germany",
  "Weidenbach, Germany",
  "Weihai, China",
  "Weikersdorf am Steinfelde, Austria",
  "Weikersheim, Germany",
  "Weil am Rhein, Germany",
  "Weil der Stadt, Germany",
  "Weil Im Schoenbuch, Germany",
  "Weilbach, Germany",
  "Weilburg, Germany",
  "Weilerbach, Germany",
  "Weilheim, Germany",
  "Weilheim (an der Teck), Germany",
  "Weimar, Germany",
  "WEINBERGEN, Germany",
  "Weingarten (Baden), Germany",
  "Weingarten (Wuertt), Germany",
  "Weinheim (Bergstr), Germany",
  "Weinolsheim, Germany",
  "Weinsheim (Eifel), Germany",
  "Weinstadt, Germany",
  "Weira, Germany",
  "Weirton, United States",
  "Weisenbach, Germany",
  "Weissandt-Goelzau, Germany",
  "Weissbach (Wuertt), Germany",
  "Weissenbach an der Triesting, Austria",
  "Weissenberg, Germany",
  "Weissenborn (bei Freiberg, Sachsen), Germany",
  "Weissenburg (Bay), Germany",
  "Weissenfels, Germany",
  "Weissenhorn, Germany",
  "Weissensee, Germany",
  "Weissenstadt, Germany",
  "Weissenstein, Austria",
  "Weissenthurm, Germany",
  "Weisskirchen in Steiermark, Austria",
  "Weisswasser, Germany",
  "Weitefeld, Germany",
  "Weiterstadt, Germany",
  "Weitnau, Germany",
  "Welcome, United States",
  "Welham Green, United Kingdom",
  "Welisara, Sri Lanka",
  "Welkenraedt, Belgium",
  "Wellampitiya, Sri Lanka",
  "Wellen, Belgium",
  "Wellesbourne Hastings, United Kingdom",
  "Wellesbourne Warwickshire, United Kingdom",
  "Wellingborough, United Kingdom",
  "Wellington, New Zealand",
  "Wellington Somerset, United Kingdom",
  "Wells, United Kingdom",
  "Wels, Austria",
  "Welschbillig, Germany",
  "Welshpool, United Kingdom",
  "Welwyn Garden City, United Kingdom",
  "Wem, United Kingdom",
  "Wembley, United Kingdom",
  "Wemding, Germany",
  "Wendelstein (Mittelfr), Germany",
  "Wenden (Biggetal), Germany",
  "Wendlingen (am Neckar), Germany",
  "Wendover, United Kingdom",
  "Weng im Innkreis, Austria",
  "Wenzhou, China",
  "Werbach, Germany",
  "Werdau (Sachsen), Germany",
  "Werder, Germany",
  "Werder (Bei Neuruppin), Germany",
  "Werdohl, Germany",
  "Werl, Germany",
  "Wermelskirchen, Germany",
  "Wernberg Koeblitz, Germany",
  "Werndorf, Austria",
  "Werne, Germany",
  "Werneck, Germany",
  "Wernigerode, Germany",
  "Wernshausen, Germany",
  "Weroth, Germany",
  "Wertach, Germany",
  "Wertheim, Germany",
  "Werther, Germany",
  "Wertingen, Germany",
  "Wervik, Belgium",
  "Wesel, Germany",
  "Wesseling, Germany",
  "Wessling, Germany",
  "Wessobrunn, Germany",
  "West Allis, United States",
  "West Auckland, United Kingdom",
  "West Babylon, United States",
  "West Bend, United States",
  "West Blocton, United States",
  "West Bridgford, United Kingdom",
  "West Bromwich, United Kingdom",
  "West Caldwell, United States",
  "West Chester, United States",
  "West Chicago, United States",
  "West Columbia, United States",
  "West Coxsackie, United States",
  "West Dereham PE, United Kingdom",
  "West Drayton London, United Kingdom",
  "West Hallam, United Kingdom",
  "West Horndon, United Kingdom",
  "West Kingsdown Kent, United Kingdom",
  "West Kirby Wirral, United Kingdom",
  "West Memphis, United States",
  "West Molesey, United Kingdom",
  "West Monroe, United States",
  "West Nyack, United States",
  "West Palm Beach, United States",
  "West Plains, United States",
  "West Sacramento, United States",
  "West Seneca, United States",
  "West Springfield, United States",
  "West Thurrock, United Kingdom",
  "West Unity, United States",
  "West Warwick, United States",
  "Westbrook, United States",
  "Westbury, United States",
  "Westbury Wiltshire, United Kingdom",
  "Westcliff On Sea, United Kingdom",
  "Westerham, United Kingdom",
  "Westerheim (Wuertt), Germany",
  "Westerlo, Belgium",
  "Westerly, United States",
  "Westerstede, Germany",
  "Westerville, United States",
  "Westhausen (Wuertt), Germany",
  "Westhill Aberdeenshire, United Kingdom",
  "Westhofen, Germany",
  "Westhoughton, United Kingdom",
  "Westlake, United States",
  "Westland, United States",
  "Westmoreland, United States",
  "Weston, United States",
  "Weston Dorset, United Kingdom",
  "Weston Zoyland, United Kingdom",
  "Weston-Super-Mare, United Kingdom",
  "Westwoodside Lincolnshire, United Kingdom",
  "Wetherby, United Kingdom",
  "Wetter (Ruhr), Germany",
  "Wetteren, Belgium",
  "Wettringen (Kr Steinfurt), Germany",
  "Wetzikon, Switzerland",
  "Wetzlar, Germany",
  "Weurt, Netherlands",
  "Wevelgem, Belgium",
  "Wewak, Papua New Guinea",
  "Wexford Co Wexford, Ireland",
  "Weybridge, United Kingdom",
  "Weyers Cave, United States",
  "Weyersheim, France",
  "Weyhe, Germany",
  "Weymouth, United Kingdom",
  "Whaley Bridge, United Kingdom",
  "Whetstone London, United Kingdom",
  "Whiston Knowsley, United Kingdom",
  "Whitakers, United States",
  "Whitby, Canada",
  "Whitby North Yorkshire, United Kingdom",
  "Whitchurch Bristol, United Kingdom",
  "Whitchurch Shrops, United Kingdom",
  "White, United States",
  "White Marsh, United States",
  "White Sand, United States",
  "Whitefield Bury, United Kingdom",
  "Whitehouse, United States",
  "WHITELEY, FAREHAM, United Kingdom",
  "Whites Creek, United States",
  "Whitley Bay, United Kingdom",
  "Whitley Coventry, United Kingdom",
  "Whitsett, United States",
  "Whitstable, United Kingdom",
  "Whittlesey, United Kingdom",
  "Whittlesford CB, United Kingdom",
  "Whitworth, United Kingdom",
  "Wiazowna, Poland",
  "Wichita, United States",
  "Wick Highland, United Kingdom",
  "Wickede (Ruhr), Germany",
  "Wickford, United Kingdom",
  "Wickliffe, United States",
  "Wicklow Wicklow, Ireland",
  "Widnes, United Kingdom",
  "Wiebelsheim, Germany",
  "Wiedemar, Germany",
  "Wiefelstede, Germany",
  "Wiehl, Germany",
  "Wieliczka, Poland",
  "Wielsbeke, Belgium",
  "Wielun, Poland",
  "Wiener Neudorf, Austria",
  "Wiener Neustadt, Austria",
  "Wieringerwerf, Netherlands",
  "Wiesbaden, Germany",
  "Wieselburg, Austria",
  "Wiesemscheid, Germany",
  "Wiesentheid, Germany",
  "Wiesenttal, Germany",
  "Wieseth, Germany",
  "Wiesloch, Germany",
  "Wietmarschen, Germany",
  "Wigan, United Kingdom",
  "Wigston, United Kingdom",
  "Wigton, United Kingdom",
  "Wijchen, Netherlands",
  "Wijdewormer, Netherlands",
  "Wijnegem, Belgium",
  "Wilamowice, Poland",
  "Wilbertoord, Netherlands",
  "Wilburgstetten, Germany",
  "Wildalpen, Austria",
  "Wildau (bei Koenigs Wusterhausen), Germany",
  "Wildeck-Obersuhl, Germany",
  "Wildeshausen, Germany",
  "Wildflecken, Germany",
  "Wildon, Austria",
  "Wildpoldsried, Germany",
  "Wilhelmshaven, Germany",
  "Wilkes Barre, United States",
  "Wilkow, Poland",
  "Wilkowice, Poland",
  "Willand, United Kingdom",
  "Willard, United States",
  "Willebroek, Belgium",
  "Willemstad, Curacao",
  "WillenhallWalsall, United Kingdom",
  "Willesden, United Kingdom",
  "Williamsburg, United States",
  "Williamsport, United States",
  "Willich, Germany",
  "Willingen, Germany",
  "Williton Somerset, United Kingdom",
  "Willstaett, Germany",
  "Wilmer, United States",
  "Wilmington, United States",
  "Wilmslow, United Kingdom",
  "Wilnsdorf, Germany",
  "Wilrijk, Belgium",
  "Wilsdruff, Germany",
  "Wilson, United States",
  "Wilthen, Germany",
  "Wilton Cleveland, United Kingdom",
  "Wilton North Yorkshire, United Kingdom",
  "Wimbledon, United Kingdom",
  "Wimblington, United Kingdom",
  "Wimborne Minster, United Kingdom",
  "Wincanton, United Kingdom",
  "Wincham, United Kingdom",
  "Winchcombe, United Kingdom",
  "Winchester, United Kingdom",
  "Winchester, United States",
  "Windeck (Sieg), Germany",
  "Windermere, United Kingdom",
  "Windhagen (Westerwald), Germany",
  "Windham, United States",
  "Windhoek, Namibia",
  "Windischeschenbach, Germany",
  "Windsbach, Germany",
  "Windsor, United Kingdom",
  "Windsor, United States",
  "Windsor Locks, United States",
  "Winfield, United States",
  "Wingham, Canada",
  "Wingles, France",
  "Winhoering, Germany",
  "Winkelhaid, Germany",
  "Winkleigh Devon, United Kingdom",
  "Winnenden, Germany",
  "Winnipeg, Canada",
  "Winnweiler, Germany",
  "Winschoten, Netherlands",
  "Winsen (Luhe), Germany",
  "Winsford Ches, United Kingdom",
  "Winston Salem, United States",
  "Winter Garden, United States",
  "Winter Haven, United States",
  "Winterbach (Wuertt), Germany",
  "Winterberg (Westf), Germany",
  "Winterswijk, Netherlands",
  "Winterthur, Switzerland",
  "Winzer, Germany",
  "Wipperfuerth, Germany",
  "Wirges, Germany",
  "Wirksworth, United Kingdom",
  "Wisbech, United Kingdom",
  "Wisches, France",
  "Wishaw Lanarkshire, United Kingdom",
  "Wismar (Meckl), Germany",
  "Wissembourg, France",
  "Wissen, Germany",
  "Wissenbach (Eschenburg), Germany",
  "Wissington Suffolk, United Kingdom",
  "Wissous, France",
  "Witham, United Kingdom",
  "Withernsea, United Kingdom",
  "Witkowo, Poland",
  "Witney, United Kingdom",
  "Witten, Germany",
  "Wittenbach, Switzerland",
  "Wittenberge, Germany",
  "Wittenburg, Germany",
  "Wittenheim, France",
  "Wittichenau, Germany",
  "Wittingen, Germany",
  "Wittislingen, Germany",
  "Wittlich, Germany",
  "Witzenhausen, Germany",
  "Wladyslawow k Czestochowy, Poland",
  "Wladyslawowo, Poland",
  "Wloclawek, Poland",
  "Woburn Sands, United Kingdom",
  "Wodzislaw Slaski, Poland",
  "Woelfersheim, Germany",
  "Woellstein, Germany",
  "Woerden, Netherlands",
  "Woergl, Austria",
  "Woernitz, Germany",
  "Woerrstadt, Germany",
  "Woerth a d Donau, Germany",
  "Woerth a d Isar, Germany",
  "Woerth a Rhein, Germany",
  "Woerth am Main, Germany",
  "Woippy, France",
  "Woking, United Kingdom",
  "Wokingham, United Kingdom",
  "Wola Mrokowska, Poland",
  "Wola Rzedzinska, Poland",
  "Wolbrom, Poland",
  "Wolfegg, Germany",
  "Wolfen, Germany",
  "Wolfenbuettel, Germany",
  "Wolfertschwenden, Germany",
  "Wolfhagen, Germany",
  "Wolfhausen, Switzerland",
  "Wolframs-Eschenbach, Germany",
  "Wolfratshausen, Germany",
  "Wolfstein (Pfalz), Germany",
  "Wolfurt, Austria",
  "Wolkenstein, Germany",
  "Wolkersdorf, Austria",
  "Wollaston Northants, United Kingdom",
  "Wolnzach, Germany",
  "Wolomin, Poland",
  "Wolsztyn, Poland",
  "Wolvega, Netherlands",
  "Wolverhampton, United Kingdom",
  "Wolvey, United Kingdom",
  "Wombourne, United Kingdom",
  "Wombwell Barnsley, United Kingdom",
  "Wondelgem, Belgium",
  "Wooburn Green, United Kingdom",
  "Wood Dale, United States",
  "Woodbridge, Canada",
  "Woodbridge, United Kingdom",
  "Woodbridge, United States",
  "Woodburn, United States",
  "Woodford Green London, United Kingdom",
  "Woodhall Spa, United Kingdom",
  "Woodinville, United States",
  "Woodland, United States",
  "Woodley, United Kingdom",
  "Woodruff, United States",
  "Woodstock, Canada",
  "Woodstock, South Africa",
  "Woodville, United Kingdom",
  "Woolwich, United Kingdom",
  "Wootton Northamptonshire, United Kingdom",
  "Wootton Wawen, United Kingdom",
  "WORB, Switzerland",
  "Worcester, South Africa",
  "Worcester, United Kingdom",
  "Worcester, United States",
  "Worcester Park, United Kingdom",
  "Workington, United Kingdom",
  "Worksop, United Kingdom",
  "Workum, Netherlands",
  "Wormer, Netherlands",
  "Wormerveer, Netherlands",
  "Worminghall, United Kingdom",
  "Worms, Germany",
  "Worsley, United Kingdom",
  "Worthing West Sussex, United Kingdom",
  "Worthington Leicestershire, United Kingdom",
  "Woudenberg, Netherlands",
  "Wrabness, United Kingdom",
  "Wraysbury Windsor, United Kingdom",
  "Wretham, United Kingdom",
  "Wrexham, United Kingdom",
  "Wroclaw, Poland",
  "Wronki, Poland",
  "Wrzesnia, Poland",
  "Wschowa, Poland",
  "Wuelfrath, Germany",
  "Wuelknitz, Germany",
  "Wuerselen, Germany",
  "Wuerzburg, Germany",
  "Wuestenselbitz, Germany",
  "Wuhan, China",
  "Wuhu, China",
  "Wujiang, China",
  "Wunsiedel, Germany",
  "Wunstorf, Germany",
  "Wuppertal, Germany",
  "Wurmberg, Germany",
  "Wurzen, Germany",
  "Wustermark, Germany",
  "Wutha-Farnroda, Germany",
  "Wutoeschingen, Germany",
  "Wuzhou, China",
  "Wyalusing, United States",
  "Wyke, United Kingdom",
  "Wymiarki, Poland",
  "Wymondham Norfolk, United Kingdom",
  "Wyoming, United States",
  "Wyrzysk, Poland",
  "Wysogotowo, Poland",
  "Wysokie Mazowieckie, Poland",
  "Wyszkow, Poland",
  "Wythenshawe, United Kingdom",
  "Waakirchen, Germany",
  "Waalwijk, Netherlands",
  "Waardenburg, Netherlands",
  "Xalisco, Mexico",
  "Xanthi, Greece",
  "Xativa, Spain",
  "Xenia, United States",
  "Xeraco, Spain",
  "Xeresa, Spain",
  "Xhoris, Belgium",
  "Xi An, China",
  "Xiamen, China",
  "Xiaolan, China",
  "Xingang, China",
  "Xinhui, China",
  "Xintang, China",
  "Xinzo de Limia, Spain",
  "Y Felinheli, United Kingdom",
  "Yagotin, Ukraine",
  "Yakapinar, Turkey",
  "Yalova, Turkey",
  "Yambol, Bulgaria",
  "Yanbu, Saudi Arabia",
  "Yangon, Myanmar (Burma)",
  "Yangpu, China",
  "Yangzhou, China",
  "Yantai, China",
  "Yantian, China",
  "Yardley, United Kingdom",
  "Yarimca, Turkey",
  "Yarm, United Kingdom",
  "Yaroslavl, Russia",
  "Yassa, Cameroon",
  "Yate, United Kingdom",
  "Yateley, United Kingdom",
  "Yatton North Somerset, United Kingdom",
  "Yavne, Israel",
  "Yavorov, Ukraine",
  "Yaxley Cambridgeshire, United Kingdom",
  "Yazoo City, United States",
  "Yeadon, United Kingdom",
  "Yecapixtla, Mexico",
  "Yecla, Spain",
  "Yekaterinburg, Russia",
  "Yeles, Spain",
  "Yelverton Devon, United Kingdom",
  "Yenibosna, Turkey",
  "Yenice, Turkey",
  "Yenisehir, Turkey",
  "Yeovil, United Kingdom",
  "Yepes, Spain",
  "Yerevan, Armenia",
  "Yermo, United States",
  "Yerseke, Netherlands",
  "Yeruham, Israel",
  "Yffiniac, France",
  "Yi Wu, China",
  "Yibin, China",
  "Yichang, China",
  "Ylojarvi, Finland",
  "Yokkaichi, Japan",
  "Yokohama, Japan",
  "Yokosuka Kanagawa, Japan",
  "Yokota Fussa Tokyo, Japan",
  "Yonkers, United States",
  "Yoqneam, Israel",
  "York, Canada",
  "York, United Kingdom",
  "York, United States",
  "Yorkville, United States",
  "Youghal Cork, Ireland",
  "Youngstown, United States",
  "Ypsonas, Cyprus",
  "Ystad, Sweden",
  "Ystrad Mynach, United Kingdom",
  "Yue Yang, China",
  "YUMA, United States",
  "Yun Fu, China",
  "Yuncler, Spain",
  "Yuncos, Spain",
  "Yundum, Gambia",
  "Yunquera de Henares, Spain",
  "Yutz, France",
  "Yuzhno-Kurilsk, Russia",
  "Yuzhno-Sakhalinsk, Russia",
  "Yzeure, France",
  "Zabia Wola, Poland",
  "Zabierzow, Poland",
  "Zabludow, Poland",
  "Zabno, Poland",
  "Zaborow, Poland",
  "Zabreh Na Morave, Czech Republic",
  "Zabrowo, Poland",
  "Zabrze, Poland",
  "Zacapa, Guatemala",
  "Zacapu, Mexico",
  "Zacatecas, Mexico",
  "Zacler, Czech Republic",
  "Zaczernie, Poland",
  "Zadar, Croatia",
  "Zafra, Spain",
  "Zaghwan-Zagouan, Tunisia",
  "Zagreb, Croatia",
  "Zahna, Germany",
  "Zahony, Hungary",
  "Zaidin, Spain",
  "Zakupy, Czech Republic",
  "Zalaegerszeg, Hungary",
  "Zalalovo, Hungary",
  "Zalec, Slovenia",
  "Zalla, Spain",
  "Zaltbommel, Netherlands",
  "Zama Kanagawa, Japan",
  "Zamarramala, Spain",
  "Zamberk, Czech Republic",
  "Zambrow, Poland",
  "Zamora, Mexico",
  "Zamora, Spain",
  "Zamosc, Poland",
  "Zamrsk, Czech Republic",
  "Zamudio, Spain",
  "Zandobbio, Italy",
  "Zane, Italy",
  "Zanica, Italy",
  "Zanzibar, Tanzania",
  "Zapfendorf, Germany",
  "Zapopan, Mexico",
  "Zapresic, Croatia",
  "Zaragoza, El Salvador",
  "Zaragoza, Spain",
  "Zaraisk, Russia",
  "Zaratamo, Spain",
  "ZARATE, Argentina",
  "Zarka Free Zone, Jordan",
  "Zarki, Poland",
  "Zarnovica, Slovakia",
  "Zarosice, Czech Republic",
  "Zarow, Poland",
  "Zarqa, Jordan",
  "Zarrentin (Meckl), Germany",
  "Zary, Poland",
  "Zarza de Tajo Cuenca, Spain",
  "Zarzalejo, Spain",
  "Zarzis, Tunisia",
  "Zasada, Czech Republic",
  "Zaslavl, Belarus",
  "Zatec, Czech Republic",
  "ZAVAR, Slovakia",
  "Zavazna Poruba, Slovakia",
  "Zawidow, Poland",
  "Zawiercie, Poland",
  "Zbraslavice, Czech Republic",
  "Zbuch, Czech Republic",
  "Zbysov, Czech Republic",
  "Zdanice, Czech Republic",
  "Zdar Nad Sazavou, Czech Republic",
  "Zdiby, Czech Republic",
  "Zdice, Czech Republic",
  "Zdirec nad Doubravou, Czech Republic",
  "Zdunska Wola, Poland",
  "Zdzieszowice, Poland",
  "Zebrak, Czech Republic",
  "Zeccone, Italy",
  "Zeddam, Netherlands",
  "Zedelgem, Belgium",
  "Zeebrugge, Belgium",
  "Zeeland, United States",
  "Zeewolde, Netherlands",
  "Zehdenick, Germany",
  "Zeil (am Main), Germany",
  "Zeist, Netherlands",
  "Zeitz (Elster), Germany",
  "Zele, Belgium",
  "Zelechovice Nad Drevnici, Czech Republic",
  "Zelenec, Czech Republic",
  "Zeletava, Czech Republic",
  "Zelevcice, Czech Republic",
  "Zelezny Brod, Czech Republic",
  "Zelienople, United States",
  "Zeliv, Czech Republic",
  "Zell (Mosel), Germany",
  "Zell im Wiesental, Germany",
  "Zella Mehlis, Germany",
  "Zeltingen-Rachtig, Germany",
  "Zemendorf-Stoettera, Austria",
  "ZENETA, Spain",
  "Zenson di Piave, Italy",
  "Zerbst, Germany",
  "Zermeghedo, Italy",
  "Zero Branco, Italy",
  "Zervochori Verias, Greece",
  "Zestafoni, Georgia",
  "Zestoa, Spain",
  "Zeulenroda, Germany",
  "Zeven, Germany",
  "Zevenbergen, Netherlands",
  "Zevenbergschen Hoek, Netherlands",
  "Zevenaar, Netherlands",
  "Zevio, Italy",
  "Zeytinburnu, Turkey",
  "Zgierz, Poland",
  "Zgorzelec, Poland",
  "Zhangjiagang, China",
  "Zhanjiang, China",
  "Zhaoqing, China",
  "Zhengzhou, China",
  "Zhenjiang, China",
  "Zhongshan, China",
  "Zhuhai, China",
  "Zhytkavichy, Belarus",
  "Zhytomyr, Ukraine",
  "Ziano di Fiemme, Italy",
  "Ziar Nad Hronom, Slovakia",
  "Zibido San Giacomo, Italy",
  "Zichron Ya'akov, Israel",
  "Zidlochovice, Czech Republic",
  "Ziebice, Poland",
  "Zielona Gora, Poland",
  "Zierenberg, Germany",
  "Zierikzee, Netherlands",
  "Ziguinchor, Senegal",
  "Zilina, Slovakia",
  "Zimella, Italy",
  "Zimmern Ob Rottweil, Germany",
  "Zimnicea, Romania",
  "Zingonia, Italy",
  "Zionsville, United States",
  "Zirl, Austria",
  "Zirndorf, Germany",
  "Zirovnice, Czech Republic",
  "Zittau, Germany",
  "Zizelice, Czech Republic",
  "Zizers, Switzerland",
  "Zizurkil, Spain",
  "Zlata Olesnice, Czech Republic",
  "Zlate Moravce, Slovakia",
  "Zlin, Czech Republic",
  "Zliv, Czech Republic",
  "Zlotoryja, Poland",
  "Zlotow, Poland",
  "Zmigrod, Poland",
  "Znojmo, Czech Republic",
  "Zocca, Italy",
  "Zoerbig, Germany",
  "Zoetermeer, Netherlands",
  "Zoeterwoude, Netherlands",
  "Zogno, Italy",
  "Zola Predosa, Italy",
  "Zollikofen, Switzerland",
  "Zonguldak, Turkey",
  "Zonhoven, Belgium",
  "Zonnebeke, Belgium",
  "Zoppe, Italy",
  "Zoppola, Italy",
  "Zorawina, Poland",
  "Zorneding, Germany",
  "Zory, Poland",
  "Zottegem, Belgium",
  "Zoutkamp, Netherlands",
  "Zrece, Slovenia",
  "Zruc nad Sazavou, Czech Republic",
  "Zsambek, Hungary",
  "Zschornewitz, Germany",
  "Zubillaga, Spain",
  "Zubri, Czech Republic",
  "Zuelpich, Germany",
  "Zuera, Spain",
  "Zugdidi, Georgia",
  "Zugliano, Italy",
  "Zuid-Beijerland, Netherlands",
  "Zuidbroek, Netherlands",
  "Zuidland, Netherlands",
  "Zulova, Czech Republic",
  "Zulte, Belgium",
  "Zumaia, Spain",
  "Zumarraga, Spain",
  "Zurawica, Poland",
  "Zurbaran, Spain",
  "Zurich, Switzerland",
  "Zusmarshausen, Germany",
  "Zutendaal, Belgium",
  "Zutphen, Netherlands",
  "Zuzemberk, Slovenia",
  "Zuzenhausen, Germany",
  "Zverinek, Czech Republic",
  "ZVISHAVANE, Zimbabwe",
  "Zvolen, Slovakia",
  "Zvornik, Bosnia and Herzegovina",
  "Zwanenburg, Netherlands",
  "Zweibruecken (Pfalz), Germany",
  "Zwenkau, Germany",
  "Zwentendorf an der Donau, Austria",
  "Zwevegem, Belgium",
  "Zwevezele, Belgium",
  "Zwickau, Germany",
  "Zwiesel, Germany",
  "Zwijndrecht, Belgium",
  "Zwijndrecht, Netherlands",
  "Zwoenitz, Germany",
  "Zwolle, Netherlands",
  "Zwaag, Netherlands",
  "Zywiec, Poland",
  "Zaandam, Netherlands",
  "Aabenraa, Denmark",
  "Aach (Hegau), Germany",
  "Aachen, Germany",
  "Aahus, Sweden",
  "Aakers Styckebruk, Sweden",
  "Aalbaek, Denmark",
  "Aalborg, Denmark",
  "Aalborg East, Denmark",
  "Aalen (Wuertt), Germany",
  "Aalesund, Norway",
  "Aalsmeer, Netherlands",
  "Aalst, Belgium",
  "Aalst Gld, Netherlands",
  "Aalten, Netherlands",
  "Aamaal, Sweden",
  "Aarau, Switzerland",
  "Aarbergen, Germany",
  "Aarburg, Switzerland",
  "Aarhus, Denmark",
  "Aarschot, Belgium",
  "Aartselaar, Belgium",
  "Aastorp, Sweden",
  "A Caniza, Spain",
  "Aba, Nigeria",
  "Abbeville, United States",
  "Abbottstown, United States",
  "Abensberg, Germany",
  "Abertillery, United Kingdom",
  "Aberystwyth, United Kingdom",
  "Abilene, United States",
  "Abotts Langley, United Kingdom",
  "Absecon, United States",
  "Abuja, Nigeria",
  "Acanceh, Mexico",
  "Acapulco, Mexico",
  "Accra, Ghana",
  "Achada Grande Traz, Cape Verde Island",
  "Achau, Austria",
  "Achel, Belgium",
  "Acheson, Canada",
  "Achladi, Greece",
  "Aci San Antonio, Italy",
  "Acireale, Italy",
  "Aclens, Switzerland",
  "Acton, Canada",
  "Aculco, Mexico",
  "Ad Dulayl, Jordan",
  "Addingham, United Kingdom",
  "Addington London, United Kingdom",
  "Adeje, Spain",
  "Adra, Spain",
  "Adrian, United States",
  "Adstock, Canada",
  "Aelghult, Sweden",
  "Aeroton, South Africa",
  "Aesch BL, Switzerland",
  "Affing, Germany",
  "Agassiz, Canada",
  "Agege, Nigeria",
  "Aghalee Northern Ireland, United Kingdom",
  "Agia Napa, Cyprus",
  "Agia Pelagia, Greece",
  "AGIA VARVARA, Greece",
  "Agios Nikolaos, Greece",
  "Agnesberg, Sweden",
  "Aguadulce, Panama",
  "Ahrensfelde, Germany",
  "Aigen im Muhlkreis, Austria",
  "Aigle, Switzerland",
  "Airvault, France",
  "Akko, Israel",
  "Akrotiri, Cyprus",
  "Akwa Nord, Cameroon",
  "Al Khobar, Saudi Arabia",
  "Al Wakra, Qatar",
  "Alabaster, United States",
  "Alachua, United States",
  "Alamo, United States",
  "Albarraque, Portugal",
  "Albemarle, United States",
  "Albersdorf-Prebuch, Austria",
  "Alberswil, Switzerland",
  "Albertirsa, Hungary",
  "Albertslund, Denmark",
  "Albertville, United States",
  "Albeta, Spain",
  "Albon, France",
  "Albourne, United Kingdom",
  "Albrechtice u Ceskeho Tesina, Czech Republic",
  "Albufeira, Portugal",
  "Albuquerque, United States",
  "Alburtis, United States",
  "Alcabideche, Portugal",
  "Alcamo, Italy",
  "Alcanar, Spain",
  "Alcantara, Spain",
  "Alcantarilha, Portugal",
  "Alcoa, United States",
  "Alcocer de Planes, Spain",
  "Alcoutim, Portugal",
  "Alcover, Spain",
  "Aldeia de Paio Pires, Portugal",
  "Aldergrove, Canada",
  "Aldie, United States",
  "Aleg, Mauritania",
  "Aleksandrow Lodzki, Poland",
  "Alexander, United States",
  "Alexander City, United States",
  "Alexandria, Canada",
  "Alexandria, United Kingdom",
  "Alfara del Patriarca, Spain",
  "Algarrobo, Spain",
  "Alger, Algeria",
  "Algiers Terminal Eight, Algeria",
  "Algiers Terminal Five, Algeria",
  "Algiers Terminal Four, Algeria",
  "Algiers Terminal One, Algeria",
  "Algiers Terminal Seven, Algeria",
  "Algiers Terminal Six, Algeria",
  "Algiers Terminal Three, Algeria",
  "Algiers Terminal Two, Algeria",
  "Algona, United States",
  "Alhaurin el Grande, Spain",
  "Alhendin, Spain",
  "Ali Oune, Djibouti",
  "Alkoven, Austria",
  "Allaire, France",
  "Allamuchy, United States",
  "Alleroed, Denmark",
  "Allershausen (Oberbayern), Germany",
  "Alleur, Belgium",
  "Allhartsberg, Austria",
  "Alliance, United States",
  "Allingabro, Denmark",
  "Alliston, Canada",
  "ALMA, United States",
  "Almagro, Spain",
  "Al-Mazyounah, Oman",
  "Almonaster La Real, Spain",
  "Almont, United States",
  "Almonte, Canada",
  "Alosno, Spain",
  "Alpicat, Spain",
  "Alseno, Italy",
  "Alsonemedi, Hungary",
  "Altamont, United States",
  "Altamonte Springs, United States",
  "Altendiez, Germany",
  "Alto, United States",
  "Altomuenster, Germany",
  "Alton Derbyshire, United Kingdom",
  "Altoona, United States",
  "Altsasu-Alsasua, Spain",
  "Alva, United Kingdom",
  "Alvarado, United States",
  "Alvechurch, United Kingdom",
  "Alvesta, Sweden",
  "Alvin, United States",
  "Alzuza, Spain",
  "Amalgam, South Africa",
  "Amarateca, Honduras",
  "Amatitlan, Guatemala",
  "Amble, United Kingdom",
  "Ambleside, United Kingdom",
  "Amboy, United States",
  "Amer, Spain",
  "American Canyon, United States",
  "Americana, Brazil",
  "Americus, United States",
  "Amery, United States",
  "Ammern, Germany",
  "Amstetten, Austria",
  "An Najaf, Iraq",
  "Anacortes, United States",
  "Anais Charente, France",
  "Ancaster, Canada",
  "Anchorage, United States",
  "Andernos les Bains, France",
  "Andersonville, United States",
  "ANDILLY, France",
  "Andorra la Vella, Andorra",
  "Andrews Air Force Base, United States",
  "Andreze, France",
  "Andrychow, Poland",
  "Angeles City, Philippines",
  "ANGLES, Spain",
  "Anhovo, Slovenia",
  "Anji, China",
  "Anmoore, United States",
  "Annville, United States",
  "Ano Liosia, Greece",
  "Ans, Belgium",
  "Ansfelden, Austria",
  "Antioch, United States",
  "Antrim Toombridge Northern Ireland, United Kingdom",
  "Antwerp, United States",
  "Anyama, Ivory Coast",
  "Anyksciai, Lithuania",
  "Aomi Koto-ku, Japan",
  "Apache Junction, United States",
  "APAN, Mexico",
  "Apen, Germany",
  "Apex, United States",
  "Apollo, United States",
  "Apopka, United States",
  "Apple Creek, United States",
  "Apple Valley, United States",
  "Appleton Halton WA, United Kingdom",
  "Aqaba Free Zone, Jordan",
  "Arafat, Mauritania",
  "Arar, Saudi Arabia",
  "Aravaca, Spain",
  "Arbo, Spain",
  "Arborio, Italy",
  "Arbucies, Spain",
  "ARCADIA - PRETORIA, South Africa",
  "Arcene, Italy",
  "Archdale, United States",
  "Arcozelo Porto, Portugal",
  "Arcueil, France",
  "Arden, United States",
  "Arden Hills, United States",
  "Ardglass Northern Ireland, United Kingdom",
  "Ardmore, United States",
  "Arendal, Norway",
  "Arenys de Mar, Spain",
  "Arenys de Munt, Spain",
  "Argo, United States",
  "Argyroupolis, Greece",
  "Arica Peru, Peru",
  "Arichat, Canada",
  "Ariss, Canada",
  "Arkadelphia, United States",
  "Arklow Wicklow, Ireland",
  "Arlington Heights, United States",
  "Arlit, Niger",
  "Arloev, Sweden",
  "Armadale Highland, United Kingdom",
  "Arnold Nottinghamshire, United Kingdom",
  "Arnprior, Canada",
  "Aromas, United States",
  "Arona, Spain",
  "Arrington, United States",
  "Arroyo de la Miel, Spain",
  "Arroyomolinos Madrid, Spain",
  "Artashat, Armenia",
  "Artem, Russia",
  "Artes BARCELONA, Spain",
  "Artesa de Lleida, Spain",
  "Arthon, France",
  "Arthur, Canada",
  "Arthur, United States",
  "Arusha, Tanzania",
  "Arvika, Sweden",
  "Arzal, France",
  "Asajaya, Malaysia",
  "A-San, Korea, South",
  "Ashaway, United States",
  "Ashbourne Meath, Ireland",
  "Ashburton Devon, United Kingdom",
  "Ashchurch, United Kingdom",
  "Ashkabad, Turkmenistan",
  "Ashover Derbyshire, United Kingdom",
  "Ashton, South Africa",
  "Ashurst Wood, United Kingdom",
  "Asiago, Italy",
  "Assaku, Estonia",
  "Asse, Belgium",
  "ASSOMADA, Cape Verde Island",
  "Assumption, United States",
  "Aston Wokingham, United Kingdom",
  "Astoria, United States",
  "Astros, Greece",
  "Aszar, Hungary",
  "Aszod, Hungary",
  "Atar, Mauritania",
  "Atenas, Costa Rica",
  "Atherton, United States",
  "Athlone Westmeath, Ireland",
  "Atibaia, Brazil",
  "Atlacomulco, Mexico",
  "Atlantis, South Africa",
  "Atlasburg, United States",
  "Atlit, Israel",
  "Atsugi, Japan",
  "Attinguie, Ivory Coast",
  "Attleboro, United States",
  "Aubiere, France",
  "Auburn Hills, United States",
  "Auchinleck, United Kingdom",
  "Auchtermuchty, United Kingdom",
  "Auengrund, Germany",
  "Aughnacloy, United Kingdom",
  "Aulum, Denmark",
  "Auray, France",
  "Aurora, Canada",
  "Aurskog, Norway",
  "Austell, United States",
  "Austevoll, Norway",
  "Autechaux, France",
  "Autechaux Roide, France",
  "Authon du Perche, France",
  "Avidos, Portugal",
  "Avintes, Portugal",
  "Avon, United States",
  "Avon Park, United States",
  "Avrigny, France",
  "Awassa, Ethiopia",
  "Axbridge, United Kingdom",
  "Aylmer, Canada",
  "Ayr, Canada",
  "Aytre, France",
  "Azay le Brule, France",
  "Azle, United States",
  "Azrou, Morocco",
  "Azur, Israel",
  "Babaikivka, Ukraine",
  "Babenhausen (Hess), Germany",
  "Babite, Latvia",
  "Bac Giang, Vietnam",
  "Bad Nauheim, Germany",
  "Bad Rippoldsau Schapbach, Germany",
  "Bad Toelz, Germany",
  "Bad Urach, Germany",
  "Bad Vilbel, Germany",
  "Bad Zwischenahn, Germany",
  "Baddeckenstedt, Germany",
  "Baddi, India",
  "Baden, Canada",
  "Baghdad, Iraq",
  "Bagheria, Italy",
  "Bagillt, United Kingdom",
  "Baguineda, Mali",
  "Bahadurgarh, India",
  "Bahcelievler-Istanbul, Turkey",
  "Baia Mare, Romania",
  "Baie-du-Febvre, Canada",
  "Baie-D'Urfe, Canada",
  "Baildon Bradford, United Kingdom",
  "Bailly, France",
  "Bainbridge, United States",
  "Baj, Hungary",
  "Baja, Hungary",
  "Bakersfield, United States",
  "Bala, United Kingdom",
  "Balabanovo, Russia",
  "Balatonfuzfo, Hungary",
  "Balch Springs, United States",
  "Baldwin, United States",
  "Baldwyn, United States",
  "Balerno, United Kingdom",
  "Bali, Cameroon",
  "Balice, Poland",
  "Balkany, Hungary",
  "Ballerup, Denmark",
  "Ballinger, United States",
  "Ballito, South Africa",
  "Ballyclare Northern Ireland, United Kingdom",
  "Ballylanders Co Limerick, Ireland",
  "Ballynure Northern Ireland, United Kingdom",
  "Balotesti, Romania",
  "Balsareny, Spain",
  "Balsta, Sweden",
  "Balzheim, Germany",
  "Bangkok Modern Terminal, Thailand",
  "Bangor, United States",
  "Bangui, Central African Republic",
  "Baniocha, Poland",
  "Bankeryd, Sweden",
  "Banks Lancashire, United Kingdom",
  "Bankya, Bulgaria",
  "Banner Elk, United States",
  "Banov, Slovakia",
  "Bantam, United States",
  "Baraboo, United States",
  "Baranowo, Poland",
  "Barbadillo Mercado, Spain",
  "Barbezieux Saint Hilaire, France",
  "Bardu, Norway",
  "Bareyo, Spain",
  "Barksdale AFB, United States",
  "Barnwell, United States",
  "Barra, Gambia",
  "Barrington, United States",
  "Barrow upon Soar, United Kingdom",
  "Barrowford Lancashire, United Kingdom",
  "Barsinghausen, Germany",
  "Barssel, Germany",
  "Bartlett, United States",
  "Barton in the Clay, United Kingdom",
  "Bas en Basset, France",
  "Basiglio, Italy",
  "Basrah, Iraq",
  "Basseterre, St Kitts-Nevis",
  "Bassiknou, Mauritania",
  "Batesburg, United States",
  "Battle East Sussex, United Kingdom",
  "Batumi, Georgia",
  "BAUDOUR, Belgium",
  "Baume les Dames, France",
  "Bawal, India",
  "Baxenden, United Kingdom",
  "Bay City, United States",
  "Bay Minette, United States",
  "Bay Shore, United States",
  "Bayamon, Puerto Rico",
  "Beachville, Canada",
  "Beacon Falls, United States",
  "Beaminster, United Kingdom",
  "Beamsville, Canada",
  "Bean Station, United States",
  "Beau Bassin-Rose Hill, Mauritius",
  "Beaucourt, France",
  "Beaufort, United States",
  "Beaulieu / Hampshire, United Kingdom",
  "Beaulieu Sur Layon, France",
  "Beaumont Monteux, France",
  "Beauville, France",
  "Beaver Dam, United States",
  "Beaver Falls, United States",
  "Bebington, United Kingdom",
  "Becvary, Czech Republic",
  "Bedburg-Hau, Germany",
  "Bedee, France",
  "Bedford Hills, United States",
  "Bedstone, United Kingdom",
  "Bedworth Warwickshire, United Kingdom",
  "Beijing, China",
  "Beilen, Netherlands",
  "Beirut Free Zone, Lebanon",
  "Beit Shean, Israel",
  "Bekescsaba, Hungary",
  "Bekkai, Japan",
  "Bela pod Bezdezem, Czech Republic",
  "Belding, United States",
  "Belgern, Germany",
  "Belize, Belize",
  "Bell, United States",
  "Bellach, Switzerland",
  "Bellaghy Northern Ireland, United Kingdom",
  "Belle Chasse, United States",
  "Belle Glade, United States",
  "Belle Rose, Mauritius",
  "Bellefontaine, United States",
  "Bellflower, United States",
  "Bellingham, United States",
  "Bellmawr, United States",
  "Bells, United States",
  "Belmonte de Miranda Asturias, Spain",
  "Beloeil, Canada",
  "Beloit, United States",
  "Beloslav, Bulgaria",
  "Belpahar, India",
  "Belsele, Belgium",
  "Beltring, United Kingdom",
  "Beltsville, United States",
  "Ben Guerir, Morocco",
  "Benalmadena, Spain",
  "Benamargosa, Spain",
  "Benamaurel, Spain",
  "Bend, United States",
  "Bendery, Moldova",
  "Benesov nad Ploucnici, Czech Republic",
  "BENGUELA, Angola",
  "Benicia, United States",
  "Benifla, Spain",
  "Beniganim, Spain",
  "Benijofar, Spain",
  "Benissa, Spain",
  "Bentleyville, United States",
  "Beoley Worcestershire, United Kingdom",
  "Beregazzo con Figliaro, Italy",
  "Berettyoufalu, Hungary",
  "Berettyoujfalu, Hungary",
  "Berezan, Ukraine",
  "Bergen Kr Celle, Germany",
  "Bergerac, France",
  "Berghuelen, Germany",
  "Bergi, Latvia",
  "Berhida, Hungary",
  "Berkeley, United States",
  "Berkeley Heights, United States",
  "Berlin, South Africa",
  "Berlingen, Switzerland",
  "Bernau (bei Berlin), Germany",
  "Bernolakovo, Slovakia",
  "Bernsdorf, Germany",
  "Bernville, United States",
  "Berryville, United States",
  "Bertram, United States",
  "Besko, Poland",
  "Bethel, United States",
  "Bethel Park, United States",
  "Bethesda, United States",
  "Betzdorf, Germany",
  "Bexbach, Germany",
  "Bharatpur, Nepal",
  "Biarritz, France",
  "Biecz, Poland",
  "Biedermannsdorf, Austria",
  "Bien Hoa - Dong Nai, Vietnam",
  "Bienate, Italy",
  "Bienvenida, Spain",
  "Biggar South Lanarkshire, United Kingdom",
  "Biggin Hill, United Kingdom",
  "Bijeljina, Bosnia and Herzegovina",
  "Bilborough Nottinghamshire NG, United Kingdom",
  "Billerica, United States",
  "Billinghay, United Kingdom",
  "Billingstad, Norway",
  "Billund, Denmark",
  "Biloxi, United States",
  "Bilsthorpe Nottinghamshire, United Kingdom",
  "Bilthoven, Netherlands",
  "Binago, Italy",
  "Bingerville, Ivory Coast",
  "Bingham, United Kingdom",
  "Binh Duong, Vietnam",
  "Biratnagar, Nepal",
  "Birch Vale, United Kingdom",
  "Bird In Hand, United States",
  "Birdsboro, United States",
  "Birkenfeld, Germany",
  "Birr, Switzerland",
  "Birtouta, Algeria",
  "Bischoffen, Germany",
  "Bishampton Worcestershire, United Kingdom",
  "Bishkek, Kyrgyzstan",
  "Bishopbriggs, United Kingdom",
  "Bishopton Darlington, United Kingdom",
  "Bisingen, Germany",
  "Bitton Bristol, United Kingdom",
  "Biyagama, Sri Lanka",
  "Bjaernum, Sweden",
  "Bjaesta, Sweden",
  "Bjaeverskov, Denmark",
  "Bjerkreim, Norway",
  "Bjorkoby, Finland",
  "Bjugn, Norway",
  "Black Mountain, United States",
  "Black Rock, United States",
  "Blackford Cumbria, United Kingdom",
  "Blackwood Strathclyde, United Kingdom",
  "Bladensburg, United States",
  "Blaenau Ffestiniog Gwynedd, United Kingdom",
  "Blaine, United States",
  "Blairstown, United States",
  "Blairsville, United States",
  "Blaj, Romania",
  "Blakeslee, United States",
  "Blanchard, United States",
  "Blanco, United States",
  "Bland, United States",
  "Blandon, United States",
  "Blantyre, Malawi",
  "Blarney Co Cork, Ireland",
  "Blatnice pod Svatym Antoninkem, Czech Republic",
  "Blato, Croatia",
  "Bleiburg, Austria",
  "Blenheim, New Zealand",
  "Bleres, France",
  "Bloemfontein, South Africa",
  "BLOMSTERDALEN, Norway",
  "Blonie k Wroclawia, Poland",
  "Blucina, Czech Republic",
  "Blue Ash, United States",
  "Blumenthal (Holstein), Germany",
  "Blytheville, United States",
  "Boa Vista, Brazil",
  "Boa Vista, Cape Verde Island",
  "Boardman, United States",
  "Bockhorn, Germany",
  "Bodham Norfolk, United Kingdom",
  "Bodrogkeresztur, Hungary",
  "Boebingen an der Reims, Germany",
  "Boesingheliede, Netherlands",
  "Boffa, Guinea",
  "Bogodukhov, Ukraine",
  "Bogota, Colombia",
  "Bohdanovce nad Trnavou, Slovakia",
  "Bohemia, United States",
  "Bohuslavice nad Metuji, Czech Republic",
  "Boisbriand, Canada",
  "Bois-Des-Filion, Canada",
  "Boise, United States",
  "Boissy Saint Leger, France",
  "BOJNICE, Slovakia",
  "Boleradice, Czech Republic",
  "Boliden, Sweden",
  "Bolintin Deal, Romania",
  "Bonaberi, Cameroon",
  "Bonanjo, Cameroon",
  "Bonapriso, Cameroon",
  "Bonate Sopra, Italy",
  "Boncourt, Switzerland",
  "Bonham, United States",
  "Bonita Springs, United States",
  "Bonoua, Ivory Coast",
  "Bonsall, United States",
  "Booneville, United States",
  "Boonton, United States",
  "Boothwyn, United States",
  "Bor, Serbia",
  "Boranovice, Czech Republic",
  "Borcea, Romania",
  "Bordeaux en Gatinais, France",
  "Bording, Denmark",
  "Boreham Essex, United Kingdom",
  "Borek, Czech Republic",
  "Borgenhaugen, Norway",
  "Borgerhout, Belgium",
  "Borgo Poncarale, Italy",
  "Borgo San Siro, Italy",
  "Borj Cedria, Tunisia",
  "Borkheide, Germany",
  "Borlaenge, Sweden",
  "Borne, Netherlands",
  "Bornhagen, Germany",
  "Borough Green, United Kingdom",
  "Borsice, Czech Republic",
  "Boscawen, United States",
  "Bosice, Czech Republic",
  "Bossier City, United States",
  "Bothell, United States",
  "Bouchemaine, France",
  "Bouguenais, France",
  "Bouilly, France",
  "Boulder City, United States",
  "Bourbonnais, United States",
  "Bourg des Comptes, France",
  "Bourne End Buckinghamshire SL, United Kingdom",
  "Bouzonville, France",
  "BOW, United States",
  "Bowmanville, Canada",
  "Bra, Guinea-Bissau",
  "Bracebridge, Canada",
  "Brackenfell, South Africa",
  "Bradfield Berkshire, United Kingdom",
  "Bradford, Canada",
  "Bradley, United States",
  "Braelyn EBS, South Africa",
  "Brain sur l'Authion, France",
  "Braine l'Alleud, Belgium",
  "Braintree, United States",
  "Bramalea, Canada",
  "Bramfield Hertforshire, United Kingdom",
  "Bramley Surrey, United Kingdom",
  "Brampton Cambridgeshire, United Kingdom",
  "Brampton Cumbria, United Kingdom",
  "Brandesburton, United Kingdom",
  "Brandywine, United States",
  "Branford, United States",
  "Braunton, United Kingdom",
  "Bray sur Seine, France",
  "Brazi, Romania",
  "Brazil, United States",
  "Brazoria, United States",
  "Breal sous Montfort, France",
  "Breaux Bridge, United States",
  "Brebieres, France",
  "Brebu, Romania",
  "Brechin, Canada",
  "Brecksville, United States",
  "Breitenfurt bei Wien, Austria",
  "Bremanger, Norway",
  "Brenham, United States",
  "Brentwood, United States",
  "Bresles, France",
  "Brevik, Norway",
  "Brewer, United States",
  "Brick, United States",
  "Bridgeport, United States",
  "Bridgeton, United States",
  "Bridgetown, Barbados",
  "Bridgewater, Canada",
  "Brie Comte Robert, France",
  "Brier Hill, United States",
  "Brierfield, United Kingdom",
  "Brighouse Dumfries - Galloway, United Kingdom",
  "Brikama, Gambia",
  "Brill, United Kingdom",
  "Brimscombe Gloucestershire, United Kingdom",
  "Brinkley, United States",
  "Brinkworth Wiltshire, United Kingdom",
  "Brion Maine et Loire, France",
  "Brionne, France",
  "Brits, South Africa",
  "Brive la Gaillarde, France",
  "Brixham, United Kingdom",
  "Brixlegg, Austria",
  "Brnenec, Czech Republic",
  "Broadview, United States",
  "Brockham, United Kingdom",
  "Brockton, United States",
  "Brodce, Czech Republic",
  "Brokopondo, Suriname",
  "Bromma, Sweden",
  "Brompton on Swale, United Kingdom",
  "Bronkhorstspruit, South Africa",
  "Brookfield, United States",
  "Brooklyn Heights, United States",
  "BROOKLYN PARK, United States",
  "Brookmans Park, United Kingdom",
  "Brooks, United States",
  "Brookshire, United States",
  "Brooksville, United States",
  "Brookwood, United States",
  "Broomall, United States",
  "Brossard, Canada",
  "Brough Cumbria, United Kingdom",
  "Broughton Hampshire, United Kingdom",
  "Brovary, Ukraine",
  "Brownfield, United States",
  "Browns Summit, United States",
  "Brownsburg, United States",
  "Brownwood, United States",
  "Brucargo, Belgium",
  "Bruegg, Switzerland",
  "Brufut, Gambia",
  "Bruino, Italy",
  "Brumath, France",
  "Brumunddal, Norway",
  "Brundidge, United States",
  "Brunete, Spain",
  "Brwinow, Poland",
  "Bryant, United States",
  "Brzesc Kujawski, Poland",
  "Brzeziny, Poland",
  "Brzeznica, Poland",
  "Buan, Korea, South",
  "Buchanan, United States",
  "Buchen (Odenwald), Germany",
  "Bucheres, France",
  "Bucyrus, United States",
  "Budel, Netherlands",
  "Budimir, Slovakia",
  "Budmerice, Slovakia",
  "Budyne nad Ohri, Czech Republic",
  "Buelach, Switzerland",
  "Buena Park, United States",
  "Buenaventura, Mexico",
  "Bueu, Spain",
  "Bufali, Spain",
  "Buffalo Grove, United States",
  "Buhl Haut Rhin, France",
  "Builth Wells, United Kingdom",
  "Bujumbura, Burundi",
  "Bukavu, Congo, Dem. Rep. of",
  "Bulawayo, Zimbabwe",
  "Bullas, Spain",
  "Bunde (Ostfries), Germany",
  "Burbach (Eifel), Germany",
  "Burbank, United States",
  "Bures, United Kingdom",
  "Burgettstown, United States",
  "Burguillos del Cerro, Spain",
  "Burks Falls, Canada",
  "Burladingen, Germany",
  "Burnet, United States",
  "Burnham, United States",
  "Burnham On Sea Somerset, United Kingdom",
  "Burns Harbour, United States",
  "Burnsville, United States",
  "Burston Norfolk, United Kingdom",
  "Burtonsville, United States",
  "Bushenyi, Uganda",
  "Bushey, United Kingdom",
  "Busko Zdroj, Poland",
  "Bussac Foret, France",
  "Bussigny, Switzerland",
  "Bussy Lettree, France",
  "BUSSY-SAINT-MARTIN, France",
  "Butgenbach, Belgium",
  "Butler, United States",
  "Buttonwillow, United States",
  "Byfield Northamptonshire, United Kingdom",
  "Byron Center, United States",
  "Bystrice, Czech Republic",
  "Bystrice Nad Olsi, Czech Republic",
  "Baalsta, Sweden",
  "Baar-Ebenhausen, Germany",
  "Baarle Nassau, Netherlands",
  "Cabreton, Spain",
  "Cacak, Serbia",
  "Cactus, United States",
  "Cadeby Doncaster, United Kingdom",
  "Cadeby Leicestershire, United Kingdom",
  "Cadereyta, Mexico",
  "Cadiz, United States",
  "Caernarfon, United Kingdom",
  "Caerwys Flintshire, United Kingdom",
  "Cagliari, Italy",
  "Caguas, Puerto Rico",
  "Cahors, France",
  "Cai Lan, Vietnam",
  "Calafell, Spain",
  "Calamba, Philippines",
  "Calarasi, Romania",
  "Calatabiano, Italy",
  "Calden, Germany",
  "Caledon, Canada",
  "Calera, United States",
  "Callus, Spain",
  "Calvatone, Italy",
  "Calvenzano, Italy",
  "Calvert, United States",
  "Calverton, United States",
  "Calvi, Italy",
  "Calvillo, Mexico",
  "Camanche, United States",
  "Camargo, Mexico",
  "Camas, United States",
  "Cambuslang, United Kingdom",
  "Camden, United States",
  "Cameron, United States",
  "Camp Buehring, Kuwait",
  "Camp Fuji, Japan",
  "Camp Hill, United States",
  "Camp Lejeune, United States",
  "Campamento, Spain",
  "Campbell, United States",
  "Campbell River, Canada",
  "Campbellsville, United States",
  "Campden Park, Saint Vincent and the Grenadines",
  "Campeche, Mexico",
  "Campello, Spain",
  "Campllong, Spain",
  "Campo de Criptana, Spain",
  "Campotejar, Spain",
  "Can Tho, Vietnam",
  "Canada Hermosa, Spain",
  "Canary Wharf, United Kingdom",
  "Canby, United States",
  "Cancun, Mexico",
  "CANDELARIA, Spain",
  "Candiac, Canada",
  "Candler, United States",
  "Candor, United States",
  "Canejean, France",
  "Canelands, South Africa",
  "Canet de Mar, Spain",
  "Canet den Berenguer, Spain",
  "Cannes, France",
  "Cannington, Canada",
  "Cannon Falls, United States",
  "CANTALPINO, Spain",
  "Cantanhede, Portugal",
  "Cantonment, United States",
  "CANYON, United States",
  "Canyon Lake, United States",
  "Cap Haitien, Haiti",
  "Capac, United States",
  "Cape Coral, United States",
  "Cape Girardeau, United States",
  "Cape May, United States",
  "Capellen, Luxemburg",
  "Capira, Panama",
  "Capitol Heights, United States",
  "Cappella Cantone, Italy",
  "Capralba, Italy",
  "Capriano del Briosco, Italy",
  "Caracal, Romania",
  "Caracas, Venezuela",
  "Carazo, Nicaragua",
  "Carboneras Almeria, Spain",
  "Carcare, Italy",
  "Carcastillo, Spain",
  "Carchuna, Spain",
  "Cardenadijo, Spain",
  "Cardenas, Mexico",
  "Cardet, France",
  "Cardington Bedfordshire, United Kingdom",
  "Cardona, Spain",
  "Carey, United States",
  "Carhaix Plouguer, France",
  "Carlsbad, United States",
  "Carolina, Puerto Rico",
  "Carp, Canada",
  "Carpentersville, United States",
  "Carpinteria, United States",
  "Carpiquet, France",
  "Carqueiranne, France",
  "Carrieres sur Seine, France",
  "Carrizal Las Palmas, Spain",
  "Carson City, United States",
  "Cartaxo, Portugal",
  "Carthage, United States",
  "Cary, United States",
  "Casabermeja, Spain",
  "Casarano, Italy",
  "Casares, Spain",
  "Casariche, Spain",
  "Casas de Benitez, Spain",
  "Cascine Vica, Italy",
  "Casoria, Italy",
  "Cassano Spinola, Italy",
  "Castaic, United States",
  "Casteljaloux, France",
  "Castell'Alfero, Italy",
  "Castellar Frontera, Spain",
  "Castelldefels, Spain",
  "Castello D'empuries, Spain",
  "Castellvi de la Marca, Spain",
  "Castelsarrasin, France",
  "Castets, France",
  "Castle Bromwich, United Kingdom",
  "Castle Douglas, United Kingdom",
  "Castle Hayne, United States",
  "Castle Rock, United States",
  "Castlereagh Northern Ireland, United Kingdom",
  "Castletown Dorset, United Kingdom",
  "Castries, St Lucia",
  "Catawba, United States",
  "Cavaillon, France",
  "Cave City, United States",
  "Cavertitz, Germany",
  "Cayce, United States",
  "Cayuga, Canada",
  "Cazalegas, Spain",
  "Cazenga, Angola",
  "Cazenovia, United States",
  "CCLP Dadri, India",
  "Cedar Hill, United States",
  "Cedar Springs, United States",
  "Cedartown, United States",
  "Celadas, Spain",
  "Celina, United States",
  "Centennial, United States",
  "Center Line, United States",
  "Center Moriches, United States",
  "Center Valley, United States",
  "Centerbrook, United States",
  "Centerville, United States",
  "Central Falls, United States",
  "Central Valley, United States",
  "Ceptura, Romania",
  "Cercado de Lima, Peru",
  "Cercany, Czech Republic",
  "Cerete, Italy",
  "Cernosice, Czech Republic",
  "Cernozice, Czech Republic",
  "Cerritos, United States",
  "Cervere, Italy",
  "Cervinara, Italy",
  "Cesis, Latvia",
  "Ceska Kamenice, Czech Republic",
  "Ceske Mezirici, Czech Republic",
  "Cesky Rudolec, Czech Republic",
  "Cestin, Czech Republic",
  "Chaddesley Corbett, United Kingdom",
  "Chadwell Heath London, United Kingdom",
  "Chalandri, Greece",
  "Chalatenango, El Salvador",
  "Chalfont, United States",
  "Challans, France",
  "Chalonnes sur Loire, France",
  "Chambishi, Zambia",
  "Chamblee, United States",
  "Chambly, Canada",
  "CHAMBOIS, France",
  "Chamoson, Switzerland",
  "Champaign, United States",
  "Champlin, United States",
  "Chapel en le Frith, United Kingdom",
  "Chapelle-Viviers, France",
  "Chapin, United States",
  "Charbonnage, Gabon",
  "Charing, United Kingdom",
  "CharlecoteWarwickshire, United Kingdom",
  "Charlestown, St Kitts-Nevis",
  "Charlestown, United States",
  "Charlestown Mayo, Ireland",
  "Charleville Mezieres, France",
  "Charlotte Amalie, Virgin Islands (US)",
  "Charlottenlund, Denmark",
  "Charlottetown, Canada",
  "Charlton Wiltshire, United Kingdom",
  "Charlwood, United Kingdom",
  "Charmes, France",
  "Charmes sur Rhone, France",
  "Charny, Canada",
  "Chateau du Loir, France",
  "Chateau Gaillard, France",
  "Chateau Thierry, France",
  "Chateauguay, Canada",
  "Chateauneuf de Gadagne, France",
  "Chatham, Canada",
  "CHAU THANH, Vietnam",
  "Chaulnes, France",
  "Chaumont, France",
  "Chaumont en Vexin, France",
  "Chavornay, Switzerland",
  "Cheektowaga, United States",
  "Chekhov, Russia",
  "Chelan, United States",
  "Chella, Spain",
  "Chelmiec, Poland",
  "Chelmsford, United States",
  "Chelsea, United States",
  "Chelyabinsk Zelabinsk, Russia",
  "Cheney, United States",
  "Cheonan, Korea, South",
  "Cheongju, Korea, South",
  "Cheraw, United States",
  "Cherisy, France",
  "Chernihiv, Ukraine",
  "Cherry Hill, United States",
  "Cherryville, United States",
  "Chesapeake City, United States",
  "Cheshunt, United Kingdom",
  "Chesnee, United States",
  "Chester, Canada",
  "Chesterton, United States",
  "Chestertown, United States",
  "Cheswick, United States",
  "Chevy Chase, United States",
  "Chiajna, Romania",
  "Chiavenna, Italy",
  "Chiba, Japan",
  "Chickamauga, United States",
  "Chico, United States",
  "Chicoutimi, Canada",
  "Chierry, France",
  "Chievres, Belgium",
  "Childersburg, United States",
  "Chilgok, Korea, South",
  "Chilhowie, United States",
  "Chilibre-Panama, Panama",
  "Chiliomodi, Greece",
  "Chillicothe, United States",
  "Chilliwack, Canada",
  "China Lake, United States",
  "Chingola, Zambia",
  "Chipilo, Mexico",
  "Chippewa Falls, United States",
  "Chipping Ongar, United Kingdom",
  "Chirpan, Bulgaria",
  "Chisamba, Zambia",
  "Chisinau, Moldova",
  "Chistlehurst / Kent, United Kingdom",
  "Chitterne Wiltshire, United Kingdom",
  "Chloorkop, South Africa",
  "Choroszcz, Poland",
  "Chortiatis, Greece",
  "Christiansburg, United States",
  "Christiansted, Virgin Islands (US)",
  "Chtelnica, Slovakia",
  "Chtouka Ait Baha, Morocco",
  "Chulmleigh Devon EX, United Kingdom",
  "Churcham, United Kingdom",
  "Churchill Oxfordshire, United Kingdom",
  "Chuzhou, China",
  "Chybie, Poland",
  "Chyne, Czech Republic",
  "Chynow, Poland",
  "Ciampino, Italy",
  "Ciasna, Poland",
  "Ciboure, France",
  "Cicero, United States",
  "Cidra, Puerto Rico",
  "Ciervana, Spain",
  "Cifer, Slovakia",
  "Citrusdal, South Africa",
  "City of Commerce, United States",
  "Ciudad Quesada, Costa Rica",
  "Ciudad Vieja, Guatemala",
  "Clanton, United States",
  "Clara Offaly, Ireland",
  "Claremont, United States",
  "Clarksdale, United States",
  "Clarkston, United States",
  "Clarksville, United States",
  "Claughton Lancashire, United Kingdom",
  "Claygate Surrey, United Kingdom",
  "Claypool, United States",
  "Claysburg, United States",
  "Clayville, South Africa",
  "Clearfield, United States",
  "Clerval, France",
  "Cleves, United States",
  "Cliffe Medway, United Kingdom",
  "Clifton, New Zealand",
  "Closter, United States",
  "Clovis, United States",
  "Cluses, France",
  "Coalburn, United Kingdom",
  "Coalisland Northern Ireland, United Kingdom",
  "Coana, Spain",
  "Coatesville, United States",
  "Coatzacoalcos, Mexico",
  "Cobleskill, United States",
  "Cochabamba, Bolivia",
  "Cochem, Germany",
  "Cochrane, Canada",
  "Cocoa, United States",
  "Coden, United States",
  "Codlea, Romania",
  "Coetmieux, France",
  "Cofradia, Honduras",
  "Cohoes, United States",
  "Coimbatore, India",
  "Colborne, Canada",
  "Colchester, United States",
  "Coldwater, United States",
  "Colfax, United States",
  "College Station, United States",
  "Collegedale, United States",
  "Collingdale, United States",
  "Collingwood, Canada",
  "Colmar, United States",
  "Colmar Berg, Luxemburg",
  "Colmenar de Oreja, Spain",
  "Colmenar Malaga, Spain",
  "Colne Cambs, United Kingdom",
  "Colomera, Spain",
  "Colon, Panama",
  "Colonial Beach, United States",
  "Colorado Springs, United States",
  "Colt, United States",
  "Colton, United States",
  "Columbiana, United States",
  "Columbus Grove, United States",
  "Colwich Staffordshire, United Kingdom",
  "Comanche, United States",
  "Comas, Peru",
  "Comayagua, Honduras",
  "Como, United States",
  "Concord, United States",
  "Conestoga, United States",
  "Conflans en jarnisy, France",
  "Congella, South Africa",
  "Congrier, France",
  "Conklin, United States",
  "Conna Cork, Ireland",
  "Connahs Quay, United Kingdom",
  "Connellsville, United States",
  "Constancia, Portugal",
  "Contermanskloof, South Africa",
  "Convent, United States",
  "Cookley Worcs, United Kingdom",
  "Coopersburg, United States",
  "Copake, United States",
  "Copan, Honduras",
  "Copley, United States",
  "Coquitlam, Canada",
  "Corbin, United States",
  "Corcelles Pres Payerne, Switzerland",
  "Cordele, United States",
  "Corfu Kerkira, Greece",
  "Coria del Rio, Spain",
  "Cornelia, United States",
  "Cornelius, United States",
  "Corning, United States",
  "Cornwall, Canada",
  "Cornwell Oxfordshire, United Kingdom",
  "Corozal, Panama",
  "Corry, United States",
  "Cortemilia, Italy",
  "Cortes Frontera, Spain",
  "Cortland, United States",
  "Corvallis, United States",
  "Corydon, United States",
  "Coryton Devon, United Kingdom",
  "Coshocton, United States",
  "Cosse le Vivien, France",
  "Costa Mesa, United States",
  "Cotia, Brazil",
  "Cottage Grove, United States",
  "Cottam, Canada",
  "CottamNottingshire, United Kingdom",
  "Cottondale, United States",
  "Courtenay, Canada",
  "Courtland, Canada",
  "Couthures sur Garonne, France",
  "Coutras, France",
  "Cowansville, Canada",
  "Crail, United Kingdom",
  "Cranfield, United Kingdom",
  "Cranford, United States",
  "Cranmore Somerset BA, United Kingdom",
  "Cranston, United States",
  "Crawfordville, United States",
  "Cream Ridge, United States",
  "Creney Pres Troyes, France",
  "Cresco, United States",
  "Crespin, France",
  "Cressing, United Kingdom",
  "Crestwood, United States",
  "Creswell, United States",
  "Crewkerne, United Kingdom",
  "Crisfield, United States",
  "Crissier, Switzerland",
  "Cristobal, Panama",
  "Cristuru Secuiesc, Romania",
  "Crivitz, United States",
  "Crna na Koroskem, Slovenia",
  "Cromarty, United Kingdom",
  "Cromwell, United States",
  "Cross Hands, United Kingdom",
  "Crossett, United States",
  "Crossford South Lanarkshire, United Kingdom",
  "CrosskeysWales, United Kingdom",
  "Crown Mines, South Africa",
  "Croy Lanarkshire, United Kingdom",
  "Croydon, United States",
  "Crumlin Gwent, United Kingdom",
  "Crystal Lake, United States",
  "Crystal Springs, United States",
  "Csavoly, Hungary",
  "Cuauhtemoc, Mexico",
  "Cuautla, Mexico",
  "Cuautlancingo, Mexico",
  "Cuddebackville, United States",
  "Cuenca - Azuay, Ecuador",
  "Cuevas Bajas, Spain",
  "Cuevas De S Marcos Malaga, Spain",
  "Cullman, United States",
  "Culpeper, United States",
  "Culver City, United States",
  "Curdridge Hampshire, United Kingdom",
  "Curtatone, Italy",
  "Curtici, Romania",
  "Curtis Bay, United States",
  "Curwensville, United States",
  "Cuveglio, Italy",
  "Cuyahoga Heights, United States",
  "Cuyoaco, Mexico",
  "CYANGUGU, Rwanda",
  "Cypress, United States",
  "Czarkow, Poland",
  "Czernichow, Poland",
  "Czyzowice, Poland",
  "Da Chan Bay, China",
  "Dabou, Ivory Coast",
  "Dabrowa, Poland",
  "Dabrowa Bialostocka, Poland",
  "Dacula, United States",
  "Dade City, United States",
  "Daejeon, Korea, South",
  "Daellikon, Switzerland",
  "Daeniken, Switzerland",
  "Daingerfield, United States",
  "Dal, Norway",
  "Dalby, Sweden",
  "Dalecin, Czech Republic",
  "Dalgety Bay, United Kingdom",
  "Dalhart, United States",
  "Dalias, Spain",
  "Dalmuir West Dunbartonshire, United Kingdom",
  "Dalovice, Czech Republic",
  "Dalry, United Kingdom",
  "Dalrymple E Ayrshire, United Kingdom",
  "Damascus, United States",
  "Dammam Free Zone Bonded Area, Saudi Arabia",
  "Danbury, United Kingdom",
  "Danbury, United States",
  "Dandenong, Australia",
  "Danderyd, Sweden",
  "Dandong, China",
  "Dania Beach, United States",
  "Danielson, United States",
  "Danilovgrad, Montenegro",
  "Dappar, India",
  "Dar Naim, Mauritania",
  "Darley North Yorkshire, United Kingdom",
  "Darlington, United States",
  "Darvel, United Kingdom",
  "Darwendale, Zimbabwe",
  "Darzo, Italy",
  "Dasice, Czech Republic",
  "Dasing, Germany",
  "Daugavpils, Latvia",
  "Daugmale, Latvia",
  "Daule, Ecuador",
  "Davison, United States",
  "Dawson, United States",
  "Daytona Beach, United States",
  "De Leon, United States",
  "De Pere, United States",
  "De Queen, United States",
  "De Wildt Pry, South Africa",
  "De Witt, United States",
  "Debar, Macedonia",
  "Debary, United States",
  "Deeping St James, United Kingdom",
  "Deerfield, United States",
  "Deerfield Beach, United States",
  "Delabole Cornwall, United Kingdom",
  "Deland, United States",
  "Delano, United States",
  "Delaware, United States",
  "Delhi, Canada",
  "Delhi-West, India",
  "Dell, United States",
  "Delph, United Kingdom",
  "Delran, United States",
  "Delray Beach, United States",
  "Delson, Canada",
  "Delta, United States",
  "Demstrup, Denmark",
  "Den Dolder, Netherlands",
  "Den Hoorn, Netherlands",
  "Den Haag, Netherlands",
  "Denby, United Kingdom",
  "DENDERHOUTEM, Belgium",
  "Denia, Spain",
  "Denison, United States",
  "Denton N Yorks, United Kingdom",
  "Denver, South Africa",
  "Deols, France",
  "Deptford London, United Kingdom",
  "Derby, United States",
  "Derendingen, Switzerland",
  "Dermott, United States",
  "Dernbach (bei Dierdorf), Germany",
  "Derry, United States",
  "Derrybeg Donegal, Ireland",
  "Derrylin Northern Ireland, United Kingdom",
  "Derventa, Bosnia and Herzegovina",
  "Desoto, United States",
  "Dettenhausen, Germany",
  "Deutschlandsberg, Austria",
  "Deva, Romania",
  "Devens, United States",
  "Deveselu, Romania",
  "Dexter, United States",
  "Dguech, Tunisia",
  "Dhahran, Saudi Arabia",
  "Dhali, Cyprus",
  "Diamniadio, Senegal",
  "Diamond Springs, United States",
  "Diana, United States",
  "Dickinson, United States",
  "Die Wilgers, South Africa",
  "Diecimo, Italy",
  "Diegem, Belgium",
  "Dielsdorf, Switzerland",
  "Diepenheim, Netherlands",
  "Dierks, United States",
  "Dietfurt, Germany",
  "Dietikon, Switzerland",
  "Digby, United Kingdom",
  "DILOVASI, Turkey",
  "Dimitrovgrad, Bulgaria",
  "Dingden (Hamminkeln), Germany",
  "Diriamba, Nicaragua",
  "Dirlewang, Germany",
  "Distington, United Kingdom",
  "Ditton Cheshire, United Kingdom",
  "Dixinn, Guinea",
  "Djebel Oust, Tunisia",
  "Djibouti Free Zone, Djibouti",
  "Dneprorudnoye, Ukraine",
  "Dobbins AFB, United States",
  "Doboj, Bosnia and Herzegovina",
  "Dobrichovice, Czech Republic",
  "Dobrin, Czech Republic",
  "Dobrodzien, Poland",
  "Dodge Center, United States",
  "Dodgeville, United States",
  "Dodro, Spain",
  "Doebeln, Germany",
  "Dol de Bretagne, France",
  "Dole, France",
  "Dolgarrog, United Kingdom",
  "Dolgellau, United Kingdom",
  "Dolisie, Congo",
  "Dollard-Des-Ormeaux, Canada",
  "Dolne Zelenice, Slovakia",
  "Dolni Berkovice, Czech Republic",
  "Dolni Ujezd (U Prerova), Czech Republic",
  "Dolny Stal, Slovakia",
  "Dolores Hidalgo, Mexico",
  "Dolzago, Italy",
  "Domaio, Spain",
  "Domasov, Czech Republic",
  "Dominguez, United States",
  "Domont, France",
  "Dondo, Angola",
  "Donna, United States",
  "Donora, United States",
  "Doornfontein, South Africa",
  "Dorchester, Canada",
  "Dorog, Hungary",
  "Doswell, United States",
  "DOTTENIJS, Belgium",
  "Doue la Fontaine, France",
  "Douglas Lanarkshire, United Kingdom",
  "Douglasville, United States",
  "Douvrin, France",
  "Doveridge Derbyshire, United Kingdom",
  "Downers Grove, United States",
  "Downingtown, United States",
  "Downsview, Canada",
  "Doylestown, United States",
  "Dracut, United States",
  "Dragasani, Romania",
  "Dragomiresti Vale, Romania",
  "Dragomiresti-Deal, Romania",
  "Draper, United States",
  "Drawsko, Poland",
  "Dresden, Canada",
  "Dresden, United States",
  "Drevenice, Czech Republic",
  "Drietoma, Slovakia",
  "Drisy, Czech Republic",
  "Drnovice, Czech Republic",
  "Drochtersen, Germany",
  "Drogenbos, Belgium",
  "Drumshambo Leitrim, Ireland",
  "Drumshanbo, Ireland",
  "Drunen, Netherlands",
  "Dry Branch, United States",
  "Dubna, Russia",
  "Dubuque, United States",
  "Dudarkov, Ukraine",
  "Dugopolje, Croatia",
  "Duiven, Netherlands",
  "Dunabogdany, Hungary",
  "Dunaujvaros, Hungary",
  "Duncanville, United States",
  "Dundas, Canada",
  "DUNDEE, United States",
  "Dundonald Northern Ireland, United Kingdom",
  "Dundonald South Ayrshire, United Kingdom",
  "Dungarvan Waterford, Ireland",
  "Dunkeld, United Kingdom",
  "Dunlap, United States",
  "Dunleer Louth, Ireland",
  "Dunmurry Northern Ireland, United Kingdom",
  "Dunn, United States",
  "Dunnville, Canada",
  "Dunston Tyne and Wear, United Kingdom",
  "Dunswart, South Africa",
  "Duqm, Oman",
  "Durant, United States",
  "Durtal, France",
  "Duryea, United States",
  "Dvory nad Zitavou, Slovakia",
  "Dyersburg, United States",
  "Dziekanow Lesny, Poland",
  "Eagan, United States",
  "Earlswood Surrey, United Kingdom",
  "Early Branch, United States",
  "Easington Yorkshire, United Kingdom",
  "Easley, United States",
  "East Angus, Canada",
  "East Boldon, United Kingdom",
  "East Boston, United States",
  "East Bridgewater, United States",
  "East Greenwich, United States",
  "East Hanover, United States",
  "East Hartford, United States",
  "East Haven, United States",
  "East Hyde Bedfordshire, United Kingdom",
  "East Liverpool, United States",
  "East Newark, United States",
  "East Pittsburg, United States",
  "East Rutherford, United States",
  "East Syracuse, United States",
  "East Tamaki, New Zealand",
  "East Taunton, United States",
  "East Tawas, United States",
  "Eastanollee, United States",
  "Eastern Creek, Australia",
  "Eastfield Yorks YO, United Kingdom",
  "Eastham, United Kingdom",
  "Easton Dorset, United Kingdom",
  "Easton Lincolnshire NG, United Kingdom",
  "Eastwood Essex, United Kingdom",
  "Eatonton, United States",
  "Eatontown, United States",
  "Eaubonne, France",
  "Eberndorf, Austria",
  "Ebreichsdorf, Austria",
  "Ecclesfield, United Kingdom",
  "Echenoz la meline, France",
  "Eck en Wiel, Netherlands",
  "Ecorse, United States",
  "Ecser, Hungary",
  "Edeleny, Hungary",
  "Edenfield, United Kingdom",
  "EDENGLEN, South Africa",
  "Edenton, United States",
  "Edenvale, South Africa",
  "Edgewater, United States",
  "Edgewater Park, United States",
  "Edgewood, United States",
  "Edinburg, United States",
  "Edinburgh, United States",
  "Edwardsville, United States",
  "Eerbeek, Netherlands",
  "Eersel, Netherlands",
  "Effingham, United States",
  "Efkarpia, Greece",
  "Egg Harbor Township, United States",
  "Eggborough, United Kingdom",
  "Eglin AFB, United States",
  "Egly, France",
  "Egtved, Denmark",
  "Eichendorf, Germany",
  "Eide, Norway",
  "Einsiedlerhof (Kaiserslautern), Germany",
  "Eisenstadt, Austria",
  "Eisingen (Baden), Germany",
  "Ekeren, Belgium",
  "El Berron, Spain",
  "El Campillo, Spain",
  "El Carpio, Spain",
  "El Chorrillo, Spain",
  "El Morche Malaga, Spain",
  "El Puerto, Spain",
  "El Reno, United States",
  "El Romani, Spain",
  "El Rosario, Spain",
  "El Segundo, United States",
  "El Tarajal, Spain",
  "El Tejar, Guatemala",
  "El Villar de Arnedo, Spain",
  "ELATIA, Greece",
  "Elbasan, Albania",
  "Elberta, United States",
  "Elberton, United States",
  "ELCARTE, Spain",
  "Elixhausen, Austria",
  "Elizabeth City, United States",
  "ELIZABETHPORT, United States",
  "Elk Grove, United States",
  "Elkhorn, United States",
  "Elkwood, United States",
  "Ellaville, United States",
  "Ellenboro, United States",
  "Ellenville, United States",
  "Ellenwood, United States",
  "Ellerbe, United States",
  "Ellerbek (Kr Pinneberg), Germany",
  "Elmsford, United States",
  "Elmwood, United States",
  "Eloy, United States",
  "Els Hostalets de Balenya, Spain",
  "Elterlein, Germany",
  "Elva, Estonia",
  "Elverson, United States",
  "Elverum, Norway",
  "Elyria, United States",
  "Elze, Germany",
  "Emborough, United Kingdom",
  "Embrach-Embraport, Switzerland",
  "Emersacker, Germany",
  "Emmenbruecke, Switzerland",
  "Empire, United States",
  "ENGAN, Norway",
  "Engesvang, Denmark",
  "Engis, Belgium",
  "Englewood, United States",
  "Enhoerna, Sweden",
  "ENIWA, Japan",
  "Enka, United States",
  "Enniskean Co Cork, Ireland",
  "Ensdorf (Saar), Germany",
  "Entraigues, France",
  "Entraigues sur Sorgues, France",
  "Enzersdorf an der Fischa, Austria",
  "Epanomi, Greece",
  "Epaux Bezu, France",
  "Epila, Spain",
  "Epone, France",
  "Eragny, France",
  "Erd, Hungary",
  "Ergolding, Germany",
  "Ermesinde, Portugal",
  "Ernee, France",
  "Errezil, Spain",
  "Erwin, United States",
  "Eschach, Germany",
  "Eschlikon, Switzerland",
  "ESCLANYA, Spain",
  "Escobedo, Mexico",
  "Esparragal, Spain",
  "Espergaerde, Denmark",
  "EssendineLincs, United Kingdom",
  "Essington, United States",
  "Estenfeld, Germany",
  "Eston Redcar and Cleveland TS, United Kingdom",
  "Etiler, Turkey",
  "Eton, United States",
  "Eton Windsor, United Kingdom",
  "Etrelles, France",
  "Etupes, France",
  "Eudora, United States",
  "Eurre, France",
  "Eustis, United States",
  "Evendale, United States",
  "Everglades City, United States",
  "Everingham East Riding, United Kingdom",
  "Everson, United States",
  "Evian les Bains, France",
  "Ewing, United States",
  "Exeter, Canada",
  "Exincourt, France",
  "Exmore, United States",
  "Exning, United Kingdom",
  "Eyemouth, United Kingdom",
  "Eyrein, France",
  "Ezulwini, Swaziland",
  "Faerjestaden, Sweden",
  "Fagaras, Romania",
  "Fagersta, Sweden",
  "Fair Lawn, United States",
  "Fair Oaks, United States",
  "Fairbanks, United States",
  "Fairborn, United States",
  "Fairdale, United States",
  "Fairford, United Kingdom",
  "Fairless Hills, United States",
  "Fairmont, United States",
  "Fairmount, United States",
  "Fairview, United States",
  "Faison, United States",
  "Fallbrook, United States",
  "Fallon, United States",
  "Falmouth, United Kingdom",
  "Falun, Sweden",
  "Fanipol, Belarus",
  "Faribault, United States",
  "Faringdon, United Kingdom",
  "Farlington Hants, United Kingdom",
  "Farlington York, United Kingdom",
  "Farmington Hills, United States",
  "Farmville, United States",
  "Farnham, Canada",
  "Farnham Royal, United Kingdom",
  "Farringdon Devon, United Kingdom",
  "Fauldhouse West Lothian, United Kingdom",
  "Fawdon Newcastle upon Tyne, United Kingdom",
  "Fay, United States",
  "Federalsburg, United States",
  "Feldkirchen (KrMuenchen), Germany",
  "Felinfach, United Kingdom",
  "Felixton, South Africa",
  "Feltwell, United Kingdom",
  "Fenny Compton, United Kingdom",
  "Fenton, United States",
  "Fergus, Canada",
  "Fernandina Beach, United States",
  "Fetsund, Norway",
  "Fforest-fach Swansea, United Kingdom",
  "Ficarazzi, Italy",
  "Fife, United States",
  "Fillmore, United States",
  "Finestrat, Spain",
  "Finnsnes, Norway",
  "Finsterwalde, Germany",
  "Fischach (Schwab), Germany",
  "Fishers, United States",
  "Fishkill, United States",
  "Fiskars, Finland",
  "Fiumefreddo di Sicilia, Italy",
  "Fjaelkinge, Sweden",
  "Flamborough, Canada",
  "Flat Rock, United States",
  "Flatraket, Norway",
  "Flaucourt, France",
  "Fleetwood, United States",
  "FLEMING ISLAND, United States",
  "Flemington, United States",
  "Flensburg, Germany",
  "Flint, United States",
  "Flintstone, United States",
  "Flisby, Sweden",
  "Floresti, Romania",
  "Florham Park, United States",
  "Florida, United States",
  "Florida City, United States",
  "Florina, Greece",
  "Flowery Branch, United States",
  "Flowood, United States",
  "Foerde, Norway",
  "Fogelsville, United States",
  "Folgosa, Portugal",
  "Folgueroles, Spain",
  "Follese, Norway",
  "Folsom, United States",
  "Fonelas, Spain",
  "Fontaine Les Dijon, France",
  "Fontellas, Spain",
  "Foothill Ranch, United States",
  "Fordsburg, South Africa",
  "Forest City, United States",
  "Fornells de la Selva, Spain",
  "Forresfjorden, Norway",
  "Forsyth, United States",
  "Fort Belvoir, United States",
  "FORT CAMPBELL, United States",
  "Fort Dix, United States",
  "Fort Drum, United States",
  "Fort Edward, United States",
  "Fort Eustis, United States",
  "Fort Hood, United States",
  "Fort Huachuca, United States",
  "Fort Jackson, South Africa",
  "Fort Knox, United States",
  "Fort Langley, Canada",
  "Fort Lawn, United States",
  "Fort Lee, United States",
  "Fort Leonard Wood, United States",
  "Fort McCoy, United States",
  "Fort Oglethorpe, United States",
  "Fort Payne, United States",
  "Fort Pierce, United States",
  "Fort Recovery, United States",
  "Fort Stewart, United States",
  "Fort Valley, United States",
  "Fort Walton Beach, United States",
  "Fort William, United Kingdom",
  "Fosse Loir et Cher, France",
  "Fossoli di Carpi, Italy",
  "Fostoria, United States",
  "Fotherby, United Kingdom",
  "Four Ways, South Africa",
  "Fourstones Northumberland, United Kingdom",
  "Fowler, United States",
  "Fox Lake, United States",
  "Foynes Limerick, Ireland",
  "Fraijanes, Guatemala",
  "Fraisses, France",
  "Frampton on Severn, United Kingdom",
  "Francin, France",
  "Frankenmuth, United States",
  "Franklin Furnace, United States",
  "Franksville, United States",
  "Frauenfeld, Switzerland",
  "Frauenkirchen, Austria",
  "Fredericton, Canada",
  "Frederikssund, Denmark",
  "Fredonia, United States",
  "Frenkendorf, Switzerland",
  "Fridley, United States",
  "Frielendorf, Germany",
  "Friendship, United States",
  "Frigiliana, Spain",
  "Fritzlar, Germany",
  "Froestrup, Denmark",
  "Froideconche, France",
  "Froncles, France",
  "Frontera, Mexico",
  "Frostburg, United States",
  "Fruges, France",
  "Fryanovo, Russia",
  "Fryazino, Russia",
  "Frydlant v Cechach, Czech Republic",
  "Fryeburg, United States",
  "Fuencubierta, Spain",
  "Fuentes Nuevas, Spain",
  "Fuernitz, Austria",
  "Fuessen, Germany",
  "Fuldabrueck, Germany",
  "Fuldatal, Germany",
  "Funabashi Chiba, Japan",
  "Fundao, Portugal",
  "Fuqing, China",
  "Furnari, Italy",
  "Furtwangen im Schwarzwald, Germany",
  "Fyfield Essex, United Kingdom",
  "Fyllingsdalen, Norway",
  "Gabersdorf, Austria",
  "Gadesco, Italy",
  "Gadstrup, Denmark",
  "Gaeta, Italy",
  "Gaeufelden, Germany",
  "Gaffney, United States",
  "Gaglianico, Italy",
  "Gahanna, United States",
  "Gaimersheim, Germany",
  "Gainesboro, United States",
  "Gaithersburg, United States",
  "Galatas, Greece",
  "Galax, United States",
  "Galena Park, United States",
  "Galesburg, United States",
  "Gallipolis, United States",
  "Gallneukirchen, Austria",
  "Galt, United States",
  "Galzignano Terme, Italy",
  "Gambara, Italy",
  "Gamboa, Panama",
  "Gambrills, United States",
  "Gandra, Portugal",
  "Ganja, Azerbaijan",
  "Gansevoort, United States",
  "Ganzhou, China",
  "Gao, Mali",
  "Garancieres en Beauce, France",
  "Garankuwa, South Africa",
  "Gardner, United States",
  "Garelochhead, United Kingdom",
  "Garfield, United States",
  "Gargenville, France",
  "Garkalne, Latvia",
  "Garmisch-Partenkirchen, Germany",
  "Gary, United States",
  "Garyville, United States",
  "Gaspe, Canada",
  "Gaston, United States",
  "Gateside Fife, United Kingdom",
  "Gatesville, United States",
  "Gatica, Spain",
  "Gatley Cheshire, United Kingdom",
  "Gattendorf, Germany",
  "Gaurain-Ramecroix, Belgium",
  "Gaya, Niger",
  "Gayanes, Spain",
  "Gazipur, Bangladesh",
  "Gazzaniga, Italy",
  "Gechingen, Germany",
  "Gefrees, Germany",
  "Geismar, United States",
  "Geithus, Norway",
  "Geldersheim, Germany",
  "Geleen, Netherlands",
  "Gelnhausen, Germany",
  "Gemunde, Portugal",
  "Genlis, France",
  "Gennep, Netherlands",
  "Genoa City, United States",
  "Genoa City Limits, Italy",
  "George, South Africa",
  "Georgetown, Cayman Islands",
  "Gerasdorf bei Wien, Austria",
  "Gerdau, Germany",
  "Gersfeld, Germany",
  "Gessertshausen, Germany",
  "Geste, France",
  "Ghent, United States",
  "Giberville, France",
  "Gibsonton, United States",
  "Giddings, United States",
  "Gilbertsville, United States",
  "Gildersome Leeds, United Kingdom",
  "Gilford Northern Ireland, United Kingdom",
  "Gillingham Dorset, United Kingdom",
  "Giridih, India",
  "Gironella, Spain",
  "Giurgiu, Romania",
  "Giurgiulesti, Moldova",
  "Gjemnes, Norway",
  "Gjirokaster, Albania",
  "Gland, Switzerland",
  "Glanshammar, Sweden",
  "Glascoed Monmouthshire, United Kingdom",
  "Glassboro, United States",
  "Glasson Lancashire, United Kingdom",
  "Glastonbury, United States",
  "Glen Allen, United States",
  "Glen Anil, South Africa",
  "Glen Burnie, United States",
  "Glenavy Northern Ireland, United Kingdom",
  "Glencoe, Canada",
  "GLENDALE, United States",
  "Glenpool, United States",
  "Glenview, United States",
  "Glenwillow, United States",
  "Glisy, France",
  "Glocester, United States",
  "Gloucester, United States",
  "GLYNDON, United States",
  "Goderich, Canada",
  "Godmanchester, United Kingdom",
  "Godollo, Hungary",
  "Godvik, Norway",
  "Goffstown, United States",
  "Goldau, Switzerland",
  "Golden Meadow, United States",
  "Goldsboro, United States",
  "Golfito, Costa Rica",
  "Gollhofen, Germany",
  "Goma, Congo, Dem. Rep. of",
  "Gomel, Belarus",
  "Gondomar, Portugal",
  "Goodfield, United States",
  "Goodlands, Mauritius",
  "Goodwood, South Africa",
  "Gooik, Belgium",
  "Gordonsville, United States",
  "Gorebridge EH, United Kingdom",
  "Gori, Georgia",
  "Gornji Stupnik, Croatia",
  "Gorseinon Wales, United Kingdom",
  "Goshen, United States",
  "Gosselies, Belgium",
  "Gostivar, Macedonia",
  "Gouldsboro, United States",
  "Governador Valadares, Brazil",
  "Gozo, Malta",
  "Graces, France",
  "Graf Ignatievo, Bulgaria",
  "Grafenbach St Valentin, Austria",
  "Grain, United Kingdom",
  "Gramercy, United States",
  "Granada, Nicaragua",
  "Granbury, United States",
  "Granby, Canada",
  "GRAND BASSAM, Ivory Coast",
  "Grand Falls, Canada",
  "Grand Fougeray, France",
  "Grand Island, United States",
  "Grand Ledge, United States",
  "Grand Saline, United States",
  "Grand Turk, Turks and Caicos",
  "GRAND'LANDES, France",
  "Grandview, United States",
  "Grandville, United States",
  "Grange Over Sands, United Kingdom",
  "Grangetown Redcar, United Kingdom",
  "Granite, United States",
  "Granite Falls, United States",
  "Grants Pass, United States",
  "Grantsville, United States",
  "Granville, United States",
  "Graveson, France",
  "Gray, United States",
  "Gray Court, United States",
  "Grayslake, United States",
  "Grayson, United States",
  "Great Waltham, United Kingdom",
  "Great Yeldham Essex, United Kingdom",
  "Greatham Hampshire, United Kingdom",
  "Greatworth, United Kingdom",
  "Green Cove Springs, United States",
  "Greenbelt, United States",
  "Greenbrier, United States",
  "Greenfaulds, United Kingdom",
  "Greenfield Flintshire, United Kingdom",
  "Greenland, United States",
  "Greenmount, United Kingdom",
  "GREENWICH, United States",
  "Grenzach-Wyhlen, Germany",
  "Gressvik, Norway",
  "Gretna, United States",
  "Gretna Dumfries and Galloway, United Kingdom",
  "Greyville KZN, South Africa",
  "Grifton, United States",
  "Grigny Essonne, France",
  "Grijo, Portugal",
  "Grimoldby, United Kingdom",
  "Grimsaas, Sweden",
  "Grisolles, France",
  "Grissom Arb, United States",
  "Grobina, Latvia",
  "Grodno, Belarus",
  "Grossebersdorf, Austria",
  "Grossschoenau (Sachsen), Germany",
  "Grosswallstadt, Germany",
  "Groton, United States",
  "Grottoes, United States",
  "Grou, Netherlands",
  "Grove City, United States",
  "Groveland, United States",
  "Grovetown, United States",
  "Grozny, Russia",
  "Grub am Forst, Germany",
  "Gruenberg (Hessen), Germany",
  "Grygov, Czech Republic",
  "Grythyttan, Sweden",
  "Graabo, Sweden",
  "Graasten, Denmark",
  "Guadalix de la Sierra, Spain",
  "Guadiaro, Spain",
  "Guadix, Spain",
  "Guam, Guam",
  "Guelmim, Morocco",
  "Guer, France",
  "Gueugnon, France",
  "Guilderland, United States",
  "Guilderland Center, United States",
  "Guillena, Spain",
  "Gunnilse, Sweden",
  "Guntown, United States",
  "Gura Vadului, Romania",
  "Gurh, India",
  "Gustafs, Sweden",
  "Guthrie, United States",
  "Guyton, United States",
  "Gyeongsan, Korea, South",
  "Gyomaendrod, Hungary",
  "Habsheim, France",
  "Hackensack, United Stats",
  "Hackettstown, United States",
  "Hadfield, United Kingdom",
  "Hadleigh Essex, United Kingdom",
  "Haegersten, Sweden",
  "Haellefors, Sweden",
  "Haernoesand, Sweden",
  "Haeundae-gu, Korea, South",
  "Hagan, Norway",
  "Hagavik, Norway",
  "Hagen, Germany",
  "Hagersville, Canada",
  "Hagfors, Sweden",
  "Hagondange, France",
  "Haimen, China",
  "Hainamosa, Dominican Republic",
  "Haines City, United States",
  "Haj ve Slezsku, Czech Republic",
  "Hajnowka, Poland",
  "Halasztelek, Hungary",
  "Halbe, Germany",
  "Halen, Belgium",
  "Haleyville, United States",
  "Halikko, Finland",
  "Hall, United States",
  "Hallandale, United States",
  "Hallatrow Somerset BS, United Kingdom",
  "Hallbergmoos, Germany",
  "Hallwang, Austria",
  "Halsall, United Kingdom",
  "Halstenbek, Germany",
  "Haltom City, United States",
  "Halton Hills, Canada",
  "Haman, Korea, South",
  "Hambach, France",
  "Hamburg, United States",
  "Hamden, United States",
  "Hamilton, New Zealand",
  "Hamilton Square, United States",
  "Hamlet, United States",
  "Hammarsdale, South Africa",
  "Hammenhoeg, Sweden",
  "Hampole Doncaster, United Kingdom",
  "Hampshire, United States",
  "Hampstead, United States",
  "Hampton Bays, United States",
  "Hanceville, United States",
  "Handlova, Slovakia",
  "Haninge, Sweden",
  "Hanko, Finland",
  "Hanoi, Vietnam",
  "Hanwell Middlesex, United Kingdom",
  "Haradh, Saudi Arabia",
  "Harahan, United States",
  "Hardinxveld-Giessendam, Netherlands",
  "Hardley Hampshire, United Kingdom",
  "Haresfield, United Kingdom",
  "Haridwar, India",
  "Harleysville, United States",
  "Harold Hill London, United Kingdom",
  "Harrells, United States",
  "Harrington, United States",
  "Harrison Township, United States",
  "Harrisonburg, United States",
  "Harrisonville, United States",
  "Harrow, Canada",
  "Harrow On The Hill London, United Kingdom",
  "Harrow Weald, United Kingdom",
  "Hartebeestportdam, South Africa",
  "Hartford, United States",
  "Hartsville, United States",
  "Hartwell Northamptonshire, United Kingdom",
  "Harwich, United States",
  "Harworth, United Kingdom",
  "Haskell, United States",
  "Hasselt, Belgium",
  "Hasslarp, Sweden",
  "Hatboro, United States",
  "Hatton Staffordshire, United Kingdom",
  "Hatvan, Hungary",
  "Hauglandshella, Norway",
  "Haute Goulaine, France",
  "Hauterive, France",
  "Hauxton, United Kingdom",
  "Havdrup, Denmark",
  "Haverhill, United States",
  "Haverstraw, United States",
  "Havre de Grace, United States",
  "Hawkesbury, Canada",
  "Hawkins, United States",
  "Haworth, United Kingdom",
  "Hawthorne, United States",
  "Haydon Bridge, United Kingdom",
  "HAYMARKET, United States",
  "Hazel Crest, United States",
  "Hazelton Mills, United States",
  "Hazle Township, United States",
  "Hazlehead S Yorkshire, United Kingdom",
  "Headcorn, United Kingdom",
  "Hearne, United States",
  "Heathfield East Sussex, United Kingdom",
  "Heber Springs, United States",
  "Hecken, France",
  "Hedehusene, Denmark",
  "Hedon, United Kingdom",
  "Heemstede, Netherlands",
  "Heeslingen, Germany",
  "Hegins, United States",
  "HEILIGENBERG, France",
  "Heillecourt, France",
  "Helenwood, United States",
  "Hellevoetsluis, Netherlands",
  "Hellmonsoedt, Austria",
  "Helm, United States",
  "Helsingby, Finland",
  "Helsinge, Denmark",
  "Hempstead, United States",
  "Hemsworth, United Kingdom",
  "Hendrik Ido Ambacht, Netherlands",
  "Henryetta, United States",
  "Henryville, Canada",
  "Hensall, Canada",
  "Herceg Novi, Montenegro",
  "Herent, Belgium",
  "Hergla-Harqalah, Tunisia",
  "Heric, France",
  "Heriotdale, South Africa",
  "Herlev, Denmark",
  "Hermanus, South Africa",
  "Hermiston, United States",
  "Hermitage, United States",
  "Hermosillo, Mexico",
  "Herne Bay, United Kingdom",
  "Herrera, Spain",
  "Hersbruck, Germany",
  "Herzberg (am Harz), Germany",
  "Herzogenburg, Austria",
  "HESPERIA, United States",
  "HESWALL, United Kingdom",
  "Hialeah, United States",
  "Hialeah Gardens, United States",
  "Hiawatha, United States",
  "Hickam AFB, United States",
  "Hicksville, United States",
  "Higashi Ogishima Kawasaki, Japan",
  "HIGASHIOSAKA, Japan",
  "Highland, United States",
  "Higuera la Real, Spain",
  "Hildebran, United States",
  "Hilleroed, Denmark",
  "Hilliard, United States",
  "Hillsdale, United States",
  "Hilton Derbyshire DE, United Kingdom",
  "Hindhead, United Kingdom",
  "Hindley Wigan, United Kingdom",
  "Hinterweidenthal, Germany",
  "Hirwaun, United Kingdom",
  "Hisings Kaerra, Sweden",
  "Histon Cambs, United Kingdom",
  "Hitchcock, United States",
  "Hjaernarp, Sweden",
  "Hjallerup, Denmark",
  "Hjo, Sweden",
  "Hlucin, Czech Republic",
  "Hniezdne, Slovakia",
  "Ho Chi Minh city - ICD Phuoc Long, Vietnam",
  "Hobart, United States",
  "Hobbs, United States",
  "Hockley Essex, United Kingdom",
  "Hodeidah, Yemen",
  "Hodnet Shropshire, United Kingdom",
  "Hodrusa-Hamre, Slovakia",
  "Hoelstein, Switzerland",
  "Hoenefoss, Norway",
  "Hoensbroek, Netherlands",
  "Hoeoer, Sweden",
  "Hoerning, Denmark",
  "Hoerselgau, Germany",
  "Hoeslwang, Germany",
  "Hoffman Estates, United States",
  "Hofheim (am Taunus), Germany",
  "Hofors, Sweden",
  "Hohen Wangelin, Germany",
  "Hohenberg, Austria",
  "Hohenstein (Wuertt), Germany",
  "Hohenthurn, Austria",
  "Hohenwestedt, Germany",
  "Hok, Sweden",
  "Holasice, Czech Republic",
  "Hollenstedt (Nordheide), Germany",
  "Hollis, United States",
  "Hollister, United States",
  "Holly, United States",
  "Hollywood Worcestershire B, United Kingdom",
  "Holm, Sweden",
  "Holme Cumbria, United Kingdom",
  "Holmegaard, Denmark",
  "Holmsund, Sweden",
  "Holsworthy Devon, United Kingdom",
  "Holyoke, United States",
  "Holywood Belfast, United Kingdom",
  "Holzheim, Germany",
  "Hombrechtikon, Switzerland",
  "Homer, United States",
  "Homestead, United States",
  "Homewood, United States",
  "Honesdale, United States",
  "Honey Brook, United States",
  "Honselersdijk, Netherlands",
  "Hood River, United States",
  "Hoogerheide, Netherlands",
  "Hoogvliet, Netherlands",
  "Hooksett, United States",
  "Hooton, United Kingdom",
  "Hopedale, United States",
  "Hopewell Junction, United States",
  "Hopkinton, United States",
  "Hoptonheath, United Kingdom",
  "Horam, United Kingdom",
  "Hordvik, Norway",
  "Horia, Romania",
  "Horn, Switzerland",
  "Horne Odbokovce, Slovakia",
  "Hornell, United States",
  "Horni Becva, Czech Republic",
  "Horni Cerekev, Czech Republic",
  "Horni Mostenice, Czech Republic",
  "Horni Plana, Czech Republic",
  "Horni Tresnovec, Czech Republic",
  "Hornsea, United Kingdom",
  "Hornslet, Denmark",
  "Horomerice, Czech Republic",
  "Horseheads, United States",
  "Horten, Norway",
  "Hortonville, United States",
  "Hose, United Kingdom",
  "Hossegor, France",
  "Hot Springs, United States",
  "Houma, United States",
  "Hovezi, Czech Republic",
  "Hovorcovice, Czech Republic",
  "Howard City, United States",
  "Howell, United States",
  "Howick, South Africa",
  "Howrah, India",
  "Hradec nad Moravici, Czech Republic",
  "Hradek, Czech Republic",
  "Hradek u Rokycan, Czech Republic",
  "Hrobcice, Czech Republic",
  "Hronov, Czech Republic",
  "Hrotovice, Czech Republic",
  "Hroznetin, Czech Republic",
  "Huamantla, Mexico",
  "Huatusco, Mexico",
  "Hubbard, United States",
  "Huber Heights, United States",
  "Hudson Falls, United States",
  "Hudsonville, United States",
  "Huercal de Almeria, Spain",
  "Huercal Overa, Spain",
  "Hueytown, United States",
  "Huger, United States",
  "Huguenot, United States",
  "Huissen, Netherlands",
  "Huittinen, Finland",
  "Huizingen, Belgium",
  "Humber Devon, United Kingdom",
  "Humboldt, United States",
  "Hummelstown, United States",
  "Hunt Valley, United States",
  "Huntersville, United States",
  "Huntingdon, Canada",
  "Huntingdon, United States",
  "Huntingdon Valley, United States",
  "Huntington Beach, United States",
  "Huntington Yorkshire, United Kingdom",
  "Hunzenschwil, Switzerland",
  "Hurlburt Field, United States",
  "Hurley Windsor and Maidenhead, United Kingdom",
  "Hurlock, United States",
  "Huron, United States",
  "Hurup, Denmark",
  "Hustenovice, Czech Republic",
  "Hustisford, United States",
  "Hutchins, United States",
  "Hutchinson, United States",
  "Hutto, United States",
  "HUZHOU, China",
  "Hwange, Zimbabwe",
  "Hyannis, United States",
  "Hyattsville, United States",
  "Hyde Park, United States",
  "Hythe Hamps, United Kingdom",
  "Hyvinkaa, Finland",
  "Haaksbergen, Netherlands",
  "Haaltert, Belgium",
  "Ibadan, Nigeria",
  "ICHIKAWA, Japan",
  "Ickburgh, United Kingdom",
  "Icklingham Suffolk, United Kingdom",
  "ICP BIRGUNJ BHANSAR, Nepal",
  "Idar-Oberstein, Germany",
  "Iecava, Latvia",
  "Ierapetra, Greece",
  "Ihlow (Ostfriesland), Germany",
  "Ihode, Finland",
  "Ikhtiman, Bulgaria",
  "Ilion, Greece",
  "Ilketshall St Andrew, United Kingdom",
  "Ilmajoki, Finland",
  "Iloilo, Philippines",
  "Ilton North Yorkshire, United Kingdom",
  "Ilvesjoki, Finland",
  "Imari, Japan",
  "Imatra, Finland",
  "Imielin, Poland",
  "Imlay City, United States",
  "Imperial, United States",
  "Indian Head, United States",
  "Indian Land, United States",
  "Indian Trail, United States",
  "Indiana, United States",
  "Indianola, United States",
  "Indio, United States",
  "INDJIJA, Serbia",
  "Indre Arna, Norway",
  "Induno Olona, Italy",
  "Ingaroe, Sweden",
  "Ingelfingen, Germany",
  "Ingelheim (Am Rhein), Germany",
  "Ingleside, United States",
  "Ingrandes Vienne, France",
  "Innerkip, Canada",
  "Inning am Ammersee, Germany",
  "Inola, United States",
  "Insjoen, Sweden",
  "Intercourse, United States",
  "Ionadi, Italy",
  "Ipsheim, Germany",
  "Iron Ridge, United States",
  "Irvington, United States",
  "Irwindale, United States",
  "Isbergues, France",
  "Iselin, United States",
  "Ishoej, Denmark",
  "Isiolo, Kenya",
  "Isipingo, South Africa",
  "Isithebe, South Africa",
  "Isla Cristina, Spain",
  "Islamabad, Pakistan",
  "Islandia, United States",
  "Isle of Dogs, United Kingdom",
  "Issaquah, United States",
  "ISSENHEIM, France",
  "Istiea, Greece",
  "Ithaca, United States",
  "Itzehoe, Germany",
  "Ivanic-Grad, Croatia",
  "Ivanovice Na Hane, Czech Republic",
  "Iver Heath, United Kingdom",
  "Ivry sur Seine, France",
  "Iwakuni, Japan",
  "Iwanowice, Poland",
  "Ixworth, United Kingdom",
  "Izhevsk, Russia",
  "Jablonove, Slovakia",
  "Jablunka, Czech Republic",
  "Jablunkov, Czech Republic",
  "Jacobs, South Africa",
  "Jaffrey, United States",
  "Jaktorow, Poland",
  "Jalalabad, Afghanistan",
  "Janki, Poland",
  "Janoshalma, Hungary",
  "Jarvenpaa, Finland",
  "Jaslovske Bohunice, Slovakia",
  "Jastrebarsko, Croatia",
  "Jastrzebie Zdroj, Poland",
  "Jaunay Clan, France",
  "Jawor, Poland",
  "Jazan, Saudi Arabia",
  "Jedenspeigen, Austria",
  "Jedlicze, Poland",
  "Jefferson City, United States",
  "Jegenstorf, Switzerland",
  "Jekabpils, Latvia",
  "Jemeppe sur Sambre, Belgium",
  "Jemnice, Czech Republic",
  "Jenbach, Austria",
  "Jenison, United States",
  "Jeppestown JNB, South Africa",
  "Jersey Shore, United States",
  "Jerusalem, Israel",
  "Jesenice (u Rakovnika), Czech Republic",
  "Jessheim, Norway",
  "Jetrichov, Czech Republic",
  "Jilotepec, Mexico",
  "Jimenado, Spain",
  "Jincheon, Korea, South",
  "Jinotepe, Nicaragua",
  "Jiretin pod Jedlovou, Czech Republic",
  "Jirikov, Czech Republic",
  "Jirkov, Czech Republic",
  "Johnsonville, United States",
  "Johnston, United States",
  "Johvi, Estonia",
  "Joniskis, Lithuania",
  "Jordan Station, Canada",
  "Jordanow, Poland",
  "Joue les Tours, France",
  "Jousse, France",
  "Juba, South Sudan",
  "Jugon Les Lacs, France",
  "Juliette, United States",
  "Junction City, United States",
  "Juneau, United States",
  "Jungingen, Germany",
  "Jupiter, United States",
  "Jurmala, Latvia",
  "Jurupa Valley, United States",
  "Jurva, Finland",
  "Juticalpa, Honduras",
  "JUVIGNAC, France",
  "Jyderup, Denmark",
  "Jyvaskyla, Finland",
  "Kabul, Afghanistan",
  "Kabul Via Chaman, Afghanistan",
  "KADOMA, Zimbabwe",
  "Kaduna, Nigeria",
  "KAEDI, Mauritania",
  "Kaellby, Sweden",
  "Kakarbitta, Nepal",
  "Kakenstorf, Germany",
  "Kakhovka, Ukraine",
  "Kalamaria, Greece",
  "Kalamazoo, United States",
  "Kalinganagar, India",
  "Kalkaska, United States",
  "Kalmar, Sweden",
  "Kalsdorf, Austria",
  "Kaltenleutgeben, Austria",
  "Kaluga, Russia",
  "Kalyvia, Greece",
  "Kamari Viotias Evvoia, Greece",
  "Kamenica, Macedonia",
  "Kamenicna, Slovakia",
  "Kamsar, Guinea",
  "Kancheepuram, India",
  "Kandava, Latvia",
  "Kandla, India",
  "Kankakee, United States",
  "Kannot, Israel",
  "Kanzaki gun Saga, Japan",
  "Kaolack, Senegal",
  "Kapolei, United States",
  "Kapusany, Slovakia",
  "Kapuvar, Hungary",
  "Karbala, Iraq",
  "Karcag, Hungary",
  "Karkku, Finland",
  "Karmoy, Norway",
  "Karmsund, Norway",
  "Karttula, Finland",
  "Karup, Denmark",
  "Kasoa, Ghana",
  "Kasperske Hory, Czech Republic",
  "Kastel Sucurac, Croatia",
  "Kathmandu, Nepal",
  "Kato Achaia, Greece",
  "Katunayake, Sri Lanka",
  "Kaufman, United States",
  "Kauhava, Finland",
  "Kauvatsa, Finland",
  "Kawasaki, Japan",
  "Kayalami, South Africa",
  "Kayes, Mali",
  "Kayonza, Rwanda",
  "Kazan, Russia",
  "Kazlu Ruda, Lithuania",
  "Kdyne, Czech Republic",
  "Kearneysville, United States",
  "Keele Staffordshire, United Kingdom",
  "Keene, United States",
  "Kegworth, United Kingdom",
  "Kekava, Latvia",
  "Kelowna, Canada",
  "Kembolcha, Ethiopia",
  "Kenansville, United States",
  "Kenilworth, United States",
  "Kenner, United States",
  "KENSINGTON, United States",
  "Kentwood, United States",
  "Kenyon, United States",
  "Keratsini, Greece",
  "Kerava, Finland",
  "Kereki, Hungary",
  "Kerhonkson, United States",
  "Kerzers, Switzerland",
  "Ketsch, Germany",
  "Kew, South Africa",
  "Kewanee, United States",
  "Keyes, United States",
  "Khardaha, India",
  "Khidirpur, India",
  "Khimki, Russia",
  "Khor Fakkan, United Arab Emirates",
  "Khurja, India",
  "Kiato, Greece",
  "Kidron, United States",
  "Kielno, Poland",
  "Kieselbronn, Germany",
  "Kietrz, Poland",
  "Kil, Sweden",
  "Kilbeggan, Ireland",
  "Kilbirnie, United Kingdom",
  "Kilbride Argyll, United Kingdom",
  "Kilburn Derbyshire, United Kingdom",
  "Killamarsh, United Kingdom",
  "Kilmaurs, United Kingdom",
  "Kilnhurst, United Kingdom",
  "Kilombero, Tanzania",
  "Kimbolton, United Kingdom",
  "Kindsbach (Pfalz), Germany",
  "Kineton Gloucestershire, United Kingdom",
  "King City, Canada",
  "Kings Heath, United Kingdom",
  "Kingsbridge Devon, United Kingdom",
  "Kingsburg, United States",
  "Kingsbury, United States",
  "Kingsley Cheshire, United Kingdom",
  "Kingsnorth, United Kingdom",
  "Kingsport, United States",
  "Kingston, Canada",
  "Kingston, United States",
  "Kingston Blount, United Kingdom",
  "Kingstone HR, United Kingdom",
  "Kingstree, United States",
  "Kingsville, Canada",
  "Kingsville, United States",
  "Kingswood Surrey, United Kingdom",
  "Kinlochbervie, United Kingdom",
  "Kinna, Sweden",
  "Kinnahult, Sweden",
  "Kinsale Cork, Ireland",
  "Kinsarvik, Norway",
  "Kirby Hill North Yorkshire, United Kingdom",
  "Kirchberg an der Iller, Germany",
  "Kirchberg BE, Switzerland",
  "Kirchentellinsfurt, Germany",
  "Kirkbride, United Kingdom",
  "Kirkby Lonsdale, United Kingdom",
  "Kirkheaton Kirkless, United Kingdom",
  "Kirkkonummi, Finland",
  "Kirkland, Canada",
  "Kirkudbright, United Kingdom",
  "Kirton Suffolk, United Kingdom",
  "KIRYAT ATA, Israel",
  "Kista, Sweden",
  "Kitimat, Canada",
  "Klaj, Poland",
  "Klecza Gorna, Poland",
  "Kleinburg, Canada",
  "Klerksoord, South Africa",
  "Klicany, Czech Republic",
  "Klippan, Sweden",
  "Kloefta, Norway",
  "Klong Toey, Thailand",
  "Klosterneuburg, Austria",
  "Klaaswaal, Netherlands",
  "KNOPPIESLAAGTE, South Africa",
  "Knox, United States",
  "Knysna, South Africa",
  "Koberice, Czech Republic",
  "Kobylanka, Poland",
  "Kobyli, Czech Republic",
  "Kobylnica, Poland",
  "Kocani, Macedonia",
  "Kodak, United States",
  "Koengen, Germany",
  "Koeping, Sweden",
  "Koethen (Anh), Germany",
  "Kojetice, Czech Republic",
  "Kojetin, Czech Republic",
  "Kokkola, Finland",
  "Kokomo, United States",
  "Kokstad, Norway",
  "Kolarovo, Slovakia",
  "Kolomna, Russia",
  "Kolppi, Finland",
  "Kolwezi, Congo, Dem. Rep. of",
  "Komlo, Hungary",
  "Komoka, Canada",
  "Komorow, Poland",
  "Kongerslev, Denmark",
  "Kongsvinger, Norway",
  "Konohana-Ku Osaka, Japan",
  "Konopiska, Poland",
  "Kopervik, Norway",
  "Korczyna, Poland",
  "Kornik, Poland",
  "Korsten PLZ, South Africa",
  "Koryong, Korea, South",
  "Koscielna Wies, Poland",
  "Kosmonosy, Czech Republic",
  "Kosor, Czech Republic",
  "Kosove Polje, Serbia",
  "Kostany nad Turcom, Slovakia",
  "Kostenice, Czech Republic",
  "Kostice, Czech Republic",
  "Kostroma (Kostroma), Russia",
  "KOTO-KU, Japan",
  "Koudougou, Burkina Faso",
  "Koumassi, Ivory Coast",
  "Kout na Sumave, Czech Republic",
  "Kowalewo Pomorskie, Poland",
  "Kozieglowy, Poland",
  "Kozloduy, Bulgaria",
  "Kozmice, Czech Republic",
  "Kozy, Poland",
  "Kpone, Ghana",
  "Kraczkowa, Poland",
  "KRAKOVANY, Slovakia",
  "Kralendijk, Bonaire Sint Eustatius and Saba",
  "KRANIDI, Greece",
  "Krasne, Poland",
  "KRESEVO, Bosnia and Herzegovina",
  "Kribi, Cameroon",
  "Krimpen a d Lek, Netherlands",
  "Kristiansund, Norway",
  "Krivan, Slovakia",
  "KRNJESEVCI, Serbia",
  "Krong Poipet, Cambodia",
  "Krosno Odrzanskie, Poland",
  "Krucemburk, Czech Republic",
  "Krzecin - Gmina Skawina, Poland",
  "Krzemienica, Poland",
  "Krzeszowice, Poland",
  "Kraaifontein, South Africa",
  "Ksar Hlel, Tunisia",
  "Ksawerow, Poland",
  "Kucukkoy, Turkey",
  "Kuehbach, Germany",
  "Kumamoto, Japan",
  "Kuncice Pod Ondrejnikem, Czech Republic",
  "Kunda, Estonia",
  "Kungaelv, Sweden",
  "Kungsaengen, Sweden",
  "Kungsbacka, Sweden",
  "Kungshamn, Sweden",
  "Kunheim, France",
  "Kunice, Poland",
  "Kunpo, Korea, South",
  "Kunsan, Korea, South",
  "Kuopio, Finland",
  "Kurivody, Czech Republic",
  "Kursenai, Lithuania",
  "Kurume, Japan",
  "Kushiro, Japan",
  "Kustanai, Kazakhstan",
  "Kuurne, Belgium",
  "Kuwait Naval Base, Kuwait",
  "Kvasice, Czech Republic",
  "Kvissleby, Sweden",
  "Kwangju Cholla Province, Korea, South",
  "Kwangju, Kyungki-Do, Korea, South",
  "Kyro, Finland",
  "Kysice, Czech Republic",
  "L Aldea, Spain",
  "L Ange Gardien, Canada",
  "La Balme de Thuy, France",
  "La Bisbal Del Penedes, Spain",
  "La Canada de San Urbano, Spain",
  "La Canya, Spain",
  "La Chapelle Basse Mer, France",
  "La Chapelle Saint Ursin, France",
  "La Charite sur Loire, France",
  "La Chaussee Saint Victor, France",
  "La Chaux de Fonds, Switzerland",
  "La Esperanza Intibuca, Honduras",
  "La Goulette, Tunisia",
  "La Grande, United States",
  "La Grange Highlands, United States",
  "La Habra, United States",
  "La Houssaye en Brie, France",
  "La Jonquera, Spain",
  "La Llagosta, Spain",
  "La Londe, France",
  "La Mercy, South Africa",
  "La Meziere, France",
  "La Negra, Chile",
  "La Pallice, France",
  "La Paz, Bolivia",
  "La Pommeraye, France",
  "La Puebla Murcia, Spain",
  "La Puente, United States",
  "La Rochelle Pallice, France",
  "La Romana, Dominican Republic",
  "La Salette, Canada",
  "La Seguiniere, France",
  "La Seu d'Urgell, Spain",
  "La Torre de Claramunt, Spain",
  "La Tour du Pin, France",
  "La Tour-de-Peilz, Switzerland",
  "La Vall de Bianya, Spain",
  "La Verne, United States",
  "La Veuve, France",
  "La Ville aux Dames, France",
  "Lacassine, United States",
  "Laceby Lincolnshire, United Kingdom",
  "Lacey, United States",
  "Lackland A F B, United States",
  "Lacko(k Nowego Sacza), Poland",
  "Lac-Megantic, Canada",
  "Lacock Wilts, United Kingdom",
  "Laconia, United States",
  "Lacroix Saint Ouen, France",
  "Laenna - Uppsala, Sweden",
  "Lafiteau, Haiti",
  "Lagos, Nigeria",
  "Lagos, Portugal",
  "Laka, Poland",
  "Lake Bluff, United States",
  "Lake Dallas, United States",
  "Lake Elsinore, United States",
  "Lake Geneva, United States",
  "Lake Mills, United States",
  "Lake Orion, United States",
  "Lake Oswego, United States",
  "Lake Placid, United States",
  "Lake Worth, United States",
  "Laken, Belgium",
  "Lakeshore, Canada",
  "Laksevaag, Norway",
  "Lambrugo, Italy",
  "Lambton Shores, Canada",
  "Lamezia Terme, Italy",
  "Lamothe-Montravel, France",
  "Lampa, Chile",
  "Lanark, United Kingdom",
  "Lanciego, Spain",
  "Landgraaf, Netherlands",
  "Landover, United States",
  "Landstuhl, Germany",
  "Landvetter, Sweden",
  "Lane End Buckinghamshire, United Kingdom",
  "Langburkersdorf, Germany",
  "Langenbernsdorf, Germany",
  "Langenstein, Germany",
  "Langenweddingen, Germany",
  "Langhorne, United States",
  "Langhus, Norway",
  "Langley, United States",
  "Langley Slough SL, United Kingdom",
  "Langnau im Emmental, Switzerland",
  "Langon Gironde, France",
  "Langton Durham DL, United Kingdom",
  "Langwathby Cumbria, United Kingdom",
  "LangworthLincolnshire LN, United Kingdom",
  "Lanham, United States",
  "Lannemezan, France",
  "Lansdale, United States",
  "Lanzarote, Spain",
  "Lapeer, United States",
  "Lappi, Finland",
  "Lapua, Finland",
  "L'Arboc, Spain",
  "Larimna, Greece",
  "Larkfield Gourock, United Kingdom",
  "Larrabetzu, Spain",
  "Larrabezua, Spain",
  "Larrondo, Spain",
  "Larsnes, Norway",
  "Las Chafiras S Miguel, Spain",
  "Las Matas, Spain",
  "Las Ventas de Retamosa Toledo, Spain",
  "L'Assomption, Canada",
  "LATHAM, United States",
  "Latta, United States",
  "Lattimore, United States",
  "Lauderdale Lakes, United States",
  "Lauderhill, United States",
  "Laufenburg, Germany",
  "Laurens, United States",
  "Laurinburg, United States",
  "Lausanne, Switzerland",
  "Lavans les Saint Claude, France",
  "Laveen, United States",
  "Lawrence Township, United States",
  "Lawton, United States",
  "Lazne Tousen, Czech Republic",
  "Le Bourg D Oisans, France",
  "Le Cheylas, France",
  "Le Coudray Montceaux, France",
  "Le Gardeur, Canada",
  "Le Lardin Saint Lazare, France",
  "Le Longeron, France",
  "Le Monastere, France",
  "Le Pallet, France",
  "Le Plessis Bouchard, France",
  "Le Pontet, France",
  "LE PUY EN VELAY, France",
  "Le Rove, France",
  "Le Roy, United States",
  "Le Thillot sous les Cotes, France",
  "Leamington, Canada",
  "Lebcz, Poland",
  "Lebec, United States",
  "Ledce, Czech Republic",
  "Lederhose, Germany",
  "Ledgewood, United States",
  "Ledono, Spain",
  "Lee, United States",
  "Leeds, United States",
  "Leek Wootton, United Kingdom",
  "Leeming Bar, United Kingdom",
  "Lees Summit, United States",
  "Leesport, United States",
  "Leetsdale, United States",
  "Leeuwfontein, South Africa",
  "Leffe, Italy",
  "Legionowo, Poland",
  "Lehighton, United States",
  "Leinburg, Germany",
  "Leinstrand, Norway",
  "Leitchfield, United States",
  "Leith, United Kingdom",
  "Lekkerkerk, Netherlands",
  "Lekki, Nigeria",
  "Leland, United States",
  "Lemoore, United States",
  "Lemoyne, United States",
  "Lena, United States",
  "Lenasia, South Africa",
  "Lencloitre, France",
  "Lenexa, United States",
  "Lengenfeld, Germany",
  "Lenoir City, United States",
  "Lentini, Italy",
  "Lentvaris, Lithuania",
  "Leola, United States",
  "Leominster, United States",
  "Lerino, Italy",
  "LES COVES DE VINROMA, Spain",
  "Les Fonts, Spain",
  "Les Gonds, France",
  "Les Lucs sur Boulogne, France",
  "Les Masies de Roda, Spain",
  "Les Masies de Voltrega Barcelona, Spain",
  "Les Milles, France",
  "Les Mureaux, France",
  "Les Quatres Routes, France",
  "Les Ulis, France",
  "Les Verrieres, Switzerland",
  "Lesaca, Spain",
  "Leskovec Nad Moravici, Czech Republic",
  "Lesquin, France",
  "Le-Teich, France",
  "Lety, Czech Republic",
  "Leudelange, Luxemburg",
  "Leuggern, Switzerland",
  "Levadhia, Greece",
  "Leven Fife, United Kingdom",
  "LEVET, France",
  "Levis, Canada",
  "Levittown, United States",
  "Levoca, Slovakia",
  "Leyburn, United Kingdom",
  "Lezignan Corbieres, France",
  "Lhospitalet, France",
  "Lian Hua Shan, China",
  "Liberty, United States",
  "Libeznice, Czech Republic",
  "Libice nad Cidlinou, Czech Republic",
  "Liblice, Czech Republic",
  "Libusin, Czech Republic",
  "Lichoceves, Czech Republic",
  "Liebenwalde, Germany",
  "Lienden, Netherlands",
  "Lienen, Germany",
  "Lieto, Finland",
  "Lieusaint, France",
  "Liffre, France",
  "Lijnden, Netherlands",
  "Likasi, Congo, Dem. Rep. of",
  "Lilburn, United States",
  "Lillehammer, Norway",
  "Lima Peru, Peru",
  "Limbe, Cameroon",
  "Limbe, Malawi",
  "Limon via Turrialba, Costa Rica",
  "Linares, Chile",
  "Lincoln, United States",
  "Lincolnwood, United States",
  "Lindenfels, Germany",
  "Lindenhurst, United States",
  "Lindsay, Canada",
  "Lindstrom, United States",
  "Linesville, United States",
  "Linthicum, United States",
  "Linthicum Heights, United States",
  "Lira, Uganda",
  "Lisnaskea Northern Ireland, United Kingdom",
  "Listowel, Canada",
  "Liti, Greece",
  "Little Addington, United Kingdom",
  "Little Staughton, United Kingdom",
  "Littlefield, United States",
  "Littleover, United Kingdom",
  "Littlerock, United States",
  "Littlestown, United States",
  "Live Oak, United States",
  "Livingstone, Zambia",
  "Llanbedr Gwynedd, United Kingdom",
  "Llandeilo, United Kingdom",
  "Llandovery, United Kingdom",
  "Llangristiolus Anglesey LL, United Kingdom",
  "Llano, United States",
  "Llano De Brujas, Spain",
  "Llano de Quart, Spain",
  "Llissa de Munt, Spain",
  "Llorens del Penedes, Spain",
  "Lochovice, Czech Republic",
  "Lock Haven, United States",
  "Locke, United States",
  "Lockeford, United States",
  "Lockland, United States",
  "Loddon, United Kingdom",
  "Loederup, Sweden",
  "Loerenskog, Norway",
  "Loganton, United States",
  "Loganville, United States",
  "Lohfelden, Germany",
  "L'oie, France",
  "Loimaa, Finland",
  "Loiron, France",
  "Loison sous Lens, France",
  "Loitz, Germany",
  "Loja, Spain",
  "Lolita, United States",
  "Lombard, United States",
  "Lomianki, Poland",
  "Lomma, Sweden",
  "Lommatzsch, Germany",
  "Lomme, France",
  "London, United States",
  "Long Lawford, United Kingdom",
  "Long Stratton, United Kingdom",
  "Longbenton, United Kingdom",
  "Longeves, France",
  "Longeville les Saint Avold, France",
  "Longfield, United Kingdom",
  "Longhope Gloucestershire, United Kingdom",
  "Longmeadow East, South Africa",
  "Longton Staffs, United Kingdom",
  "Longueil, France",
  "Longueil Ste Marie, France",
  "LONGWOOD, United States",
  "Lons, France",
  "LOO, Estonia",
  "Lorient, France",
  "Lorinci, Hungary",
  "Loris, United States",
  "Lorton, United States",
  "Los Angeles, Chile",
  "Los Cristianos, Spain",
  "Los Fresnos, United States",
  "Los Indios, United States",
  "Los Lunas, United States",
  "Los Martinez del Puerto, Spain",
  "Los Mochis, Mexico",
  "Los Villares Jaen, Spain",
  "Losice, Poland",
  "Lostock Gralam Cheshire, United Kingdom",
  "Loudon, United States",
  "Loudwater, United Kingdom",
  "Loughton Essex, United Kingdom",
  "Louiseville, Canada",
  "Lount Leicestershire LE, United Kingdom",
  "Lourinha, Portugal",
  "Lovington, United States",
  "Lowdham, United Kingdom",
  "Lower Higham, United Kingdom",
  "Loxahatchee, United States",
  "Loxley, United Kingdom",
  "Lozanne, France",
  "Lozzo Atestino, Italy",
  "Luanshya, Zambia",
  "Lubbeek, Belgium",
  "Lubbock, United States",
  "Lubenia, Poland",
  "Lubon, Poland",
  "Lucany nad Nisou, Czech Republic",
  "Lucedale, United States",
  "Lucera, Italy",
  "Lucerne, Switzerland",
  "Ludvika, Sweden",
  "Ludwigsau, Germany",
  "Luebbenau/Spreewald, Germany",
  "Luebtheen, Germany",
  "Luesslingen-Nennigkofen, Switzerland",
  "Luka nad Vahom, Slovakia",
  "Lukeville, United States",
  "Lukovit, Bulgaria",
  "Luling, United States",
  "Lumberton, United States",
  "Lunenburg, United States",
  "Luogosano, Italy",
  "Luque, Spain",
  "Lusk Co Dublin, Ireland",
  "Lutz, United States",
  "Lutzerath, Germany",
  "Luvu, Angola",
  "LUZERN-LITTAU, Switzerland",
  "LUZERN-REUSSBUEHL, Switzerland",
  "Lycksele, Sweden",
  "Lydiate, United Kingdom",
  "Lye, United Kingdom",
  "Lynbrook, United States",
  "Lyndhurst, United Kingdom",
  "Lynnwood, South Africa",
  "Lynwood, United States",
  "Lyon Station, United States",
  "Lysa Nad Labem, Czech Republic",
  "Lysekil, Sweden",
  "Lyski, Poland",
  "Laagri, Estonia",
  "Laatsa, Estonia",
  "Mableton, United States",
  "Macao Santarem, Portugal",
  "Macclenny, United States",
  "Macedonia, United States",
  "Mackinaw, United States",
  "Macomb, United States",
  "Macungie, United States",
  "Made, Netherlands",
  "Madera, United States",
  "Maebashi, Japan",
  "Maegenwil, Switzerland",
  "Mafeteng, Lesotho",
  "Magadan, Russia",
  "Maghull, United Kingdom",
  "Magliano de'Marsi, Italy",
  "Magne, France",
  "Magnolia, United States",
  "Maiden, United States",
  "MAIDSTONE, Canada",
  "Maiolati Spontini, Italy",
  "Maitland, United States",
  "Maizuru, Japan",
  "Majcichov, Slovakia",
  "Makow Podhalanski, Poland",
  "Malakoff, United States",
  "Malanow, Poland",
  "Malchin, Germany",
  "Malden, United States",
  "Malemort Sur Correze, France",
  "Malgrat de Mar, Spain",
  "Malmstrom, United States",
  "Malvaglio, Italy",
  "Malveira, Portugal",
  "MALVERN, United States",
  "Malvern East, South Africa",
  "Mamble Worcestershire, United Kingdom",
  "Manage, Belgium",
  "Manalapan, United States",
  "Manama, Bahrain",
  "Manassas Park, United States",
  "Manchester Township, United States",
  "Manching, Germany",
  "Manda Bay, Kenya",
  "Mandi Gobindgarh, India",
  "Mangere, New Zealand",
  "Manhasset, United States",
  "Manhattan, United States",
  "Manheim, United States",
  "Manilva, Spain",
  "Mannswoerth, Austria",
  "Manor, United States",
  "Manteca, United States",
  "Manteo, United States",
  "Mantorp, Sweden",
  "Mantsala, Finland",
  "Manzini, Swaziland",
  "Maple Park, United States",
  "Maple Shade, United States",
  "Maple Valley, United States",
  "Maracena, Spain",
  "Maradi, Niger",
  "Marathonas, Greece",
  "March (Breisgau), Germany",
  "Margam, United Kingdom",
  "Margarethen am Moos, Austria",
  "Maria Lanzendorf, Austria",
  "Marianna, United States",
  "Marieville, Canada",
  "MARIGLIANELLA, Italy",
  "Marignane, France",
  "Marigny le Chatel, France",
  "Marin, France",
  "Marina de Ragusa, Italy",
  "Markdale, Canada",
  "Market Bosworth Leicestershire, United Kingdom",
  "Markham, Canada",
  "Markkleeberg, Germany",
  "Markman Township, South Africa",
  "Markt Schwaben, Germany",
  "Marktschorgast, Germany",
  "Marlow, United States",
  "Marlton, United States",
  "Marmaris, Turkey",
  "Marne, United States",
  "Marne la Vallee, France",
  "Marolles en Hurepoix, France",
  "Marple, United Kingdom",
  "Marsannay la Cote, France",
  "Marsden Kirkless, United Kingdom",
  "Marseille le Canet, France",
  "Marshall, United States",
  "Martham, United Kingdom",
  "Martin de la Jara, Spain",
  "Martinez, United States",
  "Marttila, Finland",
  "Marum, Netherlands",
  "Maryville, United States",
  "Marzan, France",
  "Masagua, Guatemala",
  "Masai, Malaysia",
  "Mascali, Italy",
  "Maspalomas, Spain",
  "Maspeth, United States",
  "Massanes, Spain",
  "Matagorda, Spain",
  "Matarani, Peru",
  "Mateare, Nicaragua",
  "Mather, United States",
  "Mattapan, United States",
  "Matteson, United States",
  "Matthews, United States",
  "Matulji, Croatia",
  "Matuu, Kenya",
  "Maud, United Kingdom",
  "Mauldin, United States",
  "Maumelle, United States",
  "Mauren, Liechtenstein",
  "Maurepas, France",
  "Maxton, United States",
  "Mayfield, United States",
  "Mayfield Lothian, United Kingdom",
  "Mayodan, United States",
  "Mayport, United States",
  "Mazancowice, Poland",
  "Mazapil, Mexico",
  "Mbabane, Swaziland",
  "Mbarara, Uganda",
  "Mbour, Senegal",
  "Mc Calla, United States",
  "Mc Cook, United States",
  "Mc Elhattan, United States",
  "Mc farland, United States",
  "Mc Gregor, United States",
  "Mc Intosh, United States",
  "Mc Kenzie, United States",
  "Mcalester, United States",
  "Mccarran, United States",
  "Mcchord, United States",
  "Mcclellan, United States",
  "Mcconnelsville, United States",
  "Mccutcheon Field, United States",
  "McDade, United States",
  "McHenry, United States",
  "McKees Rocks, United States",
  "McKinleyville, United States",
  "Mclean, United States",
  "Mcminnville, United States",
  "Mdantsane, South Africa",
  "Meadowdale, South Africa",
  "Mebane, United States",
  "Medford, United States",
  "Medgyesegyhaza, Hungary",
  "Medway, United States",
  "Medzibrod, Slovakia",
  "Meerut, India",
  "Megeces, Spain",
  "Mehoopany, United States",
  "Mehun sur Yevre, France",
  "Mek'ele, Ethiopia",
  "Melay, France",
  "Melfa, United States",
  "Melilli, Italy",
  "Melnicke Vtelno, Czech Republic",
  "Melvindale, United States",
  "Membury, United Kingdom",
  "Menands, United States",
  "Mendota, United States",
  "Menlo Park, United States",
  "Meols, United Kingdom",
  "Meppen, Germany",
  "Merbelsrod, Germany",
  "Mercato Saraceno, Italy",
  "Merced, United States",
  "Mercedes, United States",
  "Mercer, United States",
  "Mercersburg, United States",
  "Merghem, Egypt",
  "Meriden, United States",
  "Meridian, United States",
  "Merkendorf (Mittelfr), Germany",
  "Merrillville, United States",
  "Merrimack, United States",
  "Merritt Island, United States",
  "Mertert, Luxemburg",
  "Mesice u Prahy, Czech Republic",
  "Metairie, United States",
  "Metapan, El Salvador",
  "Metfield Suffolk, United Kingdom",
  "Methil, United Kingdom",
  "Methoni, Greece",
  "Methuen, United States",
  "Methwold Norfolk IP, United Kingdom",
  "Meyerton, South Africa",
  "Mezzovico, Switzerland",
  "Miami Beach, United States",
  "Michaelnbach, Austria",
  "Michalowice, Poland",
  "Mickleton, United States",
  "Middle Barton, United Kingdom",
  "Middle River, United States",
  "Middleboro, United States",
  "Middlebury, United States",
  "Middlefield, United States",
  "Middlesex, United States",
  "Midland, Canada",
  "Midland City, United States",
  "Midlothian, United States",
  "Miedzyrzecze Gorne, Poland",
  "Miesau, Germany",
  "Mihail Kogalniceanu, Romania",
  "Mikulovice u Jeseniku, Czech Republic",
  "Milanowek, Poland",
  "Milazzo, Italy",
  "Milin, Czech Republic",
  "Millbank, Canada",
  "Millbrook, United States",
  "Millbury, United States",
  "Milledgeville, United States",
  "Millersburg, United States",
  "Millington, United States",
  "MILLS RIVER, United States",
  "Millwood, United States",
  "Milnathort Perth, United Kingdom",
  "Milovice, Czech Republic",
  "Milton Bridge, United Kingdom",
  "Milton Oxon, United Kingdom",
  "Milwaukie, United States",
  "Minato-Ku Tokyo, Japan",
  "Mindelo, Portugal",
  "Minden, United States",
  "Mine Hill, United States",
  "Mineral Wells, United States",
  "Minotola, United States",
  "Mioveni, Romania",
  "Mirabel, Canada",
  "Miramas, France",
  "Miramichi, Canada",
  "Mire, France",
  "Miri, Malaysia",
  "Mirosovice, Czech Republic",
  "Mirror Lake, United States",
  "Misson, France",
  "Missouri City, United States",
  "Mittenwalde, Germany",
  "Mittenaar, Germany",
  "Mnichovice, Czech Republic",
  "Moa London, United Kingdom",
  "Mobeni East, South Africa",
  "Moberly, United States",
  "Mocenok, Slovakia",
  "Modderfontein, South Africa",
  "Modi in-Maccabim-Re ut, Israel",
  "Modlnica, Poland",
  "MODLNICZKA, Poland",
  "Modrica, Bosnia and Herzegovina",
  "Moelndal, Sweden",
  "Moelnlycke, Sweden",
  "Moerke, Denmark",
  "Moersdorf, Germany",
  "Moffat, United Kingdom",
  "Mogi das Cruzes, Brazil",
  "Mogi Guacu, Brazil",
  "Mogilno, Poland",
  "Mohacs, Hungary",
  "Mohali, India",
  "Mohave Valley, United States",
  "Moia, Spain",
  "Moira Northern Ireland, United Kingdom",
  "Moita, Portugal",
  "Mojacar, Spain",
  "Mokena, United States",
  "Mokpo, Korea, South",
  "Mokra, Czech Republic",
  "Mokren, Bulgaria",
  "Mol, Belgium",
  "Molde, Norway",
  "Molena, United States",
  "Molenbeek Saint Jean, Belgium",
  "Monaca, United States",
  "Monaco, France",
  "Moncks Corner, United States",
  "Monclova, Mexico",
  "Monee, United States",
  "Monflanquin, France",
  "Mongstad, Norway",
  "Monor, Hungary",
  "Monrovia, United States",
  "Mont Belvieu, United States",
  "Mont Bouet, Gabon",
  "Mont de Marsan, France",
  "Montagnieu, France",
  "Montauban Ille et Vilaine, France",
  "Montbrio Del Camp, Spain",
  "Montclair, United States",
  "Montealegre Del Castillo, Spain",
  "Montebello, United States",
  "Montech, France",
  "Montego Bay, Jamaica",
  "MONTELLANO, Spain",
  "Montequinto, Spain",
  "Montereau, France",
  "Montereau Faut Yonne, France",
  "Montesilvano, Italy",
  "Montevrain, France",
  "Montgomeryville, United States",
  "Monthey, Switzerland",
  "Montiano, Italy",
  "Montigny sur Loing, France",
  "Montlucon, France",
  "Montmirail, France",
  "Montmorillon, France",
  "Montoro, Spain",
  "Montreal-Est, Canada",
  "Mont-Royal, Canada",
  "Mont-Saint-Hilaire, Canada",
  "Montvale, United States",
  "Monzalbarba, Spain",
  "Monze, Zambia",
  "Moonachie, United States",
  "Moordrecht, Netherlands",
  "Moore Halton, United Kingdom",
  "Moorefield, United States",
  "Moorpark, United States",
  "Moosinning, Germany",
  "Moquegua, Peru",
  "Morahalom, Hungary",
  "Moravsky Zizkov, Czech Republic",
  "Morcott, United Kingdom",
  "Morehead City, United States",
  "Moreni, Romania",
  "Moreno Valley, United States",
  "Moreton Dorset, United Kingdom",
  "Morgan City, United States",
  "Morganville, United States",
  "Morkovice, Czech Republic",
  "Morrilton, United States",
  "Morrison, United States",
  "Mortain, France",
  "Morton, United States",
  "Morton Grove, United States",
  "Moschato, Greece",
  "Mosciska, Poland",
  "Moscow, United States",
  "Moses Lake, United States",
  "Mosina, Poland",
  "Moss Landing, United States",
  "Moss Point, United States",
  "Mossel Bay, South Africa",
  "Most pri Bratislave, Slovakia",
  "Mosty u Jablunkova, Czech Republic",
  "Mouchamps, France",
  "Moudon, Switzerland",
  "Moulton NN, United Kingdom",
  "Moultrie, United States",
  "Moundou, Chad",
  "Moundville, United States",
  "Mount Angel, United States",
  "Mount Bethel, United States",
  "Mount Brydges, Canada",
  "Mount edgecombe, South Africa",
  "Mount Forest, Canada",
  "Mount Holly, United States",
  "Mount Horeb, United States",
  "Mount Jackson, United States",
  "Mount Laurel, United States",
  "Mount Morris, United States",
  "Mount Olive, United States",
  "Mount Pearl, Canada",
  "Mount Prospect, United States",
  "Mount Royal, Canada",
  "Mount Union, United States",
  "Mount Wellington, New Zealand",
  "Mountain Brook, United States",
  "Mountain Home, United States",
  "Mountain Lake Park, United States",
  "Mountain Lakes, United States",
  "Mountain Pass, United States",
  "Mountville, United States",
  "Mours Saint Eusebe, France",
  "Moussey, France",
  "Mozyr, Belarus",
  "Mozzagrogna, Italy",
  "Mpika, Zambia",
  "Mpongwe, Zambia",
  "Muecate, Mozambique",
  "Muehlhausen (Oberpfalz), Germany",
  "Muenster, United States",
  "Muju, Korea, South",
  "Mukarov, Czech Republic",
  "Mukilteo, United States",
  "Mukwonago, United States",
  "MULANJE, Malawi",
  "Muleshoe, United States",
  "Muncie, United States",
  "Munsbach, Luxemburg",
  "Munster, United States",
  "Munster Haut Rhin, France",
  "Murga ALAVA, Spain",
  "Murgenthal, Switzerland",
  "Murtede, Portugal",
  "Muscle Shoals, United States",
  "Muskiz, Spain",
  "Muskogee, United States",
  "Muurame, Finland",
  "Muzaffarnagar, India",
  "Myakka City, United States",
  "Myerstown, United States",
  "Mykanow, Poland",
  "Mykonos, Greece",
  "Myrtle Beach, United States",
  "Mysen, Norway",
  "Myszkow, Poland",
  "Mytholmroyd, United Kingdom",
  "Mytilini, Greece",
  "Mytishchi, Russia",
  "Mzuzu, Malawi",
  "Maarheeze, Netherlands",
  "Maasbracht, Netherlands",
  "Nabeul, Tunisia",
  "Nacaome, Honduras",
  "Nacaroa, Mozambique",
  "Nachterstedt, Germany",
  "Nackenheim, Germany",
  "Nadarzyn, Poland",
  "Nadudvar, Hungary",
  "Nagybajom, Hungary",
  "Nagykoros, Hungary",
  "Naharia, Israel",
  "Nailsworth, United Kingdom",
  "Nalchik, Russia",
  "Nalda, Spain",
  "Nampula, Mozambique",
  "Namyangju, Korea, South",
  "Nanaimo, Canada",
  "Nani Reldi, India",
  "Nanko, Japan",
  "Nanuet, United States",
  "Nanyuki, Kenya",
  "Naperville, United States",
  "Napoleon, United States",
  "Nappanee, United States",
  "Nara, Japan",
  "Naro-Fominsk, Russia",
  "Narpio, Finland",
  "Narragansett, United States",
  "Narvon, United States",
  "Nash, United States",
  "Nassau, Bahamas",
  "Nastola, Finland",
  "Natolin, Poland",
  "Naucalpan, Mexico",
  "Naujoji Akmene, Lithuania",
  "Navan Meath, Ireland",
  "Navarcles, Spain",
  "Navarres, Spain",
  "NAVAS DEL REY, Spain",
  "Navolato, Mexico",
  "Nawalparasi, Nepal",
  "Nawton, United Kingdom",
  "Nazret, Ethiopia",
  "N'Djamena, Chad",
  "Nea Ionia Athens, Greece",
  "Neckarbischofsheim, Germany",
  "Neckarwestheim, Germany",
  "Nedakonice, Czech Republic",
  "Nederland, United States",
  "Nefas Mewcha, Ethiopia",
  "Nefta, Tunisia",
  "Nelahozeves, Czech Republic",
  "Nellis, United States",
  "Neosho, United States",
  "Nepalgunj, Nepal",
  "Nepean, Canada",
  "Nepomuk, Czech Republic",
  "Neptune, United States",
  "Nersingen, Germany",
  "Neslovice, Czech Republic",
  "Nether Heyford, United Kingdom",
  "Netherton Devon, United Kingdom",
  "NETIVOT, Israel",
  "Netolice, Czech Republic",
  "Neuburg a Inn, Germany",
  "Neudorf bei Ilz, Austria",
  "Neuendorf, Switzerland",
  "Neuenkirchen (Lueneburger Heide), Germany",
  "Neuenkirchen (Oldenburg), Germany",
  "Neuhaus, Austria",
  "Neuhofen An Der Krems, Austria",
  "Neumark (Sachsen), Germany",
  "Neunkirchen Am Sand, Germany",
  "Nevada, United States",
  "New Bedford, United States",
  "New Brighton West, South Africa",
  "New Caney, United States",
  "New Carlisle, United States",
  "New Galilee, United States",
  "New Hartford, United States",
  "New Haven, United States",
  "New Holland North Lincolnshire, United Kingdom",
  "New Hope, United States",
  "New Iberia, United States",
  "New Kingstown, United States",
  "New Lenox, United States",
  "New Milford, United States",
  "New Milton, United Kingdom",
  "New Oxford, United States",
  "New Plymouth, New Zealand",
  "New Preston Marble Dale, United States",
  "New Providence, United States",
  "New Rochelle, United States",
  "New Romney, United Kingdom",
  "New Salem, United States",
  "New Stanton, United States",
  "New Windsor, United States",
  "New York Mills, United States",
  "New York North Tyneside, United Kingdom",
  "Newbern, United States",
  "Newberry Springs, United States",
  "Newbold, United Kingdom",
  "Newbridge Gwent, United Kingdom",
  "Newburgh, United States",
  "Newburyport, United States",
  "Newcastle, Australia",
  "Newcastle, South Africa",
  "Newcastle Shrops, United Kingdom",
  "Newell, United States",
  "Newhouse, United Kingdom",
  "Newmarket, Canada",
  "Newmilns, United Kingdom",
  "Newport Beach, United States",
  "Newton Falls, United States",
  "Newton Grange Midlothian, United Kingdom",
  "Newton Grove, United States",
  "Newton Nottinghamshire, United Kingdom",
  "Newtown, United States",
  "Newtown Cunningham, Ireland",
  "Ngaoundere, Cameroon",
  "Nhlangano, Swaziland",
  "Nhon Trach, Vietnam",
  "Niagara On The Lake, Canada",
  "Nicholasville, United States",
  "Nichols, United States",
  "Nicolet, Canada",
  "Nidderau, Germany",
  "Niederurnen, Switzerland",
  "Niemegk, Germany",
  "Niepruszewo, Poland",
  "Niksic, Montenegro",
  "Niles, United States",
  "Ninove, Belgium",
  "Nipomo, United States",
  "Niquinohomo, Nicaragua",
  "Nis, Serbia",
  "Nitanpur, Nepal",
  "Nitro, United States",
  "Nizni Lhoty, Czech Republic",
  "Nizwa, Oman",
  "Nkayi, Congo",
  "Noblesville, United States",
  "Noerre Aaby, Denmark",
  "Nogales, United States",
  "Noida, India",
  "Noidans les Vesoul, France",
  "Noordwijk Binnen, Netherlands",
  "Norco, United States",
  "Nordhalben, Germany",
  "Norken, Germany",
  "Norrahammar, Sweden",
  "Norristown, United States",
  "Norsborg, Sweden",
  "North Acton London, United Kingdom",
  "North Baltimore, United States",
  "North Bay, Canada",
  "North Bend, United States",
  "North Branford, United States",
  "North Canton, United States",
  "North Chicago, United States",
  "North Chili, United States",
  "North East, United States",
  "North End EBS, South Africa",
  "North Franklin, United States",
  "North Haven, United States",
  "North Hollywood, United States",
  "North Lauderdale, United States",
  "North Liberty, United States",
  "North Lima, United States",
  "North Miami, United States",
  "North Miami Beach, United States",
  "North Vancouver, Canada",
  "North Vernon, United States",
  "North Wales, United States",
  "Northampton, United States",
  "Northborough, United States",
  "Northbrook, United States",
  "Northport, United States",
  "Northvale, United States",
  "Northville, United States",
  "Northwood, United States",
  "Norton, United States",
  "Norton, Zimbabwe",
  "Norwalk, United States",
  "Norwell, United States",
  "Norwich, United States",
  "Nosara, Costa Rica",
  "Notre Dame de Gravenchon, France",
  "Nova Ves (u Melnika), Czech Republic",
  "Novazzano, Switzerland",
  "Nove Straseci, Czech Republic",
  "Novi, United States",
  "Novigrad, Croatia",
  "Novomoskovsk, Russia",
  "Novosedlice, Czech Republic",
  "Novy Knin, Czech Republic",
  "Nowa Slupia, Poland",
  "Nowy Sekocin, Poland",
  "Nucice, Czech Republic",
  "Nuenchritz, Germany",
  "Nuerburg, Germany",
  "Nueva Andalucia, Spain",
  "Nuevo Laredo, Mexico",
  "Nukualofa, Tonga",
  "Numansdorp, Netherlands",
  "Nurmes, Finland",
  "Nursling, United Kingdom",
  "Nussdorf ob der Traisen (N), Austria",
  "Nutley, United States",
  "Nyborg, Norway",
  "Nybro, Sweden",
  "Nyirbogdany, Hungary",
  "Nyirtelek, Hungary",
  "Nykoebing Falster, Denmark",
  "Nykoebing Sjaelland, Denmark",
  "Nyon, Switzerland",
  "Nyrsko, Czech Republic",
  "Naantali, Finland",
  "Naarajarvi, Finland",
  "Oadby, United Kingdom",
  "Oak Creek, United States",
  "Oak Hill, United States",
  "Oak Ridge North, United States",
  "Oakamoor, United Kingdom",
  "Oakdale, United States",
  "Oakland Park, United States",
  "Oakland/Ferry St Cfs - Closed 95062, United States",
  "Oaks, United States",
  "Oakwood, United States",
  "Obanos, Spain",
  "Obergurig, Germany",
  "Oberhasli, Switzerland",
  "Oberhoffen-sur-Moder, France",
  "Oberlin, United States",
  "Oberndorf an der Melk, Austria",
  "Obersoechering, Germany",
  "Oberursel (Taunus), Germany",
  "Obninsk, Russia",
  "Oborniki, Poland",
  "Oborniki Slaskie, Poland",
  "Obristvi, Czech Republic",
  "Obrnice, Czech Republic",
  "Obukhov, Ukraine",
  "Ocean City, United States",
  "Ochelata, United States",
  "Oconomowoc, United States",
  "Oconto Falls, United States",
  "Ocotepeque, Honduras",
  "Ocotlan, Mexico",
  "Ocoyoacac, Mexico",
  "Odder, Denmark",
  "Odensbacken, Sweden",
  "Odenton, United States",
  "Odiaxere, Portugal",
  "Odivelas, Portugal",
  "Odolena Voda, Czech Republic",
  "Odysseya Chornomorsk, Ukraine",
  "Oederan, Germany",
  "Oeiras, Portugal",
  "Oelsnitz - Erzgeb, Germany",
  "Oernskoeldsvik, Sweden",
  "Oeselgem, Belgium",
  "Oestersund, Sweden",
  "Oesteraas, Norway",
  "Offutt AFB, United States",
  "Oftering, Austria",
  "Ofunato, Japan",
  "Oglesby, United States",
  "Oglethorpe, United States",
  "Ogre, Latvia",
  "Ogudu, Nigeria",
  "Ohsweken, Canada",
  "Oignies, France",
  "Oilville, United States",
  "Okahumpka, United States",
  "Okeechobee, United States",
  "Oklahoma, United States",
  "Oksvoll, Norway",
  "Olancha, United States",
  "Old Bethpage, United States",
  "Oldcastle Meath, Ireland",
  "Oldsmar, United States",
  "Olecko, Poland",
  "Olesna, Czech Republic",
  "Olesnice (U Ceskych Budejovic), Czech Republic",
  "Olesno, Poland",
  "Olifantsfontein, South Africa",
  "Olivares Sevilla, Spain",
  "Olivehurst, United States",
  "Oliver, Canada",
  "Ollioules, France",
  "Oloumi, Gabon",
  "Oltarzew, Poland",
  "Olten, Switzerland",
  "Olula del Rio, Spain",
  "Olyphant, United States",
  "Omaruru, Namibia",
  "Omerli-Istanbul, Turkey",
  "Ommen, Netherlands",
  "Ondrejov, Czech Republic",
  "Oneida, United States",
  "Oneonta, United States",
  "Onigo, Italy",
  "Onitsha, Nigeria",
  "Oologah, United States",
  "Oostburg, United States",
  "Oosterland, Netherlands",
  "Oostham, Belgium",
  "Opa Locka, United States",
  "Opatija, Croatia",
  "Opatovice - near Brno, Czech Republic",
  "Opatovice nad Labem, Czech Republic",
  "Opatowiec, Poland",
  "Opelika, United States",
  "Opelousas, United States",
  "Opglabbeek, Belgium",
  "Opmeer, Netherlands",
  "Opole Lubelskie, Poland",
  "Oppdal, Norway",
  "OR AKIVA, Israel",
  "Orahovica, Croatia",
  "ORANGE, France",
  "Orangeburg, United States",
  "Orasje, Bosnia and Herzegovina",
  "Orchard Park, United States",
  "Orcutt, United States",
  "Ordis, Spain",
  "Oregon City, United States",
  "Orehoved, Denmark",
  "Orestias, Greece",
  "Orfengo, Italy",
  "ORHANELI, Turkey",
  "Orio, Spain",
  "Oriskany, United States",
  "Orivesi, Finland",
  "Ormaiztegi, Spain",
  "Ormond Beach, United States",
  "Ormonde, South Africa",
  "Ormont, Germany",
  "Ormstown, Canada",
  "Orologio, Greece",
  "Oroville, United States",
  "Orre, Norway",
  "Orsha, Belarus",
  "Orte, Italy",
  "Ortigueira, Spain",
  "Oruro, Bolivia",
  "Osh, Kyrgyzstan",
  "Oshodi-Isolo, Nigeria",
  "Osijek, Croatia",
  "Oslany, Slovakia",
  "Ostbevern, Germany",
  "Osterfeld, Germany",
  "Ostroleka, Poland",
  "Ostrov, Slovakia",
  "Ostrozska Nova Ves, Czech Republic",
  "Oswald Twistle, United Kingdom",
  "Oswego, United States",
  "Otelfingen, Switzerland",
  "Ottery, South Africa",
  "ottobiano, Italy",
  "Ottumwa, United States",
  "Ouallam, Niger",
  "Ouarzazate, Morocco",
  "Ouazzane, Morocco",
  "Oud Vossemeer, Netherlands",
  "Oude Meer, Netherlands",
  "Oudenburg, Belgium",
  "Ouderkerk a d Ijssel, Netherlands",
  "Ouderkerk aan de Amstel, Netherlands",
  "Ougree, Belgium",
  "Outokumpu, Finland",
  "Overton - Hants, United Kingdom",
  "Owego, United States",
  "Owerri, Nigeria",
  "Owings, United States",
  "Owings Mills, United States",
  "Oxelosund, Sweden",
  "Oxhey, United Kingdom",
  "Ozarow Mazowiecki, Poland",
  "Ozd, Hungary",
  "Ozzero, Italy",
  "Pacos de Brandao, Portugal",
  "Paczkow, Poland",
  "Padeswood, United Kingdom",
  "Padim Da Graca, Portugal",
  "Paeroa, New Zealand",
  "Pahrump, United States",
  "Paide, Estonia",
  "Painesville, United States",
  "Palafrugell, Spain",
  "Palaiseau, France",
  "Palapye, Botswana",
  "Paldiski, Estonia",
  "Palestine, United States",
  "Palm Bay, United States",
  "Palm Beach, United States",
  "Palm City, United States",
  "Palm Desert, United States",
  "Palm Harbor, United States",
  "Palma de Cervello, Spain",
  "Palmarejo, Cape Verde Island",
  "Palmeira-Sal, Cape Verde Island",
  "Palmer, United States",
  "Palmerston North, New Zealand",
  "Palmerton, United States",
  "Pampa, United States",
  "Pampuro, Italy",
  "Panacea, United States",
  "Pandrup, Denmark",
  "Panorama City, United States",
  "PANTEGO, United States",
  "Papendrecht, Netherlands",
  "Paracuellos, Spain",
  "Paradise, United States",
  "Paralimni, Cyprus",
  "Paramount, United States",
  "Paramus, United States",
  "Paris, Canada",
  "Park City, United States",
  "Parkstein, Germany",
  "Parlin, United States",
  "Parma, United States",
  "Paros, Greece",
  "Partridge Green, United Kingdom",
  "Pasajes Pasaia, Spain",
  "Pascoag, United States",
  "Pass Christian, United States",
  "Pastende, Latvia",
  "Paulinia, Brazil",
  "Paullo, Italy",
  "Pavo, United States",
  "Pawcatuck, United States",
  "Pawlowice, Poland",
  "Paxton, United States",
  "PEACHTREE CORNERS, United States",
  "Pearl, United States",
  "Pecice, Poland",
  "Pecka, Czech Republic",
  "Peckville, United States",
  "Pecos, United States",
  "Pecs, Hungary",
  "Pedricktown, United States",
  "Pedro Abad, Spain",
  "Peeloff, United States",
  "Peetri, Estonia",
  "Pegalajar, Spain",
  "Pegnitz, Germany",
  "Pekin, United States",
  "Pelasgia, Greece",
  "Pelham, United States",
  "Peligros, Spain",
  "Pell City, United States",
  "Pemba, Mozambique",
  "Pembroke, United Kingdom",
  "Pembroke, United States",
  "Pembroke Pines, United States",
  "PENDERYN CF, United Kingdom",
  "Pendja, Cameroon",
  "Pendleton, United States",
  "Penedes, Spain",
  "Pennfield, Canada",
  "Pennsville, United States",
  "Penryn, United Kingdom",
  "Pensacola, United States",
  "Penticton, Canada",
  "Perales de Tajuna, Spain",
  "Perchtoldsdorf, Austria",
  "Perez Millan, Argentina",
  "Perez Zeledon, Costa Rica",
  "Perivale, United Kingdom",
  "Perkins, United States",
  "Perols, France",
  "Peronnas Ain, France",
  "Perosinho, Portugal",
  "Perseverance, South Africa",
  "Perth, Canada",
  "Peruc, Czech Republic",
  "Peruschtiza, Bulgaria",
  "Peshawar, Pakistan",
  "Petal, United States",
  "Petaluma, United States",
  "Petersberg (Kreis Fulda), Germany",
  "Petersburg, United States",
  "Petit Couronne, France",
  "Petrosino, Italy",
  "Petrvald U Karvine, Czech Republic",
  "Pettenbach, Austria",
  "Pewsey, United Kingdom",
  "Peyrehorade, France",
  "Pfaeffikon, Switzerland",
  "Pflugerville, United States",
  "Pfoerring, Germany",
  "Phenix City, United States",
  "Phetchaburi, Thailand",
  "Philipsburg, Sint Maarten",
  "Piasek(k Pszczyny), Poland",
  "Picanya, Spain",
  "Pickering, Canada",
  "Piedimonte Etneo, Italy",
  "Pierson, United States",
  "Pieszyce, Poland",
  "Pieve di Bono, Italy",
  "Pieve di Sestina, Italy",
  "Pigeon Forge, United States",
  "Pijnacker, Netherlands",
  "Pikkala, Finland",
  "Pili Viotias, Greece",
  "Pilica, Poland",
  "Pilisvorosvar, Hungary",
  "Pilot Mountain, United States",
  "Pine Bluff, United States",
  "Pine Brook, United States",
  "Pineda de Mar, Spain",
  "Pinelands Cpt, South Africa",
  "Pinetops, United States",
  "Piney River, United States",
  "Pinki, Latvia",
  "Pinsk, Belarus",
  "Pirdop, Bulgaria",
  "Pirkkala, Finland",
  "Pirot, Serbia",
  "Pirto, Hungary",
  "Pisarzowice, Poland",
  "Pischelsdorf in Steiermark, Austria",
  "Pisecna U Jeseniku, Czech Republic",
  "Piteaa, Sweden",
  "Pitlochry, United Kingdom",
  "Pitman, United States",
  "Pitt Meadows, Canada",
  "Pittem, Belgium",
  "Pittsburg, United States",
  "Pittston, United States",
  "Piwniczna Zdroj, Poland",
  "Plaisance Du Touch, France",
  "Plantation, United States",
  "Plantsville, United States",
  "Platteville, United States",
  "Plattsburgh, United States",
  "Pleasant Grove, United States",
  "Pleasant View, United States",
  "Pleasanton, United States",
  "Pleine Selve Gironde, France",
  "Plescop, France",
  "Plessisville, Canada",
  "Pleszew, Poland",
  "Pleudihen sur Rance, France",
  "Pleugueneuc, France",
  "Pleumeleuc, France",
  "Plouescat, France",
  "Plumeliau, France",
  "Plumpton Green East Sussex, United Kingdom",
  "Plunge, Lithuania",
  "Pobra do Caraminal, Spain",
  "Pocheon, Korea, South",
  "Pocono Manor, United States",
  "Poddebice, Poland",
  "Podegrodzie, Poland",
  "Podgorica, Montenegro",
  "Podturen, Slovakia",
  "Poechlarn, Austria",
  "Poernbach, Germany",
  "Poggio A Caiano, Italy",
  "Poinconnet, France",
  "Point Comfort, United States",
  "Point Tupper, Canada",
  "Pointe-Aux-Trembles, Canada",
  "Pokhara, Nepal",
  "Pola de Siero, Spain",
  "Polatli, Turkey",
  "Polebrook Northamtonshire PE, United Kingdom",
  "Polesovice, Czech Republic",
  "Polgar, Hungary",
  "Police Nad Metuji, Czech Republic",
  "Poling West Sussex, United Kingdom",
  "Polis Chrisohou, Cyprus",
  "Polk City, United States",
  "Polo, United States",
  "Polotsk, Belarus",
  "Polpenazze del Garda, Italy",
  "Pomfret Center, United States",
  "Pompton Plains, United States",
  "Ponchatoula, United States",
  "Pondicherry, India",
  "Poniatowa, Poland",
  "Poniec, Poland",
  "Ponikla, Czech Republic",
  "Pons, France",
  "Pont de Buis les Quimerch, France",
  "Pont du Chateau, France",
  "Ponthierry, France",
  "Pontllanfraith Caerphilly, United Kingdom",
  "Poortvliet, Netherlands",
  "Popow, Poland",
  "Porcelette, France",
  "Porcuna, Spain",
  "Poreba - Zawiercie, Poland",
  "Porec, Croatia",
  "Porlezza, Italy",
  "Porrentruy, Switzerland",
  "Port Arthur, United States",
  "Port Colborne, Canada",
  "Port Coquitlam, Canada",
  "Port Elizabeth, United States",
  "Port Fourchon, United States",
  "Port Harcourt, Nigeria",
  "Port Jervis, United States",
  "Port Manatee, United States",
  "Port Murray, United States",
  "Port Orange, United States",
  "Port Reading, United States",
  "Port Wentworth, United States",
  "Portalegre, Portugal",
  "Portarlington Laois, Ireland",
  "Portchester Hampshire, United Kingdom",
  "Portell de Morella, Spain",
  "Porthmadog, United Kingdom",
  "Portimao, Portugal",
  "Portishead, United Kingdom",
  "Porto Empedocle, Italy",
  "Porto Novo, Benin",
  "Porto Salvo, Portugal",
  "Portree town, United Kingdom",
  "Portslade, United Kingdom",
  "Poseyville, United States",
  "Postoloprty, Czech Republic",
  "Postrizin, Czech Republic",
  "Potosi, Bolivia",
  "Potries, Spain",
  "Pottstown, United States",
  "Pottsville, United States",
  "Potvorice, Slovakia",
  "Poughkeepsie, United States",
  "Poupry, France",
  "Povazsky Chlmec, Slovakia",
  "Povoa de Varzim, Portugal",
  "Powder Springs, United States",
  "Powidz, Poland",
  "Poynton Cheshire, United Kingdom",
  "Pozarevac, Serbia",
  "Pozzallo, Italy",
  "Prachovice, Czech Republic",
  "Praestoe, Denmark",
  "Praia da Vitoria, Portugal",
  "Pratteln, Switzerland",
  "Prebold, Slovenia",
  "Predmerice nad Labem, Czech Republic",
  "Predosa, Italy",
  "Prerov nad Labem, Czech Republic",
  "Prescott, Canada",
  "Prescott, United States",
  "Prestatyn, United Kingdom",
  "Presteigne, United Kingdom",
  "Prestwick airport, United Kingdom",
  "Prestwick South Ayrshire, United Kingdom",
  "Preveza, Greece",
  "Prichard, United States",
  "Priluky, Ukraine",
  "Prince Frederick, United States",
  "Prince George, United States",
  "Princes Risborough, United Kingdom",
  "Prineville, United States",
  "Prisovice, Czech Republic",
  "Pristina, Serbia",
  "Prosenice, Czech Republic",
  "Prospect, United States",
  "Protaras, Cyprus",
  "Providence, United States",
  "Providenciales, Turks and Caicos",
  "Prudnik, Poland",
  "Pruszcz Gdanski, Poland",
  "Pryor, United States",
  "Przygodzice, Poland",
  "Przysucha, Poland",
  "Przyszowice, Poland",
  "Psachna, Greece",
  "Psary, Czech Republic",
  "Psary K Bedzin, Poland",
  "Psary K Trzebnica, Poland",
  "Ptuj, Slovenia",
  "Puchiathipedu, India",
  "Pudahuel, Chile",
  "Pueblo, United States",
  "Puerto Castilla, Honduras",
  "Puerto de la Cruz, Spain",
  "Puerto de la Torre, Spain",
  "Puerto Morelos, Mexico",
  "Puerto Vallarta, Mexico",
  "Puglianello, Italy",
  "Puigreig, Spain",
  "Puiseux Pontoise, France",
  "Pulversheim, France",
  "Puslinch, Canada",
  "Putnam, United States",
  "Pyharanta, Finland",
  "Pyramid, South Africa",
  "Pyrbaum, Germany",
  "Pyrgos Tillirias, Cyprus",
  "Pyzdry, Poland",
  "Paal, Belgium",
  "Qiryat Gat, Israel",
  "Quainton, United Kingdom",
  "Quanzhou, China",
  "Quaregna, Italy",
  "Quarteira, Portugal",
  "Quarto Inferiore, Italy",
  "Quartzsite, United States",
  "Queensbury, United States",
  "Queluz, Portugal",
  "Quepos, Costa Rica",
  "Quievrechain, France",
  "Quilicura, Chile",
  "Quillota, Chile",
  "Quimistan, Honduras",
  "Quinta do Anjo, Portugal",
  "Quzhou, China",
  "Rabade, Spain",
  "Rabcice, Slovakia",
  "Rades, Tunisia",
  "Radford, United States",
  "Radomlje, Slovenia",
  "Radvanice, Czech Republic",
  "Raeford, United States",
  "Rafal, Spain",
  "Rahway, United States",
  "Rail Ramp Leixoes, Portugal",
  "Rain (Lech), Germany",
  "Rain (Niederbayern), Germany",
  "Raisio, Finland",
  "Rajkot, India",
  "Rakoniewice, Poland",
  "Rakvice, Czech Republic",
  "Ralsko, Czech Republic",
  "Ramsden Heath Essex, United Kingdom",
  "Ramseur, United States",
  "Randan, France",
  "Randers Soe, Denmark",
  "Randvaal, South Africa",
  "Rantoul, United States",
  "Rapotice, Czech Republic",
  "Rapotin, Czech Republic",
  "Ras Laffan, Qatar",
  "Rauenberg, Germany",
  "Raufoss, Norway",
  "Rauna, Latvia",
  "Ravenswood, United States",
  "Rawcliffe Humberside, United Kingdom",
  "Rawdon, Canada",
  "Raymondville, United States",
  "Raynham, United States",
  "Razlog, Bulgaria",
  "Reamstown, United States",
  "Reau, France",
  "Rechlin, Germany",
  "Recke, Germany",
  "Red Bay, United States",
  "Red Oak, United States",
  "Redcliff, Zimbabwe",
  "Reddick, United States",
  "Redding, United States",
  "Redmond, United States",
  "Redwitz (a d Rodach), Germany",
  "Redwood City, United States",
  "Reedville, United States",
  "Rees, Germany",
  "Rehlingen-Siersburg, Germany",
  "REHOVOT, Israel",
  "Reinholds, United States",
  "REKLAW, United States",
  "Remchingen, Germany",
  "Remenham Hill, United Kingdom",
  "Remseck (am Neckar), Germany",
  "Renaico, Chile",
  "Renescure, France",
  "Rennerod, Germany",
  "Rensselaer, United States",
  "Repov, Czech Republic",
  "Reston, United States",
  "REUNION, South Africa",
  "Revigny sur Ornan, France",
  "Revnice, Czech Republic",
  "Reynoldsburg, United States",
  "Rheems, United States",
  "Rheineck, Switzerland",
  "Rhode Island, United States",
  "Rhoon, Netherlands",
  "Rhos Fawr, United Kingdom",
  "Ribchester, United Kingdom",
  "Riccall, United Kingdom",
  "Richards Bay, South Africa",
  "Richburg, United States",
  "Riche Terre, Mauritius",
  "Richfield, United States",
  "Richford, United States",
  "Richland Hills, United States",
  "Richlands, United States",
  "Richmond Hill, Canada",
  "Richmond Hill, United States",
  "Richmond South, Canada",
  "Ridgefield, United States",
  "Ridgeville, United States",
  "Ridgmont, United Kingdom",
  "Riec sur Belon, France",
  "Riederich, Germany",
  "Riegelwood, United States",
  "Riehen, Switzerland",
  "Rimavska Bana, Slovakia",
  "Rimavske Zaluzany, Slovakia",
  "Rimouski, Canada",
  "Rincon, United States",
  "Rincon de la Victoria, Spain",
  "Riner, United States",
  "Ringtown, United States",
  "Ringwood, United States",
  "RIO CLARO, Brazil",
  "Rio Grande Tierra del Fuego, Argentina",
  "Rion des Landes, France",
  "RipleyHampshire, United Kingdom",
  "Ripoll, Spain",
  "Ripplemead, United States",
  "Ripponden, United Kingdom",
  "Ris Orangis, France",
  "Risskov, Denmark",
  "Ritschenhausen, Germany",
  "Riva di Chieri, Italy",
  "Rivas, Nicaragua",
  "Rive de Gier, France",
  "Riverbank, United States",
  "Riverdale, United States",
  "Riverhead, United States",
  "Riverton, United States",
  "Riverview, United States",
  "Riviere Beaudette, Canada",
  "Riviere-Du-Loup, Canada",
  "Rize, Turkey",
  "Rizhao, China",
  "Rizziconi, Italy",
  "Roade, United Kingdom",
  "Robbinsville, United States",
  "Robertville, South Africa",
  "Robesonia, United States",
  "Roccasecca, Italy",
  "Rocester, United Kingdom",
  "Rochefort, Belgium",
  "Rock Falls, United States",
  "Rockaway, United States",
  "ROCKDALE, United States",
  "Rockmart, United States",
  "Rockwell, United States",
  "Rockwood, United States",
  "Rocky Point, United States",
  "Rodewisch, Germany",
  "RODMAN, Panama",
  "Rodos, Greece",
  "Roedovre, Denmark",
  "Roefors, Sweden",
  "Roeslau, Germany",
  "Rogerstone, United Kingdom",
  "Rogne, Norway",
  "Rogow Sobocki, Poland",
  "Rogowiec, Poland",
  "Rojales, Spain",
  "Rolling Meadows, United States",
  "Roman, Romania",
  "Romeo, United States",
  "Romiley, United Kingdom",
  "Ronkonkoma, United States",
  "Ronks, United States",
  "Roodeplaat, South Africa",
  "Roquetas de Mar, Spain",
  "Rose Hill, United States",
  "Roseau, Dominica",
  "Rosebank, South Africa",
  "Rosedale, Canada",
  "Rosettenville, South Africa",
  "Rosharon, United States",
  "Rosice u Brna, Czech Republic",
  "Rosieres pres Troyes, France",
  "Roskilde, Denmark",
  "Rossford, United States",
  "Rosso, Mauritania",
  "Rossville, United States",
  "Rostov-na-Donu, Russia",
  "Roswell, United States",
  "Rothwell Lincolnshire LN, United Kingdom",
  "Rothwell Yorks, United Kingdom",
  "Rotkreuz, Switzerland",
  "Rouiba, Algeria",
  "Round Lake, United States",
  "Rouses Point, United States",
  "Rovasenda, Italy",
  "Rowley, United States",
  "Rowley Regis, United Kingdom",
  "Roxwell, United Kingdom",
  "Royal Tunbridge Wells, United Kingdom",
  "Roydon Essex CM, United Kingdom",
  "Roydon Norfolk, United Kingdom",
  "Roye-sur-Matz, France",
  "Royse City, United States",
  "Rozan, Poland",
  "Rozenburg, Netherlands",
  "Roztoky, Czech Republic",
  "Rubbestadneset, Norway",
  "RUBERY WEST MIDLANDS, United Kingdom",
  "Rubonia, United States",
  "Rucphen, Netherlands",
  "Rudawa, Poland",
  "Rudensk, Belarus",
  "Rudersdorf, Austria",
  "Rudnik, Czech Republic",
  "Rudrapur, India",
  "Rueckholz, Germany",
  "Ruederswil Zollbrueck, Switzerland",
  "Ruesselsheim, Germany",
  "Rungsted Kyst, Denmark",
  "Rural Retreat, United States",
  "Rushville, United States",
  "Russia, United States",
  "Rustavi, Georgia",
  "Rustenburg, South Africa",
  "Ruston, United States",
  "Rutherfordton, United States",
  "Rutland, United States",
  "Ruwi, Oman",
  "Ruza, Russia",
  "Rychvald, Czech Republic",
  "Ryd, Sweden",
  "Rymanow, Poland",
  "Rzeczyca, Poland",
  "Raadal, Norway",
  "Raahe, Finland",
  "S Agustin Almeria, Spain",
  "S Andreu de Llaveneres, Spain",
  "S Antoni Vilamajor, Spain",
  "S Cibrao das Vinas, Spain",
  "S Feliu Sasserra, Spain",
  "S Jaume dels Domenys, Spain",
  "S Joao de Areias, Portugal",
  "S Llorenc de Savall, Spain",
  "S Marti Sarroca, Spain",
  "S Martin del Tesorillo, Spain",
  "S Miguel de Abona, Spain",
  "S Pere Molanta, Spain",
  "S Salvador de Guardiola, Spain",
  "Sabden Lancashire, United Kingdom",
  "Sablons, France",
  "Sacacoyo, El Salvador",
  "Sacavem, Portugal",
  "Sachsenheim (Wuertt), Germany",
  "Sackville, Canada",
  "Saddle Brook, United States",
  "Saeffle, Sweden",
  "Saegertown, United States",
  "Saetre, Norway",
  "Safenwil, Switzerland",
  "Saginaw, United States",
  "Sahy, Slovakia",
  "Saint Agapit, Canada",
  "Saint Agreve, France",
  "Saint Aignan, France",
  "Saint Alban Leysse, France",
  "Saint Albans, United States",
  "Saint Alphonse de Granby, Canada",
  "Saint Amand Montrond, France",
  "Saint Andre de l'Epine, France",
  "Saint Andre des Eaux, France",
  "Saint Aubin d'Arquenay, France",
  "Saint Augustin, Canada",
  "Saint Augustine, United States",
  "Saint Ave, France",
  "Saint Avertin, France",
  "Saint bonnet les oules, France",
  "Saint Brisson sur Loire, France",
  "Saint Bruno de Montarville, Canada",
  "Saint Cloud, United States",
  "Saint Columb North Cornwall, United Kingdom",
  "Saint Croix Falls, United States",
  "Saint Cyr Du Ronceray, France",
  "Saint David's, Canada",
  "Saint Denis la Chevasse, France",
  "Saint Esprit, Canada",
  "Saint Esteve, France",
  "Saint Etienne de Montluc, France",
  "Saint etienne des oullieres, France",
  "Saint Fargeau Ponthierry, France",
  "Saint Francois Xavier De Brompton, Canada",
  "Saint Fulgent, France",
  "Saint George, Canada",
  "Saint Germain, Canada",
  "Saint Germain des Fosses, France",
  "Saint Germain Laprade, France",
  "Saint Gilles Ille et Vilaine, France",
  "Saint Hippolyte, France",
  "Saint Jacques de la Lande, France",
  "Saint James, United States",
  "Saint Jean de Soudain, France",
  "Saint Jean La Bussiere, France",
  "Saint Jean sur Vilaine, France",
  "Saint John, Canada",
  "Saint Johns, United States",
  "Saint Just Malmont, France",
  "Saint Laurent Blangy, France",
  "Saint Laurent de Chamousset, France",
  "Saint Louis de Montferrand, France",
  "Saint Macaire en Mauges, France",
  "Saint Mard, France",
  "Saint Mars la Jaille, France",
  "Saint Martin de Crau, France",
  "Saint Martinville, United States",
  "Saint Matthews, United States",
  "Saint Michel Chef Chef, France",
  "Saint Ouen, France",
  "Saint Perdon, France",
  "Saint Pey D Armens, France",
  "Saint Philbert de Grand-Lieu, France",
  "Saint- Pierre, Mauritius",
  "Saint Pierre en Faucigny, France",
  "Saint Pierre Montlimart, France",
  "Saint Quentin sur Isere, France",
  "Saint Remy, France",
  "Saint Remy de Provence, France",
  "Saint Roch de l'Achigan, Canada",
  "Saint Romain de Jalionas, France",
  "Saint Thomas, Canada",
  "Saint Thomas, United States",
  "Saint Thonan, France",
  "Saint Valerien, Canada",
  "Saint Valerien Yonne, France",
  "Saint Vincent du Boulay, France",
  "Saint Vit, France",
  "Saint Witz, France",
  "Saint Yrieix la Perche, France",
  "Saint Yrieix sur Charente, France",
  "Saint-Augustin-De-Desmaures, Canada",
  "Saint-Bruno, Canada",
  "Saint-Constant, Canada",
  "Saint-Denis-en-Val, France",
  "Sainte Elisabeth, Canada",
  "Sainte Gemme la Plaine, France",
  "Sainte Helene du Lac, France",
  "Sainte Helene Sur Isere, France",
  "Sainte-Brigitte-des-Saults, Canada",
  "Sainte-Catherine, Canada",
  "Sainte-Cecile-de-Milton, Canada",
  "Sainte-Clotilde-de-Chateauguay, Canada",
  "Saintes, Belgium",
  "Sainte-Therese, Canada",
  "Saint-Hubert, Canada",
  "Saint-Jacques, Canada",
  "Saint-Jerome, Canada",
  "Saint-Joseph-de-Beauce, Canada",
  "Saint-Laurent-de-Terregatte, France",
  "Saint-Louis, Senegal",
  "Saint-Martin, Canada",
  "Saint-Mathieu-De-Laprairie, Canada",
  "SAINT-MICHEL, Canada",
  "Saint-Ouen-la-Rouerie, France",
  "Saint-Remi, Canada",
  "Saint-Salvy-de-la-Balme, France",
  "Saint-Victurnien, France",
  "Sakai Osaka, Japan",
  "Sakakah, Saudi Arabia",
  "Sala, Sweden",
  "Salaberry-De-Valleyfield, Canada",
  "Salado, United States",
  "Salceda de Caselas, Spain",
  "Saldanha, South Africa",
  "Saldus, Latvia",
  "Salem, United States",
  "Salinas, United States",
  "Salinas Alicante, Spain",
  "Salobrena, Spain",
  "Saltville, United States",
  "Salvaterra de Magos, Portugal",
  "Salzhausen, Germany",
  "Salzweg, Germany",
  "Samano, Spain",
  "Samara, Russia",
  "SAMBALPUR, India",
  "Samorin, Slovakia",
  "Samos Samos, Greece",
  "SAMSON, United States",
  "San Antonio de Guerra, Dominican Republic",
  "San Benito, United States",
  "San Bernardo, Chile",
  "San Bruno, United States",
  "San Clemente, United States",
  "San Fernando, United States",
  "San Filippo del Mela, Italy",
  "San Francisco, United States",
  "San Francisco de los Romos, Mexico",
  "San Gregorio, Chile",
  "San Jose, United States",
  "San Jose Chiapa Puebla, Mexico",
  "San Juan, United States",
  "San Juan Sacatepequez, Guatemala",
  "San Lorenzo, United States",
  "San Lucas, Guatemala",
  "San Luis Obispo, United States",
  "San Marcos, United States",
  "San Marino, San Marino",
  "San Marzano Oliveto, Italy",
  "San Mateo Atenco, Mexico",
  "San Miguel, El Salvador",
  "San Miguel el Alto, Mexico",
  "San Pancrazio, Italy",
  "San Pedro, United States",
  "San Vito Verona, Italy",
  "Sande, Germany",
  "Sandiacre, United Kingdom",
  "Sandorfalva, Hungary",
  "Sandringham Norfolk PE, United Kingdom",
  "Sanger, United States",
  "Sangerhausen, Germany",
  "Sankt Georgen am Ybbsfelde, Austria",
  "Sankt Johann im Pongau, Austria",
  "Sanlucar la Mayor, Spain",
  "Sannois, France",
  "Sant Llorenc De Morunys, Spain",
  "Santa Ana, United States",
  "Santa Barbara, United States",
  "Santa Clara, United States",
  "Santa Cristina E Bissone, Italy",
  "Santa Croce Camerina, Italy",
  "Santa Cruz, Bolivia",
  "Santa Cruz, Costa Rica",
  "Santa Cruz, United States",
  "Santa Fe, Panama",
  "Santa Fe, United States",
  "SANTA MARIA, United States",
  "Santa Maria de Huachipa, Peru",
  "Santa Maria de Lamas, Portugal",
  "Santa Maria la Real de Nieva, Spain",
  "Santa Monica, United States",
  "Santa Paula, United States",
  "Santa Rosa Beach, United States",
  "Santa Rosa de Copan, Honduras",
  "Santa Teresa, United States",
  "Santafe, Spain",
  "Santiago Sacatepequez, Guatemala",
  "Santiago Tianguistenco, Mexico",
  "Santisteban del Puerto, Spain",
  "Santo Amaro De Oeiras, Portugal",
  "Santo Antao do Tojal, Portugal",
  "Santo Domingo Norte, Dominican Republic",
  "Santo Domingo Oeste, Dominican Republic",
  "SANTO TOMAS CHONTALES, Nicaragua",
  "Sao Domingos de Rana, Portugal",
  "Sao Pedro da Cova, Portugal",
  "Sao Teotonio, Portugal",
  "Sarasota, United States",
  "Saratov, Russia",
  "Sarbogard, Hungary",
  "Sarceaux, France",
  "Sardice, Czech Republic",
  "Sardis, United States",
  "Sarnia, Canada",
  "Sarospatak, Hungary",
  "Sars et Rosieres, France",
  "Sasebo, Japan",
  "Sassnitz, Germany",
  "Sastamala, Finland",
  "Sathonay Camp, France",
  "Satigny, Switzerland",
  "Sauda, Norway",
  "Saue, Estonia",
  "Saugus, United States",
  "Sauk Village, United States",
  "Saukville, United States",
  "Saul Gloucestershire, United Kingdom",
  "Saulcy sur Meurthe, France",
  "Sault Sainte Marie, United States",
  "Saulxures Sur Moselotte, France",
  "Saundersfoot Pembrokeshire, United Kingdom",
  "Sauriesi, Latvia",
  "Savage, United States",
  "Savignac de L'Isle, France",
  "Savigny sur Clairis, France",
  "Saxonburg, United States",
  "Sayama, Japan",
  "Scarmagno, Italy",
  "Scey sur Saone et Saint Albin, France",
  "Scharnstein, Austria",
  "Scharsterbrug, Netherlands",
  "Schemmerhofen, Germany",
  "Schierling, Germany",
  "Schimmert, Netherlands",
  "Schinnen, Netherlands",
  "Schinznach Bad, Switzerland",
  "Schluesselfeld, Germany",
  "Schoenheide (Erzgeb), Germany",
  "Schofield Barracks, United States",
  "SCHUEPBACH, Switzerland",
  "Schwanenstadt, Austria",
  "Schwarzenbach, Switzerland",
  "Schwenksville, United States",
  "Schaanwald, Liechtenstein",
  "Scicli, Italy",
  "Scotia, United States",
  "Scotlandwell Perth and Kinross, United Kingdom",
  "Scottsboro, United States",
  "Scottsburg, United States",
  "Scottsville, United States",
  "Sea Island, United States",
  "Seabrook, United States",
  "SEAFORD, United Kingdom",
  "Seagoville, United States",
  "Searcy, United States",
  "Searsport, United States",
  "Seaton Delaval, United Kingdom",
  "Sebastian, United States",
  "SEBENZA, South Africa",
  "Seckach, Germany",
  "Secli, Italy",
  "Sedalia, United States",
  "Sedlec Prcice, Czech Republic",
  "Seeb, Oman",
  "Seekonk, United States",
  "Seewen, Switzerland",
  "Seffner, United States",
  "Sefton, Australia",
  "Seibu, Japan",
  "Selbyville, United States",
  "Selgua, Spain",
  "Sellia Marina, Italy",
  "Selly Oak, United Kingdom",
  "Selston, United Kingdom",
  "Sem, Norway",
  "Seminole, United States",
  "Semmes, United States",
  "Semsales, Switzerland",
  "Senaki, Georgia",
  "Senatobia, United States",
  "Sencur, Slovenia",
  "Seneca, United States",
  "Seneca Falls, United States",
  "Seongnam, Korea, South",
  "Seoul, Korea, South",
  "Sequedin, France",
  "Serenje, Zambia",
  "Serezin du Rhone, France",
  "Serian, Malaysia",
  "Sermaize les Bains, France",
  "SEVARE, Mali",
  "SEVAZ, Switzerland",
  "Sevremoine, France",
  "Sewell, United States",
  "Seyssins, France",
  "Sezemice, Czech Republic",
  "Shady Grove, United States",
  "Shafter, United States",
  "Shakas Rock, South Africa",
  "Shakopee, United States",
  "Shamli, India",
  "Shamshabad, India",
  "Shaoxing, China",
  "Sharon Springs, United States",
  "Shawnee, United States",
  "Sheboygan, United States",
  "Sheffield Village, United States",
  "Shefford, United Kingdom",
  "shelby, United States",
  "Shelby Township, United States",
  "Sheldon, United States",
  "Sherman, United States",
  "Sherrill, United States",
  "Sherston, United Kingdom",
  "Sherwood, United States",
  "Shibushi, Japan",
  "Shimonoseki, Japan",
  "Shinkiba, Japan",
  "Shipley, United Kingdom",
  "Shippensburg, United States",
  "Shippenville, United States",
  "Shipshewana, United States",
  "Shipston on Stour, United Kingdom",
  "Shirvan, Azerbaijan",
  "Shoemakersville, United States",
  "Shoreham, United States",
  "Shorewood, United States",
  "Shotley Suffolk, United Kingdom",
  "Shreve, United States",
  "Shrewsbury, United States",
  "Shunde, China",
  "Sialkot, Pakistan",
  "Sibenik, Croatia",
  "Sidamon, Spain",
  "SIDDHARTHANAGAR, Nepal",
  "Sidi Kacem, Morocco",
  "Sidmouth, United Kingdom",
  "Sidney, United States",
  "Sidwell East, South Africa",
  "Siechnice, Poland",
  "Siek (Kr Stormarn), Germany",
  "Siennica Rozana, Poland",
  "Sierre, Switzerland",
  "Sighisoara, Romania",
  "Signakhi, Georgia",
  "Signal Hill, United States",
  "Siguiri, Guinea",
  "Siikainen, Finland",
  "Siirt, Turkey",
  "Silver Grove, United States",
  "Silver Lake, United States",
  "Silver Springs, United States",
  "Silverton, United States",
  "Simi Valley, United States",
  "Simmozheim, Germany",
  "Simrishamn, Sweden",
  "Sin le Noble, France",
  "Sindi, Estonia",
  "Sinkor, Liberia",
  "Sint Gillis Waas, Belgium",
  "Sint Jansteen, Netherlands",
  "Sint Maartensdijk, Netherlands",
  "Sint-Pieters-Woluwe, Belgium",
  "Siofok, Hungary",
  "Sion, Switzerland",
  "Sipoo, Finland",
  "Siracusa, Italy",
  "Sirvintos, Lithuania",
  "Sisak, Croatia",
  "Sisters, United States",
  "Sitges, Spain",
  "Sitterdorf, Switzerland",
  "Six Fours les Plages, France",
  "Skaenninge, Sweden",
  "Skaerbaek, Denmark",
  "Skaerholmen, Sweden",
  "Skala, Greece",
  "Skalica, Slovakia",
  "Skene, Sweden",
  "Ski, Norway",
  "Skibbereen Co Cork, Ireland",
  "Skjetten, Norway",
  "Skoedstrup, Denmark",
  "Skokie, United States",
  "Skovlunde, Denmark",
  "Skowhegan, United States",
  "Skreia, Norway",
  "Skrljevo, Croatia",
  "Skurup, Sweden",
  "Skvorec, Czech Republic",
  "Skwierzyna, Poland",
  "Slaithwaite, United Kingdom",
  "Slater, United States",
  "Slatersville, United States",
  "Slemmestad, Norway",
  "Slinger, United States",
  "Slobozia, Romania",
  "Slobozia Sucevei, Romania",
  "Smarde, Latvia",
  "Smederevo, Serbia",
  "Smetowo Graniczne, Poland",
  "Smiltene, Latvia",
  "Smithton, United States",
  "Smithtown, United States",
  "Smithville, Canada",
  "Smoerum, Denmark",
  "Smolensk, Russia",
  "Smrzovka, Czech Republic",
  "Snedsted, Denmark",
  "Snellville, United States",
  "Snoqualmie, United States",
  "Snover, United States",
  "Snyder, United States",
  "Sobotka, Czech Republic",
  "Sobotka, Poland",
  "Soddy Daisy, United States",
  "Soeborg, Denmark",
  "Soederbaerke, Sweden",
  "Soederkoeping, Sweden",
  "Soefteland, Norway",
  "Soell, Austria",
  "Soesdala, Sweden",
  "Sokolnice, Czech Republic",
  "Sokolow, Poland",
  "Sola, Norway",
  "Solan de Cabras Cuenca, Spain",
  "Soliers, France",
  "Sollentuna, Sweden",
  "Solna, Sweden",
  "Solnice, Czech Republic",
  "Solomon, United States",
  "Solomons, United States",
  "Soltau, Germany",
  "Solvay, United States",
  "Solwezi, Zambia",
  "Solymar, Hungary",
  "Somers, United States",
  "Somerset East, South Africa",
  "Somerset West, South Africa",
  "Someru, Estonia",
  "Somerville, United States",
  "Sondalo, Italy",
  "Sonoma, United States",
  "Sonzay, France",
  "Sopot, Bulgaria",
  "Sorel, Canada",
  "Sorel-Tracy, Canada",
  "Soskut, Hungary",
  "Sotiello, Spain",
  "Sotkamo, Finland",
  "Sotogrande, Spain",
  "Soual, France",
  "Souassi, Tunisia",
  "Soudan, France",
  "Souffelweyersheim, France",
  "South Amboy, United States",
  "South Beloit, United States",
  "South Boston, United States",
  "South Brunswick, United States",
  "South Cairo, United States",
  "South Cerney Gloucestershire, United Kingdom",
  "South Coffeyville, United States",
  "South Easton, United States",
  "South Elgin, United States",
  "South Elmsall, United Kingdom",
  "South Gate, United States",
  "South Hadley, United States",
  "South Hill, United States",
  "South Holland, United States",
  "South Houston, United States",
  "South Kearny, United States",
  "South Lee, United States",
  "South Padre Island, United States",
  "South Petherton, United Kingdom",
  "South Point, United States",
  "South San Francisco, United States",
  "South Warnborough, United Kingdom",
  "South Woodford, United Kingdom",
  "Southampton, United States",
  "Southend Argyll and Bute, United Kingdom",
  "Southington, United States",
  "Southlake, United States",
  "Southwark London, United Kingdom",
  "SouthwickWest Sussex, United Kingdom",
  "Sovata, Romania",
  "Soyaux, France",
  "Soyo, Angola",
  "Soyons, France",
  "Spanaway, United States",
  "Spangereid, Norway",
  "Sparrows Point, United States",
  "Spartan, South Africa",
  "Spata, Greece",
  "Spencer, United States",
  "Spiegelberg, Germany",
  "Spiere, Belgium",
  "Spisske Podhradie, Slovakia",
  "Spissky Hrusov, Slovakia",
  "Spokane Valley, United States",
  "Spondon, United Kingdom",
  "Spring, United States",
  "Spring City, United States",
  "Spring Green, United States",
  "Spring Hill, United States",
  "Spring Hope, United States",
  "Springburn, United Kingdom",
  "Springfield, South Africa",
  "Sprotbrough, United Kingdom",
  "Spydeberg, Norway",
  "Spytihnev, Czech Republic",
  "Srpenica, Slovenia",
  "St Agnes, United Kingdom",
  "St Boswells, United Kingdom",
  "St Catharines, Canada",
  "St Clears Carmarthenshire, United Kingdom",
  "St Columb Major Cornwall, United Kingdom",
  "ST EUGENE, Canada",
  "St Georges, Grenada",
  "St Jacobs, Canada",
  "St Johns, Antigua and Barbuda",
  "St Marys, Canada",
  "St Stephen, United Kingdom",
  "Sta Coloma Cervello, Spain",
  "Sta Cruz de Bezana, Spain",
  "Sta Eugenia de Berga, Spain",
  "Sta Eulalia Ronsana, Spain",
  "Sta Fe, Spain",
  "Staden, Belgium",
  "Stadland, Germany",
  "Staelldalen, Sweden",
  "Stamford, United States",
  "Standerton, South Africa",
  "Standlake, United Kingdom",
  "Stanford, United States",
  "Stankovice, Czech Republic",
  "Stapleford Abbotts Essex, United Kingdom",
  "Staplehurst, United Kingdom",
  "Staples, Canada",
  "Star City, United States",
  "Stare Mesto - Moravska Trebova, Czech Republic",
  "Starr, United States",
  "Stary Sacz, Poland",
  "Staudt, Germany",
  "Staunton, United States",
  "Staveley Yorks, United Kingdom",
  "Stavenhagen, Germany",
  "Staverton Gloucestershire, United Kingdom",
  "Stawiszyn, Poland",
  "Stayner, Canada",
  "Staythorpe Nottinghamshire NG, United Kingdom",
  "Steenokkerzeel, Belgium",
  "Steffenberg, Germany",
  "Steinach am Brenner, Austria",
  "Steinberg, Norway",
  "Steinheim, Germany",
  "Steinsholt, Norway",
  "Stenlille, Denmark",
  "Stephenville, United States",
  "Stepps, United Kingdom",
  "Stevensburg, United States",
  "Stickney, United States",
  "Stillington North Yorkshire, United Kingdom",
  "Stittsville, Canada",
  "Stochov, Czech Republic",
  "Stockbridge, United States",
  "Stockbridge Hampshire, United Kingdom",
  "Stocksbridge, United Kingdom",
  "Stockstadt am Main, Germany",
  "Stoke Ferry, United Kingdom",
  "Stoke Gifford Gloucestershire, United Kingdom",
  "STOKENCHURCH, United Kingdom",
  "Stokesley Yorks, United Kingdom",
  "Stoneywood, United Kingdom",
  "Stony Point, United States",
  "Stora Hoega, Sweden",
  "Stormill, South Africa",
  "Stouffville, Canada",
  "Stoughton, United States",
  "Stow, United States",
  "Strabane Northern Ireland, United Kingdom",
  "Strachotin, Czech Republic",
  "Straengnaes, Sweden",
  "Straffordville, Canada",
  "Stralsund, Germany",
  "Strasice, Czech Republic",
  "Straskov, Czech Republic",
  "Strassen, Luxemburg",
  "Strasshof an der Nordbahn, Austria",
  "Straszecin, Poland",
  "Stratford, Canada",
  "Strathaven, United Kingdom",
  "Strathroy, Canada",
  "Straume, Norway",
  "Streamwood, United States",
  "Streatham London, United Kingdom",
  "Strekov, Slovakia",
  "Strelice, Czech Republic",
  "Stretford Herefordshire HR, United Kingdom",
  "Strijdompark, South Africa",
  "Strijen, Netherlands",
  "Stringtown, United States",
  "Strongsville, United States",
  "Stroudsburg, United States",
  "Struer, Denmark",
  "Strykersville, United States",
  "Stuart, United States",
  "Stupava, Slovakia",
  "Sturgeon Bay, United States",
  "Stutensee, Germany",
  "Subang Jaya, Malaysia",
  "Subingen, Switzerland",
  "Suceava, Romania",
  "Suchovrsice, Czech Republic",
  "Suckley WR, United Kingdom",
  "Sudbury, Canada",
  "Sudkov, Czech Republic",
  "Sulgen, Switzerland",
  "Sulice, Czech Republic",
  "Sulkowice, Poland",
  "Sulphur, United States",
  "Sulzbach (Am Main), Germany",
  "Sumgayit, Azerbaijan",
  "Summerdale, United States",
  "Summerside, Canada",
  "Summit, United States",
  "Sun City, United States",
  "Sundbyfoss, Norway",
  "Sunderland Ridge, South Africa",
  "Sunds, Denmark",
  "Sunnyside, United States",
  "Sunrise, United States",
  "Surajpur Noida, India",
  "Surprise, United States",
  "Surte, Sweden",
  "Surulere, Nigeria",
  "Sussex, United States",
  "Sutton On Trent, United Kingdom",
  "Sutton Weaver, United Kingdom",
  "Suvarnakhet, Laos",
  "Suwannee, United States",
  "Suwon, Korea, South",
  "Svaloev, Sweden",
  "Svaty Jur, Slovakia",
  "Sveti Nikole, Macedonia",
  "Svetlogorsk, Belarus",
  "Svobodny, Russia",
  "Swaffham, United Kingdom",
  "Swainsboro, United States",
  "Swanzey, United States",
  "Sweetwater, United States",
  "Swepsonville, United States",
  "Swiatniki Gorne, Poland",
  "Swilcza, Poland",
  "Sycamore, United States",
  "Sykarainen, Finland",
  "Sykesville, United States",
  "Sylmar, United States",
  "Sylva, United States",
  "Symington South Lanarkshire, United Kingdom",
  "Syosset, United States",
  "Szada, Hungary",
  "Szaszberek, Hungary",
  "Szczawno Zdroj, Poland",
  "Szczerbice, Poland",
  "Szeghalom, Hungary",
  "Szigethalom, Hungary",
  "Szubin, Poland",
  "Szydlowiec, Poland",
  "Szymanow, Poland",
  "Saarijarvi, Finland",
  "Tabivere, Estonia",
  "Tabor City, United States",
  "Tabuk, Saudi Arabia",
  "Taffs Well Wales, United Kingdom",
  "Taft, United States",
  "Taftville, United States",
  "Tagoloan, Philippines",
  "Tahoua, Niger",
  "Taiba, Senegal",
  "Takanini, New Zealand",
  "Talatona, Angola",
  "Talheim (Neckar), Germany",
  "Talladega, United States",
  "Tallahassee, United States",
  "Talmaciu, Romania",
  "Tamaqua, United States",
  "Tamarac, United States",
  "Tampico, Mexico",
  "Tampico, United States",
  "Tandragee Northern Ireland, United Kingdom",
  "Tangshan, China",
  "Tannay, Switzerland",
  "Tappan, United States",
  "Tarboro, United States",
  "Tarentum, United States",
  "Targu Jiu, Romania",
  "Tarpon Springs, United States",
  "Tarvasjoki, Finland",
  "Tashkent, Uzbekistan",
  "Taubate, Brazil",
  "Taufkirchen an der Pram, Austria",
  "Tavares, United States",
  "Taveiro, Portugal",
  "Tavistock, United Kingdom",
  "Taylorsville, United States",
  "Tebay, United Kingdom",
  "Tecamac, Mexico",
  "Teculutan, Guatemala",
  "Tecumseh, Canada",
  "Teerijarvi, Finland",
  "Teesdorf, Austria",
  "Tehachapi, United States",
  "Tehovec, Czech Republic",
  "Tehuacan, Mexico",
  "Tel-Aviv, Israel",
  "Telde Salinetas, Spain",
  "Telekgerendas, Hungary",
  "Telfs, Austria",
  "Teltow, Germany",
  "Temecula, United States",
  "Temperance, United States",
  "Temple Hills, United States",
  "Tence, France",
  "Teno, Chile",
  "Tepa, Ghana",
  "Tepotzotlan, Mexico",
  "Ter Aar, Netherlands",
  "Terezin, Czech Republic",
  "Terme Vigliatore, Italy",
  "Ternat, Belgium",
  "Terport, Paraguay",
  "Terranuova Bracciolini, Italy",
  "Terrasson la Villedieu, France",
  "Terre Hill, United States",
  "Tervel, Bulgaria",
  "Tesistan, Mexico",
  "Tetcice, Czech Republic",
  "Tetla, Mexico",
  "Tetouan, Morocco",
  "Tewksbury, United States",
  "Texas City, United States",
  "Thalgau, Austria",
  "Thamesport, United Kingdom",
  "Thayngen, Switzerland",
  "The Colony, United States",
  "Theale Somerset, United Kingdom",
  "Theresa, United States",
  "Thermal, United States",
  "Thetford Mines, Canada",
  "Thetsane Industrial Estate, Lesotho",
  "Thies, Senegal",
  "Thilouze, France",
  "Thomaston, United States",
  "Thompsontown, United States",
  "Thonotosassa, United States",
  "THORNBURY GLOUCESTERSHIRE, United Kingdom",
  "Thorndale, Canada",
  "Thorndale, United States",
  "Thornhill, Canada",
  "Thornwood Common Essex, United Kingdom",
  "Thorpe Bay, United Kingdom",
  "Thulin, Belgium",
  "Thun, Switzerland",
  "Thurnau, Germany",
  "Tianjin, China",
  "Tiffin, United States",
  "Tifnit, Morocco",
  "Tifton, United States",
  "Tigard, United States",
  "Tiller, Norway",
  "Tillsonburg, Canada",
  "Timmonsville, United States",
  "Timonium, United States",
  "Tingsryd, Sweden",
  "Tingwick, Canada",
  "Tinsley, United Kingdom",
  "Tipp City, United States",
  "Tipton, United States",
  "Tiptree, United Kingdom",
  "Tirana, Albania",
  "Tirano, Italy",
  "Tisbury, United Kingdom",
  "Tiszafured, Hungary",
  "Titusville, United States",
  "Tjele, Denmark",
  "Toa Baja, Puerto Rico",
  "Tobishima, Japan",
  "Todtenweis, Germany",
  "Tolanaro Fort Dauphin, Madagascar",
  "Toliara, Madagascar",
  "Tolna, Hungary",
  "Tolox, Spain",
  "Tomar, Portugal",
  "Tomares, Spain",
  "Tomatin, United Kingdom",
  "Tomball, United States",
  "Tomblaine, France",
  "Tomelilla, Sweden",
  "Tompkinsville, United States",
  "Tong Du Chon Ni, Korea, South",
  "Tongham Surrey, United Kingdom",
  "Topeka, United States",
  "Topolobampo, Mexico",
  "Toppenish, United States",
  "Tordesillas, Spain",
  "Torhout, Belgium",
  "Toronto East, Canada",
  "Tororo, Uganda",
  "Torphins Aberdeenshire, United Kingdom",
  "Torreblanca Castellon, Spain",
  "Torrecuso, Italy",
  "Torrelodones, Spain",
  "Torrenova, Italy",
  "Torrente De Cinca, Spain",
  "Torrenueva, Spain",
  "Torrington, United States",
  "Torrox, Spain",
  "Torsby, Sweden",
  "Torsvik, Sweden",
  "Torti, Panama",
  "Tortosendo, Portugal",
  "Torvastad, Norway",
  "Torviscosa, Italy",
  "Toszeg, Hungary",
  "Touba, Senegal",
  "Toussieu, France",
  "Tovarniky, Slovakia",
  "Tower City, United States",
  "Toyohashi, Japan",
  "Trabzon, Turkey",
  "Trainer, United States",
  "Tranemo, Sweden",
  "Tranaas, Sweden",
  "Trautskirchen, Germany",
  "Traver, United States",
  "Traverse City, United States",
  "Trearddur Holyhead LL, United Kingdom",
  "Trebarov, Czech Republic",
  "Treichville, Ivory Coast",
  "Treillieres, France",
  "Tremery, France",
  "Tremes, Portugal",
  "Tremont, United States",
  "Tremosna, Czech Republic",
  "Trept, France",
  "Trhove Sviny, Czech Republic",
  "Triadelphia, United States",
  "Trindade, Cape Verde Island",
  "Trn - Laktasi, Bosnia and Herzegovina",
  "Trollaasen, Norway",
  "Tromsoe, Norway",
  "Troubky, Czech Republic",
  "Troutman, United States",
  "Troy, United States",
  "Trstena, Slovakia",
  "Trzcinica, Poland",
  "Trzebnica, Poland",
  "Tsumeb, Namibia",
  "Tsurumi-Ku Kanagawa, Japan",
  "Tubize, Belgium",
  "Tuhkakyla, Finland",
  "Tukwila, United States",
  "Tulancingo, Mexico",
  "Tulbagh, South Africa",
  "Tulcea, Romania",
  "Tulia, United States",
  "Tullahoma, United States",
  "Tully, United States",
  "Tulowice, Poland",
  "Tultitlan, Mexico",
  "Tumba, Sweden",
  "Tunica, United States",
  "Tupesy, Czech Republic",
  "Turda, Romania",
  "Turenki, Finland",
  "Turi, Estonia",
  "Turners Hill West Sussex, United Kingdom",
  "Turquel, Portugal",
  "Turrialba, Costa Rica",
  "Tursko, Czech Republic",
  "Turzovka, Slovakia",
  "Tusdeer Free Zone, Saudi Arabia",
  "Tustin, United States",
  "Tuszow Narodowy, Poland",
  "Tutzing, Germany",
  "Tuusula, Finland",
  "Tuxtla Gutierrez, Mexico",
  "Tvrdosin, Slovakia",
  "Twello, Netherlands",
  "Two Rivers, United States",
  "Twyford Buckinghamshire, United Kingdom",
  "Tyczyn, Poland",
  "Tyldesley Wigan, United Kingdom",
  "Tylersport, United States",
  "Tymbark, Poland",
  "Tyndall Air Force Base, United States",
  "Tyndrum Stirling FK, United Kingdom",
  "Tynec nad Labem, Czech Republic",
  "Tyngsboro, United States",
  "Tynaarlo, Netherlands",
  "Tyristrand, Norway",
  "Taagarp, Sweden",
  "Uddel, Netherlands",
  "Uffington Oxfordshire, United Kingdom",
  "Uithoorn, Netherlands",
  "Uiwang, Korea, South",
  "Ujezd U Brna, Czech Republic",
  "Ullared, Sweden",
  "Ullastrell Barcelona, Spain",
  "Ullo, Hungary",
  "Ulsteinvik, Norway",
  "Ulstrup, Denmark",
  "Umbergaon, India",
  "Umbilo, South Africa",
  "Umbogintwini South, South Africa",
  "Umeaa, Sweden",
  "Umlazi, South Africa",
  "Umtata, South Africa",
  "Undheim, Norway",
  "Union, United States",
  "Union Grove, United States",
  "Uniontown, United States",
  "Unionville, United States",
  "Unnaryd, Sweden",
  "Unterpremstaetten-Zettling, Austria",
  "Up Holland, United Kingdom",
  "Upper Sandusky, United States",
  "Upperlands Magherafelt BT, United Kingdom",
  "Uppingham, United Kingdom",
  "Upton ParkLondon, United Kingdom",
  "Urbancrest, United States",
  "Urdorf, Switzerland",
  "Urosevac, Serbia",
  "Urrugne, France",
  "Usagre, Spain",
  "Usansolo, Spain",
  "Usedom, Germany",
  "Usov, Czech Republic",
  "Uta, Italy",
  "Utiel, Spain",
  "Uttwil, Switzerland",
  "Utzenstorf, Switzerland",
  "Uxbridge, Canada",
  "Uxbridge, United States",
  "Uznach, Switzerland",
  "Uzwil, Switzerland",
  "Vacaville, United States",
  "Vadnais Heights, United States",
  "Vaederstad, Sweden",
  "Vaestervik, Sweden",
  "Vaestra Froelunda, Sweden",
  "Vaggeryd, Sweden",
  "Vaiges, France",
  "Valbo, Sweden",
  "Valderoey, Norway",
  "Valdivia, Chile",
  "Val-D'OR, Canada",
  "Valence d'Agen, France",
  "Valencia, Venezuela",
  "Valencia de Alcantara, Spain",
  "Valer, Norway",
  "Valla, Sweden",
  "Vallada, Spain",
  "Valladolises, Spain",
  "Valle del Zalabi, Spain",
  "Vallentuna, Sweden",
  "Valley, United States",
  "Valley City, United States",
  "Valley View, United States",
  "Valleyfield, Canada",
  "Valmadrid, Spain",
  "Valmeyer, United States",
  "Vammala, Finland",
  "Van, United States",
  "Van Buren Twp, United States",
  "Vanadzor, Armenia",
  "Vance, United States",
  "Vandalia, United States",
  "Vanderbijl Park, South Africa",
  "Vanier, Canada",
  "Vanloese, Denmark",
  "Vaprio d'Agogna, Italy",
  "Varallo, Italy",
  "Varel, Germany",
  "Varena, Lithuania",
  "Varennes, Canada",
  "Vargoen, Sweden",
  "Varhaug, Norway",
  "VARNEVILLE-BRETTEVILLE, France",
  "Vars, France",
  "Vashon, United States",
  "Vaslui, Romania",
  "Vasser, United States",
  "Vassilikos, Cyprus",
  "Vaughan, Canada",
  "Vaux sur Mer, France",
  "Vaxholm, Sweden",
  "Vedene, France",
  "Vejstrup, Denmark",
  "Velesin, Czech Republic",
  "Velika Gorica, Croatia",
  "Velika Kladusa, Bosnia and Herzegovina",
  "Velikiye Luki, Russia",
  "Velka Lomnica, Slovakia",
  "Velke Albrechtice, Czech Republic",
  "Velke Bierovce, Slovakia",
  "Velke Blahovo, Slovakia",
  "Velke Dravce, Slovakia",
  "Velke Kapusany, Slovakia",
  "Velke Nemcice, Czech Republic",
  "Velke Orviste, Slovakia",
  "Velke Rovne, Slovakia",
  "Velletri, Italy",
  "Vellinge, Sweden",
  "Velp, Netherlands",
  "Veltrusy, Czech Republic",
  "Velvary, Czech Republic",
  "Velyka Dymerka, Ukraine",
  "Vence, France",
  "Venda do pinheiro, Portugal",
  "Ventura, United States",
  "Vereeniging, South Africa",
  "Verfeil, France",
  "VERNA, India",
  "Vernalis, United States",
  "Vernier, Switzerland",
  "Vernon, Canada",
  "Vertou, France",
  "Verulam, South Africa",
  "Vestby, Norway",
  "Vestnes, Norway",
  "Vettre, Norway",
  "Vezenobres, France",
  "Vialonga, Portugal",
  "Viby, Denmark",
  "Victor, United States",
  "Victoria, Romania",
  "Victoria, United States",
  "Victoria Falls, Zimbabwe",
  "Victoria Harbour, Canada",
  "Victoriaville, Canada",
  "Victorville, United States",
  "Vidalia, United States",
  "Vidnoye, Russia",
  "Vienna, United States",
  "Vientiane, Laos",
  "Vila Nova de Cerveira, Portugal",
  "Vila Praia de Ancora, Portugal",
  "Vila Real, Portugal",
  "VILA RODONA, Spain",
  "Vila Verde, Portugal",
  "Vilamarin, Spain",
  "Villa Ahumada, Mexico",
  "Villa del Rio, Spain",
  "Villa Guerrero, Mexico",
  "Villa Maria Cordoba, Argentina",
  "Villa Park, United States",
  "Villa Rica, United States",
  "Villabona, Spain",
  "Villahermosa, Mexico",
  "Villarrubia, Spain",
  "Ville St Laurent, Canada",
  "Villebois-Lavalette, France",
  "Villeneuve la Garenne, France",
  "Villeneuve les Bouloc, France",
  "Villeparisis, France",
  "Villiers le Bel, France",
  "Vincennes, United States",
  "Vinchiaturo, Italy",
  "Vinderup, Denmark",
  "Vineland, Canada",
  "Vinkovci, Croatia",
  "Vinsloev, Sweden",
  "Vinton, United States",
  "Virgil, Canada",
  "Virrat, Finland",
  "Visby, Sweden",
  "Visp, Switzerland",
  "Vitez, Bosnia and Herzegovina",
  "Vitoria, Brazil",
  "Vivy, France",
  "Vizelas, Portugal",
  "Vizzolo Predabissi, Italy",
  "Vladikavkaz, Russia",
  "Vlora, Albania",
  "Vodochody, Czech Republic",
  "Vogelweh (Kaiserslautern), Germany",
  "Vojkovice, Czech Republic",
  "Voluntari, Romania",
  "Von Ormy, United States",
  "Vorbach, Germany",
  "Vordingborg, Denmark",
  "Vore, Albania",
  "Vorsino, Russia",
  "Voss, Norway",
  "Votanikos, Greece",
  "Votice, Czech Republic",
  "Vougy, France",
  "Vourles, France",
  "Voves, France",
  "Vracov, Czech Republic",
  "Vranje, Serbia",
  "Vranov Nad Toplou, Slovakia",
  "Vranovice-Kelcice, Czech Republic",
  "Vrbovec, Czech Republic",
  "Vredendal, South Africa",
  "Vseradice, Czech Republic",
  "Vyborg, Russia",
  "Vysoky Ujezd, Czech Republic",
  "Vaalimaa, Finland",
  "Wabash, United States",
  "WadebridgeCornwall PL, United Kingdom",
  "Wadersloh, Germany",
  "Wadsworth, United States",
  "Waibstadt, Germany",
  "Waizenkirchen, Austria",
  "Wake Forest, United States",
  "Wakefield, United States",
  "Walbridge, United States",
  "Walcott, United States",
  "Waldburg (Wurtt), Germany",
  "Walden, United States",
  "Waldneukirchen, Austria",
  "Waldo, United States",
  "Waldoboro, United States",
  "Walenczow, Poland",
  "Walker, United States",
  "Walkerton, Canada",
  "Walkerton, United States",
  "Walldorf (b Meiningen), Germany",
  "Wallenstein, Canada",
  "Wallingford, United States",
  "Walmer, South Africa",
  "Walnut Cove, United States",
  "Walnut Creek, United States",
  "Walpole, United States",
  "Walterboro, United States",
  "Waltloo, South Africa",
  "Walton Leicestershire, United Kingdom",
  "Walton on the Naze Essex, United Kingdom",
  "Wamego, United States",
  "Wanchese, United States",
  "Wardhill, United States",
  "Ware, United States",
  "Warfield, United Kingdom",
  "Warm Springs, United States",
  "Warmbaths, South Africa",
  "Warminster, United States",
  "Warnford Hampshire, United Kingdom",
  "Warrendale, United States",
  "Warrenpoint Northern Ireland, United Kingdom",
  "Warrensburg, United States",
  "Warrensville, United States",
  "Warrenton, United States",
  "Warrenville, United States",
  "Warri, Nigeria",
  "Warsaw, United States",
  "Wartberg im Muerztal, Austria",
  "Wartkowice, Poland",
  "WARWICK, Canada",
  "WARWICK, United States",
  "Wasco, United States",
  "Washington Court House, United States",
  "Washougal, United States",
  "Waskom, United States",
  "Wasosz Dolny, Poland",
  "Water Orton Warwickshire, United Kingdom",
  "Waterfoot Lancashire, United Kingdom",
  "WATERLOO, Belgium",
  "WATERLOO, United States",
  "WATERVLIET, United States",
  "Wath Upon Dearne, United Kingdom",
  "Watlington Oxfordshire, United Kingdom",
  "Watsonville, United States",
  "Wauchula, United States",
  "Waupun, United States",
  "Wausau, United States",
  "Wauwatosa, United States",
  "Waverly, United States",
  "Waverton Cheshire, United Kingdom",
  "Waycross, United States",
  "Waynesville, United States",
  "Wealdstone, United Kingdom",
  "Webberville, United States",
  "Webster, United States",
  "Wedmore, United Kingdom",
  "Weedon, United Kingdom",
  "Wegierska Gorka, Poland",
  "Wegrzce, Poland",
  "Weidenberg, Germany",
  "Weiherhammer, Germany",
  "Weilerswist, Germany",
  "Weilheim (Oberbayern), Germany",
  "Weiz, Austria",
  "WELLAND, Canada",
  "Wellford, United States",
  "Wellington, South Africa",
  "Wellington, United States",
  "Wells, United States",
  "Wellsville, United States",
  "Welshpool, Australia",
  "Welzheim, Germany",
  "Wemmel, Belgium",
  "Wermatswil, Switzerland",
  "Wernau, Germany",
  "West, United States",
  "WEST BANK EBS, South Africa",
  "West Berlin, United States",
  "West Branch, United States",
  "West Bridgewater, United States",
  "West Brookfield, United States",
  "West Carrollton, United States",
  "West Deptford, United States",
  "West Dundee, United States",
  "West Elizabeth, United States",
  "West Greenwich Center, United States",
  "West Hartford, United States",
  "West Haven, United States",
  "West Helena, United States",
  "West Jefferson, United States",
  "West Kelowna, Canada",
  "West Lebanon, United States",
  "West Linton, United Kingdom",
  "West Malling, United Kingdom",
  "West Orange, United States",
  "West Point, United States",
  "West Union, United States",
  "West Vancouver, Canada",
  "West Wareham, United States",
  "Westampton, United States",
  "Westchester, United States",
  "Westerkappeln, Germany",
  "Westfield, United States",
  "Westford, United States",
  "Westhoffen, France",
  "Westlake Village, United States",
  "WESTMEAD, South Africa",
  "Westminster, United States",
  "Weston, Canada",
  "Westonaria, South Africa",
  "Westoning Bedford, United Kingdom",
  "Westrozebeke, Belgium",
  "Westwood, United States",
  "Wetumpka, United States",
  "Wever, United States",
  "Weyauwega, United States",
  "Whalley, United Kingdom",
  "Wharton, United States",
  "Wheathampstead, United Kingdom",
  "Wheatland, United States",
  "Wheeling, United States",
  "Whippany, United States",
  "Whitbourne Herefordshire WR, United Kingdom",
  "Whitburn Loth, United Kingdom",
  "Whitchurch Cardiff CF, United Kingdom",
  "White City, United States",
  "White House, United States",
  "White Plains, United States",
  "White Settlement, United States",
  "White Waltham Berkshire, United Kingdom",
  "Whitechapel Lancashire, United Kingdom",
  "Whitechurch Pembrokeshire, United Kingdom",
  "Whitehall, United States",
  "Whiteman AFB, United States",
  "Whitestown, United States",
  "Whitinsville, United States",
  "Whitmore Lake, United States",
  "Whittier, United States",
  "Whitwell on the Hill, United Kingdom",
  "Whitwood, United Kingdom",
  "Wichita Falls, United States",
  "Widnau, Switzerland",
  "Wiekszyce, Poland",
  "Wielen, Poland",
  "Wieliszew, Poland",
  "Wiesent, Germany",
  "Wijk bij Duurstede, Netherlands",
  "Wikon, Switzerland",
  "WIL - SG, Switzerland",
  "Wilburton, United States",
  "Wilcox, United States",
  "Wilder, United States",
  "Wildwood, United States",
  "Wilhermsdorf, Germany",
  "Wilkesboro, United States",
  "Willacoochee, United States",
  "Willcox, United States",
  "Williamston, United States",
  "Williamstown, United States",
  "Willington Bedfordshire, United Kingdom",
  "Willisau, Switzerland",
  "Williston, United States",
  "Willow Springs, United States",
  "Willowbrook, United States",
  "Willows, United States",
  "Wilsele, Belgium",
  "Wilsonia, South Africa",
  "Wilsonville, United States",
  "Wilstead, United Kingdom",
  "Wilton, United States",
  "Wilton Wiltshire, United Kingdom",
  "Wimauma, United States",
  "Wimbish, United Kingdom",
  "Wimpassing Im Schwarzatale, Austria",
  "Winchendon, United States",
  "Winchester, Canada",
  "Winder, United States",
  "Windermere, United States",
  "Windischleuba, Germany",
  "Windlesham, United Kingdom",
  "Windsor, Canada",
  "Winesburg, United States",
  "Wingate, United Kingdom",
  "Winlock, United States",
  "Winn, United States",
  "Winona, United States",
  "Winsford Somerset, United Kingdom",
  "Winslow, United States",
  "Winsted, United States",
  "Winter Springs, United States",
  "Winterport, United States",
  "Wisznia Mala, Poland",
  "Witbank Mpumalanga, South Africa",
  "Witnica, Poland",
  "Witry les Reims, France",
  "Witton Norfolk, United Kingdom",
  "Wixom, United States",
  "Woburn, United States",
  "Wojkowice, Poland",
  "Wola Bykowska, Poland",
  "Wola Krzysztoporska, Poland",
  "Wola Rebkowska, Poland",
  "Wolcott, United States",
  "Wolczyn, Poland",
  "Wolfforth, United States",
  "Wolfsburg, Germany",
  "Wolka Kosowska, Poland",
  "Wolkowo, Poland",
  "Wolow, Poland",
  "Wolvercote, United Kingdom",
  "Wolverton Milton Keynes, United Kingdom",
  "Wommelgem, Belgium",
  "Wonderboom, South Africa",
  "Wood Green London, United Kingdom",
  "Wood Norton, United Kingdom",
  "Woodbine, United States",
  "Woodbrook EBS, South Africa",
  "Woodgreen, United Kingdom",
  "Woodham Durham, United Kingdom",
  "Woodhaven, United States",
  "Woodland Hills, United States",
  "Woodmansey Yorkshire, United Kingdom",
  "Woodmead, South Africa",
  "Woodridge, United States",
  "Woodside, United States",
  "Woodstock, United States",
  "Woodstown, United States",
  "Woolacombe Devon EX, United Kingdom",
  "Wooler, United Kingdom",
  "Woonsocket, United States",
  "Wooster, United States",
  "Wootton Bassett Swindon, United Kingdom",
  "Woringen, Germany",
  "Worthington, United States",
  "Wotton Under Edge, United Kingdom",
  "Wragby, United Kingdom",
  "Wreczyca Wielka, Poland",
  "Wrentham, United States",
  "Wuenschendorf, Germany",
  "Wurmsham, Germany",
  "Wurzbach, Germany",
  "Wuxi, China",
  "Wyandotte, United States",
  "Wyboston, United Kingdom",
  "Wyhl, Germany",
  "Wylie, United States",
  "Wynnewood, United States",
  "Wyoming, Canada",
  "Wytheville, United States",
  "Waalre, Netherlands",
  "Xirivella, Spain",
  "Xuzhou, China",
  "Yadkinville, United States",
  "Yainville, France",
  "Yakima, United States",
  "Yamachiche, Canada",
  "Yamoussoukro, Ivory Coast",
  "Yangsan, Korea, South",
  "Yaounde, Cameroon",
  "Yarmouth, Canada",
  "Yarmouth, United States",
  "Yatsushiro, Japan",
  "Yeongi, Korea, South",
  "Yesilkoy-Istanbul, Turkey",
  "YINGKOU, China",
  "Ylimarkku, Finland",
  "Ylistaro, Finland",
  "Yongin, Korea, South",
  "Yopougon, Ivory Coast",
  "Yorba Linda, United States",
  "YORKTOWN, United States",
  "Yorktown Heights, United States",
  "Yosu, Korea, South",
  "Youngsville, United States",
  "Ypsilanti, United States",
  "Yuba City, United States",
  "Yulee, United States",
  "Yunquera Malaga, Spain",
  "Yuzhnoukrainsk, Ukraine",
  "Yverdon les Bains, Switzerland",
  "Yvonand, Switzerland",
  "Zabkowice Slaskie, Poland",
  "Zabrodzie, Poland",
  "Zagan, Poland",
  "Zakanyszek, Hungary",
  "Zakroczym, Poland",
  "Zalany, Czech Republic",
  "Zalau, Romania",
  "Zalaapati, Hungary",
  "Zamoranos, Spain",
  "Zanesville, United States",
  "Zaporizhzhya, Ukraine",
  "Zasmuky, Czech Republic",
  "Zastavka u Brna, Czech Republic",
  "Zaventem, Belgium",
  "Zavidovici, Bosnia and Herzegovina",
  "Zawadzkie, Poland",
  "Zbehy, Slovakia",
  "Zbiroh, Czech Republic",
  "Zdislavice, Czech Republic",
  "Zeerust, South Africa",
  "ZEJTUN, Malta",
  "Zeliezovce, Slovakia",
  "Zell (unter Aichelberg), Germany",
  "Zellwood, United States",
  "Zeltweg, Austria",
  "Zelzate, Belgium",
  "Zembrzyce, Poland",
  "Zenda, United States",
  "Zenica, Bosnia and Herzegovina",
  "Zettling, Austria",
  "Zevgolatio, Greece",
  "Zglobice, Poland",
  "Zielonki, Poland",
  "Ziesar, Germany",
  "Zinder, Niger",
  "Zion, United States",
  "Zitacuaro, Mexico",
  "Zlatograd, Bulgaria",
  "Znin, Poland",
  "Zofingen, Switzerland",
  "Zomba, Malawi",
  "Zossen, Germany",
  "Zrenjanin, Serbia",
  "Zuenoula, Ivory Coast",
  "Zukowo, Poland",
  "Zurgena, Spain",
  "Zuromin, Poland",
  "Zvoleneves, Czech Republic",
  "Zwijnaarde, Belgium",
  "Zwaagdijk, Netherlands",
  "Zyrardow, Poland",
  "Aaby, Sweden",
  "Aabyhoej, Denmark",
  "Aagotnes, Norway",
  "Aaheim, Norway",
  "Aalbeke, Belgium",
  "Aalgaard, Norway",
  "Aalter, Belgium",
  "Aamli, Norway",
  "Aandalsnes, Norway",
  "Aaros, Norway",
  "Aarup, Denmark",
  "Aasbro, Sweden",
  "Aasenhoega, Sweden",
  "Aatorp, Sweden",
]
